import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
interface Props {
  company: string;
}
const CopyrightText = ({ company }: Props) => {
  const intl = useIntl();
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      fontSize="12px"
      color="grey.6"
      marginY="20px"
      spacing={[0, 1]}
      textAlign={{ base: 'center', md: 'left' }}
      width="100%"
    >
      <Text>{`\u00A9${new Date().getFullYear()} ${intl.formatMessage(
        { id: 'copyright.copyright', defaultMessage: 'All Rights Reserved. {company}' },
        { company },
      )}`}</Text>
      {/* TODO: Re-instate when link is added */}
      {/* <Text textDecoration="underline">
        {intl.formatMessage({ id: 'copyright.privacy', defaultMessage: 'Terms & Privacy' })}
      </Text> */}
    </Stack>
  );
};

export default CopyrightText;
