import {
  Box,
  Button,
  Flex,
  FlexProps,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { HIDE_SCROLLBAR_STYLES } from '../../constants/style.constants';

export const DEFAULT_MODAL_PADDING = 40;

interface Props extends ModalProps {
  actionLabel?: string;
  children: ReactNode;
  contentStyles?: ModalContentProps;
  footerFlexProps?: FlexProps;
  footerStyles?: ModalFooterProps;
  heading?: string;
  hideActionButton?: boolean;
  hideCancel?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  maxWidth?: ModalContentProps['maxWidth'];
  modalBodyPaddingX?: ModalBodyProps['paddingX'];
  onClose(): void;
  onConfirm?(): void;
  subHeading?: string;
  unwrapChildren?: boolean;
  bodyStyles?: ModalBodyProps;
}

const Modal = ({
  actionLabel,
  children,
  contentStyles,
  footerFlexProps,
  footerStyles,
  heading,
  hideActionButton,
  hideCancel,
  isLoading,
  isOpen,
  maxWidth = `${Math.min(window.innerWidth, 800)}px`,
  modalBodyPaddingX = { base: `${DEFAULT_MODAL_PADDING / 2}px`, md: `${DEFAULT_MODAL_PADDING}px` },
  onClose,
  onConfirm,
  subHeading,
  unwrapChildren,
  bodyStyles,
  ...rest
}: Props) => {
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const save = intl.formatMessage({
    id: 'modal.save',
    defaultMessage: 'Save',
  });

  const saveButtonText = actionLabel || save;

  return (
    <ChakraModal
      size={isMobile ? 'full' : undefined}
      blockScrollOnMount
      isCentered={false}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: 0, md: 6 }}
        margin={{ base: 0, md: '125px' }}
        maxWidth={maxWidth}
        minHeight={{ base: undefined, md: '600px' }}
        maxHeight={{ base: undefined, lg: '600px' }}
        css={HIDE_SCROLLBAR_STYLES}
        {...contentStyles}
      >
        <Flex
          data-test="modal-container"
          direction="column"
          align="center"
          marginTop={10}
          textAlign="center"
          width="100%"
        >
          <ModalHeader fontSize="2xl" fontFamily="ITC Avant Garde Gothic Demi">
            {heading}
          </ModalHeader>
          {subHeading && (
            <Text maxWidth="600px" paddingBottom={4}>
              {subHeading}
            </Text>
          )}
        </Flex>
        <ModalCloseButton borderRadius="50%" />
        <ModalBody paddingX={modalBodyPaddingX} css={HIDE_SCROLLBAR_STYLES} {...bodyStyles}>
          {unwrapChildren ? children : <Box>{children}</Box>}
        </ModalBody>
        {!!onConfirm && (
          <ModalFooter {...footerStyles}>
            <Flex align="center" direction="column" width="100%" {...footerFlexProps}>
              {hideActionButton || (
                <Button
                  data-test="modal-btn"
                  disabled={isLoading}
                  isLoading={isLoading}
                  loadingText={saveButtonText}
                  marginBottom={1}
                  onClick={onConfirm}
                  width={{ base: '100%', md: '400px' }}
                >
                  {saveButtonText}
                </Button>
              )}
              {!hideCancel && (
                <Button
                  color="brand"
                  width={{ base: '100%', md: '400px' }}
                  onClick={onClose}
                  variant="secondary"
                >
                  {intl.formatMessage({
                    id: 'backgrounds.Cancel',
                    defaultMessage: 'Cancel',
                  })}
                </Button>
              )}
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
