import { Flex, IconButton, Spinner, Text, useClipboard } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImImages } from 'react-icons/im';
import { RiShareForwardFill } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ShopBackground } from '../../../../../shop-api-client';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { setSelectedImageName } from '../../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../../redux/store';
import ArrowLeftbutton from '../../../../shared/components/ArrowLeftButton';
import ArrowRightButton from '../../../../shared/components/ArrowRightButton';
import ImageDisplayName from '../../../../shared/components/ImageDisplayName';
import ModalCloseButton from '../../../../shared/components/ModalCloseButton';
import {
  CLOSE_MODAL,
  FULL_IMAGE_SIZE,
  IMAGE_VIEWER_Z_INDEX,
  NAVIGATE_LEFT,
  NAVIGATE_RIGHT,
} from '../../../../shared/constants';
import useIsMobile from '../../../../shared/hooks/useIsMobile';
import useOnEvent from '../../../../shared/hooks/useOnEvent';
import useToast from '../../../../shared/hooks/useToast';
import { Params } from '../../../../shared/types/router';
import {
  BG_VIEWER_WIDTH,
  Direction,
  MOBILE_BG_SLIDER_HEIGHT,
  NEXT,
  PREV,
  VIEWER_BTN_HEIGHT,
  VIEWER_BTN_MARGIN,
} from '../../constants';
import ActiveImageCanvas from '../ActiveImageCanvas';
import AnimateWrapper from '../AnimateWrapper';
import { IMAGE_SLIDER_HEIGHT } from '../constants';

const INITIAL_ACTIVE_IMAGE_SIZE = 50;
const MOBILE_WIDTH_GUTTER = 10;
const TOOLBAR_HEIGHT = 50;

const MOBILE_ELEM_HEIGHTS = VIEWER_BTN_HEIGHT + TOOLBAR_HEIGHT + VIEWER_BTN_MARGIN * 2;

interface Props {
  background?: ShopBackground;
  direction: string;
  imageElem: HTMLImageElement;
  imageIndex: number;
  imageListCount: number;
  imageName: string;
  imageOnlyMode: boolean;
  isViewerExpanded: boolean;
  onNavigate(direction: Direction): void;
  shouldResizeCanvas: boolean;
  toggleImageOnly(): void;
  toggleShowViewer(): void;
}

const ActiveImage = ({
  background,
  direction,
  imageElem,
  imageIndex,
  imageListCount,
  imageName,
  imageOnlyMode,
  isViewerExpanded,
  onNavigate,
  shouldResizeCanvas,
  toggleImageOnly,
  toggleShowViewer,
}: Props) => {
  // Selectors
  const { backgroundSets } = useSelector(selectPriceSheet);
  const { images } = useSelector(selectGallery);

  // State
  const [backgroundElem, setBackgroundElem] = useState<HTMLImageElement | null>(null);
  const [height, setHeight] = useState(INITIAL_ACTIVE_IMAGE_SIZE);
  const [width, setWidth] = useState(INITIAL_ACTIVE_IMAGE_SIZE);
  const [loading, setLoading] = useState(true);
  const [navArrowsDisplay, setNavArrowsDisplay] = useState<'none' | 'inherit'>('none');

  const { onCopy } = useClipboard(location.href);
  const { groupID, key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const toast = useToast();

  // Refs
  const wrapper = useRef<HTMLDivElement>(null);
  const mainContainer = useRef<HTMLDivElement>(null);

  const shopImage = images[imageName];
  const isGreenScreen = !!shopImage.isGreenScreen && !!backgroundSets.length;
  const isSingleImage = Object.keys(images).length === 1;

  /**
   * Gets the maximum height the canvas can be given
   */
  const getMaxHeight = useCallback(() => {
    let subtrahend = 0;
    if (isMobile && !imageOnlyMode) {
      // If mobile, subtract mobile toolbar, BG slider from total canvas height:
      subtrahend = MOBILE_ELEM_HEIGHTS + (isViewerExpanded ? MOBILE_BG_SLIDER_HEIGHT : 0);
    } else if (!isMobile) {
      // If not mobile, the top toolbar height and the image slider height must be subtracted:
      subtrahend = TOOLBAR_HEIGHT + IMAGE_SLIDER_HEIGHT;
    }

    // Ensure the returned height does not exceed FULL_IMAGE_SIZE
    return Math.min(window.innerHeight - subtrahend, FULL_IMAGE_SIZE);
  }, [isViewerExpanded, imageOnlyMode, isMobile]);

  /**
   * Gets the maximum width the canvas can be given
   */
  const getMaxWidth = useCallback(
    (containerWidth: number, isWindowResize?: boolean) => {
      let maxWidth;

      // for SlideoutBackgroundViewer (SBV), or if mobile (so no side panel elements), set maxWidth to the container's width:
      if (isWindowResize || isMobile || !shouldResizeCanvas) {
        maxWidth = containerWidth + (isMobile ? -MOBILE_WIDTH_GUTTER : 0);
        // Else, since SBV's render is delayed to allow for a transition effect, the maxWidth must
        // pre-emptively have the SBV width added/subtracted depending on whether it has opened or closed
      } else {
        maxWidth = containerWidth + (isViewerExpanded ? -BG_VIEWER_WIDTH : BG_VIEWER_WIDTH);
      }

      // Ensure the returned width does not exceed FULL_IMAGE_SIZE
      return Math.min(maxWidth, FULL_IMAGE_SIZE);
    },
    [isMobile, isViewerExpanded, shouldResizeCanvas],
  );

  /**
   * Paints the active image and background, if present, to their respective canvases
   */
  const calculateCanvasDimensions = useCallback(
    (image: HTMLImageElement | null, isWindowResize?: boolean) => {
      if (!image || !mainContainer.current) {
        return;
      }
      const { clientWidth } = mainContainer.current;

      const isLandscape = image.height < image.width;
      const aspectRatio = isLandscape ? image.width / image.height : image.height / image.width;

      const maxHeight = getMaxHeight();
      const maxWidth = getMaxWidth(clientWidth, isWindowResize);

      let newImageWidth;
      let newImageHeight;

      if (isLandscape) {
        const shouldCalculateByHeight = maxWidth / aspectRatio > maxHeight;
        // If the max view height is less than the height of the image when scaled by max width,
        // Then calculate the image canvas size by the max height:
        if (shouldCalculateByHeight) {
          newImageHeight = maxHeight;
          newImageWidth = Math.floor(newImageHeight * aspectRatio);
        } else {
          newImageWidth = maxWidth;
          newImageHeight = Math.floor(newImageWidth / aspectRatio);
        }
      } else {
        const shouldCalculateByWidth = maxHeight / aspectRatio > maxWidth;
        // If the max view width is less than the width of the image when scaled by max height,
        // Then calculate the image canvas size by the max width:
        if (shouldCalculateByWidth) {
          newImageWidth = maxWidth;
          newImageHeight = Math.floor(newImageWidth * aspectRatio);
        } else {
          newImageHeight = maxHeight;
          newImageWidth = Math.floor(newImageHeight / aspectRatio);
        }
      }

      setHeight(newImageHeight);
      setWidth(newImageWidth);
    },
    [getMaxHeight, getMaxWidth],
  );

  const handleClose = useCallback(() => {
    dispatch(setSelectedImageName(null));
    history.push(`/${key}/photos/${groupID || 'all'}`);
  }, [dispatch, groupID, history, key]);

  const handleNavigate = useCallback(
    (direction: Direction) => {
      onNavigate(direction);
    },
    [onNavigate],
  );

  // Initialize the view for Background
  useEffect(() => {
    // If greenscreen, set the background image element
    const init = async () => {
      calculateCanvasDimensions(imageElem);

      if (!background) {
        if (isViewerExpanded && !isGreenScreen) {
          toggleShowViewer();
        }
      } else {
        const backgroundImage = new Image();
        backgroundImage.src = background.sources.full;
        await new Promise((resolve, reject) => {
          backgroundImage.onload = resolve;
          backgroundImage.onerror = reject;
        });
        setBackgroundElem(backgroundImage);
      }
      setLoading(false);
    };

    init();
  }, [
    background,
    calculateCanvasDimensions,
    imageElem,
    isGreenScreen,
    isViewerExpanded,
    loading,
    toggleShowViewer,
  ]);

  // Re-draws the image when the Background Viewer is toggled
  useEffect(() => {
    if (loading) {
      return;
    }

    calculateCanvasDimensions(imageElem);
  }, [calculateCanvasDimensions, imageElem, imageOnlyMode, isViewerExpanded, loading]);

  // Re-draws the image when the window has been resized
  useOnEvent('resize', () => calculateCanvasDimensions(imageElem, true));

  // Listens for key presses to navigate the carousel using left and right arrow keys
  useOnEvent('keydown', (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      handleNavigate(PREV);
    }
    if (e.key === 'ArrowRight') {
      handleNavigate(NEXT);
    }
    if (e.key === 'Escape') {
      handleClose();
    }
  });

  const handleCopy = () => {
    onCopy();
    toast({ title: 'URL has been copied' });
  };

  // TODO: the show/hide arrow buttons on hover is being trialed at the moment, but is subject to change
  const handleHover = (isHovering: boolean) => {
    if (isHovering) {
      setNavArrowsDisplay('inherit');
    } else {
      setNavArrowsDisplay('none');
    }
  };

  const handleTap = (e: React.TouchEvent) => {
    if (isMobile && e.nativeEvent.touches.length <= 1) {
      toggleImageOnly();
    }
  };

  const renderToolbar = () => (
    <Flex
      align="center"
      direction="row"
      height={`${TOOLBAR_HEIGHT}px`}
      justify="space-between"
      paddingBottom={1}
      paddingTop={2}
      paddingX={3}
      position="relative"
    >
      <ModalCloseButton aria-label={CLOSE_MODAL} onClick={handleClose} />
      <Text color="grey.3" fontSize="sm" textAlign="center" position="absolute" width="100%">
        {intl.formatMessage(
          {
            id: 'activeImage.imageCount',
            defaultMessage: '{activeImagePosition} of {imageListCount}',
          },
          { activeImagePosition: imageIndex + 1, imageListCount },
        )}
      </Text>
      <Flex direction="row" backgroundColor="black" zIndex="1">
        {(!isSingleImage || isMobile) && isGreenScreen && (
          <IconButton
            aria-label="Show Backgrounds"
            borderColor="black"
            icon={<ImImages fontSize="20px" />}
            marginX="1"
            onClick={toggleShowViewer}
            variant={isViewerExpanded ? 'modalLight' : 'modal'}
          />
        )}
        {/* TODO: This button copies the URL for now, but implement "share" ability when criteria is provided */}
        <IconButton
          aria-label="Copy Link"
          icon={<RiShareForwardFill fontSize="24px" />}
          marginX="1"
          onClick={handleCopy}
          variant="modal"
        />
      </Flex>
    </Flex>
  );

  const renderFrame = () => {
    if (loading) {
      return <Spinner color="white" />;
    }
    return (
      <AnimateWrapper
        containerWidth={mainContainer?.current?.clientWidth || 1000}
        direction={direction}
        handleNavigate={handleNavigate}
        height={height}
        scale={1}
        width={width}
      >
        <ActiveImageCanvas
          background={backgroundElem}
          height={height}
          image={imageElem}
          isGreenScreen={isGreenScreen}
          offset={{ x: 0, y: 0 }}
          scale={1}
          width={width}
        />
        {!imageOnlyMode && !!isMobile && (
          <ImageDisplayName
            background={background}
            imageName={shopImage.displayName}
            width="100vw"
          />
        )}
      </AnimateWrapper>
    );
  };

  return (
    <Flex direction="column" flex="auto" justify="center" maxWidth="100%" position="relative">
      {!imageOnlyMode && renderToolbar()}
      <Flex
        height="calc(100% - 55px)"
        justifyContent="center"
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        position="relative"
        ref={mainContainer}
      >
        <Flex
          align="center"
          direction="row"
          height="100%"
          justify={imageIndex === 0 ? 'flex-end' : 'space-between'}
          padding={5}
          position="absolute"
          width="100%"
        >
          {!isMobile && imageIndex > 0 && (
            <ArrowLeftbutton
              _hover={{ background: 'grey.8' }}
              aria-label={NAVIGATE_LEFT}
              background="transparent"
              data-test="active-image-navigate-left"
              display={navArrowsDisplay}
              fontSize="30px"
              onClick={() => handleNavigate(PREV)}
              zIndex={IMAGE_VIEWER_Z_INDEX + 1}
            />
          )}
          {!isMobile && imageIndex + 1 < imageListCount && (
            <ArrowRightButton
              _hover={{ background: 'grey.8' }}
              aria-label={NAVIGATE_RIGHT}
              background="transparent"
              data-test="active-image-navigate-right"
              display={navArrowsDisplay}
              fontSize="30px"
              onClick={() => handleNavigate(NEXT)}
              zIndex={IMAGE_VIEWER_Z_INDEX + 1}
            />
          )}
        </Flex>
        <Flex
          alignItems="center"
          alignSelf="center"
          data-test="active-image-canvas-image"
          height={height}
          justify="center"
          onTouchStart={handleTap}
          position="relative"
          ref={wrapper}
          width={width}
        >
          {renderFrame()}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ActiveImage;
