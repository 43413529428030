import { Box, ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { ProductOption } from '../../../../../../../shop-api-client';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { FREE } from '../../../../../../shared/constants';
import { NONE, NO_THANKS } from '../../../../../../shared/constants/labels.constants';
import { formatCurrency } from '../../../../../../shared/utils';

interface Props {
  option: ProductOption;
  selectedID?: number;
  value?: string;
}

const OptionValue = ({ option, selectedID, value }: Props) => {
  const { currency } = useAppSelector(selectAccount);

  const optionSelection = option.selections.find(s => s.catalogOptionID === selectedID);

  const getSelectedPrice = () => {
    // If no `selectedID`, bail so that the span element is not rendered:
    if (!selectedID) {
      return null;
    }
    // Initialize price as the `option` price:
    let price = option.price;
    // If `selectionPricing` is on, set the price from the chosen selection:
    if (option.type === 'selection' && option.selectionPricing) {
      price = optionSelection?.price || 0;
    }

    // If the price is not `0` format as currency, else display 'Free':
    return price ? `${formatCurrency(price, currency)}` : FREE;
  };

  const getSelectedValue = () => {
    if (!selectedID) {
      // Set the returned text to match the shown opt-out value in the Option component:
      return option.type === 'boolean' ? NO_THANKS : NONE;
    }

    if (option.type === 'text') {
      return value;
    }
    if (option.type === 'boolean') {
      return 'Yes';
    }

    return optionSelection?.name || '';
  };

  const price = getSelectedPrice();

  return (
    <UnorderedList>
      <ListItem>
        <Box as="span">{getSelectedValue()}</Box>
        {!!price && (
          <Box as="span" paddingLeft={2}>
            ({price})
          </Box>
        )}
      </ListItem>
    </UnorderedList>
  );
};

export default OptionValue;
