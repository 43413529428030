import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
}

const RoundedBanner = ({ children, ...rest }: Props) => {
  return (
    <Box
      backgroundColor="blue.1"
      borderRadius={50}
      color="blue.6"
      fontFamily="heading"
      padding={3}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default RoundedBanner;
