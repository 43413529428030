// General
export const IMAGE_BADGE_Z_INDEX = 2; // ImageBadge component - should stack on top of OVERLAY_Z_INDEX
export const OVERLAY_Z_INDEX = 1; // ActiveImageOverlay component
export const Z_INDEX_ONE = 1;
export const Z_INDEX_TWO = 2;

// My Photos
export const CAROUSEL_PANEL_Z_INDEX = 7;
export const CAROUSEL_Z_INDEX = 6;

// Navigation
export const ABOVE_MODAL_Z_INDEX = 1401; // overrides Chakra's popout/overlay components' z-index
export const BANNER_Z_INDEX = 4;
export const MOBILE_CATEGORIES_Z_INDEX = 4;
export const NAVIGATION_Z_INDEX = 5;

// Image Viewer
export const IMAGE_VIEWER_ANIMATION_Z_INDEX = -1;
export const IMAGE_VIEWER_Z_INDEX = 5;
