import { ChakraProps, keyframes } from '@chakra-ui/react';

export const HIDE_SCROLLBAR_STYLES: ChakraProps['css'] = {
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
  },
  '&::-webkit-scrollbar-track': {
    width: '0px',
    height: '0px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'none',
    borderRadius: '0px',
  },
};

export const FADE_IN_KEYFRAMES = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
