import { Button } from '@chakra-ui/button';
import { Box, Flex, Spacer, Text } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopBackground } from '../../../../../shop-api-client';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectInteractions } from '../../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import BackgroundSwatches from '../../../../shared/components/BackgroundSwatches';
import { CAROUSEL_Z_INDEX, FREE } from '../../../../shared/constants';
import { HIDE_SCROLLBAR_STYLES } from '../../../../shared/constants/style.constants';
import { formatCurrency } from '../../../../shared/utils';
import { BG_VIEWER_WIDTH } from '../../constants';
import SlideOutHeader from '../SlideOutHeader';

const VIEWER_BODY_PADDING = 20;

interface Props {
  isExpanded: boolean;
  onSelectBackground(bg: ShopBackground): void;
  setIsResizingCanvas(bool: boolean): void;
  toggleShowViewer(): void;
}

const SlideOutBackgroundViewer = ({
  isExpanded,
  onSelectBackground,
  setIsResizingCanvas,
  toggleShowViewer,
}: Props) => {
  const { backgroundSets } = useSelector(selectPriceSheet);
  const { currency } = useSelector(selectAccount);
  const { selectedBackground } = useSelector(selectInteractions);

  const [isMounted, setIsMounted] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);

  const intl = useIntl();

  useEffect(() => {
    // The isMounted variable is used to delay setting the expanded component width
    // from zero until after the initial render, by using a setTimeout whose duration
    // is that of the transition duration of the ActiveImage. Because isResizingCanvas
    // must be toggled after isMounted, it is delayed, too.
    const resizingTimeout = setTimeout(() => setIsResizingCanvas(false), 200);
    let isMountedTimeout: ReturnType<typeof setTimeout>;

    if (isExpanded) {
      setIsMounted(true);
    } else {
      isMountedTimeout = setTimeout(() => setIsMounted(false), 200);
    }

    return () => {
      if (isMountedTimeout) {
        clearTimeout(isMountedTimeout);
      }
      clearTimeout(resizingTimeout);
    };
  }, [setIsResizingCanvas, isExpanded]);

  const toggleShowThumbnail = () => setShowThumbnail(!showThumbnail);

  const renderBackgroundSets = () =>
    backgroundSets.map(set => (
      <Box
        key={set.id}
        color="grey.1"
        marginTop="20px"
        width={`${BG_VIEWER_WIDTH - VIEWER_BODY_PADDING * 2}px`}
      >
        <Flex marginX="5px">
          <Text fontSize="sm">{set.name}</Text>
          <Spacer />
          <Text fontSize="xs">
            {set.price === 0 ? FREE : `${formatCurrency(set.price, currency)}/pose`}
          </Text>
        </Flex>
        <BackgroundSwatches
          backgrounds={set.backgrounds}
          color="grey.3"
          onSelectBackground={onSelectBackground}
          selectedBackground={selectedBackground}
          showThumbnail={showThumbnail}
        />
      </Box>
    ));

  if (!isMounted && !isExpanded) {
    return null;
  }

  return (
    <Flex justifyContent="end">
      <Flex
        transition="all .2s ease"
        width={isMounted ? `${BG_VIEWER_WIDTH}px` : 0}
        zIndex={CAROUSEL_Z_INDEX}
      >
        <Flex
          alignSelf="stretch"
          background="grey.10"
          borderLeftColor="black"
          borderLeftStyle="solid"
          borderLeftWidth="1px"
          direction="column"
          width="100%"
        >
          <SlideOutHeader toggleShowViewer={toggleShowViewer} />
          <Flex
            css={HIDE_SCROLLBAR_STYLES}
            direction="column"
            overflowX="hidden"
            overflowY="auto"
            padding={`${VIEWER_BODY_PADDING}px`}
          >
            <Button
              color="grey.1"
              data-test="slide-out-background-viewer-hide-show-pose-button"
              fontSize="xs"
              leftIcon={showThumbnail ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              lineHeight="2"
              onClick={toggleShowThumbnail}
              variant="borderSmall"
            >
              {showThumbnail
                ? intl.formatMessage({
                    id: 'backgroundViewer.hidePoses',
                    defaultMessage: 'Hide Poses',
                  })
                : intl.formatMessage({
                    id: 'backgroundViewer.showPoses',
                    defaultMessage: 'Show Poses',
                  })}
            </Button>
            {renderBackgroundSets()}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SlideOutBackgroundViewer;
