import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { ShopImage, ShopPackage, ShopProduct } from '../../../shop-api-client';
import {
  BaseCartItem,
  CartImageOption,
  CartPackage,
  CartProduct,
  VisitWithCart,
} from '../../../shop-api-client/models/Cart';
import {
  countCartItems,
  getCartBackgroundsKeyedByImage,
  getImagesOnPackage,
  getImagesOnProduct,
  isImageInCartItem,
} from '../../features/Carts/utils';
import { PACKAGE_TYPES, PRODUCT_TYPES } from '../../shared/constants/zTable.constants';
import {
  ImageAndBackground,
  UniqueImage,
  UniqueImageAndBackgroundSet,
} from '../../shared/types/image';
import { sort } from '../../shared/utils';
import { RootState } from '../store';
import { selectPriceSheet } from './priceSheet.selectors';

export const selectCartSlice = (state: RootState) => state.cart;
export const selectCartMap = (state: RootState) => state.cart.cartMap;
export const selectCartFinancialsMap = (state: RootState) => state.cart.cartFinancials;
export const selectCartErrors = (state: RootState) => state.cart.cartErrors;
export const selectCartDiscountsMap = (state: RootState) => state.cart.cartDiscounts;

// Select a single cart (current visitKey cart if none is provided)
export const selectCart = createSelector(
  [
    (state: RootState) => state.cart.cartMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, visitKey?: string) => visitKey,
  ],
  (cartMap, currentVisitKey, visitKey) => {
    if (!cartMap[visitKey || currentVisitKey!]) {
      return {
        cartPackages: [],
        cartProducts: [],
        cartImageOptions: [],
        shopFavorites: [],
      } as VisitWithCart;
    }
    return cartMap[visitKey || currentVisitKey!];
  },
);

export const selectCartItem = createSelector(
  [
    (state: RootState) => state.visitor.currentVisitKey,
    (state: RootState) => state.cart.cartMap,
    (_, cartItem: Partial<BaseCartItem> | null) => cartItem,
    (_, __, visitKey?: string) => visitKey,
  ],
  (currentVisitKey, carts, cartItem, visitKey) => {
    if (!cartItem?.id || !cartItem.type) {
      return;
    }

    const { cartProducts, cartPackages } = carts[visitKey || currentVisitKey!];
    return [...cartProducts, ...cartPackages].find(
      item => item.id === cartItem.id && item.type === cartItem.type,
    );
  },
);

// Select count of all products for an array of visitkeys
export const selectCartItemCount = createSelector(
  [(state: RootState) => state.cart.cartMap, (_, visitKeys?: string[]) => visitKeys],
  (cartMap, visitKeys) => {
    let totalItemCount = 0;
    if (!visitKeys) {
      return totalItemCount;
    }
    for (const visitKey of visitKeys) {
      const cart = cartMap[visitKey];

      if (!cart) {
        break;
      }
      totalItemCount += countCartItems(cart);
    }
    return totalItemCount;
  },
);

// select total count of all items across all carts
export const selectAllCartsItemCount = createSelector(
  (state: RootState) => state.cart.cartMap,
  cartMap => {
    let totalItemCount = 0;
    for (const visitKey of Object.keys(cartMap)) {
      const cart = cartMap[visitKey];
      if (!cart) {
        break;
      }
      totalItemCount += countCartItems(cart);
    }
    return totalItemCount;
  },
);

/**
 * Select shop products and packages for all items in all carts
 */
export const selectShopProductsInCarts = createSelector(
  [
    (state: RootState) => state.cart.cartMap,
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.gallery.priceSheetMap,
  ],
  (cartMap, galleryMap, priceSheetMap) => {
    return Object.entries(cartMap).reduce<Record<number, ShopProduct | ShopPackage>>(
      (result, [visitKey, { cartProducts, cartPackages }]) => {
        const gallery = galleryMap[visitKey];
        if (!gallery?.priceSheetID) {
          return result;
        }
        const { products } = priceSheetMap[gallery.priceSheetID];

        for (const { priceSheetItemID } of [...cartProducts, ...cartPackages]) {
          if (result[priceSheetItemID]) {
            continue;
          }
          const shopProduct = products[priceSheetItemID];
          result[priceSheetItemID] = shopProduct;
        }

        return result;
      },
      {},
    );
  },
);

// Select unique images on a cartProduct
export const selectUniqueImages = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, item?: CartProduct) => item,
    (_, __, visitKey?: string) => visitKey,
  ],
  (galleryMap, currentVisitKey, item, visitKey) => {
    const gallery = galleryMap[visitKey || currentVisitKey!];
    const images = gallery?.images;

    if (!images || !item) {
      return [];
    }

    const uniqueImages = new Set<string>();

    if (item.type === 'collection' || item.type === 'imageDownload') {
      // Because our architecture requires storing the image display name for collectionImages,
      // this map is used to map the image display name to the unique internal name
      const displayNameMap = Object.values(images).reduce<Record<string, string>>((map, image) => {
        map[image.displayName] = image.internalName;
        return map;
      }, {});

      for (const image of item.collectionImages) {
        if (!image.displayName) {
          continue;
        }
        uniqueImages.add(
          JSON.stringify({
            image: displayNameMap[image.displayName],
            backgroundID: image.backgroundID,
            isGreenScreen: images[displayNameMap[image.displayName]]?.isGreenScreen,
            skipBackgroundSelection:
              images[displayNameMap[image.displayName]]?.skipBackgroundSelection,
          }),
        );
      }
    } else if (item.type === 'product') {
      for (const node of item.nodes) {
        if (node.type !== 'image' || !node.imageInternalName) {
          continue;
        }
        uniqueImages.add(
          JSON.stringify({
            image: node.imageInternalName,
            backgroundID: node.backgroundID,
            isGreenScreen: images[node.imageInternalName]?.isGreenScreen,
            skipBackgroundSelection: images[node.imageInternalName]?.skipBackgroundSelection,
          }),
        );
      }
    }

    return Array.from(uniqueImages).map(i => JSON.parse(i)) as UniqueImage[];
  },
);

// Selects map for which images have which image options
// because selectCart takes an optional param, you can pass in a param here for visitKey
export const selectImageToImageOptionLookup = createSelector(selectCart, cart =>
  cart.cartImageOptions.reduce<Record<string, CartImageOption[]>>((map, option) => {
    if (option.type !== 'image') {
      return map;
    }

    option.images.forEach(image => {
      map[image.imageName] = [...(map[image.imageName] || []), option];
    });

    return map;
  }, {}),
);

// select all images from cart items
export const selectCartImageMap = createSelector(selectCart, cart => {
  return getCartBackgroundsKeyedByImage(cart);
});

/**
 * selects images unique to a cart item
 */
export const selectUniqueImagesOnCartItem = createSelector(
  [selectCart, (_, __, selectedItemID?: number) => selectedItemID],
  (cart, selectedItemID) => {
    const allItems = [...cart.cartProducts, ...cart.cartPackages];

    const allImagesSet: Set<string> = new Set();
    const otherImagesSet: Set<string> = new Set();

    const updateSets = (imageName: string, itemID: number) => {
      allImagesSet.add(imageName);

      if (itemID !== selectedItemID) {
        otherImagesSet.add(imageName);
      }
    };

    for (const item of allItems) {
      if (Object.values(PRODUCT_TYPES).includes(item.type)) {
        const productImageMap = getImagesOnProduct(item as CartProduct);
        Object.keys(productImageMap).forEach(i => updateSets(i, item.id));
      }
      if (Object.values(PACKAGE_TYPES).includes(item.type)) {
        const packageImageMap = getImagesOnPackage(item as CartPackage);
        Object.keys(packageImageMap).forEach(i => updateSets(i, item.id));
      }
    }

    // get unique images on selected item by comparing all vs other
    // images in all but not in other are unique to the selected item

    const uniqueImages = Array.from(allImagesSet).filter(ai => !otherImagesSet.has(ai));

    return uniqueImages;
  },
);

/** Select all cart items that have a specific image */
export const selectCartItemsWithImage = createSelector(
  [
    (state: RootState) => state.cart.cartMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, shopImage?: ShopImage) => shopImage,
    (_, __, visitKey?: string) => visitKey,
  ],
  (cartMap, currentVisitKey, shopImage, visitKey) => {
    const cart = cartMap[visitKey || currentVisitKey!];
    if (!shopImage || !cart) {
      return [];
    }
    return [...cart.cartProducts, ...cart.cartPackages].filter(item =>
      isImageInCartItem(shopImage, item),
    );
  },
);

/**
 * @returns last 5 used unique cart images
 */
export const selectRecentlyUsedCartImages = createSelector(selectCart, cart => {
  const allItemsByDate = [...cart.cartProducts, ...cart.cartPackages].sort((a, b) =>
    sort(a.createdAt, b.createdAt),
  );

  const map: Record<string, ImageAndBackground> = {};

  const mapLoop = (imageMap: UniqueImageAndBackgroundSet) => {
    for (const [imageName, backgroundSet] of Object.entries(imageMap)) {
      const backgrounds = Array.from(backgroundSet);

      if (!backgrounds.length) {
        map[`${imageName}-${undefined}`] = { imageName };
      } else {
        for (const backgroundID of backgrounds) {
          map[`${imageName}-${backgroundID}`] = { imageName, backgroundID };
        }
      }
    }
  };

  for (const item of allItemsByDate) {
    if (Object.values(PRODUCT_TYPES).includes(item.type)) {
      const productImageMap = getImagesOnProduct(item as CartProduct);
      mapLoop(productImageMap);
    }
    if (Object.values(PACKAGE_TYPES).includes(item.type)) {
      const packageImageMap = getImagesOnPackage(item as CartPackage);
      mapLoop(packageImageMap);
    }

    if (Object.keys(map).length >= 5) {
      return Object.values(map);
    }
  }

  return Object.values(map);
});

export const selectUnlockedAndAddedLockedItems = createSelector(
  selectPriceSheet,
  selectCartSlice,
  ({ offersStatusMap, productCategoryMap, products }, { justAdded }) => {
    const addedMap = keyBy(justAdded.other, 'priceSheetItemID');
    return Object.entries(offersStatusMap).reduce<(ShopPackage | ShopProduct)[]>(
      (result, [categoryID, { isLocked }]) => {
        let categoryItemIDs = productCategoryMap[categoryID];

        if (isLocked) {
          categoryItemIDs = categoryItemIDs.filter(id => addedMap[id]);

          if (categoryItemIDs.length < 1) {
            return result;
          }
        }

        const categoryItems = categoryItemIDs.map(id => products[id]);
        result.push(...categoryItems);

        return result;
      },
      [],
    );
  },
);
