import { Flex } from '@chakra-ui/react';
import { CatalogProductNode } from 'iq-product-render';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useLocation, useParams } from 'react-router-dom';
import { ShopPackage } from '../../../../../shop-api-client';
import { selectCart } from '../../../../redux/selectors/cart.selectors';
import {
  selectAddedUnits,
  selectConfiguration,
} from '../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { setEditPackage } from '../../../../redux/slices/configurations.slice';
import { toggleShowNav } from '../../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../../redux/store';
import { getRendererProducts } from '../../../../redux/thunks/catalog.thunks';
import { initializeEditItem } from '../../../../redux/thunks/configuration.thunks';
import ProtectedRoute from '../../../../shared/components/ProtectedRoute';
import useProductFontLoader from '../../../../shared/hooks/useProductFontLoader';
import useSearchParams from '../../../../shared/hooks/useSearchParams';
import { Params } from '../../../../shared/types/router';
import useConfigurationRouter from '../useConfigurationRouter';
import { requiresPerProductBG } from '../utils';
import BuildPackage from './Steps/BuildPackage';
import Background from './Steps/Background/Background';
import Customize from './Steps/Customize';
import ImageAddOns from './Steps/ImageAddOns';
import Summary from './Steps/Summary';
import WizardNavigation from './WizardNavigation';
import useIsMobile from '../../../../shared/hooks/useIsMobile';
import { selectAccount } from '../../../../redux/selectors/account.selectors';

// TODO: Determine PackageWizard behavior for the case where there are no configurable items

const PackageWizard = () => {
  const addedUnits = useSelector(selectAddedUnits);
  const { cartPackages, cartImageOptions } = useSelector(selectCart);
  const { completedPkgItems, editPackage } = useSelector(selectConfiguration);
  const { isGreenScreen, isPreOrder } = useSelector(selectGallery);
  const { accountID } = useSelector(selectAccount);
  const { backgroundSets, imageOptionMap, products, preOrderBackgroundSelectionType } =
    useSelector(selectPriceSheet);

  const [isLoading, setIsLoading] = useState(true);

  const [areFontsLoaded, setFonts] = useProductFontLoader();
  const { goToCart, goToDetails, routeToWizardStep } = useConfigurationRouter();
  const { getParamsValue } = useSearchParams();
  const { packageID } = useParams<Params>();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  // Search params values
  const bgFromParams = getParamsValue('bg');
  const cartPackageID = getParamsValue('cartPackageID');
  const imageFromParams = getParamsValue('image');

  const requiresBG = requiresPerProductBG(
    'package',
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );

  const handleBackClick = useCallback(() => {
    if (cartPackageID) {
      goToCart();
    } else {
      goToDetails(products[packageID].categoryID);
    }
  }, [cartPackageID, goToCart, goToDetails, packageID, products]);

  // WIP: This callback will currently always route to the first step on initialization
  // until we support incrementally adding package products to db
  const routeToDestination = useCallback(
    (shopPkg: ShopPackage, isCartItem: boolean) => {
      const completedCount = Object.keys(completedPkgItems).length;
      const reqOptionCount = Object.values(imageOptionMap).filter(
        o => o.requirementType === 'required',
      ).length;
      const doneOptionCount =
        reqOptionCount > 0
          ? cartImageOptions.filter(
              option => imageOptionMap[option.priceSheetOptionID].requirementType === 'required',
            ).length
          : 0;

      if (requiresBG && !isCartItem) {
        if (!pathname.includes('/backgrounds')) {
          routeToWizardStep('background');
        }
      } else if (shopPkg.type === 'package-byop' && shopPkg.minUnits > addedUnits) {
        if (!pathname.includes('/byop-selection')) {
          routeToWizardStep('byop');
        }
      } else if (shopPkg.availableProducts.length !== completedCount) {
        if (!pathname.includes('/customize')) {
          // UX Note: On desktop, we want to route the visitor to the first configurable item. However,
          // for mobile, we initialize on the "CustomizeOverview" layout:
          const firstItem = !isMobile ? 1 : undefined;
          routeToWizardStep('customize', firstItem);
        }
      } else if (reqOptionCount !== doneOptionCount) {
        if (!pathname.includes('/image-options')) {
          routeToWizardStep('imageOption');
        }
      }
    },
    [
      addedUnits,
      cartImageOptions,
      completedPkgItems,
      imageOptionMap,
      isMobile,
      pathname,
      requiresBG,
      routeToWizardStep,
    ],
  );

  useEffect(() => {
    dispatch(toggleShowNav(false));
    return () => {
      dispatch(toggleShowNav(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && editPackage) {
      return;
    }

    const init = async () => {
      const shopPkg = products[packageID] as ShopPackage;
      const cartPackage = cartPackageID
        ? cartPackages.find(p => p.id === parseInt(cartPackageID))
        : undefined;

      // If a ShopPackage or cartPackage was not found, then the respective param
      // was not valid, so route away from configuration:
      if (!shopPkg || (cartPackageID && !cartPackage)) {
        handleBackClick();
        return;
      }

      // If the route has changed with a different `packageID`, update editItem to match:
      if (editPackage?.priceSheetItemID !== parseInt(packageID!)) {
        dispatch(
          initializeEditItem({
            shopItem: shopPkg,
            cartItem: cartPackage,
            imageName: imageFromParams,
            backgroundID: parseInt(bgFromParams),
          }),
        );
        const { payload } = await dispatch(getRendererProducts([shopPkg]));

        if (!payload) {
          return;
        }

        const allNodes = payload.reduce<CatalogProductNode[]>(
          (result, product) => [...result, ...product.nodes],
          [],
        );
        setFonts(allNodes);
      }

      setIsLoading(false);
      routeToDestination(shopPkg, !!cartPackage);
    };

    init();
  }, [
    bgFromParams,
    cartPackageID,
    cartPackages,
    dispatch,
    editPackage,
    handleBackClick,
    imageFromParams,
    isLoading,
    packageID,
    products,
    routeToDestination,
    setFonts,
  ]);

  // Google Analytics view_item event.
  useEffect(() => {
    window.gtag('event', 'view_item', {
      items: [
        {
          item_id: packageID,
          affiliation: accountID,
        },
      ],
    });
  }, [packageID, accountID]);

  useEffect(() => {
    return () => {
      dispatch(setEditPackage(null));
    };
  }, [dispatch]);

  if (!editPackage || !areFontsLoaded) {
    return null;
  }

  return (
    <Flex direction="column" width="100%" flex={1}>
      <WizardNavigation onBackClick={handleBackClick} />
      <Flex direction="column" flex={1}>
        <Switch>
          <ProtectedRoute
            path="/:key/configure/wizard/:packageID/backgrounds"
            component={Background}
          />
          <ProtectedRoute
            path="/:key/configure/wizard/:packageID/byop-selection"
            component={BuildPackage}
          />
          <ProtectedRoute
            path="/:key/configure/wizard/:packageID/customize/:editSubItemID?"
            component={Customize}
          />
          <ProtectedRoute
            path="/:key/configure/wizard/:packageID/image-options"
            component={ImageAddOns}
          />
          <ProtectedRoute path="/:key/configure/wizard/:packageID/summary" component={Summary} />
        </Switch>
      </Flex>
    </Flex>
  );
};

export default PackageWizard;
