import { Box, Button, Flex, Grid, GridItem, Heading, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { toggleShowFindGalleryModal } from '../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../redux/store';
import Logo from '../../../shared/components/Logo';
import { SHOP } from '../../../shared/constants';
import CartButton from '../CartButton';
import { NAV_GRID_HEIGHT, NAV_TAB_HEIGHT, PREORDER_NAV_GUTTER } from '../constants';
import NavigationTab from '../NavigationTab';
import DesktopDropdown from './DesktopDropdown';

const DesktopNavigation = () => {
  const { company, logoImage } = useSelector(selectAccount);
  const { isPreOrder, title, type, yearbookPoses } = useSelector(selectGallery);
  const { showFindGalleryModal } = useSelector(selectInteractions);
  const { yearbookSelections } = useSelector(selectGallery);
  const currentVisitKey = useSelector(selectCurrentVisitKey);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const isYBPoses =
    yearbookPoses === 1
      ? yearbookSelections?.yearbookSelection1
      : yearbookSelections?.yearbookSelection1 && yearbookSelections?.yearbookSelection2;

  const isCheckout = pathname.includes('checkout');

  const myPhotosLabel = intl.formatMessage({
    id: 'navigation.myPhotos',
    defaultMessage: 'My Photos',
  });
  const chooseYearbookLabel = intl.formatMessage({
    id: 'navigation.yearbook',
    defaultMessage: 'Choose Yearbook Pose',
  });
  const viewAnotherGallery = intl.formatMessage({
    id: 'navigation.viewAnotherGallery',
    defaultMessage: 'View Another Gallery',
  });

  const handleOnClick = () => {
    window.scrollTo(0, 0);
    dispatch(toggleShowFindGalleryModal(!showFindGalleryModal));
  };

  const renderNavigationTabs = () => (
    <>
      {!isPreOrder && (
        <NavigationTab
          data-test="desktop-nav-my-photos"
          isActive={pathname.includes('/photos')}
          label={myPhotosLabel}
          path={`/${currentVisitKey}/photos${type === 'subject' ? '/all' : ''}`}
        />
      )}
      <NavigationTab
        data-test="desktop-nav-shop"
        isActive={pathname.includes('/shop')}
        label={SHOP}
        path={`/${currentVisitKey}/shop/all`}
      />
      {!isPreOrder && !!yearbookPoses && (
        <NavigationTab
          data-test="desktop-nav-choose-yearbook"
          isActive={pathname.includes('/yearbook')}
          label={chooseYearbookLabel}
          notificationColor="error"
          notificationContent={
            isYBPoses ? null : (
              <Icon
                as={FaExclamation}
                data-test="desktop-nav-choose-yearbook-alert"
                fontSize="0.75em"
              />
            )
          }
          path={`/${currentVisitKey}/yearbook`}
        />
      )}
    </>
  );

  return (
    <Box
      borderBottomColor="grey.2"
      borderBottomStyle="solid"
      borderBottomWidth={showFindGalleryModal ? 0 : '1px'}
    >
      <Grid
        height={`${NAV_GRID_HEIGHT}px`}
        marginBottom={isCheckout ? `${PREORDER_NAV_GUTTER}px` : 0}
        paddingX={{ md: '5px', lg: '20px' }}
        templateColumns="repeat(8, 1fr)"
      >
        <GridItem colSpan={3} paddingTop="10px">
          <Heading margin="20px" size="sm" data-test="navigation-title">
            {title}
          </Heading>
        </GridItem>
        <GridItem
          colSpan={2}
          margin="auto"
          paddingRight={showFindGalleryModal ? '15px' : undefined}
        >
          <Logo
            fallbackText={company}
            imageSrc={logoImage}
            margin="auto"
            marginTop="15px"
            maxHeight="100px"
            maxWidth="300px"
            width="100%"
          />
        </GridItem>
        <GridItem
          colSpan={3}
          marginRight={showFindGalleryModal ? '27px' : '12px'}
          marginTop="18px"
          textAlign="right"
        >
          {!isCheckout && (
            <>
              <Button
                _focusVisible={{ '&:focus': 'none' }}
                data-test="desktop-nav-switch-gallery"
                marginRight="28px"
                minWidth="35px"
                onClick={handleOnClick}
                paddingX="2px"
                variant="nav"
              >
                {viewAnotherGallery}
              </Button>

              <DesktopDropdown />
            </>
          )}
          <CartButton data-test="desktop-nav-cart-button" />
        </GridItem>
      </Grid>
      {!isCheckout && !showFindGalleryModal && (
        <Flex
          alignItems="flex-end"
          direction="row"
          fontSize="18px"
          height={`${NAV_TAB_HEIGHT}px`}
          justifyContent="center"
        >
          {renderNavigationTabs()}
        </Flex>
      )}
    </Box>
  );
};

export default DesktopNavigation;
