import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../shared/components/ProtectedRoute';
import AvailableGalleries from './AvailableGalleries';
import FindGallery from './FindGallery';
import GalleryLogin from './GalleryLogin';

const MultiGallery = () => (
  <Switch>
    <ProtectedRoute path={`/:key/galleries/:nextKey/login`} component={GalleryLogin} />
    <ProtectedRoute path={`/:key/galleries/available`} component={AvailableGalleries} />
    <ProtectedRoute path={`/:key/galleries/find`} component={FindGallery} />
  </Switch>
);

export default MultiGallery;
