import { CatalogProductNode, TextNode } from 'iq-product-render';
import {
  CartSettings,
  GalleryType,
  PriceSheetOption,
  ProductOption,
  ShopBackground,
  ShopBackgroundSet,
  ShopImage,
  ShopPackage,
  ShopProduct,
} from '../../../shop-api-client';
import {
  CartFinancials,
  CartImageNode,
  CartImageOption,
  CartNode,
  CartPackage,
  CartProduct,
  CartProductReq,
  CartTextNode,
  CreateCartPackageReq,
  VisitWithCart,
} from '../../../shop-api-client/models/Cart';
import { ShopProductNodes } from '../../../shop-api-client/models/ShopProductNodes';
import { Gallery, GalleryMap } from '../../redux/slices/gallery.slice';
import { UniqueImageAndBackgroundSet } from '../../shared/types/image';
import { getUniqueImages, roundCurrency } from '../../shared/utils';
import { getNodeMapForCatalogProduct } from '../Products/utils';

/**
 * Used for convenient bundling of data required for financial calculations on the FE
 */
export interface CartFinancialsWithGallery extends CartFinancials {
  cartSettings: CartSettings;
  digitalDownloadSubtotal: number;
  gallery: Gallery;
}

export const getAllBackgroundFees = (
  cart: VisitWithCart,
  shopItems: Record<string, ShopProduct | ShopPackage>,
  bgSets: ShopBackgroundSet[],
  calculatedBGs: Record<string, boolean> = {},
) => {
  const { cartPackages, cartProducts } = cart;

  const bgSetsMap: Record<string, ShopBackgroundSet> = {};
  const bgToSetMap: Record<string, number> = {};

  for (const set of bgSets) {
    bgSetsMap[set.id] = set;
    for (const background of set.backgrounds) {
      bgToSetMap[background.id] = set.id;
    }
  }

  return [...cartPackages, ...cartProducts].reduce(
    (fees, item) =>
      (fees += getBackgroundFees(
        item,
        shopItems[item.priceSheetItemID],
        bgSetsMap,
        bgToSetMap,
        calculatedBGs,
      )),
    0,
  );
};

export const getBackgroundFees = (
  editItem: CartProductReq | CreateCartPackageReq | null,
  shopItem: ShopPackage | ShopProduct,
  backgroundSetsMap: Record<string, ShopBackgroundSet>,
  backgroundToSetMap: Record<string, number>,
  calculatedBGs: Record<string, boolean> = {},
): number => {
  if (!editItem) {
    return 0;
  }
  if (editItem.type === 'buildYourOwn' || editItem.type === 'standard') {
    const pkgItemMap = (shopItem as ShopPackage).availableProducts.reduce<
      Record<string, ShopProduct>
    >((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    return editItem.products.reduce((total, product) => {
      const productBGFees = getBackgroundFees(
        product,
        pkgItemMap[product.id!],
        backgroundSetsMap,
        backgroundToSetMap,
        calculatedBGs,
      );
      return total + productBGFees;
    }, 0);
  }
  const bgPriceMap: Record<string, number> = {};
  if (editItem.type === 'product') {
    editItem.nodes?.forEach(n => {
      if (n.type === 'image' && n.backgroundID && !calculatedBGs[n.backgroundID]) {
        const backgroundSetID = backgroundToSetMap[n.backgroundID];
        bgPriceMap[n.backgroundID] = backgroundSetsMap[backgroundSetID]?.price || 0;
        calculatedBGs[n.backgroundID] = true;
      }
    });
  } else if (editItem.type === 'collection' || editItem.type === 'imageDownload') {
    editItem.collectionImages?.forEach(img => {
      if (img.backgroundID && !calculatedBGs[img.backgroundID]) {
        const backgroundSetID = backgroundToSetMap[img.backgroundID];
        bgPriceMap[img.backgroundID] = backgroundSetsMap[backgroundSetID]?.price || 0;
        calculatedBGs[img.backgroundID] = true;
      }
    });
  }

  return Object.values(bgPriceMap).reduce((sum, price) => sum + price, 0);
};

export const getCartBackgroundsKeyedByImage = (cart: VisitWithCart) => {
  return [...cart.cartProducts, ...cart.cartPackages].reduce<UniqueImageAndBackgroundSet>(
    (result, item) => {
      const images =
        item.type === 'buildYourOwn' || item.type === 'standard'
          ? getImagesOnPackage(item)
          : getImagesOnProduct(item);
      for (const [imageName, backgroundSet] of Object.entries(images)) {
        if (!result[imageName]) {
          result[imageName] = new Set();
        }
        for (const background of backgroundSet) {
          result[imageName].add(background);
        }
      }

      return result;
    },
    {},
  );
};

/**
 * @returns an array of cart items that are found in the given `categoryItemIDs` and
 * that are not in the `excluded` list
 */
export const getCartItemsFromCategory = (
  cart: VisitWithCart,
  categoryItemIDs: number[],
  excluded: (ShopProduct | ShopPackage)[] = [],
) => {
  const { cartPackages, cartProducts } = cart;

  const categoryItemIDMap = categoryItemIDs.reduce<Record<string, boolean>>((map, id) => {
    map[id] = true;
    return map;
  }, {});

  const excludedIDMap = excluded.reduce<Record<string, boolean>>((map, item) => {
    map[item.id] = true;
    return map;
  }, {});

  return [...cartPackages, ...cartProducts].reduce<(CartPackage | CartProduct)[]>((arr, item) => {
    // If the item belongs to the given category and was not on the excluded list, push to array
    if (categoryItemIDMap[item.priceSheetItemID] && !excludedIDMap[item.priceSheetItemID]) {
      arr.push(item);
    }
    return arr;
  }, []);
};

export const getCartsByPriceSheetID = (
  priceSheetID: number,
  cartMap: Record<string, VisitWithCart>,
  galleryMap: GalleryMap,
) =>
  Object.entries(galleryMap).reduce<VisitWithCart[]>((result, [visitKey, gallery]) => {
    const cart = cartMap[visitKey];

    if (cart && gallery.priceSheetID === priceSheetID) {
      result.push(cart);
    }
    return result;
  }, []);

/**
 * @returns the total for packages and products from a single cart. Optional parameter `excluded`
 * is used to omit cart items from the total.
 */
export const getCartItemsSubtotalWithExclusions = (
  { cartPackages, cartProducts }: VisitWithCart,
  products: Record<string, ShopProduct | ShopPackage>,
  images: Record<string, ShopImage>,
  galleryType: GalleryType,
  productNodeMap: ShopProductNodes,
  excluded: (ShopProduct | ShopPackage)[] = [],
) => {
  const excludedMap = excluded.reduce<Record<string, ShopProduct | ShopPackage>>((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  return [...cartPackages, ...cartProducts].reduce((total, item) => {
    if (excludedMap[item.priceSheetItemID]) {
      return total;
    }
    const itemTotal = getCartItemSubtotal(item, products, images, galleryType, productNodeMap);
    return (total += itemTotal);
  }, 0);
};

export const getCartItemSubtotal = (
  item: CartPackage | CartProduct,
  products: Record<string, ShopProduct | ShopPackage>,
  images: Record<string, ShopImage>,
  galleryType: GalleryType,
  productNodeMap: ShopProductNodes,
) => {
  const shopItem = products[item.priceSheetItemID];
  let packageSurchageFees = 0;

  if (item.type === 'buildYourOwn' || item.type === 'standard') {
    packageSurchageFees =
      getPackageSurcharge(item, shopItem as ShopPackage, images, galleryType, productNodeMap) || 0;
  }

  const optionFees = getProductOptionFees(item, shopItem);
  return (shopItem.price + optionFees + packageSurchageFees) * item.quantity;
};

export const getImageOptionFees = (
  cartImageOptions: CartImageOption[],
  imageOptionMap: Record<string, PriceSheetOption>,
) => {
  let fees = 0;

  for (const option of cartImageOptions) {
    const priceSheetOption = imageOptionMap[option.priceSheetOptionID];
    const quantity = option.images.length || 1;
    const optionPrice = getOptionPrice(priceSheetOption, option.optionID);

    fees += optionPrice * quantity;
  }

  return roundCurrency(fees);
};

export const getOptionPrice = (option: PriceSheetOption | ProductOption, selectionID?: number) => {
  const selection = option.selections.find(s => s.catalogOptionID === selectionID);
  if (option.type === 'boolean' || option.type === 'text') {
    return option.price;
  }
  return selection && option.selectionPricing ? selection.price : option.price;
};

export const getProductOptionFees = (
  editItem: CartProductReq | CreateCartPackageReq | null,
  shopItem: ShopPackage | ShopProduct,
): number => {
  if (!editItem) {
    return 0;
  }

  if (editItem.type === 'buildYourOwn' || editItem.type === 'standard') {
    const pkgItemMap = (shopItem as ShopPackage).availableProducts.reduce<
      Record<string, ShopProduct>
    >((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    return editItem.products.reduce((total, product) => {
      const fees = getProductOptionFees(product, pkgItemMap[product.priceSheetItemID]);
      return total + fees;
    }, 0);
  }

  const shopOptionMap = (shopItem as ShopProduct).options.reduce<Record<string, ProductOption>>(
    (map, option) => {
      map[option.catalogOptionGroupID] = option;
      return map;
    },
    {},
  );

  return (editItem.options || []).reduce((total, option) => {
    const shopOption = shopOptionMap[option.optionGroupID];
    return total + getOptionPrice(shopOption, option.optionID);
  }, 0);
};

export const getPackageSurcharge = (
  cartPackage: CreateCartPackageReq,
  shopPackage: ShopPackage,
  images: Record<string, ShopImage>,
  galleryType: GalleryType,
  productNodeMap: ShopProductNodes,
) => {
  const { allowAdditionalPoses, additionalPoseFee, additionalPoseFeeType, posesIncluded } =
    shopPackage;
  const hasAdditionalPoseFee = ['perImage', 'oneTime'].includes(additionalPoseFeeType);

  if (allowAdditionalPoses && hasAdditionalPoseFee) {
    const uniqueImages = getUniqueImages(
      cartPackage,
      shopPackage,
      images,
      galleryType,
      productNodeMap,
    );

    if (uniqueImages.length > posesIncluded) {
      const additionalCount = uniqueImages.length - posesIncluded;
      if (additionalPoseFeeType === 'perImage') {
        return additionalCount * additionalPoseFee;
      } else if (additionalPoseFeeType === 'oneTime') {
        return additionalPoseFee;
      }
    }
  }
};

/**
 * @returns the subtotal for a single cart, including cart items with options, image options,
 * and background fees. Optional parameter `excluded` is used to omit cart items from the total.
 */
export const getSubtotalWithExclusions = (
  cart: VisitWithCart,
  products: Record<string, ShopProduct | ShopPackage>,
  imageOptionMap: Record<string, PriceSheetOption>,
  backgroundSets: ShopBackgroundSet[],
  images: Record<string, ShopImage>,
  galleryType: GalleryType,
  productNodeMap: ShopProductNodes,
  excluded: (ShopProduct | ShopPackage)[] = [],
) => {
  const cartItemsSubtotal = getCartItemsSubtotalWithExclusions(
    cart,
    products,
    images,
    galleryType,
    productNodeMap,
    excluded,
  );
  const backgroundFees = getAllBackgroundFees(cart, products, backgroundSets);
  const imageOptionFees = getImageOptionFees(cart.cartImageOptions, imageOptionMap);

  return roundCurrency(cartItemsSubtotal + backgroundFees + imageOptionFees);
};

export const getSubtotal = ({
  backgroundFees,
  imageOptionFees,
  orderOptionFees,
  subtotal,
}: Pick<CartFinancials, 'backgroundFees' | 'imageOptionFees' | 'orderOptionFees' | 'subtotal'>) => {
  return subtotal + orderOptionFees + imageOptionFees + backgroundFees;
};

export const getShipping = (cartFinancials: CartFinancialsWithGallery[]) => {
  const cartSettings = { shipping: 0 } as CartSettings;

  return cartFinancials.reduce((shippingSettings, financials) => {
    if (
      financials.subtotal > financials.digitalDownloadSubtotal &&
      shippingSettings.shipping < financials.cartSettings.shipping &&
      financials.cartSettings.shipmentType !== 'pickup'
    ) {
      return financials.cartSettings;
    }

    return shippingSettings;
  }, cartSettings);
};

export const getHandling = (
  { gallery, cartSettings, ...financials }: CartFinancialsWithGallery,
  fixedHandlingGallerySet: Set<number>,
) => {
  let handling = 0;

  if (cartSettings.handlingRateType === 'percent') {
    const subtotal = getSubtotal(financials);
    handling = Math.max(roundCurrency(cartSettings.handling * subtotal), 0);
  } else if (!fixedHandlingGallerySet.has(gallery.galleryID)) {
    fixedHandlingGallerySet.add(gallery.galleryID);
    handling = cartSettings.handling;
  }

  return roundCurrency(handling);
};

export const getTaxes = (
  cartFinancials: CartFinancialsWithGallery[],
  shippingSettings: CartSettings,
) => {
  let taxTotal = 0;
  let digitalDownloadTaxTotal = 0;

  for (const financials of cartFinancials) {
    let tax = 0;
    let taxable = 0;

    const taxSetting = financials.cartSettings.tax;
    const taxDigitalDownloadSetting = financials.cartSettings.taxDigitalDownload;
    const subtotal = getSubtotal(financials);

    taxable = subtotal - financials.digitalDownloadSubtotal;
    if (taxable > 0) {
      tax = roundCurrency(taxable * taxSetting);
    }

    const digitalDownloadTax = roundCurrency(
      financials.digitalDownloadSubtotal * taxDigitalDownloadSetting,
    );
    digitalDownloadTaxTotal = roundCurrency(digitalDownloadTaxTotal + digitalDownloadTax);
    taxTotal = roundCurrency(taxTotal + tax);
  }

  if (shippingSettings?.taxShipping && shippingSettings.taxAllOrders) {
    taxTotal = roundCurrency(taxTotal + shippingSettings.tax * shippingSettings.shipping);
  }

  return {
    tax: roundCurrency(taxTotal),
    digitalDownloadTax: roundCurrency(digitalDownloadTaxTotal),
  };
};

export const getDigitalDownloadSubtotal = (
  cartProducts: CartProduct[],
  shopProducts: Record<string, ShopProduct | ShopPackage>,
  { images, type }: Gallery,
  productNodeMap: ShopProductNodes,
) =>
  cartProducts.reduce((digitalDownloadSubtotal, product) => {
    if (product.type === 'imageDownload') {
      const itemSubtotal = getCartItemSubtotal(product, shopProducts, images, type, productNodeMap);
      return digitalDownloadSubtotal + itemSubtotal;
    }
    return digitalDownloadSubtotal;
  }, 0);

export const getMultiCartFinSum = (cartFinancials: CartFinancials[]) =>
  cartFinancials.reduce(
    (result, financials) => {
      result.backgroundFees += financials.backgroundFees;
      result.discountAmount += financials.discountAmount;
      result.handling += financials.handling;
      result.imageOptionFees += financials.imageOptionFees;
      result.orderOptionFees += financials.orderOptionFees;
      result.subtotal += financials.subtotal;
      result.total += financials.total;
      return result;
    },
    {
      backgroundFees: 0,
      discountAmount: 0,
      handling: 0,
      imageOptionFees: 0,
      orderOptionFees: 0,
      subtotal: 0,
      total: 0,
    },
  );

export const filterEmptyCarts = (cartMap: Record<string, VisitWithCart>) => {
  const filteredCartMap: Record<string, VisitWithCart> = {};
  for (const [visitKey, cart] of Object.entries(cartMap)) {
    if (cart.cartProducts.length + cart.cartPackages.length > 0) {
      filteredCartMap[visitKey] = cart;
    }
  }
  return filteredCartMap;
};

export const getImagesOnProduct = (product: CartProduct) => {
  if (product.type === 'imageDownload' || product.type === 'collection') {
    return product.collectionImages.reduce<UniqueImageAndBackgroundSet>((map, ci) => {
      if (ci.internalName) {
        if (!map[ci.internalName]) {
          map[ci.internalName] = new Set();
        }
        if (ci.backgroundID) {
          map[ci.internalName].add(ci.backgroundID);
        }
      }
      return map;
    }, {});
  }
  if (product.type === 'product') {
    return (product.nodes as CartNode[]).reduce<UniqueImageAndBackgroundSet>((map, node) => {
      if (node.type === 'image' && node.imageInternalName) {
        if (!map[node.imageInternalName]) {
          map[node.imageInternalName] = new Set();
        }
        if (node.backgroundID) {
          map[node.imageInternalName].add(node.backgroundID);
        }
      }
      return map;
    }, {});
  }
  return {};
};

export const getImagesOnPackage = (cartPackage: CartPackage) => {
  const map: UniqueImageAndBackgroundSet = {};

  for (const product of cartPackage.products) {
    const productImageMap = getImagesOnProduct(product);

    for (const [imageName, backgroundSet] of Object.entries(productImageMap)) {
      map[imageName] = new Set([...(map[imageName] || new Set()), ...backgroundSet]);
    }
  }

  return map;
};

export const getCartItemToOptionKey = (productID: number, optionGroupID: number) =>
  `${productID}-${optionGroupID}`;

export const isImageInCartItem = (shopImage: ShopImage, cartItem: CartPackage | CartProduct) => {
  if (cartItem.type === 'standard' || cartItem.type === 'buildYourOwn') {
    const hasImage = cartItem.products.some(product => {
      return isImageInCartItem(shopImage, product);
    });
    return hasImage;
  }
  if (cartItem.type === 'imageDownload' || cartItem.type === 'collection') {
    return cartItem.collectionImages.some(image => image.internalName === shopImage.internalName);
  }
  if (cartItem.type !== 'product') {
    return false;
  }
  return cartItem.nodes.some(node => {
    if (node.type !== 'image' || !node.imageInternalName) {
      return false;
    }
    return node.imageInternalName === shopImage.internalName;
  });
};

export const isOnlyDigitalDownload = (cartItems: (CartPackage | CartProduct)[]): boolean => {
  return cartItems.every(item => {
    if (item.type === 'buildYourOwn' || item.type === 'standard') {
      return isOnlyDigitalDownload(item.products);
    }
    return item.type === 'imageDownload';
  });
};

/**
 * Finds the background for a cart product
 * if not found/applicable, returns undefined
 * @returns shopbackground | undefined
 */
export const getBackgroundForCartProduct = (
  item: CartProduct | CartPackage,
  backgrounds: ShopBackground[],
) => {
  if (item.type === 'product') {
    const imgNode = item.nodes.find(node => node.type === 'image') as CartImageNode;
    return backgrounds.find(bg => imgNode?.backgroundID === bg.id);
  }

  if (item.type === 'imageDownload' || item.type === 'collection') {
    const collectionImgWithBG = item.collectionImages.find(img => img.backgroundID);
    return backgrounds.find(bg => collectionImgWithBG?.backgroundID === bg.id);
  }

  return;
};

/**
 * Get text nodes that should be shown for a given cart product
 */
export const getCartProductTextNodes = (
  cartProduct: CartProduct,
  productNodeMap: Record<string, CatalogProductNode[]>,
  shopProduct: ShopProduct,
) => {
  if (cartProduct.type !== 'product') {
    return [];
  }
  const nodeMap = getNodeMapForCatalogProduct(shopProduct.catalogProductID, productNodeMap);

  return cartProduct.nodes.filter(node => {
    const isTextNode = node.type === 'text' && node.text;
    if (!isTextNode) {
      return false;
    }
    const { locked } = nodeMap[node.catalogNodeID] as TextNode;

    return !locked;
  }) as CartTextNode[];
};

export const countCartItems = (cart: VisitWithCart) => {
  const productCount = cart.cartProducts.reduce((count, p) => (count += p.quantity), 0);
  const packageCount = cart.cartPackages.reduce((count, p) => (count += p.quantity), 0);
  const imageOptionsCount = cart.cartImageOptions.length;
  return packageCount + productCount + imageOptionsCount;
};
