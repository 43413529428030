import { Box, Drawer, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { CLOSE_MENU } from '../../constants';
import Logo from '../Logo';
import ModalCloseButton from '../ModalCloseButton';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  size?: string;
}

/**
 * Slide-out Drawer component that accepts children to be put into the drawer body. The studio logo
 * resides in its header, and it accepts props, `isOpen` and `onClose` to manage its open/close state
 * from an external button or other element
 */
const SlidingMenu = ({ children, isOpen, onClose, size = 'sm' }: Props) => {
  const { company, logoImage } = useSelector(selectAccount);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      returnFocusOnClose={false}
      size={size}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader as="nav" padding="10px 0 0 0" textAlign="center">
            <Logo
              fallbackFontSize="sm"
              fallbackText={company}
              imageSrc={logoImage}
              margin="auto"
              maxHeight="40px"
            />
          </DrawerHeader>
          <Box margin="10px" position="absolute" right="0">
            <ModalCloseButton
              aria-label={CLOSE_MENU}
              data-test="contact-details-close-menu"
              lightMode
              onClick={onClose}
            />
          </Box>

          {children}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SlidingMenu;
