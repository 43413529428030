import { keyBy } from 'lodash';
import { ShopPackage, ShopProduct, ShopProductCollection } from '../../../shop-api-client';
import { CartPackage, CartProduct } from '../../../shop-api-client/models/Cart';
import { intl } from '../../shared/constants';

export const getInvalidMessage = (field?: string) =>
  intl.formatMessage(
    {
      id: 'input.error',
      defaultMessage: 'Please enter a valid {field}',
    },
    { field: field || 'response' },
  );

export const getTooLongMessage = (field?: string) =>
  intl.formatMessage(
    {
      id: 'input.tooLongError',
      defaultMessage: '{field} is too long',
    },
    { field: field || 'response' },
  );

// Only print products with image nodes and Digital Downloads without the `includeAll` flag allow required image Option selections:
export const passIncludeAllImageOptionCheck = (
  cartItems: (CartProduct | CartPackage)[],
  products: Record<string, ShopProduct | ShopPackage>,
) =>
  cartItems.some(item => {
    const shopItem = products[item.priceSheetItemID];
    // Set the product map for whether it is for a product or for package sub-items:
    const productMap =
      shopItem.type === 'package' || shopItem.type === 'package-byop'
        ? keyBy(shopItem.availableProducts, 'id')
        : products;

    return (item.type === 'buildYourOwn' || item.type === 'standard' ? item.products : [item]).some(
      p =>
        (p.type === 'product' && p.nodes.some(n => n.type === 'image')) ||
        (p.type === 'imageDownload' &&
          !(productMap[p.priceSheetItemID] as ShopProductCollection).includeAll),
    );
  });
