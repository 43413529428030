import React from 'react';
import { ShopBackground, ShopImage } from '../../../../../../../shop-api-client';
import { CollectionImage } from '../../../../../../../shop-api-client/models/Cart';
import ImageNodeBackgroundDisplay from '../../ImageNodesEditor/ImageNodeBackgroundDisplay';

interface Props {
  addOrDelFromCollection(image: ShopImage, background: ShopBackground): void;
  collectionImages: CollectionImage[];
  columns: number;
  image: ShopImage | null;
}

/**
 * Wrapper for selecting multiple backgrounds for a collection/digital downloads
 * handles selection of multiple backgrounds for a GS img
 */
const CollectionSelectionBackgroundWrapper = ({
  addOrDelFromCollection,
  collectionImages,
  columns,
  image,
}: Props) => {
  const selectedBackgrounds = collectionImages.reduce<number[]>((res, ci) => {
    if (ci.backgroundID && ci.internalName === image?.internalName) {
      res.push(ci.backgroundID);
    }
    return res;
  }, []);

  if (!image || !image.isGreenScreen) {
    return null;
  }

  const handleAddBackground = (image: ShopImage, background: ShopBackground) =>
    addOrDelFromCollection(image, background);

  return (
    <ImageNodeBackgroundDisplay
      columns={columns}
      image={image}
      selectBackground={handleAddBackground}
      selectedBackgrounds={selectedBackgrounds}
    />
  );
};

export default CollectionSelectionBackgroundWrapper;
