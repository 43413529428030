import { Box, chakra, Flex, Radio, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { selectAccount } from '../../../../../redux/selectors/account.selectors';
import { selectCartItemCount } from '../../../../../redux/selectors/cart.selectors';
import { selectCartCheckoutFinancials } from '../../../../../redux/selectors/financials.selectors';
import { selectGalleryMap } from '../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../redux/store';
import {
  SUBJECTS_WITH_COLON,
  SUBJECT_WITH_COLON,
} from '../../../../../shared/constants/carts.constants';
import { formatCurrency } from '../../../../../shared/utils';

interface Props {
  cartVisitKeys: string[];
  index: number;
  isSelected: boolean;
  setSelectedCart(index: number): void;
}

interface SubjectsAndGallery {
  subjects: number[];
  title: string;
}

const CartsModalSection = ({ cartVisitKeys, index, isSelected, setSelectedCart }: Props) => {
  const financials = useAppSelector(state => selectCartCheckoutFinancials(state, cartVisitKeys));
  const galleryMap = useAppSelector(selectGalleryMap);
  const { currency } = useAppSelector(selectAccount);
  const itemCount = useAppSelector(state => selectCartItemCount(state, cartVisitKeys));

  const intl = useIntl();

  const handleCartSelect = () => setSelectedCart(index);

  const subjectsByGallery = cartVisitKeys.reduce<Record<number, SubjectsAndGallery>>(
    (map, cartKey) => {
      const { galleryID, subjectID, title } = galleryMap[cartKey];
      map[galleryID] = {
        subjects: [...(map[galleryID]?.subjects || [])],
        title,
      };

      if (subjectID) {
        map[galleryID].subjects.push(subjectID);
      }

      return map;
    },
    {},
  );

  if (!itemCount) {
    return null;
  }

  return (
    <Flex
      borderColor={isSelected ? 'brand' : 'grey.2'}
      borderRadius={10}
      borderWidth={isSelected ? 2 : 1}
      cursor="pointer"
      marginBottom={5}
      onClick={handleCartSelect}
      padding={5}
      width={{ base: '100%', lg: '500px' }}
    >
      <Box marginRight={3} marginTop={1}>
        <Radio value={index} />
      </Box>
      <Flex direction="column" width="100%">
        <Flex justify="space-between" marginY={1}>
          <Text fontFamily="heading" fontSize={{ base: 'lg', md: 'xl' }} marginRight={2}>
            {intl.formatMessage(
              {
                id: 'cartsModalSection.cartNumber',
                defaultMessage: 'Cart #{number}',
              },
              { number: index + 1 },
            )}
            <chakra.span fontFamily="body">
              {' '}
              {intl.formatMessage(
                {
                  id: 'cartsModalSection.itemCount',
                  defaultMessage: '- {itemCount} {items}',
                },
                { itemCount, items: itemCount === 1 ? 'Item' : 'Items' },
              )}
            </chakra.span>
          </Text>
          <Text fontFamily="heading" fontSize={{ base: 'md', md: 'lg' }}>
            {formatCurrency(financials.total, currency)}
          </Text>
        </Flex>
        {Object.entries(subjectsByGallery).map(([galleryID, { subjects, title }]) => (
          <Flex key={title} direction="column" marginY={2}>
            <Text fontFamily="heading">
              {galleryID} {title}
            </Text>
            {!!subjects.length && (
              <Text>
                {subjects.length === 1 ? SUBJECT_WITH_COLON : SUBJECTS_WITH_COLON}{' '}
                {subjects.join(', ')}
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default CartsModalSection;
