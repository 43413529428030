const Link = {
  variants: {
    brand: {
      color: 'brand',
      fontWeight: 'bold',
      lineHeight: '35px',
      textDecoration: 'underline',
    },
    inlineBrand: {
      color: 'brand',
      fontWeight: 'bold',
      lineHeight: 0,
      textDecoration: 'underline',
      marginX: 0,
      margin: 0,
    },
    modalPrimary: {
      background: '#FCDF7C',
      borderRadius: '8px',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '50px',
      lineHeight: '33px',
      textAlign: 'center',
      paddingX: '3',
      paddingY: '2',
      _hover: {
        textDecoration: 'none',
      },
    },
    primary: {
      backgroundColor: 'brand',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '32px',
      lineHeight: '14px',
      paddingX: '3',
      paddingY: '2',
      _hover: {
        textDecoration: 'none',
      },
    },
    underline: {
      fontSize: 'sm',
      lineHeight: '35px',
      textDecoration: 'underline',
    },
  },
};

export default Link;
