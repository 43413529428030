import { ListItem, TypographyProps, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectConfigurationSummary } from '../../../../../redux/selectors/configurations.selectors';
import {
  ITEM,
  ITEMS,
  NEW_WITH_COLON,
  REMOVED_WITH_COLON,
  UPDATED_WITH_COLON,
} from '../../../../../shared/constants/labels.constants';

interface Props {
  fontSize?: TypographyProps['fontSize'];
}

const AddOnsSummaryList = ({ fontSize = 'md' }: Props) => {
  const { imageOptionSummary } = useSelector(selectConfigurationSummary);
  const { new: added, removed, updated } = imageOptionSummary;

  return (
    <UnorderedList paddingLeft={4} data-test="items-added">
      {!!added && (
        <ListItem fontSize={fontSize}>
          {NEW_WITH_COLON} {added} {added > 1 ? ITEMS : ITEM}
        </ListItem>
      )}
      {!!updated && (
        <ListItem fontSize={fontSize}>
          {UPDATED_WITH_COLON} {updated} {updated > 1 ? ITEMS : ITEM}
        </ListItem>
      )}
      {!!removed && (
        <ListItem fontSize={fontSize}>
          {REMOVED_WITH_COLON} {removed} {removed > 1 ? ITEMS : ITEM}
        </ListItem>
      )}
    </UnorderedList>
  );
};

export default AddOnsSummaryList;
