const Text = {
  variants: {
    inline: {
      display: 'inline',
      fontSize: 'xs',
    },
  },
};

export default Text;
