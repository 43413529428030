import { Heading } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { selectGalleryMap } from '../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../redux/store';
import MultiGalleryMenuItem from '../../../../MultiGallery/MultiGalleryMenuItem';

const MyGalleries = () => {
  const galleryMap = useAppSelector(selectGalleryMap);

  const intl = useIntl();

  const myGalleriesHeading = intl.formatMessage({
    id: 'myGalleries.myGalleriesHeading',
    defaultMessage: 'MY GALLERIES',
  });

  const renderMenuItems = () =>
    Object.entries(galleryMap).map(([visitKey, gallery]) => {
      // Don't show galleries that haven't been fully authenticated
      if (!gallery.priceSheetID) {
        return null;
      }
      return <MultiGalleryMenuItem gallery={gallery} key={visitKey} visitKey={visitKey} />;
    });

  return (
    <>
      <Heading color="grey.4" fontSize="xs" marginBottom="15px" marginLeft="5px" textAlign="start">
        {myGalleriesHeading}
      </Heading>
      {renderMenuItems()}
    </>
  );
};

export default MyGalleries;
