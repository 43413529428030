import { getDefaultCrop, ImageNode } from 'iq-product-render';
import {
  PriceSheetOption,
  ShopBackground,
  ShopImage,
  ShopPackage,
  ShopProduct,
  ShopProductCollection,
  ShopProductPrint,
} from '../../../shop-api-client';
import {
  CartCollection,
  CartImageNode,
  CartImageOption,
  CartNode,
  CartPackage,
  CartProduct,
  CollectionImage,
  CreateCartNodeReq,
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../shop-api-client/models/Cart';
import { ImageOptionConfigMap } from '../../../shop-api-client/models/ShopConfig';
import {
  getBackground,
  getBuildYourOwnStep,
  getImageCropStep,
  getImageMultiNodeStep,
  getImageNodesStep,
  shapeCartImageNode,
  shapeCartImageOption,
  shapeCollectionImage,
  shapeEditPackage,
  shapeEditProduct,
  shapeNodesForItem,
} from '../../features/Products/Configuration/utils';
import {
  getBackgroundFromSets,
  getNodeMapForCatalogProduct,
  getProductNodeMap,
} from '../../features/Products/utils';
import { selectAllBackgrounds, selectBackgroundsMap } from '../selectors/background.selectors';
import {
  selectAvailableImagesForOptions,
  selectConfiguration,
  selectPackageItemMap,
  selectPackageSteps,
  selectSteps,
} from '../selectors/configurations.selectors';
import { selectGallery } from '../selectors/gallery.selectors';
import { selectPriceSheet } from '../selectors/priceSheet.selectors';
import {
  prefillImageOptions,
  resetCompletedSteps,
  setCompletedPkgItems,
  setCompletedSteps,
  setEditImageOption,
  setEditPackage,
  setEditProduct,
} from '../slices/configurations.slice';
import { AppThunk, Thunk } from '../store';
import { loadBackgroundImage } from './interactions.thunks';

interface EditItemContext {
  shopItem: ShopPackage | ShopProduct;
  cartItem?: CartPackage | CartProduct;
  imageName?: string;
  backgroundID?: number;
}

/**
 * Sets the edit item either from an existing cart item selected for editing or as a new item
 * from The Shop catalog. Optionally takes an `imageName` and `backgroundID` to pre-fill the
 * edit item with image data.
 */
export const initializeEditItem =
  ({ shopItem, cartItem, backgroundID, imageName }: EditItemContext): AppThunk =>
  (dispatch, getState) => {
    const { gallery, visitor } = getState();
    const { galleryMap, priceSheetMap } = gallery;
    const { currentVisitKey } = visitor;
    const {
      isLoading,
      isPreOrder,
      priceSheetID,
      settings: { disableCropping },
    } = galleryMap[currentVisitKey!];
    const { backgroundSets } = priceSheetMap[priceSheetID];

    if (isLoading) {
      return { valid: false, payload: null, error: 'Gallery is Loading' };
    }

    if (cartItem) {
      if (cartItem.type === 'buildYourOwn') {
        dispatch(setCompletedSteps({ [getBuildYourOwnStep()]: true }));
      }
      if (cartItem.type === 'buildYourOwn' || cartItem.type === 'standard') {
        const completed = cartItem.products.reduce<Record<string, boolean>>((res, p) => {
          res[p.id!] = true;
          return res;
        }, {});
        dispatch(setCompletedPkgItems(completed));
        dispatch(setEditPackage(cartItem));
      } else {
        dispatch(setEditProduct(cartItem));
      }
      const background = getBackground(cartItem, backgroundSets);
      if (background) {
        dispatch(loadBackgroundImage(background));
      }

      dispatch(prefillEditImageOptions());

      // With everything initialized, complete all the steps, since this product was already
      // added to the cart before:
      dispatch(unlockAllSteps());
    } else {
      dispatch(setNewEditItem(shopItem, imageName, backgroundID));

      // The initialized item might have had some images auto populated so
      // go through any image node and mark the step completed if appropriate
      if (!imageName && shopItem.type === 'package') {
        const editItem = getState().configuration.editPackage!;
        for (const product of editItem.products) {
          if (product.type !== 'product') {
            continue;
          }

          const imageStepName =
            product.nodes.filter(n => n.type === 'image').length > 1
              ? getImageMultiNodeStep(product.id!)
              : getImageNodesStep(product.id!);

          let needsImageAssignment = false;
          for (const node of product.nodes) {
            if (node.type === 'image' && !node.imageInternalName) {
              needsImageAssignment = true;
            }
          }

          // If there is no image assignment needed, and we're not cropping
          // then we can mark the step completed:
          if (disableCropping && !isPreOrder && !needsImageAssignment) {
            dispatch(setCompletedSteps({ [imageStepName]: true }));
          }
        }
      }

      // Check if there are any steps left:
      const packageSteps = selectPackageSteps(getState());
      const { completedSteps } = selectConfiguration(getState());

      for (const [productID, steps] of Object.entries(packageSteps)) {
        const incomplete = steps.some(step => !completedSteps[step.step]);
        if (!incomplete) {
          dispatch(setCompletedPkgItems({ [productID]: true }));
        }
      }
    }

    return { valid: true, payload: null, error: null };
  };

export const unlockAllSteps = (): Thunk => (dispatch, getState) => {
  const steps = selectSteps(getState());

  const unlockSteps = steps.reduce<Record<string, boolean>>((map, step) => {
    map[step] = true;

    return map;
  }, {});

  dispatch(setCompletedSteps(unlockSteps));
};

const setNewEditItem =
  (shopItem: ShopPackage | ShopProduct, imageName?: string, backgroundID?: number): Thunk =>
  (dispatch, getState) => {
    const { gallery: galleryState, visitor } = getState();
    const { galleryMap, priceSheetMap } = galleryState;
    const { currentVisitKey } = visitor;
    const gallery = galleryMap[currentVisitKey!];
    const { images, priceSheetID } = gallery;
    const { backgroundSets, productNodeMap } = priceSheetMap[priceSheetID];

    const image = imageName ? images[imageName] : undefined;
    const background = image?.isGreenScreen
      ? getBackgroundFromSets(backgroundSets, backgroundID)
      : undefined;

    if (shopItem.type === 'package' || shopItem.type === 'package-byop') {
      const editPackage = shapeEditPackage(shopItem, productNodeMap, image, background, gallery);

      dispatch(setEditPackage(editPackage));
    } else {
      const editProduct = shapeEditProduct(shopItem, productNodeMap, image, background);
      dispatch(setEditProduct(editProduct));
    }

    dispatch(prefillEditImageOptions());

    // Reset all the completed steps initially
    dispatch(resetCompletedSteps());
  };

// -------------------------------------------------------------------------
// Node Configuration
// -------------------------------------------------------------------------

/**
 * Updates a node on the `editProduct` or an `editPackage` sub-item that shares the
 * given `editItemID`
 */
export const updateEditProductNode =
  (editItemID: number, editNode: CreateCartNodeReq | CartNode, autoPopulate = false): Thunk =>
  (dispatch, getState) => {
    const rootState = getState();
    const { editPackage, editProduct } = rootState.configuration;
    const { galleryMap, priceSheetMap } = rootState.gallery;
    const { currentVisitKey } = rootState.visitor;
    const { images, priceSheetID, type } = galleryMap[currentVisitKey!];
    const backgroundsMap = selectBackgroundsMap(rootState);
    const backgroundsArray = selectAllBackgrounds(rootState);

    const update = (product?: CreateCartProductReq) => {
      if (product?.type === 'product') {
        const nodes = product.nodes.map(n =>
          // Replace the updated node with a merged copy of the node with the updated data:
          n.catalogNodeID === editNode.catalogNodeID ? { ...n, ...editNode } : n,
        );
        return { ...product, nodes };
      }
      return product;
    };

    if (editPackage) {
      const { productNodeMap, products: productMap } = priceSheetMap[priceSheetID];
      const shopPkg = productMap[editPackage.priceSheetItemID] as ShopPackage;
      const isSinglePose = !shopPkg.allowAdditionalPoses && shopPkg.posesIncluded === 1;

      // Group image nodes inside single pose packages are exempt from the pose restriction:
      const product = editPackage.products.find(p => p.id === editItemID);
      const priceSheetItem = shopPkg.availableProducts.find(
        p => p.id === product!.priceSheetItemID,
      );
      let groupImageRequired = false;
      if (type !== 'standard' && priceSheetItem?.type === 'product') {
        const node = priceSheetItem.imageRequirementProperties.find(
          req => req.nodeID === editNode.catalogNodeID,
        );
        groupImageRequired = !!node && node.type === 'group';
      }

      // If single-pose, this block is entered to assign image and background to all sub-items:
      if (autoPopulate && isSinglePose && editNode.type === 'image' && !groupImageRequired) {
        const image = images[editNode.imageInternalName!];
        let background = editNode.backgroundID ? backgroundsMap[editNode.backgroundID] : undefined;
        const catalogNode = productNodeMap[priceSheetItem!.catalogProductID]?.find(
          n => n.id === editNode.catalogNodeID,
        ) as ImageNode;

        // If backgrounds are present, yet the current node does not require a background,
        // find the background to assign to the other nodes that may require one:
        if (backgroundsArray.length && catalogNode?.skipBackgroundSelection) {
          for (const product of editPackage.products) {
            if (background) {
              break;
            }

            if (product.type === 'collection' || product.type === 'imageDownload') {
              const backgroundID = product.collectionImages.find(
                c => !!c.backgroundID,
              )?.backgroundID;
              background = backgroundID ? backgroundsMap[backgroundID] : undefined;
            } else if (product.type === 'product') {
              const backgroundID = (
                product.nodes.find(n => n.type === 'image' && n.backgroundID) as CartImageNode
              )?.backgroundID;
              background = backgroundID ? backgroundsMap[backgroundID] : undefined;
            }
          }

          // Use either an existing background that was found in the for-loop above,
          // or fallback to the first background on the price sheet:
          background = background || backgroundsArray[0];
        }

        // For single pose packages that are going through the wizard, just
        // auto populate any image choice across all products, this is done through
        // another thunk:
        return dispatch(
          assignImageToSinglePosePackage(currentVisitKey!, editPackage, image, background),
        );
      }

      const updated = update(editPackage.products.find(p => p.id === editItemID));
      if (updated) {
        const products = editPackage.products.map(p => (p.id === updated.id ? updated : p));
        dispatch(setEditPackage({ ...editPackage, products }));
      }
    } else if (editProduct) {
      dispatch(setEditProduct(update(editProduct)!));
    }
  };

export const updatePoseInPackage =
  (
    oldImage: string | null | undefined,
    oldBackground: number | null | undefined,
    newImage: string,
    newBackground: number | null | undefined,
    updateAll: boolean,
  ): Thunk =>
  (dispatch, getState) => {
    const { editPackage } = getState().configuration;
    const gallery = selectGallery(getState());
    const packageItemMap = selectPackageItemMap(getState());
    const { productNodeMap } = selectPriceSheet(getState());

    const {
      images,
      settings: { disableCropping },
    } = gallery;

    if (!editPackage) {
      return;
    }

    const cropSteps: string[] = [];
    const incompleteProducts = new Set<number>();

    // Update the pose wherever it's used in this package:
    const products = editPackage.products.map(product => {
      const updatedProduct = { ...product };

      if (updatedProduct.type === 'product') {
        let imageUpdated = false;
        const { catalogProductID } = packageItemMap[updatedProduct.priceSheetItemID];
        const nodeMap = getNodeMapForCatalogProduct(catalogProductID, productNodeMap);

        updatedProduct.nodes = updatedProduct.nodes.map(node => {
          const updatedNode = { ...node };
          const catalogNode = nodeMap[updatedNode.catalogNodeID];
          const skipBackground =
            catalogNode.type === 'image' && catalogNode.skipBackgroundSelection;

          if (
            updatedNode.type === 'image' &&
            // This node had an image previously assigned
            updatedNode.imageInternalName &&
            // Don't touch assigned group images
            !images[updatedNode.imageInternalName].group &&
            // The previous image matches this, or we're updating all assigned images
            ((updatedNode.imageInternalName === oldImage &&
              // Note == for background to support undefined/null
              (updatedNode.backgroundID == oldBackground || skipBackground)) ||
              updateAll)
          ) {
            // Calculate a new default crop:
            const { height, width } = images[newImage];
            const defaultCrop = getDefaultCrop({ height, width }, catalogNode, true, true);

            updatedNode.backgroundID = skipBackground ? updatedNode.backgroundID : newBackground;
            updatedNode.cropH = defaultCrop.height;
            updatedNode.cropW = defaultCrop.width;
            updatedNode.cropX = defaultCrop.x;
            updatedNode.cropY = defaultCrop.y;
            updatedNode.imageInternalName = newImage;
            updatedNode.imageDisplayName = images[updatedNode.imageInternalName].displayName;
            updatedNode.orientation = defaultCrop.rotation;

            imageUpdated = true;
          }

          return updatedNode;
        });

        if (!disableCropping && imageUpdated) {
          cropSteps.push(getImageCropStep(product.id!));
          incompleteProducts.add(product.id!);
        }
      } else if (updatedProduct.type === 'collection' || updatedProduct.type === 'imageDownload') {
        updatedProduct.collectionImages = updatedProduct.collectionImages.map(image => {
          const updatedImage = { ...image };

          if (
            // If an image was assigned and we're updating everything
            (updatedImage.internalName && updateAll) ||
            // Or the previous image matches this one (note == for background to support undefined/null)
            (updatedImage.internalName === oldImage && updatedImage.backgroundID == oldBackground)
          ) {
            updatedImage.displayName = images[newImage].displayName;
            updatedImage.internalName = newImage;
            updatedImage.backgroundID = newBackground || undefined;
          }

          return updatedImage;
        });
      }

      return updatedProduct;
    });

    // If cropping is enabled, we need to reset the completed crop step on any of the
    // products that were changed
    if (cropSteps.length) {
      const stepPayload = cropSteps.reduce<Record<string, boolean>>((result, step) => {
        result[step] = false;
        return result;
      }, {});

      const itemPayload = Array.from(incompleteProducts).reduce<Record<string, boolean>>(
        (result, productID) => {
          result[productID] = false;
          return result;
        },
        {},
      );

      dispatch(setCompletedSteps(stepPayload));
      dispatch(setCompletedPkgItems(itemPayload));
    }

    dispatch(setEditPackage({ ...editPackage, products }));
  };

// -------------------------------------------------------------------------
// Collection/DD Config
// -------------------------------------------------------------------------
export const updateEditProductCollectionImgs =
  (editItemID: number, collectionImages: CollectionImage[]): Thunk =>
  (dispatch, getState) => {
    const rootState = getState();
    const { editPackage, editProduct } = rootState.configuration;
    const { galleryMap, priceSheetMap } = rootState.gallery;
    const { currentVisitKey } = rootState.visitor;
    const { images, priceSheetID, type } = galleryMap[currentVisitKey!];
    const backgroundsMap = selectBackgroundsMap(rootState);

    if (editPackage) {
      const { products: productMap } = priceSheetMap[priceSheetID];
      const shopPkg = productMap[editPackage.priceSheetItemID] as ShopPackage;

      // Group image collections inside single pose packages are exempt from the pose restriction:
      const product = editPackage.products.find(p => p.id === editItemID);
      const priceSheetItem = shopPkg.availableProducts.find(
        p => p.id === product!.priceSheetItemID,
      ) as ShopProductCollection;
      const groupImageRequired =
        type !== 'standard' && priceSheetItem?.imageRequirementType === 'group';

      if (
        !shopPkg.allowAdditionalPoses &&
        shopPkg.posesIncluded === 1 &&
        collectionImages.length &&
        !groupImageRequired
      ) {
        const collectionImage = collectionImages[0];
        const image = images[collectionImage.internalName!];
        const background = collectionImage.backgroundID
          ? backgroundsMap[collectionImage.backgroundID]
          : undefined;

        // For single pose packages that are going through the wizard, just
        // auto populate any image choice across all products, this is done through
        // another thunk:
        return dispatch(
          assignImageToSinglePosePackage(currentVisitKey!, editPackage, image, background),
        );
      }

      // Otherwise just update the item in the package
      const products = editPackage.products.map(p =>
        p.id === editItemID ? { ...p, collectionImages } : p,
      );
      dispatch(setEditPackage({ ...editPackage, products }));
    } else if (editProduct) {
      dispatch(setEditProduct({ ...editProduct, collectionImages } as CartCollection));
    }
  };

/**
 * Add an image to the collectionImages property of the product currently
 * being edited, if it doesn't already exist on there
 */
export const addEditProductCollectionImgs =
  (editItemID: number, collectionImage: CollectionImage): Thunk =>
  (dispatch, getState) => {
    const { editPackage, editProduct } = getState().configuration;

    const mapProduct = (product: CreateCartProductReq) => {
      if (
        product.id === editItemID &&
        (product.type === 'collection' || product.type === 'imageDownload')
      ) {
        const collectionImages = product.collectionImages ? [...product.collectionImages] : [];

        // Verify this new collection image is not already in the array
        const exists = collectionImages.some(
          ci =>
            ci.internalName === collectionImage.internalName &&
            ci.backgroundID == collectionImage.backgroundID,
        );
        if (!exists) {
          return {
            ...product,
            collectionImages: [...collectionImages, collectionImage],
          };
        }
      }

      return product;
    };

    if (editPackage) {
      dispatch(setEditPackage({ ...editPackage, products: editPackage.products.map(mapProduct) }));
    } else if (editProduct) {
      dispatch(setEditProduct(mapProduct(editProduct)));
    }
  };

// -------------------------------------------------------------------------
// Package Configuration
// -------------------------------------------------------------------------
export const addBuildYourOwnItem =
  (subItem: ShopProduct, image?: ShopImage, background?: ShopBackground): Thunk =>
  (dispatch, getState) => {
    const {
      gallery: { galleryMap, priceSheetMap },
      configuration: { editPackage },
      visitor: { currentVisitKey },
    } = getState();
    const gallery = galleryMap[currentVisitKey!];
    const { productNodeMap } = priceSheetMap[gallery.priceSheetID];
    const editProduct = shapeEditProduct(subItem, productNodeMap, image, background);
    if (editProduct.type === 'product') {
      editProduct.nodes = shapeNodesForItem(
        gallery,
        subItem,
        productNodeMap,
        image?.internalName,
        background,
      );
    }
    const products = [...editPackage!.products, editProduct];
    dispatch(setEditPackage({ ...editPackage!, products }));
  };

export const assignImageToSinglePosePackage =
  (
    visitKey: string,
    editPackage: CreateCartPackageReq,
    image: ShopImage,
    background?: ShopBackground,
  ): Thunk =>
  (dispatch, getState) => {
    const { galleryMap, priceSheetMap } = getState().gallery;
    const { priceSheetID } = galleryMap[visitKey];
    const { productNodeMap, products } = priceSheetMap[priceSheetID];
    const shopPkg = products[editPackage.priceSheetItemID] as ShopPackage;
    const subItemMap = shopPkg.availableProducts.reduce<Record<string, ShopProduct>>(
      (result, item) => {
        result[item.id] = item;
        return result;
      },
      {},
    );

    const itemsWithAssignment = editPackage.products.map<CreateCartProductReq>(product => {
      if (product.type === 'product') {
        const subItem = subItemMap[product.priceSheetItemID] as ShopProductPrint;
        const catNodeMap = getProductNodeMap(productNodeMap[subItem.catalogProductID]);

        const nodes = product.nodes.map(node => {
          if (node.type !== 'image') {
            return node;
          }
          const catNode = catNodeMap[node.catalogNodeID] as ImageNode;

          // If the image is not a group image, and the product is asking for one, don't assign it:
          const requirementType = subItem.imageRequirementProperties.find(
            n => n.nodeID === node.catalogNodeID,
          );
          if (requirementType?.type === 'group' && !image.group) {
            return node;
          }

          // If a background is passed but the current node does not require a background,
          // set as undefined:
          const backgroundToAdd = catNode.skipBackgroundSelection ? undefined : background;
          // Shape the node to apply image/crop data:
          const updated = shapeCartImageNode(catNode, image, backgroundToAdd, node.imageTone);

          // spread to merge the updated data, incase the node already exist in the database, in which case
          // the node will have additional properties than what is assigned to `updated`:
          return { ...node, ...updated };
        });

        return { ...product, nodes };
      }

      if (product.type === 'collection' || product.type === 'imageDownload') {
        const subItem = subItemMap[product.priceSheetItemID] as ShopProductCollection;
        if (subItem.imageRequirementType === 'group' && !image.group) {
          return product;
        }

        // spread to merge the updated data, incase the collectionImages already exist in the database, in which case
        // they will have additional data (id, createdAt, etc.):
        const collectionImages = product.collectionImages.map(i => ({
          ...i,
          ...shapeCollectionImage(image, background, i.imageTone),
        }));

        return { ...product, collectionImages };
      }
      return product;
    });

    dispatch(setEditPackage({ ...editPackage, products: itemsWithAssignment }));
  };

// -------------------------------------------------------------------------
// Image Option Prefill
// -------------------------------------------------------------------------
/**
 * whenever we set a new edit item, this thunk is called and prefills the edit item
 * with the current cart image options
 */
export const prefillEditImageOptions = (): Thunk => (dispatch, getState) => {
  const { currentVisitKey } = getState().visitor;
  const { priceSheetID } = getState().gallery.galleryMap[currentVisitKey!];
  const { imageOptionMap } = getState().gallery.priceSheetMap[priceSheetID];
  const { cartImageOptions } = getState().cart.cartMap[currentVisitKey!];

  const prefilledImageOptions = cartImageOptions.reduce<ImageOptionConfigMap>((map, cartIO) => {
    // if on ps for this gallery and optional
    // add it to the map
    const priceSheetIO = imageOptionMap[cartIO.priceSheetOptionID];
    if (priceSheetIO?.requirementType === 'optional') {
      // priceSheetOptionID = option id on the pricesheet
      // optionGroupID = catalog group ID - don't use this for as an identifier
      // optionID = id of the selection on the groupOption thats actually the psOption
      map[cartIO.priceSheetOptionID] = {
        ...map[cartIO.priceSheetOptionID],
        [cartIO.optionID]: cartIO,
      };
    }

    return map;
  }, {});

  dispatch(prefillImageOptions(prefilledImageOptions));
};

export const createImageOptionConfigMap = (
  cartImageOptions: CartImageOption[],
  imageOptionMap: Record<string, PriceSheetOption>,
  requirementType?: 'required' | 'optional',
) =>
  cartImageOptions.reduce<ImageOptionConfigMap>((map, cartIO) => {
    // if on ps for this gallery (and matches requirement type), add to map
    const priceSheetIO = imageOptionMap[cartIO.priceSheetOptionID];
    const addToMap = requirementType
      ? priceSheetIO?.requirementType === requirementType
      : priceSheetIO;
    if (addToMap) {
      // priceSheetOptionID = option id on the pricesheet
      // optionGroupID = catalog group ID - don't use this for as an identifier
      // optionID = id of the selection on the groupOption thats actually the psOption
      map[cartIO.priceSheetOptionID] = {
        ...map[cartIO.priceSheetOptionID],
        [cartIO.optionID]: cartIO,
      };
    }

    return map;
  }, {});

export const autoAddSingleSelectionRequiredPackageImageOption =
  (): Thunk<Record<number, PriceSheetOption>> => (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    const { editPackage } = getState().configuration;
    const { priceSheetID, isPreOrder } = getState().gallery.galleryMap[currentVisitKey!];
    const { products } = getState().gallery.priceSheetMap[priceSheetID];
    const imagesByRequirement = selectAvailableImagesForOptions(getState());

    if (!editPackage) {
      return {};
    }
    const shopPackage = products[editPackage.priceSheetItemID] as ShopPackage;
    if (!shopPackage.options.length) {
      return {};
    }
    const requiredPkgIOMap = shopPackage.options.reduce<Record<number, PriceSheetOption>>(
      (map, o) => {
        if (o.requirementType === 'required') {
          map[o.id] = o;
        }
        return map;
      },
      {},
    );

    for (const option of Object.values(requiredPkgIOMap)) {
      if (option.selections.length === 1) {
        // add all images,  ++ TODO, make sure its properly reconciled after
        const images = isPreOrder
          ? [{ imageName: null }]
          : Object.keys(imagesByRequirement[option.imageRequirementType!]).map(img => ({
              imageName: img,
            }));

        const newCartIO = shapeCartImageOption(option, option.selections[0], images);

        dispatch(
          setEditImageOption({
            priceSheetOptionID: option.id,
            imageOptionSelections: { [option.selections[0].catalogOptionID]: newCartIO },
          }),
        );
      }
    }

    return requiredPkgIOMap;
  };
