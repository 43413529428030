import { Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { PriceSheetOption } from '../../../../../shop-api-client';
import { selectBackgroundsMap } from '../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../redux/store';
import Option from '../../../../shared/components/Option';
import ThumbnailWithBackground from '../../../../shared/components/ThumbnailWithBackground';
import { validateImageRequirement } from '../../../Products/utils';

interface Props {
  backgroundSet?: Set<number>;
  imageName: string;
  imageOptions: PriceSheetOption[];
  onSelect(
    visitKey: string,
    imageName: string,
    optionID: number,
    selectionID: number | false,
  ): void;
  selectionsForCartImage: Record<number, number | false | undefined>;
  showError?: boolean;
  visitKey: string;
}

/**
 * required image options mapped per image
 */
const RequiredImageOptionsByImage = ({
  backgroundSet,
  imageName,
  imageOptions,
  onSelect,
  selectionsForCartImage,
  showError,
  visitKey,
}: Props) => {
  const { images, isPreOrder, type } = useAppSelector(state => selectGallery(state, visitKey));
  const backgroundsMap = useAppSelector(state => selectBackgroundsMap(state, visitKey));

  const availableImageOptions = useMemo(() => {
    if (!isPreOrder && type !== 'standard') {
      return imageOptions.filter(option =>
        validateImageRequirement(images[imageName], option.imageRequirementType),
      );
    }
    return imageOptions;
  }, [images, imageName, imageOptions, isPreOrder, type]);

  const backgrounds = Array.from(backgroundSet || []);

  const handleAddSelectionForImage = (option: PriceSheetOption, selectionID: number | false) => {
    onSelect(visitKey, imageName, option.id, selectionID);
  };

  if (!availableImageOptions.length) {
    return null;
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} alignItems="flex-start">
      {!isPreOrder && (
        <ThumbnailWithBackground
          src={images[imageName].sources.thumb}
          background={
            backgrounds.length ? backgroundsMap[backgrounds?.[0]].sources.thumb : undefined
          }
          maxWidth="150px"
        />
      )}
      <Flex direction="column" marginLeft={{ base: 0, md: 4 }} marginTop={{ base: 4, md: 0 }}>
        {availableImageOptions.map(option => {
          const isInvalid =
            option.type === 'boolean'
              ? selectionsForCartImage[option.id] !== undefined
              : selectionsForCartImage[option.id];
          return (
            <Option
              key={option.id}
              heading={option.name}
              isInvalid={showError && !isInvalid}
              option={option}
              selectionID={selectionsForCartImage[option.id]}
              setSelectionID={selectionID => handleAddSelectionForImage(option, selectionID)}
              showRequired
              useCompact
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default RequiredImageOptionsByImage;
