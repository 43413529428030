import {
  api,
  CartCheckoutEntity,
  CheckoutFormData,
  CreateCheckoutResult,
} from '../../../shop-api-client';
import { CartImageOption } from '../../../shop-api-client/models/Cart';
import { ApplyDiscount, Discount } from '../../../shop-api-client/models/Discounts';
import { getCartBackgroundsKeyedByImage } from '../../features/Carts/utils';
import { shapeCartImageOption } from '../../features/Products/Configuration/utils';
import { validateImageRequirement } from '../../features/Products/utils';
import { toast } from '../../shared/components/Toast/Toast';
import { intl, PREORDER } from '../../shared/constants';
import { setCartDiscounts } from '../slices/cart.slice';
import {
  RequiredImageOptionMap,
  resetCheckoutSteps,
  setCheckoutDiscount,
  setCheckoutFinancials,
  setCheckoutVisitKeys,
  setEditReqImageOptionsMap,
  setPaymentIntent,
  setPrefillCheckout,
} from '../slices/checkout.slice';
import { PromiseThunk, Thunk } from '../store';
import { getCartSummary, validateCarts } from './cart.thunks';

export const createCheckout =
  (keys?: string[]): Thunk<Promise<CreateCheckoutResult | null>> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    try {
      // validate carts
      const { valid, payload } = await dispatch(validateCarts(currentVisitKey!));

      if (!valid || !payload) {
        return null;
      }

      const result = await api.createCheckout(currentVisitKey!, keys);
      if (result.checkout) {
        // Reset the checkout steps, in case visitor switches between checkouts
        dispatch(resetCheckoutSteps());
        dispatch(setCheckoutVisitKeys(result.checkout.visitKeys));
        dispatch(preFillCheckout(result.checkout.formData, result.checkout.visitKeys));
        dispatch(getDiscounts(result.checkout.id.toString()));
        // add timestamp to financials to show that it was recently calculated
        dispatch(
          setCheckoutFinancials({
            ...result.checkoutFinancials.orderFinancials,
            timestamp: new Date(),
          }),
        );
        if (result.paymentIntent) {
          dispatch(setPaymentIntent(result.paymentIntent));
        }
      } else {
        // We need to refresh our cart summary to have the latest data to display for
        // checkout modal options:
        await dispatch(getCartSummary());
      }

      return result;
    } catch (err) {
      return null;
    }
  };

export const getCheckout =
  (checkoutID: string): PromiseThunk<CartCheckoutEntity | null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    const { timestamp } = getState().checkout.financials;

    try {
      const checkout = await api.getCheckout(currentVisitKey!, checkoutID);

      // Reset the checkout steps, in case visitor switches between checkouts
      dispatch(resetCheckoutSteps());

      // if there is no timestamp, site was refreshed so refetch financials
      // (his is specific for when we get the checkout since if we're redirected here after upserting, we already have full financials
      if (!timestamp) {
        await dispatch(getCheckoutFinancials(checkoutID));
      }
      if (checkout.clientSecret) {
        dispatch(setPaymentIntent(checkout.clientSecret));
      }
      dispatch(setCheckoutVisitKeys(checkout.visitKeys));
      dispatch(preFillCheckout(checkout.formData, checkout.visitKeys));
      dispatch(getDiscounts(checkout.id.toString()));
      dispatch(setEditReqImageOptions());
      dispatch(
        setCheckoutDiscount({
          discountCode: checkout.discountCode,
          discountID: checkout.discountID,
        }),
      );

      return { valid: true, payload: checkout, error: null };
    } catch (error) {
      const failedToGetCheckout = intl.formatMessage({
        id: 'checkout.thunks.failedToGetCheckout',
        defaultMessage: 'We could not checkout your cart',
      });
      toast({ id: checkoutID, title: failedToGetCheckout }, error);

      return { valid: false, payload: null, error };
    }
  };

export const getCheckoutFinancials =
  (checkoutID: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;

    try {
      const financials = await api.getCheckoutFinancials(currentVisitKey!, checkoutID);
      dispatch(setCheckoutFinancials({ ...financials.orderFinancials, timestamp: new Date() }));
      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

export const getDiscounts =
  (checkoutID: string): PromiseThunk<Record<string, Discount[]> | null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;

    try {
      const discounts = await api.getDiscounts(currentVisitKey!, checkoutID);
      dispatch(setCartDiscounts(discounts));
      return { valid: true, payload: discounts, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

/**
 * Dispatches to set `checkoutPreFill` with `shippingType` initialized
 * as existing or from cart settings
 */
export const preFillCheckout =
  (stringifiedFormData: string | null, visitKeys: string[]): Thunk =>
  (dispatch, getState) => {
    const { galleryMap } = getState().gallery;
    // Grab gallery settings from the first of the given visit keys:
    const { settings } = galleryMap[visitKeys[0]];

    const formData = JSON.parse(stringifiedFormData || '{}') as CheckoutFormData;
    // Set formData shippingType if present, or fallback to settings shipmentType:
    const shippingTypeDefault =
      settings.shipmentType === 'choice' ? 'pickup' : settings.shipmentType;

    const shippingType = formData.shippingType || shippingTypeDefault;

    dispatch(setPrefillCheckout({ ...formData, shippingType }));
  };

// Apply Discount and redispatch financials
export const applyDiscount =
  (checkoutID: string, discount: ApplyDiscount): PromiseThunk<boolean> =>
  async (dispatch, getState) => {
    try {
      const visitKey = getState().visitor.currentVisitKey;

      const { financials, discountCode, discountID } = await api.applyDiscount(
        visitKey!,
        checkoutID,
        discount,
      );

      dispatch(setCheckoutFinancials(financials));
      dispatch(setCheckoutDiscount({ discountCode, discountID }));

      const discountApplied = intl.formatMessage({
        id: 'checkout.thunks.discountApplied',
        defaultMessage: 'Discount applied!',
      });
      const handleUndo = async () => await dispatch(removeDiscount(checkoutID));
      toast({
        id: `applyDiscount-${discount.discountID}`,
        title: discountApplied,
        onUndo: handleUndo,
      });

      return { valid: true, payload: true, error: null };
    } catch (error) {
      return { valid: false, payload: false, error };
    }
  };

// Remove Discount and redispatch financials
export const removeDiscount =
  (checkoutID: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    try {
      const visitKey = getState().visitor.currentVisitKey;
      const { discountCode, discountID } = getState().checkout;
      await api.removeDiscount(visitKey!, checkoutID);
      await dispatch(getCheckoutFinancials(checkoutID));
      dispatch(setCheckoutDiscount({ discountCode: null, discountID: null }));

      const discountRemoved = intl.formatMessage({
        id: 'checkout.thunks.discountRemoved',
        defaultMessage: 'Discount removed!',
      });
      const handleUndo = async () =>
        await dispatch(applyDiscount(checkoutID, { discountCode, discountID }));
      toast({
        id: `removeDiscount-${checkoutID}`,
        title: discountRemoved,
        onUndo: handleUndo,
      });

      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

export const setEditReqImageOptions = (): Thunk => (dispatch, getState) => {
  const checkoutVisitKeys = getState().checkout.checkoutVisitKeys;
  const cartMap = getState().cart.cartMap;
  const { galleryMap, priceSheetMap } = getState().gallery;

  const map: RequiredImageOptionMap = {};
  if (!checkoutVisitKeys) {
    return map;
  }
  for (const visitKey of checkoutVisitKeys) {
    const { isPreOrder, images, priceSheetID, type } = galleryMap[visitKey];
    const { imageOptionMap, packageImageOptionMap } = priceSheetMap[priceSheetID];
    const { cartImageOptions } = cartMap[visitKey];
    const cartImageMap = getCartBackgroundsKeyedByImage(cartMap[visitKey]);
    const imageNames = isPreOrder ? [PREORDER] : Object.keys(cartImageMap);
    const requiredOptions = Object.values(imageOptionMap).filter(
      o => o.requirementType === 'required' && !packageImageOptionMap.has(o.id),
    );

    for (const cartImage of imageNames) {
      for (const option of requiredOptions) {
        if (
          type !== 'standard' &&
          !isPreOrder &&
          !validateImageRequirement(images[cartImage], option.imageRequirementType)
        ) {
          continue;
        }

        if (!map[visitKey]) {
          map[visitKey] = {};
        }

        if (!map[visitKey][cartImage]) {
          map[visitKey][cartImage] = {};
        }

        const cartOption = cartImageOptions.find(
          cio =>
            cio.priceSheetOptionID === option.id &&
            (isPreOrder || cio.images.some(i => i.imageName === cartImage)),
        );

        map[visitKey][cartImage][option.id] = cartOption?.optionID;
      }
    }
  }
  dispatch(setEditReqImageOptionsMap(map));
};

/**
 * inverts the image > options map and reassumbles the data needed to reconcile against
 * currently existing cart image options
 */
export const saveRequiredImageOptions = (): PromiseThunk<null> => async (_, getState) => {
  const { editReqImageOptionsMap: map } = getState().checkout;
  const cartMap = getState().cart.cartMap;
  const { galleryMap, priceSheetMap } = getState().gallery;

  // step 1. invert the requiredImageOptionData map
  try {
    // invert requiredImageOptionData
    // map images to selections on an optiongroup
    // structure: visitKey > optiongroupID > selection > images[]
    const invertedMap: Record<string, Record<number, Record<number, string[]>>> = {};

    for (const [visitKey, cartImages] of Object.entries(map)) {
      invertedMap[visitKey] = {};

      for (const [imageName, options] of Object.entries(cartImages)) {
        for (const [optionGroupID, selectionID] of Object.entries(options)) {
          const ogID = parseInt(optionGroupID);
          if (!invertedMap[visitKey][ogID]) {
            invertedMap[visitKey][ogID] = {};
          }
          // no selectionID means an opt out (null for toggle)
          // TODO: can build in extra validation (like checking for undefined) later
          if (selectionID) {
            const imagesForSelection = invertedMap[visitKey][ogID][selectionID] || [];
            invertedMap[visitKey][ogID][selectionID] = [...imagesForSelection, imageName];
          }
        }
      }
    }

    // go through invertedmap and find cart image option matches
    // execute CRUD operations based on whether an existing cart image option exists or not
    for (const [visitKey, options] of Object.entries(invertedMap)) {
      const { cartImageOptions } = cartMap[visitKey];
      const { isPreOrder, priceSheetID } = galleryMap[visitKey];
      const { imageOptionMap } = priceSheetMap[priceSheetID];

      for (const [optionGroupID, selections] of Object.entries(options)) {
        const ogID = parseInt(optionGroupID); // technically the ps option group id

        for (const [selectionID, images] of Object.entries(selections)) {
          const cartImages = isPreOrder
            ? [{ imageName: null }]
            : images.map(i => ({ imageName: i }));
          const sID = parseInt(selectionID);

          const cartMatch = cartImageOptions.find(
            cio => cio.optionID === sID && cio.priceSheetOptionID === ogID,
          );

          // 1. no match = create
          if (!cartMatch) {
            const option = imageOptionMap[ogID];
            const selection = option.selections.find(s => s.catalogOptionID === sID);

            const newCartIO = shapeCartImageOption(option, selection!, cartImages);
            await api.createCartImageOption(visitKey, newCartIO);
          }

          //2. if match + images = update
          else if (cartMatch && images.length) {
            const updatedCartIO = { ...cartMatch, images: cartImages } as CartImageOption;
            await api.updateCartImageOption(visitKey, updatedCartIO);
          }
          // 3. match but no images = delete
          else if (cartMatch && !images.length) {
            await api.deleteCartImageOption(visitKey, cartMatch.id);
          }
        }
      }

      // for each visitkey, loop cart image options
      // find the local one and see if its the same
      const visitRequiredImgOptionData = map[visitKey];
      for (const cartImageOption of cartImageOptions) {
        // Skip optional image options since they won't exist in the required data
        if (imageOptionMap[cartImageOption.priceSheetOptionID]?.requirementType === 'optional') {
          continue;
        }

        const toDelete = !cartImageOption.images.some(
          i =>
            visitRequiredImgOptionData[i.imageName]?.[cartImageOption.priceSheetOptionID] ===
            cartImageOption.optionID,
        );
        if (toDelete) {
          await api.deleteCartImageOption(visitKey, cartImageOption.id);
        }
      }
    }

    return { valid: true, payload: null, error: null };
  } catch (error) {
    console.error('Unable to save required image options', error);
    return { valid: false, payload: null, error: null };
  }
};
