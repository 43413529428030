import { CatalogProductNode } from 'iq-api-client';
import { ImageNodeDefaultImage } from '../../../shop-api-client/models/Cart';
import { Gallery } from '../slices/gallery.slice';

/** finds default image for an image node
 * can optionally pass in a selected image to return as default if value is blank/no default
 */
export const getDefaultImgForNode = (gallery: Gallery, defaultImage: ImageNodeDefaultImage) => {
  const { images, groupImageMap, sortedGroupIDs, subjectImageNames, yearbookSelections } = gallery;

  if (defaultImage === 'subject.primaryImage') {
    return Object.values(images).find(i => i.isPrimary);
  }

  if (defaultImage === 'subject.yearbook1Image') {
    return images[yearbookSelections?.yearbookSelection1 || ''];
  }

  if (defaultImage === 'subject.yearbook2Image') {
    return images[yearbookSelections?.yearbookSelection2 || ''];
  }

  if (defaultImage === 'subject.groupImage') {
    return images[groupImageMap[sortedGroupIDs?.[0]]?.[0]];
  }

  if (defaultImage.includes('subject.images')) {
    // all strings look like 'subject.images[0]'
    const index = parseInt(defaultImage[15]);
    return images[subjectImageNames[index]];
  }
};

/**
 * eval if catalog node's default image makes it a job image
 */
export const isJobImageNode = (node: CatalogProductNode) =>
  node.type === 'image' && node.defaultImage?.split('.')?.[0] === 'gallery';
