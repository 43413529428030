import { Box } from '@chakra-ui/react';
import React from 'react';
import { FaRegUser } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAllGalleries } from '../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import NavigationActionWithPin from '../NavigationActionWithPin';
import MultiGalleryTooltip from './MultiGalleryTooltip';

const MultiGalleryButton = () => {
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const galleries = useSelector(selectAllGalleries);

  const intl = useIntl();

  const ariaLabel = intl.formatMessage({
    id: 'multiGalleryButton.ariaSwitchGallery',
    defaultMessage: 'Switch Gallery',
  });

  return (
    <MultiGalleryTooltip>
      <Box marginRight="5px">
        <NavigationActionWithPin
          aria-label={ariaLabel}
          data-test="multi-gallery-button"
          leftIcon={FaRegUser}
          pinContent={
            //TODO: stop dispatching unlogged in lite galleries to redux and then come back to fix this
            galleries.filter(g => g.priceSheetID).length > 1 ? galleries.length : undefined
          }
          pinStyles={{ backgroundColor: 'grey.9' }}
          to={`/${currentVisitKey}/galleries/available`}
        />
      </Box>
    </MultiGalleryTooltip>
  );
};

export default MultiGalleryButton;
