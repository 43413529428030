import { createSelector } from '@reduxjs/toolkit';
import { ShopBackground, ShopBackgroundSet } from '../../../shop-api-client';
import { RootState } from '../store';
import { selectPriceSheet } from './priceSheet.selectors';

// Select bg map
export const selectBackgroundsMap = createSelector(
  [
    (state: RootState) => state.gallery.priceSheetMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (state: RootState) => state.gallery.galleryMap,
    (_, visitKey?: string) => visitKey,
  ],
  (priceSheetMap, currentVisitKey, galleryMap, visitKey) => {
    if (!galleryMap[visitKey || currentVisitKey!]) {
      return {} as Record<string, ShopBackground>;
    }

    const { priceSheetID } = galleryMap[visitKey || currentVisitKey!];
    const { backgroundSets } = priceSheetMap[priceSheetID];

    return Object.values(backgroundSets).reduce<Record<string, ShopBackground>>((map, set) => {
      set.backgrounds.forEach(bg => {
        map[bg.id] = bg;
      });
      return map;
    }, {});
  },
);

// Select bg set map
export const selectBackgroundSetMap = createSelector(selectPriceSheet, ({ backgroundSets }) =>
  backgroundSets.reduce<Record<string, ShopBackgroundSet>>((map, set) => {
    map[set.id] = set;
    return map;
  }, {}),
);

// Maps bg ID to set ID
export const selectBackgroundToSetMap = createSelector(selectPriceSheet, ({ backgroundSets }) =>
  Object.values(backgroundSets).reduce<Record<string, number>>((map, set) => {
    set.backgrounds.forEach(bg => {
      map[bg.id] = set.id;
    });

    return map;
  }, {}),
);

// Select all BGs as an array. Sets are inserted with respect to their ordering on the pricesheet
export const selectAllBackgrounds = createSelector(selectPriceSheet, ({ backgroundSets }) => {
  return backgroundSets.reduce<ShopBackground[]>((result, set) => {
    result.push(...set.backgrounds);
    return result;
  }, []);
});
