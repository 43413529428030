import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectCatalog = (state: RootState) => state.catalog;

/**
 * select a renderer product via item id
 */
export const selectRendererProduct = createSelector(
  [
    (state: RootState) => state.catalog.rendererProductMap,
    (_, catalogProductID?: number) => catalogProductID,
  ],
  (rendererProductMap, catalogProductID) => {
    if (!catalogProductID) {
      return;
    }

    return rendererProductMap[catalogProductID];
  },
);
