import { Button } from '@chakra-ui/button';
import { ButtonProps } from '@chakra-ui/react';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopImage } from '../../../../../shop-api-client';
import { selectBackgroundsMap } from '../../../../redux/selectors/background.selectors';
import { selectCart } from '../../../../redux/selectors/cart.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { addFavorite, deleteFavorite } from '../../../../redux/thunks/cart.thunks';
import FavoriteIcon from '../../../../shared/components/FavoriteIcon';
import { Params } from '../../../../shared/types/router';

interface Props extends ButtonProps {
  backgroundID?: number;
  image: ShopImage | null;
}

const FavoriteButton = ({ backgroundID, image, ...rest }: Props) => {
  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { shopFavorites } = useAppSelector(state => selectCart(state, key));
  const backgroundsMap = useSelector(selectBackgroundsMap);

  const isFavorite = useCallback(() => {
    if (!shopFavorites.length || !image) {
      return;
    }

    if (image.isGreenScreen) {
      return shopFavorites.find(
        fav => fav.imageName === image.internalName && fav.backgroundID === backgroundID,
      );
    }
    return shopFavorites.find(fav => fav.imageName === image.internalName);
  }, [shopFavorites, backgroundID, image]);

  const handleFavorite = useCallback(() => {
    const favorite = isFavorite();

    if (favorite) {
      dispatch(deleteFavorite(key, favorite));
    } else {
      dispatch(addFavorite(key, image!, backgroundID ? backgroundsMap[backgroundID] : null));
    }
  }, [backgroundsMap, dispatch, key, isFavorite, backgroundID, image]);

  const ref = useRef(handleFavorite);

  useEffect(() => {
    ref.current = handleFavorite;
  }, [handleFavorite]);

  const throttleFavorite = useMemo(() => {
    const invokeRef = () => {
      ref.current?.();
    };
    return throttle(invokeRef, 1000);
  }, []);

  if (!image) {
    return null;
  }

  return (
    <Button
      fontFamily="ITC Avant Garde Gothic Book"
      fontWeight="light"
      leftIcon={<FavoriteIcon isFavorite={!!isFavorite()} />}
      onClick={throttleFavorite}
      variant="secondary"
      {...rest}
    >
      {intl.formatMessage({
        id: 'imageGrid.favorite',
        defaultMessage: 'Favorite',
      })}
    </Button>
  );
};

export default FavoriteButton;
