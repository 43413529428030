import { Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  heading: string;
  isActive: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isReady?: boolean; // TODO: ****** REMOVE OPTIONAL WHEN RELATED WORK IS DONE ********
  onClick?(): void;
}

const WizardStepHeading = ({ heading, isActive, isReady, onClick }: Props) => {
  const color = isActive ? 'brand' : isReady ? 'black' : 'grey.3';
  return (
    <Text
      borderBottomWidth={isActive ? 3 : 0}
      borderColor="brand"
      color={color}
      fontSize="sm"
      fontWeight="bold"
      onClick={isReady ? onClick : undefined}
      marginX={2}
      paddingBottom={4}
      paddingX={4}
      whiteSpace="nowrap"
      _hover={{
        cursor: isReady ? 'pointer' : 'default',
      }}
    >
      {heading}
    </Text>
  );
};

export default WizardStepHeading;
