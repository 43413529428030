export enum DownloadTokenStateEnum {
  Error = 'error',
  Expired = 'expired',
  NotFound = 'notFound',
  Valid = 'valid',
}
export enum RegenerationTokenState {
  Error = 'error',
  MessageSent = 'messageSent',
  NotFound = 'notFound',
}

export interface DigitalDownload {
  accountID: string;
  token?: string;
  tokenState: DownloadTokenStateEnum;
}

export interface TokenRegenerationResults {
  state: RegenerationTokenState;
}
