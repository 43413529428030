import { Button, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectCartMap } from '../../../../redux/selectors/cart.selectors';
import { selectGalleryMap } from '../../../../redux/selectors/gallery.selectors';
import { selectVisitor } from '../../../../redux/selectors/visitor.selectors';
import { useAppDispatch } from '../../../../redux/store';
import { createCheckout } from '../../../../redux/thunks/checkout.thunks';
import { Params } from '../../../../shared/types/router';
import CartsModal from '../../../Carts/CartsModal';
import { countCartItems } from '../../../Carts/utils';
import { GAItem, getGAItems } from '../../../../shared/utils';
import { selectAccount } from '../../../../redux/selectors/account.selectors';

const RemainingCarts = () => {
  const { currentVisitKey } = useSelector(selectVisitor);
  const cartMap = useSelector(selectCartMap);
  const galleryMap = useSelector(selectGalleryMap);
  const { accountID } = useSelector(selectAccount);

  const [checkingOut, setCheckingOut] = useState(false);
  const [possibleCheckouts, setPossibleCheckouts] = useState<string[][]>();
  const [showModal, setShowModal] = useState(false);

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const handleViewCartBtn = () => history.push(`/${currentVisitKey}/carts`);

  const handleCheckout = async (visitKeys?: string[]) => {
    setCheckingOut(true);
    const result = await dispatch(createCheckout(visitKeys));
    if (!result) {
      setCheckingOut(false);
      return;
    }
    if (result.checkout) {
      // Checkout automatically created, redirect user:
      history.push(`/${key}/checkout/${result.checkout.id}`);

      //Google Analytic begin_checkout event
      const gaItems: GAItem[] = getGAItems(cartMap, accountID);
      window.gtag('event', 'begin_checkout', {
        items: gaItems,
      });
    } else {
      setShowModal(true);
      // Checkout could not be automatically created, show possible checkouts:
      setPossibleCheckouts(result.combinations);
      setCheckingOut(false);
    }
  };

  const handleContinueCheckoutBtn = () => {
    if (!checkingOut) {
      handleCheckout();
    }
  };

  const renderCartsList = () =>
    Object.entries(cartMap).map(([visitKey, cart]) => {
      const itemCount = countCartItems(cart);
      if (itemCount === 0 || !galleryMap[visitKey]) {
        return null;
      }

      const { title, subjectID } = galleryMap[visitKey];
      const subjectIDText = intl.formatMessage(
        {
          id: 'checkout.subjectID',
          defaultMessage: 'Subject #{subjectID}',
        },
        { subjectID },
      );
      const itemCountText = intl.formatMessage(
        {
          id: 'checkout.itemCount',
          defaultMessage: '{itemCount} Item{plural}',
        },
        { itemCount, plural: itemCount > 1 ? 's' : '' },
      );

      return (
        <ListItem fontSize="sm" key={visitKey}>{`${title} ${
          subjectID ? `/ ${subjectIDText}` : ''
        } (${itemCountText})`}</ListItem>
      );
    });

  return (
    <Flex alignItems="center" flexFlow="column" marginBottom={[1.5, 8]}>
      <Text fontSize="sm" fontWeight="bold" marginBottom={3.5}>
        {intl.formatMessage({
          id: 'checkout.additionalCartItems',
          defaultMessage: 'Your additional cart items are ready for checkout',
        })}
      </Text>

      <UnorderedList marginBottom={5}>{renderCartsList()}</UnorderedList>

      <Flex marginTop={4}>
        <Button
          color="blue.4"
          fontFamily="inherit"
          fontSize="md"
          height="50px"
          marginRight={4}
          onClick={handleViewCartBtn}
          variant="white"
        >
          {intl.formatMessage({
            id: 'checkout.viewYourCart',
            defaultMessage: 'View Your Cart',
          })}
        </Button>

        <Button onClick={handleContinueCheckoutBtn}>
          {intl.formatMessage({
            id: 'checkout.continueCheckout',
            defaultMessage: 'Continue Checkout',
          })}
        </Button>
      </Flex>

      <CartsModal
        checkingOut={checkingOut}
        handleCheckout={handleCheckout}
        possibleCheckouts={possibleCheckouts}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </Flex>
  );
};

export default RemainingCarts;
