import React from 'react';
import { useDispatch } from 'react-redux';
import { ProductOption, ShopProduct } from '../../../../../../shop-api-client';
import {
  CartProduct,
  CartProductOption,
  CreateCartProductReq,
} from '../../../../../../shop-api-client/models/Cart';
import { selectConfiguration } from '../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheetItem } from '../../../../../redux/selectors/priceSheet.selectors';
import { setOptedOutProductOptions } from '../../../../../redux/slices/configurations.slice';
import { useAppSelector } from '../../../../../redux/store';
import Option from '../../../../../shared/components/Option';
import { getCartItemToOptionKey } from '../../../../Carts/utils';
import { shapeCartProductOption } from '../../utils';

interface Props {
  editProduct: CartProduct | CreateCartProductReq;
  hideTextSave?: boolean;
  isCartItem?: boolean;
  isInvalid?: boolean;
  option: ProductOption;
  onSave(editProduct: CreateCartProductReq): void;
}

/**
 * Renders a product option for configuration
 */
const ProductOptionEditor = ({
  editProduct,
  hideTextSave,
  isCartItem,
  isInvalid,
  onSave,
  option,
}: Props) => {
  const { optedOutProductOptions } = useAppSelector(selectConfiguration);
  // TODO: this might be able to be moved into the parent and passed as props if it's doable later
  /** for text node product options, need the catalog ID */
  const priceSheetItem = useAppSelector(state =>
    selectPriceSheetItem(state, editProduct.priceSheetItemID),
  );

  const { catalogOptionGroupID } = option;
  const dispatch = useDispatch();
  const productToOption = getCartItemToOptionKey(editProduct.id!, catalogOptionGroupID);
  const optedOut = optedOutProductOptions[productToOption];

  /** the product option on the edit product */
  const editProductOption = editProduct?.options?.find(
    editOption => editOption.optionGroupID === catalogOptionGroupID,
  );

  const handleApplyProductOption = (selectionID: number | false, value: string) => {
    // create a map of all the product options
    const editProductOptionMap =
      (editProduct?.options as CartProductOption[])?.reduce<Record<number, CartProductOption>>(
        (map, editOption) => {
          map[editOption.optionGroupID] = editOption;
          return map;
        },
        {},
      ) || {};

    if (selectionID) {
      const newProductOption = shapeCartProductOption(
        catalogOptionGroupID,
        selectionID,
        value,
      ) as CartProductOption;

      editProductOptionMap[catalogOptionGroupID] = newProductOption;
    } else if (editProductOptionMap[catalogOptionGroupID]) {
      delete editProductOptionMap[catalogOptionGroupID];
    }

    if (!(optedOut && selectionID === false) && option.type === 'boolean') {
      dispatch(
        setOptedOutProductOptions(getCartItemToOptionKey(editProduct.id!, catalogOptionGroupID)),
      );
    }

    onSave({ ...editProduct, options: Object.values(editProductOptionMap) });
  };

  return (
    <Option
      catalogProductID={(priceSheetItem as ShopProduct)?.catalogProductID}
      defaultText={editProductOption?.value}
      heading={option.name}
      hideTextSave={hideTextSave}
      isInvalid={!optedOut && isInvalid}
      option={option}
      selectionID={editProductOption?.optionID || ((isCartItem || optedOut) && false)}
      setSelectionID={handleApplyProductOption}
      expanded={!!editProductOption?.optionID}
    />
  );
};

export default ProductOptionEditor;
