import { Box, chakra, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopCategoryBehavior, ShopCategoryCondition } from '../../../../../shop-api-client';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { BehaviorStatus } from '../../../../redux/slices/gallery.slice';
import ButtonLink from '../../../../shared/components/ButtonLink';
import CheckMarkIcon from '../../../../shared/components/CheckMarkIcon';
import { SHOP_CATEGORY } from '../../../../shared/constants/labels.constants';
import { Params } from '../../../../shared/types/router';

interface Props {
  behavior: ShopCategoryBehavior;
  behaviorStatus: BehaviorStatus;
  isCompact: boolean;
  optionNumber: number;
  showHeading: boolean;
}

// WIP: Design for mobile
const OfferOption = ({ behavior, behaviorStatus, isCompact, optionNumber, showHeading }: Props) => {
  const { categories } = useSelector(selectPriceSheet);
  const { key } = useParams<Params>();
  const intl = useIntl();

  const { conditionStatusMap } = behaviorStatus;

  // Returns the text that should be displayed for the condition type
  const renderRequirement = (condition: ShopCategoryCondition) => {
    const { isMet } = conditionStatusMap[condition.id];
    if (condition.type === 'cartAmount') {
      return intl.formatMessage(
        {
          id: 'offerOption.purchaseAtleastMin',
          defaultMessage: 'Purchase at least ${minCartAmount} worth of items{appendedDash}',
        },
        {
          minCartAmount: condition.minCartAmount,
          appendedDash: isMet ? '\u00A0\u00A0' : ' ',
        },
      );
    }

    return (
      <>
        {intl.formatMessage(
          {
            id: 'offerOption.purchaseItemFrom',
            defaultMessage: 'Purchase an item from{space}',
          },
          { space: '\u00A0' },
        )}
        {
          <chakra.span fontStyle="italic">
            {categories[condition.requiredCategoryID].name}
          </chakra.span>
        }
      </>
    );
  };

  const renderStatus = (condition: ShopCategoryCondition) => {
    const { isMet, subtotal } = conditionStatusMap[condition.id];

    if (isMet) {
      return intl.formatMessage({
        id: 'offerOption.done',
        defaultMessage: 'Done',
      });
    }
    if (condition.type === 'cartAmount' && subtotal) {
      // If Min. Cart Amount condition, show the remaining subtotal amount necessary to unlock items
      return intl.formatMessage(
        {
          id: 'offerOption.amountRemaining',
          defaultMessage: " - You're only ${amountRemaining} away",
        },
        {
          amountRemaining: (condition.minCartAmount - subtotal).toFixed(2),
        },
      );
    }
    return '';
  };

  // Renders a single condition
  const renderCondition = (condition: ShopCategoryCondition) => {
    const { isMet } = conditionStatusMap[condition.id];
    return (
      <Flex key={condition.id}>
        <Flex
          align="center"
          marginBottom={1}
          marginTop={showHeading ? 3 : 2}
          marginX={showHeading ? 2 : 0}
        >
          <CheckMarkIcon
            backgroundColor={conditionStatusMap[condition.id].isMet ? '#268a00' : 'white'}
            borderColor="grey.3"
            borderWidth={conditionStatusMap[condition.id].isMet ? 'none' : '1px'}
            data-test="offer-option-completed-check-icon"
            iconProps={{
              color: conditionStatusMap[condition.id].isMet ? 'white' : 'grey.3',
              fontSize: '22px',
            }}
          />
          <Flex
            align={isCompact ? 'flex-start' : 'center'}
            direction={isCompact ? 'column' : 'row'}
          >
            <Text
              fontSize={isCompact ? '15px' : 'lg'}
              marginX="2"
              data-test="offer-option-condition-text"
            >
              <chakra.span
                color={isMet ? 'grey.4' : 'black'}
                textDecoration={isMet ? 'line-through' : 'none'}
              >
                {renderRequirement(condition)}
              </chakra.span>
              {(isMet || condition.type === 'cartAmount') && (
                <chakra.span fontFamily="ITC Avant Garde Gothic Demi" marginLeft={2}>
                  {renderStatus(condition)}
                </chakra.span>
              )}
            </Text>
            {!isMet && condition.type === 'requiredCategory' && (
              <ButtonLink
                data-test="offer-option-shop-category-link"
                label={SHOP_CATEGORY}
                marginLeft={2}
                marginTop={isCompact ? 4 : 0}
                minWidth="126px"
                to={`/${key}/shop/${condition.requiredCategoryID}`}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Box key={behavior.id} marginX={1} marginY={showHeading ? 7 : 0}>
      {showHeading && (
        <Heading
          fontSize={isCompact ? 'md' : 'lg'}
          marginBottom="2.5"
          whiteSpace="nowrap"
          data-test="offer-option-progress-text"
        >{`Option ${optionNumber} - (${behaviorStatus.progressPercentage}% Complete)`}</Heading>
      )}
      {behavior.conditions.map(condition => renderCondition(condition))}
    </Box>
  );
};

export default OfferOption;
