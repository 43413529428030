import { Flex, FlexProps, Icon, IconProps } from '@chakra-ui/react';
import React from 'react';
import { HiCheck } from 'react-icons/hi';

interface Props extends FlexProps {
  iconProps?: IconProps;
  size?: FlexProps['height'];
}

const CheckMarkIcon = ({ iconProps, size = '30px', ...rest }: Props) => {
  return (
    <Flex
      align="center"
      alignSelf="flex-start"
      backgroundColor="brand"
      borderRadius="50%"
      height={size}
      justify="center"
      width={size}
      minWidth={size} // Prevents icon from becoming squished when crowded
      {...rest}
    >
      <Icon as={HiCheck} color="white" fontSize="20px" {...iconProps} />
    </Flex>
  );
};

export default CheckMarkIcon;
