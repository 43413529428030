import { Button, Collapse, Flex, FlexProps, Heading, IconButton, Spacer } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useIntl } from 'react-intl';

interface Props extends FlexProps {
  actionName?: string;
  actionOnClick?(): void;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
  direction?: 'column' | 'row';
  heading: string;
  isCollapsible?: boolean;
  showBorder?: boolean;
  width?: number;
}

const SidebarSection = ({
  actionName,
  actionOnClick = noop,
  children,
  defaultCollapsed = false,
  direction = 'column',
  heading,
  isCollapsible,
  showBorder,
  width,
  ...rest
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(!defaultCollapsed);
  const intl = useIntl();

  const toggleIsExpanded = () => {
    if (isCollapsible) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Flex
      direction="column"
      width={width}
      borderBottomColor={showBorder ? 'grey.2' : 'transparent'}
      borderBottomWidth="1px"
    >
      <Flex onClick={toggleIsExpanded}>
        <Heading marginLeft={{ base: '25px', md: '35px' }} marginY="15px" size="sm">
          {heading}
        </Heading>
        <Spacer />
        {/* TODO: Style this action (current uses include 'Clear Filters' and 'Compare') */}
        {!!actionName && actionOnClick && (
          <Button variant="link" onClick={actionOnClick} data-test="sidebar-section-clear-filters">
            {actionName}
          </Button>
        )}
        {isCollapsible && (
          <IconButton
            aria-label={intl.formatMessage({
              id: 'sidebarSection.toggleCollapse',
              defaultMessage: 'Toggle collapse',
            })}
            icon={isExpanded ? <FiChevronUp /> : <FiChevronDown />}
            variant="nav"
          />
        )}
      </Flex>
      <Collapse in={isExpanded} unmountOnExit>
        <Flex direction={direction} paddingBottom="20px" wrap="wrap" {...rest}>
          {children}
        </Flex>
      </Collapse>
    </Flex>
  );
};

export default SidebarSection;
