import {
  Box,
  Button,
  Flex,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ImageNode } from 'iq-product-render';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr';
import { CartImageNodeCrop } from '../../../../../../../shop-api-client/models/Cart';
import ImageCanvas from '../../../../../../shared/components/ImageCanvas';
import { PRODUCTS_GUTTER } from '../../../../constants';
import {
  ARIA_RESET,
  ARIA_ROTATE_LEFT,
  ARIA_ROTATE_RIGHT,
  ARIA_ZOOM_IN,
  ARIA_ZOOM_OUT,
  CROP_TOOL_ROTATE,
  CROP_TOOL_ZOOM,
  RESET_CROP,
} from '../../../constants';
import {
  Coordinates,
  DEFAULT_COORDINATES,
  getDistance,
  getPercentChange,
  getPercentChangeNewValue,
  initializeCropValuesSingleNode,
} from '../utils';

interface Props {
  background: HTMLImageElement | null;
  handleCrop(crop: CartImageNodeCrop): void;
  image: HTMLImageElement;
  imageNode: ImageNode;
  initialCrop?: CartImageNodeCrop;
  inlineControls?: boolean;
  maxHeight?: number;
  maxWidth?: number;
}

/**
 * CropEditorTool for single nodes
 * Unlike the multi-node calculations, this one is based on rotating the node, while the image stays static
 */

//TODO: see if shared functions between single + multi crop tools can be compiled and shared
const CropEditorToolSingleNode = ({
  background,
  handleCrop,
  image,
  imageNode,
  initialCrop,
  inlineControls,
  maxHeight,
  maxWidth,
}: Props) => {
  const {
    cropSize,
    cropSize90,
    initialOffset,
    initialRotation,
    initialScale,
    scaleFactor,
    scaleFactor90,
  } = useMemo(
    () => initializeCropValuesSingleNode(imageNode, image, initialCrop, maxHeight, maxWidth),
    [imageNode, image, initialCrop, maxHeight, maxWidth],
  );

  // Crop derivatives
  const [scale, setScale] = useState(initialScale);
  const [offset, setOffset] = useState<Coordinates>(initialOffset);

  // User
  const [rotation, setRotation] = useState(initialRotation);
  const [isDragging, setIsDragging] = useState(false);
  const [mouseCoordinates, setMouseCoordinates] = useState<Coordinates>(DEFAULT_COORDINATES);
  const [touchCoordinates, setTouchCoordinates] = useState<{
    a: Coordinates;
    b: Coordinates;
  }>({ a: DEFAULT_COORDINATES, b: DEFAULT_COORDINATES });
  const [isTouchScaling, setIsTouchScaling] = useState(false);

  const lastTouchScaling = useRef(Date.now());
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  // Reference to assign true wheel event to prevent scrolling of desktop page
  const wrapperRef = useRef<HTMLDivElement>(null);

  const crop = rotation % 180 ? cropSize90 : cropSize;

  const handleReset = useCallback(() => {
    setScale(initialScale);
    setOffset(initialOffset);
    setRotation(initialRotation);
  }, [initialScale, initialOffset, initialRotation]);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseEnd);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseEnd);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  });

  useEffect(() => {
    const element = wrapperRef.current;
    element?.addEventListener('wheel', handleScale);

    return () => {
      element?.removeEventListener('wheel', handleScale);
    };
  });

  useEffect(() => {
    const scaledImg = { w: image.width * scale, h: image.height * scale };

    let cropX = ((cropSize.w / 2 + Math.abs(offset.x)) / scaledImg.w) * 100;
    let cropY = ((cropSize.h / 2 + Math.abs(offset.y)) / scaledImg.h) * 100;
    let cropW = (cropSize.w / scaledImg.w) * 100;
    let cropH = (cropSize.h / scaledImg.h) * 100;
    const orientation = rotation;

    if (rotation % 180) {
      cropX = ((cropSize90.w / 2 + Math.abs(offset.x)) / scaledImg.w) * 100;
      cropY = ((cropSize90.h / 2 + Math.abs(offset.y)) / scaledImg.h) * 100;
      cropW = (cropSize90.w / scaledImg.w) * 100;
      cropH = (cropSize90.h / scaledImg.h) * 100;
    }

    handleCrop({ cropX, cropY, cropW, cropH, orientation });
  }, [cropSize, cropSize90, handleCrop, image, offset, rotation, scale]);

  useEffect(() => {
    window.addEventListener('resize', handleReset);
    handleReset();
    return () => {
      window.removeEventListener('resize', handleReset);
    };
  }, [handleReset]);

  /**
   * Return the maximum x,y offsets based on a provided scale and rotation
   */
  const getMaxmimumOffset = useCallback(
    (scale: number, rotation: number) => {
      const scaledImg = { h: (image?.height || 0) * scale, w: (image?.width || 0) * scale };

      // offset value needs to be between the diff between the crop window
      // and the scaled image size in upper and lower bounds
      let dw = Math.abs(scaledImg.w - cropSize.w);
      let dh = Math.abs(scaledImg.h - cropSize.h);
      let maximumOffsetX = -dw;
      let maximumOffsetY = -dh;

      if (rotation % 180) {
        // when its rotated 90/270, we swap crop width and height
        dw = Math.abs(scaledImg.w - cropSize90.w);
        dh = Math.abs(scaledImg.h - cropSize90.h);
        maximumOffsetX = -dw;
        maximumOffsetY = -dh;
      }

      return {
        maximumOffsetX,
        maximumOffsetY,
      };
    },
    [cropSize, cropSize90, image],
  );

  /**
   * Return the minimum scale that will fit the node, based on the current rotation.
   *
   * These scale factors are calculated based on the dimensions of the node and image
   * and will ensure that the image always covers the node, and never leaves any empty space.
   */
  const getMinScale = useCallback(
    () => (rotation % 180 ? scaleFactor90 : scaleFactor),
    [rotation, scaleFactor, scaleFactor90],
  );

  /**
   * Validate the current offset is within acceptable bounds and that it
   * always fills the node given offset, scale, imageSize and rotation
   */
  const validate = useCallback(
    (offset: Coordinates, scale: number, rotation: number) => {
      const { maximumOffsetX, maximumOffsetY } = getMaxmimumOffset(scale, rotation);

      const validX = offset.x >= maximumOffsetX && offset.x <= 0;
      const validY = offset.y >= maximumOffsetY && offset.y <= 0;
      // returns if valid offset or not, and max offset available
      return { isValid: validX && validY, maxOffset: { x: maximumOffsetX, y: maximumOffsetY } };
    },
    [getMaxmimumOffset],
  );

  /**
   * Calculates the new offset given a change in x + y values
   *
   * Also validates the new offset based on current scale and rotation
   * dx and dy are calculated based on the previous position minus the current input coordinates
   */
  const handleDrag = useCallback(
    (dx: number, dy: number) => {
      const newOffset = { x: offset.x - dx, y: offset.y - dy };
      const { isValid } = validate(newOffset, scale, rotation);

      if (isValid) {
        setOffset(newOffset);
      }
    },
    [offset, scale, validate, rotation],
  );

  /**
   * Updates the scale and recalculates offset
   *
   * This function takes a new scale value and the minimum it needs to be
   * and subsequently calculates, validates and adjusts the new offset as needed.
   *
   * It will attempt to keep the offset as centered as possible, falling back to the
   * bounds when it is not.
   */
  const updateScale = useCallback(
    (requestedScale: number) => {
      if (!image) {
        return;
      }

      // scale value needs a minimum of scaling factor and max 1
      // we have made the assumption that the viewport is always smaller than the intrinsic image size
      // so scale is always <1
      const minScale = getMinScale();
      const newScale = Math.max(minScale, requestedScale);
      if (newScale > 1) {
        return;
      }

      const deltaScale = newScale - scale;

      // Keep the offset relatively centered by subtracting half of the change in scale
      const newOffset = {
        x: offset.x - (deltaScale * image.width) / 2,
        y: offset.y - (deltaScale * image.height) / 2,
      };

      // validate offset
      const { isValid, maxOffset } = validate(newOffset, newScale, rotation);
      if (!isValid) {
        // adjust offset based on maximum allowed offset
        const validX = newOffset.x >= maxOffset.x && newOffset.x <= 0;
        const validY = newOffset.y >= maxOffset.y && newOffset.y <= 0;
        if (!validX) {
          newOffset.x = newOffset.x < maxOffset.x ? maxOffset.x : 0;
        }
        if (!validY) {
          newOffset.y = newOffset.y < maxOffset.y ? maxOffset.y : 0;
        }
      }

      setScale(newScale);
      setOffset(newOffset);
    },
    [getMinScale, image, offset, rotation, scale, validate],
  );

  // -------------------- User Events -------------------- //

  const handleTouchStart = (e: React.TouchEvent) => {
    updateTouchCoordinates(e.touches[0], e.touches[1] || DEFAULT_COORDINATES);

    // if more than one touch, scale and drag
    if (e.touches.length > 1) {
      setIsTouchScaling(true);
    } else {
      // if only one touch, drag
      setIsDragging(true);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (e.touches.length > 1 && isTouchScaling) {
      lastTouchScaling.current = Date.now();
      // get distance between the 2 points of saved touches
      const prevDist = getDistance(touchCoordinates.a, touchCoordinates.b);
      // get distance between the 2 points of current touches
      const a = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      const b = { x: e.touches[1].clientX, y: e.touches[1].clientY };
      const currentDist = getDistance(a, b);
      // get percent change between previous and new, scale it down a little to make the zoom smoother
      const percentDistChange = getPercentChange(prevDist, currentDist);
      // get new scale based on percent change and update (get percent change val of base scale)
      const newScale = getPercentChangeNewValue(percentDistChange, getMinScale(), scale);
      // first 2 params for updatescale are ignored, pass in anything, only newScale matters
      updateScale(newScale);
      updateTouchCoordinates(e.touches[0], e.touches[1]);
    } else {
      const dx = touchCoordinates.a.x - e.touches[0].clientX;
      const dy = touchCoordinates.a.y - e.touches[0].clientY;
      handleDrag(dx, dy);
      updateTouchCoordinates(e.touches[0]);
    }
  };

  const handleTouchEnd = () => {
    setIsTouchScaling(false);
    setIsDragging(false);
  };

  const handleMouseStart = (e: React.MouseEvent) => {
    setMouseCoordinates({ x: e.nativeEvent.pageX, y: e.nativeEvent.pageY });
    setIsDragging(true);
  };

  const handleMouseEnd = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) {
      return;
    }

    const newMouseCoordinates = { x: e.pageX, y: e.pageY };
    const dx = mouseCoordinates.x - newMouseCoordinates.x;
    const dy = mouseCoordinates.y - newMouseCoordinates.y;

    setMouseCoordinates(newMouseCoordinates);
    handleDrag(dx, dy);
  };

  const handleScale = (e: WheelEvent) => {
    // The Apple/Mac mouse makes it very easy to click and drag
    // while also triggering a scroll event, so we want to disable scaling
    // while we're moving the crop box.
    if (isDragging) {
      return;
    }

    // Bind the new scale by the upper and lower bounds:
    const newScale =
      e.deltaY > 0 ? Math.max(getMinScale(), scale - 0.05) : Math.min(scale + 0.05, 1);
    setMouseCoordinates({ x: e.pageX, y: e.pageY });
    updateScale(newScale);

    // If a zoom action can occur, prevent the page from scrolling
    // TODO: QA this with Chris
    if (newScale > getMinScale() && newScale < 1) {
      e.preventDefault();
    }
  };

  const updateTouchCoordinates = (a: React.Touch, b?: React.Touch) => {
    const aCoordinates = { x: a.clientX, y: a.clientY };
    const bCoordinates = b ? { x: b.clientX, y: b.clientY } : DEFAULT_COORDINATES;

    setTouchCoordinates({ a: aCoordinates, b: bCoordinates });
  };

  // -------------------- User Buttons -------------------- //

  const handleRotate = (rotationValue: number) => {
    const rotation = Math.abs(rotationValue % 360);
    setRotation(rotation);

    // Grab the relevant scale factor, we'll reset to the lowest zoom whenever we rotate
    const rotationScaleFactor = rotation % 180 ? scaleFactor90 : scaleFactor;
    setScale(rotationScaleFactor);

    const { maximumOffsetX, maximumOffsetY } = getMaxmimumOffset(rotationScaleFactor, rotation);
    setOffset({ x: maximumOffsetX / 2, y: maximumOffsetY / 2 });
  };

  const renderResetControls = () => (
    <Button aria-label={ARIA_RESET} color="brand" onClick={handleReset} variant="link" marginY={0}>
      {RESET_CROP}
    </Button>
  );

  const renderRotateControls = () => (
    <Flex alignItems="center">
      <IconButton
        aria-label={ARIA_ROTATE_LEFT}
        borderColor="grey.2"
        borderRadius="50%"
        borderWidth="1px"
        height="30px"
        icon={<GrRotateLeft />}
        marginRight="10px"
        minWidth="30px"
        onClick={() => handleRotate(rotation + 270)}
        paddingX="0"
        variant="icon"
        width="30px"
      />
      <IconButton
        aria-label={ARIA_ROTATE_RIGHT}
        icon={<GrRotateRight />}
        onClick={() => handleRotate(rotation + 90)}
        variant="icon"
        borderColor="grey.2"
        borderRadius="50%"
        borderWidth="1px"
        height="30px"
        minWidth="30px"
        width="30px"
      />
    </Flex>
  );

  const renderZoomControls = () => (
    <Flex flex={1} maxWidth={400} width="100%">
      <IconButton
        aria-label={ARIA_ZOOM_OUT}
        icon={<FiZoomOut />}
        onClick={() => updateScale(Math.max(scale - 0.05, getMinScale()))}
        variant="icon"
      />
      <Slider
        min={getMinScale()}
        max={1}
        step={0.005}
        onChange={updateScale}
        value={scale}
        flex={1}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip hasArrow label="Zoom">
          <SliderThumb boxSize={4} background="brand" />
        </Tooltip>
      </Slider>
      <IconButton
        aria-label={ARIA_ZOOM_IN}
        icon={<FiZoomIn />}
        onClick={() => updateScale(Math.min(scale + 0.05, 1))}
        variant="icon"
      />
    </Flex>
  );

  const renderMobileTools = () => {
    return (
      <Flex justifyContent="center" alignItems="center" direction="column" width="100%">
        {renderResetControls()}
        <Flex direction="row" width="100%" paddingX={PRODUCTS_GUTTER / 2}>
          {renderRotateControls()}
          {renderZoomControls()}
        </Flex>
      </Flex>
    );
  };

  const renderDesktopTools = () => {
    if (inlineControls) {
      return (
        <Flex direction="row" justifyContent="space-evenly" width="100%" marginTop="10px">
          {renderRotateControls()}
          {renderZoomControls()}
          {renderResetControls()}
        </Flex>
      );
    }

    return (
      <Flex direction="column" width="100%">
        <Flex justifyContent="flex-end">{renderResetControls()}</Flex>
        <Box>
          <Text>{CROP_TOOL_ZOOM}</Text>
          {renderZoomControls()}
        </Box>
        <Box>
          <Text>{CROP_TOOL_ROTATE}</Text>
          {renderRotateControls()}
        </Box>
      </Flex>
    );
  };

  return (
    <Flex
      height="100%"
      width="100%"
      alignItems="center"
      direction="column"
      //TODO: use to sticky controls at bottom justifyContent={{ base: 'space-between' }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        height={crop.h}
        width={crop.w}
        position="relative"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          cursor={isDragging ? 'grabbing' : 'grab'}
          height={crop.h}
          onMouseDown={handleMouseStart}
          onTouchCancel={handleTouchEnd}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          ref={wrapperRef}
          width={crop.w}
        >
          <ImageCanvas
            background={background}
            height={crop.h}
            image={image}
            isGreenScreen={!!background}
            offset={offset}
            rotation={0}
            scale={scale}
            showGrid={isDragging}
            width={crop.w}
          />
        </Flex>
      </Flex>
      {isMobile ? renderMobileTools() : renderDesktopTools()}
    </Flex>
  );
};

export default CropEditorToolSingleNode;
