import { Button } from '@chakra-ui/button';
import {
  Box,
  Center,
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Spinner } from '@chakra-ui/spinner';
import { Textarea } from '@chakra-ui/textarea';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { api, GalleryLandingData } from '../../../shop-api-client';
import { UnsubscribeResponse } from '../../../shop-api-client/models/Unsubscribe';
import Logo from '../../shared/components/Logo';
import colors from '../../theme/colors';

interface RouteParams {
  queueMessageID: string;
}

const Unsubscribe = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [landingData, setLandingData] = useState<GalleryLandingData>();
  const [reasonType, setReasonType] = useState<number>(0);
  const [customReason, setCustomReason] = useState('');
  const [resubscribed, setResubscribed] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [unsubscribeRecord, setUnsubscribeRecord] = useState<UnsubscribeResponse>();

  const { queueMessageID } = useParams<RouteParams>();
  const intl = useIntl();

  useEffect(() => {
    if (!queueMessageID) {
      // TODO: We would need a non-marked 404 page to route them to from unsubscribe
      setError(
        intl.formatMessage({
          id: 'unsubscribe.invalidURL',
          defaultMessage: 'Invalid URL',
        }),
      );
      setLoading(false);
      return;
    }

    const init = async () => {
      const unsubscribeRecord = await api.getUnsubscribeRecord(queueMessageID);
      if (!unsubscribeRecord) {
        // We should always get back something unless this is an invalid queueMessageID:
        setLoading(false);
        setError(
          intl.formatMessage({
            id: 'unsubscribe.loadError',
            defaultMessage: 'Could not load your subscription data',
          }),
        );
        return;
      }

      const landingData = await api.getBaseGalleryData(`g${unsubscribeRecord.galleryID}`);
      setUnsubscribeRecord(unsubscribeRecord);
      setLandingData(landingData);
      setLoading(false);
    };

    init();
  }, [intl, queueMessageID]);

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value && reasonType !== 99) {
        setReasonType(99);
      }

      setCustomReason(e.target.value);
    },
    [reasonType],
  );

  const handleResubscribe = async () => {
    setSubmitting(true);

    try {
      await api.deleteUnsubscribeRecord(queueMessageID);
      setUnsubscribeRecord(prevRecord => ({
        ...prevRecord!,
        unsubscribed: null,
      }));
      setResubscribed(true);
    } catch {
      setError(
        intl.formatMessage({
          id: 'unsubscribe.resubscribeError',
          defaultMessage: 'Something went wrong while resubscribing, please try again',
        }),
      );
    }

    setSubmitting(false);
  };

  const handleUnsubscribe = async () => {
    setSubmitting(true);
    setResubscribed(false);
    // Create the record
    const result = await api
      .postUnsubscribeRecord(queueMessageID, {
        unsubscribed: !unsubscribeRecord?.unsubscribed,
        queueMessageID,
        customReason,
        reasonType,
      })
      .catch(() => null);

    if (result) {
      setUnsubscribeRecord(result);
    } else {
      setError(
        intl.formatMessage({
          id: 'unsubscribe.unsubscribeError',
          defaultMessage: 'Something went wrong while unsubscribing, please try again',
        }),
      );
    }

    setSubmitting(false);
  };

  const handleUpdateEmailPreferences = () => {
    setResubscribed(false);
    setCustomReason('');
    setReasonType(0);
  };

  const handleReload = () => {
    window.location.reload();
    return false;
  };

  const renderError = () => (
    <Box>
      <Text marginBottom={4}>{error}</Text>
      <Box textAlign="center">
        <Button variant="link" onClick={handleReload}>
          {intl.formatMessage({
            id: 'unsubscribe.reloadPage',
            defaultMessage: 'Reload this page',
          })}
        </Button>
      </Box>
    </Box>
  );

  if (loading || error || !landingData || !unsubscribeRecord) {
    return (
      <Center backgroundColor="white" height="100vh" position="absolute" top="0" width="100%">
        {error ? renderError() : <Spinner />}
      </Center>
    );
  }

  const renderResubscribe = () => (
    <>
      <Heading fontSize="20px" fontWeight="bold" marginY={4} data-test="unsubscribe-heading">
        {intl.formatMessage({
          id: 'unsubscribe.resubscribeLine1',
          defaultMessage: "We're sorry to see you go",
        })}
      </Heading>
      <Box marginY={4}>
        <Text as="span" fontWeight="bold" data-test="unsubscribe-email">
          {unsubscribeRecord.email}
        </Text>{' '}
        {intl.formatMessage({
          id: 'unsubscribe.resubscribeLine2',
          defaultMessage: 'is successfully unsubscribed from',
        })}{' '}
        <Text as="span" fontWeight="bold" data-test="unsubscribe-gallery-title">
          {landingData.baseGallery.title}
        </Text>
      </Box>
      <Text marginY={6}>
        {intl.formatMessage({
          id: 'unsubscribe.resubscribeLine3',
          defaultMessage: 'We will no longer send you emails from this gallery.',
        })}
      </Text>
      <Box marginY={6}>
        <Text fontWeight="bold">
          {intl.formatMessage({
            id: 'unsubscribe.resubscribeActionLabel',
            defaultMessage: 'Did you accidentally click unsubscribe?',
          })}
        </Text>
        <Button
          disabled={submitting}
          marginTop={6}
          minWidth="150px"
          onClick={handleResubscribe}
          paddingX={2}
          variant="primary"
          data-test="unsubscribe-button"
        >
          {submitting ? (
            <Spinner />
          ) : (
            intl.formatMessage({
              id: 'unsubscribe.resubscribeAction',
              defaultMessage: 'Resubscribe',
            })
          )}
        </Button>
      </Box>
    </>
  );

  const renderResubscribed = () => (
    <>
      <Heading
        fontSize="20px"
        fontWeight="bold"
        marginY={4}
        data-test="unsubscribe-resubscribe-successfull"
      >
        {intl.formatMessage({
          id: 'unsubscribe.resubscribedLine1',
          defaultMessage: "You've successfully resubscribed!",
        })}
      </Heading>
      <Box marginY={4}>
        <Text as="span" fontWeight="bold">
          {unsubscribeRecord.email}
        </Text>{' '}
        {intl.formatMessage({
          id: 'unsubscribe.resubscribedLine2',
          defaultMessage: 'will start receiving emails again from',
        })}{' '}
        <Text as="span" fontWeight="bold">
          {landingData.baseGallery.title}
        </Text>
      </Box>
      <Box marginY={6}>
        {intl.formatMessage({
          id: 'unsubscribe.resubscribedActionLabel',
          defaultMessage: 'If you did not mean to resubscribe, you can',
        })}{' '}
        <Button
          variant="link"
          onClick={handleUpdateEmailPreferences}
          data-test="unsubscribe-update-email-preferences-link"
        >
          {intl.formatMessage({
            id: 'unsubscribe.resubscribedAction',
            defaultMessage: 'update your email preferences',
          })}
        </Button>
      </Box>
    </>
  );

  const renderUnsubscribe = () =>
    resubscribed ? (
      renderResubscribed()
    ) : (
      <>
        <Heading fontSize="20px" fontWeight="bold" marginY={4}>
          {intl.formatMessage({
            id: 'unsubscribe.unsubscribeLine1',
            defaultMessage: 'Update your email preferences:',
          })}
        </Heading>
        <Box marginY={4}>
          {intl.formatMessage({
            id: 'unsubscribe.unsubscribeEmailAddress',
            defaultMessage: 'Your email address',
          })}{' '}
          <Text as="span" fontWeight="bold" data-test="unsubscribe-email">
            {unsubscribeRecord.email}
          </Text>
        </Box>
        <Box marginY={6}>
          {intl.formatMessage({
            id: 'unsubscribe.unsubscribeLine2',
            defaultMessage: 'You will not receive any more emails from',
          })}{' '}
          <Text as="span" fontWeight="bold" data-test="unsubscribe-gallery-title">
            {landingData.baseGallery.title}
          </Text>
        </Box>
        <Text fontSize="16px" fontWeight="bold" marginY={6}>
          {intl.formatMessage({
            id: 'unsubscribe.unsubscribeWhyLeaving',
            defaultMessage: 'If you have a moment, please tell us why you are leaving…',
          })}
        </Text>
        <RadioGroup
          isDisabled={submitting}
          onChange={e => setReasonType(parseInt(e))}
          paddingLeft={4}
          value={reasonType}
        >
          <Stack spacing={3}>
            <Radio value={1} data-test="unsubscribe-radio-group">
              {intl.formatMessage({
                id: 'unsubscribe.unsubscribeReason1',
                defaultMessage: 'I am no longer interested in buying photos',
              })}
            </Radio>
            <Radio value={2} data-test="unsubscribe-radio-group">
              {intl.formatMessage({
                id: 'unsubscribe.unsubscribeReason2',
                defaultMessage: 'The frequency of emails is too much',
              })}
            </Radio>
            <Radio value={3} data-test="unsubscribe-radio-group">
              {intl.formatMessage({
                id: 'unsubscribe.unsubscribeReason3',
                defaultMessage: "I've already made a purchase",
              })}
            </Radio>
            <Radio value={99} data-test="unsubscribe-radio-group">
              {intl.formatMessage({
                id: 'unsubscribe.unsubscribeReasonOther',
                defaultMessage: 'Other (fill in below)',
              })}
            </Radio>
          </Stack>
          <Flex flexDirection="column" marginLeft={7} marginTop={4} maxW="500px">
            <Textarea
              borderColor={colors.grey[3]}
              borderWidth={2}
              disabled={submitting}
              maxLength={250}
              maxW="500px"
              minH="150px"
              onChange={handleReasonChange}
              value={customReason}
              data-test="unsubscribe-other-textarea"
            />
            <Text alignSelf="flex-end" marginTop={2}>
              {customReason.length || 0} / 250
            </Text>
          </Flex>
        </RadioGroup>
        <Button
          disabled={submitting}
          marginTop={6}
          minWidth="150px"
          onClick={handleUnsubscribe}
          paddingX={2}
          variant="primary"
          data-test="unsubscribe-button"
        >
          {submitting ? (
            <Spinner />
          ) : (
            intl.formatMessage({
              id: 'unsubscribe.unsubscribeButton',
              defaultMessage: 'Unsubscribe',
            })
          )}
        </Button>
      </>
    );

  return (
    <Box margin="0 auto" maxW={1280} paddingX={12}>
      <Logo
        fallbackMargin={[8, 8, 8, 16]}
        fallbackText={landingData.account.company}
        imageSrc={landingData.account.logoImage}
        margin="auto"
        marginY={[5, 5, 5, 16]}
        maxWidth="250px"
      />
      <Flex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flexDirection="column" flexGrow={1} marginRight={12}>
          <Box>{unsubscribeRecord.unsubscribed ? renderResubscribe() : renderUnsubscribe()}</Box>
        </Flex>
        <Flex
          alignSelf="flex-start"
          bgColor={colors.grey[1]}
          borderRadius="12px"
          flex="0 0 350px"
          flexDirection="column"
          marginY={[12, 12, 12, 0]}
          paddingBottom={10}
          paddingTop={8}
          paddingX={12}
        >
          <Heading fontSize="20px" fontWeight="bold" marginY={4}>
            {intl.formatMessage({
              id: 'unsubscribe.didYouKnow',
              defaultMessage: 'Did you know?',
            })}
          </Heading>
          <UnorderedList marginTop={4} size="8" spacing="8">
            <ListItem>
              {intl.formatMessage({
                id: 'unsubscribe.didYouKnowLine1',
                defaultMessage:
                  'Staying subscribed gets you access to upcoming promos, special discounts, and exclusive offers from',
              })}{' '}
              {landingData.account.company}
            </ListItem>
            <ListItem>
              {landingData.account.company}{' '}
              {intl.formatMessage({
                id: 'unsubscribe.didYouKnowLine2',
                defaultMessage:
                  'values your online privacy and security and will never share your information',
              })}
            </ListItem>
            <ListItem>
              {intl.formatMessage({
                id: 'unsubscribe.didYouKnowLine3',
                defaultMessage:
                  'You can click unsubscribe at anytime by clicking the unsubscribe button',
              })}
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Unsubscribe;
