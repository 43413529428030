import { Button, Flex, FlexProps, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { PriceSheetOption, ShopImage } from '../../../../../../../shop-api-client';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { UniqueImageAndBackgroundSet } from '../../../../../../shared/types/image';
import { formatCurrency } from '../../../../../../shared/utils';

export interface SwapProps extends FlexProps {
  images: UniqueImageAndBackgroundSet;
  /** the option group on the pricesheet */
  option: PriceSheetOption;
  /** the currently selected selection value of the option group*/
  optionSelectionID: number;
  swapSelectionImg: ShopImage | 'all' | undefined;
  imageToSelectionIDMap: Record<string, number | null>;
  setImageToSelectionIDMap(newMap: Record<string, number | null>): void;
  setSwapSelectionImg(swapImg: undefined): void;
}

/**
 * elements inside the swap image for an image option selection
 * alerts the user that theyre swapping the image option selection for an image
 * or that theyre swapping all of them
 * can be rendered in a slideout drawer(mobile) or popover(desktop)
 */
const ImageOptionSwapSelection = ({
  option,
  optionSelectionID,
  swapSelectionImg,
  imageToSelectionIDMap,
  setImageToSelectionIDMap,
  setSwapSelectionImg,
  images,
  ...rest
}: SwapProps) => {
  const { currency } = useAppSelector(selectAccount);

  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const updateSelectionHeading = intl.formatMessage({
    id: 'imageOptionSwap.updateSelectionQuestion',
    defaultMessage: 'Update Selection?',
  });
  const updateSelectionMsg1 = intl.formatMessage({
    id: 'imageOptionSwap.updateSelectionMsg1',
    defaultMessage: 'The photo already has an option from this item: ',
  });
  const updateSelectionMsg2 = intl.formatMessage({
    id: 'imageOptionSwap.updateSelectionMsg2',
    defaultMessage: 'Would you like to change it to ',
  });
  const updateSelectionButton = intl.formatMessage({
    id: 'imageOptionSwap.updateSelection',
    defaultMessage: 'Update Selection',
  });
  const cancel = intl.formatMessage({
    id: 'imageOptionSwap.cancel',
    defaultMessage: 'Cancel',
  });

  const textProps = { variant: 'inline', fontSize: isMobile ? 'md' : 'sm' };

  if (!swapSelectionImg) {
    return null;
  }

  const handleChangeSelection = () => {
    if (swapSelectionImg === 'all') {
      const newMap = Object.keys(images).reduce<Record<string, number>>((map, imgName) => {
        map[imgName] = optionSelectionID;
        return map;
      }, {});
      setImageToSelectionIDMap(newMap);
    } else {
      const updatedMap = {
        ...imageToSelectionIDMap,
        [swapSelectionImg.internalName]: optionSelectionID,
      };
      setImageToSelectionIDMap(updatedMap);
    }

    // it's been saved, so reset this
    setSwapSelectionImg(undefined);
  };

  // for the image we're trying to modify (existing)
  // if its all, just set it to null
  const selectedImgSelectionID =
    swapSelectionImg === 'all' ? null : imageToSelectionIDMap[swapSelectionImg.internalName];
  const selectedImgOptionSelection = option.selections.find(
    s => s.catalogOptionID === selectedImgSelectionID,
  );

  // option selection for the current selection we're selecting for
  const optionSelection = option.selections.find(s => s.catalogOptionID === optionSelectionID);

  if (!optionSelection) {
    return null;
  }

  const priceDelta = ((selectedImgOptionSelection?.price || 0) - optionSelection.price) * -1;

  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="space-between"
      paddingTop={4}
      paddingX={6}
      {...rest}
    >
      <Flex direction="column" alignItems="flex-start">
        <Heading fontSize="lg" marginY={2}>
          {updateSelectionHeading}
        </Heading>
        <Flex display="inline" marginBottom={2}>
          {selectedImgOptionSelection && (
            <>
              <Text {...textProps}>{updateSelectionMsg1}</Text>{' '}
              <Text {...textProps} as="i">
                {selectedImgOptionSelection.name}.{' '}
              </Text>
            </>
          )}
          <Text {...textProps}>{updateSelectionMsg2}</Text>{' '}
          <Text {...textProps} as="b">
            {optionSelection.name}{' '}
          </Text>
          <Text {...textProps}>?</Text>
        </Flex>
      </Flex>

      <Flex direction="column" marginTop={4} width="100%">
        <Button onClick={handleChangeSelection} marginBottom={2}>
          {updateSelectionButton}{' '}
          {selectedImgOptionSelection &&
            `(${priceDelta >= 0 ? '+' : '-'}${formatCurrency(Math.abs(priceDelta), currency)})`}
        </Button>
        <Button variant="secondary" color="brand" onClick={() => setSwapSelectionImg(undefined)}>
          {cancel}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ImageOptionSwapSelection;
