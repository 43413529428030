import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { SAVE_AND_CONTINUE } from '../../../features/Products/Configuration/constants';

interface Props {
  'data-test'?: string;
  isDisabled?: boolean;
  onSave(): void;
}

const SaveButton = ({ 'data-test': dataTest, isDisabled, onSave }: Props) => {
  return (
    <Flex justify="flex-end" marginTop={5}>
      <Button
        data-test={dataTest}
        aria-disabled={isDisabled} // use aria-disabled instead of chakra `isDisabled` so it is focusable
        disabled={isDisabled}
        onClick={onSave}
        tabIndex={0}
        _disabled={{
          opacity: 0,
        }}
      >
        {SAVE_AND_CONTINUE}
      </Button>
    </Flex>
  );
};

export default SaveButton;
