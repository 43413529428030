import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { SelectionOption } from '../../../../../shop-api-client';
import ProductImage from '../../../../features/Products/ProductImage';
import useIsMobile from '../../../../shared/hooks/useIsMobile';
import Modal from '../../Modal';

interface Props {
  isOpen: boolean;
  onClose(): void;
  option: SelectionOption;
}

const CompareChoices = ({ isOpen, option, onClose }: Props) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const NO_DESCRIPTION = intl.formatMessage({
    id: 'compareChoices.noDescription',
    defaultMessage: 'No description available',
  });

  return (
    <Modal
      heading={option.name}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={isMobile ? 'outside' : 'inside'}
    >
      <Flex direction="column" height="100%">
        {option.selections.map(selection => (
          <Flex key={selection.catalogOptionID} justify="space-between" marginTop={6}>
            <Flex direction="column">
              <Heading fontSize="md" marginBottom={1}>
                {selection.name}
              </Heading>
              <Text fontSize="md" lineHeight="1.6">
                {selection.description || NO_DESCRIPTION}
              </Text>
            </Flex>
            {!!selection.displayImage && (
              <Box marginBottom={4} minWidth="150px" width="150px">
                <ProductImage
                  fallbackFontSize="12px"
                  fallbackIconSize="34px"
                  image={selection.displayImage}
                />
              </Box>
            )}
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};

export default CompareChoices;
