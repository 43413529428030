import { Box, Divider } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectInteractions,
  selectProductTypeMap,
} from '../../../redux/selectors/interactions.selectors';
import {
  selectLockedAndUnlockedCategories,
  selectPriceSheet,
} from '../../../redux/selectors/priceSheet.selectors';
import SidebarSection from '../../../shared/components/SidebarSection';
import { FILTER } from '../../../shared/constants';
import CategoryLinksSection from '../CategoryLinksSection';
import { INTL_AVAILABILITY_FILTER_LABELS, PRODUCT_TYPE_FILTER_LABELS } from '../constants';
import ProductFilterSection from '../ProductFilterSection';
import FilterPillsSection from './FilterPillsSection';

const CategoriesSidebar = () => {
  const { activeCategoryID } = useSelector(selectInteractions);
  const { lockedCategories, unlockedCategories } = useSelector(selectLockedAndUnlockedCategories);
  const { offersStatusMap } = useSelector(selectPriceSheet);

  const history = useHistory();
  const intl = useIntl();
  const productTypeMap = useSelector(selectProductTypeMap);

  const searchParams = new URLSearchParams(history.location.search);
  const filters = searchParams.getAll(FILTER);

  const handleToggleFilter = (filter: string) => {
    let updated = [...filters];

    // Clear search query to allow search across entire catalog:
    searchParams.delete(FILTER);

    if (updated.includes(filter)) {
      updated = updated.filter(f => f !== filter);
    } else {
      updated.push(filter);
    }

    for (const value of updated) {
      searchParams.append(FILTER, value);
    }

    history.replace({ search: `${searchParams}` });
  };

  return (
    <Box width="370px" paddingRight="60px">
      <SidebarSection
        heading={intl.formatMessage({
          id: 'categoriesSidebar.heading',
          defaultMessage: 'Categories',
        })}
        isCollapsible={false}
      >
        <CategoryLinksSection />
      </SidebarSection>
      <Divider variant="sidebar" />
      {!!filters.length && <FilterPillsSection filters={filters} onToggle={handleToggleFilter} />}
      <ProductFilterSection
        filterableMap={productTypeMap}
        heading={intl.formatMessage({
          id: 'productFilterSection.type',
          defaultMessage: 'Type',
        })}
        intlLabelMap={PRODUCT_TYPE_FILTER_LABELS}
        onChange={handleToggleFilter}
        productFilters={filters}
      />
      {!!Object.keys(offersStatusMap).length && activeCategoryID === 'all' && (
        <ProductFilterSection
          filterableMap={{ locked: lockedCategories, unlocked: unlockedCategories }}
          heading={intl.formatMessage({
            id: 'productFilterSection.availability',
            defaultMessage: 'Availability',
          })}
          intlLabelMap={INTL_AVAILABILITY_FILTER_LABELS}
          onChange={handleToggleFilter}
          productFilters={filters}
        />
      )}
    </Box>
  );
};

export default CategoriesSidebar;
