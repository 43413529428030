export const CATEGORIES_SUB_MENU_HEIGHT = 50;
export const DISCOUNTS_BANNER_HEIGHT = 30;
export const MOBILE_ACTION_BAR_HEIGHT = 50;
export const NAV_GRID_HEIGHT = 115;
export const NAV_TAB_HEIGHT = 65;
export const PREORDER_NAV_GUTTER = 20;
export const PREORDER_NAV_HEIGHT = DISCOUNTS_BANNER_HEIGHT + NAV_GRID_HEIGHT + PREORDER_NAV_GUTTER;
export const FULL_ORDER_NAV_HEIGHT = DISCOUNTS_BANNER_HEIGHT + NAV_GRID_HEIGHT + NAV_TAB_HEIGHT;
export const MOBILE_NAV_WITH_CAT_MENU_HEIGHT =
  CATEGORIES_SUB_MENU_HEIGHT + MOBILE_ACTION_BAR_HEIGHT + DISCOUNTS_BANNER_HEIGHT;
