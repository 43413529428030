import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address, CheckoutFormData, SelectedOptions, StepKey } from '../../../shop-api-client';
import { OrderFinancials } from '../../../shop-api-client/models/Cart';
import { ApplyDiscount } from '../../../shop-api-client/models/Discounts';
import { SUMMARY_FINANCIALS_DEFAULTS } from './cart.slice';
import { signout } from './visitor.slice';

export const ADDRESS_DEFAULTS = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  state: '',
  zip: '',
};

/**
 * Map of required image option data
 *
 * Required image options are stored as nested hashmaps:
 * - The first key is the visitKey
 * - The second key is the internal image name
 * - The third KEY is the priceSheetOptionID
 * - The third VALUE is the option selection ID, or false for an opt-out status
 *
 * Example:
 * {
 *   's123456789': {
 *     'abcdef.jpg': {
 *       12: 78,
 *       34: false,     // This means they explicitly opted out
 *       56: undefined, // This means no user interaction has happened yet
 *     }
 *   }
 * }
 */
export type RequiredImageOptionMap = Record<
  string,
  Record<string, Record<number, number | false | undefined>>
>;

interface DatedOrderFinancials extends OrderFinancials {
  timestamp?: Date;
}

const initialState: {
  checkoutActiveStep: StepKey | null;
  checkoutStepCompletion: Record<StepKey, boolean>;
  checkoutVisitKeys: string[] | null;
  discountCode: string | null;
  discountID: number | null;
  financials: DatedOrderFinancials;
  formData: CheckoutFormData;
  isSubmitting: boolean;
  editReqImageOptionsMap: RequiredImageOptionMap;
  paymentIntent: string | undefined;
} = {
  checkoutActiveStep: null,
  checkoutStepCompletion: {} as Record<StepKey, boolean>,
  checkoutVisitKeys: null,
  financials: SUMMARY_FINANCIALS_DEFAULTS,
  formData: {
    backgroundOption: null,
    billingAddress: ADDRESS_DEFAULTS,
    customDataSpec: {},
    orderNotes: '',
    orderOptions: {},
    paymentOptions: { useShippingForBillingAddress: true },
    shippingAddress: ADDRESS_DEFAULTS,
    shippingType: '',
  },
  discountCode: null,
  discountID: null,
  isSubmitting: false,
  editReqImageOptionsMap: {},
  paymentIntent: undefined,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetCheckoutSteps(state) {
      state.checkoutStepCompletion = {} as Record<StepKey, boolean>;
    },
    setCheckoutAddress(
      state,
      {
        payload: { stepKey, data },
      }: PayloadAction<{ stepKey: 'shippingAddress' | 'billingAddress'; data: Address }>,
    ) {
      state.formData[stepKey] = { ...state.formData[stepKey], ...data };
    },
    setCheckoutEmail(state, action: PayloadAction<string>) {
      state.formData.billingAddress.email = action.payload;
    },
    setCheckoutBackgroundOption(state, action: PayloadAction<number | null>) {
      state.formData.backgroundOption = action.payload;
    },
    setCheckoutCustomDataSpec(state, action: PayloadAction<Record<string, string>>) {
      state.formData.customDataSpec = { ...state.formData.customDataSpec, ...action.payload };
    },
    setCheckoutDiscount(state, action: PayloadAction<ApplyDiscount>) {
      state.discountCode = action.payload.discountCode || null;
      state.discountID = action.payload.discountID || null;
    },
    setCheckoutFinancials(state, action: PayloadAction<DatedOrderFinancials>) {
      state.financials = action.payload;
    },
    setCheckoutOrderNotes(state, action: PayloadAction<string>) {
      state.formData.orderNotes = action.payload;
    },
    setCheckoutOrderOptions(state, action: PayloadAction<SelectedOptions>) {
      state.formData.orderOptions = { ...state.formData.orderOptions, ...action.payload };
    },
    setPrefillCheckout(state, action: PayloadAction<CheckoutFormData>) {
      // Spread with initialState formData defaults:
      state.formData = { ...initialState.formData, ...action.payload };
    },
    setCheckoutShippingOptions(state, action: PayloadAction<string>) {
      state.formData.shippingType = action.payload;
    },
    setCheckoutUseShippingForBillingAddress(state, action: PayloadAction<boolean>) {
      state.formData.paymentOptions.useShippingForBillingAddress = action.payload;
      if (action.payload) {
        state.formData.billingAddress = state.formData.shippingAddress;
      }
    },
    setCheckoutActiveStep(state, action: PayloadAction<StepKey | null>) {
      state.checkoutActiveStep = action.payload;
    },
    setCheckoutStepComplete(state, action: PayloadAction<StepKey>) {
      state.checkoutStepCompletion[action.payload] = true;
    },
    setCheckoutStepIncomplete(state, action: PayloadAction<StepKey>) {
      state.checkoutStepCompletion[action.payload] = false;
    },
    setCheckoutVisitKeys(state, action: PayloadAction<string[]>) {
      state.checkoutVisitKeys = action.payload;
    },
    setIsSubmitting(state, action: PayloadAction<boolean>) {
      state.isSubmitting = action.payload;
    },
    updateReqImageOptionsMap(
      state,
      action: PayloadAction<{
        visitKey: string;
        imageName: string;
        optionGroupID: number;
        selectionID: number | false;
      }>,
    ) {
      const { visitKey, imageName, optionGroupID, selectionID } = action.payload;
      state.editReqImageOptionsMap[visitKey][imageName][optionGroupID] = selectionID;
    },
    setEditReqImageOptionsMap(state, action: PayloadAction<RequiredImageOptionMap>) {
      state.editReqImageOptionsMap = action.payload;
    },
    setPaymentIntent(state, action: PayloadAction<string | undefined>) {
      state.paymentIntent = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signout, () => {
      return initialState;
    });
  },
});

const { actions, reducer } = checkoutSlice;

export const {
  reset,
  resetCheckoutSteps,
  setCheckoutActiveStep,
  setCheckoutAddress,
  setCheckoutEmail,
  setCheckoutBackgroundOption,
  setCheckoutCustomDataSpec,
  setCheckoutDiscount,
  setCheckoutFinancials,
  setCheckoutOrderNotes,
  setCheckoutOrderOptions,
  setCheckoutShippingOptions,
  setCheckoutStepComplete,
  setCheckoutStepIncomplete,
  setCheckoutUseShippingForBillingAddress,
  setCheckoutVisitKeys,
  setIsSubmitting,
  setPrefillCheckout,
  setEditReqImageOptionsMap,
  updateReqImageOptionsMap,
  setPaymentIntent,
} = actions;

export default reducer;
