import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopProductPrint } from '../../../../../../../shop-api-client';
import {
  CartProduct,
  CreateCartProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectGroupedPkgItems,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import ProductPreview from '../../../../ProductPreview';
import { SELECT_PHOTO_SLOT } from '../../../constants';
import { getImageMultiNodeStep, getImageNodesStep, getTextNodesStep } from '../../../utils';

interface Props {
  product: CartProduct | CreateCartProductReq;
}

const PreviewCarousel = ({ product }: Props) => {
  const { configurableItems } = useSelector(selectGroupedPkgItems);
  const packageItemMap = useSelector(selectPackageItemMap);
  const { editNodeID, editPackageStep } = useSelector(selectConfiguration);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);

  const isMobile = useIsMobile();
  const intl = useIntl();

  const { priceSheetItemID } = product;
  const itemIdx = configurableItems.findIndex(i => i.priceSheetItemID === priceSheetItemID) + 1;
  const subItem = packageItemMap[product.priceSheetItemID];

  useEffect(() => {
    const handleResize = () => {
      if (ref) {
        setMaxWidth(ref.clientWidth);
        setMaxHeight(ref.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  const renderPreview = () => {
    if (!ref || product.type !== 'product') {
      return null;
    }

    const imageStepName = getImageNodesStep(product.id!);
    const textStepName = getTextNodesStep(product.id!);
    const multiStepName = getImageMultiNodeStep(product.id!);
    let unlockedNodes: 'image' | 'text' | 'none' = 'none';
    if ([imageStepName, multiStepName].includes(editPackageStep!)) {
      unlockedNodes = 'image';
    } else if (editPackageStep === textStepName) {
      unlockedNodes = 'text';
    }

    return (
      <ProductPreview
        key={product.priceSheetItemID}
        disableMiniPreview
        editProduct={product}
        maxPreviewHeight={isMobile ? undefined : maxHeight * 0.9}
        maxPreviewWidth={maxWidth * 0.9}
        previewText={!editNodeID ? SELECT_PHOTO_SLOT : undefined}
        shopProduct={subItem as ShopProductPrint}
        unlockedNodes={unlockedNodes}
      />
    );
  };

  return (
    <Flex direction="column" width="100%" height="100%">
      <Box margin={5}>
        <Text fontSize="sm">
          {intl.formatMessage(
            {
              id: 'productPreview.numberOfTotal',
              defaultMessage: '{count} of {total}',
            },
            { count: itemIdx, total: configurableItems.length },
          )}
        </Text>
        <Heading size="md">{subItem.name}</Heading>
      </Box>
      <Flex ref={flexRef => setRef(flexRef)} grow={1} justify="center" paddingX={10} width="100%">
        {renderPreview()}
      </Flex>
    </Flex>
  );
};

export default PreviewCarousel;
