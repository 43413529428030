import {
  CatalogProductNode,
  CatalogProductWithMetadata,
  CatalogTextOptionGroup,
  getDefaultCrop,
  ImageNode,
  ImageTone,
} from 'iq-product-render';
import { keyBy } from 'lodash';
import {
  GalleryType,
  OptionForSelectionOption,
  PreOrderBackgroundSelectionType,
  PriceSheetOption,
  ProductOption,
  ShopBackground,
  ShopBackgroundSet,
  ShopImage,
  ShopPackage,
  ShopProduct,
  ShopProductCollection,
  ShopProductPrint,
  ShopProductTypes,
} from '../../../../shop-api-client';
import {
  CartImage,
  CartImageNode,
  CartImageNodeReq,
  CartImageOptionReq,
  CartPackage,
  CartProductOptionReq,
  CartTextNode,
  CartTextNodeReq,
  CollectionImage,
  CreateCartImage,
  CreateCartNodeReq,
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../../shop-api-client/models/Cart';
import { ImageOptionSelectionConfigMap } from '../../../../shop-api-client/models/ShopConfig';
import {
  ShopImageNodeWithReqType,
  ShopProductNodes,
} from '../../../../shop-api-client/models/ShopProductNodes';
import { getAvailableImages } from '../../../redux/selectors/configurations.selectors';
import { Gallery } from '../../../redux/slices/gallery.slice';
import { isJobImageNode } from '../../../redux/thunks/utils';
import { IS_TEMPLATE_STRING } from '../../../shared/constants/regex.constants';
import { generateRandomInt } from '../../../shared/utils';
import { getPackageItemMap, isConfigurableImageNode } from '../utils';
import { DEFAULT_NODE_CROP } from './constants';

/////////////////////////////  Routes /////////////////////////////

/**
 * @returns `/${key}/configure/package/${id}`
 */
export const getPackageConfigurationPath = (key: string, id: string | number) =>
  `/${key}/configure/package/${id}`;

/**
 * @returns `/${key}/configure/product/${id}`
 */
export const getProductConfigurationPath = (key: string, id: string | number) =>
  `/${key}/configure/product/${id}`;

/**
 * @returns `/${key}/configure/wizard/${id}/backgrounds`
 */
export const getWizardBackgroundsPath = (key: string, packageID: string | number) =>
  `${getWizardBasePath(key, packageID)}/backgrounds`;

/**
 * @returns `/${key}/configure/wizard/${id}`
 */
export const getWizardBasePath = (key: string, id: string | number) =>
  `/${key}/configure/wizard/${id}`;

/**
 * @returns `/${key}/configure/wizard/${id}/byop-selection`
 */
export const getWizardBYOPSelectionPath = (key: string, packageID: string | number) =>
  `${getWizardBasePath(key, packageID)}/byop-selection`;

/**
 * @returns `/${key}/configure/wizard/${id}/customize/${editSubItemID}`
 */
export const getWizardCustomizeItemPath = (
  key: string,
  packageID: string | number,
  editSubItemID: string | number,
) => `${getWizardCustomizeOverviewPath(key, packageID)}/${editSubItemID}`;

/**
 * @returns `/${key}/configure/wizard/${id}/customize`
 */
export const getWizardCustomizeOverviewPath = (key: string, packageID: string | number) =>
  `${getWizardBasePath(key, packageID)}/customize`;

/**
 * @returns `/${key}/configure/wizard/${id}/image-options`
 */
export const getWizardImageOptionsPath = (key: string, packageID: string | number) =>
  `${getWizardBasePath(key, packageID)}/image-options`;

/**
 * @returns `/${key}/configure/wizard/${id}/summary`
 */
export const getWizardSummaryPath = (key: string, packageID: string | number) =>
  `${getWizardBasePath(key, packageID)}/summary`;

///////////////  Single View Configuration step keys ///////////////

/**
 * @returns `buildYourOwn`
 */
export const getBuildYourOwnStep = () => 'buildYourOwn';
/**
 * @returns `cartItemID/${editItemID}`
 */
export const getPackageSubItemStep = (editItemID: number) => `cartItemID/${editItemID}`;
/**
 * @returns `cartItemID/${editItemID}/collectionImages`
 */
export const getCollectionImagesStep = (editItemID: number) =>
  `cartItemID/${editItemID}/collectionImages`;
/**
 * @returns `cartItemID/${editItemID}/imageCrop`
 */
export const getImageCropStep = (editItemID: number) => `cartItemID/${editItemID}/imageCrop`;
/**
 * @returns `cartItemID/${editItemID}/imageNodes`
 */
export const getImageNodesStep = (editItemID: number) => `cartItemID/${editItemID}/imageNodes`;
/**
 * @returns `cartItemID/${editItemID}/imageMultiNode`
 */
export const getImageMultiNodeStep = (editItemID: number) =>
  `cartItemID/${editItemID}/imageMultiNode`;
/**
 * @returns `cartItemID/${editItemID}/imageTone`
 */
export const getImageToneStep = (editItemID: number) => `cartItemID/${editItemID}/imageTone`;
/**
 * @returns `cartItemID/${editItemID}/optional`
 */
export const getOptionalPerProductStep = (editItemID: number) =>
  `cartItemID/${editItemID}/optional`;
/**
 * @returns `optional`
 */
export const getOptionalStep = () => 'optional';
/**
 * @returns `packageImage`
 */
export const getPackageImageAssignmentStep = () => 'packageImage';
/**
 * @returns `backgrounds` OR `cartItemID/${editItemID}/backgrounds`
 */
export const getPreOrderBackgroundsStep = (
  editItemID: number,
  preOrderBackgroundSelectionType?: PreOrderBackgroundSelectionType | null,
) => {
  if (preOrderBackgroundSelectionType === 'perImage') {
    return `cartItemID/${editItemID}/backgrounds`;
  }
  return 'backgrounds';
};
/**
 * @returns `imageOptions/${optionGroupID}`
 */
export const getRequiredImageOptionsStep = (optionGroupID: number) =>
  `imageOptions/${optionGroupID}`;
/**
 * Used by packages, where all product options appear in one sub step
 * @returns `cartItemID/${editItemID}/options`
 */
export const getProductOptionsStep = (editItemID: number) => `cartItemID/${editItemID}/options`;
/**
 * @returns `cartItemID/${editItemID}/options/${optionGroupID}`
 */
export const getRequiredProductOptionsStep = (editItemID: number, optionGroupID: number) =>
  `cartItemID/${editItemID}/options/${optionGroupID}`;
/**
 * @returns `cartItemID/${editItemID}/textNodes`
 */
export const getTextNodesStep = (editItemID: number) => `cartItemID/${editItemID}/textNodes`;

/**
 * Returns the max character length allowed for a text Product Option
 * from a Catalog product's metadata, if present
 */
export const getTextOptionMaxLength = (
  option: ProductOption,
  rendererProduct: CatalogProductWithMetadata | null,
) => {
  // If the option is of type `text`, find the corresponding CatalogOptionGroup to set `maxLength`
  if (option.type === 'text') {
    const catalogOptionGroup = rendererProduct?.optionGroups.find(
      og => og.id === option.catalogOptionGroupID,
    );
    return (catalogOptionGroup as CatalogTextOptionGroup)?.options[0].maxlength;
  }
};

/**
 * Returns the chosen background for a cart package or product
 */
export const getBackground = (
  cartItem: CreateCartPackageReq | CreateCartProductReq,
  bgSets: ShopBackgroundSet[],
): ShopBackground | undefined => {
  let backgroundID: number | null | undefined;

  if (cartItem.type === 'buildYourOwn' || cartItem.type === 'standard') {
    for (const product of cartItem.products) {
      const background = getBackground(product, bgSets);
      if (background) {
        return background;
      }
    }
  } else if (cartItem.type === 'collection' || cartItem.type === 'imageDownload') {
    backgroundID = cartItem.collectionImages.find(c => c.backgroundID)?.backgroundID;
  } else if (cartItem.type === 'product') {
    backgroundID = (cartItem.nodes.find(n => n.type === 'image' && n.backgroundID) as CartImageNode)
      ?.backgroundID;
  }

  if (backgroundID) {
    for (const set of bgSets) {
      const background = set.backgrounds.find(b => b.id === backgroundID);
      if (background) {
        return background;
      }
    }
  }
};

/**
 * Gets the new selected background when the pose is swapped in a single pose package.
 * Considers no-background image nodes as part of this selection, so it finds and
 * returns an existing background from another product when needed
 */
export const getBackgroundForPoseSwap = (
  poseMapKeys: string[],
  newBackgroundID: number | undefined,
  skipBackground?: boolean,
) => {
  if (skipBackground) {
    const bgFromOtherPose = poseMapKeys.find(k => k.split('-')[1])?.split('-')[1];
    return bgFromOtherPose ? parseInt(bgFromOtherPose) : undefined;
  }
  return newBackgroundID;
};

export const getBGOverlayStyles = (swatchSize: number) => {
  const swatchGutter = 4;
  const swatchSelectSize = swatchSize + swatchGutter * 2;

  return {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    height: `${swatchSelectSize}px`,
    hideIcon: true,
    margin: `-${swatchGutter}px`,
    width: `${swatchSelectSize}px`,
  };
};

export const getGroupedProductOptions = (options: ProductOption[] = []) => {
  return options.reduce(
    (result, option) => {
      if (option.requirementType === 'required') {
        result.requiredProductOptions.push(option);
      } else {
        result.optionalProductOptions.push(option);
      }
      return result;
    },
    {
      optionalProductOptions: [] as ProductOption[],
      requiredProductOptions: [] as ProductOption[],
    },
  );
};

export const getConfigurableImageNodes = (
  shopProduct: ShopProductPrint,
  catalogNodes: CatalogProductNode[],
) => {
  return catalogNodes.reduce<ShopImageNodeWithReqType[]>((map, node) => {
    if (node.type === 'image' && isConfigurableImageNode(node)) {
      const imgReq = shopProduct.imageRequirementProperties.find(p => p.nodeID === node.id);

      map.push({
        ...node,
        imageRequirementType: imgReq?.type || 'any',
      });
    }

    return map;
  }, []);
};

/**
 * Function to initialize a map, keying a selection to the images
 * for that selection.
 *
 * @param option PriceSheetOption to initialize selections for
 * @param editImageOptionMap Current set of configured image options
 * @param availableImages Images available in the current context, for this image option
 * @returns Map, keyed by option selection ID, to the assigned images
 */
export const getInitialImageOptionMap = (
  option: PriceSheetOption,
  editImageOptionMap: ImageOptionSelectionConfigMap,
  availableImages: Record<string, Set<number>>,
) => {
  return option.selections.reduce<Record<number, string[]>>((map, { catalogOptionID }) => {
    // find corresponding editImageOption value
    if (editImageOptionMap[catalogOptionID]) {
      const filteredImagesOnSelection = (
        editImageOptionMap[catalogOptionID].images as CartImage[]
      ).reduce<string[]>((res, i) => {
        // if it has an image name (not null - in the case of preorder)
        // and it's in the available images we're currently editing, add it
        // other images on the image option but not being used during this config are reconciled later
        if (i.imageName && availableImages[i.imageName]) {
          res.push(i.imageName);
        }
        return res;
      }, []);
      map[catalogOptionID] = filteredImagesOnSelection;
    } else {
      map[catalogOptionID] = [];
    }

    return map;
  }, {});
};

export const getNodeCrop = (editNode?: CartImageNodeReq) => ({
  cropH: editNode?.cropH || DEFAULT_NODE_CROP.cropH,
  cropW: editNode?.cropW || DEFAULT_NODE_CROP.cropW,
  cropX: editNode?.cropX || DEFAULT_NODE_CROP.cropX,
  cropY: editNode?.cropY || DEFAULT_NODE_CROP.cropY,
  orientation: editNode?.orientation || DEFAULT_NODE_CROP.orientation,
});

export const getProductToNodeKey = (productID: number, nodeID: number) => `${productID}-${nodeID}`;

export const getUniqueQuantityMap = (editPackage?: CreateCartPackageReq | null) =>
  ((editPackage?.products || []) as CreateCartProductReq[]).reduce<Record<string, number>>(
    (map, item) => {
      if (!map[item.priceSheetItemID]) {
        map[item.priceSheetItemID] = 0;
      }
      map[item.priceSheetItemID]++;
      return map;
    },
    {},
  );

/**
 * Checks whether Shop Product is configurable in the Configuration Editor
 */
export const isConfigurable = (
  isPreOrder: boolean,
  product: ShopProduct | ShopPackage,
  nodeMap: ShopProductNodes,
): boolean => {
  if (product.options?.length || product.type === 'package-byop') {
    return true;
  }
  if (product.type === 'package') {
    return product.availableProducts.some(p => isConfigurable(isPreOrder, p, nodeMap));
  }

  if (product.type === 'product' && nodeMap[product.catalogProductID]) {
    for (const node of nodeMap[product.catalogProductID]) {
      // If an editable text node or if full-order and an image node, product is configurable
      if (
        (!isPreOrder && isConfigurableImageNode(node)) ||
        (node.type === 'text' && !node.locked)
      ) {
        return true;
      }
    }
  } else if (product.type === 'collection' || product.type === 'imageDownload') {
    // If collection or imageDownload and job is not pre-order, and does not
    // include all images, product is configurable
    return !isPreOrder && !product.includeAll;
  }
  // Item is a non-print product, or otherwise not configurable
  return false;
};

/**
 * Checks whether a package allows multiple poses, or has at least one pose and
 * requires a group image
 */
export const isMultiImagePackage = (
  pkg: ShopPackage,
  galleryType: GalleryType,
  hasGroupImage: boolean,
) => {
  const { allowAdditionalPoses, availableProducts, posesIncluded } = pkg;
  let allowsAny = false;
  let requiresGroupImage = false;
  let requiresPose = false;

  if (allowAdditionalPoses || posesIncluded > 1) {
    // If the package allows multiple poses, return true right away:
    return true;
  } else if (galleryType === 'standard') {
    // Else if the job is standard, the loop below is unnecessary,
    // as the package is truly single pose, so return false:
    return false;
  }

  // Because 'group' image restrictions in a subject job are not counted as poses,
  // this loop checks for scenarios that would still require the visitor to make
  // multiple image selections, thus we should route to the Package Wizard.
  // These scenarios are:
  // - At least one group restriction is found, in addition to a pose restriction, or 'any'
  // - At least one pose restriction, at least one 'any', and group images are present on the job
  for (const product of availableProducts) {
    if (product.type === 'product') {
      if (!product.imageRequirementProperties.length) {
        allowsAny = true;
      }

      for (const requirement of product.imageRequirementProperties) {
        if (requirement.type === 'group') {
          requiresGroupImage = true;
        } else if (requirement.type === 'nonGroup') {
          requiresPose = true;
        } else {
          allowsAny = true;
        }
      }
    } else if (product.type === 'collection' || product.type === 'imageDownload') {
      if (product.imageRequirementType === 'group') {
        requiresGroupImage = true;
      } else if (product.imageRequirementType === 'nonGroup') {
        requiresPose = true;
      } else {
        allowsAny = true;
      }
    }

    if (
      (requiresGroupImage && (requiresPose || allowsAny)) ||
      (requiresPose && allowsAny && hasGroupImage)
    ) {
      return true;
    }
  }

  return false;
};

/**
 * Checks whether Edit/Cart item meets configuration requirements
 */
export const meetsConfigRequirements = (
  editProduct: CreateCartProductReq,
  product: ShopProduct,
  isPreOrder: boolean,
) => {
  // If the Shop Product has required options
  if (product.options.some(o => o.requirementType === 'required')) {
    // Create a map of existing chosen product options
    const editOptionsMap = (editProduct.options || []).reduce<Record<string, boolean>>((res, o) => {
      res[o.optionGroupID] = true;
      return res;
    }, {});

    for (const option of product.options) {
      // If the current Shop Product option is required, but visitor has not made a selection,
      // the Edit Product does not meet configuration requirement:
      if (option.requirementType === 'required' && !editOptionsMap[option.catalogOptionGroupID]) {
        return false;
      }
    }
  }
  if (editProduct.type === 'product') {
    for (const node of editProduct.nodes) {
      // If not pre-order, image node and no assigned image OR if text node with no text
      // product does not meet requirement:
      if (
        (!isPreOrder && node.type === 'image' && !node.imageInternalName) ||
        (node.type === 'text' && !node.text)
      ) {
        return false;
      }
    }
  } else if (editProduct.type === 'collection' || editProduct.type === 'imageDownload') {
    for (const collectionImage of editProduct.collectionImages) {
      // If not pre-order and a collection image needs assigned,
      // product does not meet reuquirement:
      if (!isPreOrder && !collectionImage.displayName) {
        return false;
      }
    }
  }
  return true;
};

const loadPackageItemsByType = (
  shopPackage: ShopPackage,
  productNodeMap: ShopProductNodes,
  prefillImage?: ShopImage,
  prefillBackground?: ShopBackground,
  gallery?: Gallery,
) =>
  shopPackage.availableProducts.reduce<CreateCartProductReq[]>((res, item) => {
    const isBYOP = shopPackage.type === 'package-byop';
    if (!isBYOP || (isBYOP && item.price === 0)) {
      res.push(shapeEditProduct(item, productNodeMap, prefillImage, prefillBackground, gallery));
    }
    return res;
  }, []);

/**
 * This function takes an updated product and checks if there are any text nodes that were filled in,
 * that can be matched to identical text nodes in other products in the same package.
 *
 * For example if "First Name" appears in multiple products, we can set that across the products to
 * speed up package configuration.
 *
 * The returned result is the package with its products updated, ready to be submitted to Redux.
 */
export const matchTextNodesInPackage = (
  cartPackage: CreateCartPackageReq,
  incomingProduct: CreateCartProductReq,
  packageItemMap: Record<string, ShopProduct>,
  productNodeMap: Record<number, CatalogProductNode[]>,
) => {
  // This variable has the default behavior to update the products in the package
  const baseProducts = cartPackage.products.map(p =>
    p.id === incomingProduct.id ? incomingProduct : p,
  );

  // If no text nodes were updated, just update the package normally
  if (incomingProduct.type !== 'product' || !incomingProduct.nodes.some(n => n.type === 'text')) {
    return {
      updatedPackage: {
        ...cartPackage,
        products: baseProducts,
      },
      completedSteps: {},
    };
  }

  // Load node data for this updated product
  const shopProduct = packageItemMap[incomingProduct.priceSheetItemID];
  const shopProductNodes = productNodeMap[shopProduct.catalogProductID];

  // Find the text node values that have been updated (key is label, value is input text)
  const updatedTextNodes: Record<string, string> = {};
  for (const node of incomingProduct.nodes) {
    if (node.type !== 'text') {
      continue;
    }

    // Find the catalog node:
    const catalogNode = shopProductNodes.find(n => n.id === node.catalogNodeID);

    if (node.text && catalogNode) {
      updatedTextNodes[catalogNode.name] = node.text;
    }
  }

  // It's possible we will complete some steps as we go through the text nodes, so we track
  // that here:
  const completedSteps: Record<string, boolean> = {};

  const updatedProducts = cartPackage.products.map(product => {
    let emptyTextNodeCount = 0;

    // Skip any product that has no text nodes
    if (product.type !== 'product' || !product.nodes.some(n => n.type === 'text')) {
      return product;
    }

    // If we're mapping the updated product - return that:
    if (product.id === incomingProduct.id) {
      return incomingProduct;
    }

    // See if any of the text nodes on this product match labels:
    const shopProduct = packageItemMap[product.priceSheetItemID];
    const shopProductNodes = productNodeMap[shopProduct.catalogProductID];

    const nodes = product.nodes.map(node => {
      if (node.type !== 'text') {
        return node;
      }

      // Find the catalog node:
      const updatedNode = { ...node };
      const catalogNode = shopProductNodes.find(n => n.id === node.catalogNodeID);

      // If catalogNode has dynamically injected text, allow incoming override
      const hasDynamicText = catalogNode?.type === 'text' && catalogNode.text?.startsWith('{{');
      if ((!node.text || hasDynamicText) && catalogNode && !catalogNode.locked) {
        emptyTextNodeCount++;
        if (updatedTextNodes[catalogNode.name]) {
          updatedNode.text = updatedTextNodes[catalogNode.name];
          emptyTextNodeCount--;
        }
      }

      return updatedNode;
    });

    // If the completed step count is equal to the text node count, mark the step completed:
    if (emptyTextNodeCount === 0) {
      completedSteps[getTextNodesStep(product.id!)] = true;
    }

    return { ...product, nodes };
  });

  return {
    updatedPackage: {
      ...cartPackage,
      products: updatedProducts,
    },
    completedSteps,
  };
};

export const shapeEditPackage = (
  shopPackage: ShopPackage,
  productNodeMap: ShopProductNodes,
  prefillImage?: ShopImage,
  prefillBackground?: ShopBackground,
  gallery?: Gallery,
) => {
  return {
    id: -generateRandomInt(1000000000, 9999999999),
    priceSheetItemID: shopPackage.id,
    products: loadPackageItemsByType(
      shopPackage,
      productNodeMap,
      prefillImage,
      prefillBackground,
      gallery,
    ),
    quantity: 1,
    type: shopPackage.type === 'package' ? 'standard' : 'buildYourOwn',
  } as CartPackage;
};

export const shapeEditProduct = (
  shopProduct: ShopProduct,
  productNodeMap: ShopProductNodes,
  prefillImage?: ShopImage,
  prefillBackground?: ShopBackground,
  gallery?: Gallery,
) => {
  // WIP: We will not use negative temporary IDs, but want to discuss with Fons a better alternative
  const editProduct = {
    id: -generateRandomInt(1000000000, 9999999999),
    priceSheetItemID: shopProduct.id,
    quantity: 1,
    type: shopProduct.type,
  } as CreateCartProductReq;

  if (editProduct.type === 'product') {
    editProduct.nodes = (productNodeMap[shopProduct.catalogProductID] || []).reduce<
      CreateCartNodeReq[]
    >((nodes, node) => {
      // custom job image shapes?
      if (node.type === 'image') {
        if (node.locked || isJobImageNode(node)) {
          // no cart nodes for job image nodes
          return nodes;
        }

        let availableImages = {
          product: [] as ShopImage[],
          nodes: {} as Record<string, ShopImage[]>,
        };

        if (gallery) {
          availableImages = getAvailableImages(gallery, shopProduct, productNodeMap);
        }

        const nodeKey = getProductToNodeKey(shopProduct.id, node.id);
        const nodeImages = availableImages.nodes[nodeKey];
        const hasSingleImage =
          nodeImages?.length === 1 &&
          (!nodeImages[0].isGreenScreen || node.skipBackgroundSelection);

        if (!prefillImage && hasSingleImage) {
          nodes.push(shapeCartImageNode(node, nodeImages[0]));
        } else {
          nodes.push(shapeCartImageNode(node, prefillImage || null, prefillBackground));
        }
      } else if (node.type === 'text') {
        nodes.push(shapeCartTextNode(node.id, node.text));
      } else if (node.type === 'qr') {
        // no cart nodes for qr nodes - they are created during order conversion based on the catalog nodes
        return nodes;
      } else {
        // WIP: If not an image or text node, return the object below. This accomodates hidden and
        // imageContainer within the current setup, but this will likely require cleanup when we
        // are properly managing those node types in configuration and in cart service
        nodes.push({
          catalogNodeID: node.id,
          type: node.type,
        });
      }

      return nodes;
    }, []);
  } else if (editProduct.type === 'collection' || editProduct.type === 'imageDownload') {
    const { includeAll, minImages } = shopProduct as ShopProductCollection;
    const imageCount = includeAll ? 1 : minImages;

    let singleImage: ShopImage | null = null;
    if (gallery) {
      const availableImages = getAvailableImages(gallery, shopProduct, productNodeMap);
      // Only if the single image is a JPG should we auto apply it, otherwise we need to ask
      // for a background input:
      if (availableImages.product.length === 1 && !availableImages.product[0].isGreenScreen) {
        singleImage = availableImages.product[0];
      }
    }

    // If includeAll is true, only one collectionImage is expected for image assignment
    // in Blueprint, and this appears to only be in case a background is required.
    // Otherwise, the array is populated with the minimum number of images required
    // for the Collection
    editProduct.collectionImages = Array(imageCount).fill(shapeCollectionImage(singleImage));

    // Add prefillImage/prefillBackground as the first collection image, if provided:
    if (prefillImage || prefillBackground) {
      editProduct.collectionImages[0] = shapeCollectionImage(
        prefillImage || null,
        prefillBackground,
      );
    }
  }
  return editProduct;
};

export const shapeNodesForItem = (
  gallery: Gallery,
  shopItem: ShopProduct,
  productNodeMap: ShopProductNodes,
  imageName?: string,
  background?: ShopBackground,
) => {
  const { isPreOrder, images } = gallery;
  const availableImages = getAvailableImages(gallery, shopItem, productNodeMap);

  return productNodeMap[shopItem.catalogProductID].reduce<CreateCartNodeReq[]>(
    (cartNodes, node) => {
      if (node.type === 'text') {
        cartNodes.push(shapeCartTextNode(node.id, node.text || ''));
      }

      if (node.type === 'image' && !isJobImageNode(node)) {
        let imageToShape: ShopImage | null = null;

        if (!isPreOrder) {
          const nodeImages = availableImages.nodes[getProductToNodeKey(shopItem.id, node.id)];
          const nodeImagesMap = keyBy(nodeImages, 'internalName');

          // If the node is pre-mapped; add that image, with the select & buy background
          if (node.defaultImage && nodeImages.length === 1) {
            imageToShape = nodeImages[0];
          } else if (imageName && nodeImagesMap[imageName]) {
            imageToShape = images[imageName];
          }
        }

        cartNodes.push(shapeCartImageNode(node, imageToShape, background));
      }
      return cartNodes;
    },
    [],
  );
};

export const shapeCartTextNode = (catalogNodeID: number, text: string | null): CartTextNodeReq => ({
  catalogNodeID,
  text,
  type: 'text',
});

export const shapeCartImageNode = (
  catalogNode: ImageNode,
  image: ShopImage | null,
  background?: ShopBackground,
  imageTone: ImageTone | null = 'original',
  userCrop?: { cropX: number; cropY: number; cropW: number; cropH: number; orientation: number },
): CartImageNodeReq => {
  // Initialize imageNode with defaults
  const backgroundID =
    background?.id && !catalogNode.skipBackgroundSelection ? background.id : null;
  const imageNode: CartImageNodeReq = {
    catalogNodeID: catalogNode.id,
    backgroundID,
    imageDisplayName: null,
    imageInternalName: null,
    type: catalogNode.type,
    imageTone,
    migratedBackground: null,
    cropX: 50,
    cropY: 50,
    cropH: 100,
    cropW: 100,
    orientation: 0,
  };

  if (image) {
    // Since the image's width and height have already been scaled to image crop
    // in shop-api - we can just use those values here:
    const { height, width } = image;

    let crop = userCrop;
    if (!crop) {
      const defaultCrop = getDefaultCrop({ height, width }, catalogNode, true, true);
      crop = {
        cropX: defaultCrop.x,
        cropY: defaultCrop.y,
        cropH: defaultCrop.height,
        cropW: defaultCrop.width,
        orientation: defaultCrop.rotation,
      };
    }

    // Client crop values are recorded on the image node (not layered image)
    imageNode.cropX = crop.cropX;
    imageNode.cropY = crop.cropY;
    imageNode.cropW = crop.cropW;
    imageNode.cropH = crop.cropH;
    imageNode.orientation = crop.orientation;
    imageNode.imageDisplayName = image.displayName;
    imageNode.imageInternalName = image.internalName;
  }

  return imageNode;
};

export const shapeCollectionImage = (
  image: ShopImage | null,
  background?: ShopBackground,
  imageTone: ImageTone = 'original',
): CollectionImage => {
  return {
    backgroundID: background?.id,
    displayName: image?.displayName || null,
    internalName: image?.internalName || null,
    imageTone,
  };
};

export const shapeCartImageOption = (
  option: PriceSheetOption,
  selected: OptionForSelectionOption,
  images: CreateCartImage[],
): CartImageOptionReq => ({
  images,
  optionID: selected.catalogOptionID,
  optionGroupID: option.catalogOptionGroupID,
  priceSheetOptionID: option.id,
  quantity: 0, // TODO: needs to be fixed in database - this isn't needed
  type: 'image',
  // NOTE: value isn't necessary to pass to cart-service, however, until we remove
  // this and for consistency, the value is set per what's expected for boolean or
  // selection types
  value: option.type === 'boolean' ? '1' : selected.name || '',
});

export const shapeCartProductOption = (
  optionGroupID: number,
  optionID: number,
  value: string,
): CartProductOptionReq => ({
  optionGroupID,
  optionID,
  value,
});

export const setBackgroundOnItem = (
  editItem: CreateCartPackageReq | CreateCartProductReq,
  background: ShopBackground,
): CreateCartPackageReq | CreateCartProductReq => {
  const copy = { ...editItem };
  if (copy.type === 'product') {
    // Set the background on each image node:
    copy.nodes = copy.nodes.map(node => ({
      ...node,
      backgroundID: node.type === 'image' ? background.id : null,
    }));
  } else if (copy.type === 'collection' || copy.type === 'imageDownload') {
    // Set the background on each collectionImage:
    copy.collectionImages = copy.collectionImages.map(image => ({
      ...image,
      backgroundID: background.id,
    }));
  } else if (copy.type === 'buildYourOwn' || copy.type === 'standard') {
    return {
      ...copy,
      products: copy.products.map(p => setBackgroundOnItem(p, background)),
    } as CreateCartPackageReq;
  }

  return copy;
};

export const validateCollectionImages = (
  collectionImages: CollectionImage[],
  images: Record<string, ShopImage>,
) => {
  return collectionImages.every(
    i => i.internalName && (!images[i.internalName]?.isGreenScreen || i.backgroundID),
  );
};

export const validateConfiguredItem = (
  cartItem: CreateCartProductReq | CreateCartPackageReq,
  shopItem: ShopPackage | ShopProduct,
  productNodeMap: Record<string, CatalogProductNode[]>,
  isPreOrder: boolean,
  isGreenScreen: boolean,
): boolean => {
  if (cartItem.type === 'buildYourOwn' || cartItem.type === 'standard') {
    const pkgItemMap = getPackageItemMap(shopItem as ShopPackage);
    return cartItem.products.every(p =>
      validateConfiguredItem(
        p,
        pkgItemMap[p.priceSheetItemID],
        productNodeMap,
        isPreOrder,
        isGreenScreen,
      ),
    );
  }

  const cartItemOptions = (cartItem.options || []).reduce<Record<string, CartProductOptionReq>>(
    (map, option) => {
      map[option.optionGroupID] = option;
      return map;
    },
    {},
  );

  for (const option of shopItem.options) {
    const cartOpt = cartItemOptions[option.catalogOptionGroupID];
    if (!cartOpt ? option.requirementType === 'required' : !cartOpt.optionID || !cartOpt.value) {
      return false;
    }
  }

  if (cartItem.type === 'collection' || cartItem.type === 'imageDownload') {
    const { collectionImages } = cartItem;
    // the type ShopProductCollection is used for both a collection and an imageDownload
    const { includeAll, minImages } = shopItem as ShopProductCollection;
    // const includeAll = (shopItem as ShopProductCollection).includeAll;
    const meetsMinimum = collectionImages.length >= minImages;
    // Check that the gallery is pre-order (so image selections are not required),
    // or that every entry for `collectionImages` has an image chosen
    return (
      (isPreOrder && !isGreenScreen) ||
      includeAll ||
      (meetsMinimum &&
        collectionImages.every(
          i => (!isGreenScreen || !!i.backgroundID) && (isPreOrder || !!i.displayName),
        ))
    );
  }
  if (cartItem.type === 'product') {
    const nodes = productNodeMap[(shopItem as ShopProduct).catalogProductID];
    const imageNodeMap = nodes.reduce<Record<number, ImageNode>>((result, node) => {
      if (node.type === 'image' && isConfigurableImageNode(node)) {
        result[node.id] = node;
      }
      return result;
    }, {});

    return cartItem.nodes.every(node => {
      const imageNode = imageNodeMap[node.catalogNodeID];
      // TODO: for full-order, it may be necessary that we do crop validation here,
      // or perhaps prior to making it to this stage, such as during configuration
      if (node.type === 'image' && imageNode) {
        const { skipBackgroundSelection } = imageNode;

        const isBGRequirementMet = skipBackgroundSelection || !isGreenScreen || !!node.backgroundID;
        const isImageRequirementMet = isPreOrder || !!node.imageInternalName;
        // Check that the gallery is pre-order, or that an image was chosen:
        return isBGRequirementMet && isImageRequirementMet;
      } else if (node.type === 'text') {
        return (node.text?.length || 0) > 0;
      }
      // WIP: Node is either `hidden` or `imageContainer`, return true for now,
      // but these nodes may not be in Shop -- TBD.
      return true;
    });
  }
  // Product is `nonPrintProduct`, return true:
  return true;
};

export const validatePackageImageSelection = (
  editPackage: CreateCartPackageReq,
  images: Record<string, ShopImage>,
  productNodeMap: Record<string, CatalogProductNode[]>,
  shopPackage: ShopPackage,
) => {
  return editPackage.products.every(p => {
    if (p.type === 'product') {
      const subItem = shopPackage.availableProducts.find(ap => ap.id === p.priceSheetItemID);
      return validateImgNodes(p.nodes, images, productNodeMap, subItem!);
    }
    if (p.type === 'collection' || p.type === 'imageDownload') {
      return validateCollectionImages(p.collectionImages, images);
    }
    return true;
  });
};

/**
 * Returns true if all text nodes belonging to the edit/cart product have a text value
 */
export const validateTextNodes = (
  edited: (CreateCartNodeReq | CartTextNode)[],
  catalogNodes: CatalogProductNode[],
  /**
   * Optional parameter invalidates the default template string if true
   */
  invalidateTemplate?: boolean,
) => {
  const lockedMap = catalogNodes.reduce<Record<string, boolean>>((res, node) => {
    res[node.id] = node.locked;
    return res;
  }, {});

  const isTextValid = (str: string) => {
    return (!invalidateTemplate || !IS_TEMPLATE_STRING.test(str)) && str.length > 0;
  };

  return edited.every(
    n => n.type !== 'text' || lockedMap[n.catalogNodeID] || isTextValid(n.text || ''),
  );
};

export const validateImgNodes = (
  nodes: (CreateCartNodeReq | CartImageNode)[],
  images: Record<string, ShopImage>,
  productNodeMap: Record<string, CatalogProductNode[]>,
  shopProduct: ShopProduct,
) => {
  const catalogNodes = productNodeMap[shopProduct.catalogProductID];
  const imageNodeMap = catalogNodes.reduce<Record<number, ImageNode>>((result, node) => {
    if (node.type === 'image') {
      result[node.id] = node;
    }
    return result;
  }, {});

  return nodes.every(
    n =>
      n.type !== 'image' ||
      imageNodeMap[n.catalogNodeID].defaultImage === 'blank' ||
      (n.imageInternalName &&
        (!images[n.imageInternalName]?.isGreenScreen ||
          n.backgroundID ||
          n.skipBackgroundSelection)),
  );
};

/**
 * Returns true if a background has been chosen for every image or node of a configured item,
 * or if no selection is required.
 */
export const validateBackgroundSelection = (
  editItem: CreateCartPackageReq | CreateCartProductReq,
  requiresBG: boolean,
): boolean => {
  if (!requiresBG) {
    return true;
  }
  if (editItem.type === 'buildYourOwn' || editItem.type === 'standard') {
    return editItem.products.every(item => validateBackgroundSelection(item, requiresBG));
  }
  if (editItem.type === 'collection' || editItem.type === 'imageDownload') {
    return editItem.collectionImages.every(img => !!img.backgroundID);
  }
  if (editItem.type === 'product') {
    return editItem.nodes.every(node => node.type !== 'image' || !!node.backgroundID);
  }
  return editItem.type === 'nonPrintProduct';
};

export const requiresPerProductBG = (
  itemType: ShopProductTypes,
  selectionType: PreOrderBackgroundSelectionType | null,
  isGreenScreen: boolean,
  isPreOrder: boolean,
  backgroundSets: ShopBackgroundSet[],
) => {
  if (!isPreOrder || !isGreenScreen || itemType === 'nonPrintProduct' || !backgroundSets.length) {
    return false;
  }
  const isPackage = itemType === 'package' || itemType === 'package-byop';
  if (isPackage && selectionType === 'perImage') {
    return false;
  }
  return selectionType !== 'perOrder';
};

export const requiresPerImageBG = (
  itemType: ShopProductTypes,
  selectionType: PreOrderBackgroundSelectionType | null,
  isGreenScreen: boolean,
  isPreOrder: boolean,
  backgroundSets: ShopBackgroundSet[],
) => {
  if (!isPreOrder || !isGreenScreen || itemType === 'nonPrintProduct' || !backgroundSets.length) {
    return false;
  }
  return selectionType === 'perImage';
};
