import { Box, CircularProgress, CircularProgressLabel, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopPackage } from '../../../../../../../../shop-api-client';
import {
  selectConfiguration,
  selectConfiguredProgress,
  selectGroupedPkgItems,
  selectPackageItemMap,
} from '../../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../../redux/selectors/priceSheet.selectors';
import CheckMarkIcon from '../../../../../../../shared/components/CheckMarkIcon';
import useIsMobile from '../../../../../../../shared/hooks/useIsMobile';
import ProductImage from '../../../../../ProductImage';
import { NEXT_ADD_ONS, NEXT_SUMMARY, START_CUSTOMIZING } from '../../../../constants';
import useConfigurationRouter from '../../../../useConfigurationRouter';
import EditorSidebar from '../../../EditorSidebar';
import PackageFooter from '../../../PackageFooter';
import ConfigurableItemRow from '../ConfigurableItemRow';

const CustomizeOverview = () => {
  const { completedPkgItems, editPackage } = useSelector(selectConfiguration);
  const { configurableItems, nonConfigurableItems } = useSelector(selectGroupedPkgItems);
  const { imageOptionMap, products } = useSelector(selectPriceSheet);
  const packageItemMap = useSelector(selectPackageItemMap);
  const progress = useSelector(selectConfiguredProgress);

  const { goToDetails, routeToWizardStep } = useConfigurationRouter();
  const intl = useIntl();
  const isMobile = useIsMobile();

  if (!editPackage) {
    return null;
  }
  const hasImageOptions = Object.keys(imageOptionMap).length;
  const shopPkg = products[editPackage.priceSheetItemID] as ShopPackage;
  const remaining = configurableItems.filter(item => !completedPkgItems[item.id!]);

  const createHandleNext = (index?: number) => () => {
    if (index === undefined && !remaining.length) {
      if (hasImageOptions) {
        routeToWizardStep('imageOption');
      } else {
        routeToWizardStep('summary');
      }
    } else {
      routeToWizardStep('customize', (index || 0) + 1);
    }
  };

  const getBtnLabel = () => {
    if (remaining.length > 0) {
      return START_CUSTOMIZING;
    }
    return hasImageOptions ? NEXT_ADD_ONS : NEXT_SUMMARY;
  };

  const handleBack = () => {
    if (editPackage.type === 'buildYourOwn') {
      routeToWizardStep('byop');
    } else {
      goToDetails(products[editPackage.priceSheetItemID].categoryID);
    }
  };

  return (
    <Flex overflow="hidden" direction={{ base: 'column', md: 'row' }}>
      {!isMobile && (
        <ProductImage
          fallbackFontSize="xl"
          fallbackIconSize="100px"
          height={undefined}
          image={shopPkg.image || ''}
          product={shopPkg}
          isSquare
          padding={6}
        />
      )}
      <EditorSidebar>
        <Flex align="center" justify="space-between">
          <Box>
            <Text fontSize="lg" fontWeight="bold">
              {intl.formatMessage({
                id: 'customize.customizeItems',
                defaultMessage: 'Customize Items',
              })}
            </Text>
            <Text fontSize="sm" margin="2px">
              {intl.formatMessage(
                {
                  id: 'customize.itemsCompletedOfTotal',
                  defaultMessage: 'ITEMS ({configurable} OF {total})',
                },
                {
                  configurable: configurableItems.length,
                  total: editPackage.products.length,
                },
              )}
            </Text>
          </Box>
          <CircularProgress value={progress} color="brand" size="65px">
            <CircularProgressLabel>{progress}%</CircularProgressLabel>
          </CircularProgress>
        </Flex>
        <Box marginBottom={5}>
          {configurableItems.map((item, index) => (
            <ConfigurableItemRow
              key={item.id}
              isComplete={completedPkgItems[item.id!]}
              name={packageItemMap[item.priceSheetItemID].name}
              onClick={createHandleNext(index)}
            />
          ))}
        </Box>
        {nonConfigurableItems.length > 0 && (
          <Box marginBottom={10}>
            <Text fontSize="sm" margin="2px" marginBottom={2}>
              {intl.formatMessage(
                {
                  id: 'customize.readyToGo',
                  defaultMessage: 'READY TO GO ({ready} OF {total})',
                },
                { ready: nonConfigurableItems.length, total: editPackage.products.length },
              )}
            </Text>
            <Text fontSize="sm" margin="2px">
              {intl.formatMessage(
                {
                  id: 'customize.doesntNeedCustomized',
                  defaultMessage: '{count} of your items {doNot} need to be customized',
                },
                {
                  count: nonConfigurableItems.length,
                  doNot: nonConfigurableItems.length === 1 ? "doesn't" : "don't",
                },
              )}
            </Text>
            {nonConfigurableItems.map(product => (
              <Flex
                key={product.id}
                align="center"
                backgroundColor="grey.1"
                borderRadius={5}
                marginY={3}
                padding={3}
              >
                <CheckMarkIcon
                  backgroundColor="successGreen"
                  iconProps={{ fontSize: 'sm' }}
                  marginX={1}
                  size="20px"
                />
                <Text color="grey.6" fontSize="sm" marginX={2}>
                  {packageItemMap[product.priceSheetItemID].name}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
      </EditorSidebar>
      <PackageFooter nextLabel={getBtnLabel()} onBack={handleBack} onNext={createHandleNext()} />
    </Flex>
  );
};

export default CustomizeOverview;
