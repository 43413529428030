import { createSelector } from '@reduxjs/toolkit';
import { PriceSheetOption, ShopPackage, ShopProduct } from '../../../shop-api-client';
import { PRICE_SHEET_DEFAULTS } from '../slices/gallery.slice';
import { RootState } from '../store';
import { selectGalleryMap } from './gallery.selectors';

const MAX_PRICE_DIFF = 100;

export function selectPriceSheetMap(state: RootState) {
  return state.gallery.priceSheetMap;
}

export const selectAdditionalItems = createSelector(
  [
    (_, products: Record<string, ShopProduct | ShopPackage>) => products,
    (_, __, productID: string) => productID,
  ],
  (products, productID) => {
    const selected = products[productID];
    if (!selected) {
      return [];
    }
    // Return all products that are +/- MAX_PRICE_DIFF of the currently selected product:
    return Object.values(products)
      .reduce<number[]>((result, product) => {
        const priceDiff = Math.abs(selected.price - product.price);
        if (product.id !== selected.id && priceDiff <= MAX_PRICE_DIFF) {
          result.push(product.id);
        }
        return result;
      }, [])
      .sort((a, b) => {
        const priceDiffA = Math.abs(selected.price - products[a].price);
        const priceDiffB = Math.abs(selected.price - products[b].price);
        return priceDiffA - priceDiffB;
      });
  },
);

// Select a single PS (PS of current gallery if none is provided)
export const selectPriceSheet = createSelector(
  [
    (state: RootState) => state.gallery.priceSheetMap,
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, priceSheetID?: number) => priceSheetID,
  ],
  (priceSheetMap, galleryMap, currentVisitKey, priceSheetID) =>
    priceSheetMap[priceSheetID || galleryMap[currentVisitKey!]?.priceSheetID] ||
    PRICE_SHEET_DEFAULTS,
);

export const selectVisitKeytoPriceSheetIDMap = createSelector(selectGalleryMap, galleryMap =>
  Object.entries(galleryMap).reduce<Record<string, number>>((map, [visitKey, gallery]) => {
    map[visitKey] = gallery.priceSheetID;
    return map;
  }, {}),
);

/**
 * returns a product by looking it up via pricesheetItemID
 * if no pricesheetID is provided, defaults to using the pricesheet of current gallery
 */
export const selectPriceSheetItem = createSelector(
  [
    (state: RootState) => state.visitor.currentVisitKey,
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.gallery.priceSheetMap,
    (_, priceSheetItemID?: number) => priceSheetItemID,
    (_, __, visitKey?: string) => visitKey,
  ],
  (currentVisitKey, galleryMap, priceSheetMap, priceSheetItemID, visitKey) => {
    if (!priceSheetItemID) {
      return;
    }

    const { priceSheetID } = galleryMap[visitKey || currentVisitKey!];

    const { products } = priceSheetMap[priceSheetID];
    return products[priceSheetItemID];
  },
);

/**
 * Finds and returns all pricesheet products for a package and returns it as a map
 * Takes both psID for package and visitKey
 * defaults to currentVisitKey if no visitKey is passed
 */
export const selectProductsForPackage = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.gallery.priceSheetMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, packagePSItemID?: number) => packagePSItemID,
    (_, __, visitKey?: string) => visitKey,
  ],
  (galleryMap, priceSheetMap, currentVisitKey, packagePSItemID, visitKey) => {
    if (!packagePSItemID) {
      return {};
    }

    const { priceSheetID } = galleryMap[visitKey || currentVisitKey!];
    const { products } = priceSheetMap[priceSheetID];
    const pkg = products[packagePSItemID];

    if (pkg.type !== 'package' && pkg?.type !== 'package-byop') {
      return {};
    }
    return pkg.availableProducts.reduce<Record<string, ShopProduct>>((map, product) => {
      map[product.id] = product;
      return map;
    }, {});
  },
);

export const selectGroupedImageOptions = createSelector(
  selectPriceSheet,
  ({ imageOptionMap, packageImageOptionMap }) => {
    return Object.values(imageOptionMap).reduce(
      (result, option) => {
        if (option.requirementType === 'required' && !packageImageOptionMap.has(option.id)) {
          result.requiredImageOptions.push(option);
        } else if (!packageImageOptionMap.has(option.id)) {
          result.optionalImageOptions.push(option);
        }
        return result;
      },
      {
        optionalImageOptions: [] as PriceSheetOption[],
        requiredImageOptions: [] as PriceSheetOption[],
      },
    );
  },
);

export const selectPSproductsByType = createSelector(selectPriceSheet, ({ products }) => {
  const packages = Object.values(products).reduce<number[]>((result, product) => {
    if (product.type === 'package' || product.type === 'package-byop') {
      result.push(product.id);
    }
    return result;
  }, []);

  const productsAndPrints = Object.values(products).reduce<number[]>((result, product) => {
    if (product.type !== 'package' && product.type !== 'package-byop') {
      result.push(product.id);
    }
    return result;
  }, []);

  return { packages, productsAndPrints };
});

export const selectLockedAndUnlockedCategories = createSelector(
  selectPriceSheet,
  ({ categories, offersStatusMap }) => {
    const lockedCategories = Object.keys(offersStatusMap).filter(
      categoryID => offersStatusMap[categoryID].isLocked,
    );

    const unlockedCategories = Object.keys(categories).filter(
      categoryID => !offersStatusMap[categoryID]?.isLocked,
    );

    return { lockedCategories, unlockedCategories };
  },
);
