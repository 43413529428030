import { Box, DrawerBody, Flex, Heading, Icon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaExclamation, FaSignOutAlt } from 'react-icons/fa';
import { IoCamera } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../../redux/selectors/visitor.selectors';
import { useAppDispatch } from '../../../../redux/store';
import { logout } from '../../../../redux/thunks/auth.thunks';
import SlidingMenu from '../../../../shared/components/SlidingMenu';
import NavigationAction from '../../NavigationAction';
import MobileNavLink from '../MobileNavLink';
import ShopCategoriesMenu from '../ShopCategoriesMenu';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

/**
 * The drawer menu used when the visitor is viewing a gallery. Along with
 * links to the gallery's features, allows the visitor to switch galleries
 * or sign out
 */
const NavigationMenu = ({ isOpen, onClose }: Props) => {
  const { isPreOrder, title, type, yearbookPoses } = useSelector(selectGallery);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const { yearbookSelections } = useSelector(selectGallery);

  const [showMainMenu, setShowMainMenu] = useState(true);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const isYBPoses =
    yearbookPoses === 1
      ? yearbookSelections?.yearbookSelection1
      : yearbookSelections?.yearbookSelection1 && yearbookSelections?.yearbookSelection2;

  const chooseYearbook = intl.formatMessage({
    id: 'navigation.yearbook',
    defaultMessage: 'Choose Yearbook Pose',
  });
  const myPhotos = intl.formatMessage({
    id: 'navigation.myPhotos',
    defaultMessage: 'My Photos',
  });
  const shopProducts = intl.formatMessage({
    id: 'navigation.shopProducts',
    defaultMessage: 'Shop Products',
  });
  const viewAnotherGallery = intl.formatMessage({
    id: 'navigation.viewAnotherGallery',
    defaultMessage: 'View Another Gallery',
  });
  const signOutText = intl.formatMessage({
    id: 'navigation.signOut',
    defaultMessage: 'Sign Out',
  });

  useEffect(() => {
    if (!isOpen && !showMainMenu) {
      setShowMainMenu(true);
    }
  }, [showMainMenu, isOpen]);

  const exitSubMenu = () => setShowMainMenu(true);

  const handleNavClick = (
    heading: string,
    path: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (heading === shopProducts) {
      setShowMainMenu(false);
      e.stopPropagation();
    } else {
      history.push(path);
    }
  };

  const handleSignout = () => dispatch(logout());

  return (
    <SlidingMenu isOpen={isOpen} onClose={onClose} size="full">
      <Flex
        direction="row"
        left={showMainMenu ? 0 : '-100vw'}
        overflow={showMainMenu ? 'hidden' : 'unset'}
        position="relative"
        transition="left .2s ease"
        height="100%"
        width="100%"
      >
        <Box minWidth="100vw">
          <DrawerBody display="flex" flexDirection="column" onClick={onClose} padding="0">
            <Heading margin="20px" size="sm" textAlign="center" data-test="navigation-title">
              {title}
            </Heading>
            <Box padding="0 10px 20px 10px">
              {!isPreOrder && (
                <MobileNavLink
                  path={`/${currentVisitKey}/photos${type === 'subject' ? '/all' : ''}`}
                  label={myPhotos}
                  onNavClick={handleNavClick}
                  data-test="mobile-nav-my-photos"
                />
              )}
              <MobileNavLink
                data-test="mobile-nav-shop-products"
                label={shopProducts}
                onNavClick={handleNavClick}
                path={history.location.pathname}
                showArrow
              />
              {!isPreOrder && !!yearbookPoses && (
                <MobileNavLink
                  data-test="mobile-nav-choose-yearbook"
                  label={chooseYearbook}
                  notificationColor="error"
                  notificationContent={
                    isYBPoses ? null : (
                      <Icon data-test="mobile-nav-choose-yearbook-alert" as={FaExclamation} />
                    )
                  }
                  onNavClick={handleNavClick}
                  path={`/${currentVisitKey}/yearbook`}
                />
              )}
            </Box>
            <Flex align="flex-start" flexDirection="column" paddingLeft="22px" paddingTop="20px">
              <NavigationAction
                data-test="mobile-nav-switch-gallery"
                leftIcon={IoCamera}
                text={viewAnotherGallery}
                to={`/${currentVisitKey}/galleries/find`}
              />
              <NavigationAction
                leftIcon={FaSignOutAlt}
                onClick={handleSignout}
                to={`/${currentVisitKey}`}
                text={signOutText}
                data-test="mobile-nav-signout"
              />
            </Flex>
          </DrawerBody>
        </Box>
        <ShopCategoriesMenu onBackClick={exitSubMenu} onSelectCategory={onClose} />
      </Flex>
    </SlidingMenu>
  );
};

export default NavigationMenu;
