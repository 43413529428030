import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ShopProduct } from '../../../../../../../shop-api-client';
import NumberInput from '../../../../../../shared/components/NumberInput';
import { pluralize } from '../../../../../../shared/utils';
import DisplayImage from '../DisplayImage';

interface Props {
  includedCount: number;
  isLast: boolean;
  quantity: number;
  onInputChange(item: ShopProduct, isIncrement: boolean): void;
  remainingBalance: number;
  shopItem: ShopProduct;
  unitLabel: string;
}

const AvailableItem = ({
  includedCount,
  isLast,
  onInputChange,
  quantity,
  remainingBalance,
  shopItem,
  unitLabel,
}: Props) => {
  const intl = useIntl();
  const { price } = shopItem;
  const isIncluded = price === 0;
  // Max is found by adding the current quantity to the qty that may still be added
  const max = quantity + Math.floor(remainingBalance / price);

  return (
    <Flex
      data-test={`${shopItem.name}-container`}
      key={shopItem.id}
      align="center"
      borderBottomWidth={isLast ? 1 : 0}
      borderColor="grey.2"
      borderTopWidth={1}
      paddingY={2}
    >
      <DisplayImage minWidth="65px" src={shopItem.image} product={shopItem} />
      <Box paddingX={4}>
        {includedCount > 0 && (
          <Box
            backgroundColor="black"
            borderRadius={12}
            color="white"
            fontSize="xs"
            fontWeight="bold"
            padding="2px"
            width="80px"
          >
            <Text textAlign="center">
              {intl.formatMessage(
                {
                  id: 'byopSelectionStep.includedCount',
                  defaultMessage: '{count} included',
                },
                { count: includedCount },
              )}
            </Text>
          </Box>
        )}
        <Text>{shopItem.name}</Text>
        {!isIncluded && (
          <Text color="brand" fontSize="xs">
            {intl.formatMessage(
              {
                id: 'BYOPSelectionStep.itemPrice',
                defaultMessage: '{price} {units}',
              },
              {
                price: price,
                units: pluralize(price, unitLabel, false),
              },
            )}
          </Text>
        )}
      </Box>
      <Spacer />
      <NumberInput
        defaultValue={0}
        max={max}
        min={0}
        onValueChange={(val: number) => onInputChange(shopItem, val > quantity)}
        overrideValue={isIncluded ? 0 : quantity}
      />
    </Flex>
  );
};

export default AvailableItem;
