import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectInteractions } from '../../redux/selectors/interactions.selectors';
import { selectVisitor } from '../../redux/selectors/visitor.selectors';
import { ABOVE_MODAL_Z_INDEX, NAVIGATION_Z_INDEX } from '../../shared/constants';
import GalleryWelcome from '../MultiGallery/GalleryWelcome';
import { DISCOUNTS_BANNER_HEIGHT } from './constants';
import DesktopNavigation from './DesktopNavigation';
import MobileActionBar from './MobileActionBar';

const Navigation = (props: BoxProps) => {
  const { isLoggedIn } = useSelector(selectVisitor);
  const { showNav, showWelcomeModal, showFindGalleryModal } = useSelector(selectInteractions);

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Box
      as="nav"
      backgroundColor="white"
      display={showNav ? undefined : 'none'}
      position={isMobile ? 'sticky' : 'relative'}
      top="0"
      width={showFindGalleryModal ? '100vw' : undefined}
      zIndex={showFindGalleryModal ? ABOVE_MODAL_Z_INDEX : NAVIGATION_Z_INDEX}
      {...props}
    >
      <Box background="black" height={`${DISCOUNTS_BANNER_HEIGHT}px`} width="100%">
        {/* TODO: Replace this box with notification banner component */}
      </Box>
      {isMobile ? <MobileActionBar showNavigation /> : <DesktopNavigation />}
      {showWelcomeModal && <GalleryWelcome />}
    </Box>
  );
};

export default Navigation;
