import { Button, DrawerBody, DrawerHeader, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaSearchPlus } from 'react-icons/fa';
import { IoCallOutline } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import ExternalLink from '../../../../shared/components/ExternalLink';
import SlidingMenu from '../../../../shared/components/SlidingMenu';
import ContactSection from '../ContactSection';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const ContactMenu = ({ isOpen, onClose }: Props) => {
  const { contact } = useSelector(selectAccount);
  const intl = useIntl();

  if (!contact) {
    return null;
  }

  const handleEmailClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(`mailto:${contact.email}`);
  };

  return (
    <SlidingMenu isOpen={isOpen} onClose={onClose}>
      <DrawerHeader as="nav" padding={0}>
        <Heading marginTop={5} textAlign="center" fontSize="24px" data-test="contact-details-title">
          {intl.formatMessage({
            id: 'accountLanding.contact',
            defaultMessage: 'Contact',
          })}
        </Heading>
      </DrawerHeader>
      <DrawerBody marginLeft={3}>
        {/* TODO: Determine fallbacks for incomplete address data */}
        {contact.address && (
          <ContactSection heading="Our Office" icon={FaSearchPlus}>
            <Text fontSize="sm">{contact.address}</Text>
            <Text fontSize="sm">
              {contact.city}, {contact.state} {contact.zip}
            </Text>
          </ContactSection>
        )}
        {contact.email && (
          <ContactSection heading="Email Us" icon={AiOutlineMail}>
            <Button
              color="brand"
              marginY={1}
              onClick={handleEmailClick}
              variant="link"
              data-test="contact-details-email"
            >
              {contact.email}
            </Button>
          </ContactSection>
        )}
        {contact.phone && (
          <ContactSection heading="Call Us" icon={IoCallOutline}>
            <Text fontSize="sm" data-test="contact-details-phone">
              {contact.phone}
            </Text>
          </ContactSection>
        )}
        {contact.website && (
          <ContactSection heading={'Visit Our Website'} icon={FaSearchPlus}>
            <ExternalLink url={contact.website} data-test="contact-details-website-link" />
          </ContactSection>
        )}
      </DrawerBody>
    </SlidingMenu>
  );
};

export default ContactMenu;
