import { Flex, Spacer, Text } from '@chakra-ui/layout';
import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import CheckMarkIcon from '../../../../../../../shared/components/CheckMarkIcon';

interface Props {
  isComplete: boolean;
  name: string;
  onClick(): void;
}

const ConfigurableItemRow = ({ isComplete, name, onClick }: Props) => {
  const intl = useIntl();
  const renderButton = () => {
    if (isComplete) {
      return <Icon as={HiOutlineArrowRight} color="grey.3" fontSize="sm" marginX={2} />;
    }
    return (
      <Button
        backgroundColor="blue.1"
        borderRadius={10}
        color="blue.6"
        fontFamily="inherit"
        fontSize="xs"
        height="25px"
        minWidth="85px"
      >
        {intl.formatMessage({
          id: 'customizeLanding.choose',
          defaultMessage: 'Choose',
        })}
      </Button>
    );
  };

  return (
    <Flex
      align="center"
      backgroundColor={isComplete ? 'grey.1' : 'white'}
      borderColor={isComplete ? 'grey.1' : 'grey.2'}
      borderRadius={5}
      borderWidth={isComplete ? 0 : 1}
      marginY={3}
      onClick={onClick}
      padding={4}
    >
      <CheckMarkIcon
        backgroundColor={isComplete ? 'successGreen' : 'white'}
        borderColor="grey.3"
        borderWidth={isComplete ? 0 : '1px'}
        iconProps={{ color: isComplete ? 'white' : 'grey.3', fontSize: 'md' }}
        size="25px"
      />
      <Text marginX={2}>{name}</Text>
      <Spacer />
      {renderButton()}
    </Flex>
  );
};

export default ConfigurableItemRow;
