import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogProductNode } from 'iq-product-render';
import {
  CartSettings,
  GalleryType,
  PreOrderBackgroundSelectionType,
  PriceSheetOption,
  SessionGallery,
  ShopBackgroundSet,
  ShopCategory,
  ShopData,
  ShopImage,
  ShopOptionCategory,
  ShopPackage,
  ShopProduct,
  YearbookPosesValue,
  YearbookSelections,
} from '../../../shop-api-client';
import { ShopGalleryConfig } from '../../../shop-api-client/models/ShopConfig';
import { ShopProductNodes } from '../../../shop-api-client/models/ShopProductNodes';
import { signout } from './visitor.slice';

export type BehaviorStatusMap = Record<string, BehaviorStatus>;
export type OffersStatusMap = Record<string, CategoryStatus>;

export interface BehaviorStatus {
  conditionStatusMap: Record<string, ConditionStatus>;
  progressPercentage: number;
}

export interface CategoryStatus {
  behaviorStatusMap: BehaviorStatusMap;
  isLocked: boolean;
}

export interface ConditionStatus {
  isMet: boolean;
  subtotal?: number;
}

interface FlattenedOptionCategory extends Omit<ShopOptionCategory, 'options'> {
  options: number[];
}

interface FlattenedImageOptionCategory extends FlattenedOptionCategory {
  type: 'image';
}

interface FlattenedOrderOptionCategory extends FlattenedOptionCategory {
  type: 'order';
}

export interface Gallery {
  customDataSpecID?: number | null;
  customDate1?: string;
  customDate2?: string;
  customMarketing1?: string;
  customMarketing2?: string;
  customMarketing3?: string;
  customMarketing4?: string;
  customMarketing5?: string;
  eventDate?: string;
  expirationDate?: string;
  galleryConfig: ShopGalleryConfig | null;
  galleryID: number;
  groupImageMap: Record<string, string[]>;
  groups: Record<string, string>;
  images: Record<string, ShopImage>;
  isGreenScreen: boolean;
  isLoading: boolean;
  isPreOrder: boolean;
  jobImage1: string | null;
  jobImage2: string | null;
  jobImage3: string | null;
  keyword?: string;
  lastModifiedDate?: string;
  priceSheetID: number;
  password?: string;
  reference?: string;
  retakeDate?: string;
  settings: CartSettings;
  sortedGroupIDs: number[];
  subjectID: number | undefined;
  subjectImageNames: string[];
  title: string;
  type: GalleryType;
  welcomeMessage?: string;
  yearbookDueDate: string | null | undefined;
  yearbookMessage: string | undefined;
  yearbookPoses: YearbookPosesValue | undefined;
  yearbookSelections?: YearbookSelections;
}

export interface PriceSheet {
  backgroundSets: ShopBackgroundSet[];
  categories: Record<string, ShopCategory>;
  imageOptionCategoryMap: Record<string, FlattenedImageOptionCategory>;
  imageOptionMap: Record<string, PriceSheetOption>;
  lastModifiedDate?: string;
  offersStatusMap: OffersStatusMap;
  orderOptionCategoryMap: Record<string, FlattenedOrderOptionCategory>;
  orderOptionMap: Record<string, PriceSheetOption>;
  packageImageOptionMap: Set<number>;
  preOrderBackgroundSelectionType: PreOrderBackgroundSelectionType | null;
  productCategoryMap: Record<string, number[]>;
  productNodeMap: Record<string, CatalogProductNode[]>;
  products: Record<string, ShopProduct | ShopPackage>;
  sortedCategoryIDs: number[];
}

export const GALLERY_DEFAULTS: Gallery = {
  customDataSpecID: null,
  galleryConfig: null,
  galleryID: null!,
  groupImageMap: {},
  groups: {},
  images: {},
  isGreenScreen: false,
  isLoading: true,
  isPreOrder: false,
  jobImage1: null,
  jobImage2: null,
  jobImage3: null,
  priceSheetID: null!,
  settings: {} as CartSettings,
  sortedGroupIDs: [],
  subjectID: undefined,
  subjectImageNames: [],
  title: '',
  type: null!,
  yearbookDueDate: null,
  yearbookMessage: '',
  yearbookPoses: 0,
  yearbookSelections: undefined,
};

export const PRICE_SHEET_DEFAULTS: PriceSheet = {
  backgroundSets: [],
  categories: {},
  imageOptionCategoryMap: {},
  imageOptionMap: {},
  offersStatusMap: {},
  orderOptionCategoryMap: {},
  orderOptionMap: {},
  packageImageOptionMap: new Set<number>(),
  preOrderBackgroundSelectionType: null,
  productCategoryMap: { all: [] },
  productNodeMap: {},
  products: {},
  sortedCategoryIDs: [],
};

export interface GalleryMap {
  [key: string]: Gallery;
}

export interface PriceSheetMap {
  [key: number]: PriceSheet;
}

const initialState: { galleryMap: GalleryMap; priceSheetMap: PriceSheetMap } = {
  galleryMap: {},
  priceSheetMap: {},
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    initializeShopData(state, action: PayloadAction<{ data: ShopData; key: string }>) {
      const {
        data: {
          customDate1,
          customDate2,
          customMarketing1,
          customMarketing2,
          customMarketing3,
          customMarketing4,
          customMarketing5,
          eventDate,
          expirationDate,
          galleryID,
          isPreOrder,
          jobImage1,
          jobImage2,
          jobImage3,
          title,
          type,
          customDataSpecID,
          galleryConfig,
          galleryLastModifiedDate,
          images,
          isGreenScreen,
          keyword,
          password,
          priceSheetID,
          reference,
          retakeDate,
          settings,
          subjectID,
          yearbookDueDate,
          yearbookMessage,
          yearbookPoses,
          yearbookSelections,
          welcomeMessage,
        },
        key,
      } = action.payload;

      const newGallery: Gallery = {
        customDataSpecID,
        customDate1,
        customDate2,
        customMarketing1,
        customMarketing2,
        customMarketing3,
        customMarketing4,
        customMarketing5,
        eventDate,
        expirationDate,
        galleryConfig,
        galleryID,
        groupImageMap: {}, // map image group id to array of image ids
        isGreenScreen,
        isLoading: false,
        isPreOrder,
        jobImage1,
        jobImage2,
        jobImage3,
        keyword,
        lastModifiedDate: galleryLastModifiedDate,
        password,
        priceSheetID,
        reference,
        retakeDate,
        settings,
        subjectID,
        title,
        type,
        yearbookDueDate,
        yearbookMessage,
        yearbookPoses,
        yearbookSelections,
        images: {}, // map image id (internalName) to image
        groups: {}, // map image group id to groupname
        sortedGroupIDs: [], // array of group ids - used to keep order as it comes in
        subjectImageNames: [], // array of all subject image ids, keeps order of images === incoming img pay
        welcomeMessage,
      };

      // Set image data
      images.forEach(image => {
        newGallery.images[image.internalName] = image;
        // If the image is associated with a group...
        if (image.group) {
          // push to array of image names keyed by the group they belong to:
          if (newGallery.groupImageMap[image.group.id]) {
            newGallery.groupImageMap[image.group.id].push(image.internalName);
          } else {
            newGallery.groupImageMap[image.group.id] = [image.internalName];
          }
          // If group ID is not already in groups, it's a new group
          if (!newGallery.groups[image.group.id]) {
            // Add to groups map to map id to name
            newGallery.groups[image.group.id] = image.group.name;
            // Push to sortedGroupIDs to save group order
            newGallery.sortedGroupIDs.push(image.group.id);
          }
        } else {
          // Else the image is a subject image, so store it to an array of subject image names:
          newGallery.subjectImageNames.push(image.internalName);
        }
      });

      state.galleryMap[key] = newGallery;
    },
    removeGallery(state, action: PayloadAction<string>) {
      delete state.galleryMap[action.payload];
    },
    setSessionGalleries(state, action: PayloadAction<Record<string, SessionGallery>>) {
      for (const [visitKey, sessionGallery] of Object.entries(action.payload)) {
        const alreadyInState = state.galleryMap[visitKey];

        if (!alreadyInState && !sessionGallery.unavailableMessage) {
          state.galleryMap[visitKey] = {
            ...GALLERY_DEFAULTS,
            title: sessionGallery.galleryTitle,
            subjectID: sessionGallery.subjectID,
          };
        }
      }
    },
    setPriceSheets(state, action: PayloadAction<ShopData>) {
      const {
        categories,
        imageOptions,
        orderOptions,
        priceSheetID,
        products,
        backgroundSets,
        priceSheetLastModifiedDate,
        preOrderBackgroundSelectionType,
      } = action.payload;
      // Check if pricesheet already in hashmap
      if (state.priceSheetMap[priceSheetID]) {
        return state;
      }

      // init with defaults
      const newPriceSheet: PriceSheet = {
        backgroundSets,
        categories: {},
        imageOptionCategoryMap: {},
        imageOptionMap: {},
        lastModifiedDate: priceSheetLastModifiedDate,
        offersStatusMap: {},
        orderOptionCategoryMap: {},
        orderOptionMap: {},
        packageImageOptionMap: new Set<number>(),
        preOrderBackgroundSelectionType,
        productCategoryMap: { all: [] },
        productNodeMap: {},
        products: {},
        sortedCategoryIDs: [],
      };

      // Set Shop Category data
      categories.forEach(category => {
        newPriceSheet.sortedCategoryIDs.push(category.id);
        newPriceSheet.categories[category.id] = category;

        // If the category has behaviors (Offers), initialize the category's entry in the
        // offersStatusMap map with the conditions' `isMet` status for each behavior set as `false`.
        // `isMet` refers to whether the requirement has been met for that condition and contributes
        // to the overall "locked" status for the category. When all conditions are met for a behavior,
        // its `isLocked` status will be toggled to false. The offer statuses will be managed each time
        // the visitor makes a change to their cart (See: the `manageLockedCategories` thunk).
        if (category.behaviors.some(b => !!b.conditions.length)) {
          newPriceSheet.offersStatusMap[category.id] = {
            behaviorStatusMap: {},
            isLocked: true,
          };

          const { behaviorStatusMap } = newPriceSheet.offersStatusMap[category.id];

          for (const behavior of category.behaviors) {
            behaviorStatusMap[behavior.id] = {
              conditionStatusMap: behavior.conditions.reduce<Record<string, ConditionStatus>>(
                (res, condition) => {
                  res[condition.id] = {
                    isMet: false,
                  };
                  return res;
                },
                {},
              ),
              progressPercentage: 0,
            };
          }
        }
      });

      // Set Shop Items data
      products.forEach(product => {
        newPriceSheet.products[product.id] = product;
        newPriceSheet.productCategoryMap.all.push(product.id);
        if (!newPriceSheet.productCategoryMap[product.categoryID]) {
          newPriceSheet.productCategoryMap[product.categoryID] = [product.id];
        } else {
          newPriceSheet.productCategoryMap[product.categoryID].push(product.id);
        }

        if (product.type === 'package' || product.type === 'package-byop') {
          // Package options are image options:
          for (const imageOption of product.options) {
            newPriceSheet.packageImageOptionMap.add(imageOption.id);
            newPriceSheet.imageOptionMap[imageOption.id] = imageOption;
          }
        }
      });

      // Set Options
      imageOptions.forEach(category => {
        const optionIDs: number[] = [];

        // Add this category's options to imageOptionMap
        category.options.forEach(option => {
          optionIDs.push(option.id);
          newPriceSheet.imageOptionMap[option.id] = { ...option, optionGroupType: category.type };
        });

        // Add the flattened category to imageOptionCategoryMap
        newPriceSheet.imageOptionCategoryMap[category.id] = {
          ...category,
          options: optionIDs,
        };
      });

      orderOptions.forEach(category => {
        const optionIDs: number[] = [];

        // Add this category's options to orderOptionMap
        category.options.forEach(option => {
          optionIDs.push(option.id);
          newPriceSheet.orderOptionMap[option.id] = { ...option, optionGroupType: category.type };
        });

        // Add the flattened category to orderOptionCategoryMap
        newPriceSheet.orderOptionCategoryMap[category.id] = {
          ...category,
          options: optionIDs,
        };
      });

      state.priceSheetMap[priceSheetID] = newPriceSheet;
    },
    setOffersStatusMap(
      state,
      action: PayloadAction<{ data: OffersStatusMap; priceSheetID: number }>,
    ) {
      const { data, priceSheetID } = action.payload;
      state.priceSheetMap[priceSheetID].offersStatusMap = data;
    },
    setProductNodeMap(state, action: PayloadAction<{ data: ShopProductNodes; key: number }>) {
      state.priceSheetMap[action.payload.key].productNodeMap = action.payload.data;
    },
    setIsLoading(state, action: PayloadAction<string>) {
      state.galleryMap[action.payload].isLoading = false;
    },
    setYearbookSelections(state, action: PayloadAction<{ data: YearbookSelections; key: string }>) {
      const { data, key } = action.payload;
      state.galleryMap[key].yearbookSelections = data;
    },
    setYearbookLastModifiedDate(state, action: PayloadAction<{ data: string; key: string }>) {
      const { data, key } = action.payload;
      if (state.galleryMap[key].yearbookSelections) {
        state.galleryMap[key].yearbookSelections!.lastModifiedDate = data;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(signout, () => {
      return initialState;
    });
  },
});

const { actions, reducer } = gallerySlice;

export const {
  initializeShopData,
  removeGallery,
  setIsLoading,
  setOffersStatusMap,
  setPriceSheets,
  setProductNodeMap,
  setSessionGalleries,
  setYearbookLastModifiedDate,
  setYearbookSelections,
} = actions;

export default reducer;
