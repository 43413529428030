import { Flex, Spinner, Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectCartSlice } from '../../../../../redux/selectors/cart.selectors';

export const EDITOR_SIDEBAR_WIDTH = 480;
export const EDITOR_NEXT_BUTTON_HEIGHT = 55;
export const SIDEBAR_MARGIN_BOTTOM = 20;

interface Props {
  children: ReactNode;
  isLoading?: boolean;
}

const EditorSidebar = ({ children, isLoading }: Props) => {
  const { isSubmittingCart } = useSelector(selectCartSlice);
  const minWidth = `${Math.min(window.innerWidth, EDITOR_SIDEBAR_WIDTH)}px`;

  const renderContents = () => {
    if (isLoading || isSubmittingCart) {
      return (
        <Flex align="center" height="100%" justify="center">
          <Spinner />
        </Flex>
      );
    }

    return (
      <Flex
        direction="column"
        height="100%"
        paddingBottom={`${EDITOR_NEXT_BUTTON_HEIGHT}px`}
        paddingX={{ base: 5, md: 10 }}
        paddingTop={{ base: 6, md: 10 }}
      >
        {children}
      </Flex>
    );
  };

  return (
    <Stack
      borderColor="grey.1"
      borderLeftWidth={{ base: 0, md: 1 }}
      minWidth={{ base: undefined, md: minWidth }}
      position="relative"
      width={{ base: '100%', md: minWidth }}
    >
      {renderContents()}
    </Stack>
  );
};

export default EditorSidebar;
