import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ShopPackage, ShopProduct } from '../../../../shop-api-client';
import { Gallery } from '../../../redux/slices/gallery.slice';
import useSearchParams from '../../../shared/hooks/useSearchParams';
import { Params } from '../../../shared/types/router';
import {
  getPackageConfigurationPath,
  getProductConfigurationPath,
  getWizardBackgroundsPath,
  getWizardBasePath,
  getWizardBYOPSelectionPath,
  getWizardCustomizeItemPath,
  getWizardCustomizeOverviewPath,
  getWizardImageOptionsPath,
  getWizardSummaryPath,
  isMultiImagePackage,
} from './utils';

export type PathKey = 'background' | 'byop' | 'customize' | 'imageOption' | 'summary';

const useConfigurationRouter = () => {
  const { search } = useLocation();
  const { key, packageID } = useParams<Params>();
  const { getParamsValue } = useSearchParams();
  const history = useHistory();
  const originatingKey = getParamsValue('originatingKey');

  // By default, will route to the carts of the originatingKey (used in multi-gallery
  // editing) or current visit key:
  const goToCart = (visitKey = originatingKey || key) => history.push(`/${visitKey}/carts`);

  const goToDetails = (categoryID: number) =>
    history.push(`/${key}/shop/${categoryID}/product/${packageID}`);

  /**
   * Used to route to steps in the package wizard
   */
  const routeToWizardStep = (pathKey: PathKey, itemID?: number | string) => {
    let pathname = '';
    if (pathKey === 'background') {
      pathname = getWizardBackgroundsPath(key, packageID);
    } else if (pathKey === 'byop') {
      pathname = getWizardBYOPSelectionPath(key, packageID);
    } else if (pathKey === 'customize') {
      if (itemID) {
        pathname = getWizardCustomizeItemPath(key, packageID, itemID);
      } else {
        pathname = getWizardCustomizeOverviewPath(key, packageID);
      }
    } else if (pathKey === 'imageOption') {
      pathname = getWizardImageOptionsPath(key, packageID);
    } else if (pathKey === 'summary') {
      pathname = getWizardSummaryPath(key, packageID);
    }

    history.push({ pathname, search });
  };

  /**
   * Routes to the configuration destination capable of handling the requirements of the given
   * item. If `canSelectAndBuy` is true, it will pass the search string for the ProductConfiguration
   * to parse and initialize configuration with the given image data
   */
  const routeToConfigurationPath = (
    key: string,
    item: ShopPackage | ShopProduct,
    { groupImageMap, isPreOrder, settings: { disableCropping }, type }: Gallery,
    appendSearch?: boolean,
    additionalQuery?: string,
  ) => {
    const hasGroupImages = !!Object.keys(groupImageMap).length;
    const isPackage = item.type === 'package' || item.type === 'package-byop';
    const routeToWizard =
      !isPreOrder &&
      isPackage &&
      (!disableCropping || isMultiImagePackage(item as ShopPackage, type, hasGroupImages));
    let pathname = undefined;

    if (routeToWizard) {
      pathname = getWizardBasePath(key, item.id);
    } else if (isPackage) {
      pathname = getPackageConfigurationPath(key, item.id);
    } else {
      pathname = getProductConfigurationPath(key, item.id);
    }

    history.push({ pathname, search: appendSearch ? `${search}${additionalQuery || ''}` : '' });
  };

  return { goToCart, goToDetails, routeToWizardStep, routeToConfigurationPath };
};

export default useConfigurationRouter;
