import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ShopImage } from '../../../../shop-api-client';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { GridMode, toggleShowNav } from '../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../redux/store';
import { updateYearbookSelections } from '../../../redux/thunks/yearbook.thunks';
import LayoutMenu from '../../../shared/components/LayoutMenu';
import ModalCloseButton from '../../../shared/components/ModalCloseButton';
import {
  GRID_ICON_LARGE,
  GRID_ICON_MEDIUM,
  GRID_ICON_SMALL,
  LARGE,
  MEDIUM,
  SMALL,
} from '../../../shared/constants';
import { Params } from '../../../shared/types/router';
import { getShopAllPath } from '../../../shared/utils';
import { NEXT, PREV } from '../../MyPhotos/constants';
import YearbookImageGrid from '../YearbookImageGrid';
import { hasDueDatePassed } from '../yearbook.utils';

const YearbookSelection = () => {
  // Redux
  const { isPreOrder, yearbookDueDate, yearbookMessage, yearbookPoses, yearbookSelections } =
    useSelector(selectGallery);

  //State
  const [direction, setDirection] = useState(NEXT);
  const [error, setError] = useState(false);
  const [imageSize, setImageSize] = useState(200);
  const [layout, setLayout] = useState<GridMode>(SMALL);
  const [selections, setSelections] = useState<{ [key: string]: string }>({
    newYearbookSelection1: '',
    newYearbookSelection2: '',
  });
  const [showNames, setShowNames] = useState(false);

  // Hooks
  const { key, poseType } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  // Redirect if expired, bad params route, or pre-order
  useEffect(() => {
    if (isPreOrder) {
      const shopAllPath = getShopAllPath(key);
      history.replace(shopAllPath);
      return;
    }

    const isPastDueDate = hasDueDatePassed(yearbookDueDate);
    const isRoute1 = yearbookPoses === 1 && poseType !== 'pose1';
    const isRoute2 = yearbookPoses === 2 && poseType !== 'pose1' && poseType !== 'pose2';

    if (isPastDueDate || isRoute1 || isRoute2) {
      history.push(`/${key}/yearbook`);
    }
  }, [history, key, poseType, yearbookDueDate, yearbookPoses, isPreOrder]);

  // Removes the navigation bar
  useEffect(() => {
    dispatch(toggleShowNav(false));
    return () => {
      dispatch(toggleShowNav(true));
    };
  }, [dispatch]);

  // Handles the rendering of the layout style
  useEffect(() => {
    setLayout(isMobile ? MEDIUM : SMALL);
  }, [isMobile]);

  useEffect(() => {
    if (layout === LARGE) {
      setImageSize(isMobile ? 400 : 500);
    } else if (layout === MEDIUM) {
      setImageSize(isMobile ? 150 : 300);
    } else if (layout === SMALL) {
      setImageSize(200);
    }
  }, [layout, isMobile]);

  // Handles selection of YB images in state
  useEffect(() => {
    setSelections({
      newYearbookSelection1: yearbookSelections?.yearbookSelection1 || '',
      newYearbookSelection2: yearbookSelections?.yearbookSelection2 || '',
    });
  }, [yearbookSelections]);

  const handleBack = () => {
    setDirection(PREV);
    history.push(poseType === 'pose2' ? `/${key}/yearbook/pose1` : `/${key}/yearbook`);
  };

  const handleConfirm = async () => {
    const isError = handleError();
    // If no subject ID or no YB option, return
    if (isError) {
      return;
    }

    try {
      await dispatch(updateYearbookSelections(key, selections));
      history.push(`/${key}/yearbook`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleError = () => {
    const isSelected1 = poseType === 'pose1' && selections.newYearbookSelection1;
    const isSelected2 = poseType === 'pose2' && selections.newYearbookSelection2;

    setError(poseType === 'pose1' ? !isSelected1 : !isSelected2);
    return poseType === 'pose1' ? !isSelected1 : !isSelected2;
  };

  const handleExit = () => {
    history.push(`/${key}/yearbook`);
  };

  const handleLayout = (layout: GridMode) => setLayout(layout);

  const handleNext = () => {
    const isError = handleError();

    if (isError) {
      return;
    } else {
      setDirection(NEXT);
      history.push(`/${key}/yearbook/pose2`);
    }
  };

  // Set state based on which pose # is being selected
  const handleSelection = (image: ShopImage) => {
    setError(false);

    setSelections(
      poseType === 'pose1'
        ? { ...selections, newYearbookSelection1: image.internalName }
        : { ...selections, newYearbookSelection2: image.internalName },
    );
  };

  const toggleShowNames = () => setShowNames(!showNames);

  // Footer button logic based on which pose # is in the url params
  const renderFooter = () => (
    <Flex
      bgColor="white"
      borderTopColor="grey.2"
      borderTopStyle="solid"
      borderTopWidth="1px"
      bottom="0"
      flexFlow="row"
      justifyContent="center"
      p={3}
      position="fixed"
      width="100%"
      zIndex="1"
    >
      <Flex justifyContent="center" width="100%">
        <Button
          bgColor="grey.2"
          maxW="30%"
          minW={isMobile ? '30%' : '10%'}
          mx={2}
          onClick={handleExit}
          variant="secondary"
          data-test="yearbook-selection-cancel-button"
        >
          <Text fontSize={isMobile ? 'sm' : 'md'} noOfLines={1}>
            {intl.formatMessage({ id: 'yearbook.cancel', defaultMessage: 'Cancel' })}
          </Text>
        </Button>

        <Button
          isDisabled={error}
          marginX={2}
          maxW="70%"
          minW={isMobile ? '70%' : '20%'}
          onClick={poseType === `pose${yearbookPoses}` ? handleConfirm : handleNext}
          variant="primary"
          data-test="yearbook-selection-confirm"
        >
          <Text fontSize={isMobile ? 'sm' : 'md'} noOfLines={1}>
            {poseType === `pose${yearbookPoses}`
              ? intl.formatMessage({ id: 'yearbook.confirm', defaultMessage: 'Confirm' })
              : intl.formatMessage({
                  id: 'yearbook.next',
                  defaultMessage: 'Next: Choose Pose #2',
                })}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );

  const renderHeader = () => (
    <Flex
      alignItems="center"
      bgColor="white"
      borderBottomColor={isMobile ? 'grey.2' : 'transparent'}
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      justifyContent="flex-start"
      position="fixed"
      paddingTop={2}
      top={0}
      width="100%"
      zIndex="2"
      paddingRight={4}
    >
      <Button
        fontSize="lg"
        color="gray.500"
        onClick={handleBack}
        variant="secondary"
        aria-label="back"
        data-test="yearbook-selection-back-button"
      >
        <ArrowBackIcon
          border="1px solid gray"
          borderRadius="50%"
          height="2rem"
          marginRight={3}
          p={1}
          width="2rem"
        />

        {!isMobile && intl.formatMessage({ id: 'yearbook.back', defaultMessage: 'Back' })}
      </Button>

      <Spacer />
      <Text fontSize={isMobile ? 'lg' : 'x-large'} noOfLines={1}>
        {intl.formatMessage(
          { id: 'yearbook.selectTitle', defaultMessage: '{Message} {poseType}' },
          {
            poseType: poseType === 'pose1' ? 'Pose #1' : 'Pose #2',
            Message: isMobile ? 'Select' : 'Select a photo for ',
          },
        )}
      </Text>
      <Spacer />
      <ModalCloseButton onClick={handleBack} lightMode aria-label="exit" />
    </Flex>
  );

  const renderInstructions = () => (
    <>
      <Flex alignItems="flex-start" flexFlow="column" justifyContent="center" marginTop={4}>
        <Heading fontSize="lg">
          {intl.formatMessage({
            id: 'yearbook.instructions',
            defaultMessage: 'Instructions',
          })}
        </Heading>
        <Text marginTop={3} size="lg" textAlign="left" whiteSpace="pre-line" width="100%">
          {yearbookMessage}
        </Text>
      </Flex>

      {!isMobile && <Divider bgColor="grey.2" height="1px" marginTop={4} />}
    </>
  );

  const renderLayoutMenu = () => {
    // Mobile only shows double/single layout
    if (isMobile) {
      return (
        <LayoutMenu
          handleClick={handleLayout}
          layoutMap={[GRID_ICON_MEDIUM, GRID_ICON_LARGE]}
          selected={layout}
          showNames={showNames}
          showBackButton={false}
          showNameToggle={false}
          text="All Photos"
          toggleShowNames={toggleShowNames}
        />
      );
    }

    // renders grid, double, single for desktop
    return (
      <LayoutMenu
        handleClick={handleLayout}
        layoutMap={[GRID_ICON_SMALL, GRID_ICON_MEDIUM, GRID_ICON_LARGE]}
        selected={layout}
        showNames={showNames}
        showBackButton={false}
        showNameToggle={true}
        text="All Photos"
        toggleShowNames={toggleShowNames}
      />
    );
  };

  return (
    <Flex alignItems="center" flexFlow="column nowrap" padding={4} position="relative" maxH="100vh">
      {renderHeader()}
      <Flex
        flexFlow="column"
        marginBottom={8}
        marginTop={4}
        width={isMobile ? '90%' : '70%'}
        paddingY={6}
        paddingBottom={12}
      >
        {yearbookMessage && renderInstructions()}
        {renderLayoutMenu()}
        {error && (
          <Alert
            bgColor="white"
            color="error"
            fontWeight="bold"
            marginRight={3}
            paddingBottom={8}
            paddingTop={0}
            paddingLeft={0}
            status="error"
            data-test="yearbook-selection-alert"
          >
            {intl.formatMessage({
              id: 'yearbook.error',
              defaultMessage: 'Please select a Photo to continue',
            })}
          </Alert>
        )}
        <YearbookImageGrid
          direction={direction}
          error={error}
          handleSelection={handleSelection}
          imageSize={imageSize}
          selections={selections}
          showNames={showNames}
        />
      </Flex>
      {renderFooter()}
    </Flex>
  );
};

export default YearbookSelection;
