import { Box, IconProps } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import NotificationPin from '../../../shared/components/NotificationPin';

export interface NavigationTabProps {
  'data-test'?: string;
  isActive?: boolean;
  notificationColor?: string;
  notificationContent?: string | number | IconProps | null;
  onClick?(): void;
  path: string;
  styleOverrides?: Record<string, string | number>;
  label: string;
}

const NavigationTab = ({
  'data-test': dataTest,
  isActive,
  notificationContent,
  notificationColor,
  onClick,
  path,
  styleOverrides,
  label,
}: NavigationTabProps) => (
  <Box
    borderBottomColor={isActive ? 'brand' : 'transparent'}
    borderBottomWidth={isActive ? '3px' : '3px'}
    color={isActive ? 'brand' : 'inherit'}
    display="inline-flex"
    fontFamily={isActive ? 'ITC Avant Garde Gothic Demi' : 'inherit'}
    fontSize="20px"
    marginTop="6px"
    marginX="10px"
    paddingBottom="10px"
    paddingX="24px"
    position="relative"
    transition={isActive ? 'ease-in border-bottom-color .5s' : 'none'}
    {...styleOverrides}
  >
    <Link onClick={onClick} to={path} data-test={dataTest}>
      {label}
    </Link>
    {notificationContent && (
      <NotificationPin
        backgroundColor={notificationColor}
        content={notificationContent}
        position="absolute"
        right="0"
      />
    )}
  </Box>
);

export default NavigationTab;
