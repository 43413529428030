import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, useParams } from 'react-router-dom';
import { selectVisitor } from '../../../redux/selectors/visitor.selectors';
import { Params } from '../../types/router';

const RenderMiddleware = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn } = useSelector(selectVisitor);
  const { key } = useParams<Params>();
  const { pathname, search } = useLocation();

  return isLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: `/${key}`,
        search,
        state: { prevPath: pathname },
      }}
    />
  );
};

const ProtectedRoute = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render={props => <RenderMiddleware component={Component} {...props} />} />
);

export default ProtectedRoute;
