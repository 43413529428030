import { Image, ImageProps } from '@chakra-ui/image';
import { Flex, FlexProps } from '@chakra-ui/layout';
import { BoxProps, chakra, keyframes, Spinner } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  THUMB_GRID_MAX_HEIGHT,
  THUMB_GRID_MAX_WIDTH,
} from '../../../features/Products/Configuration/constants';
import { getImageBuffer } from '../../utils';
import ActiveImageOverlay from '../ActiveImageOverlay';
import ImageBadge from '../ImageBadge';
import ImageDisplayName from '../ImageDisplayName';

const fadein = keyframes`
  from {opacity:0;}
  to {opacity:1;}
`;

export interface ThumbnailWithBackgroundProps extends ImageProps {
  background?: string;
  badgeSize?: string;
  badgeText?: string;
  containerOnClick?: FlexProps['onClick'];
  containerStyles?: FlexProps;
  displayName?: string;
  error?: boolean;
  flex?: string;
  fontSize?: string;
  height?: string | number;
  hideSpinner?: boolean;
  imageWidth?: string;
  isActive?: boolean;
  margin?: string;
  onImageLoad?(): void;
  overlayProps?: BoxProps;
  placeholderH?: string | number;
  placeholderW?: string | number;
  showBadge?: boolean;
  showNames?: boolean;
  textWidth?: number;
  width?: string | number;
  lazyLoad?: boolean;
  lazyLoadOptions?: any;
}

const ThumbnailWithBackground = ({
  background,
  badgeSize,
  badgeText,
  containerOnClick,
  containerStyles,
  displayName,
  error = false,
  flex,
  fontSize,
  height,
  hideSpinner = false,
  imageWidth,
  isActive,
  margin,
  onImageLoad,
  overlayProps,
  placeholderH = THUMB_GRID_MAX_HEIGHT,
  placeholderW = THUMB_GRID_MAX_WIDTH,
  showBadge = false,
  showNames,
  src,
  textWidth,
  width,
  lazyLoad = true,
  lazyLoadOptions,
  ...rest
}: ThumbnailWithBackgroundProps) => {
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>();
  const [bgSrc, setBgSrc] = useState<string | ArrayBuffer | null>();
  const thumbRef = useRef<HTMLDivElement>(null);

  const ignoreRightClick = (e: React.MouseEvent) => e.preventDefault();

  useEffect(() => {
    if (!src) {
      return;
    }

    const loadSrc = async () => {
      const buffer = await getImageBuffer(src);
      setImgSrc(buffer?.toString());
    };

    const loadBg = async () => {
      const buffer = await getImageBuffer(background || null);
      setBgSrc(buffer?.toString());
    };

    const isBrowserCompatible = 'IntersectionObserver' in window;
    let observer: IntersectionObserver | null = null;
    if (isBrowserCompatible && lazyLoad) {
      observer = new IntersectionObserver(img => {
        if (img[0].isIntersecting && thumbRef.current) {
          loadSrc();

          if (background) {
            loadBg();
          }
          setLoading(false);
          observer?.unobserve(thumbRef.current as Element);
        }
      }, lazyLoadOptions || { root: null, rootMargin: '20%', threshold: 0 });

      if (thumbRef?.current) {
        observer.observe(thumbRef.current as Element);
      }
    } else {
      loadSrc();
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [src, background, lazyLoad, lazyLoadOptions]);

  const renderLoadingState = () => {
    if (hideSpinner) {
      return;
    }
    return (
      <Flex
        align="center"
        alignSelf="center"
        backgroundColor="white"
        height={height}
        justify="center"
        minH={placeholderH}
        minW={placeholderW}
        width={imageWidth || width}
      >
        <Spinner color="grey.3" />
      </Flex>
    );
  };

  return (
    <Flex
      alignItems="start"
      flex={flex}
      height={height}
      margin={margin}
      onClick={containerOnClick}
      onContextMenu={ignoreRightClick}
      position="relative"
      width={imageWidth}
      ref={thumbRef}
      {...containerStyles}
    >
      {(isActive || error) && !loading && (
        <ActiveImageOverlay
          backgroundColor={error ? 'transparent' : 'IQHIGHLIGHT'}
          borderColor={error ? 'error' : 'IQBLUE'}
          badgeSize={badgeSize}
          cursor="pointer"
          iconFontSize={fontSize}
          hideIcon={error}
          {...overlayProps}
        />
      )}
      {!loading && imgSrc ? (
        <>
          {bgSrc && (
            <chakra.img
              animation={`${fadein} .5s`}
              height="100%"
              position="absolute"
              src={bgSrc?.toString()}
              width="100%"
            />
          )}
          <Image
            animation={`${fadein} .5s`}
            flex={flex}
            height={height}
            maxWidth={imageWidth || '100%'}
            onLoad={onImageLoad}
            position="relative"
            src={imgSrc?.toString()}
            width={width}
            {...rest}
          />
        </>
      ) : (
        renderLoadingState()
      )}

      {showNames && (
        <ImageDisplayName imageName={displayName} width={textWidth ? `${textWidth}px` : '100%'} />
      )}
      {showBadge && !loading && (
        <ImageBadge
          badgeSize={badgeSize}
          data-test="thumbnail-image-badge"
          fontSize="40%"
          mx="2%"
          my="2%"
          position="absolute"
          right="0"
          text={badgeText}
          top="0"
        />
      )}
    </Flex>
  );
};

export default ThumbnailWithBackground;
