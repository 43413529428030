import { EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import { Box, Button, Heading, Icon, Text } from '@chakra-ui/react';
import { CustomDataField } from 'iq-api-client';
import React, { forwardRef, useState } from 'react';
import DatePicker from '../../../shared/components/DatePicker';
import FloatingLabelInput from '../../../shared/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../shared/components/FloatingLabelSelect';
import { formatDate } from '../../../shared/utils';
import { AUTOCOMPLETE, INPUT_LABELS } from '../../Checkout/constants';
import { getInvalidMessage } from '../../Checkout/utils';
import { SAVE_AND_CONTINUE } from '../../Products/Configuration/constants';
import FacialRecognitionStepHeader from '../FacialRecognitionStepHeader';

interface Props {
  fields: CustomDataField[];
  isActive: boolean;
  isComplete: boolean;
  onEdit(): void;
  onSubmit(fields: Record<string, string>): void;
  stepNumber: number;
}

const AdditionalInformationStep = forwardRef<HTMLDivElement, Props>(
  ({ fields, isActive, isComplete, onEdit, onSubmit, stepNumber }, ref) => {
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [formData, setFormData] = useState<Record<string, string>>({});

    const createInputChangeHandler =
      (field: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [field]: event.target.value });

        if (errors[field]) {
          setErrors({ ...errors, [field]: false });
        }
      };

    /**
     * The types here a little silly, we're technically getting a string
     * but the Input element is maskerading as a ReactDatePicker element if you drill
     * into the DatePicker component. The onChange types don't reflect that.
     */
    const createDateChangeHandler =
      (field: string) => (date: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [field]: formatDate(`${date}`, 'yyyy-MM-DD') || '' });

        if (errors[field]) {
          setErrors({ ...errors, [field]: false });
        }
      };

    const handleSubmit = () => {
      const errorState: Record<string, boolean> = {};
      for (const field of fields) {
        if (!formData[field.field]) {
          errorState[field.field] = true;
        }
      }

      setErrors(errorState);

      if (!Object.keys(errorState).length) {
        onSubmit(formData);
      }
    };

    const renderCompletedForm = () => {
      return (
        <Flex
          borderColor="grey.2"
          borderRadius="md"
          borderStyle="solid"
          borderWidth="1px"
          flexDirection="column"
          marginBottom={2}
          padding={2}
          position="relative"
        >
          <FacialRecognitionStepHeader
            isActive={isActive}
            isComplete={isComplete}
            stepNumber={stepNumber}
            stepTitle="Additional Information"
          />
          <Icon
            as={EditIcon}
            color="brand"
            onClick={onEdit}
            position="absolute"
            right={3}
            top={3}
          />
          <Flex flexDirection="column" marginLeft="36px">
            {fields.map((field, index) => (
              <Flex
                flexFlow="row"
                key={index}
                display="inline"
                overflowWrap="break-word"
                marginBottom={1}
              >
                <Text variant="inline" fontSize="md">
                  {field.name}
                </Text>
                <Text marginX={1} variant="inline" fontSize="md">
                  :
                </Text>
                <Text variant="inline" fontWeight="bold" fontSize="md">
                  {formData[field.field]}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      );
    };

    // Determine what type of input to render based on field type
    const renderFieldType = (field: CustomDataField) => {
      // Renders an input type
      if (field.type === 'text') {
        return (
          <Box marginTop={6}>
            <Heading fontSize="md" marginBottom={2}>
              {field.name}
            </Heading>

            <FloatingLabelInput
              aria-label={field.name || INPUT_LABELS[field.subjectMap]}
              autoComplete={AUTOCOMPLETE[field.subjectMap]}
              invalidMessage={getInvalidMessage(field.name || INPUT_LABELS[field.subjectMap])}
              isInvalid={errors[field.field]}
              isRequired={field.requiredness === 'required'}
              name={field.field}
              onChange={createInputChangeHandler(field.field)}
              placeholder={field.value || INPUT_LABELS[field.subjectMap]}
              type={field.type}
              value={formData[field.field] || ''}
              width={{ base: '100%', lg: '500px' }}
            />
          </Box>
        );
      }

      // Renders either a selection type or a boolean type that looks like a selection w/ 'yes/no'
      if ((field.type === 'selection' && field.selections) || field.type === 'boolean') {
        const selections = field.type === 'selection' ? field.selections || [] : ['Yes', 'No'];

        return (
          <FloatingLabelSelect
            aria-label={INPUT_LABELS[field.subjectMap] || field.name}
            isInvalid={errors[field.field]}
            isRequired={field.requiredness === 'required'}
            marginTop={4}
            name={field.field}
            onChange={createInputChangeHandler(field.field)}
            placeholder={field.name}
            value={formData[field.field] || ''}
            width={{ base: '100%', lg: '500px' }}
          >
            {selections.map((selection, index) => (
              <option key={index} value={selection}>
                {selection}
              </option>
            ))}
          </FloatingLabelSelect>
        );
      }

      // Renders a datepicker
      if (field.type === 'date') {
        return (
          <DatePicker
            isInvalid={errors[field.field]}
            invalidMessage="Please pick a valid date"
            isRequired={field.requiredness === 'required'}
            marginTop={4}
            onChange={createDateChangeHandler(field.field)}
            placeholder={field.name}
            value={formData[field.field] || ''}
          />
        );
      }
    };

    const renderForm = () => (
      <Flex
        borderBottomWidth={isComplete ? undefined : '1px'}
        borderColor="grey.5"
        flexDirection="column"
        paddingY={4}
        ref={ref}
      >
        <FacialRecognitionStepHeader
          isActive={isActive}
          isComplete={isComplete}
          stepNumber={stepNumber}
          stepTitle="Additional Information"
        />
        <Flex flexDirection="column">
          {fields.map((field, index) => (
            <Box key={index}>{renderFieldType(field)}</Box>
          ))}
          <Button marginTop={6} onClick={handleSubmit}>
            {SAVE_AND_CONTINUE}
          </Button>
        </Flex>
      </Flex>
    );

    if (isActive) {
      return renderForm();
    }

    if (isComplete) {
      return renderCompletedForm();
    }

    return (
      <Flex borderBottomWidth="1px" borderColor="grey.5" flexDirection="column" paddingY={4}>
        <FacialRecognitionStepHeader
          isActive={false}
          isComplete={false}
          stepNumber={stepNumber}
          stepTitle="Additional Information"
        />
      </Flex>
    );
  },
);

AdditionalInformationStep.displayName = 'AdditionalInformationStep';
export default AdditionalInformationStep;
