import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ShopBackground } from '../../../../shop-api-client';
import { getBGOverlayStyles } from '../../../features/Products/Configuration/utils';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../redux/store';
import { SELECT_YOUR_BACKGROUND } from '../../constants';
import { formatCurrency } from '../../utils';
import BackgroundSwatches from '../BackgroundSwatches';
import Modal, { DEFAULT_MODAL_PADDING } from '../Modal';

const SWATCH_PIXEL_WIDTH = 120;
const SWATCH_MARGIN = 12;
const SWATCH_WITH_GUTTER = SWATCH_PIXEL_WIDTH + SWATCH_MARGIN * 2;

const overlayStyles = getBGOverlayStyles(SWATCH_PIXEL_WIDTH);

interface Props {
  defaultSelected?: ShopBackground;
  isOpen: boolean;
  onClose(): void;
  onSave(bg?: ShopBackground): void;
  priceSheetID: number;
}

const BackgroundsModal = ({ defaultSelected, isOpen, onSave, onClose, priceSheetID }: Props) => {
  const { backgroundSets } = useAppSelector(state => selectPriceSheet(state, priceSheetID));
  const { selectedBackground } = useAppSelector(selectInteractions);
  const { currency } = useAppSelector(selectAccount);

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<ShopBackground | undefined>();
  const scrollBehavior = useBreakpointValue<'outside' | 'inside'>({
    base: 'outside',
    md: 'inside',
  });

  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const VIEW_WIDTH = window.innerWidth - (isMobile ? 0 : DEFAULT_MODAL_PADDING * 2);
  const MAX_SWATCHES_PER_ROW = Math.floor(VIEW_WIDTH / SWATCH_WITH_GUTTER);
  const SWATCHES_CONTAINER_WIDTH = MAX_SWATCHES_PER_ROW * SWATCH_WITH_GUTTER;

  const CHOOSE_A_BACKGROUND = intl.formatMessage({
    id: 'backgrounds.chooseABackground',
    defaultMessage:
      'Choose a background below and click save to update your selection. Scroll to view more backgrounds.',
  });

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const handleClose = () => {
    setSelected(undefined);
    onClose();
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    await onSave(selected);
    setIsLoading(false);
    onClose();
  };

  const handleSelected = (background: ShopBackground) => setSelected(background);

  return (
    <Modal
      contentStyles={{
        maxHeight: { base: undefined, lg: '800px' },
        marginTop: { base: 0, md: '50px' },
      }}
      heading={SELECT_YOUR_BACKGROUND}
      isLoading={isLoading}
      isOpen={isOpen}
      modalBodyPaddingX={{ base: 5, md: `${DEFAULT_MODAL_PADDING}px` }}
      onClose={handleClose}
      onConfirm={handleConfirm}
      scrollBehavior={scrollBehavior}
      subHeading={CHOOSE_A_BACKGROUND}
    >
      <Flex direction="column" height="100%" maxWidth="100%">
        {backgroundSets.map(set => (
          <Flex key={set.id} direction="column" marginTop="20px">
            <Flex align="center">
              <Heading fontSize="md">{set.name}</Heading>
              {!!set.price && (
                <Text fontSize="md" lineHeight="1.6" marginLeft={3}>
                  +{formatCurrency(set.price, currency)}
                </Text>
              )}
            </Flex>
            <BackgroundSwatches
              backgrounds={set.backgrounds}
              containerStyles={{
                alignSelf: 'center',
                width: { base: `${SWATCHES_CONTAINER_WIDTH}px`, md: '100%' },
              }}
              onSelectBackground={handleSelected}
              overlayProps={overlayStyles}
              selectedBackground={selectedBackground}
              selectedID={selected?.id}
              size={`${SWATCH_PIXEL_WIDTH}px`}
              swatchMargin={`${SWATCH_MARGIN}px`}
            />
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};

export default BackgroundsModal;
