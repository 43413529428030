import { AspectRatio, Flex, Heading, Icon, Image } from '@chakra-ui/react';
import React from 'react';
import { FiCamera } from 'react-icons/fi';

interface Props {
  aspectRatio?: number;
  bgColor?: string;
  'data-test'?: string;
  fallbackFontSize?: string;
  fallbackIconSize?: string;
  fallbackText?: string;
  height: string;
  image: string;
  margin?: number;
  width: string;
}

const ImageWithFallback = ({
  aspectRatio = 1,
  bgColor = 'grey.1',
  'data-test': dataTest,
  fallbackFontSize = 'sm',
  fallbackIconSize = '60px',
  fallbackText = 'Sorry, no photo available',
  height,
  image,
  margin = 2,
  width,
}: Props) => {
  const ignoreRightClick = (e: React.MouseEvent) => e.preventDefault();

  if (!image) {
    return (
      <AspectRatio
        backgroundColor={bgColor}
        ratio={aspectRatio}
        width={width}
        style={{ border: '1px solid black' }}
      >
        <Flex backgroundColor={bgColor} direction="column">
          <Icon as={FiCamera} color="grey.3" fontSize={fallbackIconSize} />
          <Heading
            color="grey.5"
            margin="4"
            size={fallbackFontSize}
            textAlign="center"
            data-test={`${dataTest}-fallback`}
          >
            {fallbackText}
          </Heading>
        </Flex>
      </AspectRatio>
    );
  }

  return (
    <Flex flexFlow="column">
      <AspectRatio backgroundColor={bgColor} mx={margin} ratio={aspectRatio} width={width}>
        <Flex
          align="flex-start"
          alignSelf="center"
          direction="column"
          height={height}
          justify="center"
          onContextMenu={ignoreRightClick}
          width={width}
        >
          <Image
            alignSelf="center"
            maxHeight={height}
            maxWidth={width}
            src={image}
            data-test={dataTest}
          />
        </Flex>
      </AspectRatio>
    </Flex>
  );
};

export default ImageWithFallback;
