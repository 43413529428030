import { Button, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';
import { NAVIGATION_Z_INDEX } from '../../../../../shared/constants';
import { BACK } from '../../../../../shared/constants/labels.constants';
import { EDITOR_NEXT_BUTTON_HEIGHT } from '../EditorSidebar';

interface Props {
  loading?: boolean;
  onBack(): void;
  onNext(): void;
  nextLabel: string;
}

const PackageFooter = ({ loading, nextLabel, onBack, onNext }: Props) => {
  return (
    <Flex
      align="center"
      backgroundColor="grey.1"
      borderColor="grey.2"
      borderTopWidth="1px"
      bottom="0px"
      direction="row"
      height="65px"
      justify="space-between"
      paddingX="2"
      position="fixed"
      right="0px"
      width="100%"
      zIndex={NAVIGATION_Z_INDEX}
    >
      <Flex maxWidth={{ base: '100%', md: '30em' }} marginLeft="auto">
        <Button
          disabled={loading}
          height={`${EDITOR_NEXT_BUTTON_HEIGHT}px`}
          onClick={onBack}
          variant="wizardSecondary"
        >
          {BACK}
        </Button>
        <Button
          disabled={loading}
          height={`${EDITOR_NEXT_BUTTON_HEIGHT}px`}
          onClick={onNext}
          variant="wizard"
        >
          {loading ? <Spinner /> : nextLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

export default PackageFooter;
