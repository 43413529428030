import { createSelector } from '@reduxjs/toolkit';
import { DynamicData } from 'iq-product-render';
import { pick } from 'lodash';
import { ShopImage } from '../../../shop-api-client';
import { DYNAMIC_DATA_SUBJECT } from '../../features/Products/utils';
import { GALLERY_DEFAULTS } from '../slices/gallery.slice';
import { RootState } from '../store';

export const selectGallerySlice = (state: RootState) => state.gallery;

/**
 * Although a regular selector, define this as a function to get around our
 * circular import issue with the PriceSheet selectors.
 *
 * Issue described here: https://github.com/reduxjs/reselect/issues/169
 *
 * Link to specific solve
 * https://github.com/reduxjs/reselect/issues/169#issuecomment-274690285
 */
export function selectGalleryMap(state: RootState) {
  return state.gallery.galleryMap;
}

// Select a single gallery (current visitKey cart if none is provided)
export const selectGallery = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, key?: string) => key,
  ],
  (galleryMap, currentVisitKey, visitKey) =>
    galleryMap[visitKey || currentVisitKey!] || GALLERY_DEFAULTS,
);

/**
 * Selects the data to be injected into the iq-producer-render RenderComponent
 * used to fill text nodes.
 *
 * The Subject data is predefined as a constant as we do not inject subject's
 * data, only job data.
 */
export const selectDynamicData = createSelector(
  selectGallery,
  gallery =>
    ({
      job: pick(
        gallery,
        'eventDate',
        'expirationDate',
        'keyword',
        'reference',
        'title',
        'welcomeMessage',
        'retakeDate',
        'password',
        'customDate1',
        'customDate2',
        'customMarketing1',
        'customMarketing2',
        'customMarketing3',
        'customMarketing4',
        'customMarketing5',
        'jobImages',
      ),
      subject: DYNAMIC_DATA_SUBJECT,
    } as DynamicData),
);

// Returns all galleries as an array
export const selectAllGalleries = createSelector(
  (state: RootState) => state.gallery.galleryMap,
  galleryMap => Object.values(galleryMap),
);

/////////////////////////////////// Images ///////////////////////////////////
// Always uses current visit Key gallery/pricesheet data

// Returns the images for the active group, unless none is selected,
// in which case, all group images are returned:
export const selectGroupImages = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, groupID?: string) => groupID,
  ],
  (galleryMap, currentVisitKey, groupID) => {
    const { groupImageMap, images } = galleryMap[currentVisitKey!];
    if (groupID && groupImageMap[groupID]) {
      return groupImageMap[groupID].map(imageName => images[imageName]);
    }
    return Object.keys(groupImageMap).reduce<ShopImage[]>((result, groupID) => {
      groupImageMap[groupID].forEach(imageName => result.push(images[imageName]));
      return result;
    }, []);
  },
);

export const selectSubjectImages = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, __?, showPrimary?: boolean) => showPrimary,
  ],
  (galleryMap, currentVisitKey, showPrimary) => {
    const { images, subjectImageNames } = galleryMap[currentVisitKey!];

    return subjectImageNames.reduce<ShopImage[]>((result, imageName) => {
      const isSingleImage = subjectImageNames.length === 1;
      if (!isSingleImage && showPrimary && images[imageName].isPrimary) {
        result.unshift(images[imageName]);
      } else {
        result.push(images[imageName]);
      }
      return result;
    }, []);
  },
);

export const selectPrimaryImage = createSelector(selectGallery, ({ images }) =>
  Object.values(images).find(image => image.isPrimary),
);

export const selectYearbookImages = createSelector(
  [
    (state: RootState) => state.gallery.galleryMap,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, poseType?: string) => poseType,
  ],
  (galleryMap, currentVisitKey, poseType) => {
    const { subjectImageNames, images: galleryImages } = galleryMap[currentVisitKey!];
    const images = Object.values(galleryImages);

    // If there are images that have been marked as a subset available for yearbook selection, return that, otherwise all:
    const filteredList = images.filter(image =>
      poseType === 'pose1' ? image.isAvailableForYB1 : image.isAvailableForYB2,
    );

    const subjectImages = subjectImageNames.map(imageName => galleryImages[imageName]);

    return filteredList.length ? filteredList : subjectImages;
  },
);
