import { Box, ButtonProps, IconProps } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { useHistory } from 'react-router-dom';
import NotificationPin from '../../../shared/components/NotificationPin';
import NavigationAction from '../NavigationAction';

interface Props {
  'data-test'?: string;
  label?: string;
  leftIcon: IconType;
  leftIconStyles?: IconProps;
  pinContent?: string | number | IconProps;
  pinStyles?: ButtonProps;
  to: string;
}

const NavigationActionWithPin = ({
  'data-test': dataTest,
  label,
  leftIcon,
  leftIconStyles,
  pinContent,
  pinStyles,
  to,
}: Props) => {
  const history = useHistory();

  const handlePinClick = () => {
    history.push(to);
  };

  return (
    <Box display="inline" position="relative">
      {!!pinContent && (
        <NotificationPin
          borderRadius="8px"
          content={pinContent}
          height="15px"
          left="22px"
          marginTop="1"
          padding={1}
          position="absolute"
          size="xs"
          onClick={handlePinClick}
          width="initial"
          {...pinStyles}
        />
      )}
      <NavigationAction
        data-test={dataTest}
        leftIcon={leftIcon}
        leftIconProps={leftIconStyles}
        text={label}
        to={to}
      />
    </Box>
  );
};

export default NavigationActionWithPin;
