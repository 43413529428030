import { Flex, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopImage } from '../../../../shop-api-client';
import { selectGallery, selectYearbookImages } from '../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../redux/store';
import GridDynamic from '../../../shared/components/GridDynamic';
import Thumbnail from '../../../shared/components/Thumbnail';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { Params } from '../../../shared/types/router';
import { NEXT } from '../../MyPhotos/constants';

interface Props {
  direction: string;
  error: boolean;
  handleSelection(image: ShopImage): void;
  imageSize: number;
  selections: { [key: string]: string };
  showNames: boolean;
}

const MotionFlex = motion(Flex);

const YearbookImageGrid = ({
  direction,
  error,
  handleSelection,
  imageSize,
  selections,
  showNames,
}: Props) => {
  const { poseType } = useParams<Params>();
  const subjectImages = useAppSelector(state => selectYearbookImages(state, poseType));
  const { yearbookPoses } = useSelector(selectGallery);

  const { width } = useWindowSize();

  const variants = {
    enter: () => ({
      x: direction === NEXT ? width : -width,
      opacity: 0,
      scale: 1,
      y: 0,
      zIndex: 2,
      position: 'absolute',
    }),
    center: {
      zIndex: 1,
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      position: 'relative',
    },
    exit: () => ({
      zIndex: 1,
      x: direction === NEXT ? -width : width,
      opacity: 0,
      scale: 1,
      y: 0,
      position: 'absolute',
      transition: {
        duration: 0,
      },
    }),
  };

  const transition = {
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 0.2 },
    ease: 'easeInOut',
    scale: { duration: 0 },
  };

  return (
    <AnimatePresence>
      <MotionFlex
        key={poseType}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={transition}
        paddingBottom={8}
        flex="1"
        justifyContent="center"
        alignItems="center"
      >
        <GridDynamic width="100%" imageSize={imageSize} gridWidth={width * 0.7}>
          {subjectImages.map(image => {
            const isYB1 =
              image.internalName === selections.newYearbookSelection1 && poseType === 'pose1';
            const isYB2 =
              image.internalName === selections.newYearbookSelection2 &&
              poseType === 'pose2' &&
              yearbookPoses === 2;
            const tagYB1 =
              image.internalName === selections.newYearbookSelection1 && poseType !== 'pose1';
            const tagYB2 =
              yearbookPoses === 2 &&
              image.internalName === selections.newYearbookSelection2 &&
              poseType !== 'pose2';
            return (
              <Flex
                key={image.internalName}
                alignItems="center"
                flexFlow="column"
                _hover={{
                  cursor: true,
                }}
                justifyContent="center"
                onClick={() => {
                  handleSelection(image);
                }}
                position="relative"
                maxW={imageSize}
                paddingX={2}
              >
                <Thumbnail
                  badgeSize="17%"
                  badgeText={tagYB1 ? '1' : '2'}
                  border={image.isGreenScreen ? '1px solid' : 'none'}
                  borderColor={image.isGreenScreen ? 'grey.2' : 'transparent'}
                  cursor="pointer"
                  data-test={`yearbook-image-grid-thumbnail-${image.internalName}`}
                  displayName={image.displayName}
                  error={error}
                  fontSize="40%"
                  isActive={isYB1 || isYB2}
                  placeholderH={`${imageSize * 1.25}px`}
                  placeholderW={`${imageSize}px`}
                  showBadge={tagYB1 || tagYB2}
                  showNames={showNames}
                  src={image.sources.full}
                />
                <Flex height="1.5rem" width="100%">
                  {(tagYB1 || tagYB2) && (
                    <Text textAlign="center" width="100%">
                      Pose # {tagYB1 ? '1' : '2'}
                    </Text>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </GridDynamic>
      </MotionFlex>
    </AnimatePresence>
  );
};

export default YearbookImageGrid;
