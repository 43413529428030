import { Flex } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { FiHeart } from 'react-icons/fi';

const MotionFlex = motion(Flex);
const FavoriteIcon = ({ isFavorite }: { isFavorite: boolean }) => {
  const [favorite, setFavorite] = useState(isFavorite);

  return (
    <MotionFlex
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}
      whileTap={{ scale: 1.5 }}
      transition={{ duration: 0.5 }}
      onClick={() => setFavorite(!favorite)}
    >
      {isFavorite ? (
        <FaHeart color="red" fontSize="20px" />
      ) : (
        <FiHeart color="inherit" fontSize="20px" />
      )}
    </MotionFlex>
  );
};

export default FavoriteIcon;
