import { chakra, Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../redux/store';
import { formatCurrency } from '../../../../shared/utils';

interface Props extends FlexProps {
  addEmphasis?: boolean;
  amount?: number | string;
  amountStyles?: TextProps;
  checkoutFlag?: boolean;
  label: string;
  labelStyles?: TextProps;
}

const SummaryRow = ({
  addEmphasis,
  amount,
  amountStyles,
  checkoutFlag,
  label,
  labelStyles,
  ...rest
}: Props) => {
  const { currency } = useAppSelector(selectAccount);

  const intl = useIntl();
  const dataCy = label ? label.replace(/\s+/g, '-').toLowerCase() + '-value' : 'label-not-present';

  const renderAmount = () => {
    if (typeof amount === 'string') {
      return <Text {...amountStyles}>{amount}</Text>;
    }
    return (
      <Text data-test={dataCy} {...amountStyles}>
        {amount
          ? `${addEmphasis ? '(' : ''}${formatCurrency(amount, currency)}${addEmphasis ? ')' : ''}`
          : formatCurrency(0, currency)}
      </Text>
    );
  };

  return (
    <Flex align="end" justifyContent="space-between" marginY={'2px'} {...rest}>
      <Text {...labelStyles}>
        {label}
        {checkoutFlag && (
          <chakra.span fontSize="xs" marginLeft={2}>
            {intl.formatMessage({
              id: 'summaryRow.calcAtCheckout',
              defaultMessage: '(Calculated at checkout)',
            })}
          </chakra.span>
        )}
      </Text>
      {renderAmount()}
    </Flex>
  );
};

export default SummaryRow;
