import { chakra, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';

interface Props {
  onChange(query: string): void;
  onSubmit(query: string): void;
  value: string;
}

const SearchBar = ({ onChange, onSubmit, value }: Props) => {
  const intl = useIntl();

  const clearQuery = () => {
    onChange('');
    onSubmit('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  return (
    <chakra.form width="600px" onSubmit={handleSearch}>
      <InputGroup maxWidth="600px" variant="search">
        <InputLeftElement color="grey.5" marginLeft="5px" pointerEvents="none">
          <FiSearch size="22px" />
        </InputLeftElement>
        <Input
          marginLeft="5px"
          onChange={handleChange}
          placeholder={intl.formatMessage({
            id: 'search.placeholder',
            defaultMessage: 'What can we help you find?',
          })}
          value={value}
          data-test="input-searchbar"
        />
        {!!value && (
          <InputRightElement color="grey.6" onClick={clearQuery} data-test="clear-input-query">
            <FiX size="22px" />
          </InputRightElement>
        )}
      </InputGroup>
    </chakra.form>
  );
};
export default SearchBar;
