import {
  CreateToastFnReturn,
  useToast as useChakraToast,
  UseToastOptions as ChakraUseToastOptions,
} from '@chakra-ui/react';
import React from 'react';
import Toast from '../components/Toast/Toast';

const TOAST_DURATION = 3000;

export interface ToastOptions {
  onUndo?(): void;
}

export type UseToastOptions = ChakraUseToastOptions & ToastOptions;

export const getToast = (toast: CreateToastFnReturn) => {
  const _toast = ({ id, title, description, onUndo, ...rest }: UseToastOptions) => {
    if (!id || !toast.isActive(id)) {
      toast({
        id,
        duration: TOAST_DURATION,
        isClosable: true,
        render: ({ onClose }) => (
          <Toast description={description} onClose={onClose} onUndo={onUndo} title={title} />
        ),
        ...rest,
      });
    }
  };

  return _toast;
};

const useToast = () => getToast(useChakraToast());

export default useToast;
