import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './app/redux/store';
import GlobalFonts from './app/shared/components/GlobalFonts';
import { loadLocaleMessages } from './app/shared/utils';
import { IQThemeProvider } from './app/theme/IQThemeProvider';
import reportWebVitals from './reportWebVitals';

const locale = navigator.language.split(/[-_]/)[0];
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <GlobalFonts />
    <Provider store={store}>
      <IntlProvider locale={locale} messages={loadLocaleMessages(locale)}>
        <IQThemeProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </IQThemeProvider>
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
