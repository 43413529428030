import { EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import { Button, Icon, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import FloatingLabelInput from '../../../shared/components/FloatingLabelInput';
import { SUBJECT_DATA_FIELDS } from '../../../shared/constants';
import FacialRecognitionStepHeader from '../FacialRecognitionStepHeader';

export interface SubjectRegistrationFields {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface ErrorState {
  email?: boolean;
  firstName?: boolean;
  lastName?: boolean;
}

interface Props {
  initialEmail?: string;
  initialFirstName?: string;
  initialLastName?: string;
  initialPhoneNumber?: string;
  isActive: boolean;
  isComplete: boolean;
  onEdit(): void;
  onRegistration(fields: SubjectRegistrationFields): void;
  stepNumber: number;
}

const RegisterSubjectStep = ({
  initialEmail,
  initialFirstName,
  initialLastName,
  initialPhoneNumber,
  isActive,
  isComplete,
  onEdit,
  onRegistration,
  stepNumber,
}: Props) => {
  // Form state:
  const [errors, setErrors] = useState<ErrorState>({});
  const [email, setEmail] = useState(initialEmail || '');
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');

  const intl = useIntl();

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && errors.email) {
      setErrors({
        ...errors,
        email: false,
      });
    }

    setEmail(e.target.value);
  };

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && errors.firstName) {
      setErrors({
        ...errors,
        firstName: false,
      });
    }

    setFirstName(e.target.value);
  };

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length && errors.lastName) {
      setErrors({
        ...errors,
        lastName: false,
      });
    }

    setLastName(e.target.value);
  };

  const handleRegistration = () => {
    const errorState: ErrorState = {};
    if (!firstName) {
      errorState.firstName = true;
    }

    if (!lastName) {
      errorState.lastName = true;
    }

    if (!email) {
      errorState.email = true;
    }

    if (!Object.keys(errorState).length) {
      onRegistration({
        email,
        firstName,
        lastName,
        phoneNumber,
      });
    } else {
      setErrors(errorState);
    }
  };

  const renderCompletedText = (label: string, value: string) => (
    <Flex flexFlow="row" key={label} display="inline" overflowWrap="break-word" marginBottom={1}>
      <Text variant="inline" fontSize="md">
        {label}
      </Text>
      <Text marginRight={1} variant="inline" fontSize="md">
        :
      </Text>
      <Text variant="inline" fontWeight="bold" fontSize="md">
        {value}
      </Text>
    </Flex>
  );

  const renderCompletedForm = () => (
    <Flex
      borderColor="grey.2"
      borderRadius="md"
      borderStyle="solid"
      borderWidth="1px"
      flexDirection="column"
      marginBottom={2}
      padding={2}
      position="relative"
    >
      <FacialRecognitionStepHeader
        isActive={isActive}
        isComplete={isComplete}
        stepNumber={stepNumber}
        stepTitle="Register Subject"
      />
      <Icon as={EditIcon} color="brand" onClick={onEdit} position="absolute" right={3} top={3} />
      <Flex flexDirection="column" marginLeft="36px">
        {renderCompletedText('E-mail', email)}
        {!!phoneNumber && renderCompletedText('Phone Number', phoneNumber)}
        {renderCompletedText('First Name', firstName)}
        {renderCompletedText('Last Name', lastName)}
      </Flex>
    </Flex>
  );

  const renderForm = () => (
    <Flex
      borderBottomWidth={isComplete ? undefined : '1px'}
      borderColor="grey.5"
      flexDirection="column"
      paddingBottom={6}
    >
      <FacialRecognitionStepHeader
        isActive={isActive}
        isComplete={isComplete}
        stepNumber={stepNumber}
        stepTitle="Register Subject"
      />
      <Flex flexDirection="column">
        <FloatingLabelInput
          autoFocus
          isInvalid={errors.email}
          fontSize="md"
          marginTop={6}
          name="Email Address"
          onChange={handleEmail}
          placeholder="Email Address"
          value={email}
        />
        <FloatingLabelInput
          fontSize="md"
          marginTop={6}
          name="Phone Number"
          onChange={e => setPhoneNumber(e.target.value)}
          placeholder={SUBJECT_DATA_FIELDS.phoneNumber}
          value={phoneNumber}
        />
        <FloatingLabelInput
          fontSize="md"
          isInvalid={errors.firstName}
          marginTop={6}
          name="First Name"
          onChange={handleFirstName}
          placeholder={SUBJECT_DATA_FIELDS.firstName}
          value={firstName}
        />
        <FloatingLabelInput
          fontSize="md"
          marginTop={6}
          isInvalid={errors.lastName}
          name="Last Name"
          onChange={handleLastName}
          placeholder={SUBJECT_DATA_FIELDS.lastName}
          value={lastName}
        />
        <Button marginTop={6} onClick={handleRegistration}>
          {intl.formatMessage({
            id: 'facialRecognition.register',
            defaultMessage: 'Register',
          })}
        </Button>
      </Flex>
    </Flex>
  );

  if (isActive) {
    return renderForm();
  }

  if (isComplete) {
    return renderCompletedForm();
  }

  return (
    <Flex borderBottomWidth="1px" borderColor="grey.5" flexDirection="column" paddingBottom={6}>
      <FacialRecognitionStepHeader
        isActive={false}
        isComplete={false}
        stepNumber={stepNumber}
        stepTitle="Register Subject"
      />
    </Flex>
  );
};

export default RegisterSubjectStep;
