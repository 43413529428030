import { Flex } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import ProductImage from '../../ProductImage';

interface Props {
  image: string;
  selectedImage: string;
  setSelectedImage(selectedImage: string): void;
}

const ThumbnailCarouselItem = ({ image, selectedImage, setSelectedImage }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (image === selectedImage) {
      const { current } = ref;
      if (current !== null) {
        current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }, [image, selectedImage]);

  return (
    <Flex
      ref={ref}
      onClick={() => setSelectedImage(image)}
      borderColor={image === selectedImage ? 'blue.5' : 'grey.3'}
      borderWidth={image === selectedImage ? '2px' : '1px'}
      borderRadius="6px"
      cursor="pointer"
      minWidth="60px"
      width="60px"
      height="60px"
      padding="2px"
      marginBottom="8px"
    >
      <ProductImage image={image || ''} isSquare />
    </Flex>
  );
};

export default ThumbnailCarouselItem;
