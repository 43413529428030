import { Box, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ShopImage } from '../../../../shop-api-client';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../redux/selectors/background.selectors';
import { selectCart } from '../../../redux/selectors/cart.selectors';
import {
  selectGallery,
  selectGroupImages,
  selectPrimaryImage,
  selectSubjectImages,
} from '../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import {
  GridMode,
  setGridMode,
  setSelectedImageName,
} from '../../../redux/slices/interactions.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { loadBackgroundImage } from '../../../redux/thunks/interactions.thunks';
import LayoutMenu from '../../../shared/components/LayoutMenu';
import {
  GRID_ICON_LARGE,
  GRID_ICON_MASONRY,
  GRID_ICON_MEDIUM,
  GRID_MAX_WIDTH,
  GRID_THUMB_LG,
  GRID_THUMB_MD,
  MASONRY,
  MEDIUM,
} from '../../../shared/constants';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { ImageAndBackground } from '../../../shared/types/image';
import { Params } from '../../../shared/types/router';
import BackgroundViewer from '../BackgroundViewer';
import ImageGrid from '../ImageGrid';
import MasonryGrid from '../MasonryGrid';
import { BG_VIEWER_WIDTH } from '../constants';

const Album = () => {
  const { groupID, key } = useParams<Params>();

  const { backgroundSets } = useSelector(selectPriceSheet);
  const { groups, isGreenScreen, type, images } = useSelector(selectGallery);
  const { selectedBackground, gridMode } = useAppSelector(state => state.interactions);
  const { shopFavorites } = useSelector(selectCart);
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const orderedBackgrounds = useSelector(selectAllBackgrounds);
  const groupImages = useAppSelector(state => selectGroupImages(state, groupID));
  const primaryImage = useSelector(selectPrimaryImage);
  const subjectImages = useAppSelector(state => selectSubjectImages(state, gridMode === MASONRY));

  const [showNames, setShowNames] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const isSingleImage = [...groupImages, ...subjectImages].length === 1;
  const showBackgroundViewer =
    !isSingleImage && !isMobile && isGreenScreen && !!orderedBackgrounds.length;

  const isFavorites = pathname.includes('/favorites');
  const favoritedImages = shopFavorites.map(shopFavorite => images[shopFavorite.imageName]);
  const showGroupImages = !!groupImages.length && !isFavorites;

  const formattedImages = useCallback(
    (images: ShopImage[]) => {
      return images.reduce<ImageAndBackground[]>((result, image) => {
        if (isSingleImage && image.isGreenScreen) {
          const withBackgrounds = orderedBackgrounds.map(b => ({
            imageName: image.internalName,
            backgroundID: b.id,
          }));

          result.push(...withBackgrounds);
        } else {
          result.push({ imageName: image.internalName, backgroundID: selectedBackground?.id });
        }
        return result;
      }, []);
    },
    [orderedBackgrounds, isSingleImage, selectedBackground?.id],
  );

  useEffect(() => {
    if (gridMode) {
      return;
    }
    if (
      (type === 'subject' && subjectImages.length < 8) ||
      (type === 'standard' && groupImages.length < 8)
    ) {
      dispatch(setGridMode(MEDIUM));
    } else {
      dispatch(setGridMode(MASONRY));
    }
  }, [dispatch, gridMode, groupImages, subjectImages, type]);

  useEffect(() => {
    if (isFavorites) {
      return;
    }
    if (!groupID || !groups[groupID]) {
      history.push('all');
    }
  }, [dispatch, groupID, groups, history, isFavorites]);

  useEffect(() => {
    if (isGreenScreen && !selectedBackground && backgroundSets.length) {
      dispatch(loadBackgroundImage(backgroundSets[0].backgrounds[0]));
    }
  }, [backgroundSets, dispatch, isGreenScreen, selectedBackground]);

  const getMaxGridWidth = () => {
    const gridWidth = width;
    const includeBgViewerWidth = showBackgroundViewer ? BG_VIEWER_WIDTH : 0;
    const marginWidth = isMobile ? 10 : 40;
    return Math.min(GRID_MAX_WIDTH, gridWidth - includeBgViewerWidth - marginWidth);
  };

  const getLayoutMenuText = () => {
    if (isFavorites) {
      return intl.formatMessage({
        id: 'album.favorites',
        defaultMessage: 'Favorites',
      });
    }
    if (groupID) {
      return groups[groupID];
    }
    return intl.formatMessage({
      id: 'album.allPhotos',
      defaultMessage: 'All Photos',
    });
  };

  const handleBackClick = () => history.push(`/${key}/photos`);

  const handleGridModeClick = (gridMode: GridMode) => dispatch(setGridMode(gridMode));

  const handleSelectImage = (imageName: string, backgroundID?: number) => {
    const background = backgroundID ? backgroundsMap[backgroundID] : null;
    dispatch(setSelectedImageName(imageName));

    // If a different background has been selected with the image than the current
    // `selectedBackground`, store the new background:
    if (background && backgroundID !== selectedBackground?.id) {
      dispatch(loadBackgroundImage(backgroundSets[0].backgrounds[0]));
    }
    const appendToPath = backgroundID ? `/bg/${backgroundID}` : '';
    history.push(`/${key}/photos/${groupID || 'all'}/photo/${imageName}${appendToPath}`);
  };

  const toggleShowNames = () => setShowNames(!showNames);

  const renderImages = (images: ShopImage[]) => {
    if (!gridMode) {
      return null;
    }
    if (gridMode === MASONRY) {
      return (
        <MasonryGrid
          imageList={formattedImages(images)}
          maxGridWidth={getMaxGridWidth()}
          onSelectImage={handleSelectImage}
          primaryImage={primaryImage ? formattedImages([primaryImage])[0] : undefined}
          showBackgroundViewer={showBackgroundViewer}
          showNames={showNames}
          singlePose={isSingleImage}
        />
      );
    }
    return (
      <ImageGrid
        imageList={formattedImages(images)}
        imageSize={gridMode === MEDIUM ? GRID_THUMB_MD : GRID_THUMB_LG}
        onSelectImage={handleSelectImage}
        showNames={showNames}
        singlePose={isSingleImage}
      />
    );
  };

  const renderGroupImages = () => (
    <>
      {type === 'subject' && (
        <Heading size="sm" marginBottom="20px" maxWidth={`${GRID_MAX_WIDTH}px`}>
          {intl.formatMessage({
            id: 'album.groupPhotos',
            defaultMessage: 'Group Photos',
          })}
        </Heading>
      )}
      {gridMode === MASONRY && (
        <MasonryGrid
          imageList={formattedImages(groupImages)}
          maxGridWidth={getMaxGridWidth()}
          onSelectImage={handleSelectImage}
          showBackgroundViewer={showBackgroundViewer}
          showNames={showNames}
          singlePose={isSingleImage}
        />
      )}
      {gridMode && gridMode !== MASONRY && (
        <ImageGrid
          imageList={formattedImages(groupImages)}
          imageSize={gridMode === MEDIUM ? GRID_THUMB_MD : GRID_THUMB_LG}
          onSelectImage={handleSelectImage}
          showNames={showNames}
          singlePose={isSingleImage}
        />
      )}
    </>
  );

  if (isMobile === undefined) {
    return null;
  }

  return (
    <Flex>
      <Box paddingBottom="100px" width="100%" maxWidth={GRID_MAX_WIDTH} margin="0 auto">
        <LayoutMenu
          handleBack={handleBackClick}
          handleClick={handleGridModeClick}
          isSubject={type === 'subject'}
          layoutMap={[GRID_ICON_MASONRY, GRID_ICON_MEDIUM, GRID_ICON_LARGE]}
          maxWidth={getMaxGridWidth()}
          selected={gridMode}
          showNames={showNames}
          showNameToggle
          text={getLayoutMenuText()}
          toggleShowNames={toggleShowNames}
        />
        <Box marginX={isMobile ? '5px' : '20px'}>
          {!!subjectImages.length && renderImages(subjectImages)}
          {isFavorites && renderImages(favoritedImages)}
          {showGroupImages && renderGroupImages()}
        </Box>
      </Box>
      {/* TODO: The isMobile condition is temporary until mobile layout design is provided for BackgroundViewer */}
      {showBackgroundViewer && <BackgroundViewer />}
    </Flex>
  );
};

export default Album;
