import { HStack, Tag } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ShopPackage, ShopProduct } from '../../../../shop-api-client';

interface Props {
  product: ShopProduct | ShopPackage;
}

const ProductTags = ({ product }: Props) => {
  const isMultiPose =
    (product.type === 'package' || product.type === 'package-byop') &&
    (product.posesIncluded > 1 || product.allowAdditionalPoses);

  const intl = useIntl();

  const multiPose = intl.formatMessage({
    id: 'productTags.multiPose',
    defaultMessage: 'Multi Pose',
  });

  return (
    <HStack spacing={1} marginY={1} height="20px">
      {isMultiPose && (
        <Tag size="sm" borderRadius="lg" bgColor="grey.2" fontWeight="bold">
          {multiPose}
        </Tag>
      )}
    </HStack>
  );
};

export default ProductTags;
