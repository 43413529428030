import { Button, Divider } from '@chakra-ui/react';
import React from 'react';
import { REMOVE } from '../../../../shared/constants/labels.constants';
import { EDIT } from '../../../Products/Configuration/constants';

const SHARED_ACTION_STYLES = {
  color: 'deepCyan',
  lineHeight: '1.8',
  margin: 0,
  textUnderlineOffset: 1.5,
  variant: 'link',
};

interface Props {
  editTextOverride?: string;
  isDisabled?: boolean;
  onEdit?(): void; // WIP: Remove optional bang when editing is supported
  onRemove(): void;
}

const CartItemActions = ({ editTextOverride, onEdit, onRemove, isDisabled }: Props) => (
  <>
    <Button
      data-test="cart-item-actions-remove-button"
      disabled={isDisabled}
      fontSize={{ base: 'xs', md: 'sm' }}
      onClick={onRemove}
      {...SHARED_ACTION_STYLES}
    >
      {REMOVE}
    </Button>
    {!!onEdit && (
      <>
        <Divider
          borderColor={{ base: 'grey.3', md: 'grey.2' }}
          borderLeftWidth="1px"
          marginX={{ base: 3, md: 4 }}
          orientation="vertical"
        />
        <Button
          data-test="cart-item-actions-edit-button"
          disabled={isDisabled}
          fontSize={{ base: 'xs', md: 'sm' }}
          minWidth={0}
          onClick={onEdit}
          {...SHARED_ACTION_STYLES}
        >
          {editTextOverride || EDIT}
        </Button>
      </>
    )}
  </>
);

export default CartItemActions;
