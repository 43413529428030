import React from 'react';
import { useParams } from 'react-router-dom';
import { IS_ACCOUNT_KEY } from '../../shared/constants/regex.constants';
import { Params } from '../../shared/types/router';
import AccountLanding from '../AccountLanding';
import AuthLanding from '../AuthLanding';

// Renders either account landing or auth landing based on key type
const Landing = () => {
  const { key } = useParams<Params>();
  const isAccount = IS_ACCOUNT_KEY.test(key.toUpperCase());

  if (isAccount) {
    return <AccountLanding />;
  }

  return <AuthLanding />;
};

export default Landing;
