import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, AccountLandingData } from '../../../shop-api-client/models/Account';
import { AccountGallery } from '../../../shop-api-client/models/AccountGallery';
import { ShopAccountConfig } from '../../../shop-api-client/models/ShopConfig';
import { signout } from './visitor.slice';

interface InitialState extends AccountLandingData {
  galleries: AccountGallery[];
}
const initialState: InitialState = {
  accountID: '',
  analyticsID: '',
  company: '',
  config: {} as ShopAccountConfig,
  contact: null,
  currency: null,
  galleries: [],
  hasShopMerchant: false,
  logoImage: null,
  promptForKeyword: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    initializeAccount(state, action: PayloadAction<AccountLandingData | Account>) {
      return { ...state, ...action.payload };
    },
    setAnalyticsID(state, action: PayloadAction<string>) {
      state.analyticsID = action.payload;
    },
    setGalleries(state, action: PayloadAction<AccountGallery[]>) {
      state.galleries = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signout, () => {
      return initialState;
    });
  },
});

const { actions, reducer } = accountSlice;

export const { initializeAccount, setAnalyticsID, setGalleries } = actions;

export default reducer;
