import { Button, Heading } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useOnEvent from '../../../hooks/useOnEvent';
import StickyMobileSection from '../StickyMobileSection';

interface Props {
  buttonText: string;
  disabled?: boolean;
  getTargetElem(): HTMLElement | null;
  label?: string | number;
  onButtonClick(): void;
}

const FixedMobileFooter = ({
  buttonText,
  disabled,
  getTargetElem,
  label,
  onButtonClick,
}: Props) => {
  const [showFooter, setShowFooter] = useState(false);
  const scrollPosition = useRef<number>(window.pageYOffset);

  const handleOnScroll = useCallback(() => {
    const targetElem = getTargetElem();
    if (!targetElem) {
      return;
    }

    const { height, top } = targetElem.getBoundingClientRect();

    // Set showFooter state per whether targetElem is in view
    if (
      (!showFooter && top > 85 && top + height < window.innerHeight) ||
      (showFooter && (top < 85 || top + height > window.innerHeight))
    ) {
      return;
    }
    setShowFooter(!(top > 85 && top + height < window.innerHeight));

    scrollPosition.current = window.pageYOffset;
  }, [showFooter, getTargetElem]);

  // Listens for window scroll event and toggles boolean to sticky banner
  // when the banner is at the top of the view
  useOnEvent('scroll', handleOnScroll);

  useEffect(() => {
    handleOnScroll();
  }, [handleOnScroll]);

  return (
    <StickyMobileSection direction="bottom" show={showFooter}>
      {!!label && (
        <Heading marginX="6" size="md">
          {label}
        </Heading>
      )}

      <Button disabled={disabled} onClick={onButtonClick} width={!label ? '100%' : '55%'}>
        {buttonText}
      </Button>
    </StickyMobileSection>
  );
};

export default FixedMobileFooter;
