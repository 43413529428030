import { api } from '../../../shop-api-client';
import { getImageBuffer } from '../../shared/utils';
import { initializeAccount } from '../slices/account.slice';
import { PromiseThunk } from '../store';

/**
 * Gets the landing data for the given account id, and stores the account data and the account's
 * shop config in Redux
 */
export const getAccountLanding =
  (key: string): PromiseThunk<null> =>
  async dispatch => {
    try {
      const account = await api.getAccountLanding(key);
      // Set logoImage as base64
      const logoImage = (await getImageBuffer(account.logoImage, 0)).toString();

      dispatch(initializeAccount({ ...account, logoImage }));
      document.title = account.company;

      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };
