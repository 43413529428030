import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ShopBackground,
  ShopImage,
  ShopProductPrint,
} from '../../../../../../../../shop-api-client';
import {
  CartImageNodeCrop,
  CartPrintProduct,
  CreateCartProductReq,
} from '../../../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../../../shop-api-client/models/ShopProductNodes';
import { selectConfiguration } from '../../../../../../../redux/selectors/configurations.selectors';
import { useAppSelector } from '../../../../../../../redux/store';
import ProductPreview from '../../../../../ProductPreview';
import {
  MULTI_NODE_CROP_CANCEL,
  MULTI_NODE_CROP_CONFIRM,
  MULTI_NODE_CROP_EDIT,
  MULTI_NODE_CROP_PREVIEW,
  MULTI_NODE_CROP_SAVE,
} from '../../../../constants';
import CropEditor from '../../../CropEditor';

interface Props {
  catalogNode: ShopImageNodeWithReqType;
  crop: CartImageNodeCrop;
  editProduct: CreateCartProductReq;
  handleCrop(crop: CartImageNodeCrop): void;
  onClose(): void;
  shopBackground?: ShopBackground | null;
  shopImage: ShopImage;
  shopProduct: ShopProductPrint;
}

const MultiNodeCropEditor = ({
  catalogNode,
  crop,
  editProduct,
  handleCrop,
  onClose,
  shopBackground,
  shopImage,
  shopProduct,
}: Props) => {
  const { editNodeID } = useAppSelector(selectConfiguration);
  const [currentNodeID, setCurrentNodeID] = useState(editNodeID);
  const [showPreview, setShowPreview] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 48em)', { ssr: false });

  useEffect(() => {
    setCurrentNodeID(editNodeID);
  }, [editNodeID]);

  /**
   * This wrapper function is used to ensure that when switching nodes,
   * the CropEditor does not trigger on last time with the values from the
   * previous node.
   */
  const handleCropChange = useCallback(
    (crop: CartImageNodeCrop) => {
      if (currentNodeID === editNodeID) {
        handleCrop(crop);
      }
    },
    [currentNodeID, editNodeID, handleCrop],
  );

  const renderMobilePreviewToggle = () => {
    return (
      <Flex
        alignItems="center"
        borderColor="grey.2"
        borderLeftRadius={25}
        borderWidth={1}
        borderRightRadius={25}
        direction="row"
        height="40px"
        paddingX="5px"
        justifyContent="center"
        marginX="auto"
        marginY="10px"
      >
        <Box>
          <Button
            animation={undefined}
            backgroundColor={showPreview ? undefined : 'black'}
            color={showPreview ? 'black' : 'white'}
            onClick={() => setShowPreview(false)}
            variant="multiNodeCropToggle"
          >
            {MULTI_NODE_CROP_EDIT}
          </Button>
        </Box>
        <Box>
          <Button
            animation={undefined}
            backgroundColor={showPreview ? 'black' : undefined}
            color={showPreview ? 'white' : 'black'}
            onClick={() => setShowPreview(true)}
            variant="multiNodeCropToggle"
          >
            {MULTI_NODE_CROP_PREVIEW}
          </Button>
        </Box>
      </Flex>
    );
  };

  if (isMobile) {
    return (
      <Modal blockScrollOnMount isOpen onClose={onClose} size="full" scrollBehavior="inside">
        <ModalContent>
          <ModalHeader paddingTop={4} paddingBottom={2}>
            <ModalCloseButton borderRadius="50%" />
            <Flex direction="column">
              <Heading size="md" marginX="auto">
                {MULTI_NODE_CROP_CONFIRM}
              </Heading>
              {renderMobilePreviewToggle()}
            </Flex>
          </ModalHeader>
          <ModalBody paddingTop={0}>
            {showPreview ? (
              <Flex marginY="15px" justifyContent="center">
                <ProductPreview
                  disableMiniPreview
                  editProduct={editProduct as CartPrintProduct}
                  hidePreviewText
                  isFinalRender
                  isInteractive={false}
                  shopProduct={shopProduct}
                />
              </Flex>
            ) : (
              <CropEditor
                handleCrop={handleCropChange}
                imageNode={catalogNode}
                initialCrop={crop}
                shopBackground={shopBackground}
                shopImage={shopImage}
              />
            )}
          </ModalBody>
          <ModalFooter borderTopWidth={1} borderColor="grey.2" paddingX={3} paddingY={2}>
            <Flex direction="row" width="100%">
              <Button
                background="grey.1"
                flex={1}
                height="40px"
                marginRight={4}
                onClick={onClose}
                variant="secondary"
              >
                {MULTI_NODE_CROP_CANCEL}
              </Button>
              <Button flex={2} height="40px" marginBottom={1} onClick={onClose}>
                {MULTI_NODE_CROP_SAVE}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <CropEditor
      handleCrop={handleCropChange}
      imageNode={catalogNode}
      initialCrop={crop}
      shopBackground={shopBackground}
      shopImage={shopImage}
    />
  );
};

export default MultiNodeCropEditor;
