// TODO: Add custom styling once designs are available

import { Box, Input, InputProps, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

interface Props extends InputProps {
  invalidMessage: string;
}

const DatePicker = ({
  invalidMessage,
  isInvalid,
  isRequired,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  placeholder,
  value,
  width,
  ...rest
}: Props) => {
  const [isActive, setIsActive] = useState(!!value);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => setIsActive(e.target.value !== '');
  const handleFocus = () => setIsActive(true);

  return (
    <Box
      alignContent="center"
      display="flex"
      flexDirection="column"
      margin={margin}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      position="relative"
      width={width}
    >
      <Input
        as={ReactDatePicker}
        background="transparent"
        onBlur={handleBlur}
        onFocus={handleFocus}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: 110,
            },
          },
        ]}
        value={value}
        zIndex={1}
        {...rest}
      />
      <Text
        as="label"
        color={isInvalid ? 'error' : isActive ? 'grey.6' : 'black'}
        fontSize="16px"
        padding="0 15px"
        position="absolute"
        transform={isActive ? 'translate(0, 5px) scale(0.70) ' : 'translate(0, 15px) scale(1) '}
        transformOrigin="top left"
        transition="all 0.2s ease-out"
      >
        {placeholder}
        {isRequired && (
          <Text as="span" color="error" paddingLeft="2">
            *
          </Text>
        )}
      </Text>
      {isInvalid && (
        <Text color="error" fontSize="12px" marginLeft="5px" marginTop="2px">
          {invalidMessage || `Please select a valid date.`}
        </Text>
      )}
    </Box>
  );
};

export default DatePicker;
