import { useEffect } from 'react';

type HandleEventCb = (e: any) => void;

const useOnEvent = (e: string, handleEvent: HandleEventCb) => {
  useEffect(() => {
    window.addEventListener(e, handleEvent);
    return () => {
      window.removeEventListener(e, handleEvent);
    };
  }, [e, handleEvent]);
};

export default useOnEvent;
