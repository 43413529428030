import { BoxProps, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { formatCurrency } from '../../../../../../shared/utils';

interface Props extends BoxProps {
  amount: number;
  fontSize?: string;
  justifyContent?: string;
  label: string;
}

const SummaryRow = ({
  amount,
  fontSize = 'sm',
  justifyContent = 'space-between',
  label,
  ...rest
}: Props) => {
  const { currency } = useAppSelector(selectAccount);

  return (
    <Flex align="center" justifyContent={justifyContent} marginY="2px" {...rest}>
      <Text fontSize={fontSize} marginRight={1}>
        {label}
      </Text>
      <Heading fontSize={fontSize}>{formatCurrency(amount, currency)}</Heading>
    </Flex>
  );
};

export default SummaryRow;
