import { Flex, Heading, Icon, MenuItem, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FiCamera } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCartItemCount } from '../../../redux/selectors/cart.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { Gallery } from '../../../redux/slices/gallery.slice';
import { setGridMode, toggleShowFindGalleryModal } from '../../../redux/slices/interactions.slice';
import { setIsLoggingIn } from '../../../redux/slices/visitor.slice';
import { RootState, useAppDispatch } from '../../../redux/store';
import CheckMarkIcon from '../../../shared/components/CheckMarkIcon';

interface Props {
  gallery: Gallery;
  visitKey: string;
}

const MultiGalleryMenuItem = ({ gallery: { subjectID, title }, visitKey }: Props) => {
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const cartItemCount = useSelector((state: RootState) => selectCartItemCount(state, [visitKey]));

  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const availableGalleriesAriaLabel = intl.formatMessage({
    id: 'multiGalleryButton.ariaSwitchGallery',
    defaultMessage: 'Switch Gallery',
  });
  const currentlyViewingLabel = intl.formatMessage({
    id: 'multiGalleryMenutItem.currentlyViewing',
    defaultMessage: 'Currently Viewing',
  });
  const itemsAddedText = intl.formatMessage(
    {
      id: 'multiGalleryMenutItem.xItemsAdded',
      defaultMessage: '{cartItemCount} items added',
    },
    {
      cartItemCount,
    },
  );
  const subjectIDText = intl.formatMessage(
    {
      id: 'multiGalleryMenutItem.subjectID',
      defaultMessage: 'Subject: {subjectID}',
    },
    {
      subjectID,
    },
  );

  const isActive = visitKey === currentVisitKey;

  const renderIcon = () => {
    if (isActive) {
      return (
        <CheckMarkIcon
          data-test="active-gallery-check-icon"
          marginRight="14px"
          marginTop="1px"
          size="30px"
        />
      );
    }

    return (
      <Icon
        aria-label={availableGalleriesAriaLabel}
        as={FiCamera}
        color={'grey.2'}
        fontSize="2xl"
        marginRight="15px"
      />
    );
  };

  const handleGalleryClick = () => {
    if (isActive) {
      return;
    }

    dispatch(setGridMode(null));
    dispatch(toggleShowFindGalleryModal(false));
    // set to false so we can auth again
    dispatch(setIsLoggingIn(false));

    history.push(`/${visitKey}`);
  };

  return (
    <MenuItem
      alignItems="start"
      aria-label={availableGalleriesAriaLabel}
      backgroundColor={isActive ? 'blue.1' : undefined}
      borderRadius="8px"
      marginBottom="10px"
      onClick={handleGalleryClick}
      paddingLeft="18px"
      paddingTop="15px"
      data-test={isActive ? 'active-gallery-button' : 'available-galleries-button'}
    >
      {renderIcon()}

      <Stack marginBottom="2px" spacing={1}>
        <Heading
          data-test={
            isActive ? 'active-gallery-heading-title' : 'available-galleries-heading-title'
          }
          fontSize="md"
          color={isActive ? 'brand' : undefined}
        >
          {title}
        </Heading>

        {!!subjectID && (
          <Text
            fontSize="sm"
            color="grey.5"
            data-test={isActive ? 'active-gallery-subjectid' : 'available-galleries-subjectid'}
          >
            {subjectIDText}
          </Text>
        )}

        <Text
          fontSize="xs"
          color="grey.5"
          data-test={
            isActive ? 'active-gallery-items-added-text' : 'available-galleries-items-added-text'
          }
        >
          {itemsAddedText}
        </Text>

        {isActive && (
          <Flex
            backgroundColor="blue.100"
            borderRadius="8px"
            justifyContent="center"
            paddingY="2px"
            width="100px"
          >
            <Text fontSize="10px" color="blue.5" fontFamily="heading">
              {currentlyViewingLabel}
            </Text>
          </Flex>
        )}
      </Stack>
    </MenuItem>
  );
};

export default MultiGalleryMenuItem;
