import { Center, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { selectVisitor } from '../../redux/selectors/visitor.selectors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { authorizeSession } from '../../redux/thunks/auth.thunks';
import useSearchParams from '../../shared/hooks/useSearchParams';
import { LocationWithState, Params } from '../../shared/types/router';
import { getSecurePath } from '../../shared/utils';
import Login from '../Login';

// Makes api call to check to see if there is already an active session for this gallery
// If success, redirect back to prev path or base path based on preorder type
// Otherwise render the login page
const AuthLanding = () => {
  const { isLoggedOut } = useAppSelector(selectVisitor);

  const [renderLogin, setRenderLogin] = useState(false);
  const [unavailableMessage, setUnavailableMessage] = useState('');

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search, state } = useLocation<LocationWithState>();
  const { getParamsValue } = useSearchParams();

  useEffect(() => {
    const auth = async () => {
      const mid = getParamsValue('mid');
      // Authorize session on BE based on key
      const { valid, payload, error } = await dispatch(authorizeSession(key, mid));

      // Explicit check to prevent render of loginForm while data is still loading
      // TODO: find a way to make this prettier with React18 strictMode
      if (error === 'isLoggingIn') {
        return;
      }

      if (!valid || !payload || payload.unavailableMessage) {
        setUnavailableMessage(payload?.unavailableMessage || '');
        setRenderLogin(true);
        return;
      }
      const { isPreOrder, type } = payload;
      const pathname = getSecurePath(key, state?.prevPath, !!isPreOrder, type === 'standard');

      // Send visitor back to initial path or designated landing
      history.replace({
        pathname,
        search: search,
      });
    };

    // Attempt an automatic authorization if we did not explicitly log out
    if (!isLoggedOut) {
      auth();
    } else {
      setRenderLogin(true);
    }
  }, [dispatch, getParamsValue, history, isLoggedOut, key, search, state]);

  return renderLogin ? (
    <Login unavailableMessage={unavailableMessage} setUnavailableMessage={setUnavailableMessage} />
  ) : (
    <Center width="100vw" height="100vh">
      <Spinner />
    </Center>
  );
};

export default AuthLanding;
