import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectInteractions } from '../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../redux/selectors/priceSheet.selectors';
import ProtectedRoute from '../../shared/components/ProtectedRoute';
import SearchBar from '../../shared/components/SearchBar';
import { FILTER, SEARCH } from '../../shared/constants';
import { Params } from '../../shared/types/router';
import { getShopAllPath } from '../../shared/utils';
import CategoriesSidebar from './CategoriesSidebar';
import Category from './Category';
import { PRODUCTS_GUTTER } from './constants';
import SelectAndBuyBanner from './SelectAndBuyBanner';
import { getFilteredProducts } from './utils';

const Products = () => {
  const { activeCategoryID } = useSelector(selectInteractions);
  const priceSheet = useSelector(selectPriceSheet);

  const { key } = useParams<Params>();
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const showBuyBanner = useBreakpointValue({ base: false, md: true });

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const filteredProducts = useMemo(
    () => getFilteredProducts(activeCategoryID, priceSheet, searchParams),
    [activeCategoryID, priceSheet, searchParams],
  );
  const queryFromParams = searchParams.get(SEARCH)?.split('+').join(' ') || '';
  const shopAllPath = getShopAllPath(key);

  useEffect(() => {
    const invalidPath = new RegExp(`^/${key}/shop/?$`);
    if (invalidPath.test(history.location.pathname)) {
      history.replace({
        pathname: shopAllPath,
        search: `${searchParams}`,
      });
    }
  }, [key, history, shopAllPath, searchParams]);

  const handleUpdateQuery = (query: string) => {
    // Remove search and filter params
    searchParams.delete(SEARCH);
    searchParams.delete(FILTER);

    if (query) {
      // Append latest search query string
      searchParams.append(SEARCH, query);
    }

    // Search is performed across all products, so if currently on a particular category,
    // route to /all, otherwise replace to append search:
    if (!location.pathname.includes('/shop/all')) {
      history.push({
        pathname: shopAllPath,
        search: `${searchParams}`,
      });
    } else {
      history.replace({ search: `${searchParams}` });
    }
  };

  return (
    <Box>
      {showBuyBanner && <SelectAndBuyBanner />}
      <Flex
        align="center"
        background="white"
        direction="column"
        marginX={{ base: 0, lg: '60px' }}
        paddingX={isMobile ? `${PRODUCTS_GUTTER}px` : 0}
        width={{ base: '100%', md: 'inherit' }}
      >
        <Flex justify="center" marginBottom="35px" marginTop="20px" width="100%">
          <SearchBar onChange={handleUpdateQuery} onSubmit={() => {}} value={queryFromParams} />
        </Flex>
        <Flex direction="row">
          {!isMobile && (!queryFromParams || (queryFromParams && !!filteredProducts.length)) && (
            <CategoriesSidebar />
          )}
          <ProtectedRoute
            path={[`/:key/shop/all`, `/:key/shop/:categoryID`]}
            component={Category}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Products;
