import { Flex, FlexProps, Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { ShopBackground, ShopImage } from '../../../../../../../shop-api-client';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground';
import { NONE_SELECTED, PHOTO } from '../../../constants';

interface Props {
  background?: ShopBackground;
  containerProps?: FlexProps;
  image?: ShopImage;
  subText?: String;
}

const SelectedImage = ({ background, containerProps, image, subText }: Props) => (
  <Flex alignItems="flex-start" direction="row" width="100%" {...containerProps}>
    {image && (
      <Box marginRight={4}>
        <ThumbnailWithBackground
          src={image?.sources.thumb || ''}
          imageWidth="60px"
          background={background?.sources.thumb}
        />
      </Box>
    )}
    <Flex maxWidth="250px" direction="column" marginRight={2} marginTop={1}>
      <Text fontSize="md" noOfLines={1}>
        {!image?.displayName && (
          <Box as="span" fontFamily="heading" paddingRight={2}>
            {PHOTO}
          </Box>
        )}
        {image?.displayName || NONE_SELECTED}
      </Text>
      {subText && (
        <Text as="i" fontSize="xs" marginTop={1}>
          {subText}
        </Text>
      )}
    </Flex>
  </Flex>
);

export default SelectedImage;
