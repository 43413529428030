import { Flex, Heading, Icon, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { FiCamera } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import RoundedBorderBox from '../../../../shared/components/RoundedBorderBox';

const SignInBanner = () => {
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const multipleSubjects = intl.formatMessage({
    id: 'signInBanner.multipleSubjects',
    defaultMessage: 'Purchasing for Multiple Subjects?',
  });

  const renderMultipleSubjectsText = () => {
    if (isMobile) {
      return (
        <Heading fontSize="md" color="brand" marginBottom={2.5}>
          {multipleSubjects}
        </Heading>
      );
    }
    return (
      <Text fontSize="sm" marginRight="0.7ch">
        {multipleSubjects}
      </Text>
    );
  };

  return (
    <RoundedBorderBox
      alignItems="center"
      backgroundColor={['initial', 'grey.1']}
      borderColor="grey.1"
      borderRadius={8}
      display="flex"
      justifyContent={{ base: 'flex-start', md: 'center' }}
      marginBottom={[4, 6]}
      padding={3}
    >
      <Flex
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="flex-start"
      >
        {!isMobile && <Icon as={FiCamera} color="grey.4" fontSize="30px" marginX={6} />}
        {renderMultipleSubjectsText()}

        <Text fontSize="sm">
          {intl.formatMessage({
            id: 'signInBanner.combineOrders',
            defaultMessage: 'Some orders can be combined to save on shipping.',
          })}
          <Link
            as={RouterLink}
            color={['grey.9', 'initial']}
            fontSize="sm"
            marginLeft="0.7ch"
            marginRight="0.7ch"
            to="galleries/find"
            variant="inlineBrand"
          >
            {intl.formatMessage({
              id: 'signInBanner.signIn',
              defaultMessage: 'Sign In',
            })}
          </Link>
          {intl.formatMessage({
            id: 'signInBanner.or',
            defaultMessage: 'or',
          })}
          <Link
            as={RouterLink}
            color={['grey.9', 'initial']}
            fontSize="sm"
            marginLeft="0.7ch"
            to="galleries/find"
            variant="inlineBrand"
          >
            {intl.formatMessage({
              id: 'signInBanner.findGallery',
              defaultMessage: 'Find Gallery',
            })}
          </Link>
          .
        </Text>
      </Flex>
    </RoundedBorderBox>
  );
};

export default SignInBanner;
