import { ListItem, UnorderedList } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import DividerWithLabel from '../../../../shared/components/DividerWithLabel';
import DetailsSection from '../DetailsSection';

const DEFAULT_LIST_LENGTH = 10;

interface Props {
  notIncluded?: boolean;
  packageID: number;
}

/**
 * Renders an Unordered List of package items. Defaults to displaying the first
 * ten items, but upon clicking the list, expands to show all of the items
 */
const PackageItemList = ({ packageID, notIncluded }: Props) => {
  const { products } = useSelector(selectPriceSheet);
  const [showAll, setShowAll] = useState(false);
  const intl = useIntl();

  const shopPackage = products[packageID];
  if (shopPackage.type !== 'package' && shopPackage.type !== 'package-byop') {
    return null;
  }

  let { availableProducts } = shopPackage;
  if (shopPackage.type === 'package-byop') {
    availableProducts = availableProducts.filter(p => (notIncluded ? p.price > 0 : p.price === 0));
  }
  if (!availableProducts.length) {
    return null;
  }

  const ALL = intl.formatMessage({
    id: 'packageItemList.all', 
    defaultMessage: 'All'
  });
  
  const LESS = intl.formatMessage({
    id: 'packageItemList.less',
    defaultMessage: 'Less'
  })

  const ITEMS = intl.formatMessage({
    id: 'packageItemList.items',
    defaultMessage: 'Items'
  })

  const showAllLabel = intl.formatMessage(
    {
      id: 'packageItemList.showAllLabel',
      defaultMessage: 'Show {amount}',
    },
    { amount: showAll ? LESS : `${ALL} ${availableProducts.length} ${ITEMS}` },
  );
  const whatsIncludedHeading = intl.formatMessage({
    id: 'packageItemList.whatsIncludedHeading',
    defaultMessage: "WHAT'S INCLUDED",
  });
  const buildYourOwnLabel = intl.formatMessage({
    id: 'packageItemList.buildYourOwnLabel',
    defaultMessage: 'BUILD YOUR OWN',
  });

  const toggleListLength = () => setShowAll(!showAll);

  return (
    <DetailsSection
      heading={notIncluded ? buildYourOwnLabel : whatsIncludedHeading}
      headingFontSize="sm"
      hideBorder
      marginBottom={0}
      onClick={toggleListLength}
      paddingBottom={0}
    >
      <UnorderedList marginX={7} marginY={2}>
        {availableProducts
          .slice(0, showAll ? availableProducts.length : DEFAULT_LIST_LENGTH)
          .map(item => (
            <ListItem data-test="products-list" key={item.id} fontSize="md" marginY={1}>
              {item.name}
            </ListItem>
          ))}
      </UnorderedList>
      {availableProducts.length > DEFAULT_LIST_LENGTH && (
        <DividerWithLabel label={showAllLabel} labelSize="sm" />
      )}
    </DetailsSection>
  );
};

export default PackageItemList;
