import { useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ShopProductPrint } from '../../../../../../../shop-api-client';
import {
  CartImageNodeReq,
  CartPrintProduct,
  CartPrintProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { Step } from '../../../../../../shared/components/ImageStepHeadings/ImageStepHeadings';
import ImageNodeMulti from '../../../shared/ImageNodesEditor/ImageNodeMulti';
import MultiNodeConfiguration from '../../../shared/ImageNodesEditor/ImageNodeMulti/MultiNodeConfiguration';
import MultiNodeMissingImagesModal from '../../../shared/MultiNodeMissingImagesModal';
import { getConfigurableImageNodes } from '../../../utils';

interface Props {
  editProduct: CartPrintProductReq | CartPrintProduct;
  onLeave?(): void;
  onSave?(): void;
  poseLimit: number | undefined;
  poseMap: Record<string, number[]>;
  shopProduct: ShopProductPrint;
}

const PackageMultiNode = ({
  editProduct,
  onLeave,
  onSave,
  poseLimit,
  poseMap,
  shopProduct,
}: Props) => {
  const { productNodeMap } = useAppSelector(selectPriceSheet);

  const [activeStep, setActiveStep] = useState<Step>('photo');

  const [isMobile] = useMediaQuery('(max-width: 48em)', { ssr: false });

  const catalogNodes = productNodeMap[shopProduct.catalogProductID];
  const imageNodes = getConfigurableImageNodes(shopProduct, catalogNodes);

  if (isMobile) {
    return (
      <ImageNodeMulti
        catalogNodes={imageNodes}
        complete={false}
        editProduct={editProduct}
        onLeave={onLeave}
        onSave={onSave}
        packageMode
        poseLimit={poseLimit}
        poseMap={poseMap}
        shopProduct={shopProduct}
      />
    );
  }

  // Grab all image nodes
  const nodes = editProduct.nodes.filter(node => node.type === 'image') as CartImageNodeReq[];
  return (
    <>
      <MultiNodeConfiguration
        activeStep={activeStep}
        catalogNodes={imageNodes}
        editProduct={editProduct}
        nodes={nodes}
        poseLimit={poseLimit}
        poseMap={poseMap}
        setActiveStep={setActiveStep}
        shopProduct={shopProduct}
      />
      <MultiNodeMissingImagesModal
        editProduct={editProduct}
        onLeave={onLeave}
        shopProduct={shopProduct}
      />
    </>
  );
};

export default PackageMultiNode;
