import { Avatar, Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectVisitor } from '../../../../../redux/selectors/visitor.selectors';
import { useAppDispatch } from '../../../../../redux/store';
import { logout } from '../../../../../redux/thunks/auth.thunks';

const UserWelcome = () => {
  const { email } = useSelector(selectVisitor);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const signOutText = intl.formatMessage({
    id: 'navigation.signOut',
    defaultMessage: 'Sign Out',
  });
  const welcomeText = intl.formatMessage({
    id: 'userWelcome.welcome',
    defaultMessage: 'Welcome',
  });

  const handleSignout = () => dispatch(logout());

  return (
    <Flex position="relative" justifyContent="center" paddingTop="20px">
      <Button
        backgroundColor="grey.1"
        color="grey.5"
        height="30px"
        onClick={handleSignout}
        position="absolute"
        right="-7px"
        size="xs"
        top="12px"
        width="70px"
        data-test="desktop-nav-signout"
      >
        {signOutText}
      </Button>

      <Stack alignItems="center" marginBottom="25px">
        <Avatar
          backgroundColor="white"
          borderColor="grey.2"
          borderWidth="1px"
          boxSize="45px"
          icon={<Icon as={FaUser} fontSize="27px" color="grey.2" />}
          marginBottom="10px"
          showBorder
        />
        <Text fontSize="sm" textAlign="center" lineHeight="0.8">
          {welcomeText},
        </Text>
        <Text fontSize="sm" fontFamily="heading" textAlign="center">
          {email}
        </Text>
      </Stack>
    </Flex>
  );
};

export default UserWelcome;
