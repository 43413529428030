import {
  Box,
  BoxProps,
  Flex,
  HStack,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  SimpleGridProps,
  Text,
  useBreakpointValue,
  Wrap,
} from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ShopBackground, ShopImage } from '../../../../../../../shop-api-client';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import Swatch from '../../../../../../shared/components/BackgroundSwatches/Swatch';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground/ThumbnailWithBackground';
import { UniqueImageAndBackgroundSet } from '../../../../../../shared/types/image';
import { Params } from '../../../../../../shared/types/router';
import { PNG_BG_SVG, THUMB_GRID_MAX_HEIGHT, THUMB_GRID_MAX_WIDTH } from '../../../constants';
import ImageOptionSwapSelection, {
  SwapProps,
} from '../ImageOptionSwapSelection/ImageOptionSwapSelection';

interface Props extends SimpleGridProps {
  availableImages: UniqueImageAndBackgroundSet;
  boxProps?: BoxProps;
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  swapProps?: SwapProps;
  selectedImages: string[];
  selectImage(img: ShopImage): void;
  showAsHorizontalList?: boolean;
  showPopover?: boolean;
}

/** Renders images that have are "Preset"/"Complete" (either not GS, or GS img + bg pair)
 * This is currently used for rendering favorite images
 * and recently used images (images already in-cart)
 * So none of them require any further modifications (except for crop)
 */
const ImageOptionImageDisplay = ({
  boxProps,
  imageMaxHeight = THUMB_GRID_MAX_HEIGHT,
  imageMaxWidth = THUMB_GRID_MAX_WIDTH,
  availableImages,
  selectedImages,
  selectImage,
  showAsHorizontalList,
  swapProps,
  showPopover,
  ...rest
}: Props) => {
  const { key } = useParams<Params>();
  const { images } = useAppSelector(state => selectGallery(state, key));
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const thumbsRef = useRef<Record<string, HTMLDivElement>>({});

  const borderRadius = isMobile ? '3px' : '5px';
  const borderWidth = isMobile ? '3px' : '4px';
  const borderPadding = isMobile ? '2px' : '4px';

  if (!Object.keys(availableImages).length) {
    return null;
  }

  const renderBgsForImg = (backgrounds: number[]) => {
    if (!backgrounds?.length) {
      return;
    }

    return (
      <Wrap justifyContent="flex-start" alignItems="flex-start" paddingX={1}>
        {backgrounds.map(bg => (
          <Swatch
            key={bg}
            position="relative"
            size="15px"
            borderRadius="3px"
            url={`url(${backgroundsMap[bg].sources.thumb}) no-repeat`}
            data-test="background-swatches-images"
          />
        ))}
      </Wrap>
    );
  };

  const popoverDirectionLookup = { 0: '-start', 1: '', 2: '-end' };

  const getPopoverDirection = (idx: number) => {
    const popoverDirection =
      popoverDirectionLookup[(idx % 3) as keyof typeof popoverDirectionLookup];

    // if top row
    if (idx < 3) {
      return `bottom${popoverDirection}`;
    }
    // bottom row
    if (idx >= Object.keys(availableImages).length - 3) {
      return `top${popoverDirection}`;
    }
    // in the middle somewhere
    return `auto${popoverDirection}`;
  };

  const renderPopoverThumbnail = (children: ReactNode, imageName: string, idx: number) => {
    // only show popover for a thumbnail if we it's not on 'all'
    // and if mobile
    if (!showPopover || !swapProps) {
      return null;
    }
    return (
      <Popover
        key={imageName}
        flip
        isLazy
        preventOverflow
        lazyBehavior="unmount"
        placement={getPopoverDirection(idx) as PlacementWithLogical}
      >
        <PopoverTrigger>{children}</PopoverTrigger>

        {swapProps.swapSelectionImg && swapProps.swapSelectionImg !== 'all' && (
          <PopoverContent
            bgColor="white"
            boxShadow="0px 0px 10px 2px #00000014"
            width={{ base: undefined, md: '375px' }}
          >
            <PopoverArrow />
            <PopoverBody>
              <ImageOptionSwapSelection {...swapProps} />
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    );
  };

  const renderThumbnail = (
    imageName: string,
    img: ShopImage,
    isSelected: boolean,
    bg?: ShopBackground,
  ) => (
    <Box
      data-test={`shop-photo-selection-${img.displayName}`}
      borderColor={isSelected ? 'brand' : 'transparent'}
      borderWidth={borderWidth}
      borderRadius={borderRadius}
      marginX={showAsHorizontalList ? '4px' : undefined}
      padding={borderPadding}
      ref={ref => (thumbsRef.current[imageName] = ref!)}
      tabIndex={0}
    >
      <ThumbnailWithBackground
        src={img.sources.thumb}
        background={bg?.sources?.thumb}
        containerOnClick={() => selectImage(img)}
        maxWidth={imageMaxWidth}
        maxHeight={imageMaxHeight}
        isActive={isSelected}
        _hover={{
          cursor: 'pointer',
        }}
        containerStyles={{
          background: PNG_BG_SVG,
          backgroundRepeat: 'repeat',
        }}
      />
    </Box>
  );

  const renderImages = () => {
    return Object.entries(availableImages).map(([imageName, backgroundSet], idx) => {
      const backgrounds = Array.from(backgroundSet);

      const img = images[imageName];
      const firstBg = backgrounds ? backgroundsMap[backgrounds[0]] : undefined;
      const isSelected = selectedImages.some(si => si === imageName);

      return (
        <Flex key={imageName} direction="column">
          <Flex
            direction="column"
            alignItems="center"
            marginBottom={2}
            justifyContent="space-between"
            flex={1}
          >
            {showPopover
              ? renderPopoverThumbnail(
                  renderThumbnail(imageName, img, isSelected, firstBg),
                  img.internalName,
                  idx,
                )
              : renderThumbnail(imageName, img, isSelected, firstBg)}
            <Text fontSize="xs" noOfLines={1} maxWidth={imageMaxWidth} textAlign="center">
              {img.displayName}
            </Text>
          </Flex>
          <Box height="23px">{(backgrounds?.length || 0) > 1 && renderBgsForImg(backgrounds)}</Box>
        </Flex>
      );
    });
  };

  return (
    <Box {...boxProps}>
      {showAsHorizontalList ? (
        <HStack {...rest}>{renderImages()}</HStack>
      ) : (
        <SimpleGrid columns={{ base: 2, md: 4 }} {...rest}>
          {renderImages()}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ImageOptionImageDisplay;
