import { Flex } from '@chakra-ui/layout';
import {
  Button,
  Image as ChakraImage,
  Checkbox,
  Icon,
  Input,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { forwardRef, useState } from 'react';
import { FiCamera } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import FacialRecognitionStepHeader from '../FacialRecognitionStepHeader';

export interface SubjectRegistrationFields {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UploadStepPayload {
  image: string;
  agreedToPolicy: boolean;
  smsOptIn: boolean;
}

interface Props {
  isActive: boolean;
  isComplete: boolean;
  onSubmit(payload: UploadStepPayload): Promise<void>;
  stepNumber: number;
}

const UploadSubjectStep = forwardRef<HTMLDivElement, Props>(
  ({ isActive, isComplete, onSubmit, stepNumber }, ref) => {
    const [image, setImage] = useState('');
    const [agreedToPolicy, setAgreedToPolicy] = useState(false);
    const [smsOptIn, setSmsOptIn] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const intl = useIntl();

    const handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        // const image = URL.createObjectURL(e.target.files[0]);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    const handlePolicyChange = () => {
      setAgreedToPolicy(!agreedToPolicy);
    };

    const handleOptInChange = () => {
      setSmsOptIn(!smsOptIn);
    };

    const handleSubmit = async () => {
      setSubmitting(true);
      await onSubmit({ image, agreedToPolicy, smsOptIn });
      setSubmitting(false);
    };

    const renderContent = () => {
      if (isActive) {
        return renderUploadForm();
      }

      if (isComplete) {
        return renderCompletedForm();
      }

      return null;
    };

    const renderCompletedForm = () => {
      return null;
    };

    const renderUploadForm = () => (
      <Flex flexDirection="column">
        <Flex
          flexDirection="column"
          paddingX={['52px', '52px', '52px', 0]}
          maxWidth={['auto', 'auto', 'auto', '254px']}
          marginX="auto"
        >
          <Text marginTop={6} marginBottom={3}>
            {intl.formatMessage({
              id: 'facialRecognition.faceInstructions',
              defaultMessage: 'Make sure your image is clear and contains only your face',
            })}
          </Text>
          <ChakraImage
            borderRadius="12px"
            maxWidth="100%"
            src={image || '/assets/images/selfie-examples.png'}
          />
          <Flex marginTop={4} direction="row" width="100%">
            <Input
              type="file"
              id="camera"
              accept="image/*"
              capture="user"
              onChange={handleCapture}
              display="none"
            />
            <label htmlFor="camera" style={{ flex: 1 }}>
              <Flex
                borderRadius="8px"
                padding={4}
                justifyContent="center"
                backgroundColor="brandLight"
                color="brand"
                fontSize="lg"
                width="100%"
              >
                <Icon as={FiCamera} color="brand" marginRight={2} />
                <Text fontSize="14px" fontWeight="bold">
                  Camera
                </Text>
              </Flex>
            </label>
          </Flex>
        </Flex>
        <Flex direction="column" marginTop={8} paddingX={4}>
          <Flex direction="row" marginBottom={4}>
            <Checkbox
              alignSelf="flex-start"
              isChecked={agreedToPolicy}
              onChange={handlePolicyChange}
              marginRight={2}
              id="policy"
            />
            <label htmlFor="policy">
              <Text fontSize="14px">
                {intl.formatMessage({
                  id: 'facialRecognition.confirmPolicy',
                  defaultMessage: 'I confirm that I have read, consent and agree to the',
                })}{' '}
                <Link
                  href="https://www.imagequix.com/privacy-policy/"
                  target="_blank"
                  color="brand"
                  fontWeight="bold"
                >
                  {intl.formatMessage({
                    id: 'facialRecognition.privacyPolicy',
                    defaultMessage: 'Privacy Policy',
                  })}
                </Link>
                .
              </Text>
            </label>
          </Flex>
          <Flex direction="row">
            <Checkbox
              alignSelf="flex-start"
              isChecked={smsOptIn}
              onChange={handleOptInChange}
              marginRight={2}
              id="sms-opt-in"
            />
            <label htmlFor="sms-opt-in">
              <Text fontSize="14px">
                {intl.formatMessage({
                  id: 'facialRecognition.smsOptIn',
                  defaultMessage: 'I want to receive text messages about my photos',
                })}
              </Text>
            </label>
          </Flex>
        </Flex>
        <Button
          disabled={!image || !agreedToPolicy || submitting}
          marginTop={6}
          onClick={handleSubmit}
        >
          {submitting ? <Spinner /> : 'Save and Continue'}
        </Button>
      </Flex>
    );

    return (
      <Flex flexDirection="column" paddingY={4} ref={ref}>
        <FacialRecognitionStepHeader
          isActive={isActive}
          isComplete={isComplete}
          stepNumber={stepNumber}
          stepTitle="Upload Subject"
        />
        {renderContent()}
      </Flex>
    );
  },
);

UploadSubjectStep.displayName = 'UploadSubjectStep';
export default UploadSubjectStep;
