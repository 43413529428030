import { Box } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { ShopProduct, ShopProductPrint } from '../../../../../../../shop-api-client';
import {
  CartPrintProductReq,
  CreateCartProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectBackgroundSetMap } from '../../../../../../redux/selectors/background.selectors';
import {
  selectConfiguration,
  selectShowPkgItemBGSelection,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import {
  setCompletedSteps,
  setEditStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../../../../redux/store';
import { hasConfigurableImageNodes, hasEditableTextNodes, hasImageNodesWithDisabledBg } from '../../../../utils';
import {
  getBackground,
  getGroupedProductOptions,
  getRequiredProductOptionsStep,
} from '../../../utils';
import OptionalSection from '../../OptionalSection';
import RequiredProductOptionEditor from '../../ProductOptionEditor/RequiredProductOptionEditor';
import TextNodesEditor from '../../TextNodesEditor';
import BackgroundSelection from '../BackgroundSelection';

interface Props {
  activeStep?: string;
  editProduct: CreateCartProductReq;
  invalidOptions?: Record<string, boolean>;
  isEditing?: boolean;
  isSaving?: boolean;
  isSubItem?: boolean;
  isInvalid?: boolean;
  isPreOrder?: boolean;
  onPostProcessing(item: CreateCartProductReq): void;
  onSave(item: CreateCartProductReq): void;
  shopProduct: ShopProduct;
}

const ProductConfigSection = ({
  activeStep,
  editProduct,
  invalidOptions,
  isEditing,
  isSaving,
  isSubItem,
  isInvalid = false,
  isPreOrder = false,
  onPostProcessing,
  onSave,
  shopProduct,
}: Props) => {
  const { completedSteps, editPackageStep, editStep } = useSelector(selectConfiguration);
  const { productNodeMap } = useSelector(selectPriceSheet);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const showPkgItemBGSelection = useSelector(selectShowPkgItemBGSelection);

  const dispatch = useAppDispatch();

  const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
    shopProduct.options,
  );

  const productNodes = productNodeMap[shopProduct.catalogProductID];
  const hasTextNodes = hasEditableTextNodes(productNodes);
  const hasImageNodes = hasConfigurableImageNodes(productNodes);
  const hasCollectionImages =
    editProduct.type === 'collection' || editProduct.type === 'imageDownload';

  const imageNodes = productNodes?.filter((n) => n.type === 'image');
  const skipBgSelection = hasImageNodesWithDisabledBg(imageNodes);
  const showBackgroundSelection =
    isSubItem && showPkgItemBGSelection && (hasImageNodes || hasCollectionImages) && !(skipBgSelection && isPreOrder);

  const handleEdit = (stepName: string) => {
    dispatch(setEditStep(stepName));
  };

  const handleSave = (updatedEditProduct: CreateCartProductReq) => {
    onSave(updatedEditProduct);

    dispatch(setEditStep(null));

    if (activeStep) {
      dispatch(setCompletedSteps({ [activeStep]: true }));
    }
  };

  const renderBackgroundSelection = () => {
    if (!showBackgroundSelection) {
      return;
    }
    const selectedBackground = getBackground(editProduct, Object.values(backgroundSetMap));

    return (
      <BackgroundSelection
        editItem={editProduct}
        isInvalid={isInvalid && !selectedBackground}
        isSubItem={isSubItem}
      />
    );
  };

  return (
    <Box>
      {renderBackgroundSelection()}

      {hasTextNodes && (
        <TextNodesEditor
          activeStep={activeStep}
          alwaysActive={isSubItem}
          catalogProductID={(shopProduct as ShopProductPrint).catalogProductID}
          editProduct={editProduct as CartPrintProductReq}
          hideBorder={isSubItem}
          hideSave={isSubItem}
          isEditing={isEditing}
          isSaving={isSaving}
          onPostProcessing={onPostProcessing}
          onSave={onSave}
        />
      )}

      {requiredProductOptions.map(option => {
        const stepName = getRequiredProductOptionsStep(
          editProduct.id!,
          option.catalogOptionGroupID,
        );

        const editingProductOption = editStep === stepName || editPackageStep === stepName;
        const firstInvalid = invalidOptions ? parseInt(Object.keys(invalidOptions)[0]) : null;

        return (
          <RequiredProductOptionEditor
            key={option.id}
            containerProps={isSubItem ? { paddingBottom: 6 } : undefined}
            editProduct={editProduct}
            hideSave={isSubItem}
            isCompleted={!isSubItem && !editingProductOption && completedSteps[stepName]}
            isDisabled={!isSubItem && activeStep !== stepName && !editingProductOption}
            isInvalid={invalidOptions && invalidOptions[option.catalogOptionGroupID]}
            isSubItem={isSubItem}
            onEdit={() => handleEdit(stepName)}
            option={option}
            onSave={handleSave}
            scrollToTop={firstInvalid === option.catalogOptionGroupID}
          />
        );
      })}
      {isSubItem && (
        <OptionalSection
          editItem={editProduct}
          hideBorder
          isDisabled={false}
          onSave={onSave}
          optionalProductOptions={optionalProductOptions}
          useOther={requiredProductOptions.length > 0}
        />
      )}
    </Box>
  );
};

export default ProductConfigSection;
