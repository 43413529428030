import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import Alert from './components/alert';
import Button from './components/button';
import Checkbox from './components/checkbox';
import Divider from './components/divider';
import Input from './components/input';
import Link from './components/link';
import Modal from './components/modal';
import Radio from './components/radio';
import Select from './components/select';
import Skeleton from './components/skeleton';
import Text from './components/text';
import Tooltip from './components/tooltip';
import styles from './styles';

const theme = extendTheme({
  colors,
  fonts: {
    heading: 'ITC Avant Garde Gothic Demi',
    body: 'ITC Avant Garde Gothic Book',
  },
  styles,
  components: {
    Alert,
    Button,
    Checkbox,
    Divider,
    Input,
    Link,
    Modal,
    Radio,
    Select,
    Skeleton,
    Text,
    Tooltip,
  },
});

export default theme;
