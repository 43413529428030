import React, { useEffect, useRef } from 'react';

interface Props {
  background: HTMLImageElement | null;
  height: number;
  image: HTMLImageElement | null;
  isGreenScreen: boolean;
  offset: { x: number; y: number };
  scale: number;
  width: number;
}

const ActiveImageCanvas = ({
  background,
  height,
  image,
  isGreenScreen,
  offset,
  scale,

  width,
}: Props) => {
  const backgroundRef = useRef<HTMLCanvasElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!image || !canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    // Draw the image
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      offset.x,
      offset.y,
      width * scale,
      height * scale,
    );

    // Check for background
    if (!backgroundRef.current || !isGreenScreen) {
      return;
    }
    const bgCtx = backgroundRef.current.getContext('2d');

    if (!bgCtx) {
      return;
    }
    bgCtx.canvas.width = width;
    bgCtx.canvas.height = height;

    if (background) {
      // Draw the background image, if one is present
      bgCtx.drawImage(
        background,
        0,
        0,
        background.width,
        background.height,
        offset.x,
        offset.y,
        width * scale,
        height * scale,
      );
    } else {
      // Draw white bg if no background is selected but is greenscreen
      bgCtx.fillStyle = 'white';
      bgCtx.fillRect(0, 0, width, height);
    }
  }, [height, image, offset, scale, width, background, isGreenScreen]);

  const ignoreRightClick = (e: React.MouseEvent) => e.preventDefault();

  return (
    <>
      <canvas
        ref={ref => {
          backgroundRef.current = ref;
        }}
        onContextMenu={ignoreRightClick}
        style={{
          position: 'absolute',
        }}
      />
      <canvas
        ref={ref => {
          canvasRef.current = ref;
        }}
        onContextMenu={ignoreRightClick}
        style={{
          position: 'absolute',
        }}
      />
    </>
  );
};

export default ActiveImageCanvas;
