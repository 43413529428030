import { intl } from './intl';

// Aria labels
export const CALL = intl.formatMessage({
  id: 'ariaLabel.constants.call',
  defaultMessage: 'Call',
});
export const CLOSE_MENU = intl.formatMessage({
  id: 'ariaLabel.constants.closeMenu',
  defaultMessage: 'Close Menu',
});
export const CLOSE_MODAL = intl.formatMessage({
  id: 'ariaLabel.constants.closeModal',
  defaultMessage: 'Close Modal',
});
export const EMAIL = intl.formatMessage({
  id: 'ariaLabel.constants.email',
  defaultMessage: 'E-mail',
});
export const ENLARGE = intl.formatMessage({
  id: 'ariaLabel.constants.enlarge',
  defaultMessage: 'Enlarge',
});
export const LOADING = intl.formatMessage({
  id: 'ariaLabel.constants.loading',
  defaultMessage: 'Loading',
});
export const NAVIGATE_LEFT = intl.formatMessage({
  id: 'ariaLabel.constants.navigateLeft',
  defaultMessage: 'Navigate Left',
});
export const NAVIGATE_RIGHT = intl.formatMessage({
  id: 'ariaLabel.constants.navigateRight',
  defaultMessage: 'Navigate Right',
});
