import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ShopImage } from '../../../../../shop-api-client';
import { CartImageOption } from '../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCartItemsWithImage } from '../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  calculateFinancials,
  deleteCartOption,
  deleteImageFromCartOption,
} from '../../../../redux/thunks/cart.thunks';
import Thumbnail from '../../../../shared/components/Thumbnail';
import { FREE, HIDE, SHOW } from '../../../../shared/constants';
import { formatCurrency } from '../../../../shared/utils';
import ProductImage from '../../../Products/ProductImage';
import CartItemActions from '../CartItemActions';
import CartItemImageList from '../CartItemImageList';

interface Props {
  image?: ShopImage;
  option: CartImageOption;
  priceSheetID: number;
  visitKey: string;
}

const CartImageOptionSection = ({ option, priceSheetID, image, visitKey }: Props) => {
  const { currency } = useAppSelector(selectAccount);
  const { imageOptionMap } = useAppSelector(state => selectPriceSheet(state, priceSheetID));
  const { isPreOrder } = useAppSelector(state => selectGallery(state, visitKey));
  const cartItemsWithImage = useAppSelector(state =>
    selectCartItemsWithImage(state, image, visitKey),
  );

  const [showDetails, setShowDetails] = useState(false);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const itemsWithPhoto = intl.formatMessage({
    id: 'cartImageOptionSection.itemsWithPhoto',
    defaultMessage: 'Items with Photo',
  });
  const showOrHideDetails = intl.formatMessage(
    {
      id: 'cartImageOptionSection.hideDetails',
      defaultMessage: '{showOrHide} item details',
    },
    {
      showOrHide: showDetails ? HIDE : SHOW,
    },
  );

  const shopOption = imageOptionMap[option.priceSheetOptionID];
  const selection =
    shopOption?.type === 'selection'
      ? shopOption.selections.find(s => s.catalogOptionID === option.optionID)
      : undefined;

  const handleRemove = async () => {
    dispatch(calculateFinancials(option, visitKey, { remove: true }));
    if (!isPreOrder && image) {
      await dispatch(deleteImageFromCartOption(option.id, image.internalName, visitKey));
      return;
    }
    await dispatch(deleteCartOption(option.id, visitKey));
  };

  const handleDetails = () => setShowDetails(!showDetails);

  const renderThumbnail = () => {
    if (!image) {
      return null;
    }
    const { sources } = image;
    const thumbnailSize = isMobile ? '45px' : '57px';

    return (
      <Thumbnail
        containerStyles={{ alignItems: 'center' }}
        hideSpinner
        maxHeight={thumbnailSize}
        maxW={thumbnailSize}
        maxWidth="unset"
        src={sources.thumb}
      />
    );
  };

  const renderItemsWithImageList = () => {
    if (!cartItemsWithImage.length) {
      return null;
    }
    return (
      <>
        <Heading fontSize={{ base: 'xs', md: 'sm' }} marginBottom={0.5}>
          {itemsWithPhoto}
        </Heading>
        <UnorderedList marginLeft={7}>
          {cartItemsWithImage.map(item => (
            <ListItem key={`${item.type}-${item.id}`} fontSize={{ base: 'xs', md: 'sm' }}>
              <Text>{item.name}</Text>

              {(item.type === 'standard' || item.type === 'buildYourOwn') && (
                <>
                  {item.products.map(product => (
                    <Text key={product.id} fontSize={{ base: 'xs', md: 'sm' }}>
                      <chakra.span
                        fontSize={{ base: '11px', md: '12px' }}
                        fontWeight="bold"
                        marginRight="1ch"
                      >
                        ◦
                      </chakra.span>
                      {product.name}
                    </Text>
                  ))}
                </>
              )}
            </ListItem>
          ))}
        </UnorderedList>
      </>
    );
  };

  return (
    <Flex direction="column">
      <Flex marginBottom={2.5} marginTop={{ base: 3, md: 4 }} minHeight="100px" position="relative">
        <Box
          marginRight={{ base: '18px', md: 'initial' }}
          marginTop={1}
          minWidth={{ base: '80px', md: 'initial' }}
          width={{ base: '80px', md: '145px' }}
        >
          <ProductImage
            fallbackFontSize={isMobile ? '10px' : '13px'}
            fallbackIconSize={isMobile ? '20px' : '34px'}
            height={{ base: 'initial', md: '120px' }}
            image={shopOption?.displayImage}
          />
        </Box>

        <Flex
          data-test={`${option.name}-container`}
          direction="column"
          marginLeft={{ base: 0, md: 5 }}
          width="100%"
        >
          <Box width="100%">
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              marginBottom={{ base: 4, md: 0 }}
            >
              <Box>
                <Heading fontSize="md" lineHeight="1.5" marginBottom={{ base: 1, md: 0 }}>
                  {option.name}
                </Heading>

                {selection && (
                  <Text fontSize="sm" marginTop={0.5} marginBottom={{ base: 1.5, md: 0 }}>
                    {selection.name}
                  </Text>
                )}
              </Box>

              <Heading
                as="span"
                fontSize={{ base: 'sm', md: 'lg' }}
                fontWeight="bold"
                marginTop={0.5}
              >
                {option.unitPrice ? formatCurrency(option.unitPrice, currency) : FREE}
              </Heading>
            </Flex>

            <Flex flexDir={{ base: 'column-reverse', md: 'column' }}>
              {!isPreOrder && (
                <Button
                  color="grey.9"
                  fontSize="sm"
                  justifyContent="flex-start"
                  marginBottom={{ base: 2, md: 5 }}
                  marginLeft={0}
                  marginTop={2.5}
                  onClick={handleDetails}
                  variant="link"
                >
                  {showOrHideDetails}
                </Button>
              )}
              {renderThumbnail()}
            </Flex>

            {showDetails && (
              <Box marginTop={{ base: 0, md: 4 }} marginBottom={{ base: 1, md: 0 }}>
                {image && (
                  <CartItemImageList imageSet={[image]} visitKey={visitKey} marginBottom={3} />
                )}
                {renderItemsWithImageList()}
              </Box>
            )}
          </Box>

          {/* TODO: Conditions for this will change when image options are editable */}
          {shopOption.requirementType === 'optional' && (
            <Flex justify="right" marginTop={isPreOrder ? { base: '25px', md: '100px' } : 0}>
              <CartItemActions onRemove={handleRemove} />
            </Flex>
          )}
        </Flex>
      </Flex>

      <Divider borderColor="grey.2" borderBottomWidth="1px" marginTop={{ base: 0.5, md: 1 }} />
    </Flex>
  );
};

export default CartImageOptionSection;
