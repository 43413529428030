import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ShopBuildYourOwnPackage, ShopProduct } from '../../../../../../../shop-api-client';
import {
  selectAddedUnits,
  selectConfiguration,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { Z_INDEX_TWO } from '../../../../../../shared/constants';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import { pluralize } from '../../../../../../shared/utils';
import { UNIT, UNITS } from '../../../constants';
import DisplayImage from '../../../shared/BuildYourOwn/DisplayImage';
import { getUniqueQuantityMap } from '../../../utils';

const SidebarBYOPItems = () => {
  const { editPackage } = useAppSelector(selectConfiguration);
  const { products } = useAppSelector(selectPriceSheet);
  const addedUnits = useAppSelector(selectAddedUnits);

  const isMobile = useIsMobile();
  const intl = useIntl();

  const uniqueQuantityMap = useMemo(() => getUniqueQuantityMap(editPackage), [editPackage]);

  const shopPackage = products[editPackage!.priceSheetItemID] as ShopBuildYourOwnPackage;
  const balanceCount = shopPackage.includedUnits - addedUnits;

  if (!editPackage || !shopPackage || shopPackage.type !== 'package-byop') {
    return null;
  }

  const { availableProducts } = shopPackage;
  const pkgItemMap: Record<number, ShopProduct> = {};
  const included: ShopProduct[] = [];
  const includedCount: Record<number, number> = {};
  for (const product of availableProducts) {
    pkgItemMap[product.id] = product;
    if (product.price === 0) {
      included.push(product);

      // Keep count of the included items to determine the actual added count
      includedCount[product.id] = (includedCount[product.id] || 0) + 1;
    }
  }

  const renderAdded = () => {
    if (isMobile) {
      return null;
    }

    const added: ShopProduct[] = [];
    for (const item of availableProducts) {
      // Add it as many times as there are non-included entries:
      const addedCount = uniqueQuantityMap[item.id] - (includedCount[item.id] || 0);
      for (let i = 0; i < addedCount; i++) {
        added.push(item);
      }
    }

    return (
      <>
        <Flex direction="column" paddingTop={5}>
          <Text fontFamily="heading" fontSize="sm" textTransform="uppercase">
            {intl.formatMessage(
              {
                id: 'BYOPSelectionStep.addedItems',
                defaultMessage: 'Added Items ({count})',
              },
              { count: added.length },
            )}
          </Text>
        </Flex>
        <Flex width="90%" direction="column">
          <Text paddingTop={2} fontSize="sm">
            {!added.length &&
              intl.formatMessage({
                id: 'SidebarBYOPItems.instructions',
                defaultMessage: 'No items added. Click the + icon on an item to the left to add.',
              })}
          </Text>
          {added.map((item, index) => (
            <Flex
              key={`${item.id}-${index}`}
              direction="row"
              borderBottomWidth="1px"
              borderColor="grey.2"
              paddingY={3}
            >
              <DisplayImage minWidth="80px" src={item.image} product={item} />
              <Box paddingLeft={4} paddingTop={4}>
                <Text noOfLines={1} fontSize="md" fontFamily="ITC Avant Garde Gothic Demi">
                  {item.name}
                </Text>
                <Text fontSize="sm" color="brand">
                  {intl.formatMessage(
                    {
                      id: 'BYOPSelectionStep.unitsCount',
                      defaultMessage: '{count} {units}',
                    },
                    {
                      count: item.price,
                      units: pluralize(item.price, shopPackage.unitLabel, false),
                    },
                  )}
                </Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      </>
    );
  };

  const renderBalance = () => {
    return (
      <>
        <Text fontSize="sm">
          {intl.formatMessage({
            id: 'BYOPSelectionStep.currentBalance',
            defaultMessage: 'Current Balance',
          })}
        </Text>
        <Text fontSize="x-large" fontFamily="ITC Avant Garde Gothic Demi" marginBottom={4}>
          {intl.formatMessage(
            {
              id: 'BYOPSelectionStep.unitsCount',
              defaultMessage: '{count} {units}',
            },
            {
              count: balanceCount,
              units: balanceCount === 1 ? UNIT : UNITS,
            },
          )}
        </Text>
      </>
    );
  };

  const renderIncluded = () => {
    if (isMobile || !included.length) {
      return null;
    }

    return (
      <Flex direction="column" paddingY={5}>
        <Text fontFamily="heading" fontSize="sm">
          {intl.formatMessage(
            {
              id: 'BYOPSelectionStep.includedItems',
              defaultMessage: 'INCLUDED ITEMS ({count})',
            },
            { count: included.length },
          )}
        </Text>
        <Flex width="100%" flexWrap="wrap">
          {included.map(item => (
            <Box key={item.id} marginRight={3} width="80px">
              <DisplayImage minWidth="80px" src={item.image} product={item} />
              <Text noOfLines={2} fontSize="sm" fontFamily="ITC Avant Garde Gothic Demi">
                {item.name}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {isMobile && (
        <Flex
          backgroundColor="white"
          borderColor="grey.1"
          borderBottomWidth="1px"
          direction="column"
          left="0"
          paddingTop="10px"
          paddingX="20px"
          position="sticky"
          right="0"
          top="106px"
          zIndex={Z_INDEX_TWO}
        >
          {renderBalance()}
        </Flex>
      )}
      <Flex direction="column">
        <Box marginBottom={4}>
          {!isMobile && renderBalance()}
          {!isMobile && (
            <Text fontSize="large" fontFamily="ITC Avant Garde Gothic Demi">
              {intl.formatMessage({
                id: 'BYOPSelectionStep.buildYourPackage',
                defaultMessage: 'Build your package',
              })}
            </Text>
          )}
          {renderIncluded()}
          {renderAdded()}
        </Box>
      </Flex>
    </>
  );
};

export default SidebarBYOPItems;
