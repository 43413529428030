import { Button, Flex, Spacer, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../../redux/selectors/account.selectors';
import { selectConfigurationSummary } from '../../../../../redux/selectors/configurations.selectors';
import StickyMobileSection from '../../../../../shared/components/Mobile/StickyMobileSection';
import useSearchParams from '../../../../../shared/hooks/useSearchParams';
import { formatCurrency } from '../../../../../shared/utils';
import { ADD_TO_CART } from '../../../constants';
import { TOOLBAR_HEIGHT, UPDATE } from '../../constants';

interface Props {
  isAddingOrUpdating: boolean;
  label?: string;
  onClick?(): void;
  show: boolean;
  showUpdateText: boolean;
}

const Toolbar = ({ isAddingOrUpdating, label, onClick, show, showUpdateText }: Props) => {
  const { currency } = useSelector(selectAccount);
  const { subtotal } = useSelector(selectConfigurationSummary);

  const { getParamsValue } = useSearchParams();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const buttonText = showUpdateText ? UPDATE : ADD_TO_CART;
  const cartPackageID = getParamsValue('cartPackageID');
  const cartProductID = getParamsValue('cartProductID');

  return (
    <StickyMobileSection
      backgroundColor="grey.1"
      containerHeight={isMobile ? undefined : `${TOOLBAR_HEIGHT}px`}
      direction="bottom"
      paddingX={{ base: 2, lg: 20 }}
      show={show}
    >
      <Flex paddingX={{ base: 2, md: 10 }} marginLeft="auto">
        {!cartPackageID && !cartProductID && (
          <Text fontFamily="heading" fontSize="xl">
            {formatCurrency(subtotal, currency)}
          </Text>
        )}
      </Flex>
      {isMobile && <Spacer />}
      <Button
        data-test="toolbar-add-item-button"
        disabled={isAddingOrUpdating}
        isLoading={isAddingOrUpdating}
        loadingText={label || buttonText}
        maxWidth="50%"
        onClick={onClick}
        width="300px"
      >
        {label || buttonText}
      </Button>
    </StickyMobileSection>
  );
};

export default Toolbar;
