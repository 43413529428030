import { Button, Flex, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  ImageRequirementType,
  ShopBackground,
  ShopImage,
} from '../../../../../../../shop-api-client';
import { CHANGE_BACKGROUND, CHANGE_PHOTO, EDIT_CROP } from '../../../constants';
import ImageNodeDisplay from '../../ImageNodesEditor/ImageNodeDisplay';
import { shapeSelectedImage } from '../../ImageNodesEditor/ImageNodeDisplay/utils';
import SelectedImage from '../SelectedImage';

type EditType = 'background' | 'crop' | 'image' | null;

interface Props {
  background?: ShopBackground;
  canEditCrop?: boolean;
  disableImage?: boolean;
  editImage: boolean;
  image?: ShopImage;
  imageRequirementType?: ImageRequirementType | null;
  onEdit(type: EditType): void;
  onSelectImage(img: ShopImage, background?: ShopBackground): void;
  skipBackgroundSelection?: boolean;
  subText?: String;
}

const EditSelection = ({
  background,
  canEditCrop,
  disableImage,
  editImage,
  image,
  imageRequirementType,
  onEdit,
  onSelectImage,
  skipBackgroundSelection,
  subText,
}: Props) => (
  <Flex direction="column" width="100%" marginY={2}>
    <Flex marginY={4}>
      <SelectedImage background={background} image={image} subText={subText} />
      {image && (
        <VStack alignItems="flex-end">
          <Button isDisabled={disableImage} onClick={() => onEdit('image')} variant="grey">
            {CHANGE_PHOTO}
          </Button>
          {image.isGreenScreen && !skipBackgroundSelection && (
            <Button onClick={() => onEdit('background')} variant="grey">
              {CHANGE_BACKGROUND}
            </Button>
          )}
          {canEditCrop && (
            <Button onClick={() => onEdit('crop')} variant="grey">
              {EDIT_CROP}
            </Button>
          )}
        </VStack>
      )}
    </Flex>
    {editImage && (
      <ImageNodeDisplay
        imageRequirementType={imageRequirementType}
        selectedImages={shapeSelectedImage(image?.internalName, background?.id)}
        selectImage={onSelectImage}
        skipBackgroundSelection={skipBackgroundSelection}
      />
    )}
  </Flex>
);

export default EditSelection;
