import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import RendererComponent, {
  Cache,
  CartItemImageNode,
  CartItemNode,
  CatalogProductNode,
  HeightAndWidth,
  ImageNode,
  LAYER,
  QRNode,
} from 'iq-product-render';
import { keyBy } from 'lodash';
import LRUCache from 'lru-cache';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiCamera } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Crop, ShopProductPrint } from '../../../../shop-api-client';
import {
  CartNode,
  CartPrintProduct,
  CartPrintProductReq,
  CreateCartNodeReq,
  ImageTone,
} from '../../../../shop-api-client/models/Cart';
import { selectAllBackgrounds } from '../../../redux/selectors/background.selectors';
import { selectCatalog } from '../../../redux/selectors/catalog.selectors';
import { selectConfiguration } from '../../../redux/selectors/configurations.selectors';
import { selectDynamicData, selectGallery } from '../../../redux/selectors/gallery.selectors';
import { setEditNodeID } from '../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import StickyMobileSection from '../../../shared/components/Mobile/StickyMobileSection';
import { BACKGROUND_THUMB, ONDEMAND_SIZE_600 } from '../../../shared/constants';
import useIsElemInView from '../../../shared/hooks/useIsElemInView';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { JobImageKey } from '../../../shared/types/image';
import { getScaledHeight, getScaledWidth } from '../../../shared/utils';
import {
  MAX_PREVIEW_HEIGHT,
  MAX_PREVIEW_SIZE,
  PHOTO_NUMBER,
  PREVIEW,
  PREVIEW_ONLY,
} from '../Configuration/constants';
import { isConfigurableImageNode } from '../utils';

export const CART_SUMMARY_MAX_PREVIEW_SIZE = 57;
export const CART_SUMMARY_MAX_PREVIEW_SIZE_MOBILE = 45;

const imageCache = new LRUCache<string, HTMLImageElement | Promise<HTMLImageElement>>({ max: 30 });

interface NodeLayeredImage {
  background?: NodeLayeredImageBackground | null;
  crop: Crop;
  image: string | null;
  imageTone: ImageTone;
  isGroup: boolean;
  origName: string | null;
}

interface NodeLayeredImageBackground {
  id: number;
  name: string;
  serviceID: number;
  setID: number;
  sourceSize: number;
}

interface ImageLoadingMap {
  [key: string]: boolean;
}

interface InteractiveNodeProps {
  node: CatalogProductNode;
  style: React.CSSProperties;
}

interface Props extends BoxProps {
  boxShadow?: BoxProps['boxShadow'];
  disableMiniPreview?: boolean;
  editProduct: CartPrintProductReq | CartPrintProduct;
  hidePreviewText?: boolean;
  isFinalRender?: boolean;
  isInteractive?: boolean;
  maxPreviewHeight?: number;
  maxPreviewWidth?: number;
  onDoubleClickNode?(node: CatalogProductNode): void;
  shopProduct: ShopProductPrint;
  unlockedNodes?: 'image' | 'text' | 'none';
  visitKey?: string;
  previewText?: string;
}

// TODO: This component needs Dynamic Data properly injected
const ProductPreview = ({
  boxShadow,
  disableMiniPreview,
  editProduct,
  hidePreviewText,
  isFinalRender = false,
  isInteractive = true,
  maxPreviewHeight = MAX_PREVIEW_HEIGHT,
  maxPreviewWidth = MAX_PREVIEW_SIZE,
  onDoubleClickNode,
  previewText = PREVIEW_ONLY,
  shopProduct,
  unlockedNodes,
  visitKey,
  ...rest
}: Props) => {
  const allBackgrounds = useSelector(selectAllBackgrounds);
  const { editNodeID } = useSelector(selectConfiguration);
  const { rendererProductMap } = useSelector(selectCatalog);
  const { images, ...gallery } = useAppSelector(state => selectGallery(state, visitKey));
  const dynamicData = useSelector(selectDynamicData);

  const [imageLoadingMap, setImageLoadingMap] = useState<ImageLoadingMap>({});
  const [loadingProduct, setLoadingProduct] = useState(true);

  const { height, width } = useWindowSize();
  const dispatch = useAppDispatch();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const rendererMiniRef = useRef<RendererComponent | null>(null);
  const rendererRef = useRef<RendererComponent | null>(null);
  const rendererWrapperRef = useRef<HTMLDivElement>(null);
  const previewInView = useIsElemInView(rendererWrapperRef.current);

  const rendererProduct = rendererProductMap[shopProduct.catalogProductID];
  // Shape editProducts nodes to fit interface expected by product renderer
  const rendererNodes = useMemo(
    () =>
      (editProduct.nodes as CartNode[]).reduce<CartItemNode[]>((res, n) => {
        if (n.type === 'text') {
          res.push({ ...n, text: n.text || '', nodeID: n.catalogNodeID });
        } else if (n.type === 'image') {
          const layeredImage: NodeLayeredImage = {
            crop: {
              height: 100,
              left: 50,
              top: 50,
              width: 100,
            },
            isGroup: false,
            image: n.imageInternalName,
            origName: n.imageDisplayName,
            background: n.backgroundID
              ? { id: n.backgroundID, name: '', serviceID: 0, setID: 0, sourceSize: 500 }
              : null,
            imageTone: n.imageTone || 'original',
          };

          res.push({
            ...n,
            layeredImage,
            nodeID: n.catalogNodeID,
          } as CartItemNode);
        }

        return res;
      }, []),
    [editProduct],
  );

  // Whenever node being edited changes,
  // call ProductRenderer's `forceRender` method to trigger canvas update
  useEffect(() => {
    if (!rendererProduct) {
      return;
    }

    rendererRef.current?.forceRender();
  }, [editProduct, maxPreviewHeight, maxPreviewWidth, rendererNodes, rendererProduct]);

  useEffect(() => {
    if (!rendererProduct) {
      return;
    }

    if (!previewInView) {
      rendererMiniRef.current?.forceRender();
    }
  }, [editProduct, previewInView, rendererNodes, rendererProduct]);

  if (!rendererProduct) {
    return null;
  }

  const rendererCatalogNodes = rendererProduct.nodes.map(n => ({
    ...n,
    locked: n.locked === true || (!!unlockedNodes && n.type !== unlockedNodes),
  }));

  const rendererAR = rendererProduct.width / rendererProduct.height;

  // Calculate both scaling by width and height:
  const scaledByWidth = Math.min(maxPreviewWidth, width * 0.85);
  const heightScaledByWidth = getScaledHeight(rendererAR, scaledByWidth);
  const scaledByHeight = Math.min(maxPreviewHeight, height * 0.8);
  const widthScaledByHeight = getScaledWidth(rendererAR, scaledByHeight);

  // Now pick the dimension that fits, defaulting to a width scaling:
  let rendererWidth = scaledByWidth;
  let rendererHeight = heightScaledByWidth;
  if (maxPreviewHeight && heightScaledByWidth > maxPreviewHeight) {
    rendererWidth = widthScaledByHeight;
    rendererHeight = scaledByHeight;
  }

  /** calculates current scale of rendered product from original
   * and scales the node dimensions based on product scale
   */
  const getScaledNodeDimensions = (node: CatalogProductNode, dimensions: HeightAndWidth) => {
    // compute scale by width
    const scale = dimensions.width / rendererProduct.width;
    const w = scale * node.width;
    const h = scale * node.height;
    return { w, h, scale };
  };

  const getScaledImageSize = (nodeSize: { w: number; h: number }, cartNode: CartItemImageNode) => {
    // cropW/H is calcuated by the (size of the node / scaledImage) *100
    // so we reverse engineer that to find the size of the img
    // default to 100 cropW/H if no value is available
    // TODO: recalculate based on orientation??
    const imgSizeW = nodeSize.w / ((cartNode.cropW || 100) / 100);
    const imgSizeH = nodeSize.h / ((cartNode.cropH || 100) / 100);
    // return the max value to determine if full or thumb
    return { imgSizeH, imgSizeW };
  };

  /** based on dimensions of renderer component's canvas, the image node in question, and available sources
   * determine which source size to use
   */
  const getImageUrlBySize = (
    cartNode: CartItemImageNode,
    dimensions: HeightAndWidth,
    sources: { thumb: string; full: string },
    ondemandSize = ONDEMAND_SIZE_600,
  ) => {
    // find matching node from product and cache
    const catalogNode = rendererProduct.nodes.find(node => node.id === cartNode.nodeID);

    if (!catalogNode) {
      return '';
    }

    // get scaled node dimensions
    const { w, h } = getScaledNodeDimensions(catalogNode, dimensions);
    const { imgSizeH, imgSizeW } = getScaledImageSize({ w, h }, cartNode);
    const size = Math.max(imgSizeW, imgSizeH) > ondemandSize ? 'full' : 'thumb';

    return sources[size];
  };

  /** Determines which url to return based on type
   * dimensions are the dimmensions of the whole canvas (not node)
   * cache returns the type of item it is rendering, and the data of the thing its rendering
   * renders both product images from MyDesign
   * and customer images that go into the nodes
   */
  const getImageURL = async (cache: Cache, dimensions: HeightAndWidth) => {
    if (!rendererProduct) {
      return '';
    }
    const { type, obj } = cache;

    switch (type) {
      // return base product design image urls set by studio
      case LAYER.PRODUCT_BACKGROUND: {
        return rendererProduct.background;
      }
      case LAYER.PRODUCT_FOREGROUND: {
        return rendererProduct.foreground;
      }
      case LAYER.NODE_JOB_IMAGE: {
        const catalogNode = obj as ImageNode;
        if (!catalogNode.defaultImage) {
          return '';
        }

        const jobImageKey = catalogNode.defaultImage.split('.')[1] as JobImageKey;
        return gallery[jobImageKey] || '';
      }
      // only is rendered when node mapping type is set to 'no image'
      // even if a foreground img is set on a node
      case LAYER.NODE_FOREGROUND: {
        const catalogNode = obj as ImageNode;
        return catalogNode.foreground;
      }
      // set bg on node
      case LAYER.NODE_IMAGE_BACKGROUND: {
        const cartNode = obj as CartItemImageNode;
        const background = allBackgrounds.find(
          bg => bg.id === cartNode.layeredImage.background?.id,
        );

        if (!background) {
          return '';
        }
        return getImageUrlBySize(cartNode, dimensions, background.sources, BACKGROUND_THUMB);
      }
      case LAYER.NODE_IMAGE: {
        const cartNode = obj as CartItemImageNode;
        const image = images[cartNode.layeredImage.image as string];

        return getImageUrlBySize(cartNode, dimensions, image.sources);
      }
      case LAYER.NODE_QR: {
        const dataUrl = await QRCode.toDataURL((obj as QRNode).qrCodeValue);
        return dataUrl;
      }
      default:
        return '';
    }
  };

  const handleDoubleClick = (node: CatalogProductNode) => {
    if (onDoubleClickNode) {
      onDoubleClickNode(node);
    }
  };

  const onImageLoaded = (cache: Cache) => {
    if (cache.type !== LAYER.NODE_IMAGE) {
      return;
    }

    const cartNode = cache.obj as CartItemNode;
    const image = cartNode.layeredImage;

    if (!image) {
      return;
    }

    setImageLoadingMap(prev => ({
      ...prev,
      [(cache.obj as CartItemNode).nodeID]: false,
    }));
  };

  const onImageLoadStart = (cache: Cache) => {
    if (cache.type === LAYER.NODE_IMAGE) {
      setImageLoadingMap(prev => ({
        ...prev,
        [(cache.obj as CartItemNode).nodeID]: true,
      }));
    }
  };

  const loadImage = async (cache: Cache, dimensions: HeightAndWidth) => {
    const img = new Image();
    const src = await getImageURL(cache, dimensions);

    if (!src) {
      img.src = '';
      return img;
    }

    if (imageCache.has(src)) {
      return imageCache.get(src)!;
    }

    const loadImagePromise = new Promise<HTMLImageElement>((resolve, reject) => {
      const loadTimeout = setTimeout(() => onImageLoadStart(cache), 100);

      const customResolve = () => {
        clearTimeout(loadTimeout);
        imageCache.set(src, img);
        resolve(img);
        onImageLoaded(cache);
      };

      img.crossOrigin = 'Anonymous';
      img.onload = customResolve;
      img.onerror = err => {
        clearTimeout(loadTimeout);
        reject(err);
      };

      img.src = src || '';
    });
    // Return a promise until image is done loading so that RendererComponent doesn't render a gray box thinking the image failed to load
    imageCache.set(src, loadImagePromise);

    return loadImagePromise;
  };

  const handleError = () => setLoadingProduct(false);

  const handleRenderStart = () => setLoadingProduct(true);

  const toggleloadingProduct = () => setLoadingProduct(!loadingProduct);

  const isNodePopulated = (editNode: CreateCartNodeReq | undefined) => {
    return editNode?.type === 'image' && !!editNode.imageInternalName;
  };

  /** function passed into renderer component that instructs how to render each node */
  const renderInteractiveNode = ({ node, style }: InteractiveNodeProps) => {
    const editNode = editProduct.nodes.find(n => n.catalogNodeID === node.id);
    const isConfigurable = isConfigurableImageNode(node);
    const nodeMap = keyBy(rendererCatalogNodes, 'id');
    const index = editProduct.nodes
      .filter(n => isConfigurableImageNode(nodeMap[n.catalogNodeID]))
      .findIndex(n => n === editNode);
    const nodeIsPopulated = isNodePopulated(editNode);
    const isImageLoading = nodeIsPopulated && imageLoadingMap[node.id];
    const isSelected = node.id === editNodeID;

    const sx = {
      ...style,
      borderColor: isSelected ? 'brand' : undefined,
      borderWidth: isSelected ? '5px' : undefined,
    };

    const handleClick = () => {
      if (!isSelected) {
        dispatch(setEditNodeID(node.id));
      }
    };

    if (
      editNode?.type === 'image' &&
      !isFinalRender &&
      (!nodeIsPopulated || isImageLoading) &&
      isConfigurable
    ) {
      return (
        <Flex
          key={node.id}
          align="center"
          cursor="pointer"
          justify="center"
          sx={sx}
          onClick={handleClick}
          onDoubleClick={() => handleDoubleClick(node)}
        >
          {isImageLoading && <Spinner />}
          {!nodeIsPopulated && (
            <Flex direction="column" alignItems="center">
              <Icon as={FiCamera} color="grey.8" fontSize="xl" />
              <Text fontSize="sm" textAlign="center">
                {PHOTO_NUMBER} {index + 1}
              </Text>
            </Flex>
          )}
        </Flex>
      );
    }

    return (
      <Flex
        data-test="photo-node"
        key={node.id}
        onClick={handleClick}
        onDoubleClick={() => handleDoubleClick(node)}
        sx={sx}
      />
    );
  };

  /** interactive node but for mobile, so technically no interactions - just needs same logic as above */
  const renderInteractiveNodeMobileMini = ({ node, style }: InteractiveNodeProps) => {
    const editNode = editProduct.nodes.find(n => n.catalogNodeID === node.id);
    const index = editProduct.nodes.filter(n => n.type === 'image').findIndex(n => n === editNode);
    const nodeIsPopulated = isNodePopulated(editNode);
    const isImageLoading = nodeIsPopulated && imageLoadingMap[node.id];

    if (editNode?.type === 'image' && (!nodeIsPopulated || isImageLoading)) {
      return (
        <Flex
          key={node.id}
          align="center"
          backgroundColor="grey.3"
          justify="center"
          sx={style}
          onClick={() => dispatch(setEditNodeID(node.id))}
        >
          {isImageLoading && <Spinner />}
          {!nodeIsPopulated && (
            <Flex direction="column" alignItems="center">
              <Icon as={FiCamera} color="grey.8" fontSize="xl" />
              <Text fontSize="sm" textAlign="center">
                {PHOTO_NUMBER} {index + 1}
              </Text>
            </Flex>
          )}
        </Flex>
      );
    }

    return <Flex key={node.id} sx={style} />;
  };

  return (
    <Box {...rest}>
      <Flex direction="column" ref={rendererWrapperRef} boxShadow={boxShadow}>
        <RendererComponent
          ref={rendererRef}
          cartItem={{
            ...editProduct,
            nodes: rendererNodes,
            catalogProduct: rendererProduct,
          }}
          catalogProduct={{
            ...rendererProduct,
            nodes: rendererCatalogNodes,
          }}
          drawHitNodes
          dynamicData={dynamicData}
          height={rendererHeight}
          imageLoader={loadImage}
          interactive={isInteractive}
          onError={handleError}
          onRenderEnd={toggleloadingProduct}
          onRenderStart={handleRenderStart}
          renderInteractiveNode={renderInteractiveNode}
          style={{ overflow: 'hidden' }}
          width={rendererWidth}
        />
        {!hidePreviewText && (
          <Text as="i" textAlign="center" fontSize="sm" marginTop={4}>
            {previewText}
          </Text>
        )}
      </Flex>
      {!disableMiniPreview && !previewInView && isMobile && (
        <StickyMobileSection
          containerHeight="100px"
          direction="top"
          show={previewInView}
          align="center"
          justify="space-between"
        >
          <RendererComponent
            ref={rendererMiniRef}
            imageLoader={loadImage}
            onRenderStart={handleRenderStart}
            onRenderEnd={toggleloadingProduct}
            dynamicData={dynamicData}
            onError={handleError}
            cartItem={{
              ...editProduct,
              nodes: rendererNodes,
              catalogProduct: rendererProduct,
            }}
            catalogProduct={rendererProduct}
            height={75}
            width={75}
            drawHitNodes
            renderInteractiveNode={renderInteractiveNodeMobileMini}
          />

          <Button variant="grey" borderRadius="md">
            {PREVIEW}
          </Button>
        </StickyMobileSection>
      )}
    </Box>
  );
};

export default ProductPreview;
