import { Box, BoxProps, Flex, Heading, Icon, Spinner } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FaCheck } from 'react-icons/fa';
import EditButton from '../../../../../shared/components/EditButton';
import RoundedBorderBox from '../../../../../shared/components/RoundedBorderBox';

interface Props extends BoxProps {
  children: ReactNode;
  contentProps?: BoxProps;
  heading?: string;
  isLoading?: boolean;
  onEdit?(): void;
}

const CompletedSection = ({
  children,
  contentProps,
  heading,
  isLoading,
  onEdit,
  ...rest
}: Props) => {
  const renderContents = () => {
    if (isLoading) {
      return (
        <Flex align="center" justify="center" width="100%">
          <Spinner />
        </Flex>
      );
    }
    return children;
  };

  return (
    <RoundedBorderBox
      borderColor="grey.2"
      display="flex"
      marginBottom={5}
      marginTop={5}
      marginX={-1}
      paddingBottom={3}
      paddingTop={8}
      paddingX={6}
      {...rest}
    >
      <Flex paddingLeft={2} paddingRight={3}>
        <Icon as={FaCheck} color="successGreen" fontSize="xl" />
      </Flex>
      <Box width="100%">
        <Flex justify="space-between">
          <Heading fontSize="lg" paddingBottom={2}>
            {heading}
          </Heading>
          {!!onEdit && <EditButton editClick={onEdit} fontSize="sm" height="30px" />}
        </Flex>
        <Box paddingY={3} {...contentProps}>
          {renderContents()}
        </Box>
      </Box>
    </RoundedBorderBox>
  );
};

export default CompletedSection;
