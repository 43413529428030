import { Flex, Spinner } from '@chakra-ui/react';
import { ImageNode } from 'iq-product-render';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ShopBackground, ShopImage } from '../../../../../../shop-api-client';
import { CartImageNodeCrop } from '../../../../../../shop-api-client/models/Cart';
import CropEditorTool from './CropEditorTool/CropEditorTool';
import CropEditorToolSingleNode from './CropEditorToolSingleNode';

/**
 * Pixel amount to deduct from the allowed height of the canvas to allow all elements above and below the
 * canvas to be visible within the viewport, eliminating the need to scroll
 */
const OTHER_ELEM_HEIGHTS = 100;
/**
 * Combined amount to allow white space on each side of the canvas
 */
const CANVAS_X_GUTTER = 50;

interface Props {
  flipSingleImageNode?: boolean;
  forceHeight?: boolean; // Forces height (singleNodeMaxHeight) from props, even if undefined/falsy
  forceWidth?: boolean; // Forces width (singleNodeMaxWidth) from props, even if undefined/falsy
  handleCrop(crop: CartImageNodeCrop): void;
  imageNode: ImageNode;
  initialCrop?: CartImageNodeCrop;
  inlineControls?: boolean;
  shopBackground?: ShopBackground | null;
  shopImage: ShopImage;
  singleNodeMaxHeight?: number;
  singleNodeMaxWidth?: number;
}

const CropEditor = ({
  flipSingleImageNode,
  forceHeight,
  forceWidth,
  handleCrop,
  imageNode,
  initialCrop,
  inlineControls,
  shopBackground,
  shopImage,
  singleNodeMaxHeight,
  singleNodeMaxWidth,
}: Props) => {
  const { current: memoizedInitialCrop } = useRef(initialCrop);

  const [background, setBackground] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<CartImageNodeCrop | undefined>(memoizedInitialCrop);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (!shopImage) {
      return;
    }

    const initImage = async () => {
      const loadImage = new Image();
      loadImage.src = shopImage.sources.full;
      await new Promise((resolve, reject) => {
        loadImage.onload = resolve;
        loadImage.onerror = reject;
      });

      setImage(loadImage);
    };

    initImage();
  }, [shopImage]);

  useEffect(() => {
    if (!shopBackground) {
      return;
    }

    const initBg = async () => {
      const loadBg = new Image();
      loadBg.src = shopBackground.sources.full;
      await new Promise((resolve, reject) => {
        loadBg.onload = resolve;
        loadBg.onerror = reject;
      });
      setBackground(loadBg);
    };

    initBg();
  }, [shopBackground]);

  useEffect(() => {
    if (crop && !isEqual(crop, initialCrop)) {
      handleCrop(crop);
    }
  }, [crop, initialCrop, handleCrop]);

  // Show spinner while we're loading
  if (!image || (shopBackground && !background)) {
    return <Spinner />;
  }

  const renderCropEditorTool = () => {
    if (!ref?.parentElement) {
      return null;
    }

    const { clientHeight, clientWidth } = ref.parentElement;
    if (flipSingleImageNode) {
      const maxHeight =
        forceHeight || singleNodeMaxHeight
          ? singleNodeMaxHeight
          : clientHeight - OTHER_ELEM_HEIGHTS;
      const maxWidth =
        forceWidth || singleNodeMaxWidth ? singleNodeMaxWidth : clientWidth - CANVAS_X_GUTTER;

      return (
        <CropEditorToolSingleNode
          background={background}
          handleCrop={setCrop}
          image={image}
          imageNode={imageNode}
          initialCrop={memoizedInitialCrop}
          inlineControls={inlineControls}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        />
      );
    }

    return (
      <CropEditorTool
        background={background}
        onCrop={setCrop}
        image={image}
        imageNode={imageNode}
        initialCrop={memoizedInitialCrop}
        inlineControls={inlineControls}
        maxHeight={clientHeight - OTHER_ELEM_HEIGHTS}
        maxWidth={clientWidth - CANVAS_X_GUTTER}
      />
    );
  };
  return (
    <Flex ref={flexRef => setRef(flexRef)} width="100%" flex={1}>
      {renderCropEditorTool()}
    </Flex>
  );
};

export default CropEditor;
