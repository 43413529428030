import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { StepKey } from '../../../../shop-api-client';
import { OrderFinancials } from '../../../../shop-api-client/models/Cart';
import {
  selectCheckoutData,
  selectCheckoutSlice,
  selectCheckoutSteps,
} from '../../../redux/selectors/checkout.selectors';
import { selectGalleryMap } from '../../../redux/selectors/gallery.selectors';
import RoundedBorderBox from '../../../shared/components/RoundedBorderBox';
import OrderSummary from '../OrderSummary';

interface Props {
  financials: OrderFinancials;
}

const CheckoutSummary = ({ financials }: Props) => {
  const steps = useSelector(selectCheckoutSteps);
  const { checkoutStepCompletion, checkoutVisitKeys } = useSelector(selectCheckoutSlice);
  const { shippingAddress, billingAddress } = useSelector(selectCheckoutData);
  const galleryMap = useSelector(selectGalleryMap);

  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });

  const hasPerOrderBG = steps.some(s => s.stepKey === 'backgroundOptions');
  const requiresShipping = steps.some(s => s.stepKey === 'shippingAddress');
  const taxAddress = requiresShipping ? shippingAddress : billingAddress;
  const useStripeTax = checkoutVisitKeys?.some(k => galleryMap[k].settings.useStripeTax);
  const taxState = useStripeTax ? taxAddress.zip : taxAddress.state;

  // NOTE: the "taxable" state depends on whether the `shippingType` is `pickup`. The expected behavior is that
  // if a shipping address is provided (therefore, `direct` shipping), then we use that state to determine the
  // tax value. Otherwise, we use the billing address state. Below we use this variable to determine the visitor's
  // selection of the state responsible for determining the tax value.

  // check if these two tax variable options are required
  // if required, they need to be complete, otherwise leave it out
  const additionalTaxStepsComplete = (['backgroundOptions', 'orderOptions'] as StepKey[]).every(
    step => !steps.some(s => s.stepKey === step) || checkoutStepCompletion[step],
  );

  // if taxState has a value and all required steps are complete/omitted, this is the final
  const isFinalCalculation = !!taxState.length && additionalTaxStepsComplete;

  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center"
      position={{ base: 'initial', md: 'sticky' }}
      top={{ base: 'initial', md: '34px' }}
    >
      <RoundedBorderBox
        paddingTop={{ base: 3, lg: 6 }}
        paddingX={6}
        width={{ base: '100%', lg: '350px' }}
        border={isMobile ? 'none' : undefined}
      >
        <OrderSummary
          financials={financials}
          hasPerOrderBG={hasPerOrderBG}
          isFinalCalculation={isFinalCalculation}
        />
      </RoundedBorderBox>
    </Flex>
  );
};

export default CheckoutSummary;
