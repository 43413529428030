import { Box, Flex, Heading, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';
import { CatalogProductNode } from 'iq-product-render';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ShopPackage } from '../../../../../shop-api-client';
import {
  CartPackage,
  CartPrintProductReq,
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectBackgroundSetMap } from '../../../../redux/selectors/background.selectors';
import { selectCartSlice } from '../../../../redux/selectors/cart.selectors';
import {
  selectActiveStep,
  selectConfiguration,
  selectPackageItemMap,
  selectShowPkgItemBGSelection,
  selectSteps,
} from '../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectInteractions } from '../../../../redux/selectors/interactions.selectors';
import {
  selectGroupedImageOptions,
  selectPriceSheet,
} from '../../../../redux/selectors/priceSheet.selectors';
import { DEFAULT_VISIT_WITH_CART } from '../../../../redux/slices/cart.slice';
import {
  setCompletedSteps,
  setEditPackage,
  setEditProduct,
} from '../../../../redux/slices/configurations.slice';
import { toggleShowFooter } from '../../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../../redux/store';
import { addCartItem, updateCartItem } from '../../../../redux/thunks/cart.thunks';
import { getRendererProducts } from '../../../../redux/thunks/catalog.thunks';
import {
  autoAddSingleSelectionRequiredPackageImageOption,
  initializeEditItem,
} from '../../../../redux/thunks/configuration.thunks';
import {
  initializeSelectAndBuy,
  loadBackgroundImage,
} from '../../../../redux/thunks/interactions.thunks';
import DisabledOverlay from '../../../../shared/components/DisabledOverlay';
import { PACKAGE_ADD_ONS } from '../../../../shared/constants';
import { REGEX_CONFIG_SUB_ITEM_STEP } from '../../../../shared/constants/regex.constants';
import useIsElemInView from '../../../../shared/hooks/useIsElemInView';
import useProductFontLoader from '../../../../shared/hooks/useProductFontLoader';
import useSearchParams from '../../../../shared/hooks/useSearchParams';
import useToast from '../../../../shared/hooks/useToast';
import { Params } from '../../../../shared/types/router';
import { formatCurrency } from '../../../../shared/utils';
import { getCartItemToOptionKey } from '../../../Carts/utils';
import CartItemDrawer from '../../CartItemDrawer';
import { PRODUCTS_GUTTER } from '../../constants';
import ProductsBreadCrumb from '../../ProductsBreadCrumb';
import { hasConfigurableImageNodes, hasImageNodesWithDisabledBg } from '../../utils';
import { REQUIRED_FIELDS } from '../constants';
import ConfigurationPreview from '../shared/ConfigurationPreview';
import BackgroundSelection from '../shared/configurationSections/BackgroundSelection';
import BYOPItemSelection from '../shared/configurationSections/BYOPItemSelection';
import ConfigurationSummary from '../shared/ConfigurationSummary';
import EditorSectionContainer from '../shared/EditorSectionContainer';
import ImageOptionEditor from '../shared/ImageOptionEditor';
import OptionalSection from '../shared/OptionalSection';
import Toolbar from '../shared/Toolbar';
import {
  getBackground,
  getBuildYourOwnStep,
  getPackageImageAssignmentStep,
  getPreOrderBackgroundsStep,
  getRequiredProductOptionsStep,
  getTextNodesStep,
  isConfigurable,
  requiresPerImageBG,
  validateTextNodes,
} from '../utils';
import PackageImageSelection from './PackageImageSelection';
import PackageItemConfiguration from './PackageItemConfiguration';

const EDITOR_WIDTH = { base: '100%', md: '45%', lg: '40%' }; // TODO: adjust for mobile as necessary
const MAX_WIDTH = 1200;
const SUMMARY_BTN_OFFSET = 72;

const PackageConfiguration = () => {
  const { accountID, currency } = useSelector(selectAccount);

  const { buyModeImage, selectedBackground } = useSelector(selectInteractions);
  const { cartMap } = useSelector(selectCartSlice);
  const { completedSteps, editPackage, editStep, optedOutProductOptions } =
    useSelector(selectConfiguration);
  const { isPreOrder, isGreenScreen } = useSelector(selectGallery);
  const { offersStatusMap, products, productNodeMap, preOrderBackgroundSelectionType } =
    useSelector(selectPriceSheet);
  const { optionalImageOptions } = useSelector(selectGroupedImageOptions);
  const activeStep = useSelector(selectActiveStep);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const packageItemMap = useSelector(selectPackageItemMap);
  const showPkgItemBGSelection = useSelector(selectShowPkgItemBGSelection);
  const steps = useSelector(selectSteps);

  const [isAddingOrUpdating, setIsAddingOrUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const [areFontsLoaded, setFonts] = useProductFontLoader();
  const { key, packageID } = useParams<Params>();
  const cartPackages = useMemo(
    () => (cartMap[key] || DEFAULT_VISIT_WITH_CART).cartPackages,
    [cartMap, key],
  );
  const { getParamsValue } = useSearchParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const shopPackage = products[packageID] as ShopPackage;
  const summaryRef = useRef<HTMLDivElement>(null);
  const isSummaryInView = useIsElemInView(
    summaryRef.current,
    summaryRef.current ? summaryRef.current.clientHeight - SUMMARY_BTN_OFFSET : 0,
  );
  const toast = useToast();

  // Search params values
  const bgFromParams = getParamsValue('bg');
  const cartPackageID = getParamsValue('cartPackageID');
  const imageFromParams = getParamsValue('image');
  const originatingKey = getParamsValue('originatingKey');

  const isSubItemActive = activeStep?.includes('cartItemID/');
  const activeCartItemID = isSubItemActive ? parseInt(activeStep!.split('/')[1]) : undefined;
  const hasAdditionalPoses = shopPackage.allowAdditionalPoses;

  const skipBgSelectionPerProduct = useMemo(() => {
    if (!editPackage || editStep) {
      return false;
    }
    const subItemIDs = editPackage.products.map(p => p.priceSheetItemID);
    let imageNodes: CatalogProductNode[] = [];
    subItemIDs.forEach(subItemID => {
      const shopSubItem = packageItemMap[subItemID];

      if (shopSubItem) {
        const productNodes = productNodeMap[shopSubItem.catalogProductID];
        if (productNodes) {
          const filteredImageNodes = productNodes?.filter(n => n.type === 'image');
          imageNodes = imageNodes.concat(filteredImageNodes);
        }
      }
    });
    return hasImageNodesWithDisabledBg(imageNodes);
  }, [editPackage, editStep, packageItemMap, productNodeMap]);

  const backgroundStep = editPackage?.id
    ? getPreOrderBackgroundsStep(editPackage.id, preOrderBackgroundSelectionType)
    : undefined;
  const hasBackgroundStep =
    !!backgroundStep && steps.includes(backgroundStep) && !skipBgSelectionPerProduct;

  const stepsMap = useMemo(
    () =>
      steps.reduce<Record<string, boolean>>((map, step) => {
        map[step] = true;
        return map;
      }, {}),
    [steps],
  );

  /**
   * Returns the ShopProduct or ShopPackage that corresponds to the given edit item.
   * Param `item` can be `editProduct` or `editPackage`, whose `priceSheetItemID` will be
   * keyed in the `products` map - OR - the item may be an `editPackage` sub-item, in which
   * case the ShopProduct data for the item will reside in the `packageItemMap`
   */
  const getShopItem = useCallback(
    (item: CreateCartPackageReq | CreateCartProductReq) =>
      products[item.priceSheetItemID] || packageItemMap[item.priceSheetItemID],
    [packageItemMap, products],
  );

  const isAllUnlocked = useCallback(
    (subItemID?: number) => {
      if (!editPackage || editStep) {
        return false;
      }

      if (subItemID) {
        const cartSubItem = editPackage.products.find(p => p.id === subItemID);
        const shopSubItem = packageItemMap[cartSubItem!.priceSheetItemID];
        const requiredOptions = shopSubItem.options.filter(o => o.requirementType === 'required');

        const hasImageNodes = hasConfigurableImageNodes(
          productNodeMap[shopSubItem.catalogProductID],
        );
        const skipBg = hasImageNodesWithDisabledBg(
          productNodeMap[shopSubItem.catalogProductID]?.filter(n => n.type === 'image'),
        );
        const hasCollectionImages =
          cartSubItem &&
          (cartSubItem.type === 'collection' || cartSubItem.type === 'imageDownload');
        const showBackgroundSelection =
          showPkgItemBGSelection && (hasImageNodes || hasCollectionImages) && !skipBg;
        const selectedBackground =
          cartSubItem && getBackground(cartSubItem, Object.values(backgroundSetMap));

        if (showBackgroundSelection && !selectedBackground) {
          return false;
        }

        if (
          steps.includes(getTextNodesStep(subItemID)) &&
          !validateTextNodes(
            (cartSubItem as CartPrintProductReq).nodes,
            productNodeMap[shopSubItem.catalogProductID],
          )
        ) {
          return false;
        }

        if (requiredOptions.length) {
          const areOptionsComplete = requiredOptions.every(
            o =>
              completedSteps[getRequiredProductOptionsStep(subItemID, o.catalogOptionGroupID)] ||
              optedOutProductOptions[getCartItemToOptionKey(subItemID, o.catalogOptionGroupID)],
          );

          if (!areOptionsComplete) {
            return false;
          }
        }

        return true;
      }

      // Since sub-item sections do not complete/collapse, they are skipped as entries
      // in `completedSteps`:
      return steps.every(step => step.match(REGEX_CONFIG_SUB_ITEM_STEP) || completedSteps[step]);
    },
    [
      backgroundSetMap,
      completedSteps,
      editPackage,
      editStep,
      optedOutProductOptions,
      packageItemMap,
      productNodeMap,
      showPkgItemBGSelection,
      steps,
    ],
  );

  // On init, sets the editProduct, fetches the full catalog product and
  // sets required & optional product options
  useEffect(() => {
    // If the editPackage is loaded and has not changed, return:
    if (isLoading || (ready && editPackage?.priceSheetItemID === parseInt(packageID))) {
      return;
    }

    // If the editPackage is loaded, and not editing an existing cart item, check whether
    // it's a locked item:
    if (!cartPackageID && editPackage) {
      const shopItem = getShopItem(editPackage);
      if (offersStatusMap[shopItem.categoryID]?.isLocked) {
        // If the item is locked, send them to the details page:
        history.replace(`/${key}/shop/${shopItem.categoryID}/product/${shopItem.id}`);
        return;
      }
    }

    const init = async () => {
      setIsLoading(true);
      if (!editPackage || editPackage.priceSheetItemID !== parseInt(packageID)) {
        let cartItem;

        if (cartPackageID) {
          cartItem = cartPackages.find(p => p.id === parseInt(cartPackageID));
        }

        if (imageFromParams && !buyModeImage) {
          dispatch(initializeSelectAndBuy(imageFromParams, parseInt(bgFromParams)));
        }

        const { valid, error } = dispatch(
          initializeEditItem({
            shopItem: shopPackage,
            cartItem,
            imageName: imageFromParams,
            backgroundID: parseInt(bgFromParams),
          }),
        );

        if (!valid) {
          return toast({ title: error });
        }
      }
      const { payload } = await dispatch(getRendererProducts([shopPackage]));

      const allNodes = (payload || []).reduce<CatalogProductNode[]>(
        (result, product) => [...result, ...product.nodes],
        [],
      );

      await setFonts(allNodes);
      setReady(true);
      setIsLoading(false);

      // NOTE: scrollTo resolves an issue where late loading large thumbnail sets
      // were causing the scroll position to be further down the window
      window.scrollTo(0, 0);
    };

    init();
  }, [
    bgFromParams,
    buyModeImage,
    cartPackageID,
    cartPackages,
    dispatch,
    editPackage,
    getShopItem,
    history,
    imageFromParams,
    isLoading,
    key,
    offersStatusMap,
    packageID,
    ready,
    setFonts,
    shopPackage,
    toast,
  ]);

  useEffect(() => {
    if (
      isPreOrder &&
      skipBgSelectionPerProduct &&
      activeStep === backgroundStep &&
      preOrderBackgroundSelectionType === 'perProduct'
    ) {
      dispatch(setCompletedSteps({ [activeStep as string]: true }));
    }
  });

  useEffect(() => {
    dispatch(autoAddSingleSelectionRequiredPackageImageOption());
  }, [dispatch]);

  // Google Analytics view_item event.
  useEffect(() => {
    window.gtag('event', 'view_item', {
      items: [
        {
          item_id: shopPackage.id,
          item_name: shopPackage.name,
          item_category: shopPackage.type,
          affiliation: accountID,
          price: shopPackage.price,
        },
      ],
    });
  }, [shopPackage, accountID]);

  useEffect(() => {
    dispatch(toggleShowFooter(false));
    return () => {
      // Reset initial `isLoading` and `ready` to prepare for the next item for configuration:
      setIsLoading(true);
      setReady(false);
      dispatch(loadBackgroundImage(null));
      dispatch(toggleShowFooter(true));
    };
  }, [dispatch]);

  // Return null if no editPackage, fonts are not ready, or if editPackage data is stale, which is possible
  // when selecting another item for configuration from the CartItemDrawer, after adding the currently
  // configured item to the cart:
  if (!editPackage || !areFontsLoaded || editPackage.priceSheetItemID !== parseInt(packageID)) {
    return null;
  }

  const getActiveEditItem = () => {
    // If there is only one item in the package, show at all times:
    if (editPackage.products.length === 1) {
      return editPackage.products[0];
    }

    if (!activeCartItemID || editPackage.id === activeCartItemID) {
      return editPackage;
    }
    return editPackage.products.find(p => p.id === activeCartItemID);
  };

  const handleSave = (updated: CreateCartPackageReq | CreateCartProductReq) => {
    if (updated.type === 'buildYourOwn' || updated.type === 'standard') {
      dispatch(setEditPackage(updated));
    } else {
      dispatch(setEditProduct(updated));
    }
  };

  const handleAddOrUpdate = async () => {
    // If not all editor sections are unlocked, visitor should not add to cart. This check
    // guards against visitor potentially deleting the overlay elem in dev tools then pressing
    // the add button when it is not valid
    if (!isAllUnlocked()) {
      return toast({ title: REQUIRED_FIELDS });
    }

    setIsAddingOrUpdating(true);
    if (cartPackageID) {
      const { valid } = await dispatch(updateCartItem(editPackage as CartPackage, key));
      if (valid) {
        // Return to the gallery from which the visitor chose to edit the cart item:
        history.push(`/${originatingKey}/carts`);
      }
    } else {
      await dispatch(addCartItem(editPackage, undefined));
    }
    setIsAddingOrUpdating(false);
  };

  const handleDrawerClose = () => {
    history.push(`/${key}/shop/${shopPackage.categoryID}/product/${editPackage.priceSheetItemID}`);
  };

  const renderIncluded = () => {
    if (!shopPackage.options.length) {
      return null;
    }

    return (
      <EditorSectionContainer
        hideBorder={!optionalImageOptions.length}
        hideToolbar
        isDisabled={!isAllUnlocked()}
        marginBottom={3}
        paddingTop={4}
      >
        <Heading fontSize="lg" marginBottom={6}>
          {PACKAGE_ADD_ONS}
        </Heading>
        <Flex direction="column" borderBottomWidth="1px" borderColor="grey.2" paddingBottom={4}>
          {shopPackage.options.map(option => (
            <ImageOptionEditor key={option.id} option={option} />
          ))}
        </Flex>
      </EditorSectionContainer>
    );
  };

  /**
   * Renders product specific configuration steps for `editProduct` or `editPackage` sub-items
   */
  const renderProductConfig = () => {
    return editPackage.products.map(product => {
      // NOTE: `true` is passed in as the `isPreOrder` parameter, regardless of whether the job
      // is pre-order, because image assignment is not required in the ProductConfigSection, so
      // below we are overriding to remove the need for image assignment as criteria

      const canEditProduct = isConfigurable(
        true,
        packageItemMap[product.priceSheetItemID],
        productNodeMap,
      );

      const canEditPerImageBG = requiresPerImageBG(
        product.type,
        preOrderBackgroundSelectionType,
        isGreenScreen,
        isPreOrder,
        Object.values(backgroundSetMap),
      );
      if (!canEditProduct && !canEditPerImageBG) {
        return null;
      }

      return (
        <PackageItemConfiguration
          key={product.id}
          completed={isAllUnlocked(product.id)}
          disabled={activeCartItemID !== product.id}
          editProduct={product}
          isCartItem={!!cartPackageID || isAllUnlocked(product.id)}
          shopProduct={packageItemMap[product.priceSheetItemID]}
          isPreOrder={isPreOrder}
        />
      );
    });
  };

  const renderEditor = () => {
    if (!ready) {
      return (
        <Flex align="center" justify="center" height="100%" width="100%">
          <Spinner />
        </Flex>
      );
    }

    return (
      <Box>
        {hasBackgroundStep && <BackgroundSelection editItem={editPackage} />}
        {stepsMap[getBuildYourOwnStep()] && (
          <BYOPItemSelection isDisabled={hasBackgroundStep && !selectedBackground} />
        )}
        {stepsMap[getPackageImageAssignmentStep()] && <PackageImageSelection />}
        {renderProductConfig()}
        {renderIncluded()}
        {optionalImageOptions.length > 0 && (
          <OptionalSection
            optionalImageOptions={optionalImageOptions}
            useOther={!!shopPackage.options?.length}
            onSave={handleSave}
            editItem={editPackage}
            isDisabled={!isAllUnlocked()}
          />
        )}
        <Box ref={summaryRef} position="relative">
          <DisabledOverlay isDisabled={!isAllUnlocked()} />
          <ConfigurationSummary
            hasAdditionalPoses={hasAdditionalPoses}
            hasBackgrounds={hasBackgroundStep || showPkgItemBGSelection}
            hasProductOptions={!!shopPackage.options.length}
            isAddingOrUpdating={isAddingOrUpdating}
            onAddToCart={handleAddOrUpdate}
            showUpdateText={!!cartPackageID}
          />
        </Box>
      </Box>
    );
  };

  const renderPreview = () => {
    const activeEditItem = getActiveEditItem();
    const activeShopItem = getShopItem(activeEditItem || editPackage);

    return <ConfigurationPreview editItem={activeEditItem} shopItem={activeShopItem} />;
  };

  return (
    <Flex align="center" direction="column" height="100%" paddingX={`${PRODUCTS_GUTTER}px`}>
      <CartItemDrawer onClose={handleDrawerClose} />
      <Flex direction={{ base: 'column', md: 'row' }} maxWidth={`${MAX_WIDTH}px`} width="100%">
        <Flex
          alignSelf="flex-start"
          bottom={0}
          direction="column"
          height={{ base: 'fit-content', md: '100vh' }}
          position={{ base: 'relative', md: 'sticky' }}
          top={0}
          width={{ base: '100%', md: '55%', lg: '60%' }}
        >
          <ProductsBreadCrumb showBuy />
          {renderPreview()}
        </Flex>
        <Box
          paddingBottom="150px"
          paddingTop={{ base: 6, md: '90px' }}
          paddingX={isMobile ? 0 : '20px'}
          width={EDITOR_WIDTH}
        >
          <Heading size="lg">
            {intl.formatMessage(
              {
                id: 'packageConfiguration.buyProduct',
                defaultMessage: 'Buy {product}',
              },
              {
                product: shopPackage.name,
              },
            )}
          </Heading>
          <Text color="brand" fontSize="sm">
            {formatCurrency(shopPackage.price, currency)}
          </Text>
          {renderEditor()}
        </Box>
      </Flex>
      <Toolbar
        isAddingOrUpdating={isAddingOrUpdating}
        onClick={handleAddOrUpdate}
        show={!isSummaryInView && isAllUnlocked()}
        showUpdateText={!!cartPackageID}
      />
    </Flex>
  );
};

export default PackageConfiguration;
