import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { ShopPackage } from '../../../../../../../shop-api-client';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import {
  selectGroupedImageOptions,
  selectPriceSheet,
} from '../../../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { autoAddSingleSelectionRequiredPackageImageOption } from '../../../../../../redux/thunks/configuration.thunks';
import { AVAILABLE_ADD_ONS, PACKAGE_ADD_ONS } from '../../../../../../shared/constants';
import { NEXT_SUMMARY } from '../../../constants';
import ImageOptionEditor from '../../../shared/ImageOptionEditor';
import useConfigurationRouter from '../../../useConfigurationRouter';
import PackageSummaryPreview from '../../EditorPreview/PackageSummaryPreview';
import EditorSidebar from '../../EditorSidebar';
import PackageFooter from '../../PackageFooter';

const ImageAddOns = () => {
  const { editPackage } = useAppSelector(selectConfiguration);
  const { optionalImageOptions } = useAppSelector(selectGroupedImageOptions);
  const { products } = useAppSelector(selectPriceSheet);
  const { routeToWizardStep } = useConfigurationRouter();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(autoAddSingleSelectionRequiredPackageImageOption());
  }, [dispatch]);

  if (!editPackage) {
    return null;
  }

  const shopPackage = products[editPackage.priceSheetItemID] as ShopPackage;

  const handleBack = () => routeToWizardStep('customize');
  const handleNext = () => routeToWizardStep('summary');

  const renderAddons = () => (
    <>
      <Heading fontSize="lg" marginBottom={6}>
        {AVAILABLE_ADD_ONS}
      </Heading>
      <Flex direction="column" marginBottom={6}>
        {optionalImageOptions.map(option => (
          <ImageOptionEditor key={option.id} option={option} />
        ))}
      </Flex>
    </>
  );

  const renderIncluded = () => {
    if (!shopPackage.options.length) {
      return null;
    }

    return (
      <>
        <Heading fontSize="lg" marginBottom={6}>
          {PACKAGE_ADD_ONS}
        </Heading>
        <Flex direction="column" borderBottomWidth="1px" borderColor="grey.2" marginBottom={6}>
          {shopPackage.options.map(option => (
            <ImageOptionEditor key={option.id} option={option} />
          ))}
        </Flex>
      </>
    );
  };

  return (
    <>
      <Flex overflow="hidden" direction={{ base: 'column', md: 'row' }}>
        <PackageSummaryPreview />
        <EditorSidebar>
          <Flex direction="column" grow={1} padding={4}>
            {renderIncluded()}
            {renderAddons()}
          </Flex>
        </EditorSidebar>
      </Flex>
      <PackageFooter nextLabel={NEXT_SUMMARY} onBack={handleBack} onNext={handleNext} />
    </>
  );
};

export default ImageAddOns;
