import { Box, Button, CloseButton, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react';
import { CatalogProductNode } from 'iq-product-render';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { ShopProductPrint } from '../../../../../../../shop-api-client';
import {
  CartImageNodeReq,
  CartPrintProduct,
  CartPrintProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../../shop-api-client/models/ShopProductNodes';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../../../../redux/selectors/visitor.selectors';
import {
  setCompletedSteps,
  setEditStep,
  setShowMultiNodeModal,
} from '../../../../../../redux/slices/configurations.slice';
import { toggleShowFooter, toggleShowNav } from '../../../../../../redux/slices/interactions.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { Step } from '../../../../../../shared/components/ImageStepHeadings/ImageStepHeadings';
import Thumbnail from '../../../../../../shared/components/Thumbnail';
import ProductPreview from '../../../../ProductPreview';
import { PRODUCTS_GUTTER } from '../../../../constants';
import { EDITOR_SIDEBAR_WIDTH } from '../../../PackageWizard/EditorSidebar';
import {
  DEFAULT_IMG_MSG,
  FONTS,
  PHOTO_NUMBER,
  PREVIEW,
  SELECT_PHOTO_SLOT,
  SELECT_YOUR_PHOTOS,
} from '../../../constants';
import { getImageMultiNodeStep } from '../../../utils';
import CompletedSection from '../../CompletedSection';
import MultiNodeMissingImagesModal from '../../MultiNodeMissingImagesModal';
import MultiNodeConfiguration from './MultiNodeConfiguration';
import MultiNodeHeader from './MultiNodeHeader';

interface Props {
  catalogNodes: ShopImageNodeWithReqType[];
  complete: boolean;
  editProduct: CartPrintProductReq | CartPrintProduct;
  onLeave?(): void;
  onSave?(): void;
  packageMode?: boolean;
  poseLimit?: number;
  poseMap?: Record<string, number[]>;
  shopProduct: ShopProductPrint;
}

const ImageNodeMulti = ({
  complete,
  catalogNodes,
  editProduct,
  onLeave,
  onSave,
  packageMode,
  poseLimit,
  poseMap = {},
  shopProduct,
}: Props) => {
  const stepName = useMemo(() => getImageMultiNodeStep(editProduct.id!), [editProduct]);

  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const currentVisitKey = useAppSelector(selectCurrentVisitKey);
  const { editNodeID } = useAppSelector(selectConfiguration);
  const {
    images,
    settings: { disableCropping },
    type,
  } = useAppSelector(selectGallery);
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState<Step>('photo');
  const [fullPreview, setFullPreview] = useState(false);

  // Preview width & height:
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);

  const [isMobile] = useMediaQuery('(max-width: 48em)', { ssr: false });
  const history = useHistory();

  useEffect(() => {
    if (!complete && !packageMode) {
      dispatch(toggleShowFooter(false));
      dispatch(toggleShowNav(false));
    }

    return () => {
      if (!complete && !packageMode) {
        dispatch(toggleShowFooter(true));
        dispatch(toggleShowNav(true));
      }
    };
  }, [complete, dispatch, packageMode]);

  useEffect(() => {
    const handleResize = () => {
      if (ref) {
        setMaxWidth(ref.clientWidth);
        setMaxHeight(ref.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [editNodeID, ref]);

  // Convenient for type flow, should probably redirect instead of a null return
  if (editProduct?.type !== 'product') {
    return null;
  }

  // Grab all iamge nodes
  const nodes = editProduct.nodes.filter(node => node.type === 'image') as CartImageNodeReq[];

  const handleEdit = () => {
    dispatch(setEditStep(stepName));
  };

  const handleLeave = () => {
    if (onLeave) {
      onLeave();
    } else {
      history.push(`/${currentVisitKey}/shop/${shopProduct.categoryID}/product/${shopProduct.id}`);
    }
  };

  const handleNodeCrop = (catalogNode: CatalogProductNode) => {
    const node = nodes.find(n => n.catalogNodeID === catalogNode.id);

    if (!disableCropping && node?.imageInternalName) {
      setActiveStep('crop');
    }
  };

  const handlePreviewClose = () => {
    setFullPreview(false);
  };

  const handlePreviewOpen = () => {
    setFullPreview(true);
  };

  const handleSave = () => {
    const missingImages = nodes.filter(node => !node.imageInternalName);

    if (missingImages.length) {
      dispatch(setShowMultiNodeModal(true));
    } else {
      dispatch(setEditStep(null));
      dispatch(setCompletedSteps({ [stepName]: true }));

      if (onSave) {
        onSave();
      }
    }
  };

  const renderCompleteSection = () => {
    return (
      <CompletedSection heading={SELECT_YOUR_PHOTOS} onEdit={handleEdit}>
        <Flex direction="column">{nodes.map(renderCompletedNode)}</Flex>
      </CompletedSection>
    );
  };

  const renderCompletedNode = (node: CartImageNodeReq, index: number) => {
    const { imageInternalName, backgroundID } = node;
    const background = backgroundID ? backgroundsMap[backgroundID] : null;
    const image = imageInternalName ? images[imageInternalName] : null;
    const catalogNode = catalogNodes.find(catalogNode => catalogNode.id === node.catalogNodeID);

    return (
      <Flex
        key={node.catalogNodeID}
        alignItems="flex-start"
        direction="row"
        width="100%"
        marginBottom={4}
        _last={{ marginBottom: 0 }}
      >
        <Thumbnail
          background={background?.sources.thumb}
          imageWidth="60px"
          src={image?.sources.thumb}
        />
        <Flex maxWidth="250px" direction="column" marginLeft={6} paddingY={2}>
          <Flex>
            <Heading fontSize="14px" lineHeight="inherit">
              {PHOTO_NUMBER}
              {index + 1}:
            </Heading>
            <Text fontSize="14px" noOfLines={1} marginLeft={1}>
              {node.imageDisplayName}
            </Text>
          </Flex>
          {type !== 'standard' && catalogNode?.defaultImage && (
            <Text as="i" fontSize="xs" marginTop={1}>
              {DEFAULT_IMG_MSG}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  };

  const renderConfiguration = () => {
    if (!editNodeID) {
      return null;
    }

    return (
      <MultiNodeConfiguration
        activeStep={activeStep}
        catalogNodes={catalogNodes}
        editProduct={editProduct}
        nodes={nodes}
        poseLimit={poseLimit}
        poseMap={poseMap}
        setActiveStep={setActiveStep}
        shopProduct={shopProduct}
      />
    );
  };

  const renderPreview = () => {
    return (
      <Flex
        direction="column"
        flex={1}
        marginX={`${2 * PRODUCTS_GUTTER}px`}
        maxWidth={{
          base: '100%',
          md: `calc(100vw - ${!editNodeID ? 0 : EDITOR_SIDEBAR_WIDTH + 2 * PRODUCTS_GUTTER}px)`,
        }}
        paddingTop={isMobile ? '58px' : '30px'}
        ref={flexRef => setRef(flexRef)}
      >
        {isMobile && (
          <Button color="brand" marginLeft="auto" onClick={handlePreviewOpen} variant="link">
            {PREVIEW}
          </Button>
        )}
        <Flex marginY="15px" justifyContent="center">
          <ProductPreview
            boxShadow="0px 0px 10px 2px #00000014"
            disableMiniPreview
            editProduct={editProduct}
            hidePreviewText
            maxPreviewHeight={isMobile ? undefined : maxHeight * 0.85}
            maxPreviewWidth={maxWidth * 0.85}
            onDoubleClickNode={handleNodeCrop}
            shopProduct={shopProduct}
            unlockedNodes="image"
          />
        </Flex>
        {!editNodeID && <Box textAlign="center">{SELECT_PHOTO_SLOT}</Box>}
      </Flex>
    );
  };

  if (complete) {
    return renderCompleteSection();
  }

  if (fullPreview) {
    return (
      <Flex direction="column" paddingX={`${2 * PRODUCTS_GUTTER}px`}>
        <CloseButton
          alignSelf="flex-end"
          aria-label="Close"
          onClick={handlePreviewClose}
          marginTop={4}
        />
        <Text size="sm" marginY={3} textAlign="center">
          {FONTS}
        </Text>
        <ProductPreview
          disableMiniPreview
          editProduct={editProduct}
          hidePreviewText
          isFinalRender
          isInteractive={false}
          shopProduct={shopProduct}
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column" flex={1}>
      <MultiNodeHeader
        handleBack={handleLeave}
        handleSave={handleSave}
        handlePreview={handlePreviewOpen}
      />
      {isMobile ? (
        <>
          {renderPreview()}
          {renderConfiguration()}
        </>
      ) : (
        <Flex direction="row" flex={1} paddingTop="58px">
          {renderPreview()}
          {renderConfiguration()}
        </Flex>
      )}
      <MultiNodeMissingImagesModal
        editProduct={editProduct}
        onDoubleClickNode={handleNodeCrop}
        onLeave={handleLeave}
        shopProduct={shopProduct}
      />
    </Flex>
  );
};

export default ImageNodeMulti;
