import { Flex, Icon, Link, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineExclamation } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import RoundedBorderBox from '../../../../shared/components/RoundedBorderBox';
import { Params } from '../../../../shared/types/router';
import { convertToLocalTimeZone } from '../../../../shared/utils';
import { getDueDateEOD } from '../../../Yearbook/yearbook.utils';

const YearbookAlert = () => {
  const { yearbookDueDate } = useSelector(selectGallery);
  const { key } = useParams<Params>();
  const intl = useIntl();

  if (!yearbookDueDate) {
    return null;
  }

  const dueDateEOD = getDueDateEOD(yearbookDueDate);

  return (
    <RoundedBorderBox
      alignItems={['initial', 'center']}
      borderColor={{ base: 'grey.1', md: 'grey.2' }}
      display="flex"
      marginBottom={4}
      paddingBottom={2}
      paddingTop={{ base: 3, md: 2 }}
      paddingX={5}
    >
      <Icon as={AiOutlineExclamation} fontSize="25px" marginTop={[0.5, 0]} />

      <Flex direction={['column', 'initial']} alignItems={['initial', 'center']} flex={1}>
        <Text fontSize="sm" marginLeft={5} marginRight={[0, 5]} data-test="yearbook-alert-text">
          {intl.formatMessage(
            {
              id: 'cart.selectYearbook',
              defaultMessage: 'Select a photo for the yearbook before {date} at {time}',
            },
            {
              date: convertToLocalTimeZone(dueDateEOD, 'MM/DD/YY'),
              time: convertToLocalTimeZone(dueDateEOD, 'h:mm a z'),
            },
          )}
        </Text>
        <Spacer marginBottom={[0.5, 0]} />
        <Link
          as={RouterLink}
          data-test="yearbook-alert-choose-photos-link"
          marginX={5}
          to={`/${key}/yearbook`}
          variant="underline"
        >
          {intl.formatMessage({
            id: 'cart.choosePhotos',
            defaultMessage: 'Choose Photos',
          })}
        </Link>
      </Flex>
    </RoundedBorderBox>
  );
};

export default YearbookAlert;
