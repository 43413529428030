import { ImageNode } from 'iq-product-render';
import { CartImageNodeCrop } from '../../../../../../shop-api-client/models/Cart';

export interface Coordinates {
  x: number;
  y: number;
}

export interface Dimensions {
  h: number;
  w: number;
}

export const DEFAULT_COORDINATES = { x: 0, y: 0 };
export const DEFAULT_DIMENSIONS = { w: 0, h: 0 };

export const getScaledHeight = (aspectRatio: number, targetW: number) => targetW / aspectRatio;
export const getScaledWidth = (aspectRatio: number, targetH: number) => targetH * aspectRatio;

export const getDistance = (a: Coordinates, b: Coordinates) => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;

  return Math.sqrt(dx * dx + dy * dy);
};

export const getPercentChangeNewValue = (
  percentChange: number,
  baseValue: number,
  currentValue: number,
) => {
  if (percentChange === 0) {
    return currentValue;
  }
  return percentChange * Math.abs(baseValue) + currentValue;
};

export const getPercentChange = (a: number, b: number) => {
  return (b - a) / Math.abs(a);
};

export const rotatoMatrix: Record<number, number[]> = {
  0: [1, 1],
  90: [1, -1],
  180: [-1, -1],
  270: [-1, 1],
  360: [1, 1],
};

export const initializeCropValues = (
  imageNode: ImageNode,
  image: HTMLImageElement,
  flipSingleImageNode?: boolean,
  initialCrop?: CartImageNodeCrop,
  maxH?: number,
  maxW?: number,
) => {
  const orientation = initialCrop?.orientation || 0;
  const rotation = flipSingleImageNode ? 360 - orientation : orientation;

  // TODO: Revisit these margins and double check if we can safely remove them
  // To double check we will need a variety of aspect ratios to make sure there
  // is no weird behavior if we put a 1:1 AR on a 8:10 node etc.
  // max node  = max h/w - a bit of a buffer
  let maxNodeW = maxW || window.innerWidth;
  let maxNodeH = maxH || window.innerHeight;
  if (flipSingleImageNode && rotation % 180) {
    maxNodeH = (maxW || window.innerWidth) - 25;
    maxNodeW = (maxH || window.innerHeight) - 25;
  }

  const imageNodeAR = imageNode.width / imageNode.height;

  // get size of crop box
  // attempt to maximize space available
  let imageNodeW = 0;
  let imageNodeH = 0;

  // if node is portrait
  if (imageNodeAR <= 1) {
    // get projected H using max W
    const scaledHByW = getScaledHeight(imageNodeAR, Math.min(maxNodeW, 500));
    // cap the projected height (whichever value is smallest)
    imageNodeH = Math.min(scaledHByW, image.height, maxNodeH);
    // get final W
    imageNodeW = imageNodeH * imageNodeAR;
  }
  //if node is landscape
  else if (imageNodeAR > 1) {
    const scaledWByH = getScaledWidth(imageNodeAR, Math.min(maxNodeH, 500));
    imageNodeW = Math.min(scaledWByH, image.width, maxNodeW);
    imageNodeH = imageNodeW / imageNodeAR;
  }

  // Find the dimension to scale by when not rotated (0, 180 degrees)
  let widthRatio = imageNodeW / image.width;
  let heightRatio = imageNodeH / image.height;
  const scaleFactor = Math.max(widthRatio, heightRatio);

  // Find the dimensions to scale by when rotated (90, 270 degrees)
  widthRatio = imageNodeW / image.height;
  heightRatio = imageNodeH / image.width;
  const scaleFactor90 = Math.max(widthRatio, heightRatio);

  // calculate offset based on scaled Image size that is fitted into node
  const scaledToFitImg = {
    w: image.width * scaleFactor,
    h: image.height * scaleFactor,
  };

  const initialOffset = {
    x: -(scaledToFitImg.w - imageNodeW) / 2,
    y: -(scaledToFitImg.h - imageNodeH) / 2,
  };

  // Now if there's an initial crop passed in, calculate the
  // scale, rotation and offset from it:
  const radianRotation = rotation ? rotation * (Math.PI / 180) : null;
  let scale = initialCrop ? (imageNodeW * 100) / (initialCrop.cropW * image.width) : scaleFactor;
  let translation: Coordinates | null = null;

  if (initialCrop) {
    if (rotation === 90) {
      translation = { x: imageNodeW, y: 0 };
    } else if (rotation === 180) {
      translation = { x: imageNodeW, y: imageNodeH };
    } else if (rotation === 270) {
      translation = { x: 0, y: imageNodeH };
    }

    let offsetX = (initialCrop.cropX * image.width * scale) / 100 - imageNodeW / 2;
    let offsetY = (initialCrop.cropY * image.height * scale) / 100 - imageNodeH / 2;

    if (rotation % 180) {
      scale = (imageNodeH * 100) / (initialCrop.cropW * image.width);
      offsetX = (initialCrop.cropX * image.width * scale) / 100 - imageNodeH / 2;
      offsetY = (initialCrop.cropY * image.height * scale) / 100 - imageNodeW / 2;
    }

    initialOffset.x = -offsetX;
    initialOffset.y = -offsetY;
  }

  return {
    cropSize: { w: imageNodeW, h: imageNodeH },
    initialOffset,
    initialRotation: rotation,
    initialRadianRotation: radianRotation,
    initialScale: scale,
    initialTranslation: translation,
    scaleFactor,
    scaleFactor90,
  };
};

/**
 * Calculates the size of the image node in both landscape and portrait views for single node products
 * //TODO: see if shared logic/functions between the two can be cleaned up further
 */
export const initializeCropValuesSingleNode = (
  imageNode: ImageNode,
  image: HTMLImageElement,
  initialCrop?: CartImageNodeCrop,
  maxH?: number,
  maxW?: number,
) => {
  const orientation = initialCrop?.orientation || 0;
  const rotation = orientation;

  const maxNodeW = maxW || window.innerWidth;
  const maxNodeH = maxH || window.innerHeight;

  const imageNodeAR = imageNode.width / imageNode.height;

  // calculate the horizontal and vertical size of the image node to maximize given bounding H/W
  let imageNodeW = 0;
  let imageNodeH = 0;
  // Note: imageNodeW90 = original imageNodeH, imageNodeH90 = imageNodeW
  let imageNodeW90 = 0;
  let imageNodeH90 = 0;

  if (maxH) {
    imageNodeH = Math.min(maxNodeH, 500);
    imageNodeW = imageNodeH * imageNodeAR;
    imageNodeW90 = Math.min(maxNodeH, 500);
    imageNodeH90 = imageNodeW90 / imageNodeAR;
  } else if (maxW) {
    imageNodeW = Math.min(maxNodeW, 500);
    imageNodeH = imageNodeW / imageNodeAR;
    imageNodeH90 = Math.min(maxNodeW, 500);
    imageNodeW90 = imageNodeH90 * imageNodeAR;
  }

  // Find the dimension to scale by when not rotated (0, 180 degrees)
  let widthRatio = imageNodeW / image.width;
  let heightRatio = imageNodeH / image.height;
  const scaleFactor = Math.max(widthRatio, heightRatio);

  // Find the dimensions to scale by when rotated (90, 270 degrees)
  widthRatio = imageNodeH90 / image.width;
  heightRatio = imageNodeW90 / image.height;
  const scaleFactor90 = Math.max(widthRatio, heightRatio);

  // calculate offset based on scaled Image size that is fitted into node
  const scaledToFitImg = {
    w: image.width * scaleFactor,
    h: image.height * scaleFactor,
  };

  const initialOffset = {
    x: -(scaledToFitImg.w - imageNodeW) / 2,
    y: -(scaledToFitImg.h - imageNodeH) / 2,
  };

  let scale = rotation % 180 ? scaleFactor90 : scaleFactor;
  if (initialCrop) {
    scale = (imageNodeW * 100) / (initialCrop.cropW * image.width);
    let offsetX = (initialCrop.cropX * image.width * scale) / 100 - imageNodeW / 2;
    let offsetY = (initialCrop.cropY * image.height * scale) / 100 - imageNodeH / 2;

    if (rotation % 180) {
      scale = (imageNodeH90 * 100) / (initialCrop.cropW * image.width);
      offsetX = (initialCrop.cropX * image.width * scale) / 100 - imageNodeH90 / 2;
      offsetY = (initialCrop.cropY * image.height * scale) / 100 - imageNodeW90 / 2;
    }

    initialOffset.x = -offsetX;
    initialOffset.y = -offsetY;
  }

  return {
    cropSize: { w: imageNodeW, h: imageNodeH },
    cropSize90: { w: imageNodeH90, h: imageNodeW90 },
    initialOffset,
    initialRotation: rotation,
    initialScale: scale,
    scaleFactor,
    scaleFactor90,
  };
};

export const getCropMobileBuffer = (
  buffer = { 600: 140, 700: 100, base: 50 },
  landScapeBuffer?: Record<string | number, number>,
) => {
  for (const [minHeight, margin] of Object.entries(landScapeBuffer || buffer)) {
    if (minHeight !== 'base' && window.innerHeight < parseInt(minHeight)) {
      return margin;
    }
  }

  return landScapeBuffer?.base || buffer.base;
};
