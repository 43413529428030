import { Box, BoxProps, TypographyProps, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import DisabledOverlay from '../../../../../shared/components/DisabledOverlay';
import SaveButton from '../../../../../shared/components/SaveButton';
import { Z_INDEX_TWO } from '../../../../../shared/constants';
import useIsElemInView from '../../../../../shared/hooks/useIsElemInView';
import DetailsSection from '../../../ProductDetails/DetailsSection';
import { SAVE_AND_CONTINUE } from '../../constants';
import Toolbar from '../Toolbar';

interface Props extends BoxProps {
  children: ReactNode;
  'data-test'?: string;
  heading?: string;
  headingFontSize?: TypographyProps['fontSize'];
  hideBorder?: boolean;
  hideSaveButton?: boolean;
  hideToolbar?: boolean;
  isDisabled?: boolean;
  onSave?(): void;
  scrollToTop?: boolean;
}

const EditorSectionContainer = ({
  children,
  'data-test': dataTest,
  heading,
  headingFontSize = 'lg',
  hideBorder,
  hideSaveButton,
  hideToolbar,
  isDisabled,
  onSave,
  scrollToTop,
  ...rest
}: Props) => {
  const [saveRef, setSaveRef] = useState<HTMLDivElement | null>(null);

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const isSaveInView = useIsElemInView(saveRef, 0, true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToTop) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToTop]);

  return (
    <Box position="relative" marginTop={10}>
      {/* The box below offsets `scrollIntoView` to give a greater buffer at the top of the parent */}
      <Box ref={ref} position="absolute" top={isMobile ? -100 : -5} />
      <DisabledOverlay isDisabled={isDisabled} zIndex={Z_INDEX_TWO} />
      <DetailsSection
        heading={heading}
        headingFontSize={headingFontSize}
        hideBorder={hideBorder}
        {...rest}
      >
        {children}
        {!hideSaveButton && !!onSave && (
          <Box marginY={4} ref={ref => setSaveRef(ref)}>
            <SaveButton data-test={dataTest} isDisabled={isDisabled} onSave={onSave} />
          </Box>
        )}
      </DetailsSection>
      <Toolbar
        isAddingOrUpdating={false}
        label={SAVE_AND_CONTINUE}
        onClick={onSave}
        show={!hideToolbar && !!onSave && !isSaveInView}
        showUpdateText={false}
      />
    </Box>
  );
};

export default EditorSectionContainer;
