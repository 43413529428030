import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCartSlice } from '../../../../redux/selectors/cart.selectors';
import { selectGalleryMap } from '../../../../redux/selectors/gallery.selectors';
import CartsErrorModal from '../../../../shared/components/CartsErrorModal';
import MinOrderAmountSection from '../../../../shared/components/CartsErrorModal/MinOrderAmountSection';
import { CONTINUE_SHOPPING } from '../../../../shared/constants/labels.constants';
import { Params } from '../../../../shared/types/router';
import { formatCurrency } from '../../../../shared/utils';

interface Props {
  errorVisitKeys: string[];
  isOpen: boolean;
  onClose(showError: boolean): void;
}

const MinOrderAmountModal = ({ errorVisitKeys, isOpen, onClose }: Props) => {
  const { cartFinancials } = useSelector(selectCartSlice);
  const { currency } = useSelector(selectAccount);
  const galleryMap = useSelector(selectGalleryMap);

  const { key } = useParams<Params>();
  const history = useHistory();
  const intl = useIntl();

  const { minOrderAmount, subtotal } = errorVisitKeys.reduce(
    (result, visitKey) => {
      const { subtotal, orderOptionFees, imageOptionFees, backgroundFees } =
        cartFinancials[visitKey];
      const thisMinOrderAmount = galleryMap[visitKey].settings.minOrderAmount;

      if (thisMinOrderAmount > result.minOrderAmount) {
        result.minOrderAmount = thisMinOrderAmount;
      }

      result.subtotal += subtotal + orderOptionFees + imageOptionFees + backgroundFees;
      return result;
    },
    { minOrderAmount: 0, subtotal: 0 },
  );
  const isSingleCart = errorVisitKeys.length === 1;
  const currentCartOnly = isSingleCart && errorVisitKeys[0] === key;

  const heading = intl.formatMessage({
    id: 'minOrderAmountModal.amountNotMet',
    defaultMessage: 'Minimum Order Amount Not Met',
  });
  const multiSubHeading = intl.formatMessage(
    {
      id: 'minOrderAmountModal.multiCart',
      defaultMessage:
        'This checkout requires a minimum order amount of {minOrderAmount}. Add {diff} worth of items from {gallery} before checking out.',
    },
    {
      gallery: isSingleCart ? 'the following gallery' : 'any of the following galleries',
      minOrderAmount: formatCurrency(minOrderAmount, currency),
      diff: formatCurrency(minOrderAmount - subtotal, currency),
    },
  );
  const singleSubHeading = intl.formatMessage(
    {
      id: 'minOrderAmountModal.singleCart',
      defaultMessage:
        'This gallery has a minimum order amount of {minOrderAmount}. Add {diff} worth of items before checking out.',
    },
    {
      minOrderAmount: formatCurrency(minOrderAmount, currency),
      diff: formatCurrency(minOrderAmount - subtotal, currency),
    },
  );

  const handleRouteToShop = (visitKey: string) => history.push(`/${visitKey}/shop/all`);

  return (
    <CartsErrorModal
      actionLabel={CONTINUE_SHOPPING}
      heading={heading}
      subHeading={currentCartOnly ? singleSubHeading : multiSubHeading}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={currentCartOnly ? () => handleRouteToShop(key) : undefined}
    >
      {!currentCartOnly &&
        errorVisitKeys.map((visitkey, idx) => (
          <MinOrderAmountSection
            key={visitkey}
            onButtonClick={handleRouteToShop}
            showDivider={idx !== errorVisitKeys.length - 1}
            visitKey={visitkey}
          />
        ))}
    </CartsErrorModal>
  );
};

export default MinOrderAmountModal;
