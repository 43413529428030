import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCartItemCount, selectCartSlice } from '../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../redux/store';
import { GO_TO_GALLERY, SUBJECT_WITH_COLON } from '../../../constants/carts.constants';
import { formatCurrency } from '../../../utils';

interface Props {
  onButtonClick(key: string): void;
  showDivider?: boolean;
  visitKey: string;
}

const MinOrderAmountSection = ({ onButtonClick, showDivider, visitKey }: Props) => {
  const { cartFinancials } = useSelector(selectCartSlice);
  const { currency } = useAppSelector(selectAccount);
  const { title, subjectID } = useAppSelector(state => selectGallery(state, visitKey));
  const count = useAppSelector(state => selectCartItemCount(state, [visitKey]));
  const intl = useIntl();

  const { subtotal, orderOptionFees, imageOptionFees, backgroundFees } = cartFinancials[visitKey];
  const cartSubtotal = subtotal + orderOptionFees + imageOptionFees + backgroundFees;

  const handleClick = () => onButtonClick(visitKey);

  return (
    <>
      <Flex justifyContent="space-between" marginY={4} paddingX={2} textAlign="left" width="100%">
        <Flex direction="column">
          <Heading fontSize="md" marginBottom={2}>
            {title}
          </Heading>
          {subjectID && (
            <Text fontSize="sm">
              {SUBJECT_WITH_COLON}
              {subjectID}
            </Text>
          )}
          <Text fontFamily="heading" fontSize="sm" marginY={1}>
            {intl.formatMessage(
              {
                id: 'minOrderAmountSection.itemsAdded',
                defaultMessage: '{count} {item} added / total: {amount}',
              },
              {
                count,
                item: count === 1 ? 'item' : 'items',
                amount: formatCurrency(cartSubtotal, currency),
              },
            )}
          </Text>
        </Flex>
        <Button onClick={handleClick} variant="black">
          {GO_TO_GALLERY}
        </Button>
      </Flex>
      {showDivider && <Divider borderColor="grey.2" />}
    </>
  );
};

export default MinOrderAmountSection;
