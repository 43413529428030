import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaRegUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectAllGalleries } from '../../../../redux/selectors/gallery.selectors';
import NotificationPin from '../../../../shared/components/NotificationPin';
import { NAVIGATION_Z_INDEX } from '../../../../shared/constants';
import MultiGalleryTooltip from '../../MultiGalleryButton/MultiGalleryTooltip';
import MyGalleries from './MyGalleries';
import UserWelcome from './UserWelcome';

const DesktopDropdown = () => {
  const galleries = useSelector(selectAllGalleries);
  const filteredGalleries = galleries.filter(g => g.priceSheetID);

  return (
    <Menu offset={[45, 2]} autoSelect={false}>
      <MultiGalleryTooltip>
        <MenuButton
          as={Button}
          paddingX="2px"
          variant="nav"
          minWidth="35px"
          position="relative"
          data-test="desktop-nav-user-icon"
        >
          {filteredGalleries.length > 1 && (
            <NotificationPin
              backgroundColor="grey.9"
              borderRadius="8px"
              content={filteredGalleries.length}
              height="15px"
              left="21px"
              position="absolute"
              size="xs"
              top="3px"
              width="initial"
            />
          )}

          <Icon as={FaRegUser} fontSize="xl" marginTop="2px" />
        </MenuButton>
      </MultiGalleryTooltip>

      <MenuList
        border="none"
        boxShadow="rgb(0 0 0 / 20%) 0px 0px 6px"
        paddingBottom="25px"
        paddingX="25px"
        width="410px"
        zIndex={NAVIGATION_Z_INDEX}
      >
        <UserWelcome />
        <MyGalleries />
      </MenuList>
    </Menu>
  );
};

export default DesktopDropdown;
