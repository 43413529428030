import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { OVERLAY_Z_INDEX } from '../../constants';
import ImageBadge from '../ImageBadge';

export interface ActiveImageOverlayProps extends BoxProps {
  badgeSize?: string;
  hideIcon?: boolean;
  icon?: IconType;
  iconFontSize?: string;
}

const ActiveImageOverlay = ({
  badgeSize,
  hideIcon = false,
  icon = FaCheck,
  iconFontSize,
  ...rest
}: ActiveImageOverlayProps) => (
  <Box
    backgroundColor="IQHIGHLIGHT"
    borderColor="IQBLUE"
    borderWidth="2px"
    boxSizing="border-box"
    height="100%"
    position="absolute"
    width="100%"
    zIndex={OVERLAY_Z_INDEX}
    {...rest}
  >
    {hideIcon || (
      <ImageBadge
        badgeSize={badgeSize}
        bottom="0"
        fontSize={iconFontSize}
        icon={icon}
        mx="2%"
        my="2%"
        position="absolute"
        right="0"
      />
    )}
  </Box>
);

export default ActiveImageOverlay;
