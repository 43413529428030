import { Flex, Heading, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  selectCartMap,
  selectImageToImageOptionLookup,
} from '../../../redux/selectors/cart.selectors';
import { selectGallery, selectGalleryMap } from '../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../redux/store';
import { SUBJECT_WITH_COLON } from '../../../shared/constants';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { sort } from '../../../shared/utils';
import { countCartItems } from '../utils';
import CartImageOptionSection from './CartImageOptionSection';
import CartItemSection from './CartItemSection';

interface Props {
  visitKey: string;
}

const Cart = ({ visitKey }: Props) => {
  const { images, isLoading, isPreOrder, priceSheetID, subjectID, title } = useAppSelector(state =>
    selectGallery(state, visitKey),
  );
  const cartMap = useAppSelector(selectCartMap);
  const galleryMap = useAppSelector(selectGalleryMap);
  const imageToOptionLookup = useAppSelector(state =>
    selectImageToImageOptionLookup(state, visitKey),
  );

  const { width } = useWindowSize();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const cartWithItemsKeys = useMemo(
    () =>
      Object.entries(cartMap).reduce<string[]>((result, [key, cart]) => {
        const cartItemCount = countCartItems(cart);
        if (cartItemCount) {
          result.push(key);
        }
        return result;
      }, []),
    [cartMap],
  );

  const cart = cartMap[visitKey];
  const cartProducts = [...cart.cartProducts].sort((a, b) => sort(a.createdAt, b.createdAt));
  const cartPackages = [...cart.cartPackages].sort((a, b) => sort(a.createdAt, b.createdAt));
  const cartImageOptions = [...cart.cartImageOptions].sort((a, b) =>
    sort(a.createdAt, b.createdAt),
  );

  const renderHeading = () => {
    if (cartWithItemsKeys.length < 2) {
      return null;
    }

    const hasUniqueGalleryID = cartWithItemsKeys.every(key => {
      if (key === visitKey) {
        return true;
      }
      return galleryMap[key].galleryID !== galleryMap[visitKey].galleryID;
    });

    return (
      <Flex direction={['column', 'initial']} marginTop={6} marginBottom={4}>
        <Heading size="sm" marginTop="3px">
          {title}
        </Heading>

        {subjectID && !hasUniqueGalleryID && (
          <>
            {!isMobile && <Text marginX="1.25ch">/</Text>}

            <Text fontSize={['sm', 'initial']} marginTop={['8px', 0]}>
              {SUBJECT_WITH_COLON} {subjectID}
            </Text>
          </>
        )}
      </Flex>
    );
  };

  const renderCartImageOptions = () => {
    if (isPreOrder) {
      return cartImageOptions.map(imageOption => (
        <CartImageOptionSection
          key={imageOption.id}
          option={imageOption}
          priceSheetID={priceSheetID}
          visitKey={visitKey}
        />
      ));
    }
    return Object.entries(imageToOptionLookup).map(([imageName, imageOptions]) => {
      const image = images[imageName];
      if (!image) {
        return null;
      }
      return imageOptions.map(imageOption => {
        return (
          <CartImageOptionSection
            image={image}
            key={`${image.internalName}-${imageOption.priceSheetOptionID}`}
            option={imageOption}
            priceSheetID={priceSheetID}
            visitKey={visitKey}
          />
        );
      });
    });
  };

  // if gallery is still loading return spinner
  if (isLoading) {
    return <Spinner margin="0 auto" />;
  }

  // if no items for cart, return
  if (cartProducts.length + cartPackages.length + cartImageOptions.length === 0) {
    return null;
  }

  return (
    <Flex direction="column" maxW={{ base: '100%', md: `${width - 400}px`, lg: '100%' }}>
      {renderHeading()}

      {cartPackages.map(item => (
        <CartItemSection
          key={`package-${item.id}`}
          item={item}
          priceSheetID={priceSheetID}
          visitKey={visitKey}
        />
      ))}
      {cartProducts.map(item => (
        <CartItemSection
          key={`product-${item.id}`}
          item={item}
          priceSheetID={priceSheetID}
          visitKey={visitKey}
        />
      ))}
      {renderCartImageOptions()}
    </Flex>
  );
};

export default Cart;
