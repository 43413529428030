import { Box, Input } from '@chakra-ui/react';
import React from 'react';

interface Props {
  isChecked: boolean;
  onChange(): void;
}

const Switch = ({ isChecked, onChange }: Props) => (
  <Box
    backgroundColor="transparent"
    cursor="pointer"
    display="inline-block"
    onClick={onChange}
    position="relative"
  >
    <Box
      backgroundColor={isChecked ? 'brand' : 'grey.4'}
      borderRadius="30px"
      height="19px"
      transition="all .2s ease"
      width="38px"
    />
    <Box
      backgroundColor="white"
      border="2px solid grey.3"
      borderRadius="50%"
      boxSizing="border-box"
      height="15px"
      left={isChecked ? '20px' : '1px'}
      margin="1px"
      position="absolute"
      top="1px"
      transition="all .2s ease"
      width="15px"
    />
    <Input
      aria-label="Toggle Button"
      border="0"
      height="1px"
      margin="-1px"
      overflow="hidden"
      padding="0"
      position="absolute"
      type="checkbox"
      width="1px"
      data-test="image-name-toggle"
    />
  </Box>
);

export default Switch;
