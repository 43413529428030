import { Box, Button, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FiZoomIn } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopBackground } from '../../../../../../shop-api-client';
import {
  CartImageNodeReq,
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../../redux/selectors/account.selectors';
import {
  selectBackgroundSetMap,
  selectBackgroundsMap,
  selectBackgroundToSetMap,
} from '../../../../../redux/selectors/background.selectors';
import { selectConfiguration } from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { setEditItemBackground } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../../../redux/store';
import { loadBackgroundImage } from '../../../../../redux/thunks/interactions.thunks';
import BackgroundsModal from '../../../../../shared/components/BackgroundsModal';
import BackgroundSwatches from '../../../../../shared/components/BackgroundSwatches';
import { FREE, SELECT_YOUR_BACKGROUND } from '../../../../../shared/constants';
import { formatCurrency, getNameWithoutExt } from '../../../../../shared/utils';
import { BACKGROUND_WITH_COLON, NONE_SELECTED } from '../../constants';
import { getBGOverlayStyles } from '../../utils';
import BackgroundPreview from './BackgroundPreview';

const SWATCH_SIZE = 43;
const overlayStyles = getBGOverlayStyles(SWATCH_SIZE);

interface Props {
  editItem?: CreateCartProductReq | CreateCartPackageReq | null;
  isInvalid?: boolean;
  isSubItem?: boolean;
  onSelect?(background: ShopBackground): void;
  selectedBackground?: ShopBackground | null;
  setOnEditItem?: boolean;
  showHeading?: boolean;
  showPreview?: boolean;
}

const Backgrounds = ({
  editItem,
  isInvalid,
  isSubItem,
  onSelect = () => {},
  selectedBackground,
  setOnEditItem = true,
  showHeading = true,
  showPreview,
}: Props) => {
  const { editPackage, editProduct } = useSelector(selectConfiguration);
  const { priceSheetID } = useSelector(selectGallery);
  const { currency } = useSelector(selectAccount);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const backgroundToSetMap = useSelector(selectBackgroundToSetMap);

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const price = selectedBackground
    ? backgroundSetMap[backgroundToSetMap[selectedBackground.id]].price
    : 0;

  useEffect(() => {
    if (!isLoading || selectedBackground || (!editPackage && !editProduct)) {
      return;
    }

    const init = async () => {
      let backgroundID: number | null = null;

      for (const product of editPackage?.products || [editProduct!]) {
        if (product.type === 'product') {
          const nodeWithBG = product.nodes.find(
            n => n.type === 'image' && n.backgroundID,
          ) as CartImageNodeReq;
          backgroundID = nodeWithBG?.backgroundID || null;
        } else if (product.type === 'collection' || product.type === 'imageDownload') {
          const image = product.collectionImages.find(i => i.backgroundID);
          backgroundID = image?.backgroundID || null;
        }
        if (backgroundID) {
          break;
        }
      }

      if (backgroundID && backgroundsMap[backgroundID]) {
        await dispatch(loadBackgroundImage(backgroundsMap[backgroundID]));
        onSelect(backgroundsMap[backgroundID]);
      }
      setIsLoading(false);
    };

    init();
  }, [backgroundsMap, dispatch, editPackage, editProduct, isLoading, onSelect, selectedBackground]);

  const handleSelectBackground = useCallback(
    (background: ShopBackground) => {
      if (setOnEditItem) {
        const editSubItem = isSubItem ? (editItem as CreateCartProductReq) : undefined;
        dispatch(setEditItemBackground({ background, editSubItem }));
      }
      dispatch(loadBackgroundImage(background));
      onSelect(background);
    },
    [dispatch, onSelect, setOnEditItem, editItem, isSubItem],
  );

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <BackgroundsModal
        isOpen={showModal}
        onSave={handleSelectBackground}
        onClose={toggleModal}
        priceSheetID={priceSheetID}
      />
      {showHeading && (
        <Heading marginBottom={4} fontSize="lg" color={isInvalid ? 'error' : 'black'}>
          {SELECT_YOUR_BACKGROUND}
        </Heading>
      )}
      <Flex direction={{ base: 'column', md: 'row' }}>
        <Flex direction="column">
          <Flex align="center" wrap="wrap">
            <Text fontSize="sm" fontWeight="bold" paddingRight={1}>
              {BACKGROUND_WITH_COLON}
            </Text>
            <Text color={isInvalid ? 'error' : 'black'}>
              {getNameWithoutExt(selectedBackground?.name) || NONE_SELECTED}
            </Text>
          </Flex>
          {!!selectedBackground && (
            <Text fontSize="xs">
              {intl.formatMessage(
                {
                  id: 'backgrounds.price',
                  defaultMessage: '{price}',
                },
                {
                  price: price > 0 ? formatCurrency(price, currency) : FREE,
                },
              )}
            </Text>
          )}
        </Flex>
        <Spacer />
        <Button
          leftIcon={<FiZoomIn color="black" />}
          onClick={toggleModal}
          color="brand"
          fontSize="sm"
          marginX={{ base: 0, md: 2 }}
          marginY={2}
          justifyContent="normal" // unsets default "center"
          variant="link"
        >
          {intl.formatMessage({
            id: 'backgrounds.viewLarger',
            defaultMessage: 'View Larger',
          })}
        </Button>
      </Flex>
      {showPreview && !!selectedBackground && <BackgroundPreview selected={selectedBackground} />}
      {Object.values(backgroundSetMap).map(set => (
        <Box key={set.id} marginY={2}>
          <Flex align="center" marginY={1}>
            <Heading fontSize="sm" marginRight={5}>
              {set.name}
            </Heading>
            {set.price !== 0 && (
              <Text fontSize="sm">{`+ ${formatCurrency(set.price, currency)}`}</Text>
            )}
          </Flex>
          <Box marginLeft={-2}>
            <BackgroundSwatches
              backgrounds={set.backgrounds}
              onSelectBackground={handleSelectBackground}
              overlayProps={overlayStyles}
              selectedBackground={selectedBackground}
              showText={false}
              size={`${SWATCH_SIZE}px`}
              swatchMargin={2}
            />
          </Box>
        </Box>
      ))}
      {isInvalid && (
        <Text color="error">
          {intl.formatMessage({
            id: 'backgrounds.pleaseSelect',
            defaultMessage: 'Please select a background',
          })}
        </Text>
      )}
    </>
  );
};

export default Backgrounds;
