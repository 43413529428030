import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProductOption, ShopProduct } from '../../../../../../../../shop-api-client';
import {
  CartPrintProductReq,
  CartProductOptionReq,
  CartTextNode,
  CreateCartProductReq,
} from '../../../../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../../../../redux/selectors/account.selectors';
import {
  selectBackgroundSetMap,
  selectBackgroundToSetMap,
} from '../../../../../../../redux/selectors/background.selectors';
import { selectPriceSheet } from '../../../../../../../redux/selectors/priceSheet.selectors';
import Swatch from '../../../../../../../shared/components/BackgroundSwatches/Swatch';
import { FREE } from '../../../../../../../shared/constants';
import { formatCurrency, getNameWithoutExt } from '../../../../../../../shared/utils';
import { CHOOSE_BACKGROUND } from '../../../../../../Checkout/constants';
import { getProductNodeMap } from '../../../../../utils';
import { ADD_TEXT, NONE_SELECTED } from '../../../../constants';
import { getBackground, getGroupedProductOptions } from '../../../../utils';
import CompletedSection from '../../../CompletedSection';
import OptionValue from '../../../CompletedSection/OptionValue';
import TextNodeList from '../../../CompletedSection/TextNodeList';

interface Props {
  editProduct: CreateCartProductReq;
  onEdit?(): void;
  shopProduct: ShopProduct;
}

const CompletedProduct = ({ editProduct, onEdit, shopProduct }: Props) => {
  const { productNodeMap, preOrderBackgroundSelectionType } = useSelector(selectPriceSheet);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const backgroundToSetMap = useSelector(selectBackgroundToSetMap);
  const { currency } = useSelector(selectAccount);

  const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
    shopProduct.options,
  );

  const selectedOptionMap = (editProduct.options || ([] as CartProductOptionReq[])).reduce<
    Record<string, CartProductOptionReq>
  >((result, option) => {
    result[option.optionGroupID] = option;
    return result;
  }, {});

  const optionalSelections = optionalProductOptions.filter(
    o => !!selectedOptionMap[o.catalogOptionGroupID],
  );

  const renderOption = (option: ProductOption) => {
    const { catalogOptionGroupID } = option;
    const selected = selectedOptionMap[catalogOptionGroupID];

    return (
      <Box key={catalogOptionGroupID} paddingBottom={4}>
        <Heading fontSize="md" paddingBottom={1}>
          {option.name}
        </Heading>
        <OptionValue option={option} selectedID={selected?.optionID} value={selected?.value} />
      </Box>
    );
  };

  const renderTextNodes = () => {
    const nodeMap = getProductNodeMap(productNodeMap[shopProduct.catalogProductID]);
    const textNodes = (editProduct as CartPrintProductReq).nodes.filter(
      n => n.type === 'text' && !nodeMap[n.catalogNodeID].locked,
    ) as CartTextNode[];

    if (!textNodes.length) {
      return null;
    }

    return (
      <Box paddingBottom={3}>
        <Heading fontSize="md" paddingBottom={1}>
          {ADD_TEXT}
        </Heading>
        <TextNodeList cartTextNodes={textNodes} nodeMap={nodeMap} />
      </Box>
    );
  };

  const renderSelectedBackground = () => {
    const selectedBackground = getBackground(editProduct, Object.values(backgroundSetMap));
    if (!selectedBackground || preOrderBackgroundSelectionType !== 'perImage') {
      return null;
    }

    const backgroundSetID = selectedBackground ? backgroundToSetMap[selectedBackground.id] : null;
    const backgroundSet = backgroundSetID ? backgroundSetMap[backgroundSetID] : null;
    const price = backgroundSet?.price;

    return (
      <Box paddingBottom={6}>
        <Heading fontSize="md" paddingBottom="10px">
          {CHOOSE_BACKGROUND}
        </Heading>

        <Flex gap={2}>
          <Swatch
            position="relative"
            size="55px"
            url={`url(${selectedBackground.sources.thumb}) no-repeat`}
            cursor="auto"
          />

          <Box>
            <Text lineHeight={1} marginBottom={1}>
              {getNameWithoutExt(selectedBackground?.name) || NONE_SELECTED}
            </Text>
            <Text fontSize="xs">{price ? formatCurrency(price, currency) : FREE}</Text>
          </Box>
        </Flex>
      </Box>
    );
  };

  return (
    <CompletedSection heading={shopProduct.name} onEdit={onEdit}>
      {renderSelectedBackground()}
      {editProduct.type === 'product' && renderTextNodes()}
      {requiredProductOptions.length > 0 && requiredProductOptions.map(renderOption)}
      {optionalSelections.length > 0 && optionalSelections.map(renderOption)}
    </CompletedSection>
  );
};

export default CompletedProduct;
