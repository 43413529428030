import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogProductWithMetadata } from 'iq-product-render';
import { signout } from './visitor.slice';

interface InitialState {
  rendererProductMap: Record<number, CatalogProductWithMetadata>;
}

const initialState: InitialState = {
  rendererProductMap: {},
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setRendererProductMap(state, action: PayloadAction<CatalogProductWithMetadata[]>) {
      for (const item of action.payload) {
        state.rendererProductMap[item.id] = item;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(signout, () => {
      return initialState;
    });
  },
});

const { actions, reducer } = catalogSlice;

export const { setRendererProductMap } = actions;

export default reducer;
