import { api } from '../../../shop-api-client';
import { setYearbookLastModifiedDate, setYearbookSelections } from '../slices/gallery.slice';
import { Thunk } from '../store';

export const updateYearbookSelections =
  (key: string, selections: Record<string, string>): Thunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const { yearbookSelections } = getState().gallery.galleryMap[key];
    try {
      await api.postYearbookSelections(key, [
        selections.newYearbookSelection1,
        selections.newYearbookSelection2,
      ]);

      // Update redux with updated YB info
      dispatch(
        setYearbookSelections({
          data: {
            ...yearbookSelections,
            yearbookSelection1: selections.newYearbookSelection1,
            yearbookSelection2: selections.newYearbookSelection2,
          },
          key,
        }),
      );

      dispatch(setYearbookLastModifiedDate({ data: new Date().toUTCString(), key }));
      return true;
    } catch (err) {
      throw new Error(`Failed to update selections: ${err}`);
    }
  };
