import { intl } from './intl';

export const apply = intl.formatMessage({
  id: 'discounts.constants.apply',
  defaultMessage: 'Apply',
});

export const remove = intl.formatMessage({
  id: 'discounts.constants.remove',
  defaultMessage: 'Remove',
});
