import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShopBackground } from '../../../shop-api-client';
import { reset } from './checkout.slice';
import { signout } from './visitor.slice';

export type GridMode = 'small' | 'medium' | 'large' | 'masonry' | null;

export interface BuyModeImage {
  background?: ShopBackground | null;
  image: string | null;
}

const initialState: {
  activeCategoryID: string;
  buyModeBackground: ShopBackground | null;
  buyModeImage: string | null;
  gridMode: GridMode;
  isBgViewerExpanded: boolean;
  selectedBackground: ShopBackground | null;
  selectedBackgroundData: string | ArrayBuffer | null;
  selectedImageName: string | null;
  showFindGalleryModal: boolean;
  showFooter: boolean;
  showMultiGalleryTooltip: boolean;
  showNav: boolean;
  showWelcomeModal: boolean;
} = {
  activeCategoryID: 'all',
  buyModeBackground: null,
  buyModeImage: null,
  gridMode: null,
  isBgViewerExpanded: false,
  selectedBackground: null,
  selectedBackgroundData: null,
  selectedImageName: null,
  showFindGalleryModal: false,
  showFooter: true,
  showMultiGalleryTooltip: false,
  showNav: true,
  showWelcomeModal: false,
};

const interactionsSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setBuyModeImage(state, action: PayloadAction<BuyModeImage>) {
      const { background, image } = action.payload;

      state.buyModeImage = image;
      if (background) {
        state.buyModeBackground = background;
      }
    },
    setActiveCategoryID(state, action: PayloadAction<string>) {
      state.activeCategoryID = action.payload;
    },
    setGridMode(state, action: PayloadAction<GridMode>) {
      state.gridMode = action.payload;
    },
    setSelectedBackground(
      state,
      {
        payload: { background, selectedBackgroundData },
      }: PayloadAction<{
        background: ShopBackground | null;
        selectedBackgroundData: string | ArrayBuffer | null;
      }>,
    ) {
      state.selectedBackground = background;
      state.selectedBackgroundData = selectedBackgroundData;
    },
    setSelectedImageName(state, action: PayloadAction<string | null>) {
      state.selectedImageName = action.payload;
    },
    toggleIsBgViewerExapnded(state, action: PayloadAction<boolean>) {
      state.isBgViewerExpanded = action.payload;
    },
    toggleShowMultiGalleryTooltip(state, action: PayloadAction<boolean>) {
      state.showMultiGalleryTooltip = action.payload;
    },
    toggleShowFindGalleryModal(state, action: PayloadAction<boolean>) {
      state.showFindGalleryModal = action.payload;
    },
    toggleShowWelcomeModal(state, action: PayloadAction<boolean>) {
      state.showWelcomeModal = action.payload;
    },
    toggleShowFooter(state, action: PayloadAction<boolean>) {
      state.showFooter = action.payload;
    },
    toggleShowNav(state, action: PayloadAction<boolean>) {
      state.showNav = action.payload;
      state.showFooter = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signout, () => {
        return initialState;
      })
      .addCase(reset, () => {
        return initialState;
      });
  },
});

const { actions, reducer } = interactionsSlice;

export const {
  setActiveCategoryID,
  setBuyModeImage,
  setGridMode,
  setSelectedBackground,
  setSelectedImageName,
  toggleIsBgViewerExapnded,
  toggleShowFindGalleryModal,
  toggleShowFooter,
  toggleShowMultiGalleryTooltip,
  toggleShowNav,
  toggleShowWelcomeModal,
} = actions;

export default reducer;
