import {
  Button,
  CloseButton,
  createStandaloneToast,
  Flex,
  Heading,
  Text,
  ToastProps as ChakraToastProps,
} from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import useOnEvent from '../../hooks/useOnEvent';
import { getToast, ToastOptions, UseToastOptions } from '../../hooks/useToast';
import { isForbiddenError } from '../../utils';

export type ToastProps = Pick<ChakraToastProps, 'title' | 'description' | 'onClose'> & ToastOptions;

/**
 * Wraps and calls Chakra UIs `createStandaloneToast` function to ensure that our own `Toast` component
 * is used, and that we're able to create toasts in thunks or other non-component functions
 *
 * NOTE: Pass `error` as an optional parameter to avoid showing toasts for 403 errors
 */
export const toast = (options: UseToastOptions, error?: any) => {
  if (isForbiddenError(error)) {
    return;
  }
  const { toast: standaloneToast } = createStandaloneToast();
  const toast = getToast(standaloneToast);
  toast(options);
};

const Toast = ({ description, onClose, onUndo, title }: ToastProps) => {
  const intl = useIntl();

  const undoText = intl.formatMessage({
    id: 'notificationToast.undoText',
    defaultMessage: 'Undo',
  });

  useOnEvent('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose!();
    }
  });

  const handleUndoButtonClick = () => {
    onUndo!();
    onClose!();
  };

  return (
    <Flex
      backgroundColor="gray.800"
      borderRadius="8px"
      color="gray.100"
      justifyContent="space-between"
      marginBottom="11px"
      paddingX={['20px', '18px']}
      paddingY="15px"
      width={['calc(100vw - 20px)', '590px']}
    >
      <Flex direction="column" justifyContent="center">
        <Heading fontSize="sm" marginBottom={description ? [1, 0] : undefined}>
          {title}
        </Heading>
        <Text fontSize="sm">{description}</Text>
      </Flex>

      <Flex position="relative" bottom="8px" left="11px">
        {onUndo && (
          <Button
            backgroundColor="inherit"
            color="gray.100"
            fontWeight="light"
            height="30px"
            marginRight="18px"
            marginY={0}
            onClick={handleUndoButtonClick}
            paddingTop={description ? undefined : ['13px', '11px']}
            variant="link"
          >
            {undoText}
          </Button>
        )}
        <CloseButton onClick={onClose} transform="scale(0.95, 1.15)" size="sm" marginTop="5px" />
      </Flex>
    </Flex>
  );
};

export default Toast;
