import React, { useCallback, useEffect, useRef, useState } from 'react';
import colors from '../../../theme/colors';

interface Props {
  background: HTMLImageElement | null;
  gridValue?: number;
  height: number;
  image: HTMLImageElement | null;
  isGreenScreen: boolean;
  offset: { x: number; y: number };
  radianRotation?: number | null;
  rotation: number;
  scale: number;
  showGrid?: boolean;
  translation?: { x: number; y: number } | null;
  width: number;
}

const ImageCanvas = ({
  background,
  gridValue = 3,
  height,
  image,
  isGreenScreen,
  offset,
  radianRotation,
  rotation,
  scale,
  showGrid = false,
  translation,
  width,
}: Props) => {
  const backgroundRef = useRef<HTMLCanvasElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const gridRef = useRef<HTMLCanvasElement | null>(null);

  const [ctx, setCtx] = useState<CanvasRenderingContext2D>(null!);
  const [bgCtx, setBgCtx] = useState<CanvasRenderingContext2D>(null!);
  const [gridCtx, setGridCtx] = useState<CanvasRenderingContext2D>(null!);

  /** renders the image
   * if it's a mask canvas element, it renders shaded visual borders
   * focus image and mask image elements are layered on top of each other
   */
  const drawImage = useCallback(() => {
    if (!image || !ctx) {
      return;
    }
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    ctx.save();

    if (translation) {
      ctx.translate(translation.x, translation.y);
    }
    if (radianRotation) {
      ctx.rotate(radianRotation);
    }

    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      offset.x,
      offset.y,
      image.width * scale,
      image.height * scale,
    );

    ctx.restore();
  }, [ctx, height, image, offset, radianRotation, scale, translation, width]);

  /** renders the background, if present */
  const drawBg = useCallback(() => {
    if (!bgCtx) {
      return;
    }
    bgCtx.canvas.width = width;
    bgCtx.canvas.height = height;

    bgCtx.save();

    if (translation) {
      bgCtx.translate(translation.x, translation.y);
    }
    if (radianRotation) {
      bgCtx.rotate(radianRotation);
    }

    if (image && background) {
      // The background is essentially forced into the image dimensions:
      bgCtx.drawImage(
        background,
        0,
        0,
        (rotation || 0) % 180 ? height : width,
        (rotation || 0) % 180 ? width : height,
      );
    } else {
      // Draw white bg if no background is selected but is greenscreen
      bgCtx.fillStyle = 'white';
      bgCtx.fillRect(0, 0, width, height);
    }

    bgCtx.restore();
  }, [background, bgCtx, height, image, radianRotation, rotation, translation, width]);

  /** renders the grid overlay
   * maths out the values needed to draw it
   */
  const drawGrid = useCallback(() => {
    if (!gridCtx) {
      return;
    }

    gridCtx.canvas.width = width;
    gridCtx.canvas.height = height;

    // get size of square grid unit based on shortest side
    // gridvalue is the number of lines
    const gridSpacing = (width <= height ? width : height) / gridValue;

    // get quotient for both values
    // shorter side should = grid value - because math
    const qW = Math.floor(width / gridSpacing);
    const qH = Math.floor(width / gridSpacing);

    // get remainder for sides
    // short side should be 0
    // divide by 2 (for 2 sides of a rect)
    const rW = (width % gridSpacing) / 2;
    const rH = (height % gridSpacing) / 2;

    gridCtx.lineWidth = 1;
    gridCtx.strokeStyle = colors.brand;

    gridCtx.beginPath();

    // draw vertical lines
    for (let i = 0; i <= qW; i++) {
      // adding remainder to center the grid
      const dW = i * gridSpacing + rW;
      gridCtx.moveTo(dW, 0);
      gridCtx.lineTo(dW, height);
    }
    // draw horizontal lines
    for (let i = 0; i <= qH; i++) {
      // adding remainder to center the grid
      const dH = i * gridSpacing + rH;
      gridCtx.moveTo(0, dH);
      gridCtx.lineTo(width, dH);
    }
    gridCtx.closePath();
    gridCtx.stroke();

    // outline border
    gridCtx.lineWidth = 8;
    gridCtx.strokeRect(0, 0, width, height);
  }, [gridCtx, height, width, gridValue]);

  // runs all the render functions to draw image, bg, grid based on props
  useEffect(() => {
    if (!image || !canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    setCtx(ctx);
    drawImage();

    // check for showGrid
    // no early return because we still need to check for background
    if (gridRef.current && showGrid) {
      const gridCtx = gridRef.current.getContext('2d');

      if (gridCtx) {
        setGridCtx(gridCtx);
        drawGrid();
      }
    }

    // Check for background
    if (!backgroundRef.current || !isGreenScreen) {
      return;
    }
    const bgCtx = backgroundRef.current.getContext('2d');

    if (!bgCtx) {
      return;
    }
    setBgCtx(bgCtx);
    drawBg();
  }, [drawImage, drawGrid, drawBg, image, isGreenScreen, showGrid]);

  const ignoreRightClick = (e: React.MouseEvent) => e.preventDefault();

  return (
    <>
      <canvas
        ref={ref => {
          backgroundRef.current = ref;
        }}
        onContextMenu={ignoreRightClick}
        style={{
          position: 'absolute',
        }}
      />
      <canvas
        ref={ref => {
          canvasRef.current = ref;
        }}
        onContextMenu={ignoreRightClick}
        style={{
          position: 'absolute',
        }}
      />
      {showGrid && (
        <canvas
          ref={ref => {
            gridRef.current = ref;
          }}
          onContextMenu={ignoreRightClick}
          style={{
            position: 'absolute',
          }}
        />
      )}
    </>
  );
};

export default ImageCanvas;
