import { Flex, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import Tab from '../../../../shared/components/Tab';
import { ActiveTab } from '../GallerySearch';

interface Props {
  activeTab: ActiveTab;
  inputRightElement: ReactNode;
  invalidMessage: string;
  isInvalid: boolean;
  isSubmitting: boolean;
  onChange(tab: ActiveTab, e: React.ChangeEvent<HTMLInputElement>): void;
  onKeyUp(e: React.KeyboardEvent<HTMLDivElement>): void;
  toggleTab(tab: ActiveTab): void;
  value: { search: string; code: string };
}

const GallerySearchInput = ({
  activeTab,
  inputRightElement,
  invalidMessage,
  isInvalid,
  onChange,
  onKeyUp,
  toggleTab,
  value,
}: Props) => {
  const intl = useIntl();
  const { promptForKeyword } = useSelector(selectAccount);

  const codeText = intl.formatMessage({
    id: 'gallerySearchInput.enterCode',
    defaultMessage: 'Enter Code',
  });
  const codePlaceholder = intl.formatMessage({
    id: 'gallerySearchInput.codePlaceholder',
    defaultMessage: 'Enter your Online or Access code',
  });
  const gallerySearch = intl.formatMessage({
    id: 'gallerySearchInput.gallerySearch',
    defaultMessage: 'Gallery Search',
  });
  const searchPlaceholder = intl.formatMessage({
    id: 'gallerySearchInput.searchPlaceholder',
    defaultMessage: 'Search by Gallery Name',
  });

  const placeholderText = activeTab === 'search' ? searchPlaceholder : codePlaceholder;

  return (
    <Flex direction="column" height="170px" maxWidth="700px" width="100%" zIndex={0}>
      <Flex alignItems="flex-start">
        <Tab
          data-test="gallery-search-access-code-tab"
          disableFocus
          isActive={activeTab === 'code'}
          label={codeText}
          onClick={() => toggleTab('code')}
        />
        {!promptForKeyword && (
          <Tab
            data-test="gallery-search-lookup-tab"
            disableFocus
            isActive={activeTab === 'search'}
            label={gallerySearch}
            onClick={() => toggleTab('search')}
          />
        )}
      </Flex>
      <InputGroup
        alignItems="center"
        borderColor={isInvalid ? 'error' : 'inherit'}
        borderRadius="8px"
        borderWidth={isInvalid ? '2px' : '1px'}
        color="grey.7"
        fontFamily="ITC Avant Garde Gothic Demi"
        height="60px"
        justifyContent="space-between"
        padding={1}
      >
        <InputLeftElement color="grey.5" height="100%" pointerEvents="none">
          <FiSearch size="20px" />
        </InputLeftElement>
        <Input
          _placeholder={{ fontFamily: 'ITC Avant Garde Gothic Book' }}
          autoComplete="off"
          border="unset"
          data-test="gallery-search-input"
          height="100%"
          marginLeft={8}
          onChange={e => onChange(activeTab, e)}
          onKeyUp={e => onKeyUp(e)}
          paddingTop={0}
          placeholder={placeholderText}
          value={value[activeTab]}
          width="100%"
        />
        {inputRightElement}
      </InputGroup>
      {isInvalid && (
        <Text
          color="error"
          data-test="invalid-search-key-message"
          fontSize="sm"
          marginLeft="5px"
          marginTop="10px"
        >
          {invalidMessage}
        </Text>
      )}
    </Flex>
  );
};

export default GallerySearchInput;
