import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './app/features/Landing';
import NoKeyInUrl from './app/features/NoKeyInUrl';
import Unsubscribe from './app/features/Unsubscribe';
import ProtectedApp from './app/ProtectedApp';
import DigitalDownloads from './app/features/DigitalDownloads';
import FacialRecognition from './app/features/FacialRecognition';

// if `/key`
// 1. Routes to Landing first
// 2. renders account or auth landing based on key type
// 3. if gallery/subject gallery key, we authorize and redirect user to either photos/shop
// 4. if auth fails, we redirect to login page

const App = () => {
  return (
    <Switch>
      <Route path="/download/:token" component={DigitalDownloads} />
      <Route path="/find-me/:galleryID/:token?" component={FacialRecognition} />
      <Route path="/unsubscribe/:queueMessageID" component={Unsubscribe} />
      <Route path="/:key" exact component={Landing} />
      <Route path="/:key" component={ProtectedApp} />
      <Route path="/" component={NoKeyInUrl} />
    </Switch>
  );
};

export default App;
