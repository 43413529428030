import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Z_INDEX_ONE } from '../../constants';

interface Props extends InputProps {
  description?: string;
  invalidMessage?: string;
  name: string;
  onCancel?: any;
  showCancel?: boolean;
  alwaysScalePlaceholder?: boolean;
}

const FloatingLabelInput = ({
  autoFocus,
  description,
  invalidMessage,
  isInvalid,
  isRequired,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  name,
  onBlur,
  onCancel,
  onFocus,
  placeholder,
  alwaysScalePlaceholder = false, // Added to always keep it in the floating scaled down mode
  showCancel = false,
  value,
  width,
  ...rest
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [scalePlaceholder, setScalePlaceholder] = useState(!!value);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // The autoFocus property on the input will take care of its own focus
    // state if it's initially true. If the autoFocus property switches from
    // false to true however, it won't actually focus on the re-render.
    // This useEffect essentially accomplishes that feature.
    if (autoFocus && ref.current) {
      ref.current.focus();
      setIsFocused(true);
    }
  }, [autoFocus]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    setIsFocused(e.target.value !== '');
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
    setIsFocused(true);
  };

  useEffect(() => {
    // If the input has received focus, scale down the placeholder
    if (isFocused) {
      setScalePlaceholder(true);
    } else {
      // If the input has a value, but the placeholder has not scaled down, set `scalePlaceholder`
      // to true, else if the inverse is true, set state to false
      if (value && !scalePlaceholder) {
        setScalePlaceholder(true);
      } else if (!value && scalePlaceholder) {
        setScalePlaceholder(false);
      }
    }
  }, [isFocused, scalePlaceholder, value]);

  return (
    <Box
      alignContent="center"
      display="flex"
      flexDirection="column"
      margin={margin}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      position="relative"
      width={width}
    >
      <InputGroup>
        <Input
          ref={ref}
          autoFocus={autoFocus}
          isInvalid={isInvalid}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          variant="floatingLabel"
          {...rest}
        />
        <InputRightElement>
          {description && (
            <Tooltip label={description} aria-label={description} background="grey.5">
              <InfoOutlineIcon color="grey.3" marginRight="112px" />
            </Tooltip>
          )}
          {showCancel && (
            <Button
              data-test="Cancel-button"
              variant="link"
              color="#737475"
              onClick={onCancel}
              position="absolute"
              right={0}
              width="72px"
              height="35px"
              minWidth="72px"
              minHeight="35px"
            >
              Cancel
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
      <Text
        as="label"
        color={
          isInvalid ? 'error' : scalePlaceholder || alwaysScalePlaceholder ? 'grey.6' : 'black'
        }
        fontSize="16px"
        maxWidth={width}
        noOfLines={1}
        padding="0 15px"
        position="absolute"
        transformOrigin="top left"
        transform={
          scalePlaceholder || alwaysScalePlaceholder
            ? 'translate(0, 5px) scale(0.70)'
            : 'translate(0, 15px) scale(1)'
        }
        transition={scalePlaceholder || alwaysScalePlaceholder ? 'all 0.2s ease-out' : 'unset'}
        zIndex={scalePlaceholder || alwaysScalePlaceholder ? Z_INDEX_ONE : '0'}
      >
        {placeholder}
        {isRequired && (
          <Text as="span" color="error" paddingLeft="2">
            *
          </Text>
        )}
      </Text>
      {isInvalid && (
        <Text
          data-test="required-to-fill-error"
          color="error"
          fontSize="12px"
          marginLeft="5px"
          marginTop="8px"
        >
          {invalidMessage || `Please enter a valid ${placeholder}`}
        </Text>
      )}
    </Box>
  );
};

export default FloatingLabelInput;
