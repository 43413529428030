import { BoxProps, Button, ButtonProps, Flex, Input } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { NUMBER_INPUT_BTN_TOTAL_W } from '../../../theme/components/button';

export const DEBOUNCE_DURATION = 250;

const CHAR_WIDTH = 8;
const INPUT_PADDING = 20;

interface Props extends BoxProps {
  debounceDuration?: number;
  decreaseButtonProps?: ButtonProps;
  defaultValue?: number;
  /**
   * Number value used to set only the input width, excluding the buttons
   */
  inputWidth?: number;
  isDisabled?: boolean;
  max?: number;
  min?: number;
  onValueChange(value: number): void;
  /**
   * Callback used for behavior or actions that should never be debounced
   */
  onValueChangeImmediate?(value: number): void;
  overrideValue?: number;
  readOnly?: boolean;
  step?: number;
  /**
   * Number value used to set total width of the component
   */
  width?: number;
}

const NumberInput = ({
  debounceDuration = 0,
  decreaseButtonProps,
  defaultValue = 1,
  inputWidth,
  isDisabled,
  max,
  min = 1,
  onValueChange,
  onValueChangeImmediate,
  overrideValue,
  readOnly = true,
  step = 1,
  width,
  ...rest
}: Props) => {
  const [value, setValue] = useState(defaultValue);

  // Explicitly check whether `max` is a number, since `0` is falsy:
  const isIncButtonDisabled = isDisabled || (typeof max === 'number' && value >= max);
  const isDecButtonDisabled = isDisabled || value <= min;

  useEffect(() => {
    if (overrideValue) {
      setValue(overrideValue);
    }
  }, [overrideValue]);

  const debouncedOnValueChange = useMemo(
    () => debounce((nextValue: number) => onValueChange(nextValue), debounceDuration),
    [debounceDuration, onValueChange],
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedOnValueChange.cancel();
    };
  }, [debouncedOnValueChange]);

  /**
   * Gets the width for the input, excluding the button widths
   */
  const getInputWidth = () => {
    // If a total width was given, subtract the button widths to get the input width;
    if (width) {
      return width - NUMBER_INPUT_BTN_TOTAL_W * 2;
    }
    // Return the specified `inputWidth` if given or size input based on number of characters
    return inputWidth || value.toString().length * CHAR_WIDTH + INPUT_PADDING;
  };

  const handleClick =
    (increment = true) =>
    () => {
      const nextValue = increment ? value + step : value - step;
      setValue(nextValue);

      if (onValueChangeImmediate) {
        onValueChangeImmediate(nextValue);
      }

      if (!debounceDuration) {
        onValueChange(nextValue);
        return;
      }
      debouncedOnValueChange(nextValue);
    };

  return (
    <Flex
      borderColor="grey.3"
      width={`${getInputWidth() + NUMBER_INPUT_BTN_TOTAL_W * 2}px`}
      color={isDisabled ? 'grey.3' : undefined}
      {...rest}
    >
      <Button
        color="inherit"
        data-test="quantity-decrease-button"
        disabled={isDecButtonDisabled}
        onClick={handleClick(false)}
        variant="numberInput"
        {...decreaseButtonProps}
      >
        -
      </Button>
      <Input
        color="inherit"
        disabled={isDisabled}
        max={max}
        min={min}
        readOnly={readOnly}
        step={step}
        value={value}
        variant="numberInput"
        width={`${getInputWidth()}px`}
      />
      <Button
        color="inherit"
        data-test="quantity-increase-button"
        disabled={isIncButtonDisabled}
        onClick={handleClick()}
        variant="numberInput"
      >
        +
      </Button>
    </Flex>
  );
};

export default NumberInput;
