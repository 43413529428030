import { Box } from '@chakra-ui/react';
import { TextNode } from 'iq-product-render';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CartPrintProductReq,
  CartTextNode,
  CreateCartProductReq,
} from '../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectIsStepCompleted,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import { setCompletedSteps, setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getNodeMapForCatalogProduct } from '../../../utils';
import { ADD_TEXT } from '../../constants';
import { getTextNodesStep, validateTextNodes } from '../../utils';
import CompletedSection from '../CompletedSection';
import TextNodeList from '../CompletedSection/TextNodeList';
import EditorSectionContainer from '../EditorSectionContainer';
import TextNodeEntry from './TextNodeEntry';

interface Props {
  activeStep?: string;
  alwaysActive?: boolean;
  catalogProductID: number;
  /**
   * editProduct can be the `editProduct` from redux or an editPackage sub-item
   */
  editProduct: CartPrintProductReq;
  hideBorder?: boolean;
  hideSave?: boolean;
  isEditing?: boolean;
  isSaving?: boolean;
  onPostProcessing(editProduct: CreateCartProductReq): void;
  onSave(editProduct: CreateCartProductReq): void;
}

const TextNodesEditor = ({
  activeStep,
  alwaysActive,
  editProduct,
  hideBorder,
  hideSave,
  isEditing,
  isSaving,
  onPostProcessing,
  onSave,
  catalogProductID,
}: Props) => {
  const stepName = useMemo(() => getTextNodesStep(editProduct.id!), [editProduct]);
  const { editStep, editPackageStep, invalidSteps } = useAppSelector(selectConfiguration);
  const { productNodeMap } = useAppSelector(selectPriceSheet);
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  const nodeMap = getNodeMapForCatalogProduct(catalogProductID, productNodeMap);
  const nodes = productNodeMap[catalogProductID];

  // Manages UI while updated text nodes are being added to editProduct:
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Manages the validity of the editProduct's text node completion status:
  const [isValid, setIsValid] = useState(validateTextNodes(editProduct.nodes, nodes, !isEditing));

  const editing = editStep === stepName || editPackageStep === stepName;
  const dispatch = useAppDispatch();

  /**
   * Checks that all text nodes are valid, sets the result to isValid and returns result
   */
  const validateEditNodes = useCallback(() => {
    const editNodes = editProduct.nodes.filter(
      n => n.type === 'text' && !nodeMap[n.catalogNodeID].locked,
    );

    const isValid = validateTextNodes(editNodes, nodes);
    setIsValid(isValid);
    return isValid;
  }, [editProduct, nodes, nodeMap]);

  useEffect(() => {
    if (isSaving || invalidSteps[stepName]) {
      setIsSubmitting(true);
      validateEditNodes();
    }
  }, [invalidSteps, isSaving, validateEditNodes, stepName]);

  const editNodes = editProduct.nodes.filter(
    n => n.type === 'text' && !nodeMap[n.catalogNodeID].locked,
  ) as CartTextNode[];
  if (!editNodes.length) {
    return null;
  }

  /**
   * Stores each text node change locally by updating that node in the editNodes state
   * and validates the completion of all text node entry for the Edit Product
   */
  const handleApplyText = (updated: CartTextNode) => {
    // Have each of the updated `editNodes` replace the existing corresponding
    // node belonging to the editProduct, and update the editProduct in Redux:
    const nodes = editProduct.nodes.map(node =>
      node.catalogNodeID === updated.catalogNodeID ? updated : node,
    );

    onSave({ ...editProduct, nodes });
  };

  const handleEdit = () => {
    dispatch(setEditStep(getTextNodesStep(editProduct.id!)));
  };

  const handleSave = () => {
    setIsSubmitting(true);
    if (!validateEditNodes()) {
      return;
    }

    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
    setIsSubmitting(false);

    // Signal any post processing that should happen on this product
    onPostProcessing(editProduct);
  };

  if (!alwaysActive && completed) {
    return (
      <CompletedSection heading={ADD_TEXT} onEdit={handleEdit}>
        <TextNodeList cartTextNodes={editNodes} nodeMap={nodeMap} />
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer
      data-test="text-node-entry-apply-button"
      heading={ADD_TEXT}
      hideToolbar={!editing}
      hideBorder={hideBorder}
      isDisabled={!(alwaysActive || activeStep === stepName || editing)}
      onSave={!hideSave ? handleSave : undefined}
    >
      <Box paddingTop={2}>
        {editNodes.map((node, index) => {
          const catalogNode = nodeMap[node.catalogNodeID] as TextNode;
          if (!catalogNode) {
            return null;
          }

          return (
            <TextNodeEntry
              key={`${catalogNode.id}-${activeStep}`}
              autoFocus={activeStep === stepName && index === 0}
              canEdit={activeStep === stepName}
              defaultText={catalogNode.text}
              isInvalidProduct={isSubmitting && !isValid}
              label={catalogNode.name}
              maxChars={catalogNode.maxChars}
              node={node}
              onApply={handleApplyText}
              data-test="product-editor-text-node"
            />
          );
        })}
      </Box>
    </EditorSectionContainer>
  );
};

export default TextNodesEditor;
