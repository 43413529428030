import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderFinancials } from '../../../../shop-api-client/models/Cart';
import { selectCartMap } from '../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { FREE } from '../../../shared/constants';
import { ADD_ONS } from '../../Products/Configuration/constants';
import SummaryRow from './SummaryRow';

interface Props {
  financials: OrderFinancials;
  hasPerOrderBG?: boolean;
  isCartView?: boolean;
  isFinalCalculation?: boolean;
}

const OrderSummary = ({
  isFinalCalculation,
  financials: {
    backgroundFees,
    digitalDownloadTax,
    discount,
    handling,
    imageOptionFees,
    orderOptionFees,
    shipping,
    subtotal,
    tax,
    total,
  },
  isCartView,
}: Props) => {
  const {
    settings: { handlingLabel, handlingRateType, shippingLabel, shipmentType, taxLabel },
  } = useSelector(selectGallery);
  const cartMap = useSelector(selectCartMap);
  const currentVisitKey = useSelector(selectCurrentVisitKey);

  const { pathname } = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const bgFees = intl.formatMessage({
    id: 'orderSummary.backgroundFees',
    defaultMessage: 'Background Fees',
  });
  const editText = intl.formatMessage({ id: 'orderSummary.edit', defaultMessage: 'Edit' });
  const estimatedTotalText = intl.formatMessage({
    id: 'orderSummary.estimatedTotal',
    defaultMessage: 'Estimated Total',
  });
  const estimatedTax = intl.formatMessage(
    {
      id: 'orderSummary.estimatedTax',
      defaultMessage: 'Estimated {taxLabel}',
    },
    { taxLabel },
  );
  const estimatedHandling = intl.formatMessage(
    {
      id: 'orderSummary.estimatedHandling',
      defaultMessage: 'Estimated {handlingLabel}',
    },
    { handlingLabel },
  );
  const orderSummary = intl.formatMessage({
    id: 'orderSummary.orderSummary',
    defaultMessage: 'Order Summary',
  });

  const subtotalText = intl.formatMessage({
    id: 'orderSummary.itemSubtotal',
    defaultMessage: 'Item Subtotal',
  });
  const totalText = intl.formatMessage({
    id: 'orderSummary.total',
    defaultMessage: 'Total',
  });
  const youSavedText = intl.formatMessage({
    id: 'orderSummary.youSaved',
    defaultMessage: 'You Saved',
  });

  const isCheckoutConfirmation =
    pathname.includes('/checkout') && pathname.includes('/confirmation');
  const isCustomerChoice = shipmentType === 'choice';
  const isMultiCart = Object.keys(cartMap).length > 1;
  // Calculated at checkout if multicart or customer choice for shipping
  const isCalculatedAtCheckout = isCartView && (isMultiCart || isCustomerChoice);
  // Always show tax if single cart
  const isEstimatedHandling =
    handlingRateType === 'percent' && (isCartView || (isCustomerChoice && !isFinalCalculation));
  const subTotalPlusImageOption = subtotal + imageOptionFees;
  const showTax = !isMultiCart || !isCartView;
  const showShipping = shipmentType !== 'pickup';

  const calculatedAtCheckout = intl.formatMessage(
    {
      id: 'orderSummary.shippingAndHandlingCalc',
      defaultMessage: '{tax}{shippingLabel} & {handlingLabel} calculated at checkout',
    },
    {
      shippingLabel: shippingLabel,
      handlingLabel: handlingLabel,
      tax: !showTax ? 'Tax, ' : '',
    },
  );

  const handleEditButtonClick = () => history.push(`/${currentVisitKey!}/carts`);

  return (
    <Box width="100%">
      <Flex justifyContent="space-between">
        <Heading fontSize={['lg', 'xl']} marginBottom={isCheckoutConfirmation ? '12px' : 0}>
          {orderSummary}
        </Heading>
        {!isCartView && !isCheckoutConfirmation && (
          <Button
            color="deepCyan"
            data-test="edit-order"
            marginTop={1.5}
            onClick={handleEditButtonClick}
            variant="link"
          >
            {editText}
          </Button>
        )}
      </Flex>
      <Flex flexFlow="column" marginTop={2}>
        <SummaryRow
          amount={subTotalPlusImageOption}
          data-test="item-subtotal"
          label={subtotalText}
        />
        {orderOptionFees > 0 && (
          <SummaryRow amount={orderOptionFees} label={ADD_ONS} data-test="add-ons-cost" />
        )}
        {backgroundFees > 0 && (
          <SummaryRow amount={backgroundFees} label={bgFees} data-test="background-fees" />
        )}
        {!isCalculatedAtCheckout && (
          <>
            {showShipping && (
              <SummaryRow
                amount={shipping || FREE.toLocaleUpperCase()}
                data-test="shipping-cost"
                label={shippingLabel}
              />
            )}
            <SummaryRow
              amount={handling}
              data-test="handling-cost"
              label={isEstimatedHandling ? estimatedHandling : handlingLabel}
            />
          </>
        )}
        {!!discount && (
          <SummaryRow
            addEmphasis
            data-test="discount"
            amount={discount}
            amountStyles={{ color: 'brand' }}
            label="Discount"
          />
        )}
        {showTax && (
          <SummaryRow
            amount={tax + digitalDownloadTax}
            data-test="estimated-tax"
            label={isFinalCalculation ? taxLabel : estimatedTax}
          />
        )}
        {isCalculatedAtCheckout && (
          <Text fontSize="13px" data-test="calculated-at-checkout" marginTop={6}>
            {calculatedAtCheckout}
          </Text>
        )}
        <SummaryRow
          amount={discount > 0 && total === 0 ? FREE.toLocaleUpperCase() : total}
          label={isCartView ? estimatedTotalText : totalText}
          labelStyles={{
            fontSize: 'lg',
            fontFamily: 'heading',
          }}
          amountStyles={{
            fontSize: 'lg',
            fontFamily: 'heading',
          }}
          marginBottom={1}
          marginTop={6}
        />
        {!isCartView && !!discount && (
          <SummaryRow
            data-test="amount-saved"
            amount={discount}
            amountStyles={{ color: 'brand' }}
            label={youSavedText}
            labelStyles={{ color: 'brand' }}
            marginBottom={3}
          />
        )}
      </Flex>
    </Box>
  );
};

export default OrderSummary;
