import { keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShopProduct } from '../../../../../../shop-api-client';
import { CreateCartProductReq } from '../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectPackageItemMap,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import {
  setCompletedSteps,
  setEditPackage,
} from '../../../../../redux/slices/configurations.slice';
import { useAppSelector } from '../../../../../redux/store';
import useToast from '../../../../../shared/hooks/useToast';
import ProductConfigSection from '../../shared/configurationSections/ProductConfigSection';
import CompletedProduct from '../../shared/configurationSections/ProductConfigSection/CompletedProduct';
import EditorSectionContainer from '../../shared/EditorSectionContainer';
import { getPackageSubItemStep, matchTextNodesInPackage } from '../../utils';

interface Props {
  completed: boolean;
  disabled: boolean;
  editProduct: CreateCartProductReq;
  isCartItem: boolean;
  shopProduct: ShopProduct;
  isPreOrder?: boolean;
}

const PackageItemConfiguration = ({
  completed,
  disabled,
  editProduct,
  isCartItem,
  shopProduct,
  isPreOrder = false,
}: Props) => {
  const { editPackage } = useSelector(selectConfiguration);
  const packageItemMap = useAppSelector(selectPackageItemMap);
  const { productNodeMap } = useAppSelector(selectPriceSheet);

  const [isCollapsed, setIsCollapsed] = useState(isCartItem);
  const [isSaving, setIsSaving] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const stepName = useMemo(() => {
    return getPackageSubItemStep(editProduct.id!);
  }, [editProduct.id]);

  const invalidOptions = useMemo(() => {
    const invalid: Record<string, boolean> = {};
    const editOptionMap = keyBy(editProduct.options || [], 'optionGroupID');

    if (isSaving) {
      for (const option of shopProduct.options) {
        if (option.requirementType === 'optional') {
          continue;
        }
        if (!editOptionMap[option.catalogOptionGroupID]) {
          invalid[option.catalogOptionGroupID] = true;
        }
      }
    }
    return invalid;
  }, [editProduct, isSaving, shopProduct]);

  useEffect(() => {
    if (completed && isCollapsed) {
      dispatch(setCompletedSteps({ [stepName]: true }));
    }
  }, [completed, dispatch, isCollapsed, stepName]);

  const handleSave = (updated: CreateCartProductReq) => {
    const products = editPackage!.products.map(p => (p.id === updated.id ? updated : p));
    dispatch(setEditPackage({ ...editPackage!, products }));
  };

  const handleEdit = () => {
    dispatch(setCompletedSteps({ [stepName]: false }));
    setIsCollapsed(false);
  };

  const handleComplete = () => {
    setIsSaving(true);
    // If the item is not validated as completed, prevent visitor from progressing:
    if (!completed) {
      setIsInvalid(true);
      // WIP SC-342: this toast is a placeholder until desired criteria is provided
      return toast({ title: 'Please fill out the required sections to continue' });
    }

    // Handle post processing at this step:
    handlePostProcessing(editProduct);
    dispatch(setCompletedSteps({ [stepName]: true }));

    // Reset states:
    setIsCollapsed(true);
    setIsInvalid(false);
    setIsSaving(false);
  };

  const handlePostProcessing = (completed: CreateCartProductReq) => {
    // TODO: could limit this to only happen on text node steps by checking active step
    const { completedSteps, updatedPackage } = matchTextNodesInPackage(
      editPackage!,
      completed,
      packageItemMap,
      productNodeMap,
    );
    dispatch(setEditPackage(updatedPackage));
    if (Object.keys(completedSteps).length) {
      dispatch(setCompletedSteps(completedSteps));
    }
  };

  if (completed && isCollapsed) {
    return (
      <CompletedProduct
        key={editProduct.id}
        editProduct={editProduct}
        onEdit={handleEdit}
        shopProduct={shopProduct}
      />
    );
  }

  return (
    <EditorSectionContainer
      heading={shopProduct.name}
      headingFontSize="xl"
      hideToolbar={disabled}
      isDisabled={!isCartItem && disabled}
      onSave={handleComplete}
    >
      <ProductConfigSection
        editProduct={editProduct}
        invalidOptions={invalidOptions}
        isSaving={isSaving}
        isInvalid={isInvalid}
        onPostProcessing={handlePostProcessing}
        onSave={handleSave}
        shopProduct={shopProduct}
        isEditing
        isSubItem
        isPreOrder={isPreOrder}
      />
    </EditorSectionContainer>
  );
};

export default PackageItemConfiguration;
