import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { Z_INDEX_TWO } from '../../constants';
interface Props extends IconButtonProps {
  lightMode?: boolean;
  onClick?(): void;
}

const ArrowRightButton = ({ lightMode = false, onClick, ...rest }: Props) => (
  <IconButton
    variant={lightMode ? 'modalLight' : 'modal'}
    icon={<HiOutlineArrowRight />}
    onClick={onClick}
    zIndex={Z_INDEX_TWO}
    {...rest}
  />
);

export default ArrowRightButton;
