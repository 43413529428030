import React from 'react';
import { Box, Button } from '@chakra-ui/react';

interface DigitalDownloadButtonProps {
  url: string;
  text: string;
}

const DigitalDownloadButton = ({ text, url }: DigitalDownloadButtonProps) => {
  return (
    <Box>
      <a href={url} download={url}>
        <Button colorScheme="blue">{text}</Button>
      </a>
    </Box>
  );
};

export default DigitalDownloadButton;
