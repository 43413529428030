import { Alert, Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ShopProductCollection } from '../../../../../../../shop-api-client';
import {
  CartCollection,
  CartCollectionReq,
  CartDownload,
  CartDownloadReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground';
import { Params } from '../../../../../../shared/types/router';

const BOX_SIZE = 80;

interface Props {
  editProduct: CartCollection | CartDownload | CartCollectionReq | CartDownloadReq;
  shopProduct: ShopProductCollection;
}

const PackageCollectionPreview = ({ editProduct, shopProduct }: Props) => {
  const { key } = useParams<Params>();
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const { editPackageStep, invalidSteps } = useAppSelector(selectConfiguration);
  const { images } = useAppSelector(state => selectGallery(state, key));

  const [error, setError] = useState(false);

  const intl = useIntl();
  const { imageRequirementType, includeAll, minImages, maxImages } = shopProduct;
  const collectionImages = editProduct.collectionImages.filter(ci => !!ci.internalName);

  useEffect(() => {
    if (editPackageStep) {
      setError(invalidSteps[editPackageStep]);
    }
  }, [editPackageStep, invalidSteps]);

  const allImagesAreIncluded = intl.formatMessage({
    id: 'collectionSelection.allImagesAreIncluded',
    defaultMessage: 'All your photos are included.',
  });

  const selectMinPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMinPhotos',
      defaultMessage: 'You must select at least {minImages} {photo}.',
    },
    {
      minImages,
      photo: minImages === 1 ? 'photo' : 'photos',
    },
  );

  const selectMaxPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMaxPhotos',
      defaultMessage: 'You can select up to {maxImages} {photo}.',
    },
    {
      maxImages,
      photo: maxImages === 1 ? 'photo' : 'photos',
    },
  );

  const imagesSelected = intl.formatMessage({
    id: 'collectionSelection.imagesSelected',
    defaultMessage: 'Images Selected:',
  });

  const imagesSelectedOfMax = intl.formatMessage(
    {
      id: 'collectionSelection.imagesSelectedOfMax',
      defaultMessage: '{selected} of {max}',
    },
    {
      selected: collectionImages.filter(ci => !!ci.internalName).length,
      max: maxImages,
    },
  );

  const imgRequirementTypeMsg = intl.formatMessage(
    {
      id: 'collectionSelection.imgRequirementTypeMsg',
      defaultMessage: 'No {imgRequirement} photos allowed.',
    },
    {
      imgRequirement: imageRequirementType === 'nonGroup' ? 'group' : 'subject',
    },
  );

  const renderExplanation = () => {
    return (
      <Alert variant="info" borderRadius="md" marginY={4}>
        <Box lineHeight="short">
          {includeAll ? (
            <Text variant="inline" marginRight={1}>
              {allImagesAreIncluded}
            </Text>
          ) : (
            <>
              <Text variant="inline" marginRight={1}>
                {selectMinPhotos}
              </Text>
              <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book" marginRight={1}>
                {selectMaxPhotos}
              </Text>
              {imageRequirementType !== 'any' && (
                <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book">
                  {imgRequirementTypeMsg}
                </Text>
              )}
            </>
          )}
        </Box>
      </Alert>
    );
  };

  const renderSelections = () => {
    if (includeAll) {
      // Selections aren't rendered when all images are included
      return null;
    }

    return (
      <>
        <Flex alignItems="baseline">
          <Text fontFamily="ITC Avant Garde Gothic Demi" marginRight={1} marginBottom={3}>
            {imagesSelected}
          </Text>
          <Text fontFamily="ITC Avant Garde Gothic Book">{imagesSelectedOfMax}</Text>
        </Flex>
        <Flex direction="row" wrap="wrap">
          {[...Array(maxImages)].map((imgSlot, index) => {
            const img = images[collectionImages[index]?.internalName!];
            const bg = backgroundsMap[collectionImages[index]?.backgroundID || ''];

            if (img) {
              return (
                <Box
                  key={index}
                  height={`${BOX_SIZE}px`}
                  width={`${BOX_SIZE}px`}
                  marginRight={2}
                  marginBottom={2}
                >
                  <ThumbnailWithBackground
                    src={img.sources.thumb}
                    background={bg?.sources?.thumb}
                    objectFit="cover"
                    height={`${BOX_SIZE}px`}
                    width={`${BOX_SIZE}px`}
                    borderRadius="md"
                  />
                </Box>
              );
            }

            return (
              <Box
                key={index}
                borderStyle="dashed"
                borderColor={index < minImages ? (error ? 'error' : 'grey.4') : 'grey.2'}
                borderRadius="md"
                borderWidth="2px"
                h={`${BOX_SIZE}px`}
                marginRight={2}
                marginBottom={2}
                w={`${BOX_SIZE}px`}
              />
            );
          })}
        </Flex>
      </>
    );
  };

  return (
    <Flex direction="column" width="100%">
      {renderExplanation()}
      {renderSelections()}
    </Flex>
  );
};

export default PackageCollectionPreview;
