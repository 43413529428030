import { createSelector } from '@reduxjs/toolkit';
import { ImageNode } from 'iq-api-client';
import { ShopProduct } from '../../../shop-api-client';
import { isConfigurableImageNode } from '../../features/Products/utils';
import { ALL } from '../../shared/constants';
import { RootState } from '../store';
import { selectGallery } from './gallery.selectors';
import { selectPriceSheet } from './priceSheet.selectors';

export const selectInteractions = (state: RootState) => state.interactions;

export const selectActiveCategory = createSelector(
  (state: RootState) => state.interactions,
  selectPriceSheet,
  ({ activeCategoryID }, { categories }) =>
    categories[activeCategoryID] ? categories[activeCategoryID].name : ALL,
);

export const selectBuyModeImage = createSelector(
  (state: RootState) => state.interactions,
  selectGallery,
  ({ buyModeBackground, buyModeImage }, { images }) =>
    buyModeImage ? { buyModeImage: images[buyModeImage], buyModeBackground } : {},
);

export const selectCanSelectAndBuy = createSelector(
  selectGallery,
  selectPriceSheet,
  selectInteractions,
  ({ images, type }, { productNodeMap, products }, { buyModeImage }) => {
    if (!buyModeImage) {
      return {};
    }

    // Returns true if Shop Product requires any configuration steps:
    const shouldSkip = (product: ShopProduct) => {
      const isGroupImage = !!images[buyModeImage].group;

      if (product.type === 'nonPrintProduct') {
        return true;
      }

      if (product.type === 'product') {
        // If not a standard job, and print product has image node requirements, ensure that it matches
        // that of the `buyModeImage`:
        if (
          type !== 'standard' &&
          product.imageRequirementProperties.some(
            p => (p.type === 'group' && !isGroupImage) || (p.type === 'nonGroup' && isGroupImage),
          )
        ) {
          return true;
        }
        let imageNodes = 0;
        for (const node of productNodeMap[product.catalogProductID]) {
          const isImageNode = isConfigurableImageNode(node);
          // If node has a default image, it should be skipped:
          if (isImageNode && (node as ImageNode).defaultImage) {
            return true;
          }

          if (isImageNode) {
            imageNodes++;
          }
          // If product has multiple image nodes, return true:
          if (imageNodes > 1) {
            return true;
          }
        }
      } else {
        const { imageRequirementType } = product;
        return (
          (imageRequirementType === 'group' && !isGroupImage) ||
          (imageRequirementType === 'nonGroup' && isGroupImage)
        );
      }
    };

    return Object.values(products).reduce<Record<string, boolean>>((result, product) => {
      // The criteria for adding to the `canSelectAndBuy` map are:
      // The item is NOT a Build Your Own Package
      // AND IF the item is a Standard Package, it is single pose and meets product requirements
      // ELSE the item is ShopProduct, and must meet product requirements:
      if (
        product.type !== 'package-byop' &&
        (product.type === 'package'
          ? !product.allowAdditionalPoses && !product.availableProducts.some(shouldSkip)
          : !shouldSkip(product))
      ) {
        result[product.id] = true;
      }

      return result;
    }, {});
  },
);

/**
 * This selector returns a map keyed by product id whose value is a boolean determined by
 * whether the product has required option selections or text node input requirements
 */
export const selectCanAutoAddMap = createSelector(
  selectGallery,
  selectPriceSheet,
  selectCanSelectAndBuy,
  (
    { isGreenScreen, settings: { disableCropping } },
    { backgroundSets, imageOptionMap, productNodeMap, products },
    canSelectAndBuy,
  ) => {
    // If no Shop Items can be chosen for Select & Buy, and the Shop has optional image options, or the job is
    // green screen, return empty object, since nothing can be automatically added to cart:
    if (
      Object.keys(canSelectAndBuy).length === 0 ||
      Object.values(imageOptionMap).some(o => o.requirementType === 'optional') ||
      (isGreenScreen && Object.keys(backgroundSets).length > 0)
    ) {
      return {};
    }

    const hasRequirements = (product: ShopProduct) => {
      if (product.options?.length) {
        return true;
      }
      if (
        product.type === 'product' &&
        (!disableCropping ||
          productNodeMap[product.catalogProductID].some(
            n => n.type === 'text' && !n.hidden && !n.locked && !n.text,
          ))
      ) {
        return true;
      }
      if (product.type === 'collection' || product.type === 'imageDownload') {
        if (!product.includeAll && product.minImages > 1) {
          return true;
        }
      }
    };

    return Object.values(products).reduce<Record<string, boolean>>((result, product) => {
      // If it does not meet selectAndBuy criteria, bail:
      if (!canSelectAndBuy[product.id]) {
        return result;
      }

      if (product.type === 'package' || product.type === 'package-byop') {
        // If package has image options or its items have requirements, bail:
        if (product.options?.length || product.availableProducts.some(hasRequirements)) {
          return result;
        }
      } else if (hasRequirements(product)) {
        return result;
      }

      result[product.id] = true;
      return result;
    }, {});
  },
);

export const selectProductTypeMap = createSelector(
  (state: RootState) => state.interactions,
  selectPriceSheet,
  ({ activeCategoryID }, { productCategoryMap, products }) =>
    productCategoryMap[activeCategoryID].reduce<{
      [key: string]: number[];
    }>((result, productID) => {
      if (!result[products[productID].type]) {
        result[products[productID].type] = [products[productID].id];
      } else {
        result[products[productID].type].push(products[productID].id);
      }
      return result;
    }, {}),
);

export const selectSelectedImage = createSelector(
  (state: RootState) => state.interactions,
  selectGallery,
  ({ selectedImageName }, { images }) => (selectedImageName ? images[selectedImageName] : null),
);
