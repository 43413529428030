import { Alert } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { convertToLocalTimeZone, getLocalDate } from '../../../shared/utils';
import { getDueDateEOD } from '../yearbook.utils';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const countdown = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      const id = setInterval(countdown, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const YearbookTimer = () => {
  const { yearbookDueDate } = useSelector(selectGallery);

  const endOfDueDate = useMemo(() => {
    return !yearbookDueDate ? undefined : getDueDateEOD(yearbookDueDate);
  }, [yearbookDueDate]);
  const dueDate = useMemo(() => convertToLocalTimeZone(endOfDueDate), [endOfDueDate]);
  const formattedDueDate = useMemo(
    () => convertToLocalTimeZone(endOfDueDate, 'MMMM Do YYYY, h:mm a z'),
    [endOfDueDate],
  );

  const [timer, setTimer] = useState(
    dueDate ? Math.ceil((moment(dueDate).valueOf() - getLocalDate().valueOf()) / 1000) : 0,
  );

  const intl = useIntl();

  useInterval(() => {
    if (timer) {
      setTimer(timer - 1);
    }
  }, 1000);

  const renderTimer = () => {
    const numberOfDays = Math.floor(timer / (60 * 60 * 24));
    const hours = Math.floor((timer % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timer % (60 * 60)) / 60);
    const seconds = Math.floor(timer % 60);

    if (numberOfDays < 1) {
      return (
        <>
          {intl.formatMessage(
            {
              id: 'yearbook.timerOneDay',
              defaultMessage: '{hours} Hours, {minutes} Minutes, and {seconds} seconds',
            },
            { hours, minutes, seconds: seconds < 10 ? `0${seconds}` : seconds },
          )}
        </>
      );
    }

    return (
      <>
        {intl.formatMessage(
          {
            id: 'yearbook.timerMultiDay',
            defaultMessage: '{numberOfDays} {day}',
          },
          { numberOfDays, day: numberOfDays === 1 ? 'day' : 'days' },
        )}
      </>
    );
  };

  return (
    <Alert
      variant="info"
      borderRadius="3xl"
      fontWeight="bold"
      status="info"
      px={[2, 3, 8]}
      data-test="yearbook-timer-expiration"
    >
      {timer > 0 ? (
        <>
          {intl.formatMessage(
            {
              id: 'yearbook.countdown',
              defaultMessage:
                'Yearbook selections must be made by {yearbookDueDate}. You have {yearbookCountdown} left to make your selections.',
            },
            {
              yearbookDueDate: formattedDueDate,
              yearbookCountdown: renderTimer(),
            },
          )}
        </>
      ) : (
        <>
          {intl.formatMessage(
            {
              id: 'yearbook.expired',
              defaultMessage:
                'The window for yearbook selections expired on {yearbookDueDate}. You can no longer select or make any changes to your selections.',
            },
            {
              yearbookDueDate: formattedDueDate,
            },
          )}
        </>
      )}
    </Alert>
  );
};

export default YearbookTimer;
