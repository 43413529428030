import { Box, Flex, FlexProps, Heading, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { ShopProductPrint } from '../../../../../../shop-api-client';
import { CartProduct } from '../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../redux/selectors/background.selectors';
import { selectUniqueImages } from '../../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { selectProductsForPackage } from '../../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../../redux/store';
import ProductImage from '../../../../Products/ProductImage';
import ProductPreview from '../../../../Products/ProductPreview';
import {
  CART_SUMMARY_MAX_PREVIEW_SIZE,
  CART_SUMMARY_MAX_PREVIEW_SIZE_MOBILE,
} from '../../../../Products/ProductPreview/ProductPreview';
import { getProductImageSrc } from '../../../../Products/utils';
import { getBackgroundForCartProduct } from '../../../utils';
import CartBGSection from '../../CartBGSection';
import CartProductDetails from '../../CartProductDetails';
import CartItemThumbnailStack from './CartItemThumbnailStack';

interface Props extends FlexProps {
  packagePSItemID: number;
  product: CartProduct;
  visitKey: string;
}

/**
 * Shows rendered thumbnail and product details for a given package product
 */
const CartPackageItemSection = ({ product, packagePSItemID, visitKey, ...flexProps }: Props) => {
  const { isPreOrder } = useAppSelector(state => selectGallery(state, visitKey));
  const backgroundsMap = useAppSelector(state => selectBackgroundsMap(state, visitKey));
  const packageProductMap = useAppSelector(state =>
    selectProductsForPackage(state, packagePSItemID, visitKey),
  );
  const uniqueImages = useAppSelector(state => selectUniqueImages(state, product, visitKey));

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const shopItem = packageProductMap[product.priceSheetItemID];
  const background = getBackgroundForCartProduct(product, Object.values(backgroundsMap));

  const maxPreviewSize = isMobile
    ? CART_SUMMARY_MAX_PREVIEW_SIZE_MOBILE
    : CART_SUMMARY_MAX_PREVIEW_SIZE;

  const renderThumbnails = () => {
    if (background && isPreOrder) {
      return <CartBGSection shopBG={background} />;
    }
    if (!uniqueImages.length) {
      const imageSrc = getProductImageSrc(product);
      return <ProductImage image={imageSrc} product={product} maxWidth="57px" />;
    }
    if (product.type === 'product') {
      return (
        <ProductPreview
          disableMiniPreview
          editProduct={product}
          hidePreviewText
          isFinalRender
          isInteractive={false}
          marginBottom={{ base: 1, md: 4 }}
          marginTop={{ base: 1, md: 0 }}
          maxPreviewHeight={maxPreviewSize}
          maxPreviewWidth={maxPreviewSize}
          shopProduct={shopItem as ShopProductPrint}
          visitKey={visitKey}
        />
      );
    }
    return <CartItemThumbnailStack imageSet={uniqueImages} visitKey={visitKey} />;
  };

  return (
    <Flex key={product.id} {...flexProps}>
      {!isMobile && renderThumbnails()}

      <Box data-test={`${product.name}-container`} marginLeft={{ base: 0, md: 4 }}>
        <Heading fontSize="sm" marginBottom={{ base: 2, md: 3 }}>
          {product.name}
        </Heading>
        {isMobile && <Box marginLeft={2.5}>{renderThumbnails()}</Box>}
        <CartProductDetails
          item={product}
          priceSheetItem={packageProductMap[product.priceSheetItemID]}
          visitKey={visitKey}
        />
      </Box>
    </Flex>
  );
};

export default CartPackageItemSection;
