import { intl } from './intl';

export const ALL = intl.formatMessage({
  id: 'headings.constants.all',
  defaultMessage: 'All',
});
export const ALL_CATEGORIES = intl.formatMessage({
  id: 'headings.constants.allCategories',
  defaultMessage: 'All Categories',
});
export const AVAILABLE_ADD_ONS = intl.formatMessage({
  id: 'headings.constants.availableAddOns',
  defaultMessage: 'Available Add-ons',
});
export const CANCEL = intl.formatMessage({
  id: 'headings.constants.cancel',
  defaultMessage: 'Cancel',
});
export const FREE = intl.formatMessage({
  id: 'headings.constants.free',
  defaultMessage: 'Free',
});
export const LOGIN = intl.formatMessage({
  id: 'headings.constants.login',
  defaultMessage: 'Login',
});
export const OTHER = intl.formatMessage({
  id: 'headings.constants.other',
  defaultMessage: 'Other',
});
export const PACKAGE_ADD_ONS = intl.formatMessage({
  id: 'headings.constants.includedAddOns',
  defaultMessage: 'Included Add-ons',
});
export const SELECT_YOUR_BACKGROUND = intl.formatMessage({
  id: 'headings.constants.selectYourBackground',
  defaultMessage: 'Select Your Background',
});
export const SHOP = intl.formatMessage({
  id: 'headings.constants.shop',
  defaultMessage: 'Shop',
});
export const SORT_AND_FILTER = intl.formatMessage({
  id: 'headings.constants.sort',
  defaultMessage: 'Sort & Filter',
});
