import { Flex, Text, useToast } from '@chakra-ui/react';
import { IsEqualCustomizer, isEqualWith } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ShopBuildYourOwnPackage, ShopProduct } from '../../../../../../../shop-api-client';
import { CreateCartProductReq } from '../../../../../../../shop-api-client/models/Cart';
import {
  selectAddedUnits,
  selectConfiguration,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectInteractions } from '../../../../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { setEditPackage } from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../../../../redux/store';
import { ALL_ITEMS } from '../../../constants';
import { getUniqueQuantityMap, setBackgroundOnItem, shapeEditProduct } from '../../../utils';
import { TOOLBAR_HEIGHT } from '../../WizardNavigation';
import AvailableItem from './AvailableItem';

const PackageBYOPEditor = () => {
  const { editPackage } = useSelector(selectConfiguration);
  const { productNodeMap, products } = useSelector(selectPriceSheet);
  const { selectedBackground } = useSelector(selectInteractions);
  const addedUnits = useSelector(selectAddedUnits);

  const dispatch = useAppDispatch();
  const toast = useToast();

  const uniqueQuantityMap = useMemo(() => getUniqueQuantityMap(editPackage), [editPackage]);

  const shopPackage = products[editPackage!.priceSheetItemID] as ShopBuildYourOwnPackage;
  const balanceCount = shopPackage.includedUnits - addedUnits;

  if (!editPackage || !shopPackage || shopPackage.type !== 'package-byop') {
    return null;
  }

  const { availableProducts } = shopPackage;
  const { included, units } = availableProducts.reduce<Record<string, ShopProduct[]>>(
    (result, product) => {
      if (product.price === 0) {
        result.included.push(product);
      } else {
        result.units.push(product);
      }
      return result;
    },
    { included: [], units: [] },
  );

  /**
   * A map containing arrays of included BYOP items grouped/keyed by `catalogProductID`
   */
  const includedByCatProductID = included.reduce<Record<string, ShopProduct[]>>((result, item) => {
    if (!result[item.catalogProductID]) {
      result[item.catalogProductID] = [];
    }

    result[item.catalogProductID].push(item);
    return result;
  }, {});

  /**
   * A map that is keyed by BYOP price sheet item `id`, whose values are the count of included items that are
   * exactly the same as the item for the given price sheet item id (except for id, and price)
   */
  const uniqueItemCountMap = availableProducts.reduce<Record<string, number>>((result, item) => {
    const groupedBycatProductID = includedByCatProductID[item.catalogProductID];
    /**
     *  `customizer` ignores the `id` and `price` fields when comparing the two items:
     */
    const customizer: IsEqualCustomizer = (a, b, key) => {
      return key === 'id' || key === 'price' || undefined;
    };

    if (groupedBycatProductID) {
      // Filter by performing a deep comparison on the items sourced from the same catalog product, returning like-items:
      const filtered = groupedBycatProductID.filter(i => isEqualWith(i, item, customizer));
      // Store the filtered count:
      result[item.id] = filtered.length;
    }

    return result;
  }, {});

  const handleChange = (item: ShopProduct, isAdding: boolean) => {
    if (isAdding) {
      if (shopPackage.includedUnits < addedUnits + item.price) {
        toast({
          title: 'This item could not be added because it exceeds the amount of units remaining',
        });
        return;
      }
      let editItem = shapeEditProduct(item, productNodeMap);

      if (selectedBackground) {
        editItem = setBackgroundOnItem(editItem, selectedBackground) as CreateCartProductReq;
      }

      const products = [...editPackage.products, editItem];
      dispatch(setEditPackage({ ...editPackage, products }));
    } else {
      // Remove the first found sub-item from the `editPackage` with the given `item.priceSheetItemID`:
      //
      // WIP SC-301: UX criteria remains to be considered when the visitor has two or more of the same item and configures
      // them differently in the Customize step, then comes back to this step and decreases  the the number of editPackage
      // items from the same price sheet item. For now, it will automatically remove the first found PS item. However,
      // there should be some intermediate step that does a deep comparison of the configured items, and if divergences
      // are found, have the visitor choose which to remove.
      const itemIndex = editPackage.products.findIndex(p => p.priceSheetItemID === item.id);
      const products = [...editPackage.products];
      products.splice(itemIndex, 1);
      dispatch(setEditPackage({ ...editPackage, products }));
    }
  };

  return (
    <Flex
      direction="column"
      height={{ base: 'inherit', md: window.innerHeight - TOOLBAR_HEIGHT - 50 }}
      padding={{ base: 1, md: 5 }}
      grow={1}
    >
      <Flex direction="column" width="100%">
        <Text fontFamily="heading" fontSize="sm" marginY={3} textTransform="uppercase">
          {ALL_ITEMS}
        </Text>
        <Flex direction="row" flexWrap="wrap">
          {units.map(unit => (
            <AvailableItem
              key={unit.id}
              includedCount={uniqueItemCountMap[unit.id] || 0}
              quantity={uniqueQuantityMap[unit.id] || 0}
              onInputChange={handleChange}
              remainingBalance={balanceCount}
              shopItem={unit}
              unitLabel={shopPackage.unitLabel}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PackageBYOPEditor;
