const Radio = {
  baseStyle: {
    control: {
      backgroundColor: 'transparent',
      borderColor: 'grey.3',
      borderRadius: '50%',
      marginTop: '2px',
      _checked: {
        _before: {
          borderRadius: '50%',
          borderWidth: '6px',
          borderColor: 'brand',
          color: 'brand',
          w: '50%',
          h: '50%',
        },
        _focus: {
          boxShadow: 'none',
          boxShadowColor: 'brand',
          bgColor: 'white',
          borderColor: 'brand',
        },
        _hover: {
          bgColor: 'white',
          borderColor: 'brand',
          backgroundColor: 'transparent',
        },
        boxShadow: 'none',
        bgColor: 'white',
        borderColor: 'brand',
        color: 'brand',
      },
    },
  },
  variants: {
    sidebar: {
      border: 0,
      borderBottomWidth: '1px',
      borderStyle: 'solid',
      marginLeft: '30px',
      maxWidth: '275px',
    },
    maxLabel: {
      label: {
        flexGrow: 1,
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export default Radio;
