import { Box, Button, Collapse, Flex, Heading } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaSlidersH } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectActiveCategory,
  selectInteractions,
  selectProductTypeMap,
} from '../../../redux/selectors/interactions.selectors';
import {
  selectLockedAndUnlockedCategories,
  selectPriceSheet,
} from '../../../redux/selectors/priceSheet.selectors';
import ModalCloseButton from '../../../shared/components/ModalCloseButton';
import SegmentedControl from '../../../shared/components/SegmentedControl';
import SidebarSection from '../../../shared/components/SidebarSection';
import { ALL_CATEGORIES, CLOSE_MENU, FILTER, SORT_AND_FILTER } from '../../../shared/constants';
import {
  CATEGORIES_SUB_MENU_HEIGHT,
  MOBILE_NAV_WITH_CAT_MENU_HEIGHT,
} from '../../Navigation/constants';
import CategoryLinksSection from '../CategoryLinksSection';
import { INTL_AVAILABILITY_FILTER_LABELS, PRODUCT_TYPE_FILTER_LABELS } from '../constants';
import ProductFilterSection from '../ProductFilterSection';
import SelectAndBuyBanner from '../SelectAndBuyBanner';

interface Props {
  activeSegment?: string;
  toggleSegment(segment?: string): void;
}

const MobileCategoriesMenu = ({ activeSegment, toggleSegment }: Props) => {
  const { activeCategoryID } = useSelector(selectInteractions);
  const { lockedCategories, unlockedCategories } = useSelector(selectLockedAndUnlockedCategories);
  const { offersStatusMap } = useSelector(selectPriceSheet);
  const productTypeMap = useSelector(selectProductTypeMap);
  const activeCategory = useSelector(selectActiveCategory);

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const filters = searchParams.getAll(FILTER);

  const [filterSelections, setFilterSelections] = useState<string[]>(filters);

  const intl = useIntl();

  const handleApplyFilters = () => {
    searchParams.delete(FILTER);
    filterSelections.forEach(filter => {
      searchParams.append(FILTER, filter);
    });

    toggleSegment();
    history.replace({ search: `${searchParams}` });
  };

  const handleClearFilters = () => setFilterSelections([]);

  const toggleProductFilter = (filter: string) => {
    if (filterSelections.includes(filter)) {
      setFilterSelections(filterSelections.filter(f => f !== filter));
    } else {
      setFilterSelections([...filterSelections, filter]);
    }
  };

  const renderHeading = () => {
    if (activeSegment !== SORT_AND_FILTER) {
      return (
        <SegmentedControl
          activeSegment={activeSegment}
          height={`${CATEGORIES_SUB_MENU_HEIGHT}px`}
          leftLabel={activeCategory === 'All' ? ALL_CATEGORIES : activeCategory}
          rightLabel={SORT_AND_FILTER}
          rightPrefixIcon={<FaSlidersH />}
          showLeftCaret
          toggleSegment={toggleSegment}
        />
      );
    }
    return (
      <Flex
        align="center"
        borderBottomWidth="8px"
        borderColor="grey.1"
        height="70px"
        justify="space-between"
        paddingLeft="25px"
        paddingRight="10px"
      >
        <Heading fontFamily="inherit" fontWeight="100" size="lg">
          {SORT_AND_FILTER}
        </Heading>
        <ModalCloseButton aria-label={CLOSE_MENU} lightMode onClick={() => toggleSegment()} />
      </Flex>
    );
  };

  return (
    <Box background="white" maxHeight="100%" width="100%">
      <Box background="white">
        {renderHeading()}
        {!activeSegment && <SelectAndBuyBanner />}
      </Box>
      <Collapse in={!!activeSegment} unmountOnExit>
        <Box height={window.innerHeight - MOBILE_NAV_WITH_CAT_MENU_HEIGHT} overflow="scroll">
          {activeSegment !== SORT_AND_FILTER && (
            <Flex background="grey.1" direction="column" paddingBottom="60px" paddingTop={4}>
              <CategoryLinksSection onLinkClick={() => toggleSegment()} />
            </Flex>
          )}
          {activeSegment === SORT_AND_FILTER && (
            <Box paddingTop="70px">
              <SidebarSection defaultCollapsed heading="Sort By" isCollapsible showBorder>
                {/* TODO: Sort config here, when design/criteria has been provided */}
                {''}
              </SidebarSection>
              <ProductFilterSection
                filterableMap={productTypeMap}
                heading={intl.formatMessage({
                  id: 'productFilterSection.type',
                  defaultMessage: 'Type',
                })}
                intlLabelMap={PRODUCT_TYPE_FILTER_LABELS}
                isMobile
                onChange={toggleProductFilter}
                productFilters={filterSelections}
              />
              {!!Object.keys(offersStatusMap).length && activeCategoryID === 'all' && (
                <ProductFilterSection
                  filterableMap={{ locked: lockedCategories, unlocked: unlockedCategories }}
                  heading={intl.formatMessage({
                    id: 'productFilterSection.availability',
                    defaultMessage: 'Availability',
                  })}
                  intlLabelMap={INTL_AVAILABILITY_FILTER_LABELS}
                  isMobile
                  onChange={toggleProductFilter}
                  productFilters={filterSelections}
                />
              )}
              <Flex
                align="center"
                background="grey.1"
                bottom="0"
                height="70px"
                paddingX="5px"
                position="fixed"
                width="100%"
              >
                <Button flexGrow={1} margin="5px" variant="secondary" onClick={handleClearFilters}>
                  {intl.formatMessage({
                    id: 'mobileCategoriesMenu.clearAll',
                    defaultMessage: 'Clear All',
                  })}
                </Button>
                {/* TODO: Display filtered item count on button label */}
                <Button flexGrow={2} margin="5px" onClick={handleApplyFilters}>
                  {intl.formatMessage(
                    {
                      id: 'mobileCategoriesMenu.apply',
                      defaultMessage: 'Apply ({filterCount})',
                    },
                    {
                      filterCount: filterSelections.length,
                    },
                  )}
                </Button>
              </Flex>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default MobileCategoriesMenu;
