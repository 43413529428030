import { Flex } from '@chakra-ui/layout';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { IMAGE_VIEWER_ANIMATION_Z_INDEX, IMAGE_VIEWER_Z_INDEX } from '../../../../shared/constants';
import { Params } from '../../../../shared/types/router';
import { NEXT, PREV } from '../../constants';

const MotionFlex = motion(Flex);

interface Props {
  children: ReactNode;
  containerWidth: number;
  direction: string;
  handleNavigate(direction: string): void;
  height: number;
  scale: number;
  width: number;
}

interface GestureElement {
  x: number;
  y: number;
}

const AnimateWrapper = ({
  children,
  containerWidth,
  direction,
  handleNavigate,
  height,
  scale,
  width,
}: Props) => {
  const { images } = useSelector(selectGallery);
  const { backgroundID, photoID } = useParams<Params>();

  const animateKey = Object.values(images).length === 1 ? `${photoID}-${backgroundID}` : photoID;
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

  const variants = {
    enter: (direction: string) => ({
      zIndex: IMAGE_VIEWER_ANIMATION_Z_INDEX,
      x: direction === NEXT ? containerWidth : -containerWidth,
      opacity: 0,
    }),
    center: {
      zIndex: IMAGE_VIEWER_Z_INDEX,
      x: 0,
      opacity: 1,
    },
    exit: (direction: string) => ({
      zIndex: IMAGE_VIEWER_ANIMATION_Z_INDEX,
      x: direction === NEXT ? -containerWidth : containerWidth,
      opacity: 0,
    }),
  };

  const handleDrag = (offset: GestureElement, velocity: GestureElement) => {
    if (scale !== 1) {
      return;
    }
    const swipe = swipePower(offset.x, velocity.x);
    if (swipe < -swipeConfidenceThreshold) {
      handleNavigate(NEXT);
    } else if (swipe > swipeConfidenceThreshold) {
      handleNavigate(PREV);
    }
  };

  return (
    <Flex height={height} width={width} position="absolute">
      <AnimatePresence initial={false} custom={direction}>
        <MotionFlex
          animate="center"
          custom={direction}
          drag={scale === 1 ? 'x' : ''}
          dragConstraints={scale === 1 ? { left: 0, right: 0 } : {}}
          dragElastic={scale === 1 ? 1 : 0}
          exit="exit"
          height={height}
          initial="enter"
          key={animateKey}
          onDragEnd={(
            e: React.DragEvent,
            { offset, velocity }: { offset: GestureElement; velocity: GestureElement },
          ) => {
            handleDrag(offset, velocity);
          }}
          position="absolute"
          transition={{ x: { type: 'spring', stiffness: 200, damping: 30, bounce: 0 } }}
          variants={variants}
          width={width}
        >
          {children}
        </MotionFlex>
      </AnimatePresence>
    </Flex>
  );
};

export default AnimateWrapper;
