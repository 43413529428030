import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/checkbox';
import React, { ReactNode } from 'react';
import CheckboxIcon from './CheckboxIcon';

interface Props extends CheckboxProps {
  children: ReactNode;
}

const Checkbox = ({ children, ...rest }: Props) => (
  <ChakraCheckbox icon={<CheckboxIcon />} {...rest}>
    {children}
  </ChakraCheckbox>
);

export default Checkbox;
