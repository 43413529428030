import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsArrowLeftRight } from 'react-icons/bs';
import { ShopImageNodeWithReqType } from '../../../../../../../shop-api-client/models/ShopProductNodes';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { updatePoseInPackage } from '../../../../../../redux/thunks/configuration.thunks';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground';
import { CANCEL } from '../../../../../../shared/constants';
import { ITEM } from '../../../../../../shared/constants/labels.constants';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import { pluralize } from '../../../../../../shared/utils';
import {
  POSE_LIMIT_HEADING,
  POSE_LIMIT_REACHED,
  POSE_LIMIT_REPLACE,
  UPDATE,
} from '../../../constants';
import { getBackgroundForPoseSwap } from '../../../utils';

export interface PoseToSwap {
  imageName: string;
  backgroundID: number | undefined;
}

interface PoseForRender {
  backgroundID: string | undefined;
  imageName: string;
  poseKey: string;
  usageCount: number;
}

interface Props {
  catalogNode?: ShopImageNodeWithReqType | undefined;
  onClose(): void;
  onConfirm(): void;
  poseMap: Record<string, number[]>;
  newPose: PoseToSwap;
}

const PackagePoseModal = ({ catalogNode, onClose, onConfirm, poseMap, newPose }: Props) => {
  const backgroundMap = useAppSelector(selectBackgroundsMap);
  const { images } = useAppSelector(selectGallery);

  const [selectedOldPose, setSelectedOldPose] = useState('');

  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const handleClick = (pose: string) => {
    setSelectedOldPose(pose);
  };

  const handleConfirm = () => {
    if (!selectedOldPose) {
      return;
    }

    const split = selectedOldPose.split('-');
    const imageName = split[0];
    const backgroundID = split.length > 1 ? parseInt(split[1]) : undefined;
    const nextBackgroundID = getBackgroundForPoseSwap(
      Object.keys(poseMap),
      newPose?.backgroundID,
      catalogNode?.skipBackgroundSelection,
    );

    dispatch(
      updatePoseInPackage(imageName, backgroundID, newPose?.imageName, nextBackgroundID, false),
    );

    onConfirm();
    onClose();
  };

  const renderHeader = () => {
    // Parse the incoming new image
    const { imageName: newImageName, backgroundID: newBackgroundID } = newPose;
    const newImage = images[newImageName].sources.thumb;
    const newBackground = newBackgroundID
      ? backgroundMap[newBackgroundID].sources.thumb
      : undefined;

    // If a pose has already been selected, parse & show it:
    const split = (selectedOldPose || '').split('-');
    const imageName = split[0];
    const image = images[imageName];
    const backgroundID = split.length > 1 ? split[1] : undefined;
    const background = backgroundID ? backgroundMap[backgroundID] : undefined;

    return (
      <Flex data-test="modal-container" alignItems="center" direction="column">
        <Heading
          fontFamily="ITC Avant Garde Gothic Demi"
          marginX="auto"
          paddingBottom={4}
          size="lg"
        >
          {POSE_LIMIT_HEADING}
        </Heading>
        <Text fontSize="sm" fontWeight="100">
          {POSE_LIMIT_REACHED}
        </Text>
        <Text fontSize="sm" fontWeight="100">
          {POSE_LIMIT_REPLACE}
        </Text>
        <Flex direction="row" marginX="auto" paddingTop={4}>
          <ThumbnailWithBackground src={newImage} background={newBackground} maxHeight="80px" />
          <Icon as={BsArrowLeftRight} alignSelf="center" marginX={10} fontSize="30px" />
          {selectedOldPose ? (
            <ThumbnailWithBackground
              src={image?.sources.thumb || ''}
              background={background?.sources.thumb}
              maxHeight="80px"
            />
          ) : (
            <Box
              width="80px"
              height="80px"
              borderRadius="md"
              borderWidth="2px"
              borderColor="grey.2"
              borderStyle="dashed"
            />
          )}
        </Flex>
      </Flex>
    );
  };

  const renderPoses = () => {
    const poses = Object.entries(poseMap).reduce<PoseForRender[]>((filtered, [key, usages]) => {
      const split = key.split('-');
      const imageName = split[0];
      const backgroundID = split.length > 1 ? split[1] : undefined;

      // If this pose has no background, but other usage of the image is found with a background, it
      // means this pose is present due to usage in a no-background node, so skip to avoid having
      // the background-less image appear as a choice in the modal that can be generally used:
      if (!backgroundID && Object.keys(poseMap).some(k => k !== key && k.includes(imageName))) {
        return filtered;
      }

      filtered.push({
        backgroundID,
        imageName,
        poseKey: key,
        usageCount: usages.length,
      });

      return filtered;
    }, []);

    return (
      <Flex direction="column">
        <Heading size="sm" paddingY={4}>
          Images in Package ({poses.length})
        </Heading>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={8} marginX={4}>
          {poses.map(({ backgroundID, imageName, poseKey, usageCount }) => {
            const image = images[imageName];
            const background = backgroundID ? backgroundMap[backgroundID] : undefined;

            return (
              <Flex
                key={poseKey}
                data-test={`pose-photo-selection-${image.displayName}`}
                direction="column"
              >
                <ThumbnailWithBackground
                  isActive={selectedOldPose === poseKey}
                  onClick={() => handleClick(poseKey)}
                  src={image?.sources.thumb || ''}
                  background={background?.sources.thumb}
                />
                <Text marginX="auto" fontSize="xs" paddingTop={2} textTransform="capitalize">
                  {pluralize(usageCount, ITEM, true)}
                </Text>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Flex>
    );
  };

  return (
    <Modal
      blockScrollOnMount
      isOpen
      onClose={onClose}
      size={isMobile ? 'full' : undefined}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: 0, md: 6 }}
        margin={{ base: 0, md: '125px' }}
        minHeight={{ base: undefined, md: '600px' }}
        maxHeight={{ base: undefined, lg: '700px' }}
        maxWidth="800px"
      >
        <ModalHeader paddingTop={10} paddingBottom={2}>
          <ModalCloseButton borderRadius="50%" />
          {renderHeader()}
        </ModalHeader>
        <ModalBody paddingTop={0} paddingX="40px">
          {renderPoses()}
        </ModalBody>
        <ModalFooter paddingX={3} paddingY={{ base: 2, md: 8 }}>
          <Flex direction="row" marginX="auto" width={{ base: '100%', md: 'auto' }}>
            <Button
              background="grey.1"
              height="40px"
              marginRight={4}
              onClick={onClose}
              variant="secondary"
              width={{ base: '50%', md: '150px' }}
            >
              {CANCEL}
            </Button>
            <Button
              width={{ base: '50%', md: '250px' }}
              height="40px"
              marginBottom={1}
              onClick={handleConfirm}
            >
              {UPDATE}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PackagePoseModal;
