import { Box, ListItem, UnorderedList } from '@chakra-ui/react';
import { CatalogProductNode, template } from 'iq-product-render';
import React from 'react';
import { useSelector } from 'react-redux';
import { CartTextNode } from '../../../../../../../shop-api-client/models/Cart';
import { selectDynamicData } from '../../../../../../redux/selectors/gallery.selectors';
import { TextNode } from 'iq-api-client';
import { useIntl } from 'react-intl';

interface Props {
  cartTextNodes: CartTextNode[];
  nodeMap: Record<string, CatalogProductNode>;
}

const TextNodeList = ({ cartTextNodes, nodeMap }: Props) => {
  const intl = useIntl();
  const dynamicData = useSelector(selectDynamicData);
  const usingDataOnFile = intl.formatMessage({
    id: 'cartProductDetails.usingDataOnFile',
    defaultMessage: 'Using data on file',
  });

  return (
    <UnorderedList>
      {cartTextNodes.map(node => (
        <ListItem key={`${node.id}-${node.catalogNodeID}`} marginBottom={2} color="#737475">
          <Box as="span">{nodeMap[node.catalogNodeID].name}:</Box>
          {node.type === 'text' && (nodeMap[node.catalogNodeID] as TextNode).text !== node.text ? (
            <Box
              data-test={`${nodeMap[node.catalogNodeID].name}-value`}
              as="span"
              fontFamily="heading"
              paddingLeft={2}
              color="#1a202c"
            >
              {template(node.text || '', dynamicData)}
            </Box>
          ) : (
            <Box
              data-test={`${nodeMap[node.catalogNodeID].name}-value`}
              as="span"
              fontStyle="italic"
              fontWeight="bold"
              paddingLeft={2}
            >
              {usingDataOnFile}
            </Box>
          )}
        </ListItem>
      ))}
    </UnorderedList>
  );
};

export default TextNodeList;
