import { Flex, IconButton } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectAllGalleries } from '../../../redux/selectors/gallery.selectors';
import Logo from '../../../shared/components/Logo';
import ContactActions from '../../AccountLanding/ContactDetails/ContactActions';
import ContactMenu from '../../AccountLanding/ContactDetails/ContactMenu';
import MobileCategoriesMenu from '../../Products/MobileCategoriesMenu';
import CartButton from '../CartButton';
import { MOBILE_ACTION_BAR_HEIGHT } from '../constants';
import MultiGalleryButton from '../MultiGalleryButton';
import NavigationMenu from './NavigationMenu';

interface Props {
  showNavigation?: boolean;
}

/**
 * The `MobileActionBar` allows the visitor, when visiting a gallery, to access their cart
 * and upon toggling the menu button, view the navigation menu, which also has options
 * to view another gallery or sign out.
 * If the visitor is in the `Shop` feature, a secondary navigation (`MobileCategoriesMenu`)
 * is appended to the `MobileActionBar` that allows the user to navigate Shop categories,
 * and filter the products that are displayed
 *
 * When the user is on the Account Landing view, the `MobileActionBar`'s menu instead has the
 * account's contact details, if the studio permits displaying contact information. The toolbar
 * in this view also features top level actions to email or call the Studio
 */
const MobileActionBar = ({ showNavigation }: Props) => {
  const { company, contact, logoImage } = useSelector(selectAccount);
  const galleries = useSelector(selectAllGalleries);

  const [activeSegment, setActiveSegment] = useState<string | undefined>();
  const [showDrawer, setShowDrawer] = useState(false);

  const { pathname } = useLocation();
  const intl = useIntl();

  const ariaShowMenu = intl.formatMessage({
    id: 'navigation.ariaShowMenu',
    defaultMessage: 'Show Menu',
  });

  const isCheckout = pathname.includes('checkout');
  const isShopLanding = pathname.includes('/shop/') && !pathname.includes('/product/');
  const showMultiGalleryButton = galleries.filter(g => g.priceSheetID).length > 1 && !isCheckout;

  const toggleMenu = () => setShowDrawer(!showDrawer);

  const toggleSegment = useCallback(
    (segment?: string) => {
      setActiveSegment(activeSegment && segment === activeSegment ? undefined : segment);
    },
    [activeSegment],
  );

  const renderActions = () => {
    if (showNavigation) {
      return <CartButton />;
    }
    if (contact) {
      return <ContactActions />;
    }
    return null;
  };

  const renderMenu = () => {
    if (showNavigation) {
      return <NavigationMenu isOpen={showDrawer} onClose={toggleMenu} />;
    }
    if (contact) {
      return <ContactMenu isOpen={showDrawer} onClose={toggleMenu} />;
    }
    return null;
  };

  return (
    <Flex position="relative" direction="column">
      <Flex
        align="center"
        borderBottomColor="grey.2"
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        flexDirection={isCheckout ? 'row-reverse' : undefined}
        height={`${MOBILE_ACTION_BAR_HEIGHT}px`}
        justifyContent="space-between"
        paddingX="5px"
        position="relative"
      >
        {(showNavigation || !!contact) && !isCheckout && (
          <IconButton
            aria-label={ariaShowMenu}
            data-test="mobile-nav-show-menu-icon"
            fontSize="2xl"
            icon={<FaBars />}
            onClick={toggleMenu}
            variant="icon"
          />
        )}
        <Flex
          flexGrow={1}
          justifyContent="center"
          left="50%"
          position="absolute"
          top="50%"
          transform="translate(-50%, -50%)"
        >
          <Logo
            fallbackFontSize="sm"
            fallbackText={company}
            imageSrc={logoImage}
            margin="auto"
            maxHeight="40px"
          />
        </Flex>
        <Flex paddingRight={4} position="relative" right={0} direction="row">
          {showMultiGalleryButton && <MultiGalleryButton />}
          {renderActions()}
        </Flex>
        {renderMenu()}
      </Flex>
      {isShopLanding && (
        <MobileCategoriesMenu activeSegment={activeSegment} toggleSegment={toggleSegment} />
      )}
    </Flex>
  );
};

export default MobileActionBar;
