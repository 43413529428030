const Modal = {
  baseStyle: {},
  variants: {
    // This variant is a workaround to allow 'full' mode to work as expected in iOS
    // This is a Chakra issue that has persisted for a while, but unfortunately was closed due
    // to inactivity (https://github.com/chakra-ui/chakra-ui/issues/4680)
    full: {
      dialogContainer: {
        '@supports(height: -webkit-fill-available)': {},
        height: '100%',
      },
    },
  },
};

export default Modal;
