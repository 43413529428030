import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PriceSheetOption, ProductOption, SelectedOptions, api } from '../../../../shop-api-client';
import {
  selectCheckoutData,
  selectCheckoutOrderOptions,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import {
  selectPriceSheetMap,
  selectVisitKeytoPriceSheetIDMap,
} from '../../../redux/selectors/priceSheet.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { setCheckoutOrderOptions } from '../../../redux/slices/checkout.slice';
import { useAppDispatch } from '../../../redux/store';
import Option from '../../../shared/components/Option';
import { AVAILABLE_ADD_ONS, OTHER } from '../../../shared/constants';
import { NO_THANKS, YES_ADD } from '../../../shared/constants/labels.constants';
import { Params } from '../../../shared/types/router';
import { CheckoutStep } from '../CheckoutData';

const OrderOptions = ({ isActive, isComplete, setValidateCallback }: CheckoutStep) => {
  const checkoutData = useSelector(selectCheckoutData);
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const priceSheetMap = useSelector(selectPriceSheetMap);
  const visitKeytoPriceSheetIDMap = useSelector(selectVisitKeytoPriceSheetIDMap);
  const { requiredOptions, optionalOptions, optionIDToVisitKey } = useSelector(
    selectCheckoutOrderOptions,
  );

  const [data, setData] = useState<SelectedOptions>({});
  const [errors, setErrors] = useState<Record<number, boolean>>({});
  const [errorState, setErrorState] = useState(false);

  const { checkoutID } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const validate = useCallback(async () => {
    if (Object.keys(errors).length) {
      setErrorState(true);
      return false;
    }

    dispatch(setCheckoutOrderOptions(data));
    const result = await api.updateCheckout(currentVisitKey!, checkoutID!, {
      ...checkoutData,
      orderOptions: data,
    });
    return !!result;
  }, [checkoutData, checkoutID, dispatch, data, errors, currentVisitKey]);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [isActive, setValidateCallback, validate]);

  useEffect(() => {
    setData(checkoutData.orderOptions);
  }, [checkoutData]);

  useEffect(() => {
    const errors = requiredOptions.reduce<Record<string, boolean>>((map, option) => {
      const selectedOptionsForVisit = data[optionIDToVisitKey[option.id]];
      if (!(option.id in (selectedOptionsForVisit || {}))) {
        map[option.id] = true;
      }
      return map;
    }, {});

    setErrors(errors);
  }, [data, optionIDToVisitKey, requiredOptions]);

  const handleSelection =
    (visitKey: string, option: ProductOption) => (selectionID: number | false) => {
      setData({
        ...data,
        [visitKey]: {
          ...data[visitKey],
          [option.id]: selectionID,
        },
      });
    };

  const selectionLabel = (option: PriceSheetOption, selectionID: number | false) => {
    const selected = option.selections.find(s => s.catalogOptionID === selectionID);
    if (selected) {
      return option.type === 'boolean' || !selected.name ? YES_ADD : selected.name;
    }

    return NO_THANKS;
  };

  if (!isComplete && !isActive) {
    return null;
  }

  const renderCompletedText = (visitKey: string) => {
    const { orderOptionMap } = priceSheetMap[visitKeytoPriceSheetIDMap[visitKey]];
    const noneSelected = Object.values(data).every(o => Object.values(o).every(s => !s));

    if (noneSelected) {
      return (
        <Text fontSize="md">
          {intl.formatMessage({
            id: 'checkoutOrderOptions.noneSelected',
            defaultMessage: 'None Selected',
          })}
        </Text>
      );
    }

    if (!data[visitKey]) {
      return null;
    }

    return (
      <>
        {Object.entries(data[visitKey]).map(([optionID, selectionID]) => {
          const option = orderOptionMap[optionID];

          return (
            <Flex data-test="order-option-summary" key={optionID} display="inline">
              <Text variant="inline" fontSize="md">
                {option.name}
              </Text>
              <Text marginX={1} variant="inline" fontSize="md">
                :
              </Text>
              <Text variant="inline" fontSize="md" fontWeight="bold">
                {selectionLabel(option, selectionID)}
              </Text>
            </Flex>
          );
        })}
      </>
    );
  };

  const renderOption = (key: string, option: PriceSheetOption) => (
    <Box key={option.id} marginY={5}>
      <Option
        heading={option.name}
        isInvalid={errorState && errors[option.id]}
        option={option}
        selectionID={data[key]?.[option.id]}
        setSelectionID={handleSelection(key, option)}
        showRequired={option.requirementType === 'required'}
      />
    </Box>
  );

  if (isComplete && !isActive) {
    return (
      <Flex flexFlow="column" paddingX={10} paddingBottom={8}>
        {checkoutVisitKeys!.map((visitKey, index) => {
          return (
            <Flex
              flexFlow="column"
              key={visitKey}
              paddingBottom={index < checkoutVisitKeys!.length - 1 ? 6 : 0}
            >
              {renderCompletedText(visitKey)}
            </Flex>
          );
        })}
      </Flex>
    );
  }

  return (
    <Flex flexFlow="column" marginY={2}>
      {requiredOptions.map(option => renderOption(optionIDToVisitKey[option.id], option))}
      {!!optionalOptions.length && (
        <Box>
          <Heading fontSize="lg">
            {!!requiredOptions.length && OTHER + ' '}
            {AVAILABLE_ADD_ONS}
          </Heading>
          {optionalOptions.map(option => renderOption(optionIDToVisitKey[option.id], option))}
        </Box>
      )}
    </Flex>
  );
};

export default OrderOptions;
