import { ComponentWithAs, Flex, Heading, Icon, IconProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { IconType } from 'react-icons';

interface Props {
  children: ReactNode;
  detailCol: number;
  heading: string;
  icon: ComponentWithAs<'svg', IconProps> | IconType;
  showBorder?: boolean;
}

const DetailsBox = ({ children, heading, icon, detailCol, showBorder = true }: Props) => (
  <Flex
    borderColor="grey.2"
    borderLeftWidth={showBorder ? '2px' : ''}
    flexFlow="column"
    minW={`${Math.round(100 / detailCol)}%`}
    paddingX={8}
    paddingY={6}
  >
    <Icon as={icon} marginY={2} color="grey.5" />
    <Heading marginY={2} size="16px">
      {heading}
    </Heading>
    <Flex flexFlow="column" fontSize="14px" lineHeight={1.5}>
      {children}
    </Flex>
  </Flex>
);

export default DetailsBox;
