export const VERTICAL_WIDTH = 2;
export const VERTICAL_MARGIN = 10;

const Divider = {
  baseStyle: {},
  variants: {
    sidebar: {
      border: 0,
      borderBottomWidth: '1px',
      borderStyle: 'solid',
      marginLeft: '30px',
      maxWidth: '275px',
    },
    vertical: {
      borderColor: 'grey.8',
      borderLeftWidth: `${VERTICAL_WIDTH}px`,
      borderStyle: 'solid',
      height: 'auto',
      marginX: `${VERTICAL_MARGIN}px`,
      marginY: '4px',
    },
    dotted: {
      borderStyle: 'dotted',
      borderWidth: '2.5px',
      borderColor: 'grey.4',
      backgroundColor: 'transparent',
      background: 'none',
    },
  },
};

export default Divider;
