import { AspectRatio, AspectRatioProps, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { IMAGE_BADGE_Z_INDEX } from '../../constants';

interface Props extends AspectRatioProps {
  badgeSize?: string;
  fontSize?: string;
  icon?: IconType;
  maxWidth?: string;
  text?: string | number | undefined;
}

const ImageBadge = ({
  badgeSize = '25%',
  fontSize = 'xs',
  icon,
  maxWidth = '60px',
  text,
  ...rest
}: Props) => (
  <AspectRatio
    width={badgeSize}
    ratio={1}
    position="absolute"
    maxWidth={maxWidth}
    zIndex={IMAGE_BADGE_Z_INDEX}
    {...rest}
  >
    <Flex
      alignItems="center"
      bgColor={icon ? 'IQBLUE' : 'white'}
      border={icon ? '' : '1px solid grey'}
      borderRadius="50%"
      color={icon ? 'white' : 'black'}
      justifyContent="center"
      position="absolute"
      width="100%"
    >
      {icon && <Icon as={icon} fontSize={fontSize} resize="both" width="60%" height="60%" />}
      {text && (
        <Text noOfLines={1} fontSize="100%">
          {text}
        </Text>
      )}
    </Flex>
  </AspectRatio>
);

export default ImageBadge;
