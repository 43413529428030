import { Divider, Flex, Text } from '@chakra-ui/layout';
import React from 'react';

interface Props {
  children: React.ReactNode;
  heading: string;
}

const SummarySection = ({ children, heading }: Props) => (
  <Flex direction="column" marginY={5}>
    <Text>{heading}</Text>
    <Divider borderColor="grey.3" marginY={2} />
    {children}
  </Flex>
);

export default SummarySection;
