import { Flex, Link } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { selectBackgroundsMap } from '../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { useAppDispatch } from '../../../redux/store';
import { initializeSelectAndBuy } from '../../../redux/thunks/interactions.thunks';
import Thumbnail from '../../../shared/components/Thumbnail';
import { GRID_MAX_WIDTH } from '../../../shared/constants';
import { BUY_PHOTO } from '../../../shared/constants/labels.constants';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { ImageAndBackground } from '../../../shared/types/image';
import { Params } from '../../../shared/types/router';
import FavoriteButton from './FavoriteButton';

interface Props {
  imageList: ImageAndBackground[];
  imageSize: number;
  onSelectImage(imageName: string, backgroundID?: number): void;
  showNames: boolean;
  singlePose?: boolean;
}

const THUMBNAIL_PADDING = 32;
const BUTTON_HEIGHT = 40;
const BUTTON_GUTTER = 10;
const BTN_CONTAINER_HEIGHT = BUTTON_HEIGHT + BUTTON_GUTTER * 2;
const SHARED_BUTTON_STYLES = {
  fontSize: 'md',
  height: `${BUTTON_HEIGHT}px`,
  width: '140px',
};

const ImageGrid = ({ imageSize, imageList, onSelectImage, showNames, singlePose }: Props) => {
  const { images } = useSelector(selectGallery);
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const { key } = useParams<Params>();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const maxContainerWidth = Math.min(GRID_MAX_WIDTH, width);
  const imagesPerRowCount =
    Math.floor(maxContainerWidth / (imageSize + THUMBNAIL_PADDING * 2)) || 1;

  const handleBuyPhoto = (image: string, backgroundID?: number) => {
    dispatch(initializeSelectAndBuy(image, backgroundID));
  };

  return (
    <Flex flexWrap="wrap" margin="auto" maxWidth={imagesPerRowCount * imageSize}>
      {imageList.map(({ backgroundID, imageName }) => (
        <Flex
          alignItems="center"
          direction="column"
          flex="auto"
          justifyContent="center"
          key={singlePose ? `${imageName}-${backgroundID}` : imageName}
          maxHeight={`${imageSize + BTN_CONTAINER_HEIGHT}px`}
          maxWidth={`${imageSize}px`}
          marginBottom={12}
          paddingX={!isMobile ? `${THUMBNAIL_PADDING}px` : 0}
          paddingRight={isMobile ? '5px' : 0}
        >
          <Flex height="100%" alignItems="center">
            <Thumbnail
              background={backgroundID ? backgroundsMap[backgroundID]?.sources.full : undefined}
              cursor="pointer"
              data-test="image-grid-thumbnail-images"
              displayName={images[imageName].displayName}
              maxHeight={`${imageSize}px`}
              onClick={() => onSelectImage(imageName, backgroundID)}
              showNames={showNames}
              src={images[imageName].sources.thumb}
              lazyLoad
            />
          </Flex>

          <Flex
            bottom="0"
            justifyContent="center"
            padding={`${BUTTON_GUTTER}px`}
            position="relative"
          >
            <FavoriteButton
              backgroundID={backgroundID}
              image={images[imageName]}
              {...SHARED_BUTTON_STYLES}
            />
            <Link
              as={RouterLink}
              onClick={() => handleBuyPhoto(imageName, backgroundID)}
              to={{
                pathname: `/${key}/shop/all`,
                search: `?image=${imageName}${
                  images[imageName].isGreenScreen && backgroundID ? `&bg=${backgroundID}` : ''
                }`,
              }}
              variant="primary"
              data-test="image-grid-buy-photo"
              paddingTop={3}
              textAlign="center"
              {...SHARED_BUTTON_STYLES}
            >
              {BUY_PHOTO}
            </Link>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default ImageGrid;
