import { Heading, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  OptionForSelectionOption,
  SelectionOption as SelectionOptionType,
} from '../../../../../shop-api-client';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../redux/store';
import { NONE } from '../../../constants/labels.constants';
import { formatCurrency } from '../../../utils';
import NotificationPin from '../../NotificationPin';
import ChoiceContainer from './../ChoiceContainer';

const MAX_CHAR_LENGTH = 20;

interface Props {
  isInvalid?: boolean;
  onSelect(selection: OptionForSelectionOption | null, value?: string): void;
  option: SelectionOptionType;
  /** for selection options, only optional selection options can be opted out, so an undefined/null is an "opt out" in all cases */
  selectionID?: number | false;
  /**only used for multi-image image option selection */
  selectionBadges?: Record<number, number>;
}

const SelectionOption = ({ isInvalid, onSelect, option, selectionID, selectionBadges }: Props) => {
  const { currency } = useAppSelector(selectAccount);

  const isRequired = option.requirementType === 'required';
  const hasLongText = useMemo(
    () => option.selections.some(o => (o.name || '').length > MAX_CHAR_LENGTH),
    [option],
  );
  const hasBadges = !!selectionBadges && Object.keys(selectionBadges).length > 0;

  const padding = hasLongText ? 0 : 1;
  const SHARED_PROPS = {
    width: '100%',
    isSingleRow: hasLongText,
    _even: {
      marginLeft: padding,
    },
    _odd: {
      marginRight: padding,
    },
  };
  const renderSelection = (selection: OptionForSelectionOption) => {
    // Selection options with more than 1 selection, that have also been given badges
    // should not show selected state:
    const isSelected = selection.catalogOptionID === selectionID && !hasBadges;
    const dataCy = selection.name
      ? 'selection-option-' + selection.name.replace(/\s+/g, '-').toLowerCase()
      : 'label-not-present';
    return (
      <ChoiceContainer
        data-test={dataCy}
        isInvalid={isInvalid}
        key={selection.catalogOptionID}
        isSelected={isSelected}
        onClick={() => onSelect(selection, selection.name || '')}
        position="relative"
        {...SHARED_PROPS}
      >
        {!!selectionBadges?.[selection.catalogOptionID] && (
          <NotificationPin
            content={selectionBadges[selection.catalogOptionID]}
            fontSize="sm"
            height="25px"
            position="absolute"
            right="-8px"
            top="-5px"
            width="25px"
          />
        )}
        <Heading fontSize="md" textAlign="center">
          {selection.name}
        </Heading>
        <Text fontSize="md" marginTop={0.5} textAlign="center">
          {formatCurrency(option.selectionPricing ? selection.price : option.price, currency)}
        </Text>
      </ChoiceContainer>
    );
  };

  return (
    <SimpleGrid columns={hasLongText ? 1 : 2} spacing={1} width="100%" paddingRight="5px">
      {!isRequired && (
        <ChoiceContainer
          data-test="selection-option-none"
          isSelected={selectionID === false}
          onClick={() => onSelect(null)}
          {...SHARED_PROPS}
        >
          <Heading fontSize="md" marginBottom={1} textAlign="center">
            {NONE}
          </Heading>
        </ChoiceContainer>
      )}
      {option.selections.map(renderSelection)}
    </SimpleGrid>
  );
};

export default SelectionOption;
