import { Box, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ShopBackground,
  ShopImage,
  ShopProduct,
  ShopProductPrint,
} from '../../../../../../../../shop-api-client';
import {
  CartImageNodeCrop,
  CartImageNodeReq,
  CreateCartProductReq,
  ImageTone,
} from '../../../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../../../shop-api-client/models/ShopProductNodes';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../../../../../redux/selectors/background.selectors';
import {
  selectConfiguration,
  selectEditItemAvailableImages,
} from '../../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../../redux/selectors/gallery.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/store';
import {
  updateEditProductNode,
  updatePoseInPackage,
} from '../../../../../../../redux/thunks/configuration.thunks';
import ImageStepHeadings, {
  Step,
} from '../../../../../../../shared/components/ImageStepHeadings/ImageStepHeadings';
import { PRODUCTS_GUTTER } from '../../../../../constants';
import { EDITOR_SIDEBAR_WIDTH } from '../../../../PackageWizard/EditorSidebar';
import PackagePoseModal, {
  PoseToSwap,
} from '../../../../PackageWizard/EditorSidebar/PackagePoseModal/PackagePoseModal';
import {
  MULTI_NODE_IMAGE_WARNING,
  SELECT_YOUR_PHOTO,
  THUMB_GRID_MAX_HEIGHT,
  THUMB_GRID_MAX_WIDTH,
} from '../../../../constants';
import {
  getBackgroundForPoseSwap,
  getProductToNodeKey,
  shapeCartImageNode,
} from '../../../../utils';
import ImageSelectionPresets from '../../../ImageSelectionPresets';
import ImageNodeBackgroundDisplay from '../../ImageNodeBackgroundDisplay';
import ImageNodeDisplay from '../../ImageNodeDisplay';
import ImageNodeToneDisplay from '../../ImageNodeToneDisplay';
import MultiNodeCropEditor from '../MultiNodeCropEditor';

interface Props {
  activeStep: Step;
  catalogNodes: ShopImageNodeWithReqType[];
  editProduct: CreateCartProductReq;
  nodes: CartImageNodeReq[];
  poseLimit?: number;
  poseMap?: Record<string, number[]>;
  setActiveStep(step: Step): void;
  shopProduct: ShopProduct;
}

const MultiNodeConfiguration = ({
  activeStep,
  catalogNodes,
  editProduct,
  nodes,
  poseLimit,
  poseMap = {},
  setActiveStep,
  shopProduct,
}: Props) => {
  const availableImages = useAppSelector(selectEditItemAvailableImages);
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const orderedBackgrounds = useAppSelector(selectAllBackgrounds);
  const { editNodeID } = useAppSelector(selectConfiguration);
  const {
    images,
    isGreenScreen,
    settings: { disableCropping, showBwAndSepia },
    type,
  } = useAppSelector(selectGallery);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // State
  const [imageTone, setImageTone] = useState<ImageTone>('original');
  const [poseHadBackground, setPoseHadBackground] = useState(true);
  const [newPose, setNewPose] = useState<PoseToSwap | null>(null);
  const [showWarning, setShowWarning] = useState(false);

  const [isMobile] = useMediaQuery('(max-width: 48em)', { ssr: false });

  const poseMapKeys = Object.keys(poseMap);
  const poseLimitReached = poseLimit && poseMapKeys.length >= poseLimit;
  const isSinglePose = poseLimit === 1;

  const getAvailableNodeImages = useCallback(
    (catalogNodeID: number) => {
      const nodeKey = getProductToNodeKey(editProduct.priceSheetItemID, catalogNodeID);
      return Object.values(availableImages.nodes[nodeKey] || {});
    },
    [availableImages, editProduct],
  );

  const { catalogNode, disabledSteps, node, steps } = useMemo(() => {
    // Pull the Cart & Catalog Node
    const node = editNodeID ? nodes.find(node => node.catalogNodeID === editNodeID) : undefined;
    const catalogNode = node
      ? catalogNodes.find(catalogNode => catalogNode.id === node.catalogNodeID)
      : undefined;
    const nodeImages = catalogNode ? getAvailableNodeImages(catalogNode.id) : [];

    const steps: Step[] = ['photo'];

    // For green screen galleries, make sure there's at least one png available, and no preset image
    if (
      nodeImages.length !== 1 &&
      isGreenScreen &&
      nodeImages.some(image => image.isGreenScreen && !catalogNode?.skipBackgroundSelection)
    ) {
      steps.push('background');
    }

    if (!disableCropping) {
      steps.push('crop');
    }

    if (showBwAndSepia && !isGreenScreen) {
      steps.push('effects');
    }

    // The disabled state depends on:
    // - If the user clicked on a step other than "photo" before selecting an image: showWarning
    // - For the background step - a non-green screen image was selected
    const disabledSteps: Step[] = [];

    if (showWarning && !node?.imageDisplayName) {
      disabledSteps.push('background', 'crop', 'effects');
    } else if (
      (node?.imageInternalName && !images[node.imageInternalName].isGreenScreen) ||
      catalogNode?.skipBackgroundSelection
    ) {
      disabledSteps.push('background');
    }

    return {
      catalogNode,
      disabledSteps,
      node,
      steps,
    };
  }, [
    catalogNodes,
    disableCropping,
    editNodeID,
    getAvailableNodeImages,
    images,
    isGreenScreen,
    nodes,
    showBwAndSepia,
    showWarning,
  ]);

  const handleChangeCrop = useCallback(
    (crop: CartImageNodeCrop) => {
      if (node && editProduct) {
        dispatch(
          updateEditProductNode(editProduct.id!, {
            ...node,
            ...crop,
          }),
        );
      }
    },
    [dispatch, editProduct, node],
  );

  useEffect(() => {
    // Reset whenever the node changes
    setActiveStep('photo');
    setImageTone('original');
    setShowWarning(false);
  }, [editNodeID, setActiveStep]);

  if (!catalogNode || !node || !editProduct) {
    return null;
  }

  /**
   * Update an image/background selection in a multi-node product.
   *
   * Note: this function is identical to `PackageSingleNode.handleSelectImage`
   */
  const handleSelectImage = (image: ShopImage, background?: ShopBackground) => {
    if (!catalogNode) {
      return;
    }

    if (showWarning) {
      setShowWarning(false);
    }

    let selectedBackground = background;
    const skipBackground = catalogNode.skipBackgroundSelection;
    // If no background was passed, but this is a green screen image...
    if (!background && image.isGreenScreen && !skipBackground) {
      // Find whether the image is already in the poseMap:
      const existingKey = poseMapKeys.find(k => k.includes(`${image.internalName}-`));

      // If a key is found for the image, grab the background id to set selectedBackground.
      // This is done so that defaulting to the first background will not potentially count
      // toward, or exceed, the visitor's allowed pose count.
      if (existingKey) {
        const backgroundID = existingKey.split('-')[1];
        selectedBackground = backgroundsMap[backgroundID];
      } else {
        // Default to the first background of the first set
        selectedBackground = orderedBackgrounds[0];
      }
    }

    let shouldUpdateNode = true;

    // Create two keys, one pointing to the old image assigned to the node, one to the newly selected one
    const { imageInternalName, backgroundID } = node;
    const prevKey = backgroundID ? `${imageInternalName}-${backgroundID}` : imageInternalName;
    const newKey = selectedBackground
      ? `${image.internalName}-${selectedBackground.id}`
      : image.internalName;

    // Automatically update the pose if:
    // - This is a single pose package
    // - This product is the only one that uses the old image
    // Prompt for input from the user if:
    // - The incoming selection is a new pose, and we're already at the limit
    if (poseLimitReached && catalogNode.imageRequirementType !== 'group') {
      const isExclusiveToProduct =
        prevKey &&
        poseMap[prevKey] &&
        poseMap[prevKey].length === 1 &&
        poseMap[prevKey][0] === editProduct.id!;

      if (isExclusiveToProduct) {
        const nextBackgroundID = getBackgroundForPoseSwap(
          poseMapKeys,
          selectedBackground?.id,
          skipBackground,
        );

        dispatch(
          updatePoseInPackage(
            imageInternalName!,
            backgroundID,
            image.internalName,
            nextBackgroundID,
            isSinglePose,
          ),
        );
      } else if (!poseMap[newKey]) {
        // We need to ask the user what pose they want to replace
        setNewPose({ imageName: image.internalName, backgroundID: selectedBackground?.id });
        setPoseHadBackground(!!background);
        shouldUpdateNode = false;
      }
    }

    if (shouldUpdateNode) {
      const shapedImgNode = shapeCartImageNode(catalogNode, image, selectedBackground, 'original');
      dispatch(updateEditProductNode(editProduct.id!, shapedImgNode, true));

      if (image.isGreenScreen && !catalogNode.skipBackgroundSelection && !background) {
        setActiveStep('background');
      }
    }
  };

  const handleSelectStep = (step: Step) => {
    // If no image has been selected; set the error state;
    if (!node.imageInternalName) {
      setShowWarning(true);
      return;
    }

    setActiveStep(step);
  };

  const handleSelectTone = (imageTone: ImageTone) => {
    setImageTone(imageTone);
    dispatch(updateEditProductNode(editProduct.id!, { ...node, imageTone }));
  };

  const handleSwapPose = () => {
    if (!newPose) {
      return;
    }

    const { imageName, backgroundID } = newPose;
    const image = images[imageName];
    const background = backgroundID ? backgroundsMap[backgroundID] : undefined;

    const shapedImgNode = shapeCartImageNode(catalogNode, image, background, 'original');
    dispatch(updateEditProductNode(editProduct.id!, shapedImgNode));

    if (image.isGreenScreen && !poseHadBackground) {
      setActiveStep('background');
    }
  };

  const renderPoseModal = () => {
    if (!newPose) {
      return null;
    }

    return (
      <PackagePoseModal
        catalogNode={catalogNode}
        onClose={() => setNewPose(null)}
        onConfirm={handleSwapPose}
        poseMap={poseMap}
        newPose={newPose}
      />
    );
  };

  const renderStep = (step: Step) => {
    if (step === 'photo') {
      const nodeImages = getAvailableNodeImages(node.catalogNodeID);

      if ((type !== 'standard' && catalogNode.defaultImage) || nodeImages.length === 1) {
        return (
          <ImageSelectionPresets
            imageMaxHeight={isMobile ? '100px' : THUMB_GRID_MAX_HEIGHT}
            imageMaxWidth={isMobile ? '130px' : THUMB_GRID_MAX_WIDTH}
            images={nodeImages}
            selectedBackgrounds={node.backgroundID ? [node.backgroundID] : undefined}
            selectImage={handleSelectImage}
            selectedImageName={node.imageInternalName}
            showAsHorizontalList={isMobile}
            skipBackgroundSelection={catalogNode.skipBackgroundSelection}
          />
        );
      }

      // In multi-node we only show the selected image for the currently selected node
      // highlighted in blue, everything else uses a counter:
      const selectedImages = node.imageInternalName
        ? {
            [`${node.imageInternalName}-${node.backgroundID || undefined}`]: {
              imageName: node.imageInternalName,
              backgroundID: node.backgroundID || undefined,
            },
          }
        : {};

      const selectedImagesCounter = nodes.reduce<Record<string, number>>((result, n) => {
        // Only add images that don't match the currently selected one
        if (n.imageInternalName && n.imageInternalName !== node.imageInternalName) {
          result[n.imageInternalName] = (result[n.imageInternalName] || 0) + 1;

          // If there is a background, also add that:
          if (n.backgroundID) {
            const imageKey = `${n.imageInternalName}-${n.backgroundID}`;
            result[imageKey] = (result[imageKey] || 0) + 1;
          }
        }

        return result;
      }, {});

      return (
        <ImageNodeDisplay
          imageMaxWidth={isMobile ? '130px' : THUMB_GRID_MAX_WIDTH}
          imageMaxHeight={isMobile ? '100px' : THUMB_GRID_MAX_HEIGHT}
          imageRequirementType={catalogNode.imageRequirementType}
          selectedImagesCounter={selectedImagesCounter}
          selectedImages={selectedImages}
          selectImage={handleSelectImage}
          showAsHorizontalList={isMobile}
          showErrorOutline={showWarning}
          skipBackgroundSelection={catalogNode.skipBackgroundSelection}
        />
      );
    }

    // All other steps require a selected image
    if (!node.imageInternalName) {
      return null;
    }

    if (step === 'background') {
      return (
        <ImageNodeBackgroundDisplay
          columns={3}
          image={images[node.imageInternalName]}
          selectBackground={handleSelectImage}
          selectedBackgrounds={node.backgroundID ? [node.backgroundID] : []}
          showAsHorizontalList={isMobile}
          showBackgroundName={false}
        />
      );
    }

    if (step === 'crop') {
      const crop: CartImageNodeCrop = {
        cropH: node.cropH!,
        cropW: node.cropW!,
        cropX: node.cropX!,
        cropY: node.cropY!,
        orientation: node.orientation!,
      };

      return (
        <MultiNodeCropEditor
          catalogNode={catalogNode}
          crop={crop}
          editProduct={editProduct}
          handleCrop={handleChangeCrop}
          onClose={() => setActiveStep('photo')}
          shopBackground={node.backgroundID ? backgroundsMap[node.backgroundID] : null}
          shopImage={images[node.imageInternalName]}
          shopProduct={shopProduct as ShopProductPrint}
        />
      );
    }

    if (step === 'effects') {
      return (
        <Box paddingX={{ base: 2, md: 0 }} marginTop={4}>
          <Heading size="xs" marginTop={2} marginBottom={4}>
            {intl.formatMessage({
              id: 'multiNodeConfiguration.allEffects',
              defaultMessage: 'All Effects (3)',
            })}
          </Heading>
          <ImageNodeToneDisplay
            selectedTone={imageTone}
            selectTone={handleSelectTone}
            useOffWhite={false}
          />
        </Box>
      );
    }
  };

  const renderWarning = () => {
    if (!showWarning) {
      return null;
    }

    if (isMobile) {
      // TODO: modal?
      return null;
    }

    return (
      <Text color="error" paddingTop={4}>
        {MULTI_NODE_IMAGE_WARNING}
      </Text>
    );
  };

  if (isMobile) {
    return (
      <Flex backgroundColor="white" bottom={0} direction="column" marginTop="auto" width="100%">
        {renderWarning()}
        <Box backgroundColor="grey.1">{renderStep(activeStep)}</Box>
        <ImageStepHeadings
          activeStep={activeStep}
          disabled={disabledSteps}
          handleSelectStep={handleSelectStep}
          position="bottom"
          steps={steps}
        />
        {renderPoseModal()}
      </Flex>
    );
  }

  return (
    <Flex
      borderColor="grey.2"
      borderLeftWidth={isMobile ? 0 : 1}
      direction="column"
      height="calc(100vh - 58px)"
      flexShrink={0}
      overflowY="auto"
      paddingX={`${PRODUCTS_GUTTER * 2}px`}
      position="sticky"
      width={`${EDITOR_SIDEBAR_WIDTH}px`}
    >
      <Heading size="md" marginX="auto" paddingTop={12}>
        {SELECT_YOUR_PHOTO}
      </Heading>
      <ImageStepHeadings
        activeStep={activeStep}
        disabled={disabledSteps}
        handleSelectStep={handleSelectStep}
        position="top"
        steps={steps}
      />
      {renderWarning()}
      {renderStep(activeStep)}
      {renderPoseModal()}
    </Flex>
  );
};

export default MultiNodeConfiguration;
