import axios, { AxiosError } from 'axios';
import { CatalogProductWithMetadata } from 'iq-product-render';
import { signout } from '../app/redux/slices/visitor.slice';
import store from '../app/redux/store';
import { FORBIDDEN } from '../app/shared/constants';
import {
  AuthSession,
  CartCheckoutEntity,
  CheckoutFormData,
  CreateCheckoutResult,
  CustomDataSpec,
  GalleryLandingData,
  ShopData,
  ShopProduct,
  TokenRegenerationResults,
} from './models';
import { AccountLandingData } from './models/Account';
import { AccountGallery } from './models/AccountGallery';
import {
  CartError,
  CartFinancials,
  CartImageOption,
  CartImageOptionReq,
  CartPackage,
  CartProduct,
  CheckoutFinancials,
  CreateCartPackageReq,
  CreateCartProductReq,
  OrderFinancials,
  ShopFavorite,
  UpdateCartImageOption,
  UpdateCartPackageReq,
  UpdateCartProductReq,
  VisitWithCart,
} from './models/Cart';
import { ApplyDiscount, ApplyDiscountResult, Discount } from './models/Discounts';
import { DigitalDownload } from './models/Download';
import { FacialRecognitionInitials } from './models/FacialRecognition';
import { ShopProductNodes } from './models/ShopProductNodes';
import { UnsubscribeResponse, UnsubscribeShop, UnsubscribeUpdate } from './models/Unsubscribe';

export interface LoginCredentials {
  email?: string;
  onlineCode?: string;
  galleryPassword?: string;
  canNotify?: boolean;
  mid?: string;
}

export interface ShopApiClientConfig {
  apiServer: string;
}

export type HttpMethod = 'get' | 'post' | 'patch' | 'put' | 'delete';

export class ShopApiClient {
  constructor(private config: ShopApiClientConfig) {
    this.config = config;
  }

  // ------------------------------------ Auth ------------------------------------

  async authSession(key: string, mid?: string, campaign?: string) {
    const path = `/auth/session/${key}`;
    const params: Record<string, string> = {};
    if (mid) {
      params.mid = mid;
    }
    if (campaign) {
      params.campaign = campaign;
    }
    return this.executeRequest<AuthSession>('get', path, { params });
  }

  async authLogin(key: string, data: LoginCredentials, campaign: string) {
    const path = `/auth/login/${key}`;
    return this.executeRequest<AuthSession>('post', path, { data: { ...data, campaign } });
  }

  async authLogout() {
    const path = `/auth/logout`;
    return this.executeRequest('post', path);
  }

  // ------------------------------------ Cart ------------------------------------

  async getCarts(key: string) {
    const path = `/cart/${key}`;
    return this.executeRequest<Record<string, VisitWithCart>>('get', path);
  }

  async getFinancials(key: string) {
    const path = `/cart/${key}/financials`;
    return this.executeRequest<{
      cartFinancials: Record<string, CartFinancials>;
      summaryFinancials: OrderFinancials;
    }>('get', path);
  }

  async validateCarts(key: string) {
    const path = `/cart/${key}/validate`;
    return this.executeRequest<Record<string, CartError[]>>('get', path);
  }

  async createCartProduct(key: string, data: CreateCartProductReq) {
    const path = `/cart/${key}/product`;
    return this.executeRequest<CartProduct>('post', path, { data });
  }

  async updateCartProduct(key: string, data: UpdateCartProductReq) {
    const path = `/cart/${key}/product/${data.id}`;
    return this.executeRequest<CartProduct>('patch', path, { data });
  }

  async deleteCartProduct(key: string, id: number) {
    const path = `/cart/${key}/product/${id}`;
    return this.executeRequest<void>('delete', path);
  }

  async createCartPackage(key: string, data: CreateCartPackageReq) {
    const path = `/cart/${key}/package`;
    return this.executeRequest<CartPackage>('post', path, { data });
  }

  async updateCartPackage(key: string, data: UpdateCartPackageReq) {
    const path = `/cart/${key}/package/${data.id}`;
    return this.executeRequest<CartPackage>('patch', path, { data });
  }

  async deleteCartPackage(key: string, id: number) {
    const path = `/cart/${key}/package/${id}`;
    return this.executeRequest<void>('delete', path);
  }

  async createCartImageOption(key: string, data: CartImageOptionReq) {
    const path = `/cart/${key}/image-option`;
    return this.executeRequest<CartImageOption>('post', path, { data });
  }

  async updateCartImageOption(key: string, data: UpdateCartImageOption) {
    const path = `/cart/${key}/image-option/${data.id}`;
    return this.executeRequest<CartImageOption>('patch', path, { data });
  }

  async deleteCartImageOption(key: string, id: number) {
    const path = `/cart/${key}/image-option/${id}`;
    return this.executeRequest<void>('delete', path);
  }

  // ----------------------------------- Catalog -----------------------------------

  async getCatalogProduct(key: string, product: ShopProduct) {
    const path = `/catalog/${key}/service/${product.serviceID}/product/${product.catalogProductID}`;
    return this.executeRequest<CatalogProductWithMetadata>('get', path);
  }

  // ------------------------------------ Checkout ------------------------------------

  async getCheckout(key: string, checkoutID: string) {
    const path = `/${key}/checkout/${checkoutID}`;
    return this.executeRequest<CartCheckoutEntity>('get', path);
  }

  async getCheckoutFinancials(key: string, checkoutID: string) {
    const path = `/${key}/checkout/${checkoutID}/financials`;
    return this.executeRequest<CheckoutFinancials>('get', path);
  }

  async createCheckout(key: string, visitKeys?: string[]) {
    const path = `/${key}/checkout`;
    const data = visitKeys ? { visitKeys } : undefined;
    return this.executeRequest<CreateCheckoutResult>('post', path, { data });
  }

  async updateCheckout(key: string, checkoutID: string, formData: CheckoutFormData) {
    const path = `/${key}/checkout/${checkoutID}`;
    return this.executeRequest<CartCheckoutEntity>('patch', path, { data: formData });
  }

  async stageCheckout(key: string, checkoutID: string, formData: any) {
    const path = `/${key}/checkout/${checkoutID}/stage`;
    return this.executeRequest<CartCheckoutEntity>('put', path, { data: formData });
  }

  async getCheckoutImageOptions(key: string, checkoutID: string) {
    const path = `/${key}/checkout/${checkoutID}/image-options`;
    return this.executeRequest<CheckoutFinancials>('get', path);
  }

  async getDiscounts(key: string, checkoutID: string) {
    const path = `/${key}/checkout/${checkoutID}/discount`;
    return this.executeRequest<Record<string, Discount[]>>('get', path);
  }

  async applyDiscount(key: string, checkoutID: string, discount: ApplyDiscount) {
    const path = `/${key}/checkout/${checkoutID}/discount`;
    return this.executeRequest<ApplyDiscountResult>('put', path, {
      data: discount,
    });
  }

  async removeDiscount(key: string, checkoutID: string) {
    const path = `/${key}/checkout/${checkoutID}/discount`;
    return this.executeRequest<void>('delete', path);
  }

  // ------------------------------- Metrics -------------------------------
  async upsertCESMetrics(key: string, checkoutID: string, cesValue: number) {
    const path = `/${key}/checkout/${checkoutID}/ces`;
    return this.executeRequest<void>('put', path, { data: { cesValue } });
  }

  // ------------------------------------ Download ------------------------------------
  getZipStreamURL(token: string) {
    return `${this.config.apiServer}/download/${token}/stream`;
  }

  async regenerateDownloadToken(token: string): Promise<TokenRegenerationResults> {
    const path = `/download/${token}`;
    return this.executeRequest<TokenRegenerationResults>('post', path);
  }

  async validateDownloadToken(token: string): Promise<DigitalDownload> {
    const path = `/download/${token}`;
    return this.executeRequest<DigitalDownload>('get', path);
  }

  // ------------------------------- Facial Recognition -------------------------------
  async createFacialRecognitionRegistration(galleryID: number, payload: any) {
    const path = `/facial-recognition/${galleryID}/register`;
    return this.executeRequest<string>('post', path, { data: payload });
  }

  async updateFacialRecognitionRegistration(token: string, payload: any) {
    const path = `/facial-recognition/session/${token}`;
    return this.executeRequest<void>('put', path, { data: payload });
  }

  async uploadFacialRecognitionImage(token: string, payload: any) {
    const path = `/facial-recognition/session/${token}/image`;
    return this.executeRequest<void>('post', path, { data: payload });
  }

  async initializeFacialRecognition(galleryID: string, token?: string) {
    const path = `/facial-recognition/${galleryID}`;
    return this.executeRequest<FacialRecognitionInitials>('get', path, { params: { token } });
  }

  // ------------------------------------ Order ------------------------------------

  async getOrder(key: string, orderID: number) {
    const path = `/${key}/order/${orderID}`;
    return this.executeRequest<any>('get', path);
  }

  // ------------------------------------ Unsubscribe ------------------------------

  async getUnsubscribeRecord(queueMessageID: string) {
    const path = `/unsubscribe/${queueMessageID}`;
    return this.executeRequest<UnsubscribeResponse>('get', path).catch(() => null);
  }

  async postUnsubscribeRecord(queueMessageID: string, data: UnsubscribeUpdate) {
    const path = `/unsubscribe/${queueMessageID}`;
    return this.executeRequest<UnsubscribeResponse>('post', path, { data }).catch(() => null);
  }

  async deleteUnsubscribeRecord(queueMessageID: string) {
    const path = `/unsubscribe/${queueMessageID}`;
    return this.executeRequest<void>('delete', path).catch(() => null);
  }

  async postShopUnsubscribe(data: UnsubscribeShop) {
    const path = `/unsubscribe/shop/`;
    return this.executeRequest<UnsubscribeResponse>('post', path, { data });
  }

  async putUnsubscribeRecord(queueMessageID: string, data: UnsubscribeUpdate) {
    const path = `/unsubscribe/${queueMessageID}`;
    return this.executeRequest<UnsubscribeResponse>('put', path, { data }).catch(() => null);
  }

  async deleteShopUnsubscribe(data: UnsubscribeShop) {
    const path = `/unsubscribe/shop/`;
    return this.executeRequest<void>('delete', path, { data });
  }

  // ------------------------------------ Other ------------------------------------

  async getAccountLanding(accountID: string) {
    const path = `/account/${accountID}`;
    return this.executeRequest<AccountLandingData>('get', path);
  }

  async getAllProductNodes(key: string) {
    const path = `/gallery/${key}/nodes`;
    return this.executeRequest<ShopProductNodes>('get', path);
  }

  async getBaseGalleryData(key: string) {
    const path = `/gallery/${key}/landing`;
    return this.executeRequest<GalleryLandingData>('get', path);
  }

  async getCustomForm(key: string, customDataSpecID: number) {
    const path = `/gallery/${key}/custom-form/${customDataSpecID}`;
    return this.executeRequest<CustomDataSpec>('get', path);
  }

  async getAccountGalleries(
    accountID: string | null,
    key: string | null,
    code: string | null,
    search: string | null,
  ) {
    const path = `/account/galleries`;
    const params = { accountID, key, code, search };
    return this.executeRequest<{ galleries: AccountGallery[]; nextVisitKey: string | null }>(
      'get',
      path,
      { params },
    );
  }

  async getShopData(key: string) {
    const path = `/gallery/${key}/full`;
    return this.executeRequest<ShopData>('get', path);
  }

  async postYearbookSelections(key: string, poses: (string | null)[]) {
    const path = `/yearbook/${key}`;
    return this.executeRequest<void>('post', path, { data: poses });
  }

  async addFavorite(key: string, data: { imageName: string; backgroundID?: number | null }) {
    const path = `/${key}/visitor/favorite`;
    return this.executeRequest<ShopFavorite>('post', path, { data });
  }

  async deleteFavorite(key: string, favoriteID: number) {
    const path = `/${key}/visitor/favorite/${favoriteID}`;
    return this.executeRequest<void>('delete', path);
  }

  private async executeRequest<T>(
    method: HttpMethod,
    path: string,
    options?: { data?: Record<string, any>; params?: Record<string, any> },
  ) {
    const url = `${this.config.apiServer}${path}`;
    return axios
      .request<T>({
        method,
        url,
        // Pass cookie with cross-domain calls
        withCredentials: true,
        ...options,
      })
      .then(response => response.data)
      .catch((error: AxiosError) => {
        // TODO: handle errors better?
        console.log(error);

        // Sign out of stale sessions
        if (error.response?.status === FORBIDDEN) {
          console.error('[Unauthorized] Signing out...');
          store.dispatch(signout());
        }

        return Promise.reject(error);
      });
  }
}

export const api = new ShopApiClient({
  apiServer: process.env.REACT_APP_API_SERVER || 'https://api.imagequix.com/shop',
});
