import { EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import { Button, Icon, Link, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import FacialRecognitionStepHeader from '../FacialRecognitionStepHeader';

interface Props {
  studioName: string;
  isActive: boolean;
  isComplete: boolean;
  onEdit(): void;
  onSubmit(): void;
  stepNumber: number;
}

const AcceptConditionsStep = forwardRef<HTMLDivElement, Props>(
  ({ isActive, isComplete, onEdit, onSubmit, stepNumber, studioName }, ref) => {
    const intl = useIntl();

    const handleSubmit = () => {
      onSubmit();
    };

    const renderCompletedForm = () => {
      return (
        <Flex
          borderColor="grey.2"
          borderRadius="md"
          borderStyle="solid"
          borderWidth="1px"
          flexDirection="column"
          marginBottom={2}
          padding={2}
          position="relative"
        >
          <FacialRecognitionStepHeader
            isActive={isActive}
            isComplete={isComplete}
            stepNumber={stepNumber}
            stepTitle="Terms & Conditions"
          />
          <Icon
            as={EditIcon}
            color="brand"
            onClick={onEdit}
            position="absolute"
            right={3}
            top={3}
          />
        </Flex>
      );
    };

    const renderAgreement = () => (
      <Flex
        borderBottomWidth={isComplete ? undefined : '1px'}
        borderColor="grey.5"
        flexDirection="column"
        paddingY={4}
        ref={ref}
      >
        <FacialRecognitionStepHeader
          isActive={isActive}
          isComplete={isComplete}
          stepNumber={stepNumber}
          stepTitle="Biometric Data Policy"
        />
        <Flex flexDirection="column" paddingY={6}>
          {intl.formatMessage({
            id: 'facialRecognition.conditionsFirstParagraph',
            defaultMessage: 'By clicking "I accept" below, you acknowledge and agree that:',
          })}
          <OrderedList>
            <ListItem paddingY={4}>
              {intl.formatMessage(
                {
                  id: 'facialRecognition.conditionsSecondParagraph',
                  defaultMessage: `ImageQuix is a service provider to {studioName}, which you are a
                  customer of, and in connection with its services to {studioName}, and on behalf of
                  {studioName}, ImageQuix will collect, capture, receive, or otherwise acquire the following
                  biometric identifiers about you (your "Biometric Data"):  facial scan information, including face
                  geometry information and characteristics derived by facial recognition and other technologies.
                `,
                },
                { studioName },
              )}
            </ListItem>
            <ListItem paddingY={4}>
              <Text paddingBottom={2}>
                {intl.formatMessage(
                  {
                    id: 'facialRecognition.conditionsThirdParagraph',
                    defaultMessage: `ImageQuix may collect, use, disclose, and otherwise process your Biometric Data
                    on behalf of {studioName} in connection with the services that ImageQuix provides to {studioName},
                    including to provide features that allow you and/or {studioName} to search databases or repositories
                    of photos to determine which photos you appear in.
                  `,
                  },
                  { studioName },
                )}
              </Text>
              <Text paddingBottom={2}>
                {intl.formatMessage({
                  id: 'facialRecognition.conditionsFourthParagraph',
                  defaultMessage: `Unless otherwise required by law, your Biometric Data is accessible only to ImageQuix
                    (and our service providers working on our behalf, such as our technology vendors).`,
                })}
              </Text>
              <Text paddingBottom={2}>
                {intl.formatMessage({
                  id: 'facialRecognition.conditionsFifthParagraph',
                  defaultMessage: `You consent to ImageQuix's collection, use, and storage of your Biometric Data
                    for the purposes referenced above, and any other purposes that you specifically
                    consent to.`,
                })}
              </Text>
            </ListItem>
            <ListItem>
              {intl.formatMessage({
                id: 'facialRecognition.conditionsAgree',
                defaultMessage: `You have read and agree to ImageQuix's `,
              })}{' '}
              <Link
                href="https://www.imagequix.com/biometric-privacy-policy/"
                target="_blank"
                color="brand"
                fontWeight="bold"
              >
                {intl.formatMessage({
                  id: 'facialRecognition.biometricsInformationPrivacyPolicy',
                  defaultMessage: 'Biometric Information Privacy Policy',
                })}
              </Link>
            </ListItem>
          </OrderedList>
          <Button marginTop={6} onClick={handleSubmit}>
            {intl.formatMessage({
              id: 'facialRecognition.conditionsAccept',
              defaultMessage: 'I Accept',
            })}
          </Button>
        </Flex>
      </Flex>
    );

    if (isActive) {
      return renderAgreement();
    }

    if (isComplete) {
      return renderCompletedForm();
    }

    return (
      <Flex borderBottomWidth="1px" borderColor="grey.5" flexDirection="column" paddingY={4}>
        <FacialRecognitionStepHeader
          isActive={false}
          isComplete={false}
          stepNumber={stepNumber}
          stepTitle="Terms & Conditions"
        />
      </Flex>
    );
  },
);

AcceptConditionsStep.displayName = 'AcceptConditionsStep';
export default AcceptConditionsStep;
