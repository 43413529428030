import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ImageRequirementType,
  ShopBackground,
  ShopImage,
  ShopPackage,
} from '../../../../../../shop-api-client';
import {
  selectActiveStep,
  selectConfiguration,
  selectEditItemAvailableImages,
  selectIsStepCompleted,
  selectPackageItemMap,
  selectSinglePoseImage,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import { setCompletedSteps, setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { assignImageToSinglePosePackage } from '../../../../../redux/thunks/configuration.thunks';
import { Params } from '../../../../../shared/types/router';
import ImageSelection from '../../shared/ImageSelection';
import { getPackageImageAssignmentStep, validatePackageImageSelection } from '../../utils';

const PackageImageSelection = () => {
  const stepName = useMemo(() => getPackageImageAssignmentStep(), []);

  const { editPackage, editStep } = useSelector(selectConfiguration);
  const { images, isGreenScreen, type: galleryType } = useSelector(selectGallery);
  const { background, image } = useSelector(selectSinglePoseImage);
  const { productNodeMap, products } = useSelector(selectPriceSheet);
  const { package: packageImages } = useSelector(selectEditItemAvailableImages);
  const activeStep = useSelector(selectActiveStep);
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));
  const packageItemMap = useSelector(selectPackageItemMap);

  const [ready, setReady] = useState(false);

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const imgList = Object.values(images);
  const isActive = activeStep === getPackageImageAssignmentStep();
  const isDisabled =
    !isActive &&
    !validatePackageImageSelection(
      editPackage!,
      images,
      productNodeMap,
      products[editPackage!.priceSheetItemID] as ShopPackage,
    );
  const isSingleGreenScreen = isGreenScreen && imgList.length === 1 && imgList[0].isGreenScreen;

  useEffect(() => {
    if (completed && !ready) {
      setReady(true);
      return;
    }
    if (ready) {
      return;
    }

    if (image && (!isGreenScreen || background)) {
      dispatch(setCompletedSteps({ [getPackageImageAssignmentStep()]: true }));
    }

    setReady(true);
  }, [background, completed, dispatch, image, isGreenScreen, ready]);

  /**
   * Returns the shared image requirement type of the editPackage's sub-items.
   * However, if the gallery is `standard` or if the package has multiple requirement
   * types across its sub-items, `undefined` is returned.
   *
   * This function is used so that the ImageSelection component will display only
   * the image sets, group or subject, that are acceptable for assignment.
   */
  const getImageRequirementType = () => {
    let requirementType: ImageRequirementType | null = null;

    if (galleryType === 'standard') {
      return;
    }

    for (const item of editPackage!.products) {
      const shopItem = packageItemMap[item.priceSheetItemID];
      if (shopItem.type === 'collection' || shopItem.type === 'imageDownload') {
        if (requirementType && shopItem.imageRequirementType !== requirementType) {
          return;
        } else {
          requirementType = shopItem.imageRequirementType;
        }
      } else if (shopItem.type === 'product') {
        for (const irp of shopItem.imageRequirementProperties) {
          if (requirementType && irp.type !== requirementType) {
            return;
          } else {
            requirementType = irp.type;
          }
        }
      }
    }
    return requirementType;
  };

  const handleSelection = (image: ShopImage, background?: ShopBackground) => {
    dispatch(assignImageToSinglePosePackage(key, editPackage!, image, background));
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [getPackageImageAssignmentStep()]: true }));
  };

  return (
    <ImageSelection
      completed={completed}
      disableCrop={true}
      imageRequirementType={getImageRequirementType()}
      initialBackground={background}
      initialImage={packageImages.length === 1 ? packageImages[0] : image}
      isActive={isActive}
      isDisabled={isDisabled}
      lockedImage={packageImages.length === 1 || isSingleGreenScreen}
      onSelect={handleSelection}
      selectInitialImage={!!image}
      showContinue={editStep === stepName}
      stepName={stepName}
    />
  );
};

export default PackageImageSelection;
