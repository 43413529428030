import { Z_INDEX_ONE } from '../../shared/constants';

const NUMBER_INPUT_BORDER_W = 1;
const NUMBER_INPUT_BTN_W = 25;
export const NUMBER_INPUT_BTN_TOTAL_W = NUMBER_INPUT_BTN_W + NUMBER_INPUT_BORDER_W;

const Button = {
  baseStyle: {},
  variants: {
    // Bordered
    borderSmall: {
      background: 'transparent',
      borderColor: 'grey.2',
      borderRadius: '3px',
      borderWidth: '1px',
      color: 'brand',
      fontSize: 'small',
      fontWeight: '100',
      height: '25px',
      marginTop: '8px',
    },
    borderLarge: {
      background: 'transparent',
      borderColor: 'grey.2',
      borderRadius: '8px',
      borderWidth: '1px',
      color: 'black',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'xs',
      height: '40px',
    },
    // Color Variants
    black: {
      background: 'black',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '32px',
      _hover: {
        _disabled: {
          backgroundColor: 'black', // prevents background transparency on hover of disabled button
        },
      },
      _disabled: {
        opacity: 0.125,
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    white: {
      background: 'white',
      borderRadius: '8px',
      borderColor: 'grey.2',
      borderWidth: '1px',
      color: 'black',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '32px',
      _hover: {
        backgroundColor: 'grey.1',
        _disabled: {
          backgroundColor: 'black',
        },
      },
      _disabled: {
        opacity: 0.125,
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    grey: {
      background: 'grey.1',
      borderRadius: 0,
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: '12px',
      height: '36px',
      width: '120px',
      _hover: {
        _disabled: {
          backgroundColor: 'grey.1', // prevents background transparency on hover of disabled button
        },
      },
    },
    primary: {
      background: 'brand',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      height: '50px',
      _hover: {
        _disabled: {
          backgroundColor: 'brand', // prevents background transparency on hover of disabled button
        },
      },
    },
    secondary: {
      background: 'white',
      borderRadius: '8px',
      color: 'black',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      height: '50px',
      _hover: {
        _disabled: {
          backgroundColor: 'brand', // prevents background transparency on hover of disabled button
        },
      },
    },
    tertiary: {
      background: 'successGreen',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'xs',
      height: '40px',
      _hover: {
        _disabled: {
          backgroundColor: 'successGreen', // prevents background transparency on hover of disabled button
        },
      },
    },
    // Icon Button
    icon: {
      fontWeight: 'lighter',
      height: '50px',
      width: '50px',
    },
    // Input element
    numberInput: {
      borderColor: 'grey.2',
      borderRadius: '0',
      borderWidth: `${NUMBER_INPUT_BORDER_W}px`,
      height: '30px',
      margin: 0,
      minWidth: '0',
      paddingX: '3',
      padding: 0,
      paddingBottom: '1px',
      width: `${NUMBER_INPUT_BTN_W}px`,
      _disabled: {
        borderColor: 'grey.2',
        color: 'grey.2',
        opacity: 1,
      },
    },
    // Modal
    modal: {
      background: 'grey.10',
      borderTopRadius: '50%', // each border set separately to override Chakra Icon Button default
      borderBottomRadius: '50%',
      borderRightRadius: '50%',
      borderLeftRadius: '50%',
      boxShadow: 'none',
      color: 'grey.3',
      fontSize: '27px',
      size: 'sm',
      zIndex: '1401',
      _hover: {
        background: 'grey.8',
      },
    },
    modalLight: {
      background: 'white',
      borderTopRadius: '50%', // each border set separately to override Chakra Icon Button default
      borderBottomRadius: '50%',
      borderRightRadius: '50%',
      borderLeftRadius: '50%',
      borderWidth: '1px',
      borderColor: 'grey.2',
      boxShadow: 'none',
      color: 'black',
      fontSize: '27px',
      size: 'sm',
      zIndex: Z_INDEX_ONE,
    },
    modalPrimary: {
      background: '#FCDF7C',
      borderRadius: '8px',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '50px',
    },
    modalSecondary: {
      background: 'grey.8',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '50px',
    },
    multiNodeCropToggle: {
      background: 'transparent',
      borderLeftRadius: '15px',
      borderRightRadius: '15px',
      color: 'black',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '30px',
      textTransform: 'capitalize',
    },
    multiNodeStep: {
      background: 'transparent',
      borderLeftRadius: '20px',
      borderRightRadius: '20px',
      color: 'black',
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: 'sm',
      height: '40px',
      paddingX: '20px',
      textTransform: 'capitalize',
    },
    // Navigation Icon Button
    nav: {
      borderRadius: 0,
      fontSize: '14px',
      fontWeight: 'lighter',
    },
    // Notification pin
    notification: {
      background: 'brand',
      borderRadius: '50%',
      color: 'white',
      fontWeight: 'bold',
      height: '20px',
      lineHeight: 'initial',
      minWidth: '15px', // disables Chakra default
      padding: 0,
      position: 'absolute',
      width: '20px',
      zIndex: 1,
    },
    // Plain text link-styled
    link: {
      color: 'black',
      fontSize: 'sm',
      marginY: '15px',
      textDecoration: 'underline',
      _hover: {
        _disabled: {
          textDecoration: 'underline',
        },
      },
    },
    linkWhite: {
      color: 'white',
      fontSize: 'md',
      marginY: '15px',
      textDecoration: 'underline',
    },
    linkGreen: {
      color: 'successGreen',
      fontSize: 'md',
      marginY: '15px',
      marginX: 0,
      padding: 0,
    },
    // Pill
    pill: {
      background: 'grey.1',
      fontSize: '14px',
      fontWeight: '100',
      paddingX: '10px',
      borderRadius: '10px',
    },
    // Tabs
    segmented: {
      borderRadius: 0,
      fontFamily: 'ITC Avant Garde Gothic Demi',
      fontSize: '14px',
      height: '50px',
      margin: '0 0 0 0',
      whiteSpace: 'unset',
      width: '50%',
    },
    // Wizard
    wizard: {
      backgroundColor: 'brand',
      borderRadius: 8,
      color: 'white',
      fontSize: 'sm',
      margin: 2,
      paddingX: '80px',
      width: '100%',
      _hover: {
        _disabled: {
          backgroundColor: 'brand', // prevents background transparency on hover of disabled button
        },
      },
    },
    wizardSecondary: {
      backgroundColor: 'grey.2',
      borderRadius: 8,
      color: 'grey.6',
      fontSize: 'sm',
      margin: 2,
      paddingX: '40px',
      width: '100%',
      _hover: {
        _disabled: {
          backgroundColor: 'brand', // prevents background transparency on hover of disabled button
        },
      },
    },
    custom: (props: any) => ({
      background: props.customPrimary,
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Button;
