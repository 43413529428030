import { Button, ButtonProps, IconProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends ButtonProps {
  content: IconProps | string | number;
}

/**
 * TODO: We need to convert this to a `chakra.span` or similar component, and address all touch points.
 * Once converted to a span element, we can pass `NotificationPin` to any navigational buttons as a child.
 * https://stackoverflow.com/questions/12982269/is-it-semantically-incorrect-to-put-a-div-or-span-inside-of-a-button
 */
const NotificationPin = ({ content, ...buttonProps }: Props) => (
  <Button as="span" variant="notification" {...buttonProps}>
    <>{content}</>
  </Button>
);

export default NotificationPin;
