import React from 'react';
import { IoCartOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { selectAllCartsItemCount } from '../../../redux/selectors/cart.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import NavigationActionWithPin from '../NavigationActionWithPin';

const CartButton = () => {
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const cartItemCount = useSelector(selectAllCartsItemCount);

  return (
    <NavigationActionWithPin
      data-test="cart-button"
      leftIcon={IoCartOutline}
      leftIconStyles={{ fontSize: '2xl' }}
      pinContent={cartItemCount}
      pinStyles={{ backgroundColor: 'grey.9' }}
      to={`/${currentVisitKey!}/carts`}
    />
  );
};

export default CartButton;
