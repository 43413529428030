import { Button, Flex, Spacer } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ContactActions from './ContactActions';
import ContactMenu from './ContactMenu';

const ContactDetails = () => {
  const [showDetails, setShowDetails] = useState(false);
  const intl = useIntl();

  const handleHideDetails = () => setShowDetails(false);

  const handleShowDetails = () => setShowDetails(true);

  return (
    <Flex paddingX={10} paddingY={5} position="absolute" width="100%">
      <Button
        onClick={handleShowDetails}
        variant="grey"
        data-test="contact-details-show-contact-button"
      >
        {intl.formatMessage({
          id: 'accountLanding.showContact',
          defaultMessage: 'Show Contact',
        })}
      </Button>
      <Spacer />
      <ContactActions />
      <ContactMenu isOpen={showDetails} onClose={handleHideDetails} />
    </Flex>
  );
};

export default ContactDetails;
