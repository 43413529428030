import { Flex } from '@chakra-ui/react';
import { noop } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ShopPackage,
  ShopProductCollection,
  ShopProductPrint,
} from '../../../../../../../shop-api-client';
import {
  CartCollectionReq,
  CartPrintProductReq,
  CreateCartProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectLimitedPoses,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import {
  setEditPackage,
  setEditPackageStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import OptionalSection from '../../../shared/OptionalSection';
import RequiredProductOptionEditor from '../../../shared/ProductOptionEditor/RequiredProductOptionEditor';
import TextNodesEditor from '../../../shared/TextNodesEditor';
import {
  getCollectionImagesStep,
  getGroupedProductOptions,
  getImageCropStep,
  getImageMultiNodeStep,
  getImageNodesStep,
  getProductOptionsStep,
  getRequiredProductOptionsStep,
  getTextNodesStep,
} from '../../../utils';
import PackageCollection from '../PackageCollection';
import PackageMultiNode from '../PackageMultiNode';
import PackageSingleNode from '../PackageSingleNode';

const EDITOR_SIDEBAR_WIDTH = 480;

interface Props {
  activeItem: CreateCartProductReq;
  activeStep?: string | null;
  onBackToOverview(): void;
  onNext(): void;
}

const SidebarCustomize = ({ activeItem, activeStep, onBackToOverview, onNext }: Props) => {
  // Selectors
  const { editPackage, invalidSteps } = useSelector(selectConfiguration);
  const poseMap = useAppSelector(selectLimitedPoses);
  const packageItemMap = useSelector(selectPackageItemMap);
  const { products } = useSelector(selectPriceSheet);

  // Misc hooks
  const dispatch = useAppDispatch();

  if (!editPackage || !activeItem) {
    return null;
  }

  // Pose restrictions
  const shopPackage = products[editPackage.priceSheetItemID] as ShopPackage;
  const poseLimit = shopPackage.allowAdditionalPoses ? undefined : shopPackage.posesIncluded;

  // Misc variables
  const minWidth = `${Math.min(window.innerWidth, EDITOR_SIDEBAR_WIDTH)}px`;
  const product = packageItemMap[activeItem.priceSheetItemID];
  const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
    product.options,
  );

  const handleEditStep = (stepName: string) => {
    dispatch(setEditPackageStep(stepName));
  };

  const handleProductSave = (updatedProduct: CreateCartProductReq) => {
    // Return new array of products with the modified product:
    const products = editPackage.products.map(product =>
      product.id === updatedProduct.id ? updatedProduct : product,
    );
    dispatch(setEditPackage({ ...editPackage, products }));
  };

  const renderCollection = () => {
    if (activeStep !== getCollectionImagesStep(activeItem.id!)) {
      return null;
    }

    return (
      <PackageCollection
        key={`collection-${activeItem.id}`}
        activeStep={activeStep}
        editProduct={activeItem as CartCollectionReq}
        poseLimit={poseLimit}
        poseMap={poseMap}
        shopProduct={product as ShopProductCollection}
      />
    );
  };

  const renderImageMultiNode = () => {
    if (activeStep !== getImageMultiNodeStep(activeItem.id!)) {
      return null;
    }

    return (
      <PackageMultiNode
        editProduct={activeItem as CartPrintProductReq}
        onLeave={onBackToOverview}
        onSave={onNext}
        poseLimit={poseLimit}
        poseMap={poseMap}
        shopProduct={product as ShopProductPrint}
      />
    );
  };

  const renderImageNode = () => {
    if (
      activeStep !== getImageNodesStep(activeItem.id!) &&
      activeStep !== getImageCropStep(activeItem.id!)
    ) {
      return null;
    }

    return (
      <PackageSingleNode
        key={activeItem.id}
        activeItem={activeItem as CartPrintProductReq}
        poseLimit={shopPackage.allowAdditionalPoses ? undefined : shopPackage.posesIncluded}
        poseMap={poseMap}
      />
    );
  };

  const renderProductOptions = () => {
    const stepName = getProductOptionsStep(activeItem.id!);
    if (activeStep !== stepName) {
      return null;
    }

    return (
      <>
        {requiredProductOptions.map(option => {
          const optionStepName = getRequiredProductOptionsStep(
            activeItem.id!,
            option.catalogOptionGroupID,
          );
          return (
            <RequiredProductOptionEditor
              key={option.id}
              containerProps={{ marginTop: -6 }}
              editProduct={activeItem}
              hideSave
              isCompleted={false}
              isDisabled={false}
              isInvalid={invalidSteps[optionStepName]}
              onEdit={() => handleEditStep(stepName)}
              onSave={handleProductSave}
              option={option}
            />
          );
        })}
        {!!optionalProductOptions.length && (
          <OptionalSection
            containerProps={{ paddingTop: 0, marginTop: -6 }}
            editItem={activeItem}
            isDisabled={false}
            optionalProductOptions={optionalProductOptions}
            onSave={handleProductSave}
          />
        )}
      </>
    );
  };

  const renderTextNodes = () => {
    if (activeStep !== getTextNodesStep(activeItem.id!)) {
      return null;
    }

    return (
      <TextNodesEditor
        key={`text-${activeItem.id}`}
        activeStep={activeStep!}
        catalogProductID={product.catalogProductID}
        editProduct={activeItem as CartPrintProductReq}
        hideSave
        onPostProcessing={noop}
        onSave={handleProductSave}
      />
    );
  };

  const inDesignerMode = activeStep === getImageMultiNodeStep(activeItem.id!);
  const mobileBottomPadding = inDesignerMode ? '0px' : '60px';

  return (
    <Flex
      borderColor="grey.1"
      borderLeftWidth={{ base: 0, md: 1 }}
      direction="column"
      flex={{ base: 1, md: 0 }}
      minWidth={{ base: undefined, md: minWidth }}
      paddingBottom={{ base: mobileBottomPadding, md: undefined }}
      width={{ base: '100%', md: minWidth }}
    >
      {renderImageMultiNode()}
      {!inDesignerMode && (
        <Flex
          direction="column"
          paddingX={{ base: 5, md: 10 }}
          paddingTop={{ base: 0, md: 10 }}
          flex={1}
        >
          {renderImageNode()}
          {renderCollection()}
          {renderTextNodes()}
          {renderProductOptions()}
        </Flex>
      )}
    </Flex>
  );
};

export default SidebarCustomize;
