import { Button, Box, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface Props extends FlexProps {
  activeSegment?: string;
  leftLabel: string;
  leftPrefixIcon?: any;
  rightLabel: string;
  rightPrefixIcon?: any;
  showLeftCaret?: boolean;
  showRightCaret?: boolean;
  toggleSegment(label: string): void;
}

const SegmentedControl = ({
  activeSegment,
  leftLabel,
  leftPrefixIcon,
  rightLabel,
  rightPrefixIcon,
  showLeftCaret,
  showRightCaret,
  toggleSegment,
  ...rest
}: Props) => {
  const isLeftActive = activeSegment === leftLabel;
  const isRightActive = activeSegment === rightLabel;
  const leftButtonCaret = isLeftActive ? <FaCaretUp /> : <FaCaretDown />;
  const rightButtonCaret = isRightActive ? <FaCaretUp /> : <FaCaretDown />;

  return (
    <Box borderBottomWidth="1px" borderColor="grey.2" {...rest}>
      <Button
        borderRight="grey.2"
        borderRightWidth="1px"
        color={isLeftActive ? 'brand' : 'black'}
        fontSize="md"
        leftIcon={leftPrefixIcon ? leftPrefixIcon : undefined}
        onClick={() => toggleSegment(leftLabel)}
        rightIcon={showLeftCaret ? leftButtonCaret : undefined}
        variant="segmented"
      >
        <Text width="100%" noOfLines={2}>
          {leftLabel.toLocaleUpperCase()}
        </Text>
      </Button>
      <Button
        color={isRightActive ? 'brand' : 'black'}
        fontSize="md"
        leftIcon={rightPrefixIcon ? rightPrefixIcon : undefined}
        onClick={() => toggleSegment(rightLabel)}
        rightIcon={showRightCaret ? rightButtonCaret : undefined}
        variant="segmented"
      >
        <Text width="100%" noOfLines={2}>
          {rightLabel.toLocaleUpperCase()}
        </Text>
      </Button>
    </Box>
  );
};

export default SegmentedControl;
