import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import GallerySearch from '../../AccountLanding/GallerySearch';

const FindGallery = () => {
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleResults = (nextVisitKey: string | null, query: string) => {
    if (nextVisitKey) {
      history.push(`/${currentVisitKey}/galleries/${nextVisitKey}/login`, { prevPath: pathname });
      return;
    }
    history.replace(`/${currentVisitKey}/galleries/find?${query}`);
  };

  return (
    <Flex align="center" direction="column" paddingTop={5}>
      <GallerySearch accountKey={null} visitKey={currentVisitKey} handleResults={handleResults} />
    </Flex>
  );
};

export default FindGallery;
