import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

interface Props extends IconButtonProps {
  lightMode?: boolean;
  onClick?(): void;
}

const ModalCloseButton = ({ lightMode = false, onClick, ...rest }: Props) => (
  <IconButton
    variant={lightMode ? 'modalLight' : 'modal'}
    icon={<FiX />}
    onClick={onClick}
    {...rest}
  />
);

export default ModalCloseButton;
