import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import accountReducer from './slices/account.slice';
import cartReducer from './slices/cart.slice';
import catalogReducer from './slices/catalog.slice';
import checkoutReducer from './slices/checkout.slice';
import configurationReducer from './slices/configurations.slice';
import galleryReducer from './slices/gallery.slice';
import interactionsReducer from './slices/interactions.slice';
import visitorReducer from './slices/visitor.slice';

const rootReducer = combineReducers({
  account: accountReducer,
  cart: cartReducer,
  catalog: catalogReducer,
  checkout: checkoutReducer,
  configuration: configurationReducer,
  gallery: galleryReducer,
  interactions: interactionsReducer,
  visitor: visitorReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

interface ThunkReturnType<T> {
  payload: T;
  valid: boolean;
  error: any;
}

export type RootState = ReturnType<typeof rootReducer>;
export type Thunk<T = void> = ThunkAction<T, RootState, unknown, AnyAction>;
export type AppThunk<T = null> = ThunkAction<ThunkReturnType<T>, RootState, unknown, AnyAction>;
export type PromiseThunk<T = null> = ThunkAction<
  Promise<ThunkReturnType<T>>,
  RootState,
  unknown,
  AnyAction
>;

export type AppDispatch = typeof store.dispatch & ThunkDispatch<RootState, null, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
