import { Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api } from '../../../../shop-api-client';
import {
  selectCheckoutSlice,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import { setCartImageOptions } from '../../../redux/slices/cart.slice';
import {
  RequiredImageOptionMap,
  updateReqImageOptionsMap,
} from '../../../redux/slices/checkout.slice';
import { useAppDispatch } from '../../../redux/store';
import { saveRequiredImageOptions } from '../../../redux/thunks/checkout.thunks';
import { Params } from '../../../shared/types/router';
import { CheckoutStep } from '../CheckoutData';
import RequiredImageOptionsByCart from './RequiredImageOptionsByCart';

/**
 * all required image options across a multi-gallery checkout
 */
const RequiredImageOptions = ({ isActive, isComplete, setValidateCallback }: CheckoutStep) => {
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const { editReqImageOptionsMap } = useSelector(selectCheckoutSlice);

  const [showError, setShowError] = useState(false);

  const { checkoutID, key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const allItemsCompleted = intl.formatMessage({
    id: 'requiredImageOptions.allItemsCompleted',
    defaultMessage: 'All Items Completed',
  });

  /**
   * if any selections have an undefined value, user did not actively select an option
   */
  const isError = useCallback((map: RequiredImageOptionMap) => {
    for (const cartImages of Object.values(map)) {
      for (const requiredOptions of Object.values(cartImages)) {
        for (const selection of Object.values(requiredOptions)) {
          if (selection === undefined) {
            return true;
          }
        }
      }
    }
    return false;
  }, []);

  const validate = useCallback(async () => {
    if (isError(editReqImageOptionsMap)) {
      setShowError(true);
      return false;
    }
    const { valid } = await dispatch(saveRequiredImageOptions());

    if (valid) {
      const updatedCartIO = await api.getCheckoutImageOptions(key, checkoutID!);

      for (const [visitKey, cartOptions] of Object.entries(updatedCartIO)) {
        dispatch(setCartImageOptions({ cartOptions, visitKey }));
      }
    }

    return valid;
  }, [key, editReqImageOptionsMap, checkoutID, dispatch, isError]);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [isActive, setValidateCallback, validate]);

  useEffect(() => {
    setShowError(false);
  }, [editReqImageOptionsMap]);

  if (!checkoutVisitKeys) {
    return null;
  }

  const renderComplete = () => (
    <Flex paddingX={10} paddingBottom={8}>
      <Text>{allItemsCompleted}</Text>
    </Flex>
  );

  const handleSelection = (
    visitKey: string,
    imageName: string,
    optionGroupID: number,
    selectionID: number | false,
  ) => {
    dispatch(updateReqImageOptionsMap({ visitKey, imageName, optionGroupID, selectionID }));
  };

  return (
    <>
      {isComplete && !isActive && renderComplete()}
      {isActive && (
        <Flex direction="column">
          {checkoutVisitKeys.map(visitKey => (
            <RequiredImageOptionsByCart
              key={visitKey}
              onSelect={handleSelection}
              visitKey={visitKey}
              selectionsForCart={editReqImageOptionsMap[visitKey] || {}}
              showError={showError}
            />
          ))}
        </Flex>
      )}
    </>
  );
};

export default RequiredImageOptions;
