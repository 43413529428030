import { Box, Button, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PriceSheetOption, ShopImage } from '../../../../../../../shop-api-client';
import StickyMobileSection from '../../../../../../shared/components/Mobile/StickyMobileSection';
import Modal from '../../../../../../shared/components/Modal';
import { Z_INDEX_TWO } from '../../../../../../shared/constants';
import { UniqueImageAndBackgroundSet } from '../../../../../../shared/types/image';
import ImageOptionImageDisplay from '../ImageOptionImageDisplay';
import ImageOptionSwapSelection from '../ImageOptionSwapSelection';

interface Props {
  setShowModal(showModal: boolean): void;
  showModal: boolean;
  /** the option group on the pricesheet */
  option: PriceSheetOption;
  /** the currently selected selection value of the option group*/
  optionSelectionID: number;
  setOptionSelectionID(optionSelectionID: number | undefined): void;
  /** images selected on the edit item that are available for selection */
  images: UniqueImageAndBackgroundSet;
  /** map of selections for this option to associated images */
  selectionIDToImageMap: Record<number, string[]>;
  /** updates selectionIDToImageMap in parent so it can be saved to redux */
  updateImageOptionSelections(map: Record<number, string[]>): void;
}

/**
 * image selection modal for a multi-image node product with multiple images selected
 * self-manages which images will apply a specific image option selection
 * then makes a call to the parent to update
 */
const ImageOptionEditorModal = ({
  images,
  option,
  optionSelectionID,
  selectionIDToImageMap,
  setOptionSelectionID,
  setShowModal,
  showModal,
  updateImageOptionSelections,
}: Props) => {
  // saves the shop image that might be swapped to current image option selection
  // if 'All' we want to set all images to current selection
  const [swapSelectionImg, setSwapSelectionImg] = useState<ShopImage | 'all'>();
  /**
   * Map of the image associated to the selection
   *
   * Every image can only have (at most) one of the selection options on the image option
   * we use this to internally manage image selection + swapping inside the modal
   * null value if that img is not assigned to a selection
   */
  const [imageToSelectionIDMap, setImageToSelectionIDMap] = useState<Record<string, number | null>>(
    {},
  );

  const optionSelection = option.selections.find(s => s.catalogOptionID === optionSelectionID);

  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const photosOnItem = intl.formatMessage(
    {
      id: 'imageOptionModal.photosOnItem',
      defaultMessage: 'Photos on Item ({num})',
    },
    { num: Object.keys(images).length },
  );
  const selectPhotos = intl.formatMessage({
    id: 'imageOptionModal.selectPhotos',
    defaultMessage: 'Select Photos',
  });
  const selectPhotosMsg = intl.formatMessage(
    {
      id: 'imageOptionModal.selectPhotosMsg',
      defaultMessage: 'Choose photos you would like to add: {name}',
    },
    { name: optionSelection?.name || '' },
  );
  const selectAll = intl.formatMessage(
    {
      id: 'imageOptionModal.selectAll',
      defaultMessage: 'Select All ({num})',
    },
    { num: Object.keys(images).length },
  );

  /** create a map of images > the associated option selection */
  useEffect(() => {
    const imageToSelectionIDMap = Object.entries(selectionIDToImageMap).reduce<
      Record<string, number>
    >((map, [selectionID, images]) => {
      // for every possible image in the images associated with the selection
      for (const imgName of images) {
        map[imgName] = parseInt(selectionID);
      }

      return map;
    }, {});
    // end result is a map with a key for every available img, and either an associated image option selection or null
    setImageToSelectionIDMap(imageToSelectionIDMap);
  }, [selectionIDToImageMap]);

  const getSelectedImages = () =>
    Object.entries(imageToSelectionIDMap).reduce<string[]>((res, [imageName, selectionID]) => {
      if (selectionID === optionSelectionID) {
        res.push(imageName);
      }
      return res;
    }, []);

  // when selecting an image in the modal, ensure a single instance
  // could also be a map
  const handleSelectImgForOptionSelection = (image: ShopImage) => {
    // 1. see if image is already assigned to another selection for this option
    if (!imageToSelectionIDMap[image.internalName]) {
      // if its not in the map, we gucci
      // update the map
      const updatedMap = { ...imageToSelectionIDMap, [image.internalName]: optionSelectionID };
      setImageToSelectionIDMap(updatedMap);

      return;
    }

    // if it's currently already assigned to the current option selection
    // trash it and deselect
    if (imageToSelectionIDMap[image.internalName] === optionSelectionID) {
      const updatedMap = { ...imageToSelectionIDMap };
      delete updatedMap[image.internalName];
      setImageToSelectionIDMap(updatedMap);
      return;
    }
    // otherwise it's arleady assigned to another option selection
    // popup to ask if they want to up/downgrade
    // if the value here has a number (not null), it's been assigned
    setSwapSelectionImg(image);
  };

  const handleSelectAll = () => {
    const newMap = Object.keys(images).reduce<Record<string, number>>((map, imgName) => {
      map[imgName] = optionSelectionID;
      return map;
    }, {});

    setImageToSelectionIDMap(newMap);
    return;
  };

  /**
   * inverses the image to selection map and reconvert to selctionID > imageNames[]
   * prepping it to be sent to the parent Image Option Editor to handle the data
   */
  const onConfirm = () => {
    // 1. map over all images and their associated selections
    //to get a partial map selections WITH images
    const partialSelectionMap = Object.entries(imageToSelectionIDMap).reduce<
      Record<number, string[]>
    >((map, [imageName, selectionID]) => {
      if (selectionID) {
        map[selectionID] = [...(map[selectionID] || []), imageName];
      }

      return map;
    }, {});

    // 2. because selections without images aren't recorded
    // we need to find all the selections that don't have any associated images and add them to the map as well with images = empty []
    // the editImageOptions map for an image group expects an image option object for every selection
    // even if no images were selected for it...
    // this is done primarily for later reconciliation of cart image options
    const allSelections = option.selections.map(s => s.catalogOptionID);
    const unselectedSelections = allSelections.filter(as => !partialSelectionMap[as]);

    // so we can just create a map with {selectionID: empty[]}
    const unselectedSelectionsMap = unselectedSelections.reduce<Record<number, string[]>>(
      (map, us) => {
        map[us] = [];
        return map;
      },
      {},
    );

    const updatedSelectionIDToImageMap = { ...unselectedSelectionsMap, ...partialSelectionMap };

    updateImageOptionSelections(updatedSelectionIDToImageMap);
    setShowModal(false);
  };

  const onClose = () => {
    // if no options were selected for any images, set selectionID to undefined/no
    if (!Object.values(imageToSelectionIDMap).filter(Boolean).length) {
      setOptionSelectionID(undefined);
    }
    setShowModal(false);
  };

  /** renders pop up drawer for CTA when user attempts to select an image that is already assigned to another
   * option selection
   */
  const renderSwapMobileSlideout = () => {
    if (!swapSelectionImg) {
      return null;
    }

    return (
      <>
        <Box
          bgColor="semiTransparentBlack"
          borderRadius={6}
          height="100%"
          left={0}
          position="absolute"
          top={0}
          width="100%"
          zIndex={100}
        />
        <StickyMobileSection
          borderBottomRadius={6}
          containerHeight="300px"
          direction="bottom"
          paddingX={{ base: 4, md: 12 }}
          paddingY={4}
          show
          slideMotionProps={{
            position: 'fixed',
            zIndex: 101,
            bottom: 0,
            left: 0,
          }}
          width="100%"
        >
          <ImageOptionSwapSelection
            images={images}
            imageToSelectionIDMap={imageToSelectionIDMap}
            option={option}
            optionSelectionID={optionSelectionID}
            setImageToSelectionIDMap={setImageToSelectionIDMap}
            setSwapSelectionImg={setSwapSelectionImg}
            swapSelectionImg={swapSelectionImg}
          />
        </StickyMobileSection>
      </>
    );
  };
  return (
    <>
      <Modal
        heading={selectPhotos}
        bodyStyles={{ paddingBottom: { base: '87px', md: 0 } }}
        footerStyles={{
          backgroundColor: { base: 'white', md: undefined },
          borderColor: 'grey.2',
          borderTopWidth: { base: '1px', md: 0 },
          bottom: { base: 0, md: undefined },
          left: { base: 0, md: undefined },
          position: { base: 'fixed', md: 'unset' },
          right: { base: 0, md: undefined },
          zIndex: { base: Z_INDEX_TWO, md: undefined },
        }}
        footerFlexProps={{ direction: { base: 'row-reverse', md: undefined } }}
        isOpen={showModal}
        onClose={onClose}
        onConfirm={onConfirm}
        subHeading={selectPhotosMsg}
        scrollBehavior={isMobile ? 'outside' : 'inside'}
      >
        <Flex>
          <Heading alignSelf="center" size="sm">
            {photosOnItem}
          </Heading>
          <Button variant="link" color="brand" marginLeft="auto" onClick={handleSelectAll}>
            {selectAll}
          </Button>
        </Flex>
        <ImageOptionImageDisplay
          availableImages={images}
          selectedImages={getSelectedImages()}
          selectImage={handleSelectImgForOptionSelection}
          swapProps={{
            images,
            imageToSelectionIDMap,
            option,
            optionSelectionID,
            setImageToSelectionIDMap,
            setSwapSelectionImg,
            swapSelectionImg,
          }}
          showPopover={!isMobile}
        />
        {isMobile && renderSwapMobileSlideout()}
      </Modal>
    </>
  );
};

export default ImageOptionEditorModal;
