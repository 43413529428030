import { api, GalleryType, LoginCredentials, SessionGallery } from '../../../shop-api-client';
import {
  toggleShowMultiGalleryTooltip,
  toggleShowWelcomeModal,
} from '../slices/interactions.slice';
import { setIsLoggedIn, setIsLoggingIn, signout } from '../slices/visitor.slice';
import { PromiseThunk } from '../store';
import { loadGalleryData } from './gallery.thunks';

interface AuthorizeSessionPayload {
  isPreOrder?: boolean;
  type?: GalleryType;
  unavailableMessage?: string;
}

/**
 * Handles session check/authentication, and fetches and stores post-login data
 * @param: visitKey (can have a '-')
 */
export const authorizeSession =
  (key: string, mid?: string): PromiseThunk<AuthorizeSessionPayload | null> =>
  async (dispatch, getState) => {
    try {
      if (getState().visitor.isLoggingIn) {
        return { valid: false, payload: null, error: 'isLoggingIn' };
      }
      // set redux value to true once auth is initiated to prevent a 2nd hit
      dispatch(setIsLoggingIn(true));

      // Pass along whatever campaign cookie is set:
      const campaignCookie = ('; ' + document.cookie).split(`; X-IQ-Campaign=`).pop();
      const campaign = campaignCookie?.split(';')[0] || '';

      // Checks for existing session, authenticates and creates Visit if session exists
      const session = await api.authSession(key, mid, campaign);

      // Find gallery in session returned from API
      const sessionGallery = session.studioGalleries[key];

      if (!sessionGallery) {
        // Session Error
        return { valid: false, payload: null, error: 'Gallery not active on session in BE' };
      }

      if (sessionGallery.unavailableMessage) {
        return {
          valid: true,
          payload: sessionGallery,
          error: sessionGallery.unavailableMessage,
        };
      }

      // set is logged in to true
      dispatch(setIsLoggedIn(!!sessionGallery));

      const { valid, payload, error } = await dispatch(loadGalleryData(key, session));

      if (!valid || !payload) {
        // Error loading/processing gallery data into redux
        return { valid, payload: null, error };
      }

      return { valid, payload: payload, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

/**
 * @param key: url key for initial gallery route
 */
export const login =
  (
    key: string,
    credentials: LoginCredentials,
  ): PromiseThunk<{ visitKey: string; sessionGallery: SessionGallery } | null> =>
  async (dispatch, getState) => {
    try {
      // Pass along whatever campaign cookie is set:
      const campaignCookie = ('; ' + document.cookie).split(`; X-IQ-Campaign=`).pop();
      const campaign = campaignCookie?.split(';')[0] || '';

      const session = await api.authLogin(key, credentials, campaign);
      const sessionGallery = session.studioGalleries[session.visitKey];
      const isLoggedIn = getState().visitor.isLoggedIn;

      // Gallery is valid, but unavailable due to
      // 1. inactive gallery
      // 2. expired subject on active gallery
      // 3. no images on gallery
      // After login attempt, unavailable message designates whether gallery is available or not
      if (sessionGallery.unavailableMessage) {
        return {
          valid: true,
          payload: { visitKey: session.visitKey, sessionGallery },
          error: sessionGallery.unavailableMessage,
        };
      }

      const { valid, error, payload } = await dispatch(loadGalleryData(session.visitKey, session));
      if (!isLoggedIn) {
        dispatch(setIsLoggedIn(!!sessionGallery));
      }

      // check if their first visit or they are repeat to toggle welcome modal
      if (sessionGallery?.visits === 1 && payload?.galleryConfig?.welcomeMessage) {
        dispatch(toggleShowWelcomeModal(true));
      }

      // if logged into 2 galleries, toggle on the tooltip
      if (Object.values(session.studioGalleries).length === 2) {
        dispatch(toggleShowMultiGalleryTooltip(true));
      }

      if (!valid) {
        return { valid: false, payload: null, error };
      }

      // passed all checks, data all loaded, good to go
      return { valid: true, payload: { visitKey: session.visitKey, sessionGallery }, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

/**
 * Clears the visitor's session. If `isUnavailable` is true, the visitor is signing out of an unavailable
 * gallery, therefore only the `unavailableMessage` has to be unset, otherwise `gallery` Redux state must
 * be reset to it's initial state, and the `isLoggedIn` flag toggled
 */
export const logout = (): PromiseThunk<null> => async dispatch => {
  try {
    await api.authLogout();
    dispatch(signout());
    return { valid: true, payload: null, error: null };
  } catch (error) {
    return { valid: false, payload: null, error };
  }
};
