import {
  As,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Discount } from '../../../../../shop-api-client/models/Discounts';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCart } from '../../../../redux/selectors/cart.selectors';
import {
  selectCheckoutGalleriesMap,
  selectCheckoutSlice,
  selectCheckoutVisitKeys,
} from '../../../../redux/selectors/checkout.selectors';
import { selectCartFinancials } from '../../../../redux/selectors/financials.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../redux/store';
import { SUBJECT_WITH_COLON } from '../../../../shared/constants/carts.constants';
import { apply, remove } from '../../../../shared/constants/discounts.constants';
import { DISCOUNT_SEGMENTS } from '../../../../shared/constants/zTable.constants';
import { formatCurrency } from '../../../../shared/utils';
import { PromoCodeProps } from '../PromoCode/PromoCode';

const APPLIED_STYLES = {
  color: 'successGreen',
  backgroundColor: 'tintedGreenOne',
  borderColor: 'transparent',
};

const AVAILABLE_STYLES = {
  borderStyle: 'dotted',
};

interface QuickyApplyProps extends PromoCodeProps {
  discount: Discount;
  cartVisitKey: string;
}

const QuickApply = ({
  appliedDiscount,
  cartVisitKey,
  discount,
  error,
  handleApplyDiscount,
  handleRemoveDiscount,
}: QuickyApplyProps) => {
  const { isSubmitting } = useSelector(selectCheckoutSlice);
  const { currency } = useAppSelector(selectAccount);
  const { title } = useAppSelector(state => selectGallery(state, cartVisitKey));
  const cart = useAppSelector(state => selectCart(state, cartVisitKey));
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const checkoutGalleriesMap = useSelector(selectCheckoutGalleriesMap);
  const cartFinancials = useAppSelector(state => selectCartFinancials(state, cartVisitKey));

  const meetsAppliesToItemReq =
    !discount.appliesToItems.length ||
    discount.appliesToItems.some(item => {
      const match = [...cart.cartProducts, ...cart.cartPackages].some(
        p => p.priceSheetItemID === item.id,
      );

      return match;
    });

  const amountBelowMinimum = discount.minOrderAmount - cartFinancials.subtotal;
  const meetsMinAmtReq = amountBelowMinimum <= 0;

  const intl = useIntl();

  const isApplied = discount.id === appliedDiscount.discountID;
  const showError = discount.id === error?.discountID;

  // GENERAL
  const discountMsg = intl.formatMessage(
    {
      id: 'discounts.quickApply.discountMsg',
      defaultMessage: `{amount} off {segments}`,
    },
    {
      // TODO: reconvert amountType to string in shop-api
      amount:
        discount.amountType === 1
          ? formatCurrency(discount.amount, currency)
          : `${discount.amount}%`,
      segments: discount.segments.map(num => DISCOUNT_SEGMENTS[num]).join(', '),
    },
  );

  const failedToApplyDiscount = intl.formatMessage({
    id: 'discounts.quickApply.failedToApplyDiscount',
    defaultMessage:
      'Sorry, the offer may no longer be available. Please try again or enter a different code below.',
  });

  const unlockThisDiscount = intl.formatMessage({
    id: 'discounts.quickApply.unlockThisDiscount',
    defaultMessage: 'to unlock this discount.',
  });

  const from = intl.formatMessage({
    id: 'discounts.quickApply.from',
    defaultMessage: 'from',
  });

  const and = intl.formatMessage({
    id: 'discounts.quickApply.and',
    defaultMessage: 'and',
  });

  // MINIMUM AMT DISCOUNT
  const minAmtError = intl.formatMessage(
    {
      id: 'discounts.quickApply.minAmtError',
      defaultMessage: 'Unlocks after buying {minOrderAmount} worth of items.',
    },
    {
      minOrderAmount: formatCurrency(discount.minOrderAmount, currency),
    },
  );

  const amountBelowMinimumText = intl.formatMessage(
    {
      id: 'discounts.quickApply.amountBelowMinimum',
      defaultMessage: "You're {amountBelowMinimum} away!",
    },
    {
      amountBelowMinimum: formatCurrency(amountBelowMinimum, currency),
    },
  );

  const purchaseMoreItems = intl.formatMessage({
    id: 'discounts.quickApply.purchaseMoreItems',
    defaultMessage: 'Purchase more items',
  });

  const appliesToItemError = intl.formatMessage({
    id: 'discounts.quickApply.appliesToItemError',
    defaultMessage: 'Unlocks with purchase of select item',
  });

  const appliesToItemsError = intl.formatMessage({
    id: 'discounts.quickApply.appliesToItemsError',
    defaultMessage: 'Unlocks with purchase of select items',
  });

  const purchaseOneOfTheFollowing = intl.formatMessage({
    id: 'discounts.quickApply.purchaseOneOfTheFollowing',
    defaultMessage: 'Purchase one of the following items',
  });

  const showItems = intl.formatMessage({
    id: 'discounts.quickApply.showItems',
    defaultMessage: 'Show Items',
  });

  const appliesToItemsPopoverHeader = intl.formatMessage({
    id: 'discounts.quickApply.appliesToItemsPopoverHeader',
    defaultMessage: 'Unlocks with purchase of',
  });

  const handleQuickApply = () => {
    if (isApplied) {
      handleRemoveDiscount();
    } else {
      handleApplyDiscount({ discountID: discount.id });
    }
  };

  const renderLockOrButton = () => {
    if (!meetsMinAmtReq || !meetsAppliesToItemReq) {
      return (
        <Flex backgroundColor="gray.100" paddingY={1.5} paddingX={3.5} borderRadius={5.5}>
          <Icon as={IoLockClosedOutline} fontSize="xl" color="gray.400" />
        </Flex>
      );
    }

    return (
      <Button
        disabled={isSubmitting}
        onClick={handleQuickApply}
        variant={isApplied ? 'white' : 'black'}
      >
        {isApplied ? remove : apply}
      </Button>
    );
  };

  const renderCTAMessage = () => {
    const meetsAppliesToItems = meetsAppliesToItemReq || !discount?.appliesToItems;

    // show both errors if both don't pass
    if (!meetsMinAmtReq && !meetsAppliesToItemReq) {
      return `${
        discount?.appliesToItems?.length > 1 ? appliesToItemsError : appliesToItemError
      } ${and}
          ${minAmtError.slice(20)}`;
    }

    if (!meetsMinAmtReq) {
      return minAmtError;
    }

    if (!meetsAppliesToItems) {
      return `${
        discount?.appliesToItems?.length > 1 ? appliesToItemsError : appliesToItemError
      }${'.'}`;
    }

    // if all reqs are met, show the code
    return discount.discountCode;
  };

  const renderGalleryTitleAndSubject = (as?: As) => {
    if (!checkoutVisitKeys || checkoutVisitKeys.length <= 1) {
      return null;
    }
    return (
      <>
        <Text as={as || 'span'} variant="inline">
          {from}
        </Text>
        <Text marginX={1} as={as || 'b'} variant="inline">
          {title}
          {/* same gallery, but multiple subjects */}
          {Object.keys(checkoutGalleriesMap).length === 1 && checkoutVisitKeys.length > 1 && (
            <>
              {' / '}
              {SUBJECT_WITH_COLON} {discount.subjectID}
            </>
          )}
        </Text>
      </>
    );
  };

  // render text for not meeting min order amt reqs
  const renderMinOrderCTADetails = () => (
    <Text fontSize="xs" marginTop={1.5}>
      {amountBelowMinimumText}
      <Link as={RouterLink} marginX={1} textDecoration="underline" to={`/${cartVisitKey}/shop/all`}>
        {purchaseMoreItems}
      </Link>
      {renderGalleryTitleAndSubject()}
      {unlockThisDiscount}
    </Text>
  );

  // render text for not meeting appliesToItems reqs
  const renderAppliesToItemsCTADetails = () => (
    <Box display="inline" marginTop={1.5}>
      <Text variant="inline">{purchaseOneOfTheFollowing}</Text> {renderGalleryTitleAndSubject()}
      <Text variant="inline">{unlockThisDiscount}</Text>
      <Popover trigger="hover" closeOnBlur direction="rtl" placement="auto">
        <PopoverTrigger>
          <Text as="u" fontSize="xs" display="inline" marginLeft={1}>
            {showItems}
          </Text>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader borderBottomWidth={0} paddingBottom={0}>
            <Text color="gray.400" fontSize="sm" fontWeight="bold">
              {appliesToItemsPopoverHeader.toUpperCase()}
            </Text>
          </PopoverHeader>
          <PopoverBody>
            <Flex>{renderAppliesToItemsList()}</Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );

  // render list of items if appliesToItems req is not met
  const renderAppliesToItemsList = () => {
    if (!discount.appliesToItems.length) {
      return;
    }

    return (
      <UnorderedList>
        {discount.appliesToItems.map(item => (
          <ListItem key={item.id}>
            <Text fontWeight="bold" fontSize="xs">
              <Link
                as={RouterLink}
                marginX={1}
                textDecoration="underline"
                to={`/${cartVisitKey}/shop/${item.categoryID}/product/${item.id}`}
              >
                {item.name}
              </Link>
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    );
  };

  return (
    <Box maxWidth="475px">
      <Box
        borderColor={showError ? 'error' : 'gray.300'}
        borderRadius={8}
        borderWidth="2px"
        marginY={3}
        paddingX={4}
        paddingY={3}
        position="relative"
        width="100%"
        {...(isApplied ? APPLIED_STYLES : AVAILABLE_STYLES)}
      >
        <Flex>
          <Box flexGrow={1}>
            <Heading fontSize="15px" marginBottom="2px">
              {discountMsg}
            </Heading>
            {/* TODO: add additional messaging to show discount is only for a single gallery
            {renderGalleryTitleAndSubject('sup')} */}
            <Text
              data-test="applied-discount"
              color={isApplied ? 'successGreen' : '#00afef'}
              fontSize="sm"
              whiteSpace="normal"
              fontWeight="bold"
            >
              {renderCTAMessage()}
            </Text>
          </Box>
          <Flex align={meetsMinAmtReq ? 'start' : 'center'} paddingX={[0, 2]} marginTop={0.5}>
            {renderLockOrButton()}
          </Flex>
        </Flex>
        {!meetsMinAmtReq && renderMinOrderCTADetails()}
        {!meetsAppliesToItemReq && renderAppliesToItemsCTADetails()}
      </Box>

      {showError && (
        <Text color="error" fontSize="xs" whiteSpace="normal">
          {failedToApplyDiscount}
        </Text>
      )}
    </Box>
  );
};

export default QuickApply;
