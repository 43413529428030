import { intl } from './intl';

export const BACK = intl.formatMessage({
  id: 'labels.constants.back',
  defaultMessage: 'Back',
});

export const BUY_PHOTO = intl.formatMessage({
  id: 'labels.constants.buyPhoto',
  defaultMessage: 'Buy Photo',
});

export const CLEAR_FILTERS = intl.formatMessage({
  id: 'labels.constants.clearFilters',
  defaultMessage: 'Clear Filters',
});

export const CONTINUE_SHOPPING = intl.formatMessage({
  id: 'labels.constants.continueShopping',
  defaultMessage: 'Continue Shopping',
});

export const ITEM = intl.formatMessage({
  id: 'labels.constants.item',
  defaultMessage: 'item',
});

export const ITEM_PRICE = intl.formatMessage({
  id: 'labels.constants.itemPrice',
  defaultMessage: 'Item Price',
});

export const ITEMS = intl.formatMessage({
  id: 'labels.constants.items',
  defaultMessage: 'items',
});

export const NEW_WITH_COLON = intl.formatMessage({
  id: 'labels.constants.newWithColon',
  defaultMessage: 'New:',
});

export const NONE = intl.formatMessage({
  id: 'labels.constants.none',
  defaultMessage: 'None',
});

export const NO_THANKS = intl.formatMessage({
  id: 'labels.constants.noThanks',
  defaultMessage: 'No Thanks',
});

export const REMOVE = intl.formatMessage({
  id: 'labels.constants.remove',
  defaultMessage: 'Remove',
});

export const REMOVED_WITH_COLON = intl.formatMessage({
  id: 'labels.constants.removedWithColon',
  defaultMessage: 'Removed:',
});

export const SHOP_ALL_CATEGORIES = intl.formatMessage({
  id: 'labels.constants.shopAllCategories',
  defaultMessage: 'Shop All Categories',
});

export const SHOP_CATEGORY = intl.formatMessage({
  id: 'labels.constants.shopCategory',
  defaultMessage: 'Shop Category',
});

export const UPDATED_WITH_COLON = intl.formatMessage({
  id: 'labels.constants.updatedWithColon',
  defaultMessage: 'Updated:',
});

export const YES_ADD = intl.formatMessage({
  id: 'labels.constants.yesAdd',
  defaultMessage: 'Yes, Add',
});
