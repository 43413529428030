import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { selectGallery } from '../../redux/selectors/gallery.selectors';
import ProtectedRoute from '../../shared/components/ProtectedRoute';
import { Params } from '../../shared/types/router';
import { getShopAllPath } from '../../shared/utils';
import Album from './Album';
import ImageViewer from './ImageViewer';
import PhotoAlbums from './PhotoAlbums';

const MyPhotos = () => {
  const { groupID, photoID, key } = useParams<Params>();
  const { path, url } = useRouteMatch();
  const { type, isPreOrder } = useSelector(selectGallery);
  const history = useHistory();

  useEffect(() => {
    // If pre-order, route to 'Shop' tab as no images are present
    if (isPreOrder) {
      const shopAllPath = getShopAllPath(key);
      history.replace(shopAllPath);
      return;
    }

    // If pathname includes /photo/ return, since the Image Viewer is open
    if (history.location.pathname.includes('/photo/')) {
      return;
    }

    if (type === 'standard' && groupID) {
      // If standard gallery and groupID route param, route to group's album:
      history.replace(`${url}/${groupID}`);
    }
  }, [groupID, history, photoID, type, url, key, isPreOrder]);

  return (
    <Switch>
      <ProtectedRoute path={`${path}/all`} component={Album} exact />
      <ProtectedRoute path={`${path}/favorites`} component={Album} exact />
      <ProtectedRoute path={`${path}/:groupID`} component={Album} exact />
      <ProtectedRoute
        path={`${path}/all/photo/:photoID/(bg)?/:backgroundID?`}
        component={ImageViewer}
        exact
      />
      <ProtectedRoute
        path={`${path}/:groupID/photo/:photoID/(bg)?/:backgroundID?`}
        component={ImageViewer}
        exact
      />
      <ProtectedRoute path={path} component={PhotoAlbums} exact />
    </Switch>
  );
};

export default MyPhotos;
