import { Box } from '@chakra-ui/layout';
import { Button, Flex, Heading, IconButton, Spacer } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { setGalleries } from '../../../redux/slices/account.slice';
import { useAppDispatch } from '../../../redux/store';
import GalleryCard from '../../../shared/GalleryCard';
import useSearchParams from '../../../shared/hooks/useSearchParams';
import GalleriesSortSelect from '../GalleriesSortSelect';
import GallerySearchInput from './GallerySearchInput';

interface Props {
  handleResults(nextVisitKey: string | null, query: string): void;
  accountKey: string | null;
  visitKey: string | null;
}

export type ActiveTab = 'search' | 'code';

const GallerySearch = ({ handleResults, accountKey, visitKey }: Props) => {
  const { getParamsValue } = useSearchParams();
  const paramSearch = getParamsValue('search');
  const paramCode = getParamsValue('code');

  const { galleries } = useSelector(selectAccount);

  const [value, setValue] = useState({ search: paramSearch || '', code: paramCode || '' });
  const [error, setError] = useState({ search: '', code: '' });
  const [activeTab, setActiveTab] = useState<ActiveTab>(value.search ? 'search' : 'code');
  const [initialLoad, setInitialLoad] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortedGalleries, setSortedGalleries] = useState(galleries);

  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const isMultiGallery = !!visitKey;
  const enterValidText = intl.formatMessage(
    {
      id: 'gallerySearch.enterValid',
      defaultMessage: 'Please enter a valid {text}',
    },
    { text: activeTab === 'search' ? 'search' : 'online code' },
  );
  const findGalleryText = intl.formatMessage({
    id: 'gallerySearch.findGallery',
    defaultMessage: 'Find Gallery',
  });
  const numGalleriesFound = intl.formatMessage(
    {
      id: 'gallerySearch.numGalleriesFound',
      defaultMessage: '{galleryCount} Galleries Found',
    },
    { galleryCount: galleries.length },
  );
  const invalidInputValue = intl.formatMessage(
    {
      id: 'gallerySearch.incorrectKeyword',
      defaultMessage: 'The {text} you entered cannot be found or is incorrect',
    },
    { text: activeTab === 'search' ? 'search' : 'online code' },
  );

  const search = useCallback(async () => {
    setIsSubmitting(true);
    const code = activeTab === 'code' ? value.code.trim() : null;
    const searchValue = activeTab === 'search' ? value.search.trim() : null;
    const { galleries, nextVisitKey } = await api
      .getAccountGalleries(accountKey, visitKey, code, searchValue)
      .catch(() => ({ galleries: [], nextVisitKey: null }));

    setIsSubmitting(false);

    if (!galleries.length) {
      setError({
        ...error,
        [activeTab]: invalidInputValue,
      });
      return { galleries: [], nextVisitKey: null };
    }

    return { galleries, nextVisitKey };
  }, [activeTab, accountKey, visitKey, value, error, invalidInputValue]);

  const handleSearch = async () => {
    if (!value[activeTab]) {
      setError({ ...error, [activeTab]: enterValidText });
      return;
    }

    const { galleries, nextVisitKey } = await search();

    if (!nextVisitKey) {
      dispatch(setGalleries(galleries));
    }
    const query = `${activeTab}=${value[activeTab]}`;
    handleResults(nextVisitKey, query);
  };

  useEffect(() => {
    const init = async () => {
      const result = await search();
      dispatch(setGalleries(result.galleries));
      setInitialLoad(false);
    };

    if (galleries.length) {
      // if initial load, but we have values saved in redux from an old search, clear it
      if (initialLoad && !paramCode && !paramSearch) {
        // if setInitialLoad(false) is called after dispatch, safari hangs
        // so we do it here and it works for chrome and safari
        if (visitKey) {
          setInitialLoad(false);
        }
        dispatch(setGalleries([]));
        return;
      }
      setSortedGalleries(galleries);
    } else if (initialLoad && (paramCode || paramSearch)) {
      init();
    }
  }, [galleries, paramCode, paramSearch, search, dispatch, initialLoad, visitKey]);

  /**
   * Handler is fired only when the user uses enter key
   * exit search if current value matches query params value or if no value
   */
  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (
      e.key !== 'Enter' ||
      e.target.value === (activeTab === 'search' ? paramSearch : paramCode)
    ) {
      return;
    }

    if (!e.target.value || !value[activeTab]) {
      setError({ ...error, [activeTab]: enterValidText });
      return;
    }

    handleSearch();
  };

  const toggleTab = (tab: ActiveTab) => setActiveTab(tab);

  const handleQueryChange = (tab: ActiveTab, e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [tab]: e.target.value });
    setError({ ...error, [tab]: '' });
  };

  const clearQuery = () => {
    setValue({ ...value, [activeTab]: '' });
    history.replace(pathname);
    dispatch(setGalleries([]));
  };

  const renderButton = () => {
    if (galleries.length && value[activeTab] === (paramCode || paramSearch)) {
      return (
        <IconButton
          aria-label="Clear query"
          border="unset"
          fontFamily="ITC Avant Garde Gothic Book"
          icon={<FiX />}
          onClick={clearQuery}
          variant="modalLight"
        />
      );
    }

    return (
      <Button
        data-test="account-landing-find-gallery-button"
        disabled={isSubmitting || !value[activeTab]}
        fontSize="sm"
        height="100%"
        isLoading={isSubmitting}
        onClick={handleSearch}
        type="submit"
        width="160px"
      >
        {findGalleryText}
      </Button>
    );
  };

  return (
    <Box
      alignContent="center"
      display="flex"
      flexGrow={1}
      justifyContent="center"
      paddingX={5}
      width={isMultiGallery ? '100%' : '100vw'}
    >
      <Flex align="center" direction="column" width={['95vw', '100vw']}>
        <GallerySearchInput
          activeTab={activeTab}
          inputRightElement={renderButton()}
          invalidMessage={error[activeTab]}
          isInvalid={!!error[activeTab]}
          isSubmitting
          onChange={handleQueryChange}
          onKeyUp={handleSubmit}
          toggleTab={toggleTab}
          value={value}
        />
        {!!galleries.length && (
          <>
            <Flex width="100%" maxWidth="1000px">
              <Heading margin={2} size="md" data-test="account-landing-galleries-found">
                {numGalleriesFound}
              </Heading>
              <Spacer />
              <GalleriesSortSelect setSortedGalleries={setSortedGalleries} galleries={galleries} />
            </Flex>
            <Flex direction="column" maxWidth="1000px" width="100%">
              {sortedGalleries.map(gallery => (
                <GalleryCard key={gallery.id} gallery={gallery} multiGallery={isMultiGallery} />
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default GallerySearch;
