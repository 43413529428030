import { Flex, Heading } from '@chakra-ui/layout';
import { Box, Icon } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { IconType } from 'react-icons/lib';

interface Props {
  children: ReactNode;
  heading: string;
  icon: IconType;
}

const ContactSection = ({ children, heading, icon }: Props) => (
  <Flex padding={3}>
    <Icon as={icon} fontSize="sm" marginRight={3} marginY={2} />
    <Box>
      <Heading marginY={2} fontSize="sm">
        {heading}
      </Heading>
      {children}
    </Box>
  </Flex>
);

export default ContactSection;
