import { Flex, IconButton, Spacer } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { IoCallOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { CALL, EMAIL } from '../../../../shared/constants';

/**
 * Contact buttons that open `mailto` and `tel` links
 */
const ContactActions = () => {
  const { contact } = useSelector(selectAccount);

  if (!contact) {
    return null;
  }

  const handleEmailClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(`mailto:${contact.email}`);
  };

  const handleCallClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(`tel:${contact.phone}`);
  };

  return (
    <Flex marginX={1} width="72px">
      {contact.phone && (
        <IconButton
          aria-label={CALL}
          fontSize="lg"
          icon={<IoCallOutline />}
          onClick={handleCallClick}
          size="sm"
          variant="modalLight"
        />
      )}
      {contact.email && (
        <>
          <Spacer />
          <IconButton
            aria-label={EMAIL}
            fontSize="lg"
            icon={<AiOutlineMail />}
            onClick={handleEmailClick}
            size="sm"
            variant="modalLight"
          />
        </>
      )}
    </Flex>
  );
};

export default ContactActions;
