import React, { useEffect, useState } from 'react';
import baseTheme from './index';
import { useSelector } from 'react-redux';
import { selectGallery } from '../redux/selectors/gallery.selectors';
import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';

export const IQThemeProvider = ({ children, ...rest }: ChakraProviderProps) => {
  const { galleryConfig } = useSelector(selectGallery);
  const [iqTheme, setIQTheme] = useState(baseTheme);

  useEffect(() => {
    if (iqTheme.colors.brand === galleryConfig?.themeColor) {
      return;
    }

    setIQTheme(() => {
      if (!galleryConfig?.themeColor) {
        return baseTheme;
      }

      return { ...baseTheme, colors: { ...baseTheme.colors, brand: galleryConfig.themeColor } };
    });
  }, [galleryConfig, iqTheme]);

  return (
    <ChakraProvider theme={iqTheme} {...rest}>
      {children}
    </ChakraProvider>
  );
};
