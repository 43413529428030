import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react';
import React from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';

const Select = ({ children, ...props }: SelectProps) => (
  <ChakraSelect icon={<RiArrowDownSFill />} variant="unstyled" {...props}>
    {children}
  </ChakraSelect>
);

export default Select;
