import { Flex } from '@chakra-ui/layout';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FiCheck } from 'react-icons/fi';

const MotionFlex = motion(Flex);
const CheckboxIcon = ({ isChecked }: { isChecked?: boolean }) => {
  const variants = {
    unchecked: { scale: 0 },
    checked: { scale: 1 },
  };

  return (
    <AnimatePresence initial={false}>
      {isChecked && (
        <MotionFlex
          animate="checked"
          exit="unchecked"
          initial="unchecked"
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
          }}
          variants={variants}
        >
          <FiCheck />
        </MotionFlex>
      )}
    </AnimatePresence>
  );
};

export default CheckboxIcon;
