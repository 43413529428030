import { chakra } from '@chakra-ui/system';
import React from 'react';

interface Props {
  text?: string;
  url: string;
  'data-test'?: string;
}

const ExternalLink = ({ text, url, 'data-test': dataTest }: Props) => (
  <chakra.a
    href={`https://${url}`}
    color="brand"
    fontSize="14px"
    fontWeight="bold"
    target="_blank"
    textDecoration="underline"
    data-test={dataTest}
  >
    {text || url}
  </chakra.a>
);

export default ExternalLink;
