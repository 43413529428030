import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IsEqualCustomizer, isEqualWith } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopBuildYourOwnPackage, ShopProduct } from '../../../../../../shop-api-client';
import {
  selectAddedUnits,
  selectConfiguration,
  selectSinglePoseImage,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectInteractions } from '../../../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import { setEditPackage } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../../../redux/store';
import { addBuildYourOwnItem } from '../../../../../redux/thunks/configuration.thunks';
import { pluralize } from '../../../../../shared/utils';
import { ALL_ITEMS } from '../../constants';
import { getUniqueQuantityMap } from '../../utils';
import AvailableItem from './AvailableItem';
import DisplayImage from './DisplayImage';

const MotionText = motion(Text);

const BuildYourOwn = () => {
  const { editPackage } = useSelector(selectConfiguration);
  const { selectedBackground: preOrderBackground } = useSelector(selectInteractions);
  const { products } = useSelector(selectPriceSheet);
  const { background, image } = useSelector(selectSinglePoseImage);
  const addedUnits = useSelector(selectAddedUnits);

  const [balanceChange, setBalanceChange] = useState<number | undefined>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const toast = useToast();

  const uniqueQuantityMap = useMemo(() => getUniqueQuantityMap(editPackage), [editPackage]);

  const shopPackage = products[editPackage!.priceSheetItemID] as ShopBuildYourOwnPackage;
  const balanceCount = shopPackage.includedUnits - addedUnits;

  if (!editPackage || !shopPackage || shopPackage.type !== 'package-byop') {
    return null;
  }

  const { availableProducts } = shopPackage;
  const { included, units } = availableProducts.reduce<Record<string, ShopProduct[]>>(
    (result, product) => {
      if (product.price === 0) {
        result.included.push(product);
      } else {
        result.units.push(product);
      }
      return result;
    },
    { included: [], units: [] },
  );

  /**
   * A map containing arrays of included BYOP items grouped/keyed by `catalogProductID`
   */
  const includedByCatProductID = included.reduce<Record<string, ShopProduct[]>>((result, item) => {
    if (!result[item.catalogProductID]) {
      result[item.catalogProductID] = [];
    }

    result[item.catalogProductID].push(item);
    return result;
  }, {});

  /**
   * A map that is keyed by BYOP price sheet item `id`, whose values are the count of included items that are
   * exactly the same as the item for the given price sheet item id (except for id, and price)
   */
  const uniqueItemCountMap = availableProducts.reduce<Record<string, number>>((result, item) => {
    const groupedBycatProductID = includedByCatProductID[item.catalogProductID];
    /**
     *  `customizer` ignores the `id` and `price` fields when comparing the two items:
     */
    const customizer: IsEqualCustomizer = (a, b, key) => {
      return key === 'id' || key === 'price' || undefined;
    };

    if (groupedBycatProductID) {
      // Filter by performing a deep comparison on the items sourced from the same catalog product, returning like-items:
      const filtered = groupedBycatProductID.filter(i => isEqualWith(i, item, customizer));
      // Store the filtered count:
      result[item.id] = filtered.length;
    }

    return result;
  }, {});

  const handleBalanceChange = (isAdding: boolean) =>
    setBalanceChange((prev = 0) => (isAdding ? prev + 1 : prev - 1));

  const handleChange = (item: ShopProduct, isAdding: boolean) => {
    if (isAdding) {
      if (shopPackage.includedUnits < addedUnits + item.price) {
        toast({
          title: `This item could not be added because it exceeds the amount of ${pluralize(
            2,
            shopPackage.unitLabel,
            false,
          )} remaining`,
        });
        return;
      }
      dispatch(addBuildYourOwnItem(item, image, background || preOrderBackground || undefined));
    } else {
      // Remove the first found sub-item from the `editPackage` with the given `item.priceSheetItemID`:
      //
      // WIP SC-301: UX criteria remains to be considered when the visitor has two or more of the same item and configures
      // them differently in the Customize step, then comes back to this step and decreases  the the number of editPackage
      // items from the same price sheet item. For now, it will automatically remove the first found PS item. However,
      // there should be some intermediate step that does a deep comparison of the configured items, and if divergences
      // are found, have the visitor choose which to remove.
      const itemIndex = editPackage.products.findIndex(p => p.priceSheetItemID === item.id);
      const products = [...editPackage.products];
      products.splice(itemIndex, 1);
      dispatch(setEditPackage({ ...editPackage, products }));
    }
    handleBalanceChange(isAdding);
  };

  const removeBalanceChange = () => setBalanceChange(undefined);

  return (
    <Flex direction="column" paddingBottom={10} grow={1}>
      <Box marginBottom={4} marginTop={{ base: 0, md: 10 }}>
        <Text>
          {intl.formatMessage({
            id: 'BYOPSelectionStep.currentBalance',
            defaultMessage: 'Current Balance',
          })}
        </Text>
        <Text fontSize="xx-large" fontWeight="bold">
          {intl.formatMessage(
            {
              id: 'BYOPSelectionStep.unitsCount',
              defaultMessage: '{count} {units}',
            },
            {
              count: balanceCount,
              units: pluralize(balanceCount, shopPackage.unitLabel, false),
            },
          )}
        </Text>
        <Box height="30px" paddingBottom={2}>
          {balanceChange !== undefined && (
            <MotionText
              animate={{ opacity: [0.7, 1, 0] }}
              color={balanceChange! >= 0 ? 'successGreen' : 'error'}
              fontSize="xs"
              onAnimationComplete={removeBalanceChange}
              transition={{ duration: 2 }}
            >
              {intl.formatMessage(
                {
                  id: 'BYOPSelectionStep.subtractedUnits',
                  defaultMessage: '{balanceChange} {units}',
                },
                {
                  balanceChange: `${balanceChange! >= 0 ? '+' : ''}${balanceChange}`,
                  units: pluralize(balanceCount, shopPackage.unitLabel, false),
                },
              )}
            </MotionText>
          )}
        </Box>
        <Text fontSize="xl" fontWeight="bold">
          {intl.formatMessage({
            id: 'BYOPSelectionStep.buildYourPackage',
            defaultMessage: 'Build your package',
          })}
        </Text>
        <Flex direction="column" paddingY={5}>
          <Text fontFamily="heading" fontSize="sm">
            {intl.formatMessage(
              {
                id: 'BYOPSelectionStep.includedItems',
                defaultMessage: 'INCLUDED ITEMS ({count})',
              },
              { count: included.length },
            )}
          </Text>
          <Flex width="100%" overflowX="auto" marginRight={-5}>
            {included.map(item => (
              <Box key={item.id} marginRight={3} width="80px">
                <DisplayImage minWidth="80px" src={item.image} product={item} />
                <Text noOfLines={2} fontSize="sm">
                  {item.name}
                </Text>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Box>
      <Flex direction="column" width="100%">
        <Text fontFamily="heading" fontSize="sm" marginY={3} textTransform="uppercase">
          {ALL_ITEMS}
        </Text>
        {units.map((unit, idx) => (
          <AvailableItem
            key={unit.id}
            includedCount={uniqueItemCountMap[unit.id] || 0}
            isLast={idx === availableProducts.length - 1}
            quantity={uniqueQuantityMap[unit.id] || 0}
            onInputChange={handleChange}
            remainingBalance={balanceCount}
            shopItem={unit}
            unitLabel={shopPackage.unitLabel}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default BuildYourOwn;
