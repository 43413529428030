import { createIntl, createIntlCache } from 'react-intl';
import { loadLocaleMessages } from '../utils';

// Locale
const locale = navigator.language.split(/[-_]/)[0];
const messages = loadLocaleMessages(locale);
const cache = createIntlCache();
export const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);
