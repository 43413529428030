import { api, AuthSession, GalleryLandingData, ShopData } from '../../../shop-api-client';
import { initializeStripe } from '../../shared/constants/stripe';
import { getImageBuffer } from '../../shared/utils';
import { initializeAccount } from '../slices/account.slice';
import {
  Gallery,
  initializeShopData,
  setIsLoading,
  setPriceSheets,
  setProductNodeMap,
  setSessionGalleries,
} from '../slices/gallery.slice';
import { setEmail } from '../slices/visitor.slice';
import { PromiseThunk, Thunk } from '../store';
import { getCartSummary, manageLockedCategories } from './cart.thunks';

export const loadGalleryData =
  (visitKey: string, session: AuthSession): PromiseThunk<Gallery | ShopData | null> =>
  async dispatch => {
    const { valid, payload, error } = await dispatch(getFullGallery(visitKey));

    if (!valid) {
      return { valid: false, payload: null, error };
    }
    dispatch(setEmail(session.email));
    dispatch(setSessionGalleries(session.studioGalleries));
    dispatch(loadSessionGalleries());
    return { valid: true, payload, error: null };
  };

/**
 * Fetches gallery data after the visitor has been authenticated, stores data to Redux and routes visitor
 * to the given path
 */
export const getFullGallery =
  (visitKey: string): PromiseThunk<ShopData | Gallery | null> =>
  async (dispatch, getState) => {
    try {
      const { cartMap } = getState().cart;
      const { galleryMap } = getState().gallery;
      const { company } = getState().account;
      const currentVisitKey = getState().visitor.currentVisitKey;
      const gallery = galleryMap[visitKey];
      // TODO: Determine how to best leverage lastModifiedDate below, to avoid stale data https://imagequix.atlassian.net/browse/SC-192

      if (gallery && !gallery.isLoading) {
        // it has already been loaded
        return { valid: true, payload: gallery, error: null };
      }

      // Full ShopData payload
      const shopData = await api.getShopData(visitKey);

      if (!company) {
        const { payload: baseData } = await dispatch(getGalleryLanding(visitKey));
        if (!baseData) {
          return { valid: false, payload: null, error: 'Failed to load account data' };
        }
        const { account } = baseData;

        const logoImage = (
          (await getImageBuffer(account.logoImage, 0).catch(() => null)) || ''
        ).toString();
        dispatch(initializeAccount({ ...account, logoImage }));
      }

      if (shopData.merchant.publishableKey) {
        // Once valid, make sure to initialize stripe:
        initializeStripe(shopData.merchant.publishableKey);
      }

      dispatch(
        initializeShopData({
          data: shopData,
          key: visitKey,
        }),
      );
      dispatch(setIsLoading(visitKey));
      dispatch(setPriceSheets(shopData));
      // Fetch and set all nodes for products on the price sheet
      await dispatch(getAllProductNodes(visitKey, shopData.priceSheetID));

      // If a cart has not been loaded for this visit, fetch and store to Redux.
      // `autoAddItems` requires gallery/price sheet data from redux, so this step must
      // come after that data has been set:
      if (!cartMap[visitKey]) {
        await dispatch(getCartSummary(visitKey));
      }

      //only update if currently on gallery
      if (visitKey === currentVisitKey) {
        document.title = shopData.title;
      }

      return { valid: true, payload: shopData, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

export const loadSessionGalleries = (): Thunk => async (dispatch, getState) => {
  const { galleryMap } = getState().gallery;
  const { currentVisitKey } = getState().visitor;
  const { cartMap } = getState().cart;
  for (const galleryWithCartKey of Object.keys(cartMap)) {
    if (galleryMap[galleryWithCartKey]?.isLoading && galleryWithCartKey !== currentVisitKey) {
      await dispatch(getFullGallery(galleryWithCartKey));
    }
  }
  if (currentVisitKey) {
    dispatch(manageLockedCategories(currentVisitKey));
  }
};

/**
 * Gets the landing data for the given subject or gallery key, and sets account data in the account slice,
 * and the base gallery data and gallery config in the gallery slice
 */
export const getGalleryLanding =
  (key: string): PromiseThunk<GalleryLandingData | null> =>
  async (_, getState) => {
    try {
      const { galleryMap } = getState().gallery;
      const landingData = await api.getBaseGalleryData(key);

      // If this account has no valid shop merchant, send them to the fallback:
      if (!landingData.account.hasShopMerchant) {
        const split = key.split('/');
        window.location.replace(`${process.env.REACT_APP_FALLBACK_MERCHANT_URL}/${split[0]}`);
      }

      if (!Object.keys(galleryMap).length) {
        document.title = landingData.baseGallery.title;
      }
      return { valid: true, payload: landingData, error: null };
    } catch (error) {
      return {
        valid: false,
        payload: null,
        error,
      };
    }
  };

/**
 * loads all product nodes for a gallery given a visitKey
 */
export const getAllProductNodes =
  (visitKey: string, priceSheetID: number): PromiseThunk<null> =>
  async dispatch => {
    try {
      const nodeMap = await api.getAllProductNodes(visitKey);
      if (nodeMap) {
        dispatch(setProductNodeMap({ data: nodeMap, key: priceSheetID }));
      }
      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };
