import { Link, LinkProps } from '@chakra-ui/react';
import { Location, LocationDescriptor } from 'history';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends LinkProps {
  'data-test'?: string;
  label: string;
  onClick?(): void;
  to: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>);
}

const ButtonLink = ({ 'data-test': dataTest, label, onClick, to, ...rest }: Props) => (
  <Link
    as={RouterLink}
    backgroundColor="brand"
    borderRadius="8px"
    color="white"
    data-test={dataTest}
    fontFamily="ITC Avant Garde Gothic Demi"
    fontSize="xs"
    height="32px"
    lineHeight="14px"
    onClick={onClick}
    paddingX={3}
    paddingY={2}
    textAlign="center"
    to={to}
    _hover={{ textDecoration: 'none' }}
    {...rest}
  >
    {label}
  </Link>
);

export default ButtonLink;
