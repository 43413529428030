import { Box, Flex, SystemStyleObject, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { CreateCartProductReq } from '../../../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectPackageItemMap,
  selectPackageSteps,
} from '../../../../../../../redux/selectors/configurations.selectors';
import { setEditPackageStep } from '../../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/store';
import useConfigurationRouter from '../../../../useConfigurationRouter';

interface Props {
  activeItem: CreateCartProductReq;
  activeStep: string;
  packageItems: CreateCartProductReq[];
  steps: { step: string; label: string }[];
}

const ItemProgressionOverview = ({ activeItem, activeStep, packageItems, steps }: Props) => {
  const { completedPkgItems, completedSteps } = useAppSelector(selectConfiguration);
  const packageItemMap = useAppSelector(selectPackageItemMap);
  const packageSteps = useAppSelector(selectPackageSteps);

  const dispatch = useAppDispatch();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const { routeToWizardStep } = useConfigurationRouter();

  const createGoToStep = (step: string) => () => {
    dispatch(setEditPackageStep(step));
  };

  const renderProduct = (packageItem: CreateCartProductReq, index: number) => {
    const isActive = packageItem.id === activeItem?.id;
    const isComplete = completedPkgItems[packageItem.id!];

    const sx: SystemStyleObject = {};
    if (isActive) {
      sx.fontFamily = 'ITC Avant Garde Gothic Demi';
      sx.fontSize = 'sm';
    }

    let bgColor = isComplete ? 'successGreen' : 'grey.1';
    if (isActive) {
      bgColor = 'brand';
    }

    return (
      <Flex direction="column" paddingBottom={2} key={packageItem.id}>
        <Flex
          data-test="product-list"
          cursor="pointer"
          direction="row"
          onClick={() => routeToWizardStep('customize', index + 1)}
        >
          <Flex
            alignItems="center"
            bgColor={bgColor}
            borderRadius="50%"
            color={isActive || isComplete ? 'white' : 'black'}
            fontFamily="ITC Avant Garde Gothic Demi"
            fontSize="10px"
            height="23px"
            justifyContent="center"
            marginRight={2}
            width="23px"
          >
            {isComplete ? <FiCheck /> : index + 1}
          </Flex>
          {!isMobile && (
            <Flex
              flex={1}
              fontFamily={isActive ? 'ITC Avant Garde Gothic Demi' : undefined}
              fontSize={!isActive ? 'sm' : undefined}
            >
              {packageItemMap[packageItem.priceSheetItemID].name}
            </Flex>
          )}
        </Flex>
        {!isMobile && renderSteps(packageItem)}
      </Flex>
    );
  };

  const renderSteps = (packageItem: CreateCartProductReq) => {
    if (packageItem.id !== activeItem?.id) {
      return null;
    }

    return (
      <Flex direction="column" paddingLeft={7} paddingTop={2} paddingBottom={2}>
        {steps.map(({ step, label }) => {
          const isActiveStep = activeStep === step;
          const isComplete = completedSteps[step];

          // Determine if this is the first incomplete step
          const productSteps = packageSteps[activeItem.id!];
          const nextIncomplete = productSteps.find(
            productStep => !completedSteps[productStep.step],
          );

          // Allow navigation if this step has been completed, or is the next incomplete step
          const onClick =
            isComplete || nextIncomplete?.step === step ? createGoToStep(step) : undefined;

          let color = isComplete ? 'grey.2' : undefined;
          if (isActiveStep) {
            color = 'brand';
          }

          return (
            <Flex key={step} alignItems="center">
              <Box color="successGreen" width="30px">
                {isComplete && <FiCheck />}
              </Box>
              <Text
                color={color}
                cursor={onClick ? 'pointer' : undefined}
                fontFamily={isActiveStep || isComplete ? 'ITC Avant Garde Gothic Demi' : undefined}
                fontSize="sm"
                onClick={onClick}
                paddingY={1}
              >
                {label}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex
      direction={{ base: 'row', md: 'column' }}
      borderColor="grey.2"
      borderBottomWidth={{ base: '1px', md: 0 }}
      paddingBottom={{ base: '10px', md: 0 }}
      paddingTop={{ base: '20px', md: '80px' }}
      paddingX={{ base: '20px', md: '40px' }}
      width={{ base: '100%', md: undefined }}
    >
      {packageItems.map(renderProduct)}
    </Flex>
  );
};

export default ItemProgressionOverview;
