import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Z_INDEX_TWO } from '../../constants';

interface Props extends IconButtonProps {
  lightMode?: boolean;
  onClick?(): void;
}

const ArrowLeftButton = ({ lightMode = false, onClick, ...rest }: Props) => (
  <IconButton
    variant={lightMode ? 'modalLight' : 'modal'}
    icon={<HiOutlineArrowLeft />}
    onClick={onClick}
    zIndex={Z_INDEX_TWO}
    {...rest}
  />
);

export default ArrowLeftButton;
