import { Flex } from '@chakra-ui/react';
import React from 'react';
import { selectCartImageMap } from '../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectGroupedImageOptions } from '../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../redux/store';
import { PREORDER } from '../../../../shared/constants';
import RequiredImageOptionsByImage from '../RequiredImageOptionByImage/RequiredImageOptionByImage';

interface Props {
  onSelect(
    visitKey: string,
    imageName: string,
    optionID: number,
    selectionID: number | false,
  ): void;
  selectionsForCart: Record<string, Record<number, number | false | undefined>>;
  showError?: boolean;
  visitKey: string;
}

/**
 * required image options for a single cart
 * primarily a wrapper to inject image options and cart images
 */
const RequiredImageOptionsByCart = ({
  visitKey,
  onSelect,
  selectionsForCart,
  showError,
}: Props) => {
  const { isPreOrder, priceSheetID } = useAppSelector(state => selectGallery(state, visitKey));
  const { requiredImageOptions } = useAppSelector(state =>
    selectGroupedImageOptions(state, priceSheetID),
  );
  const cartImageMap = useAppSelector(state => selectCartImageMap(state, visitKey));

  if (isPreOrder) {
    return (
      <RequiredImageOptionsByImage
        imageName={PREORDER}
        imageOptions={requiredImageOptions}
        onSelect={onSelect}
        selectionsForCartImage={selectionsForCart[PREORDER] || {}}
        showError={showError}
        visitKey={visitKey}
      />
    );
  }

  return (
    <Flex direction="column">
      {Object.entries(cartImageMap).map(([imageName, backgroundSet]) => (
        <RequiredImageOptionsByImage
          key={imageName}
          backgroundSet={backgroundSet}
          imageName={imageName}
          imageOptions={requiredImageOptions}
          onSelect={onSelect}
          selectionsForCartImage={selectionsForCart[imageName] || {}}
          showError={showError}
          visitKey={visitKey}
        />
      ))}
    </Flex>
  );
};

export default RequiredImageOptionsByCart;
