import { Image } from '@chakra-ui/image';
import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { selectCart } from '../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';

const PhotoAlbums = () => {
  const { groupImageMap, sortedGroupIDs, groups, images } = useSelector(selectGallery);
  const { shopFavorites } = useSelector(selectCart);

  const { url } = useRouteMatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const handleAlbumClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      history.push(`${url}/${e.currentTarget.dataset.group}`);
    },
    [history, url],
  );

  const renderSidebarNavigation = () => {
    if (isMobile) {
      return null;
    }

    return (
      <VStack alignItems="left" spacing="3" width="310px" margin="20px" marginRight="60px">
        <Heading size="sm">
          {intl.formatMessage({
            id: 'photoAlbums.photos',
            defaultMessage: 'Photos',
          })}
        </Heading>
        <VStack paddingLeft="20px" alignItems="left">
          <Link to={`${url}/favorites`}>
            {intl.formatMessage(
              {
                id: 'photoAlbums.favoritesCount',
                defaultMessage: 'Favorites ({count})',
              },
              { count: shopFavorites.length },
            )}
          </Link>
          <Link to={`${url}/all`}>
            {intl.formatMessage(
              {
                id: 'photoAlbums.allPhotos',
                defaultMessage: 'All Photos ({count})',
              },
              { count: Object.keys(images).length },
            )}
          </Link>
        </VStack>
        <Heading size="sm">
          {intl.formatMessage({
            id: 'photoAlbums.albums',
            defaultMessage: 'Albums',
          })}
        </Heading>
        <VStack paddingLeft="20px" alignItems="left">
          {sortedGroupIDs.map(groupID => (
            <Link key={groupID} to={`${url}/${groupID}`}>
              {groups[groupID]} ({groupImageMap[groupID].length})
            </Link>
          ))}
        </VStack>
      </VStack>
    );
  };

  return (
    <Flex width="100%" justify="center" padding={{ base: '20px', md: '40px' }}>
      {renderSidebarNavigation()}
      <Flex direction="column" flex={1}>
        <Heading margin="5px" size="md">
          {intl.formatMessage({
            id: 'photoAlbums.albums',
            defaultMessage: 'Albums',
          })}
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} wrap={{ base: undefined, md: 'wrap' }}>
          {sortedGroupIDs.map(groupID => {
            const firstImage = groupImageMap[groupID][0];
            const photoCount = groupImageMap[groupID].length;
            const { height, width } = images[firstImage];
            const isLandscape = height > width;

            let renderWidth = !isLandscape ? 'auto' : '200px';
            let renderHeight = isLandscape ? 'auto' : '200px';
            if (isMobile) {
              renderHeight = 'auto';
              renderWidth = '100%';
            }

            return (
              <Box
                data-group={groupID}
                key={groupID}
                marginX="5px"
                marginY="15px"
                onClick={handleAlbumClick}
                width={{ base: undefined, md: '200px' }}
              >
                <Flex
                  align="center"
                  borderRadius={{ base: undefined, md: '8px' }}
                  height={{ base: 'auto', md: '200px' }}
                  justify={{ base: undefined, md: 'center' }}
                  overflow={{ base: undefined, md: 'hidden' }}
                  width={{ base: undefined, md: '200px' }}
                >
                  <Image
                    borderRadius={{ base: '8px', md: undefined }}
                    cursor="pointer"
                    data-test="photo-albums-image"
                    height={renderHeight}
                    maxHeight={!isLandscape ? '100%' : 'none'}
                    maxWidth={isLandscape ? '100%' : 'none'}
                    src={images[firstImage].sources.thumb}
                    width={renderWidth}
                  />
                </Flex>
                <Heading marginTop="2" noOfLines={2} size="sm">
                  {groups[groupID]}
                </Heading>
                <Text fontSize="xs">
                  {intl.formatMessage(
                    {
                      id: 'photoAlbums.photoCount',
                      defaultMessage: '{photoCount} photos',
                    },
                    { photoCount },
                  )}
                </Text>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PhotoAlbums;
