import { Box, Collapse, Flex, Icon, Spacer, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { ProductOption, ShopProduct } from '../../../../../../../../shop-api-client';
import {
  CartProductOptionReq,
  CreateCartProductReq,
} from '../../../../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../../../../redux/store';
import { formatCurrency } from '../../../../../../../shared/utils';
import { getOptionPrice } from '../../../../../../Carts/utils';

interface Props {
  price: string;
  editItem: CreateCartProductReq;
  shopPkgItem: ShopProduct;
}

const ItemWithOptionsRow = ({ editItem, price, shopPkgItem }: Props) => {
  const { currency } = useAppSelector(selectAccount);

  const [isExpanded, setIsExpanded] = useState(false);

  const shopOptionMap = shopPkgItem.options.reduce<Record<string, ProductOption>>((map, option) => {
    map[option.catalogOptionGroupID] = option;
    return map;
  }, {});

  const toggleCollapse = () => setIsExpanded(!isExpanded);

  const renderCaret = () => {
    if (isExpanded) {
      return <Icon as={FaCaretDown} fontSize="sm" position="absolute" left={-3} top={2} />;
    }
    return <Icon as={FaCaretRight} fontSize="sm" position="absolute" left={-3} top={2} />;
  };

  const renderOptionRow = (editOption: CartProductOptionReq) => {
    const shopOption = shopOptionMap[editOption.optionGroupID];
    const price = getOptionPrice(shopOption, editOption.optionID);

    return (
      <Flex key={editOption.optionGroupID} fontSize="xs" paddingX={3} width="100%">
        <Text>
          {shopOption.name}
          {shopOption.type !== 'boolean' && `: ${editOption.value}`}
        </Text>
        <Spacer />
        <Text height="100%" marginLeft={20}>
          {`+${price > 0 ? formatCurrency(price, currency) : '$0'}`}
        </Text>
      </Flex>
    );
  };

  return (
    <Box onClick={toggleCollapse} position="relative" width="100%">
      {renderCaret()}
      <Flex data-test="item-with-options" fontSize="sm" padding={1}>
        <Text textDecoration="underline">{shopPkgItem.name}</Text>
        <Spacer />
        <Text height="100%" marginLeft={20} marginRight={2}>
          {price}
        </Text>
      </Flex>
      <Collapse in={isExpanded}>
        <VStack paddingBottom={3} paddingTop={1}>
          {editItem.options!.map(renderOptionRow)}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default ItemWithOptionsRow;
