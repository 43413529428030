import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { SHOP } from '../../../shared/constants';
import { Params } from '../../../shared/types/router';

interface Props {
  showBuy?: boolean;
}

const ProductsBreadCrumb = ({ showBuy }: Props) => {
  const { categories, products } = useSelector(selectPriceSheet);
  const { key, packageID, productID } = useParams<Params>();
  const intl = useIntl();
  const product = products[productID || packageID!];

  if (!product) {
    return null;
  }
  const { categoryID, name } = product;

  return (
    <Breadcrumb fontSize="sm" marginY="35px">
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={`/${key}/shop/all`}>
          {SHOP}
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={`/${key}/shop/${categoryID}`}>
          {categories[categoryID].name}
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage={!showBuy} fontWeight={!showBuy ? 'extrabold' : 'normal'}>
        <BreadcrumbLink
          as={Link}
          to={`/${key}/shop/${categoryID}/product/${productID || packageID}`}
        >
          {name}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {!!showBuy && (
        <BreadcrumbItem isCurrentPage fontWeight="extrabold">
          <BreadcrumbLink
            as={Link}
            to={`/${key}/shop/${categoryID}/product/${productID || packageID}`}
          >
            {intl.formatMessage(
              {
                id: 'productsBreadCrumb.buyProduct',
                defaultMessage: 'Buy {product}',
              },
              {
                product: name,
              },
            )}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};

export default ProductsBreadCrumb;
