import { createSelector } from '@reduxjs/toolkit';
import { ImageNode } from 'iq-api-client';
import { CatalogProductNode } from 'iq-product-render';
import { keyBy } from 'lodash';
import {
  ImageRequirementType,
  PriceSheetOption,
  ShopBackground,
  ShopImage,
  ShopPackage,
  ShopProduct,
} from '../../../shop-api-client';
import {
  CartCollectionReq,
  CartDownloadReq,
  CartImageNode,
  CartImageOption,
  CartPrintProductReq,
  CollectionImage,
  CreateCartNodeReq,
  CreateCartProductReq,
  ImageNodeDefaultImage,
} from '../../../shop-api-client/models/Cart';
import { ImageOptionConfigMap } from '../../../shop-api-client/models/ShopConfig';
import { getBackgroundFees, getProductOptionFees } from '../../features/Carts/utils';
import {
  getBuildYourOwnStep,
  getCollectionImagesStep,
  getGroupedProductOptions,
  getImageCropStep,
  getImageMultiNodeStep,
  getImageNodesStep,
  getImageToneStep,
  getPackageImageAssignmentStep,
  getPackageSubItemStep,
  getPreOrderBackgroundsStep,
  getProductOptionsStep,
  getProductToNodeKey,
  getRequiredProductOptionsStep,
  getTextNodesStep,
  isConfigurable,
  meetsConfigRequirements,
  requiresPerImageBG,
  requiresPerProductBG,
} from '../../features/Products/Configuration/utils';
import {
  getPackageItemMap,
  isConfigurableImageNode,
  validateImageRequirement,
} from '../../features/Products/utils';
import { REGEX_CONFIG_SUB_ITEM_STEP } from '../../shared/constants/regex.constants';
import { UniqueImageAndBackgroundSet } from '../../shared/types/image';
import { getUniqueImages } from '../../shared/utils';
import { Gallery } from '../slices/gallery.slice';
import { RootState } from '../store';
import { getDefaultImgForNode, isJobImageNode } from '../thunks/utils';
import {
  selectBackgroundSetMap,
  selectBackgroundsMap,
  selectBackgroundToSetMap,
} from './background.selectors';
import { selectCart, selectImageToImageOptionLookup } from './cart.selectors';
import { selectGallery } from './gallery.selectors';
import { selectPriceSheet } from './priceSheet.selectors';
import { ADD_ONS, ADD_TEXT, CHOOSE_ADD_ONS, CHOOSE_PHOTO_HEADING, CONFIRM_CROP, CROP, PHOTO_NO_COLON, SELECT_YOUR_PHOTOS, TEXT } from '../../features/Products/Configuration/constants';

interface PackageStep {
  step: string;
  label: string;
  nextLabel: string;
}

export function selectConfiguration(state: RootState) {
  return state.configuration;
}

export const selectShowProductBGSelection = createSelector(
  (state: RootState) => state.configuration,
  selectGallery,
  selectPriceSheet,
  (
    { editPackage, editProduct },
    { isGreenScreen, isPreOrder },
    { backgroundSets, preOrderBackgroundSelectionType, products },
  ) => {
    if (!editProduct && !editPackage) {
      return false;
    }
    return requiresPerProductBG(
      products[(editProduct || editPackage!).priceSheetItemID].type,
      preOrderBackgroundSelectionType,
      isGreenScreen,
      isPreOrder,
      backgroundSets,
    );
  },
);

export const selectShowPkgItemBGSelection = createSelector(
  (state: RootState) => state.configuration,
  selectGallery,
  selectPriceSheet,
  (
    { editPackage },
    { isGreenScreen, isPreOrder },
    { backgroundSets, preOrderBackgroundSelectionType, products },
  ) => {
    if (!editPackage) {
      return false;
    }
    return requiresPerImageBG(
      products[editPackage.priceSheetItemID].type,
      preOrderBackgroundSelectionType,
      isGreenScreen,
      isPreOrder,
      backgroundSets,
    );
  },
);

/**
 * Finds and returns all pricesheet products for a package being configured
 */
export const selectPackageItemMap = createSelector(
  selectPriceSheet,
  (state: RootState) => state.configuration,
  ({ products }, { editPackage }) => {
    const pkg = editPackage ? products[editPackage.priceSheetItemID] : null;
    if (pkg?.type !== 'package' && pkg?.type !== 'package-byop') {
      return {};
    }
    return getPackageItemMap(pkg);
  },
);

/**
 * Returns an object containing `configurableItems` and `nonConfigurableItems`,
 * whose values are arrays of editPackage sub-items grouped by whether they are
 * configurable
 */
export const selectGroupedPkgItems = createSelector(
  selectPackageItemMap,
  selectGallery,
  selectPriceSheet,
  (state: RootState) => state.configuration,
  (itemMap, { isPreOrder }, { productNodeMap }, { editPackage }) =>
    (editPackage?.products || []).reduce<{
      configurableItems: CreateCartProductReq[];
      nonConfigurableItems: CreateCartProductReq[];
    }>(
      (result, subItem) => {
        const shopProduct = itemMap[subItem.priceSheetItemID];
        if (isConfigurable(isPreOrder, shopProduct, productNodeMap)) {
          result.configurableItems.push(subItem);
        } else {
          result.nonConfigurableItems.push(subItem);
        }
        return result;
      },
      { configurableItems: [], nonConfigurableItems: [] },
    ),
);

/**
 * Returns a count of added units:
 */
export const selectAddedUnits = createSelector(
  (state: RootState) => state.configuration,
  selectPackageItemMap,
  ({ editPackage }, packageItemMap) =>
    (editPackage?.products || []).reduce(
      (res, i) => res + packageItemMap[i.priceSheetItemID].price,
      0,
    ),
);

export const selectConfiguredProgress = createSelector(
  selectGroupedPkgItems,
  (state: RootState) => state.configuration.completedPkgItems,
  ({ configurableItems }, completedPkgItems) => {
    const configurableCount = configurableItems.length;
    const configurableDoneCount = configurableItems.filter(i => completedPkgItems[i.id!]).length;

    const progress = configurableDoneCount / configurableCount;

    // If `progress` is 0/0 (this happens if there are no configurable items), then it is NaN, so
    // multiply 100 by 1, since this means progress is completed despite nothing needing configured
    return Math.round((isNaN(progress) ? 1 : progress) * 100);
  },
);

export const selectConfiguredPkgItemsMap = createSelector(
  selectGallery,
  selectPackageItemMap,
  selectGroupedPkgItems,
  ({ isPreOrder }, packageItemMap, { configurableItems }) =>
    configurableItems.reduce<Record<string, boolean>>((res, item) => {
      const shopPkgItem = packageItemMap[item.priceSheetItemID];
      if (meetsConfigRequirements(item, shopPkgItem, isPreOrder)) {
        // Note: The non-null assertion is reliable here, as it is only optional so it can
        // be removed on local edit items when adding to cart, but it is present otherwise
        res[item.id!] = true;
      }
      return res;
    }, {}),
);

export const selectPackageSteps = createSelector(
  selectConfiguration,
  selectGallery,
  selectPriceSheet,
  (
    { editPackage },
    { isPreOrder, settings: { disableCropping } },
    { productNodeMap, products },
  ) => {
    const steps: Record<number, PackageStep[]> = {};
    if (!editPackage) {
      return steps;
    }

    const packageItemMap = getPackageItemMap(products[editPackage.priceSheetItemID] as ShopPackage);

    // Item configuration steps:
    for (const item of editPackage.products) {
      steps[item.id!] = [];

      const shopItem = (products[item.priceSheetItemID] ||
        packageItemMap[item.priceSheetItemID]) as ShopProduct;

      if (item.type === 'product') {
        const nodes = productNodeMap[shopItem.catalogProductID];

        // Track the nodes for this product
        const { imageNodeCount, textNodeCount } = nodes.reduce(
          (result, node) => {
            if (isConfigurableImageNode(node)) {
              result.imageNodeCount++;
            }
            if (node.type === 'text' && !node.locked) {
              result.textNodeCount++;
            }

            return result;
          },
          {
            imageNodeCount: 0,
            textNodeCount: 0,
          },
        );

        if (!isPreOrder) {
          // Image node steps (multi, single, image tone)
          if (imageNodeCount > 1) {
            steps[item.id!].push({
              step: getImageMultiNodeStep(item.id!),
              label: CHOOSE_PHOTO_HEADING,
              nextLabel: PHOTO_NO_COLON,
            });
          }

          if (imageNodeCount === 1) {
            steps[item.id!].push({
              step: getImageNodesStep(item.id!),
              label: CHOOSE_PHOTO_HEADING,
              nextLabel: PHOTO_NO_COLON,
            });

            if (!disableCropping) {
              steps[item.id!].push({
                step: getImageCropStep(item.id!),
                label: CONFIRM_CROP,
                nextLabel: CROP,
              });
            }
          }
        }

        if (textNodeCount) {
          steps[item.id!].push({
            step: getTextNodesStep(item.id!),
            label: ADD_TEXT,
            nextLabel: TEXT,
          });
        }
      } else if (!isPreOrder && (item.type === 'collection' || item.type === 'imageDownload')) {
        // Collection step for non-packaged products
        steps[item.id!].push({
          step: getCollectionImagesStep(item.id!),
          label: SELECT_YOUR_PHOTOS,
          nextLabel: PHOTO_NO_COLON,
        });
      }

      const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
        shopItem.options,
      );
      if (optionalProductOptions.length || requiredProductOptions.length) {
        steps[item.id!].push({
          step: getProductOptionsStep(item.id!),
          label: CHOOSE_ADD_ONS,
          nextLabel: ADD_ONS,
        });
      }
    }

    return steps;
  },
);

/**
 * Utility function for the `selectEditItemAvailableImages` selector below
 */
export const getAvailableImages = (
  gallery: Gallery,
  product: ShopProduct,
  productNodes: Record<string, CatalogProductNode[]>,
) => {
  const result = {
    product: [] as ShopImage[],
    nodes: {} as Record<string, ShopImage[]>,
    requiresImages: false,
  };

  const filterByRequirementType = (requirement: ImageRequirementType | null) =>
    Object.values(gallery.images).filter(image => {
      if (!requirement || requirement === 'any' || gallery.type === 'standard') {
        return true;
      }

      return (
        (image.group && requirement === 'group') || (!image.group && requirement === 'nonGroup')
      );
    });

  if (product.type === 'collection' || product.type === 'imageDownload') {
    result.requiresImages = true;
    const images = filterByRequirementType(product.imageRequirementType);
    for (const image of images) {
      result.product.push(image);
    }
  } else if (product.type === 'product') {
    // Products are parsed by node:
    const nodes = productNodes[product.catalogProductID];
    for (const node of nodes) {
      // Skip job image or non-image nodes:
      if (node.type !== 'image' || node.defaultImage === 'blank' || isJobImageNode(node)) {
        continue;
      }
      result.requiresImages = true;

      // Find the available images for this node:
      const requirements = product.imageRequirementProperties.find(n => n.nodeID === node.id);
      const requirementType = requirements?.type || 'any';
      let images = filterByRequirementType(requirementType);

      if (node.defaultImage && gallery.type !== 'standard') {
        // Find the default image:
        const defaultImage = getDefaultImgForNode(
          gallery,
          node.defaultImage as ImageNodeDefaultImage,
        );

        if (node.defaultImage !== 'subject.groupImage') {
          // A defaultImage is required, so overwrite images to include only
          // the defaultImage if present, or the images array is empty:
          images = defaultImage ? [defaultImage] : [];
        }
      }

      result.nodes[getProductToNodeKey(product.id, node.id)] = images;
      // Make sure to add all of the images to the product as well
      result.product.push(...images);
    }
  }

  return result;
};

/**
 * Create a map of available images per package, product, and node
 *
 * This map essentially goes through the logic of figuring out what images are available
 * for each of the tiers, when relevant, for a specific item being edited. If that item is
 * a package, the package tier is available, otherwise it's just product and nodes.
 *
 * The imageRequirementType property from the PriceSheet and the defaultImage property from
 * the catalog products are used to construct these maps
 */
export const selectEditItemAvailableImages = createSelector(
  selectConfiguration,
  selectGallery,
  selectPriceSheet,
  ({ editProduct, editPackage }, gallery, { products, productNodeMap }) => {
    const result = {
      package: [] as ShopImage[],
      products: {} as Record<number, ShopImage[]>,
      nodes: {} as Record<string, ShopImage[]>,
    };

    const productList = editPackage ? editPackage.products : [editProduct!];

    let productMap = products;
    const packageImageMap: Record<string, ShopImage> = {};
    if (editPackage) {
      const shopPkg = products[editPackage.priceSheetItemID] as ShopPackage;
      productMap = keyBy(shopPkg.availableProducts, 'id');
    }

    for (const product of productList) {
      const productResult = getAvailableImages(
        gallery,
        productMap[product.priceSheetItemID] as ShopProduct,
        productNodeMap,
      );

      result.nodes = {
        ...result.nodes,
        ...productResult.nodes,
      };
      result.products[product.id!] = productResult.product;

      for (const image of productResult.product) {
        packageImageMap[image.internalName] = image;
      }
    }
    result.package.push(...Object.values(packageImageMap));

    return result;
  },
);

/**
 * current images selected for the edit item
 * these are the images available for selection for the image options
 */
export const selectEditItemImageMap = createSelector(
  (state: RootState) => state.configuration.editPackage,
  (state: RootState) => state.configuration.editProduct,
  (editPackage, editProduct) => {
    const map: UniqueImageAndBackgroundSet = {};

    if (!editProduct && !editPackage) {
      return {};
    }

    const nodeLoop = (node: CreateCartNodeReq) => {
      if (node.type !== 'image' || !node.imageInternalName) {
        return;
      }

      if (!map[node.imageInternalName]) {
        map[node.imageInternalName] = new Set();
      }

      if (node.backgroundID) {
        map[node.imageInternalName].add(node.backgroundID);
      }
    };

    const collectionLoop = (image: CollectionImage) => {
      if (!image.internalName) {
        return;
      }

      if (!map[image.internalName]) {
        map[image.internalName] = new Set();
      }

      if (image.backgroundID) {
        map[image.internalName].add(image.backgroundID);
      }
    };

    const products = editPackage ? editPackage.products : [editProduct!];
    for (const product of products) {
      if (product.type === 'product') {
        product.nodes.forEach(nodeLoop);
      } else if (product.type === 'collection' || product.type === 'imageDownload') {
        product.collectionImages.forEach(collectionLoop);
      }
    }

    return map;
  },
);

export const selectLimitedPoses = createSelector(
  selectConfiguration,
  selectPriceSheet,
  selectGallery,
  ({ editPackage }, { products }, { images }) => {
    // Map of images (and backgrounds) to products they're used in
    const poseMap: Record<string, number[]> = {};

    if (!editPackage) {
      return poseMap;
    }

    const shopPackage = products[editPackage.priceSheetItemID] as ShopPackage;
    if (shopPackage.allowAdditionalPoses) {
      return poseMap;
    }

    const addToPoseMap = (
      productID: number,
      imageName: string,
      backgroundID: number | null | undefined,
    ) => {
      const key = backgroundID ? `${imageName}-${backgroundID}` : imageName;

      if (!poseMap[key]) {
        poseMap[key] = [];
      }

      poseMap[key].push(productID);
    };

    const nodeLoop = (product: CartPrintProductReq, node: CreateCartNodeReq) => {
      if (
        node.type !== 'image' ||
        !node.imageInternalName ||
        images[node.imageInternalName].group
      ) {
        return;
      }

      addToPoseMap(product.id!, node.imageInternalName, node.backgroundID);
    };

    const collectionLoop = (
      product: CartCollectionReq | CartDownloadReq,
      image: CollectionImage,
    ) => {
      if (!image.internalName || images[image.internalName].group) {
        return;
      }
      addToPoseMap(product.id!, image.internalName, image.backgroundID);
    };

    for (const product of editPackage.products) {
      if (product.type === 'product') {
        product.nodes.forEach(node => nodeLoop(product, node));
      } else if (product.type === 'collection' || product.type === 'imageDownload') {
        product.collectionImages.forEach(collectionImage =>
          collectionLoop(product, collectionImage),
        );
      }
    }

    return poseMap;
  },
);

/**
 * Utility function for `selectConfigurationSummary`
 *
 * Calculates the additions, updates and removal of image options relative
 * to the existing cart, when configuring a product.
 *
 * This also includes to total fees for image options, which can be negative
 * if you are swapping out a more expensive image option selection that exists
 * on your cart.
 *
 */
const getImageOptionSummary = (
  cartImageOptions: CartImageOption[],
  editImageOptionsMap: ImageOptionConfigMap,
  editItemImagesMap: UniqueImageAndBackgroundSet,
  imageOptionMap: Record<string, PriceSheetOption>,
  imageLookupMap: Record<string, CartImageOption[]>,
) => {
  let imageOptionFees = 0;
  const imageOptionSummary = {
    count: 0,
    new: 0,
    updated: 0,
    removed: 0,
  };

  // Convert the cartImageOptions to a map, mirroring the editImageOptionsMap:
  const cartImageOptionsMap = cartImageOptions.reduce<ImageOptionConfigMap>(
    (result, cartImageOption) => {
      const { priceSheetOptionID, optionID } = cartImageOption;

      if (!result[priceSheetOptionID]) {
        result[priceSheetOptionID] = {};
      }

      result[priceSheetOptionID]![optionID] = cartImageOption;
      return result;
    },
    {},
  );

  const imageSet = new Set<string>();

  // Parse the image options from the configuration data:
  for (const [imageOptionID, imageOption] of Object.entries(editImageOptionsMap)) {
    if (!imageOption) {
      if (cartImageOptionsMap[imageOptionID]) {
        // Count how many items this removes:
        for (const cartImageOption of Object.values(cartImageOptionsMap[imageOptionID]!)) {
          imageOptionSummary.removed += cartImageOption.images.length;
          imageOptionFees -=
            cartImageOption.images.length * (cartImageOption as CartImageOption).unitPrice;
        }
      }

      // Nothing further todo
      continue;
    }

    const shopOptionGroup = imageOptionMap[imageOptionID];
    for (const selection of Object.values(imageOption)) {
      const shopOption = shopOptionGroup.selections.find(
        catalogSelection => catalogSelection.catalogOptionID === selection.optionID,
      );
      const price =
        shopOptionGroup.type === 'selection' && shopOptionGroup.selectionPricing && shopOption
          ? shopOption.price
          : shopOptionGroup.price;

      // This selection needs to iterate through its images:
      for (const image of selection.images) {
        let existingImageOptions: CartImageOption[] = [];

        if (image.imageName) {
          imageSet.add(`${image.imageName}-${imageOptionID}`);
          // Find the list of image options associated with this image
          existingImageOptions = imageLookupMap[image.imageName] || [];
        }

        // Find if the image is already in the cart with the same selection
        if (existingImageOptions.find(io => io.optionID === selection.optionID)) {
          continue;
        }

        // Find if the image is assigned to an image option already in the cart
        const existingImageOption = existingImageOptions.find(
          io => io.priceSheetOptionID === selection.priceSheetOptionID,
        );
        if (existingImageOption) {
          // Image is in the cart, but with a different selection, calculate the difference in price:
          imageOptionFees += price - existingImageOption.unitPrice;
          // Mark this option as an update:
          imageOptionSummary.updated++;
        } else {
          // New image option altogether:
          imageOptionSummary.new++;
          imageOptionFees += price;
        }
      }
    }
  }

  // Finally check if any image options from the cart are no longer present, meaning they
  // should be counted as removals:
  for (const cartImageOption of cartImageOptions) {
    for (const image of cartImageOption.images) {
      if (
        editItemImagesMap[image.imageName] &&
        editImageOptionsMap[cartImageOption.priceSheetOptionID] &&
        !imageSet.has(`${image.imageName}-${cartImageOption.priceSheetOptionID}`)
      ) {
        imageOptionSummary.removed++;
        imageOptionFees -= cartImageOption.unitPrice;
      }
    }
  }

  imageOptionSummary.count =
    imageOptionSummary.new + imageOptionSummary.updated + imageOptionSummary.removed;

  return {
    imageOptionFees,
    imageOptionSummary,
  };
};

export const selectConfigurationSummary = createSelector(
  (state: RootState) => state.configuration,
  selectPriceSheet,
  selectGallery,
  selectBackgroundSetMap,
  selectBackgroundToSetMap,
  selectEditItemImageMap,
  selectImageToImageOptionLookup,
  selectCart,
  (
    { editImageOptionsMap, editProduct, editPackage },
    { imageOptionMap, productNodeMap, products },
    { images, isPreOrder, type },
    backgroundSetsMap,
    backgroundToSetMap,
    editItemImagesMap,
    imageLookupMap,
    { cartImageOptions },
  ) => {
    let subtotal = 0;
    let editProductPrice = 0;
    let editPackagePrice = 0;
    let backgroundFees = 0;
    let productAddOnsTotal = 0;
    let additionalPoseFees = 0;
    let additionalPoses = 0;
    const includedImageOptions: Record<string, PriceSheetOption> = {};

    if (editProduct) {
      const shopProduct = products[editProduct.priceSheetItemID];
      subtotal += shopProduct?.price || 0;
      editProductPrice = shopProduct?.price || 0;
      backgroundFees = getBackgroundFees(
        editProduct,
        shopProduct,
        backgroundSetsMap,
        backgroundToSetMap,
      );
      productAddOnsTotal = getProductOptionFees(editProduct, shopProduct);
    } else if (editPackage) {
      const shopPackage = products[editPackage.priceSheetItemID] as ShopPackage;

      for (const includedImageOption of shopPackage.options) {
        includedImageOptions[includedImageOption.id] = includedImageOption;
      }

      subtotal += shopPackage?.price;
      editPackagePrice = shopPackage?.price || 0;
      backgroundFees = getBackgroundFees(
        editPackage,
        shopPackage,
        backgroundSetsMap,
        backgroundToSetMap,
      );
      productAddOnsTotal = getProductOptionFees(editPackage, shopPackage);

      const uniqueImages = isPreOrder
        ? []
        : getUniqueImages(editPackage, shopPackage, images, type, productNodeMap);

      if (uniqueImages.length > shopPackage.posesIncluded) {
        if (shopPackage.additionalPoseFeeType === 'oneTime') {
          additionalPoseFees = shopPackage.additionalPoseFee;
          additionalPoses = 1;
        } else if (shopPackage.additionalPoseFeeType === 'perImage') {
          const additional = uniqueImages.length - shopPackage.posesIncluded;
          additionalPoseFees = additional * shopPackage.additionalPoseFee;
          additionalPoses = additional;
        }
      }
    }

    const { imageOptionFees, imageOptionSummary } = getImageOptionSummary(
      cartImageOptions,
      editImageOptionsMap,
      editItemImagesMap,
      { ...imageOptionMap, ...includedImageOptions },
      imageLookupMap,
    );

    subtotal += additionalPoseFees + backgroundFees + productAddOnsTotal + imageOptionFees;

    return {
      additionalPoses,
      additionalPoseFees,
      backgroundFees,
      editPackagePrice,
      editProductPrice,
      imageOptionFees,
      imageOptionSummary,
      productAddOnsTotal,
      subtotal,
    };
  },
);

export const selectAvailableImagesForOptions = createSelector(
  selectGallery,
  selectEditItemImageMap,
  ({ images, type }, editItemImageMap) => {
    // Create a map for all three requirement types:
    const requirementTypes: ImageRequirementType[] = ['any', 'group', 'nonGroup'];

    const result: Record<ImageRequirementType, Record<string, Set<number>>> = {
      any: {},
      group: {},
      nonGroup: {},
    };

    for (const requirementType of requirementTypes) {
      const imageMap = Object.keys(editItemImageMap).reduce<Record<string, Set<number>>>(
        (map, imageName) => {
          if (type === 'standard' || validateImageRequirement(images[imageName], requirementType)) {
            map[imageName] = editItemImageMap[imageName];
          }

          return map;
        },
        {},
      );

      result[requirementType] = imageMap;
    }

    return result;
  },
);

export const selectSteps = createSelector(
  selectConfiguration,
  selectGallery,
  selectPriceSheet,
  selectPackageItemMap,
  selectShowProductBGSelection,
  selectShowPkgItemBGSelection,
  (
    { editProduct, editPackage },
    { isPreOrder, isGreenScreen, settings: { showBwAndSepia } },
    { productNodeMap, products, preOrderBackgroundSelectionType, backgroundSets },
    packageItemMap,
    showProductBGSelection,
    showPkgItemBGSelection,
  ) => {
    const steps: string[] = [];
    const editItem = editPackage || editProduct;
    if (!editItem) {
      return steps;
    }

    // Pre-order background step:
    if ((showProductBGSelection || showPkgItemBGSelection) && editItem.id) {
      if (
        (editItem.type === 'standard' || editItem.type === 'buildYourOwn') &&
        preOrderBackgroundSelectionType === 'perImage'
      ) {
        for (const product of editItem.products) {
          if (product.type !== 'nonPrintProduct') {
            steps.push(getPreOrderBackgroundsStep(product.id!, preOrderBackgroundSelectionType));
          }
        }
      } else {
        steps.push(getPreOrderBackgroundsStep(editItem.id, preOrderBackgroundSelectionType));
      }
    }

    // Package steps - BYOP
    if (editItem.type === 'buildYourOwn') {
      steps.push(getBuildYourOwnStep());
    }

    let isSinglePosePackage = false;

    // Package - Single Pose Image step:
    if ((editItem.type === 'buildYourOwn' || editItem.type === 'standard') && !isPreOrder) {
      const shopPackage = products[editItem.priceSheetItemID] as ShopPackage;
      isSinglePosePackage = shopPackage.posesIncluded === 1 && !shopPackage.allowAdditionalPoses;

      // Make sure that this package has at least a collection product or print product
      // with an image node:
      const hasImage = editItem.products.some(product => {
        if (['collection', 'imageDownload'].includes(product.type)) {
          return true;
        }

        if (product.type === 'product') {
          const subItem = shopPackage.availableProducts.find(
            p => p.id === product.priceSheetItemID,
          );
          const nodes = productNodeMap[subItem?.catalogProductID!];
          const nodeMap = nodes.reduce<Record<number, ImageNode>>((result, node) => {
            if (node.type === 'image' && isConfigurableImageNode(node)) {
              result[node.id] = node;
            }
            return result;
          }, {});
          return product.nodes.some(node => nodeMap[node.catalogNodeID]);
        }

        return false;
      });

      if (isSinglePosePackage && hasImage) {
        steps.push(getPackageImageAssignmentStep());
      }
    }

    // Package boolean - will not work when type flow is required
    // So you will see a few if-statements with the explicit type check
    const isPackage = editItem.type === 'buildYourOwn' || editItem.type === 'standard';

    // Iterate through all product(s):
    let items: CreateCartProductReq[] = [];
    if (editItem.type === 'buildYourOwn') {
      items = editItem.products.filter(p => {
        const requiresBG = requiresPerImageBG(
          p.type,
          preOrderBackgroundSelectionType,
          isGreenScreen,
          isPreOrder,
          backgroundSets,
        );
        return (
          requiresBG || isConfigurable(true, packageItemMap[p.priceSheetItemID], productNodeMap)
        );
      });
    } else if (editItem.type === 'standard') {
      items = editItem.products;
    } else {
      items = [editItem];
    }

    // Item configuration steps:
    for (const item of items) {
      const shopItem = (products[item.priceSheetItemID] ||
        packageItemMap[item.priceSheetItemID]) as ShopProduct;

      let packageTextNodes = 0;

      if (item.type === 'product') {
        const nodes = productNodeMap[shopItem.catalogProductID];

        // Track the nodes for this product
        const { imageNodeCount, textNodeCount } = nodes.reduce(
          (result, node) => {
            if (isConfigurableImageNode(node)) {
              result.imageNodeCount++;
            }
            if (node.type === 'text' && !node.locked) {
              result.textNodeCount++;
              packageTextNodes++;
            }

            return result;
          },
          {
            imageNodeCount: 0,
            textNodeCount: 0,
          },
        );

        if (!isPreOrder && !isSinglePosePackage) {
          // Image node steps (multi, single, image tone)
          if (imageNodeCount > 1) {
            steps.push(getImageMultiNodeStep(item.id!));
          }

          if (imageNodeCount === 1) {
            steps.push(getImageNodesStep(item.id!));

            if (showBwAndSepia && !isGreenScreen) {
              steps.push(getImageToneStep(item.id!));
            }
          }
        }

        if (textNodeCount) {
          steps.push(getTextNodesStep(item.id!));
        }
      } else if (
        !isSinglePosePackage &&
        !isPreOrder &&
        (item.type === 'collection' || item.type === 'imageDownload')
      ) {
        // Collection step for non-packaged products
        steps.push(getCollectionImagesStep(item.id!));
      }

      // Package - per item required product options step:
      if (isPackage) {
        const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
          shopItem.options,
        );
        for (const { catalogOptionGroupID } of requiredProductOptions) {
          steps.push(getRequiredProductOptionsStep(item.id!, catalogOptionGroupID));
        }

        const requiresBG = requiresPerImageBG(
          item.type,
          preOrderBackgroundSelectionType,
          isGreenScreen,
          isPreOrder,
          backgroundSets,
        );

        if (
          packageTextNodes ||
          optionalProductOptions.length ||
          requiredProductOptions.length ||
          requiresBG
        ) {
          // Push package sub-item step last, since the completion of this step
          // depends on the completion of its child steps (text nodes and options):
          steps.push(getPackageSubItemStep(item.id!));
        }
      }
    }

    if (!isPackage) {
      const shopItem = products[editItem.priceSheetItemID] as ShopProduct;

      const { requiredProductOptions } = getGroupedProductOptions(shopItem.options);
      for (const { catalogOptionGroupID } of requiredProductOptions) {
        // Required product options step:
        steps.push(getRequiredProductOptionsStep(editItem.id!, catalogOptionGroupID));
      }
    }

    return steps;
  },
);

export const selectActiveStep = createSelector(
  selectConfiguration,
  selectSteps,
  ({ editPackage, completedSteps, editStep }, steps) => {
    // Either return the `editStep` or, if configuring a package, the first incomplete sub-item
    // section. Otherwise, if configuring a product, the first configuration section is returned:
    return (
      editStep ||
      steps.find(
        step => (!editPackage || !step.match(REGEX_CONFIG_SUB_ITEM_STEP)) && !completedSteps[step],
      )
    );
  },
);

export const selectIsStepCompleted = createSelector(
  [selectConfiguration, (_, stepName: string) => stepName],
  ({ completedSteps, editPackage, editPackageStep, editStep }, stepName) => {
    const step = editPackage ? editPackageStep : editStep;
    return step !== stepName && completedSteps[stepName];
  },
);

export const selectShopProduct = createSelector(
  selectConfiguration,
  selectPriceSheet,
  ({ editProduct }, { products }) => {
    // This selector assumes it's only called from places where editProduct has been set
    return products[editProduct!.priceSheetItemID];
  },
);

export const selectSinglePoseImage = createSelector(
  selectConfiguration,
  selectGallery,
  selectBackgroundsMap,
  ({ editPackage }, { images, isGreenScreen }, backgroundsMap) => {
    const imgList = Object.values(images);
    const isSingleGreenScreen = isGreenScreen && imgList.length === 1 && imgList[0].isGreenScreen;

    const result: { image?: ShopImage; background?: ShopBackground } = {
      image: undefined,
      background: undefined,
    };

    for (const item of editPackage!.products) {
      if (item.type === 'product') {
        const found = item.nodes.find(
          n => n.type === 'image' && n.imageInternalName,
        ) as CartImageNode;
        result.image = found?.imageInternalName ? images[found.imageInternalName] : undefined;
        result.background = found?.backgroundID ? backgroundsMap[found.backgroundID] : undefined;
      } else if (item.type === 'collection' || item.type === 'imageDownload') {
        const found = item.collectionImages.find(i => i.internalName);
        result.image = found?.internalName ? images[found.internalName] : undefined;
        result.background = found?.backgroundID ? backgroundsMap[found.backgroundID] : undefined;
      }

      // If an image was found, early return:
      if (result.image) {
        return result;
      }
    }

    // If only a single image is present on the gallery, set in result.
    // This must be done after looping through editPackage products to check for background.
    if (isSingleGreenScreen) {
      result.image = imgList[0];
    }

    return result;
  },
);

/**
 * Validates that a full-order gallery has necessary images needed to support a product
 * for default images or product image requirements
 *
 * Note: This needs to be NOT in pricesheet.selector.ts or gallery.selector.ts
 * because of circular dependency chaining that would happen.
 */
export const validateGalleryImageSupport = createSelector(
  [
    state => selectGallery(state),
    state => selectPriceSheet(state),
    (_: RootState, shopItem: ShopProduct | ShopPackage) => shopItem,
  ],
  (gallery, { backgroundSets, productNodeMap }, shopItem) => {
    const { images, isGreenScreen, isPreOrder } = gallery;

    if (isPreOrder) {
      return true;
    }

    const productList =
      shopItem.type === 'package' || shopItem.type === 'package-byop'
        ? shopItem.availableProducts
        : [shopItem!];

    for (const product of productList) {
      if (product.type === 'nonPrintProduct') {
        continue;
      }

      if (product.type === 'collection' || product.type === 'imageDownload') {
        // Get a count of available backgrounds. This is used in combination with green
        // screen images to represent the number of possible images with unique backgrounds:
        const backgroundCount = isGreenScreen
          ? backgroundSets.reduce((num, set) => (num += set.backgrounds.length), 0)
          : 0;
        let imageCount = 0;

        for (const image of Object.values(images)) {
          if (imageCount >= product.minImages) {
            break;
          }
          // Increment count by number of unique backgrounds or 1:
          imageCount += image.isGreenScreen ? backgroundCount : 1;
        }

        // If the imageCount is less than the minimum number of images required for the
        // product it cannot be configured/purchased in this gallery:
        if (imageCount < product.minImages) {
          return false;
        }
      }

      const productResult = getAvailableImages(gallery, product, productNodeMap);

      // If the product has no images, or any of its nodes have no images, we can't
      // sell this product:
      if (
        productResult.requiresImages &&
        (productResult.product.length === 0 ||
          Object.values(productResult.nodes).some(images => images.length === 0))
      ) {
        return false;
      }
    }

    return true;
  },
);
