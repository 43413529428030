import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopPackage, ShopProductPrint } from '../../../../../../../shop-api-client';
import { selectBackgroundSetMap } from '../../../../../../redux/selectors/background.selectors';
import {
  selectConfiguration,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { Params } from '../../../../../../shared/types/router';
import ProductImage from '../../../../ProductImage';
import ProductPreview from '../../../../ProductPreview';
import { NEXT_BUILD_PACKAGE, NEXT_CUSTOMIZE } from '../../../constants';
import Backgrounds from '../../../shared/Backgrounds';
import useConfigurationRouter from '../../../useConfigurationRouter';
import { getBackground } from '../../../utils';
import EditorSidebar from '../../EditorSidebar';
import PackageFooter from '../../PackageFooter';

const Background = () => {
  const { editPackage } = useSelector(selectConfiguration);
  const { isPreOrder } = useSelector(selectGallery);
  const { products } = useSelector(selectPriceSheet);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const packageItemMap = useSelector(selectPackageItemMap);

  const { routeToWizardStep, goToDetails } = useConfigurationRouter();
  const { packageID } = useParams<Params>();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const shopPackage = products[packageID] as ShopPackage;
  const isBYOP = shopPackage?.type === 'package-byop';

  const selectedBackground = editPackage
    ? getBackground(editPackage, Object.values(backgroundSetMap))
    : undefined;

  const handleBack = () => goToDetails(shopPackage.categoryID);
  const handleNext = () => routeToWizardStep(isBYOP ? 'byop' : 'customize');

  if (!editPackage) {
    return null;
  }

  const renderProducts = () => {
    if (isMobile) {
      return null;
    }

    return (
      <Flex direction="row" flexWrap="wrap" width="100%">
        {editPackage.products.map(product => (
          <Flex key={product.id} direction="column" width="250px" margin={6}>
            {!isPreOrder && product.type === 'product' && (
              <ProductPreview
                editProduct={product}
                hidePreviewText
                isInteractive={false}
                maxPreviewHeight={250}
                maxPreviewWidth={250}
                shopProduct={packageItemMap[product.priceSheetItemID] as ShopProductPrint}
              />
            )}
            {(isPreOrder || product.type !== 'product') && (
              <ProductImage
                fallbackFontSize="xl"
                fallbackIconSize="100px"
                height={undefined}
                image={packageItemMap[product.priceSheetItemID].image || ''}
                product={packageItemMap[product.priceSheetItemID]}
                maxHeight={250}
                maxWidth={250}
                isSquare
              />
            )}
            <Text color="grey.6" fontFamily="ITC Avant Garde Gothic Demi" fontSize="sm" marginY={2}>
              {packageItemMap[product.priceSheetItemID].name}
            </Text>
          </Flex>
        ))}
      </Flex>
    );
  };

  return (
    <Flex overflow="hidden" direction={{ base: 'column', md: 'row' }}>
      {renderProducts()}
      <EditorSidebar>
        <Box paddingBottom={10}>
          <Backgrounds editItem={editPackage} selectedBackground={selectedBackground} showPreview />
        </Box>
      </EditorSidebar>
      <PackageFooter
        onBack={handleBack}
        onNext={handleNext}
        nextLabel={isBYOP ? NEXT_BUILD_PACKAGE : NEXT_CUSTOMIZE}
      />
    </Flex>
  );
};

export default Background;
