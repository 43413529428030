import { Flex } from '@chakra-ui/layout';
import {
  Box,
  Heading,
  Link,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { api } from '../../../shop-api-client';
import { FacialRecognitionInitials } from '../../../shop-api-client/models/FacialRecognition';
import CheckMarkIcon from '../../shared/components/CheckMarkIcon';
import Logo from '../../shared/components/Logo';
import colors from '../../theme/colors';
import AcceptConditionsStep from './AcceptConditionsStep';
import AdditionalInformationStep from './AdditionalInformationStep';
import RegisterSubjectStep from './RegisterSubjectStep';
import { SubjectRegistrationFields } from './RegisterSubjectStep/RegisterSubjectStep';
import UploadSubjectStep from './UploadSubjectStep';
import { UploadStepPayload } from './UploadSubjectStep/UploadSubjectStep';

interface RouteParams {
  galleryID: string;
  token?: string;
}

const FacialRecognition = () => {
  const { galleryID, token } = useParams<RouteParams>();

  const [landingData, setLandingData] = useState<FacialRecognitionInitials>();
  const [sessionToken, setSessionToken] = useState<string | undefined>(token);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);

  // Refs for scrolling:
  const additionalRef = React.createRef<HTMLDivElement>();
  const acceptConditionsRef = React.createRef<HTMLDivElement>();
  const uploadRef = React.createRef<HTMLDivElement>();

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const intl = useIntl();
  const history = useHistory();
  const toast = useToast();

  const hasCustomFormStep = !!landingData?.customFields.length;

  useEffect(() => {
    if (!loading) {
      return;
    }

    const init = async () => {
      try {
        const landingData = await api.initializeFacialRecognition(galleryID, sessionToken);
        if (landingData) {
          setLandingData(landingData);
          setStep(landingData.subjectID ? 5 : 1);
        }
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    };

    init();
  }, [loading, galleryID, sessionToken]);

  useEffect(() => {
    if (step !== 5) {
      return;
    }

    let counter = 0;

    const intervalId = setInterval(() => {
      console.log('Checking registration for match…');
      api
        .initializeFacialRecognition(galleryID, sessionToken)
        .then(landingData => {
          if (counter > 20 || (landingData && landingData.subjectID)) {
            setLandingData(landingData);

            // Stop looping
            clearInterval(intervalId);
          }

          counter++;
        })
        .catch(error => {
          // Stop looping
          clearInterval(intervalId);

          console.log(error);
        });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [galleryID, sessionToken, step]);

  if (!landingData) {
    return <Spinner />;
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHOP_URL}/find-me/${galleryID}`);
    toast({ title: 'Link copied' });
  };

  const handleAdditionalInformation = async (formData: Record<string, string>) => {
    if (sessionToken) {
      await api.updateFacialRecognitionRegistration(sessionToken, formData);
      setStep(3);
      acceptConditionsRef.current?.scrollIntoView();
    }
  };

  const handleRegistration = async ({
    email,
    firstName,
    lastName,
    phoneNumber,
  }: SubjectRegistrationFields) => {
    if (sessionToken) {
      await api
        .updateFacialRecognitionRegistration(sessionToken, {
          email,
          firstName,
          lastName,
          phoneNumber,
        })
        .catch(e => {
          console.error(e);
          return null;
        });

      setStep(2);
      return;
    }

    // Validate input fields:
    const token = await api
      .createFacialRecognitionRegistration(landingData.galleryID, {
        email,
        firstName,
        lastName,
        phoneNumber,
      })
      .catch(e => {
        console.error(e);
        return null;
      });

    setSessionToken(token || undefined);

    if (token) {
      // Send the user to the next step, but also update the URL to include the token
      // so that if they refresh they will be on the right session immediately.
      history.push(`/find-me/${galleryID}/${token}`);
      setStep(2);
      if (hasCustomFormStep) {
        additionalRef.current?.scrollIntoView();
      } else {
        acceptConditionsRef.current?.scrollIntoView();
      }
    } else {
      // TODO: Set error status visually
      console.log('Did not receive a token');
    }
  };

  const handleUpload = async (uploadPayload: UploadStepPayload) => {
    const { image, agreedToPolicy, smsOptIn } = uploadPayload;

    if (!sessionToken) {
      // TODO: Set error status visually
      return;
    }

    // If they did not agree to the required policies; flag it:
    if (!agreedToPolicy) {
      // TODO: Set status visually
    }

    const imageElement = await loadImage(image).catch(() => null);
    if (!imageElement) {
      // TODO: Handle error
      return;
    }

    const payload = {
      accountID: landingData.accountID,
      galleryID: landingData.galleryID,
      image: {
        format: 'image/jpeg',
        height: imageElement.height,
        width: imageElement.width,
      },
      imageString: image,
      // This property is set by agreeing to the biometrics step
      agreedToBiometrics: true,
      agreedToPolicy,
      smsOptIn,
    };

    try {
      await api.uploadFacialRecognitionImage(sessionToken, payload);
      setStep(5);
    } catch (e) {
      console.error(e);
    }
  };

  const loadImage = async (imageSrc: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = imageSrc;
    });
  };

  const renderHeader = () => {
    // The mobile has the logo as a sticky header at the top instead
    if (isMobile) {
      return (
        <>
          <Flex height="60px" alignItems="center" borderColor="grey.2" borderBottomWidth="1px">
            <Logo
              fallbackMargin={[8, 8, 8, 16]}
              fallbackText={landingData.company}
              imageSrc={landingData.logoImage}
              margin="auto"
              maxHeight="40px"
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" paddingX={6}>
            <Heading
              fontFamily="ITC Avant Garde Gothic Demi"
              fontSize="25px"
              fontWeight="bold"
              marginTop={4}
            >
              {landingData.title}
            </Heading>
            <Link as={Text} marginX={5} onClick={handleCopyLink} variant="underline">
              {intl.formatMessage({
                id: 'facialRecognition.copyLink',
                defaultMessage: 'Copy registration link',
              })}
            </Link>
          </Flex>
        </>
      );
    }

    return (
      <Flex
        flexDirection="row"
        paddingY={4}
        height="115px"
        borderColor="grey.2"
        borderBottomWidth="1px"
      >
        <Heading fontSize="md" fontWeight="bold" marginLeft={6}>
          {landingData?.title}
        </Heading>
        <Flex flex={1} justifyContent="center">
          <Logo
            fallbackMargin={[8, 8, 8, 16]}
            fallbackText={landingData.company}
            imageSrc={landingData.logoImage}
            maxWidth="250px"
            maxHeight="100px"
          />
        </Flex>
        <Link as={Text} marginX={5} onClick={handleCopyLink} variant="underline" marginRight={6}>
          {intl.formatMessage({
            id: 'facialRecognition.copyLink',
            defaultMessage: 'Copy registration link',
          })}
        </Link>
      </Flex>
    );
  };

  const renderCompleted = () => {
    return (
      <Flex flexDirection="column" textAlign="center" marginX="auto" width="250px">
        <CheckMarkIcon backgroundColor="brand" alignSelf="center" width="64px" height="64px" />
        <Heading fontSize="18px" fontWeight="bold" marginY={4}>
          {intl.formatMessage({
            id: 'facialRecognition.youAreRegistered',
            defaultMessage: 'You are registered!',
          })}
        </Heading>
        <Text>
          {intl.formatMessage({
            id: 'facialRecognition.letYouknow',
            defaultMessage: 'We will let you know when your photos are available.',
          })}
        </Text>
      </Flex>
    );
  };

  const renderSteps = () => {
    const conditionsStepNumber = hasCustomFormStep ? 3 : 2;
    const uploadStepNumber = hasCustomFormStep ? 4 : 3;

    return (
      <Flex flexDirection="column">
        <RegisterSubjectStep
          initialEmail={landingData.email}
          initialFirstName={landingData.firstName}
          initialLastName={landingData.lastName}
          initialPhoneNumber={landingData.phoneNumber}
          isActive={step === 1}
          isComplete={step > 1}
          onEdit={() => setStep(1)}
          onRegistration={handleRegistration}
          stepNumber={1}
        />
        {hasCustomFormStep && (
          <AdditionalInformationStep
            fields={landingData.customFields}
            isActive={step === 2}
            isComplete={step > 2}
            onEdit={() => setStep(2)}
            onSubmit={handleAdditionalInformation}
            stepNumber={2}
            ref={additionalRef}
          />
        )}
        <AcceptConditionsStep
          isActive={step === conditionsStepNumber}
          isComplete={step > conditionsStepNumber}
          onSubmit={() => setStep(conditionsStepNumber + 1)}
          onEdit={() => setStep(conditionsStepNumber)}
          stepNumber={conditionsStepNumber}
          studioName={landingData.company}
          ref={acceptConditionsRef}
        />
        <UploadSubjectStep
          isActive={step === uploadStepNumber}
          isComplete={step > uploadStepNumber}
          onSubmit={handleUpload}
          stepNumber={uploadStepNumber}
          ref={uploadRef}
        />
      </Flex>
    );
  };

  const renderDidYouKnow = () => {
    if (isMobile) {
      return null;
    }

    return (
      <Flex
        alignSelf="flex-start"
        bgColor={colors.grey[1]}
        borderRadius="12px"
        flex="0 0 350px"
        flexDirection="column"
        marginY={[12, 12, 12, 0]}
        paddingBottom={10}
        paddingTop={8}
        paddingX={12}
      >
        <Heading fontSize="20px" fontWeight="bold" marginY={4}>
          {intl.formatMessage({
            id: 'facialRecognition.didYouKnow',
            defaultMessage: 'Did you know?',
          })}
        </Heading>
        <UnorderedList marginTop={4} size="8" spacing="8">
          <ListItem>
            {intl.formatMessage({
              id: 'facialRecognition.didYouKnowLine1',
              defaultMessage:
                'We will notify you and provide a link to view your photos once they are available',
            })}{' '}
          </ListItem>
          <ListItem>
            {intl.formatMessage({
              id: 'facialRecognition.didYouKnowLine2',
              defaultMessage:
                'We take your privacy and security very seriously, and will never share your information with anyone',
            })}
          </ListItem>
          <ListItem>
            {intl.formatMessage({
              id: 'facialRecognition.didYouKnowLine3',
              defaultMessage:
                "To share this form simply select 'Copy Registration Link' located at the top",
            })}
          </ListItem>
        </UnorderedList>
      </Flex>
    );
  };

  return (
    <>
      {renderHeader()}
      <Box margin="0 auto" maxW={1280} paddingX={[4, 4, 4, 8]} marginTop={8}>
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          marginX="auto"
          maxWidth={['auto', 'auto', 'auto', '820px']}
        >
          {step === 5 ? (
            renderCompleted()
          ) : (
            <>
              <Flex flexDirection="column" flexGrow={1} marginRight={[0, 0, 0, 12]}>
                {renderSteps()}
              </Flex>
              {renderDidYouKnow()}
            </>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default FacialRecognition;
