export const IMAGE_MARGIN = 5;

// Image Viewer
export const BG_VIEWER_WIDTH = 327;
export const MOBILE_BG_SLIDER_HEIGHT = 90;
export const VIEWER_BTN_HEIGHT = 45;
export const VIEWER_BTN_MARGIN = 10;

export const NEXT = 'next';
export const PREV = 'prev';

export type Direction = typeof NEXT | typeof PREV;

// Carousel
export const CARD_MARGIN = 10;
