import { Flex, Heading } from '@chakra-ui/layout';
import React from 'react';
import { useIntl } from 'react-intl';
import ModalCloseButton from '../../../../shared/components/ModalCloseButton';
import { CLOSE_MENU } from '../../../../shared/constants';

interface Props {
  toggleShowViewer(): void;
}

const SlideOutHeader = ({ toggleShowViewer }: Props) => {
  const intl = useIntl();
  return (
    <Flex
      align="center"
      borderBottomWidth="2px"
      borderColor="black"
      justify="space-between"
      marginTop="1"
    >
      <Heading
        color="white"
        borderBottomWidth="3px"
        borderColor="white"
        lineHeight="40px"
        marginX="10"
        size="xs"
      >
        {intl.formatMessage({
          id: 'slideOutHeader.Backgrounds',
          defaultMessage: 'Backgrounds',
        })}
      </Heading>
      <ModalCloseButton
        aria-label={CLOSE_MENU}
        fontSize="lg"
        onClick={toggleShowViewer}
        data-test="slide-out-header-close-button"
      />
    </Flex>
  );
};

export default SlideOutHeader;
