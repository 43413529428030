import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { CatalogProductNode } from 'iq-product-render';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ShopProductPrint } from '../../../../../../shop-api-client';
import { CartPrintProductReq } from '../../../../../../shop-api-client/models/Cart';
import { selectConfiguration } from '../../../../../redux/selectors/configurations.selectors';
import { selectCurrentVisitKey } from '../../../../../redux/selectors/visitor.selectors';
import {
  setEditNodeID,
  setShowMultiNodeModal,
} from '../../../../../redux/slices/configurations.slice';
import { useAppSelector } from '../../../../../redux/store';
import useIsMobile from '../../../../../shared/hooks/useIsMobile';
import ProductPreview from '../../../ProductPreview';

interface Props {
  editProduct: CartPrintProductReq;
  onDoubleClickNode?(node: CatalogProductNode): void;
  onLeave?(): void;
  shopProduct: ShopProductPrint;
}

const MultiNodeMissingImagesModal = ({
  editProduct,
  onDoubleClickNode,
  onLeave,
  shopProduct,
}: Props) => {
  const { showMultiNodeModal } = useAppSelector(selectConfiguration);
  const currentVisitKey = useAppSelector(selectCurrentVisitKey);
  const missingImagesCount = useMemo(
    () => editProduct.nodes.filter(n => n.type === 'image' && !n.imageInternalName).length,
    [editProduct],
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useIsMobile();

  const exitAnyways = intl.formatMessage({
    id: 'multiNodeMissingImagesModal.exitAnyways',
    defaultMessage: 'Exit Anyways',
  });

  const instructionsPlural = intl.formatMessage(
    {
      id: 'multiNodeMissingImagesModal.instructionsPlural',
      defaultMessage: 'You need to add images to {count} other photo slots before continuing.',
    },
    {
      count: missingImagesCount,
    },
  );

  const instructionsSingular = intl.formatMessage({
    id: 'multiNodeMissingImagesModal.instructionsSingular',
    defaultMessage: 'You need to add images to 1 other photo slot before continuing.',
  });

  const letsFixThem = intl.formatMessage({
    id: 'multiNodeMissingImagesModal.letsFixThem',
    defaultMessage: "Let's Fix them",
  });

  const missingImages = intl.formatMessage({
    id: 'multiNodeMissingImagesModal.missingImages', // TODO change this name
    defaultMessage: 'Missing Images',
  });

  const handleClose = () => {
    dispatch(setShowMultiNodeModal(false));
  };

  const handleFix = () => {
    const node = editProduct.nodes.find(node => node.type === 'image');
    if (node) {
      dispatch(setEditNodeID(node.catalogNodeID));
    }

    handleClose();
  };

  const handleLeave = () => {
    dispatch(setShowMultiNodeModal(false));
    if (onLeave) {
      onLeave();
    } else {
      history.push(`/${currentVisitKey}/shop/${shopProduct.categoryID}/product/${shopProduct.id}`);
    }
  };

  return (
    <Modal
      blockScrollOnMount
      isOpen={showMultiNodeModal}
      size={isMobile ? 'full' : '3xl'}
      onClose={handleClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingTop={10} paddingBottom={6}>
          <ModalCloseButton borderRadius="50%" />
          <Flex direction="column">
            <Heading size="lg" marginX="auto">
              {missingImages}
            </Heading>
          </Flex>
        </ModalHeader>
        <ModalBody marginX="auto">
          <Text textAlign="center">
            {missingImagesCount === 1 ? instructionsSingular : instructionsPlural}
          </Text>
          <Flex marginY="15px" justifyContent="center">
            <ProductPreview
              disableMiniPreview
              editProduct={editProduct}
              hidePreviewText
              isInteractive={false}
              onDoubleClickNode={onDoubleClickNode}
              shopProduct={shopProduct}
              unlockedNodes="image"
            />
          </Flex>
        </ModalBody>
        <ModalFooter paddingY={isMobile ? 4 : 10}>
          <Flex direction="row" marginX="auto" width={isMobile ? '100%' : '60%'}>
            <Button
              background="grey.1"
              flex={1}
              height="50px"
              marginRight={isMobile ? 1 : 4}
              onClick={handleLeave}
              variant="secondary"
            >
              {exitAnyways}
            </Button>
            <Button flex={2} height="50px" marginLeft={isMobile ? 1 : 6} onClick={handleFix}>
              {letsFixThem}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiNodeMissingImagesModal;
