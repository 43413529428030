import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props extends ButtonProps {
  editClick(): void;
}

const EditButton = ({ editClick, ...rest }: Props) => {
  const intl = useIntl();

  return (
    <Button
      onClick={editClick}
      alignItems="start"
      color="brand"
      fontSize="xs"
      height="50px"
      lineHeight="1.8"
      margin={0}
      variant="link"
      {...rest}
    >
      {intl.formatMessage({
        id: 'backgrounds.edit',
        defaultMessage: 'Edit',
      })}
    </Button>
  );
};

export default EditButton;
