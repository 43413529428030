import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { ImImages } from 'react-icons/im';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopBackground } from '../../../../../shop-api-client';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../../redux/selectors/background.selectors';
import { selectInteractions } from '../../../../redux/selectors/interactions.selectors';
import BackgroundSwatches from '../../../../shared/components/BackgroundSwatches';
import { Params } from '../../../../shared/types/router';
import { getNameWithoutExt } from '../../../../shared/utils';

interface Props {
  onSelectBackground(bg: ShopBackground): void;
  toggleShowViewer(): void;
}

const SidePanelBackgrounds = ({ onSelectBackground, toggleShowViewer }: Props) => {
  const { selectedBackground } = useSelector(selectInteractions);
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const orderedBackgrounds = useSelector(selectAllBackgrounds);

  const { backgroundID } = useParams<Params>();
  const intl = useIntl();

  return (
    <Box marginY="5">
      <Text
        color="white"
        data-test="side-panel-backgrounds-selected-background-text"
        fontSize="xs"
        margin="1"
      >
        {intl.formatMessage({
          id: 'sidePanelBackgrounds.selectedBackground',
          defaultMessage: 'Selected Background:',
        })}
        {'  '}
        <Box as="span" fontFamily="ITC Avant Garde Gothic Demi">
          {getNameWithoutExt(backgroundsMap[backgroundID]?.name)}
        </Box>
      </Text>
      <Box marginX={1}>
        <BackgroundSwatches
          backgrounds={orderedBackgrounds}
          onSelectBackground={onSelectBackground}
          selectedBackground={selectedBackground}
          selectedID={parseInt(backgroundID)}
          showText={false}
          size="45px"
          swatchMargin={1}
        />
      </Box>
      <Button
        color="grey.3"
        data-test="side-panel-backgrounds-viewer"
        leftIcon={<ImImages fontSize="20px" />}
        marginX="1"
        marginY="3"
        onClick={toggleShowViewer}
        variant="link"
      >
        {intl.formatMessage({
          id: 'sidePanelBackgrounds.backgroundViewer',
          defaultMessage: 'Background Viewer',
        })}
      </Button>
    </Box>
  );
};

export default SidePanelBackgrounds;
