import { Box, Flex, FlexProps, Text } from '@chakra-ui/layout';
import { noop } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ShopBackground } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectSelectedImage } from '../../../redux/selectors/interactions.selectors';
import { formatCurrency, getNameWithoutExt } from '../../utils';
import { ActiveImageOverlayProps } from '../ActiveImageOverlay';
import Swatch from './Swatch';

interface Props {
  backgrounds: ShopBackground[];
  color?: string;
  containerStyles?: FlexProps;
  onSelectBackground(bg: ShopBackground): void;
  overlayProps?: ActiveImageOverlayProps;
  selectedBackground?: ShopBackground | null;
  selectedID?: number;
  showPrice?: boolean;
  showText?: boolean;
  showThumbnail?: boolean;
  size?: string;
  swatchMargin?: string | number;
}

const BackgroundSwatches = ({
  backgrounds,
  color = 'black',
  containerStyles,
  onSelectBackground = noop,
  overlayProps,
  selectedBackground,
  selectedID,
  showPrice = false,
  showText = true,
  showThumbnail,
  size = '85px',
  swatchMargin = '5px',
}: Props) => {
  const selectedImage = useSelector(selectSelectedImage);
  const { currency } = useSelector(selectAccount);

  const handleSwatchClick = (background: ShopBackground, e: React.MouseEvent<HTMLDivElement>) => {
    onSelectBackground(background);
    e.stopPropagation();
  };

  return (
    <Flex color={color} wrap="wrap" {...containerStyles}>
      {backgrounds.map(bg => (
        <Flex direction="column" key={bg.id} margin={swatchMargin} position="relative">
          <Swatch
            onClick={e => handleSwatchClick(bg, e)}
            overlayProps={overlayProps}
            position="relative"
            showOverlay={selectedID ? selectedID === bg.id : selectedBackground?.id === bg.id}
            size={size}
            url={`url(${bg.sources.thumb}) no-repeat`}
            data-test={`background-swatches-${bg.name}`}
          />
          {showThumbnail && (
            <Swatch
              onClick={e => handleSwatchClick(bg, e)}
              position="absolute"
              size={size}
              url={`url(${selectedImage?.sources.thumb}) no-repeat`}
              data-test="background-swatches-with-images"
            />
          )}
          {showText && (
            <Box fontSize="xs" textAlign="center" width={size}>
              <Text whiteSpace="normal" noOfLines={1}>
                {getNameWithoutExt(bg.name)}
              </Text>
              {showPrice && !!bg.price && <Text>{formatCurrency(bg.price, currency)}</Text>}
            </Box>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default BackgroundSwatches;
