import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShopPackage, ShopProduct } from '../../../../../../shop-api-client';
import {
  CartPrintProduct,
  CartProductReq,
  CreateCartPackageReq,
} from '../../../../../../shop-api-client/models/Cart';
import { selectActiveStep } from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import ProductImage from '../../../ProductImage';
import ProductPreview from '../../../ProductPreview';
import { getFirstAssignedImage } from '../../../utils';
import { MAX_PREVIEW_SIZE, TOOLBAR_HEIGHT } from '../../constants';
import { getImageNodesStep, getTextNodesStep } from '../../utils';

interface Props {
  editItem?: CreateCartPackageReq | CartProductReq;
  shopItem: ShopPackage | ShopProduct;
}

const ConfigurationPreview = ({ editItem, shopItem }: Props) => {
  const { isPreOrder } = useSelector(selectGallery);
  const activeStep = useSelector(selectActiveStep);

  const [maxPreviewHeight, setMaxPreviewHeight] = useState(0);
  const [maxPreviewWidth, setMaxPreviewWidth] = useState(0);
  const [previewRef, setPreviewRef] = useState<HTMLDivElement | null>(null);

  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });

  useEffect(() => {
    const handleResize = () => {
      if (previewRef) {
        setMaxPreviewWidth(previewRef.clientWidth);
        setMaxPreviewHeight(previewRef.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [previewRef]);

  if (!isPreOrder && shopItem.type === 'product' && editItem) {
    let unlockedNodes: 'text' | 'image' | 'none' | undefined = undefined;
    if (activeStep === getImageNodesStep(editItem.id!)) {
      unlockedNodes = 'image';
    } else if (activeStep === getTextNodesStep(editItem.id!)) {
      unlockedNodes = 'text';
    }

    return (
      <Flex
        justifyContent="center"
        width="100%"
        height={{ base: 'fit-content', md: '100%' }}
        ref={flexRef => setPreviewRef(flexRef)}
      >
        <ProductPreview
          key={shopItem.id}
          disableMiniPreview
          editProduct={editItem as CartPrintProduct}
          isInteractive={!!unlockedNodes}
          maxPreviewHeight={isMobile ? undefined : maxPreviewHeight * 0.9 - TOOLBAR_HEIGHT}
          maxPreviewWidth={maxPreviewWidth * 0.9}
          shopProduct={shopItem}
          unlockedNodes={unlockedNodes}
        />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" width="100%">
      <ProductImage
        fallbackFontSize="xl"
        fallbackIconSize="100px"
        height="100%"
        image={getFirstAssignedImage(shopItem)}
        maxHeight={MAX_PREVIEW_SIZE}
        maxWidth={MAX_PREVIEW_SIZE}
        product={shopItem}
        isSquare
      />
    </Flex>
  );
};

export default ConfigurationPreview;
