import { Box, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplyDiscount } from '../../../../shop-api-client/models/Discounts';
import { selectCartSlice } from '../../../redux/selectors/cart.selectors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { applyDiscount, removeDiscount } from '../../../redux/thunks/checkout.thunks';
import { Params } from '../../../shared/types/router';
import PromoCode from './PromoCode';
import QuickApply from './QuickApply';

const Discounts = () => {
  const { discountCode: appliedDiscountCode, discountID: appliedDiscountID } = useAppSelector(
    state => state.checkout,
  );
  const { cartDiscounts: discountsMap } = useSelector(selectCartSlice);

  const [appliedDiscount, setAppliedDiscount] = useState<ApplyDiscount>({
    discountCode: null,
    discountID: null,
  });
  const [error, setError] = useState<ApplyDiscount | undefined>();

  const { checkoutID } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const availableOffers = intl.formatMessage({
    id: 'discounts.availableOffers',
    defaultMessage: 'Available Offers',
  });

  const limitOnePerOrder = intl.formatMessage({
    id: 'discounts.limitOnePerOrder',
    defaultMessage: 'Limit 1 per order.',
  });

  // set applied discount state based on whether it matches a quick apply discount or not
  useEffect(() => {
    if (!Object.keys(discountsMap)) {
      return;
    }

    if (!appliedDiscountID && !appliedDiscountCode) {
      setAppliedDiscount({ discountCode: null, discountID: null });
      return;
    }

    // Check if ID is a quick apply discount
    const isQuickApply = Object.values(discountsMap).some(discounts =>
      discounts.some(discount => appliedDiscountID === discount.id),
    );

    if (isQuickApply) {
      setAppliedDiscount({ discountID: appliedDiscountID });
    } else {
      setAppliedDiscount({ discountCode: appliedDiscountCode });
    }
  }, [appliedDiscountCode, appliedDiscountID, discountsMap]);

  const handleApplyDiscount = async (discount: ApplyDiscount) => {
    const { valid } = await dispatch(applyDiscount(checkoutID!, discount));
    if (!valid) {
      setError(discount);
    }
  };

  const handleRemoveDiscount = async () => {
    await dispatch(removeDiscount(checkoutID!));
  };

  if (!Object.keys(discountsMap)) {
    return null;
  }

  return (
    <Box marginBottom={5} marginTop={2.5}>
      {Object.keys(discountsMap).length && (
        <>
          <Heading fontSize="md" marginBottom={2}>
            {availableOffers}
          </Heading>

          <Text fontSize="13px" fontStyle="italic">
            {limitOnePerOrder}
          </Text>
        </>
      )}

      {Object.entries(discountsMap).map(([visitKey, cartDiscounts]) =>
        cartDiscounts.map(discount => (
          <QuickApply
            key={discount.id}
            appliedDiscount={appliedDiscount}
            cartVisitKey={visitKey}
            discount={discount}
            error={error}
            handleApplyDiscount={handleApplyDiscount}
            handleRemoveDiscount={handleRemoveDiscount}
          />
        )),
      )}

      <PromoCode
        appliedDiscount={appliedDiscount}
        error={error}
        handleApplyDiscount={handleApplyDiscount}
        handleRemoveDiscount={handleRemoveDiscount}
      />
    </Box>
  );
};

export default Discounts;
