import { Button } from '@chakra-ui/button';
import React from 'react';

interface Props {
  'data-test'?: string;
  disableFocus?: boolean;
  isActive: boolean;
  label: string;
  onClick(): void;
  styleOverrides?: Record<string, string | number>;
}

const Tab = ({
  'data-test': dataTest,
  disableFocus,
  isActive,
  label,
  onClick,
  styleOverrides,
}: Props) => (
  <Button
    _focusVisible={disableFocus ? { '&:focus': 'none' } : undefined}
    borderBottomColor={isActive ? 'brand' : 'transparent'}
    borderBottomWidth="4px"
    borderRadius={0}
    color={isActive ? 'brand' : 'inherit'}
    data-test={dataTest}
    fontFamily={isActive ? 'ITC Avant Garde Gothic Demi' : 'inherit'}
    fontSize={{ base: 'sm', sm: 'md' }}
    marginX={{ base: 5, sm: 10 }}
    marginY={{ base: 3, sm: 5 }}
    onClick={onClick}
    paddingX={0.1}
    position="relative"
    transition={isActive ? 'ease-in border-bottom-color .3s' : 'none'}
    variant="tab"
    {...styleOverrides}
  >
    {label}
  </Button>
);

export default Tab;
