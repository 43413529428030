import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiX } from 'react-icons/fi';

interface Props {
  label: string;
  onClick(): void;
}

const Pill = ({ label, onClick }: Props) => (
  <Button marginRight="10px" marginY="5px" onClick={onClick} variant="pill">
    {label}
    <Icon as={FiX} color="grey.7" fontSize="18px" marginLeft="4px" marginBottom="2px" />
  </Button>
);

export default Pill;
