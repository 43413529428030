import { Box, BoxProps, chakra, Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ShopImage } from '../../../../../shop-api-client';
import { selectBackgroundsMap } from '../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../redux/store';
import DividerWithLabel from '../../../../shared/components/DividerWithLabel';
import { UniqueImage } from '../../../../shared/types/image';
import { getNameWithoutExt, getUniqueImageKey } from '../../../../shared/utils';

interface Props extends BoxProps {
  imageSet: (UniqueImage | ShopImage)[];
  visitKey: string;
}

const DEFAULT_LIST_LENGTH = 8;

/**
 * Renders an Unordered List of images used by the product based on item type
 */
const CartItemImageList = ({ visitKey, imageSet, ...boxProps }: Props) => {
  const { images } = useAppSelector(state => selectGallery(state, visitKey));
  const backgroundsMap = useAppSelector(state => selectBackgroundsMap(state, visitKey));
  const [showAll, setShowAll] = useState(false);

  const intl = useIntl();

  const onBackground = intl.formatMessage({
    id: 'cartItemImageList.onBackground',
    defaultMessage: 'on',
  });
  const showAllLabel = intl.formatMessage(
    {
      id: 'cartItemImageList.showAll',
      defaultMessage: 'Show {amount}',
    },
    { amount: showAll ? 'Less' : 'All' },
  );
  const selectedPhotos = intl.formatMessage(
    {
      id: 'cartItemImageList.selectedPhotos',
      defaultMessage: 'Selected Photo{plural}',
    },
    {
      plural: imageSet.length > 1 ? 's' : '',
    },
  );

  const toggleListLength = () => setShowAll(!showAll);

  const renderBackgroundText = (image: UniqueImage) => {
    if (!image.backgroundID) {
      return null;
    }
    const { name } = backgroundsMap[image.backgroundID];
    return (
      <>
        <chakra.span marginX="0.5ch" color="grey.5" fontStyle="italic">
          {onBackground}
        </chakra.span>
        {getNameWithoutExt(name)}
      </>
    );
  };

  const renderListItem = (item: UniqueImage | ShopImage) => {
    const uniqueKey =
      'backgroundID' in item ? getUniqueImageKey(item.image, item.backgroundID) : item.internalName;
    const nameWithoutExt =
      'image' in item
        ? getNameWithoutExt(images[item.image]?.displayName)
        : getNameWithoutExt(item.displayName);

    return (
      <ListItem
        data-test="cart-item-image-name-text"
        fontSize={{ base: 'xs', md: 'sm' }}
        key={uniqueKey}
      >
        {nameWithoutExt}
        {'backgroundID' in item && renderBackgroundText(item)}
      </ListItem>
    );
  };

  return (
    <Box marginBottom={{ base: 0.5, md: 2 }} onClick={toggleListLength} {...boxProps}>
      <Box marginY={1}>
        <Heading fontSize={{ base: 'xs', md: 'sm' }} marginBottom={0.5}>
          {selectedPhotos}
        </Heading>
        <UnorderedList marginLeft={{ base: 5, md: 6 }} marginBottom={1.5}>
          {imageSet
            .slice(0, showAll ? imageSet.length : DEFAULT_LIST_LENGTH)
            .map(item => renderListItem(item))}
        </UnorderedList>
      </Box>
      {imageSet.length > DEFAULT_LIST_LENGTH && (
        <DividerWithLabel buttonMargin={4} label={showAllLabel} />
      )}
    </Box>
  );
};

export default CartItemImageList;
