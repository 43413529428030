import { CatalogProductNode } from 'iq-product-render';
import { useEffect, useState } from 'react';
import webFont from 'webfontloader';

interface FontConfig {
  fontFace: string;
  variants: string[];
}

const useProductFontLoader = (): [boolean, (nodes: CatalogProductNode[]) => void] => {
  const [areFontsLoaded, setAreFontsLoaded] = useState(false);
  const [fontCache, setFontCache] = useState<{ [key: string]: boolean }>({});
  const [isUnmounted, setIsUnmounted] = useState(false);

  useEffect(() => {
    setIsUnmounted(false);
    return () => {
      setIsUnmounted(true);
    };
  }, []);

  const handleSetIsActive = () => {
    if (isUnmounted) {
      return;
    }
    setAreFontsLoaded(true);
  };

  const loadFonts = (fonts: FontConfig[]) => {
    const filteredFonts = fonts.filter(font => !fontCache[font.fontFace]);
    const fontConfigs = filteredFonts.reduce<string[]>((result, config) => {
      let fontStr = config.fontFace || '';
      if (config.variants) {
        fontStr = `${fontStr}:${config.variants.join(',')}`;
      }
      if (fontStr) {
        result.push(fontStr);
      }
      return result;
    }, []);
    const fontsMap: { [key: string]: boolean } = {};

    if (!fontConfigs.length) {
      // If there are no new fonts to load, yet `areFontsLoaded` is false, set to true.
      // This accounts for a scenario where the fonts were previously loaded into cache,
      // but the component where used unmounted before the loaded state could be updated
      if (!areFontsLoaded) {
        handleSetIsActive();
      }
      return;
    }
    webFont.load({
      active: handleSetIsActive,
      google: { families: fontConfigs },
    });

    // Set each font face as a key in fontsMap
    filteredFonts.forEach(font => {
      fontsMap[font.fontFace] = true;
    });

    // Update fontCache state to include the additional fonts
    setFontCache(prev => ({
      ...prev,
      ...fontsMap,
    }));
  };

  const setFonts = (nodes: CatalogProductNode[]) => {
    const fontCache = nodes.reduce<FontConfig[]>((result, node) => {
      if (node.type !== 'text' || !node.fontFace) {
        return result;
      }

      result.push({
        fontFace: node.fontFace,
        variants: node.fontStyle ? [node.fontStyle] : [],
      });

      return result;
    }, []);

    loadFonts(fontCache);
  };

  return [areFontsLoaded, setFonts];
};

export default useProductFontLoader;
