import { createSelector } from '@reduxjs/toolkit';
import { PriceSheetOption, ShopPackage, ShopProduct, StepKey } from '../../../shop-api-client';
import { isOnlyDigitalDownload } from '../../features/Carts/utils';
import {
  ADDITIONAL_INFORMATION,
  BILLING_ADDRESS,
  CHOOSE_BACKGROUND,
  IMAGE_ADD_ONS,
  ORDER_OPTIONS,
  PAYMENT,
  SHIPPING_ADDRESS,
  SHIPPING_AND_PICKUP_OPTIONS,
} from '../../features/Checkout/constants';
import { passIncludeAllImageOptionCheck } from '../../features/Checkout/utils';
import { GalleryMap } from '../slices/gallery.slice';
import { RootState } from '../store';
import { selectVisitKeytoPriceSheetIDMap, selectPriceSheet } from './priceSheet.selectors';
import { CatalogProductNode } from 'iq-product-render';
import { hasImageNodesWithDisabledBg } from '../../features/Products/utils';


interface StepInterface {
  stepKey: StepKey;
  stepTitle: string;
}

export function selectCheckoutSlice(state: RootState) {
  return state.checkout;
}

export function selectCheckoutVisitKeys(state: RootState) {
  return state.checkout.checkoutVisitKeys;
}

export function selectCheckoutData(state: RootState) {
  return state.checkout.formData;
}

// Select a subset of galleryMap for the galleries currently being checked out
export const selectCheckoutGalleriesMap = createSelector(
  (state: RootState) => state.checkout.checkoutVisitKeys,
  (state: RootState) => state.gallery.galleryMap,
  (checkoutVisitKeys, galleryMap) => {
    if (!checkoutVisitKeys) {
      return {};
    }
    return checkoutVisitKeys.reduce<GalleryMap>((res, visitKey) => {
      res[galleryMap[visitKey].galleryID] = galleryMap[visitKey];

      return res;
    }, {});
  },
);

// Determines what checkout steps need to be displayed based on variables
export const selectCheckoutSteps = createSelector(
  (state: RootState) => state.checkout,
  (state: RootState) => state.gallery.galleryMap,
  (state: RootState) => state.gallery.priceSheetMap,
  (state: RootState) => state.checkout.formData,
  (state: RootState) => state.cart.cartMap,
  selectPriceSheet,
  (
    { checkoutVisitKeys, editReqImageOptionsMap },
    galleryMap,
    priceSheetMap,
    checkoutData,
    cartMap,
    { productNodeMap },
  ) => {
    if (!checkoutVisitKeys) {
      return [];
    }
    const { shippingType } = checkoutData;

    const {
      showBgOptions,
      showCustomDataSpec,
      showOrderOptions,
      showRequiredImageOptions,
      showShipmentOptions,
      showShippingAddress,
      useStripeTax,
    } = checkoutVisitKeys!.reduce<{
      showBgOptions: boolean;
      showCustomDataSpec: boolean;
      showOrderOptions: boolean;
      showRequiredImageOptions: boolean;
      showShipmentOptions: boolean;
      showShippingAddress: boolean;
      useStripeTax: boolean;
    }>(
      (res, visitKey) => {
        // Get Gallery Info
        const {
          customDataSpecID,
          isGreenScreen,
          priceSheetID,
          isPreOrder,
          settings: { shipmentType, useStripeTax: useStripeTaxSetting },
        } = galleryMap[visitKey];
        const { cartPackages, cartProducts } = cartMap[visitKey];
        const { backgroundSets, orderOptionMap, preOrderBackgroundSelectionType, products } =
          priceSheetMap[priceSheetID];

        const cartItems = [...cartPackages, ...cartProducts];
        const canChooseImageOptions = passIncludeAllImageOptionCheck(cartItems, products);
        const hasRequiredImageOptions = Object.values(editReqImageOptionsMap).length;
        const isOnlyDDs = isOnlyDigitalDownload(cartItems);

        let imageNodes: CatalogProductNode[] = [];
        const extractImageNodesFromProduct = (product: ShopProduct | ShopPackage) => {
          if (product.type === 'product') {
            const productNodes = productNodeMap[product.catalogProductID];
            const filteredImageNodes = productNodes?.filter(n => n.type === 'image');
            if (filteredImageNodes) {
              imageNodes = imageNodes.concat(filteredImageNodes);
            }
          }
        };
        const extractImageNodes = (subItemIDs: number[]) => {
          subItemIDs.forEach(subItemID => {
            const shopSubItem = products[subItemID];
            if (shopSubItem) {
              extractImageNodesFromProduct(shopSubItem);
              if (shopSubItem.type === 'package') {
                shopSubItem.availableProducts.forEach(availableProduct => {
                  extractImageNodesFromProduct(availableProduct);
                });
              }
            }
          });
        };
        if (cartProducts && cartProducts.length > 0) {
          const productPriceSheetItemIDs = cartProducts.map(p => p.priceSheetItemID);
          extractImageNodes(productPriceSheetItemIDs);
        }
        if (cartPackages && cartPackages.length > 0) {
          const packagePriceSheetItemIDs = cartPackages.map(p => p.priceSheetItemID);
          extractImageNodes(packagePriceSheetItemIDs);
        }
        const skipBgSelectionPerOrder = hasImageNodesWithDisabledBg(imageNodes);

        // Selectively assign values if they're needed to display a checkout step for each gallery
        // If there is a customdataspecID, add a custom form section
        if (customDataSpecID) {
          res.showCustomDataSpec = true;
        }
        // Show BG selection for GS galleries with a pricesheet "per order" background setting
        if (
          isPreOrder &&
          preOrderBackgroundSelectionType === 'perOrder' &&
          isGreenScreen &&
          backgroundSets.length &&
          !skipBgSelectionPerOrder
        ) {
          res.showBgOptions = true;
        }
        // if required image options exist on a gallery
        if (canChooseImageOptions && hasRequiredImageOptions) {
          res.showRequiredImageOptions = true;
        }
        // If shipment type is choice and cart has non-DD items, add section to select direct/pickup
        if (shipmentType === 'choice' && !isOnlyDDs) {
          res.showShipmentOptions = true;
        }
        // If the shipment type is always direct, or if the user selected it, render shipping form:
        if ((shipmentType === 'direct' || shippingType === 'direct') && !isOnlyDDs) {
          res.showShippingAddress = true;
        }

        // If there are additional order options, add order options step
        if (Object.keys(orderOptionMap).length > 0) {
          res.showOrderOptions = true;
        }

        // If any cart template has stripe tax enabled, the whole checkout uses it:
        if (useStripeTaxSetting) {
          res.useStripeTax = useStripeTaxSetting;
        }

        return res;
      },
      {
        showCustomDataSpec: false,
        showBgOptions: false,
        showShipmentOptions: false,
        showShippingAddress: false,
        showOrderOptions: false,
        showRequiredImageOptions: false,
        useStripeTax: false,
      },
    );

    const steps: StepInterface[] = [];

    if (showCustomDataSpec) {
      steps.push({
        stepKey: 'customDataSpec',
        stepTitle: ADDITIONAL_INFORMATION,
      });
    }

    if (showBgOptions) {
      steps.push({
        stepKey: 'backgroundOptions',
        stepTitle: CHOOSE_BACKGROUND,
      });
    }

    if (showRequiredImageOptions) {
      steps.push({
        stepKey: 'requiredImageOptions',
        stepTitle: IMAGE_ADD_ONS,
      });
    }

    if (showShipmentOptions) {
      steps.push({
        stepKey: 'shippingType',
        stepTitle: SHIPPING_AND_PICKUP_OPTIONS,
      });
    }

    if (showShippingAddress) {
      steps.push({
        stepKey: 'shippingAddress',
        stepTitle: SHIPPING_ADDRESS,
      });
    }

    if (showOrderOptions) {
      steps.push({
        stepKey: 'orderOptions',
        stepTitle: ORDER_OPTIONS,
      });
    }

    if (useStripeTax && !showShippingAddress) {
      steps.push({
        stepKey: 'billingAddress',
        stepTitle: BILLING_ADDRESS,
      });
    }

    // Always add payment step last
    steps.push({
      stepKey: 'paymentOptions',
      stepTitle: PAYMENT,
    });

    return steps;
  },
);

// Select which checkout step is currently active based on which steps are completed and most recent activity
export const selectCheckoutActiveStep = createSelector(
  selectCheckoutSteps,
  (state: RootState) => state.checkout,
  (steps, { checkoutStepCompletion, checkoutActiveStep }) =>
    // The active step serves as an override, otherwise we find the first incomplete step:
    steps.find(step =>
      checkoutActiveStep
        ? step.stepKey === checkoutActiveStep
        : !checkoutStepCompletion[step.stepKey],
    ),
);

/*
 *  Returns an object containing all unique Order Options from all price sheets associated with
 * the carts for the current checkout. The options are split based on their `requirementType`
 * into their respective arrays. A third property, `optionIDToVisitKey` associates each option
 * to the visit that used the price sheet from which the option exists. This hashmap is used to make
 * sure that the option is added to the correct cart when the visitor makes a selection
 *
 * NOTE: Because multiple visits can use the same price sheet, and each Order Option should
 * only be presented once at checkout, the Option is skipped if it is found on subsequent `visitKey`s
 */
export const selectCheckoutOrderOptions = createSelector(
  (state: RootState) => state.checkout.checkoutVisitKeys,
  (state: RootState) => state.visitor.currentVisitKey,
  (state: RootState) => state.gallery.priceSheetMap,
  selectVisitKeytoPriceSheetIDMap,
  (checkoutVisitKeys, currentVisitKey, priceSheetMap, visitKeytoPriceSheetIDMap) => {
    return (checkoutVisitKeys || [currentVisitKey!]).reduce(
      (result, key) => {
        const { orderOptionMap } = priceSheetMap[visitKeytoPriceSheetIDMap[key]];

        for (const option of Object.values(orderOptionMap)) {
          if (result.optionIDToVisitKey[option.id]) {
            continue;
          }

          if (option.requirementType === 'required') {
            result.requiredOptions.push(option);
          } else {
            result.optionalOptions.push(option);
          }
          result.optionIDToVisitKey[option.id] = key;
        }

        return result;
      },
      {
        optionalOptions: [] as PriceSheetOption[],
        requiredOptions: [] as PriceSheetOption[],
        optionIDToVisitKey: {} as Record<string, string>,
      },
    );
  },
);
