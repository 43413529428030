import { Box, Center, Flex, Spinner, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from '../../app/features/Footer';
import FindGalleryModal from '../../app/features/MultiGallery/FindGallery/FindGalleryModal';
import Navigation from '../../app/features/Navigation';
import {
  CATEGORIES_SUB_MENU_HEIGHT,
  DISCOUNTS_BANNER_HEIGHT,
  FULL_ORDER_NAV_HEIGHT,
  MOBILE_ACTION_BAR_HEIGHT,
  PREORDER_NAV_HEIGHT,
} from '../../app/features/Navigation/constants';
import { selectGallery } from '../../app/redux/selectors/gallery.selectors';
import { selectInteractions } from '../../app/redux/selectors/interactions.selectors';
import { toggleShowFindGalleryModal } from '../../app/redux/slices/interactions.slice';
import { useAppDispatch } from '../../app/redux/store';
import ScrollToTop from '../../app/shared/components/ScrollToTop';
import Carts from '../features/Carts';
import Checkout from '../features/Checkout';
import CheckoutConfirmation from '../features/Checkout/CheckoutConfirmation';
import MultiGallery from '../features/MultiGallery';
import MyPhotos from '../features/MyPhotos';
import Products from '../features/Products';
import Configuration from '../features/Products/Configuration';
import ProductDetails from '../features/Products/ProductDetails';
import Yearbook from '../features/Yearbook';
import YearbookSelection from '../features/Yearbook/YearbookSelection';
import { selectAccount } from '../redux/selectors/account.selectors';
import { selectCurrentVisitKey, selectVisitor } from '../redux/selectors/visitor.selectors';
import { setVisitKey } from '../redux/slices/visitor.slice';
import ProtectedRoute from '../shared/components/ProtectedRoute';
import { Params } from '../shared/types/router';
import { getSecurePath } from '../shared/utils';

export const MOBILE_NAV_HEIGHT = DISCOUNTS_BANNER_HEIGHT + MOBILE_ACTION_BAR_HEIGHT;

const ProtectedApp = () => {
  const { key } = useParams<Params>();

  const { isLoggedIn } = useSelector(selectVisitor);
  const { analyticsID } = useSelector(selectAccount);
  const { isLoading, isPreOrder, title, type } = useSelector(selectGallery);
  const { showFindGalleryModal, showNav } = useSelector(selectInteractions);
  const currentVisitKey = useSelector(selectCurrentVisitKey);

  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const isShopProducts = pathname.includes('/shop/');

  useEffect(() => {
    if (analyticsID) {
      /* eslint-disable */
      //@ts-ignore
      gtag('config', analyticsID);
      /* eslint-enable */
    }
  }, [analyticsID]);

  useEffect(() => {
    if (!isLoggedIn) {
      const prevPath = getSecurePath(key, pathname, isPreOrder, type === 'standard');

      history.push({
        pathname: `/${key}`,
        search,
        state: { prevPath },
      });
    }
  }, [history, isLoggedIn, isPreOrder, key, pathname, search, type]);

  useEffect(() => {
    document.title = title;
    if (currentVisitKey === key) {
      return;
    }
    dispatch(setVisitKey(key));
  }, [dispatch, key, currentVisitKey, title]);

  if (isLoading) {
    return (
      <Center width="100%" flexDirection="row" flex={1}>
        <Spinner />
      </Center>
    );
  }

  const getNavHeight = () => {
    if (!showNav) {
      return 0;
    }

    if (isMobile) {
      if (isShopProducts) {
        return MOBILE_NAV_HEIGHT + CATEGORIES_SUB_MENU_HEIGHT;
      }
      return MOBILE_NAV_HEIGHT;
    }
    return isPreOrder ? PREORDER_NAV_HEIGHT : FULL_ORDER_NAV_HEIGHT;
  };

  const handleModalOnClose = () => dispatch(toggleShowFindGalleryModal(false));

  return (
    <Box minHeight="100vh">
      <Navigation />
      {showFindGalleryModal && (
        <FindGalleryModal isOpen={showFindGalleryModal} onClose={handleModalOnClose} />
      )}

      <Flex
        direction="column"
        minHeight={`calc(100vh - ${getNavHeight()}px)`}
        marginTop={showFindGalleryModal ? '66px' : undefined}
      >
        <Flex direction="column" flex="1">
          <ScrollToTop>
            <Switch>
              <ProtectedRoute path="/:key/carts" component={Carts} />
              <ProtectedRoute path="/:key/galleries" component={MultiGallery} />
              <ProtectedRoute
                path="/:key/checkout/:checkoutID/confirmation"
                component={CheckoutConfirmation}
              />
              <ProtectedRoute path="/:key/checkout/:checkoutID" component={Checkout} />
              <ProtectedRoute path="/:key/photos" component={MyPhotos} />
              <ProtectedRoute path={`/:key/configure`} component={Configuration} />
              <ProtectedRoute
                path={`/:key/shop/:categoryID/product/:productID`}
                component={ProductDetails}
              />
              <ProtectedRoute path="/:key/shop" component={Products} />
              <ProtectedRoute path="/:key/yearbook/:poseType" component={YearbookSelection} />
              <ProtectedRoute path="/:key/yearbook" component={Yearbook} />
            </Switch>
          </ScrollToTop>
        </Flex>
        <Footer />
      </Flex>
    </Box>
  );
};

export default ProtectedApp;
