import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ShopProduct } from '../../../../../../../../shop-api-client';
import NumberInput from '../../../../../../../shared/components/NumberInput';
import { pluralize } from '../../../../../../../shared/utils';
import DisplayImage from '../../../../shared/BuildYourOwn/DisplayImage';

interface Props {
  includedCount: number;
  quantity: number;
  onInputChange(item: ShopProduct, isIncrement: boolean): void;
  remainingBalance: number;
  shopItem: ShopProduct;
  unitLabel: string;
}

const AvailableItem = ({
  includedCount,
  onInputChange,
  quantity,
  remainingBalance,
  shopItem,
  unitLabel,
}: Props) => {
  const intl = useIntl();
  const { price } = shopItem;
  const isIncluded = price === 0;
  // Max is found by adding the current quantity to the qty that may still be added
  const max = quantity + Math.floor(remainingBalance / price);

  return (
    <Flex
      data-test={`${shopItem.name}-container`}
      key={shopItem.id}
      borderColor="grey.2"
      borderBottomWidth={{ base: '1px', md: '0px' }}
      direction={{ base: 'row', md: 'column' }}
      marginBottom={{ base: '10px', md: '40px' }}
      marginRight={{ base: undefined, md: '40px' }}
      minHeight={{ base: 'auto', md: '300px' }}
      paddingY={2}
      width={{ base: '100%', md: '150px' }}
    >
      <DisplayImage minWidth="65px" src={shopItem.image} product={shopItem} />
      <Box flex={{ base: 1, md: undefined }} paddingY={2} paddingLeft={{ base: 5, md: 0 }}>
        {includedCount > 0 && (
          <Box
            backgroundColor="black"
            borderRadius={12}
            color="white"
            fontSize="xs"
            padding="2px"
            width="80px"
          >
            <Text textAlign="center">
              {intl.formatMessage(
                {
                  id: 'byopSelectionStep.includedCount',
                  defaultMessage: '{count} included',
                },
                { count: includedCount },
              )}
            </Text>
          </Box>
        )}
        <Text fontFamily="ITC Avant Garde Gothic Demi" fontSize="md" noOfLines={3}>
          {shopItem.name}
        </Text>
        {!isIncluded && (
          <Text color="brand" fontSize="xs">
            {intl.formatMessage(
              {
                id: 'availableItem.itemPrice',
                defaultMessage: '{price} {units}',
              },
              {
                price: price,
                units: pluralize(price, unitLabel, false),
              },
            )}
          </Text>
        )}
      </Box>
      <NumberInput
        alignSelf={{ base: 'center', md: undefined }}
        decreaseButtonProps={{
          backgroundColor: quantity > 0 ? 'error' : undefined,
          color: quantity > 0 ? 'white' : undefined,
        }}
        defaultValue={0}
        max={max}
        min={0}
        onValueChange={(val: number) => onInputChange(shopItem, val > quantity)}
        overrideValue={isIncluded ? 0 : quantity}
      />
    </Flex>
  );
};

export default AvailableItem;
