import { Center, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { GalleryLandingData } from '../../../../shop-api-client';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { useAppDispatch } from '../../../redux/store';
import { getGalleryLanding } from '../../../redux/thunks/gallery.thunks';
import { LocationWithState } from '../../../shared/types/router';
import LoginForm from '../../Login/LoginForm';

interface Props {
  nextVisitKey: string;
  handleBack?(): void;
}

// Multigallery login wrapper that fetches landing data for a gallery when coming from another gallery
// Similar to Login component but requires a nextKey prop to be passed in instead of using params
const MultiGalleryLogin = ({ nextVisitKey, handleBack }: Props) => {
  const { galleryID } = useSelector(selectGallery);

  const [landingData, setLandingData] = useState<GalleryLandingData>();
  const [unavailableMessage, setUnavailableMessage] = useState('');

  const { search, state } = useLocation<LocationWithState>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  // Call api to retrieve gallery landing data - does not save to redux
  useEffect(() => {
    const init = async () => {
      const { valid, payload } = await dispatch(getGalleryLanding(nextVisitKey));

      if (!valid || !payload) {
        history.replace({
          pathname: state?.prevPath,
          search: search,
        });
        return;
      }

      setLandingData(payload);
    };

    init();
  }, [dispatch, history, nextVisitKey, search, state, galleryID]);

  if (!landingData) {
    return (
      <Center minHeight="385px" minWidth="484px">
        <Spinner />
      </Center>
    );
  }

  // uses passed in prop as the visitkey to login to with nextKey from params as fallback
  return (
    <LoginForm
      galleryKey={nextVisitKey}
      handleBack={handleBack}
      landingData={landingData}
      setUnavailableMessage={setUnavailableMessage}
      unavailableMessage={unavailableMessage}
      isMultiGalleryView
    />
  );
};

export default MultiGalleryLogin;
