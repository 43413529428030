import { Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import NavigationTab, { NavigationTabProps } from '../../NavigationTab';

interface Props extends NavigationTabProps {
  onNavClick(label: string, path: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  showArrow?: boolean;
}

/**
 * `MobileNavLink` wraps the `NavigationTab` to apply additional styling for, and around, the
 * tab for use in the `NavigationMenu` on mobile devices
 */
const MobileNavLink = ({ label, onNavClick, showArrow, path, ...rest }: Props) => (
  <Flex
    alignItems="center"
    background="grey.1"
    borderRadius="8px"
    display="flex"
    fontWeight="bold"
    height="70px"
    marginX="2"
    marginY="3"
    onClick={e => onNavClick(label, path, e)}
    paddingTop="8px"
    position="relative"
  >
    <NavigationTab label={label} path={path} {...rest} />
    {showArrow && (
      <Flex align="center" height="70px" position="absolute" right="30px" top="0" width="60px">
        <Icon as={HiOutlineArrowRight} fontSize="25px" />
      </Flex>
    )}
  </Flex>
);

export default MobileNavLink;
