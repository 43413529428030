import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ShopCategory } from '../../../../../shop-api-client';
import { selectCartMap } from '../../../../redux/selectors/cart.selectors';
import { selectGalleryMap } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheetMap } from '../../../../redux/selectors/priceSheet.selectors';
import CartsErrorModal from '../../../../shared/components/CartsErrorModal';
import { SHOP_CATEGORY } from '../../../../shared/constants/labels.constants';
import { Params } from '../../../../shared/types/router';
import { getCartItemsFromCategory } from '../../utils';

interface Props {
  errorVisitKeys: string[];
  isOpen: boolean;
  onClose(close: boolean): void;
}

interface CategoryWithVisitKey extends ShopCategory {
  visitKey: string;
}

const RequiredPurchaseModal = ({ errorVisitKeys, isOpen, onClose }: Props) => {
  const cartMap = useSelector(selectCartMap);
  const galleryMap = useSelector(selectGalleryMap);
  const priceSheetMap = useSelector(selectPriceSheetMap);

  const { key } = useParams<Params>();
  const history = useHistory();
  const intl = useIntl();

  const categoriesWithVisitKey = errorVisitKeys.reduce<CategoryWithVisitKey[]>(
    (result, visitKey) => {
      const { priceSheetID } = galleryMap[visitKey];
      const { categories, productCategoryMap } = priceSheetMap[priceSheetID];

      for (const cat of Object.values(categories)) {
        const isRequired = ['atLeastOne', 'singleRequired'].includes(cat.selectionType);
        if (
          isRequired &&
          !getCartItemsFromCategory(cartMap[visitKey], productCategoryMap[cat.id]).length
        ) {
          result.push({
            ...cat,
            visitKey,
          });
        }
      }
      return result;
    },
    [],
  );
  const currentCartOnly = errorVisitKeys.length === 1 && errorVisitKeys[0] === key;

  const atLeastOne = intl.formatMessage({
    id: 'requiredPurchaseModal.atLeastOne',
    defaultMessage: 'At least one item must be added',
  });
  const heading = intl.formatMessage({
    id: 'requiredPurchaseModal.requiredItemNeeded',
    defaultMessage: 'Required Item Needed',
  });
  const oneItemOnly = intl.formatMessage({
    id: 'requiredPurchaseModal.oneItem',
    defaultMessage: 'One item must be added',
  });
  const subheading = intl.formatMessage(
    {
      id: 'requiredPurchaseModal.requiresSpecificItems',
      defaultMessage:
        '{gallery} requires you to add specific items before continuing. Go to the {category} below and follow the instructions to add items.',
    },
    {
      gallery: currentCartOnly ? 'This gallery' : 'One or more of your galleries',
      category: categoriesWithVisitKey.length === 1 ? 'category' : 'categories',
    },
  );

  const handleRouteToShop = (categoryID: number, visitKey: string) => {
    history.push(`/${visitKey}/shop/${categoryID}`);
  };

  return (
    <CartsErrorModal heading={heading} subHeading={subheading} isOpen={isOpen} onClose={onClose}>
      {categoriesWithVisitKey.map((category, idx) => {
        const { visitKey } = category;
        const { title } = galleryMap[visitKey];
        const isLast = categoriesWithVisitKey.length - 1 === idx;

        return (
          <Fragment key={`${visitKey}-${category.id}`}>
            <Flex direction="column" paddingTop={5} textAlign="left" width="100%">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                borderBottomColor="grey.1"
                justifyContent="space-between"
                paddingX={1}
                paddingBottom={5}
                width="100%"
              >
                <Flex direction="column">
                  <Heading fontSize="md" textAlign="left">
                    {category.name}
                  </Heading>
                  <Text data-test="offer-instruction" fontSize="sm" paddingY={1}>
                    {category.selectionType === 'atLeastOne' ? atLeastOne : oneItemOnly}
                  </Text>
                  {!currentCartOnly && (
                    <Text color="grey.6" fontSize="xs">
                      {intl.formatMessage(
                        {
                          id: 'requiredPurchaseModal.gallery',
                          defaultMessage: 'Gallery: {title}',
                        },
                        { title },
                      )}
                    </Text>
                  )}
                </Flex>
                <Button
                  marginTop={{ base: 5, md: 0 }}
                  onClick={() => handleRouteToShop(category.id, visitKey)}
                  variant="black"
                >
                  {SHOP_CATEGORY}
                </Button>
              </Flex>
            </Flex>
            {!isLast && <Divider borderColor="grey.2" />}
          </Fragment>
        );
      })}
    </CartsErrorModal>
  );
};

export default RequiredPurchaseModal;
