import { BoxProps } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ProductOption } from '../../../../../../../shop-api-client';
import {
  CartProduct,
  CreateCartProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import {
  setCompletedSteps,
  setEditStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { getRequiredProductOptionsStep } from '../../../utils';
import CompletedSection from '../../CompletedSection';
import OptionValue from '../../CompletedSection/OptionValue';
import EditorSectionContainer from '../../EditorSectionContainer';
import ProductOptionEditor from '../ProductOptionEditor';
interface Props {
  containerProps?: BoxProps;
  editProduct: CartProduct | CreateCartProductReq;
  hideSave?: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  isInvalid?: boolean;
  isSubItem?: boolean;
  /**
   * pricesheet product option (toggle/selection/text)
   */
  option: ProductOption;
  /**
   * editProduct may be the `editProduct` from redux or an `editPackage` sub-item
   */
  onEdit(): void;
  onSave(editProduct: CreateCartProductReq): void;
  scrollToTop?: boolean;
}

/**
 * Renders a product option for selection during configuration
 */
const RequiredProductOptionEditor = ({
  containerProps,
  editProduct,
  hideSave,
  isCompleted,
  isDisabled,
  isInvalid,
  isSubItem,
  onEdit,
  onSave,
  option,
  scrollToTop,
}: Props) => {
  const stepName = useMemo(() => {
    return getRequiredProductOptionsStep(editProduct.id!, option.catalogOptionGroupID);
  }, [editProduct, option]);

  const { editStep, editPackageStep } = useAppSelector(selectConfiguration);

  const editing = editStep === stepName || editPackageStep === stepName;

  const canSave = !hideSave && editing && option.type !== 'text';
  const dispatch = useAppDispatch();

  /** the product option on the edit product */
  const editProductOption = editProduct.options?.find(
    editOption => editOption.optionGroupID === option.catalogOptionGroupID,
  );

  const handleSave = (updatedEditProduct: CreateCartProductReq) => {
    onSave(updatedEditProduct);
    handleSaveAndContinue();
  };

  const handleSaveAndContinue = () => {
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
  };

  if (isCompleted) {
    return (
      <CompletedSection heading={option.name} onEdit={onEdit}>
        <OptionValue
          option={option}
          selectedID={editProductOption?.optionID}
          value={editProductOption?.value}
        />
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer
      hideBorder={isSubItem}
      isDisabled={isDisabled}
      onSave={canSave ? handleSaveAndContinue : undefined}
      scrollToTop={scrollToTop}
      {...containerProps}
    >
      <ProductOptionEditor
        editProduct={editProduct}
        hideTextSave={hideSave}
        isInvalid={isInvalid}
        onSave={handleSave}
        option={option}
      />
    </EditorSectionContainer>
  );
};

export default RequiredProductOptionEditor;
