import { Flex, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
  price: string | number;
}

const ConfigSummaryRow = ({ children, price }: Props) => (
  <Flex data-test="package-item" align="center" fontSize="sm" padding={1} width="100%">
    {children}
    <Spacer />
    <Text height="100%" marginLeft={20} marginRight={2} marginTop={2}>
      {price}
    </Text>
  </Flex>
);

export default ConfigSummaryRow;
