import { Flex } from '@chakra-ui/layout';
import { Box, Center, Heading, Spinner } from '@chakra-ui/react';
import { AccountLandingData } from 'iq-api-client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { api, DownloadTokenStateEnum } from '../../../shop-api-client';
import Logo from '../../shared/components/Logo';
import DigitalDownloadButton from './DigitalDownloadButton';
import RegenerateButton from './RegenerateButton';

interface RouteParams {
  token: string;
}

const DigitalDownloads = () => {
  const { token } = useParams<RouteParams>();
  const [accountData, setAccountData] = useState<AccountLandingData>();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenState, setTokenState] = useState('');
  const intl = useIntl();

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const init = async () => {
      try {
        const tokenData = await api.validateDownloadToken(token);
        if (
          tokenData.token &&
          tokenData.tokenState !== DownloadTokenStateEnum.NotFound &&
          tokenData.tokenState !== DownloadTokenStateEnum.Error
        ) {
          const accountData = await api.getAccountLanding(tokenData.accountID);
          setAccountData(accountData);
        }

        setTokenState(tokenData.tokenState);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };

    init();
  }, [isLoading, token]);

  const renderButton = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (tokenState === DownloadTokenStateEnum.Valid) {
      return (
        <DigitalDownloadButton
          text={intl.formatMessage({
            id: 'downloads.buttons.downloadLabel',
            defaultMessage: 'Download Now',
          })}
          url={`${api.getZipStreamURL(token)}`}
        />
      );
    }

    if (tokenState === DownloadTokenStateEnum.Expired) {
      return (
        <RegenerateButton
          text={intl.formatMessage({
            id: 'downloads.buttons.regenerateLabel',
            defaultMessage: 'Resend Link',
          })}
          token={token}
        />
      );
    }
  };

  const renderHeaderText = () => {
    if (isLoading) {
      return;
    }

    if (tokenState === DownloadTokenStateEnum.Valid) {
      return intl.formatMessage({
        id: 'downloads.header.download',
        defaultMessage: 'Your photos are ready to download!',
      });
    }

    if (tokenState === DownloadTokenStateEnum.Expired) {
      return intl.formatMessage({
        id: 'downloads.header.expired',
        defaultMessage: 'Your photo download link has expired.',
      });
    }

    return (
      <Box marginTop={16}>
        {intl.formatMessage({
          id: 'downloads.header.error',
          defaultMessage: 'Sorry, we could not find your photo download link.',
        })}
      </Box>
    );
  };

  const renderInstructionsText = () => {
    if (isLoading) {
      return;
    }

    if (tokenState === DownloadTokenStateEnum.Valid) {
      return intl.formatMessage({
        id: 'downloads.instructions.download',
        defaultMessage: 'Click the button below to start your download.',
      });
    }

    if (tokenState === DownloadTokenStateEnum.Expired) {
      return intl.formatMessage({
        id: 'downloads.instructions.expired',
        defaultMessage:
          "No worries, we'll send you a new link to the email we have on file. Click the button below to reactivate your link.",
      });
    }

    return intl.formatMessage({
      id: 'downloads.instructions.error',
      defaultMessage:
        'Please check that you have entered the URL correctly or contact your photography provider to resend a new link.',
    });
  };

  const renderLogo = () => {
    if (accountData) {
      return (
        <Logo
          fallbackMargin={[8, 8, 8, 16]}
          fallbackText={accountData?.company}
          imageSrc={accountData?.logoImage}
          margin="auto"
          marginY={[5, 5, 5, 16]}
          maxWidth="250px"
        />
      );
    }

    return '';
  };

  return (
    <Box margin="0 auto" maxW={1280} paddingX={12}>
      {renderLogo()}
      <Center>
        <Flex flexDirection={['column', 'column', 'column', 'row']}>
          <Flex flexDirection="column" flexGrow={1} marginRight={12}>
            <Box>
              <Heading fontSize="20px" fontWeight="bold" marginY={4}>
                {renderHeaderText()}
              </Heading>
              <Box marginY={4}>{renderInstructionsText()}</Box>
              <Box marginY={6}>{renderButton()}</Box>
            </Box>
          </Flex>
        </Flex>
      </Center>
    </Box>
  );
};

export default DigitalDownloads;
