import { Flex, Heading, Icon, Spacer, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons/lib';
import { useIntl } from 'react-intl';
import { GridMode } from '../../../redux/slices/interactions.slice';
import Switch from '../../../shared/components/Switch';
import ArrowLeftbutton from '../ArrowLeftButton';

interface Props {
  handleBack?(): void;
  handleClick(layout: string | GridMode): void;
  isSubject?: boolean;
  layoutMap: { icon: IconType; name: string }[];
  selected?: string | GridMode;
  showBackButton?: boolean;
  showNames?: boolean;
  showNameToggle?: boolean;
  text?: string;
  toggleShowNames?(): void;
  maxWidth?: number;
}

const LayoutMenu = ({
  handleBack,
  handleClick,
  isSubject,
  layoutMap,
  selected = '',
  showBackButton = true,
  showNames = false,
  showNameToggle = false,
  text = 'Photos',
  toggleShowNames = () => {},
  maxWidth,
}: Props) => {
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const renderToggle = () => (
    <Flex>
      <Text fontSize="sm" marginX={2} marginLeft={{ base: 0, md: 2 }}>
        {intl.formatMessage({
          id: 'layoutMenu.imageNames',
          defaultMessage: 'Image Names',
        })}
      </Text>
      <Switch isChecked={showNames} onChange={toggleShowNames} />
    </Flex>
  );

  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="center"
      marginY={{ base: '20px', md: '40px' }}
      width="100%"
    >
      <Flex
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction="row"
        flex={1}
        margin="0 auto"
        maxWidth={{ base: undefined, md: maxWidth || '100%' }}
        paddingX={{ base: 2, md: 0 }}
        width="100%"
      >
        {!isSubject && showBackButton && (
          <ArrowLeftbutton
            aria-label={intl.formatMessage({
              id: 'layoutMenu.back',
              defaultMessage: 'Back to Albums',
            })}
            fontSize="sm"
            lightMode
            marginRight={3}
            onClick={handleBack}
            size="sm"
          />
        )}
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Heading fontSize={{ base: 'lg', md: '2xl' }} noOfLines={1}>
            {text}
          </Heading>
          {/* {isMobile && showNameToggle && renderToggle()} */}
        </Flex>
        <Spacer />
        <Flex justifyContent="flex-end">
          <Flex alignSelf="center" direction="row" paddingBottom="2px">
            {layoutMap.map(l => (
              <Icon
                as={l.icon}
                color={selected === l.name ? 'brand' : 'black'}
                fontSize="24px"
                key={l.name}
                left="0"
                marginLeft="5px"
                onClick={() => handleClick(l.name)}
                top="10px"
                _hover={{
                  cursor: 'pointer',
                }}
              />
            ))}
          </Flex>
        </Flex>
        {!isMobile && showNameToggle && renderToggle()}
      </Flex>
      {isMobile && showNameToggle && (
        <Flex width="100%" marginLeft={2} maxWidth={maxWidth || '100%'} marginTop={2}>
          {renderToggle()}
        </Flex>
      )}
    </Flex>
  );
};

export default LayoutMenu;
