const Alert = {
  baseStyle: {
    backgroundColor: 'brand',
  },
  variants: {
    info: {
      container: {
        backgroundColor: 'primaryAlertBackground',
        borderColor: 'primaryAlertBorder',
        borderWidth: '1px',
        color: 'primaryAlertText',
        fontFamily: 'ITC Avant Garde Gothic Demi',
      },
    },
    success: {
      container: {
        backgroundColor: 'lightBlue',
        color: 'successGreen',
      },
    },
  },
};

export default Alert;
