import { Box, BoxProps, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import ArrowLeftbutton from '../../../shared/components/ArrowLeftButton';
import ArrowRightButton from '../../../shared/components/ArrowRightButton';
import { NAVIGATE_LEFT, NAVIGATE_RIGHT } from '../../../shared/constants';
import { Params } from '../../../shared/types/router';
import { CARD_MARGIN, Direction, NEXT, PREV } from '../../MyPhotos/constants';
import ProductCard from '../Productcard';

interface Props extends BoxProps {
  heading: string;
  items: number[];
}

const ProductCarousel = ({ heading, items, ...boxProps }: Props) => {
  const { products } = useSelector(selectPriceSheet);
  const [isContainerEnd, setIsContainerEnd] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const { key } = useParams<Params>();
  const history = useHistory();
  const productsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!productsContainer.current) {
      return;
    }
    const containerRef = productsContainer.current; // local variable is required for cleanup

    // Scan scroll coordinates to toggle displaying navigation arrows
    const scanCoordinates = () => {
      // If left arrow is not visible and scroll is greater than card margin
      // (so part of the first product card is now concealed), then show arrow:
      if (!showLeftArrow && containerRef.scrollLeft > CARD_MARGIN) {
        setShowLeftArrow(true);
        // If scroll is less than or equal to card margin, hide left arrow:
      } else if (showLeftArrow && containerRef.scrollLeft <= CARD_MARGIN) {
        setShowLeftArrow(false);
      }

      // If isContainerEnd is true, but the sum of the current scrollLeft coordinates
      // and the visible container width are no longer equal to the full container's width,
      // then set isContainerEnd to false;
      if (
        isContainerEnd &&
        containerRef.scrollLeft + containerRef.clientWidth !== containerRef.scrollWidth
      ) {
        setIsContainerEnd(false);
        // If isContainerEnd is false and the sum of scrollLeft coordinates and the visible container
        // width equal the full container's width, then set isContainerEnd to true:
      } else if (
        !isContainerEnd &&
        containerRef.scrollLeft + containerRef.clientWidth === containerRef.scrollWidth
      ) {
        setIsContainerEnd(true);
      }
    };

    // If all product cards fit in the visible width of the container, set isContainerEnd to true:
    if (!isContainerEnd && containerRef.scrollWidth <= containerRef.clientWidth) {
      setIsContainerEnd(true);
    }

    containerRef.addEventListener('scroll', scanCoordinates);
    return () => {
      containerRef.removeEventListener('scroll', scanCoordinates);
    };
  }, [showLeftArrow, isContainerEnd]);

  const fireGAItemEvent = (productID: number) => {
    let itemType = '';
    if (heading === 'Same Category Items') {
      itemType = 'view_same_category_item';
    } else if (heading === 'Additional Items You May Like') {
      itemType = 'view_you_may_like_item';
    }
    window.gtag('event', `${itemType}`, {
      items: [{ item_id: productID }],
    });
  };

  const handleViewProduct = (productID: number) => {
    if (!products[productID]) {
      return;
    }
    fireGAItemEvent(productID);
    history.push(`/${key}/shop/${products[productID].categoryID}/product/${productID}`);
  };

  const handleNavigate = (direction: Direction) => {
    if (!productsContainer.current) {
      return;
    }
    const { clientWidth, scrollWidth } = productsContainer.current;
    const itemWidth = scrollWidth / items.length;
    const cardRemainder = itemWidth - (clientWidth % itemWidth);
    let xCoord = direction === NEXT ? clientWidth : -clientWidth;

    // If product cards do not fit evenly
    // within the container's width, then only scroll for the length of the
    // products that were entirely visible:
    if (direction === NEXT && cardRemainder > CARD_MARGIN) {
      const visibleProducts = Math.floor(clientWidth / itemWidth);
      xCoord = visibleProducts * itemWidth;
    }

    // If at the end of the product carousel and product cards do not fit evenly
    // within the container's width, then only scroll for the length of the
    // products that were entirely visible:
    if (direction === PREV && isContainerEnd && cardRemainder > CARD_MARGIN) {
      const visibleProducts = Math.floor(clientWidth / itemWidth);
      xCoord = -(visibleProducts * itemWidth);
    }

    productsContainer.current.scrollBy({
      top: 0,
      left: xCoord,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      borderStyle="solid"
      borderTopColor="grey.1"
      borderTopWidth="2px"
      paddingBottom="24px"
      position="relative"
      {...boxProps}
    >
      {heading && (
        <Heading fontSize="18px" marginBottom="20px" marginTop="25px">
          {heading}
        </Heading>
      )}
      <Flex justifyContent="center">
        <Flex
          justify={showLeftArrow ? 'space-between' : 'flex-end'}
          left="-5px"
          position="absolute"
          top="40%"
          width="calc(100% + 10px)"
        >
          {showLeftArrow && (
            <ArrowLeftbutton
              aria-label={NAVIGATE_LEFT}
              fontSize="24px"
              lightMode
              onClick={() => handleNavigate(PREV)}
              size="md"
            />
          )}
          {!isContainerEnd && (
            <ArrowRightButton
              aria-label={NAVIGATE_RIGHT}
              fontSize="24px"
              lightMode
              onClick={() => handleNavigate(NEXT)}
              size="md"
            />
          )}
        </Flex>
        <Flex
          direction="row"
          overflowX="auto"
          ref={productsContainer}
          sx={{ scrollSnapType: 'x mandatory' }}
          width="100%"
        >
          {items.map(item => {
            const product = products[item];
            return (
              <ProductCard
                key={product.id}
                cardMargin="6px"
                maxCardWidth="175px"
                onCardClick={() => handleViewProduct(product.id)}
                product={product}
              />
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductCarousel;
