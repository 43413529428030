import { Flex, FlexProps, Radio, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends FlexProps {
  focus: boolean;
  label: string;
  price?: number | string;
  subtext?: string;
  value: string;
}

const BorderedRadioInput = ({ label, value, focus, subtext, price, ...rest }: Props) => (
  <Flex
    borderColor={focus ? 'brand' : 'grey.2'}
    borderRadius="md"
    borderStyle="solid"
    borderWidth={focus ? '2px' : '1px'}
    cursor="pointer"
    flexDirection="column"
    padding={focus ? '17px' : '18px'}
    position="relative"
    width="100%"
    {...rest}
  >
    <Radio value={value}>
      <Flex justifyContent="space-between" marginLeft="7px">
        <Text fontWeight="bold">{label}</Text>
        {!!price && <Text fontSize={14}>{price}</Text>}
      </Flex>
    </Radio>
    {subtext && (
      <Text fontSize="14px" marginLeft="35px" marginTop="5px">
        {subtext}
      </Text>
    )}
  </Flex>
);
export default BorderedRadioInput;
