import { Box, BoxProps, Icon, IconProps, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import NotificationPin from '../../../shared/components/NotificationPin';
import useWindowSize from '../../../shared/hooks/useWindowSize';

interface Props {
  isLocked?: boolean;
  onClick?(): void;
  notificationColor?: string;
  notificationContent?: string | number;
  path: string;
  text: string;
  boxProps?: BoxProps;
  iconProps?: IconProps;
}

const CategoryLink = ({
  isLocked,
  notificationContent,
  notificationColor,
  onClick,
  path,
  text,
  boxProps,
  iconProps,
}: Props) => {
  const { width } = useWindowSize();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isActive = path === location.pathname;

  return (
    <Box
      background={isActive ? 'white' : 'transparent'}
      color={isActive ? 'brand' : 'inherit'}
      display="inline-flex"
      fontFamily={isActive ? 'ITC Avant Garde Gothic Demi' : 'inherit'}
      fontSize="lg"
      marginTop="6px"
      paddingLeft="55px"
      paddingRight="10px"
      paddingY={isMobile ? '5px' : '0'}
      position="relative"
      width={{ base: '100%', lg: '315px' }}
      {...boxProps}
    >
      {isActive && !isMobile && (
        <Icon as={FaPlay} color="brand" fontSize="6px" left="0" position="absolute" top="10px" />
      )}
      {isLocked && (
        <Icon
          as={FiLock}
          fontSize="lg"
          left={{ base: '25px', lg: '15px' }}
          top={{ base: '8px', lg: '4px' }}
          position="absolute"
          data-test="category-link-locked-icon"
          {...iconProps}
        />
      )}
      <Link
        as={RouterLink}
        onClick={onClick}
        to={path}
        data-test="category-link-text"
        width="100%"
        textDecoration="none"
        _hover={{ textDecoration: 'none' }}
      >
        <Text maxWidth={{ base: `${width - 110}px`, lg: '100%' }} style={{ hyphens: 'auto' }}>
          {text}
        </Text>
      </Link>
      {notificationContent && (
        <NotificationPin
          backgroundColor={notificationColor}
          content={notificationContent}
          position="absolute"
          right="0"
        />
      )}
    </Box>
  );
};

export default CategoryLink;
