import { Flex, Heading, Icon, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { IoCartOutline } from 'react-icons/io5';
import Modal from '../Modal';

interface Props {
  actionLabel?: string;
  children?: React.ReactNode;
  heading: string;
  isOpen: boolean;
  onClose(showError: boolean): void;
  onConfirm?(): void;
  subHeading?: string;
}

// TODO SC-204: Update modal to support `singleRequired` and `atLeastOne` category selection type errors
const CartsErrorModal = ({
  actionLabel,
  children,
  heading,
  isOpen,
  onClose,
  onConfirm,
  subHeading,
}: Props) => {
  const handleClose = () => onClose(false);
  const scrollBehavior = useBreakpointValue<'outside' | 'inside'>({
    base: 'outside',
    md: 'inside',
  });

  return (
    <Modal
      actionLabel={actionLabel}
      contentStyles={{
        minHeight: { base: undefined, md: '470px' },
        maxHeight: { base: undefined, md: '800px' },
        paddingBottom: '60px',
      }}
      footerStyles={{ paddingBottom: '20px' }}
      hideCancel
      isOpen={isOpen}
      maxWidth={`${Math.min(window.innerWidth, 630)}px`}
      onClose={handleClose}
      onConfirm={onConfirm}
      scrollBehavior={scrollBehavior}
    >
      <Flex align="center" direction="column" height="100%" textAlign="center">
        <Icon as={IoCartOutline} height={20} width={20} marginBottom={5} />
        <Heading data-test="heading-error" fontSize="2xl" marginY={2} maxW="400px">
          {heading}
        </Heading>
        {subHeading && (
          <Text data-test="subheading-error" color="error" marginY={2}>
            {subHeading}
          </Text>
        )}
        {children}
      </Flex>
    </Modal>
  );
};

export default CartsErrorModal;
