import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  isDisabled?: boolean;
}

const DisabledOverlay = ({ isDisabled, ...rest }: Props) => {
  if (!isDisabled) {
    return null;
  }

  return (
    <Box
      backgroundColor="semiTransparentWhite"
      height="100%"
      position="absolute"
      width="100%"
      zIndex={1}
      {...rest}
    />
  );
};

export default DisabledOverlay;
