import { Center, Flex, Image, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GalleryLandingData } from '../../../shop-api-client';
import { setAnalyticsID } from '../../redux/slices/account.slice';
import { useAppDispatch } from '../../redux/store';
import { logout } from '../../redux/thunks/auth.thunks';
import { getGalleryLanding } from '../../redux/thunks/gallery.thunks';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { Params } from '../../shared/types/router';
import LoginForm from './LoginForm';

const COVER = 'cover';
const FORM_MAX_WIDTH = 484;
const WALLPAPER_HEIGHT = 750;
const WALLPAPER_WIDTH = 750;
const MAX_ELEM_WIDTH = FORM_MAX_WIDTH + WALLPAPER_WIDTH;

export interface LoginProps {
  unavailableMessage: string;
  setUnavailableMessage(unavailableMessage: string): void;
}

// Login wrapper that fetches landing data for gallery for a single gallery login
const Login = ({ unavailableMessage, setUnavailableMessage }: LoginProps) => {
  const [isFitCover, setIsFitCover] = useState(false);
  const [landingData, setLandingData] = useState<GalleryLandingData>();
  const [wallpaper, setWallpaper] = useState('');
  const [formKey, setFormKey] = useState(0);

  const { key } = useParams<Params>();
  const { height, width } = useWindowSize();
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Wallpaper variables used to set style props
  const maxWallpaperHeight = height > WALLPAPER_HEIGHT ? `${WALLPAPER_HEIGHT}px` : '100%';
  const maxWallpaperWidth = width > MAX_ELEM_WIDTH ? `${WALLPAPER_WIDTH}px` : undefined;

  // Call api to retrieve gallery landing data - does not save to redux
  useEffect(() => {
    const init = async () => {
      const { valid, payload } = await dispatch(getGalleryLanding(key));

      if (!valid || !payload) {
        history.push('/');
        return;
      }
      dispatch(setAnalyticsID(payload.account.analyticsID));
      const { baseGallery, galleryConfig } = payload;

      setLandingData(payload);
      setWallpaper(galleryConfig.wallpaper.url);
      setIsFitCover(galleryConfig.wallpaper.fit === COVER);

      document.title = baseGallery.title;
    };

    init();
  }, [dispatch, history, key]);

  /**
   * Handles signout when a visitor has been authenticated for an unavailable gallery,
   * additionally resets the unavailable message to null
   */
  const handleSignout = () => {
    dispatch(logout());

    // Clear unavailable message to unlock login form
    setUnavailableMessage('');

    // change form key so on back + remount LoginForm so it resets
    const newFormKey = formKey + 1;
    setFormKey(newFormKey);
  };

  if (!landingData) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex backgroundColor="white" direction="row" maxHeight="100%" maxWidth="100%" padding="0">
      <LoginForm
        key={formKey}
        galleryKey={key}
        handleBack={handleSignout}
        landingData={landingData}
        setUnavailableMessage={setUnavailableMessage}
        unavailableMessage={unavailableMessage}
      />
      <Flex
        align="center"
        flex="1"
        justify="center"
        maxHeight="100vh"
        overflow="hidden"
        position="relative"
      >
        {wallpaper && (
          <Image
            flexShrink={0}
            left="50%"
            maxHeight={isFitCover ? 'none' : maxWallpaperHeight}
            maxWidth={isFitCover ? 'none' : maxWallpaperWidth}
            minHeight={isFitCover ? '100%' : 'inherit'}
            minWidth={isFitCover ? '100%' : 'inherit'}
            position="absolute"
            src={wallpaper}
            top="50%"
            transform="translateY(-50%) translateX(-50%)"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Login;
