import { Flex, Heading } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import {
  selectCheckoutData,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { setCheckoutShippingOptions } from '../../../redux/slices/checkout.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import BorderedRadioInput from '../../../shared/components/BorderedRadioInput';
import VerticalRadioGroup from '../../../shared/components/VerticalRadioGroup';
import { FREE } from '../../../shared/constants';
import { Params } from '../../../shared/types/router';
import { formatCurrency } from '../../../shared/utils';
import { CheckoutStep } from '../CheckoutData';
import { CHECKOUT_ERROR } from '../constants';

const CheckoutShippingOptions = ({ isActive, isComplete, setValidateCallback }: CheckoutStep) => {
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const {
    settings: { pickupLabel, shipping },
  } = useAppSelector(state => selectGallery(state, checkoutVisitKeys?.[0] || currentVisitKey!));
  const checkoutData = useSelector(selectCheckoutData);
  const { currency } = useAppSelector(selectAccount);

  const [data, setData] = useState(checkoutData.shippingType || 'pickup');

  const dispatch = useAppDispatch();
  const { checkoutID } = useParams<Params>();
  const intl = useIntl();

  const shippingPickup = intl.formatMessage(
    { id: 'checkout.checkoutShippingPickup', defaultMessage: 'Pickup at {pickupLabel}' },
    { pickupLabel },
  );
  const shippingPickupSubtext = intl.formatMessage(
    {
      id: 'checkout.checkoutShippingPickupSubtext',
      defaultMessage: 'Items will be available for pickup at {pickupLabel}',
    },
    { pickupLabel },
  );
  const shippingDirect = intl.formatMessage({
    id: 'checkout.checkoutShippingDirect',
    defaultMessage: 'Ship to me',
  });
  const shippingDirectSubtext = intl.formatMessage({
    id: 'checkout.checkoutShippingDirectSubtext',
    defaultMessage: 'Items will be shipped directly to you',
  });
  const shippingPrompt = intl.formatMessage({
    id: 'checkout.checkoutShippingMessage',
    defaultMessage: 'Choose how you would like to receive your order',
  });

  // Validate callback:
  // Validate data + check for errors
  // Send API/Redux updates when next button is clicked
  const validate = useCallback(async () => {
    if (!data) {
      return false;
    }

    dispatch(setCheckoutShippingOptions(data));
    const result = await api.updateCheckout(currentVisitKey!, checkoutID!, {
      ...checkoutData,
      shippingType: data,
    });
    return !!result;
  }, [checkoutID, checkoutData, currentVisitKey, data, dispatch]);

  // Set the Callback as a callable function in parent state when isActive
  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [isActive, setValidateCallback, validate]);

  const handleChange = (e: string) => setData(e);

  const renderComplete = () => (
    <Flex flexFlow="column" paddingBottom={8} paddingX={10}>
      <Heading fontSize="md">{data === 'pickup' ? shippingPickup : shippingDirect}</Heading>
    </Flex>
  );

  return (
    <>
      {isComplete && !isActive && renderComplete()}
      {isActive && (
        <Flex flexFlow="column" paddingLeft={{ base: 0, lg: '65px' }} marginBottom="50px">
          <VerticalRadioGroup
            headingProps={{ paddingBottom: '5px' }}
            invalidMessage={CHECKOUT_ERROR}
            isInvalid={!data}
            marginTop={4}
            name="shippingType"
            onChange={e => handleChange(e)}
            text={shippingPrompt}
            value={data}
            variant="maxLabel"
          >
            <BorderedRadioInput
              data-test="customer-choice-pick-up"
              focus={data === 'pickup'}
              label={shippingPickup}
              onClick={() => handleChange('pickup')}
              price={FREE}
              subtext={shippingPickupSubtext}
              value="pickup"
            />
            <BorderedRadioInput
              data-test="customer-choice-shipping"
              focus={data === 'direct'}
              label={shippingDirect}
              marginBottom="6px"
              onClick={() => handleChange('direct')}
              price={shipping ? `+${formatCurrency(shipping, currency)}` : FREE}
              subtext={shippingDirectSubtext}
              value="direct"
            />
          </VerticalRadioGroup>
        </Flex>
      )}
    </>
  );
};

export default CheckoutShippingOptions;
