// TODO SC-205: https://imagequix.atlassian.net/browse/SC-205
// Add Product Option details. This may entail changing the conditions
// for when this component is rendered (currently does not support package types)

import { Divider, Flex } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { CartPackage } from '../../../../../shop-api-client/models/Cart';
import CartPackageItemSection from './CartPackageItemSection';

interface Props {
  item: CartPackage;
  visitKey: string;
}

/**
 * Displays all products and their respective details for a cart package
 */
const CartPackageDetails = ({ item, visitKey }: Props) => {
  return (
    <Flex marginBottom={{ base: 2, md: 4 }} marginLeft={{ base: 0, md: 2 }} direction="column">
      {item.products.map(product => (
        <Fragment key={product.id}>
          <CartPackageItemSection
            marginBottom={{ base: 0.5, md: 2 }}
            packagePSItemID={item.priceSheetItemID}
            product={product}
            visitKey={visitKey}
          />

          <Divider marginBottom={{ base: 3.5, md: 5 }} />
        </Fragment>
      ))}
    </Flex>
  );
};

export default CartPackageDetails;
