import { Box } from '@chakra-ui/layout';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectAccount } from '../../redux/selectors/account.selectors';
import { selectInteractions } from '../../redux/selectors/interactions.selectors';
import { toggleShowNav } from '../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../redux/store';
import { getAccountLanding } from '../../redux/thunks/account.thunks';
import Logo from '../../shared/components/Logo';
import { Params } from '../../shared/types/router';
import CopyrightText from '../Footer/CopyrightText';
import MobileActionBar from '../Navigation/MobileActionBar';
import ContactDetails from './ContactDetails';
import GallerySearch from './GallerySearch';

const AccountLanding = () => {
  const { company, contact, logoImage } = useSelector(selectAccount);
  const { showNav } = useSelector(selectInteractions);

  const [isLoading, setIsLoading] = useState(true);

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    /**
     * Fetch base data required to view Account Landing and store to Redux
     */
    const initAccountLanding = async () => {
      const { valid } = await dispatch(getAccountLanding(key.toUpperCase()));

      if (!valid) {
        history.push('/');
      }
    };

    initAccountLanding();
    setIsLoading(false);
  }, [dispatch, history, key, isLoading]);

  useEffect(() => {
    dispatch(toggleShowNav(false));
    return () => {
      dispatch(toggleShowNav(true));
    };
  }, [dispatch, showNav]);

  // If company isn't present, base data hasn't loaded yet, so don't render components
  if (!company) {
    return null;
  }

  const handleResults = (nextVisitKey: string | null, query: string) => {
    if (nextVisitKey) {
      history.push(`/${nextVisitKey}`);
      return;
    }

    history.replace(`/${key}?${query}`);
  };

  return (
    <Flex align="center" direction="column" height="100vh">
      <Box width="100%">{isMobile && <MobileActionBar />}</Box>
      {!!contact && !isMobile && <ContactDetails />}
      <Flex flex={1} direction="column" alignItems="center" marginTop="5%">
        {!isMobile && (
          <Logo
            fallbackMargin={5}
            fallbackText={company}
            imageSrc={logoImage}
            margin={5}
            maxHeight="100px"
            maxWidth="200px"
          />
        )}
        <GallerySearch accountKey={key} visitKey={null} handleResults={handleResults} />
      </Flex>
      <Box marginY={10}>
        <CopyrightText company={company} />
      </Box>
    </Flex>
  );
};

export default AccountLanding;
