import { Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProductOption, ShopProduct } from '../../../../../shop-api-client';
import { CartProduct, CartProductOption } from '../../../../../shop-api-client/models/Cart';
import { selectUniqueImages } from '../../../../redux/selectors/cart.selectors';
import { selectGallery, selectDynamicData } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../redux/store';
import { ADD_ONS } from '../../../Products/Configuration/constants';
import { getNodeMapForCatalogProduct } from '../../../Products/utils';
import { getCartProductTextNodes } from '../../utils';
import CartItemImageList from '../CartItemImageList';
import { TextNode } from 'iq-api-client';
import { template } from 'iq-product-render';
import { useSelector } from 'react-redux';

interface Props {
  item: CartProduct;
  priceSheetItem: ShopProduct;
  visitKey: string;
}

// TODO SC-205: https://imagequix.atlassian.net/browse/SC-205
// Add Product Option details. This may entail changing the conditions
// for when this component is rendered (currently does not support package types)

/**
 * Displays details for a cart product
 */
const CartProductDetails = ({ item, priceSheetItem, visitKey }: Props) => {
  const { priceSheetID } = useAppSelector(state => selectGallery(state, visitKey));
  const { productNodeMap } = useAppSelector(state => selectPriceSheet(state, priceSheetID));
  const uniqueImages = useAppSelector(state => selectUniqueImages(state, item, visitKey));

  const intl = useIntl();

  const text = intl.formatMessage({
    id: 'cartProductDetails.text',
    defaultMessage: 'Text',
  });
  const yes = intl.formatMessage({
    id: 'cartProductDetails.yes',
    defaultMessage: 'Yes',
  });
  const added = intl.formatMessage({
    id: 'cartProductDetails.added',
    defaultMessage: 'Added',
  });
  const usingDataOnFile = intl.formatMessage({
    id: 'cartProductDetails.usingDataOnFile',
    defaultMessage: 'Using data on file',
  });

  const psCatalogOptionGroupMap = priceSheetItem.options.reduce<Record<string, ProductOption>>(
    (map, o) => {
      map[o.catalogOptionGroupID] = o;
      return map;
    },
    {},
  );

  const dynamicData = useSelector(selectDynamicData);
  const renderTextNodes = () => {
    const textNodes = getCartProductTextNodes(item, productNodeMap, priceSheetItem);
    if (!textNodes.length) {
      return null;
    }
    const nodeMap = getNodeMapForCatalogProduct(priceSheetItem.catalogProductID, productNodeMap);

    return (
      <Flex marginBottom={{ base: 2, md: 4 }} direction="column">
        <Heading fontSize={{ base: 'xs', md: 'sm' }} marginBottom={0.5}>
          {text}
        </Heading>
        <UnorderedList marginLeft={{ base: 5, md: 6 }}>
          {textNodes.map(node => {
            const catalogNode = nodeMap[node.catalogNodeID];
            return (
              <ListItem key={node.id} fontSize={{ base: 'xs', md: 'sm' }} color="#737475">
                <Text as="span">{catalogNode.name}: </Text>
                {node.type === 'text' && (catalogNode as TextNode).text !== node.text ? (
                  <Text
                    data-test={`${nodeMap[node.catalogNodeID].name}-value`}
                    as="span"
                    color="#1a202c"
                    fontWeight="bold"
                  >
                    {template(node.text || '', dynamicData)}
                  </Text>
                ) : (
                  <Text
                    data-test={`${nodeMap[node.catalogNodeID].name}-value`}
                    as="span"
                    fontStyle="italic"
                    fontWeight="bold"
                  >
                    {usingDataOnFile}
                  </Text>
                )}
              </ListItem>
            );
          })}
        </UnorderedList>
      </Flex>
    );
  };

  const getOptionValue = (option: CartProductOption) => {
    const psOptionGroup = psCatalogOptionGroupMap[option.optionGroupID];
    const optionSelection = psOptionGroup.selections.find(
      s => s.catalogOptionID === option.optionID,
    );

    if (psOptionGroup.type === 'text') {
      return option.value;
    }
    if (psOptionGroup.type === 'boolean') {
      return yes;
    }
    return optionSelection?.name || added;
  };

  const renderProductOptions = () => {
    if (!item.options?.length) {
      return null;
    }

    return (
      <Flex marginBottom={{ base: 2, md: 4 }} direction="column">
        <Heading fontSize={{ base: 'xs', md: 'sm' }} marginBottom={0.5}>
          {ADD_ONS}
        </Heading>
        <UnorderedList marginLeft={{ base: 5, md: 6 }}>
          {item.options.map(option => {
            const value = getOptionValue(option);
            return (
              <ListItem key={option.id} fontSize={{ base: 'xs', md: 'sm' }}>
                <Text as="span">
                  {option.name}: {value}
                </Text>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Flex>
    );
  };

  return (
    <Flex direction="column" width="100%">
      {!!uniqueImages.length && <CartItemImageList imageSet={uniqueImages} visitKey={visitKey} />}
      {renderTextNodes()}
      {renderProductOptions()}
    </Flex>
  );
};

export default CartProductDetails;
