import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api, ShopBackground } from '../../../../shop-api-client';
import {
  selectCheckoutData,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import {
  selectPriceSheet,
  selectVisitKeytoPriceSheetIDMap,
} from '../../../redux/selectors/priceSheet.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { setCheckoutBackgroundOption } from '../../../redux/slices/checkout.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { loadBackgroundImage } from '../../../redux/thunks/interactions.thunks';
import BackgroundSwatches from '../../../shared/components/BackgroundSwatches';
import Swatch from '../../../shared/components/BackgroundSwatches/Swatch';
import { Params } from '../../../shared/types/router';
import { getNameWithoutExt } from '../../../shared/utils';
import { CheckoutStep } from '../CheckoutData';
import { CHECKOUT_ERROR } from '../constants';

const CheckoutBgOptions = ({ isActive, isComplete, setValidateCallback }: CheckoutStep) => {
  const checkoutData = useSelector(selectCheckoutData);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const visitKeytoPriceSheetIDMap = useSelector(selectVisitKeytoPriceSheetIDMap);
  // this section only shows when the gallery is preOrder
  //so just always pull pricesheet of the SINGLE current checkoutvisitkey
  const { backgroundSets } = useAppSelector(state =>
    selectPriceSheet(state, visitKeytoPriceSheetIDMap[checkoutVisitKeys?.[0] || currentVisitKey!]),
  );
  const { selectedBackground } = useSelector(selectInteractions);

  const [data, setData] = useState<number | null>(null);
  const [errors, setErrors] = useState(true);
  const [errorState, setErrorState] = useState(false);

  const { checkoutID } = useParams<Params>();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const backgroundInstructions = intl.formatMessage({
    id: 'checkoutBackgroundOptions.instructions',
    defaultMessage: 'One background must be selected for this order.',
  });

  // Validate callback:
  // Validate data + check for errors
  // Send API/Redux updates when next button is clicked
  const validate = useCallback(async () => {
    if (errors || !checkoutID) {
      setErrorState(true);
      return false;
    }

    dispatch(setCheckoutBackgroundOption(data));
    const result = await api.updateCheckout(currentVisitKey!, checkoutID, {
      ...checkoutData,
      backgroundOption: data,
    });
    return !!result;
  }, [checkoutID, checkoutData, currentVisitKey, data, dispatch, errors]);

  // Set the Callback as a callable function in parent state when isActive
  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [isActive, setValidateCallback, validate]);

  // Pre-fill with data if saved/in Redux
  useEffect(() => {
    // if no saved checkout data, reset bg
    if (!checkoutData.backgroundOption) {
      dispatch(loadBackgroundImage(null));
    }
    setData(checkoutData.backgroundOption);

    for (const bgSet of backgroundSets) {
      const foundBg = bgSet.backgrounds.find(bg => bg.id === checkoutData.backgroundOption);
      if (foundBg) {
        dispatch(loadBackgroundImage(foundBg));
        break;
      }
    }

    // reset redux bg to nothing
  }, [checkoutData, backgroundSets, dispatch]);

  // Set Error state based on data
  useEffect(() => {
    setErrors(!data);
  }, [data]);

  const handleChange = useCallback(
    (bg: ShopBackground) => {
      setData(bg.id);
      dispatch(setCheckoutBackgroundOption(bg.id));
      dispatch(loadBackgroundImage(bg));
    },
    [dispatch],
  );

  const renderComplete = () =>
    !!selectedBackground && (
      <Flex flexFlow="row" paddingBottom={8} paddingLeft={10}>
        <Heading fontSize="md" marginRight={8} maxW={'85px'}>
          {getNameWithoutExt(selectedBackground.name)}
        </Heading>
        <Swatch
          onClick={() => {}}
          position="relative"
          showOverlay={false}
          size="85px"
          url={`url(${selectedBackground.sources.thumb}) no-repeat`}
          data-test="background-swatches-images"
        />
      </Flex>
    );

  const renderBackgroundSets = () => {
    const allBackgrounds = backgroundSets.reduce<ShopBackground[]>((arr, set) => {
      for (const bg of set.backgrounds) {
        arr.push({ ...bg, price: set.price });
      }
      return arr;
    }, []);

    return (
      <BackgroundSwatches
        backgrounds={allBackgrounds}
        swatchMargin={2}
        onSelectBackground={handleChange}
        selectedBackground={selectedBackground}
        showPrice
      />
    );
  };

  return (
    <>
      {isComplete && !isActive && renderComplete()}
      {isActive && (
        <Flex flexFlow="column" paddingLeft={14}>
          <Text marginBottom={4} marginLeft={2}>
            {backgroundInstructions}
          </Text>
          {renderBackgroundSets()}
          {errorState && (
            <Text fontSize="xs" color="error">
              {`* ${CHECKOUT_ERROR}`}
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};

export default CheckoutBgOptions;
