import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import {
  selectAllCartsItemCount,
  selectCartErrors,
  selectCartItem,
  selectCartSlice,
  selectUnlockedAndAddedLockedItems,
} from '../../../redux/selectors/cart.selectors';
import { selectSummaryFinancials } from '../../../redux/selectors/financials.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { removeJustAdded } from '../../../redux/slices/cart.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { updateCartItem } from '../../../redux/thunks/cart.thunks';
import { createCheckout } from '../../../redux/thunks/checkout.thunks';
import CheckMarkSuccess from '../../../shared/components/CheckMarkSuccess';
import DrawerSection from '../../../shared/components/DrawerSection';
import NumberInput from '../../../shared/components/NumberInput';
import { DEBOUNCE_DURATION } from '../../../shared/components/NumberInput/NumberInput';
import { CLOSE_MODAL, CONTINUE_TO_CHECKOUT } from '../../../shared/constants';
import { Params } from '../../../shared/types/router';
import { formatCurrency, GAItem, getGAItems, sort } from '../../../shared/utils';
import ProductImage from '../ProductImage';
import { getFirstAssignedImage } from '../utils';
import OtherItems from './OtherItems';

interface Props {
  onClose?(): void;
}

// TODO SC-203: Some elements need styling consideration for mobile. Awaiting criteria
const CartItemDrawer = ({ onClose }: Props) => {
  const { offersStatusMap, products } = useSelector(selectPriceSheet);
  const { subtotal, imageOptionFees, backgroundFees } = useSelector(selectSummaryFinancials);
  const { cartMap, justAdded } = useSelector(selectCartSlice);
  const { accountID, currency } = useSelector(selectAccount);
  const cartErrors = useSelector(selectCartErrors);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const itemCount = useSelector(selectAllCartsItemCount);
  const unlockedItems = useSelector(selectUnlockedAndAddedLockedItems);
  const cartItem = useAppSelector(state => selectCartItem(state, justAdded.initial));

  const [isUpdating, setIsUpdating] = useState(false);
  const [numberInputError, setNumberInputError] = useState(false);

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  /**
   * `handleQuantityChange` is used as a depedency for memoization of a debounced
   * version of itself in NumberInput, which requires it to be memoized here
   */
  const handleQuantityChange = useCallback(
    async (quantity: number) => {
      if (!cartItem) {
        return;
      }
      setNumberInputError(false);

      setIsUpdating(true);
      const { valid } = await dispatch(updateCartItem({ ...cartItem, quantity }, key, false));
      setIsUpdating(false);

      if (!valid) {
        setNumberInputError(true);
      }
    },
    [cartItem, dispatch, key],
  );

  if (!cartItem) {
    return null;
  }

  const subtotalText = intl.formatMessage({
    id: 'cartItemDrawer.subtotalText',
    defaultMessage: 'Subtotal:',
  });
  const successText = intl.formatMessage(
    {
      id: 'cartItemDrawer.itemAdded',
      defaultMessage: '{quantityAdded} item{plural} added',
    },
    {
      quantityAdded: cartItem.quantity,
      plural: cartItem.quantity > 1 ? 's' : '',
    },
  );
  const otherProductsHeading = intl.formatMessage({
    id: 'cartItemDrawer.otherProductsHeading',
    defaultMessage: 'Other products you may like',
  });
  const unlockedItemsHeading = intl.formatMessage({
    id: 'cartItemDrawer.unlockedProducts',
    defaultMessage: "Products you've unlocked",
  });
  const viewCart = intl.formatMessage(
    {
      id: 'cartItemDrawer.viewCart',
      defaultMessage: 'View Cart ({itemCount})',
    },
    { itemCount },
  );

  const shopItem = products[cartItem.priceSheetItemID];
  const isLocked = offersStatusMap[shopItem.categoryID]?.isLocked;
  const summarySubtotal = subtotal + imageOptionFees + backgroundFees;

  const otherProducts = Object.values(products)
    .sort((a, b) => sort(a.price, b.price))
    .filter(p => p.id !== cartItem.priceSheetItemID && !offersStatusMap[p.categoryID]);

  const handleCheckout = async () => {
    const checkoutID = (await dispatch(createCheckout([key])))?.checkout?.id;
    dispatch(removeJustAdded());
    if (checkoutID) {
      history.push(`/${key}/checkout/${checkoutID}`);

      //Google Analytic begin_checkout event
      const gaItems: GAItem[] = getGAItems(cartMap, accountID);
      window.gtag('event', 'begin_checkout', {
        items: gaItems,
      });
    } else {
      history.push(`/${key}/carts`);
    }
  };

  const handleClose = () => {
    dispatch(removeJustAdded());
    if (onClose) {
      onClose();
    }
  };

  const handleRouteToCart = () => {
    dispatch(removeJustAdded());
    history.push(`/${currentVisitKey}/carts`);
  };

  return (
    <Drawer isOpen={!!justAdded.initial} onClose={handleClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerSection minHeight="120px">
          <Flex>
            <Flex marginY="3" grow={1}>
              <ProductImage
                fallbackFontSize="5px"
                fallbackIconSize="sm"
                image={getFirstAssignedImage(shopItem)}
                marginX="5"
                maxHeight="70px"
                maxWidth="70px"
                product={shopItem}
                width={70}
              />
              <Flex grow={1} direction="column">
                <CheckMarkSuccess text={successText} />
                <Heading fontSize="sm" lineHeight="1.5">
                  {cartItem.name}
                </Heading>
              </Flex>
              {!isLocked && (
                <NumberInput
                  alignSelf="center"
                  debounceDuration={DEBOUNCE_DURATION}
                  isDisabled={isUpdating}
                  marginTop="4"
                  marginX="6"
                  onValueChange={handleQuantityChange}
                  overrideValue={numberInputError ? cartItem.quantity : undefined}
                />
              )}
            </Flex>
            <Icon
              data-test="close-modal"
              _hover={{ cursor: 'pointer' }}
              aria-label={CLOSE_MODAL}
              as={FiX}
              color="grey.5"
              fontSize="30px"
              onClick={handleClose}
            />
          </Flex>
        </DrawerSection>
        <DrawerBody padding="0">
          {unlockedItems.length > 0 && (
            <OtherItems
              heading={unlockedItemsHeading}
              onClose={handleClose}
              shopitems={unlockedItems}
            />
          )}
          {otherProducts.length > 0 && (
            <OtherItems
              heading={otherProductsHeading}
              onClose={handleClose}
              shopitems={otherProducts.slice(0, 6)}
            />
          )}
        </DrawerBody>
        <DrawerFooter borderColor="grey.2" borderTopWidth="1px">
          <VStack marginBottom={4} marginX={5} width="100%">
            <Flex justifyContent="space-between" marginBottom={4} width="100%">
              <Text fontSize="sm">{subtotalText}</Text>
              <Text fontFamily="heading" fontSize="xl">
                {formatCurrency(summarySubtotal, currency)}
              </Text>
            </Flex>
            <Button
              data-test="cart-item-drawer-view-cart-button"
              onClick={handleRouteToCart}
              variant="borderLarge"
              width="100%"
            >
              {viewCart}
            </Button>
            {Object.keys(cartMap).length === 1 && !cartErrors[key]?.length && (
              <Button
                data-test="cart-item-drawer-view-checkout-button"
                onClick={handleCheckout}
                variant="tertiary"
                width="100%"
              >
                {CONTINUE_TO_CHECKOUT}
              </Button>
            )}
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CartItemDrawer;
