import { intl } from './intl';

// -------------------------------------------------------------------------
// General
// -------------------------------------------------------------------------

export const ADDED = intl.formatMessage({
  id: 'carts.constants.added',
  defaultMessage: 'Added',
});

export const cartNoItems = intl.formatMessage({
  id: 'carts.constants.noItems',
  defaultMessage: 'There are no items in your cart',
});

export const CONTAINER_MAX_WIDTH = '1115px';

export const CONTINUE_TO_CHECKOUT = intl.formatMessage({
  id: 'carts.constants.continueToCheckout',
  defaultMessage: 'Continue to Checkout',
});

export const GO_TO_GALLERY = intl.formatMessage({
  id: 'carts.constants.gotoGallery',
  defaultMessage: 'Go to Gallery ',
});

export const NO_ITEMS_IN_CART = intl.formatMessage({
  id: 'carts.constants.noItems',
  defaultMessage: 'There are no items in your cart',
});

export const HIDE = intl.formatMessage({
  id: 'carts.constants.hide',
  defaultMessage: 'Hide',
});

export const SHOW = intl.formatMessage({
  id: 'carts.constants.show',
  defaultMessage: 'Show',
});

// -------------------------------------------------------------------------
// Multi
// -------------------------------------------------------------------------

export const ITEMS_CANNOT_BE_COMBINED = intl.formatMessage({
  id: 'carts.constants.multipleCheckoutsMsg',
  defaultMessage:
    'Some items in your cart cannot be combined because you are ordering from multiple galleries with different shipping or tax rates.',
});

export const MULTIPLE_CHECKOUTS_AVAILABLE = intl.formatMessage({
  id: 'carts.constants.multipleCheckoutsTitle',
  defaultMessage: 'Multiple Checkouts Available',
});

export const SUBJECT_WITH_COLON = intl.formatMessage({
  id: 'carts.constants.subject',
  defaultMessage: 'Subject: ',
});

export const SUBJECTS_WITH_COLON = intl.formatMessage({
  id: 'carts.constants.subjects',
  defaultMessage: 'Subjects: ',
});

// -------------------------------------------------------------------------
// Toasts
// -------------------------------------------------------------------------

export const updatedAssociatedOffers = intl.formatMessage({
  id: 'carts.constants.updatedAssociatedOffers',
  defaultMessage: 'Any associated offers or discounts have been updated.',
});

export const removedCartItem = intl.formatMessage({
  id: 'carts.constants.removedCartItem',
  defaultMessage: 'Removed',
});

export const updatedCartItem = intl.formatMessage({
  id: 'carts.constants.updatedCartItem',
  defaultMessage: 'Updated',
});

// -------------------------------------------------------------------------
// Errors
// -------------------------------------------------------------------------

export const MIN_ORDER_AMT_ERROR = 'financials.minOrderAmt';

export const REQUIRED_ITEM_ERROR = 'cart.requiredItem';
