import { Flex, Icon, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { BiSlider } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { AccountGallery } from '../../../../shop-api-client/models/AccountGallery';
import Select from '../../../shared/components/Select';
import { sort } from '../../../shared/utils';

const SELECT_ALPHABETICAL = 'alphabetical';
const SELECT_EXPIRATION = 'expiration';
const SELECT_NEWEST = 'newest';
const SELECT_OLDEST = 'oldest';
const SELECT_REVERSE_ALPHABETICAL = 'reverseAlphabetical';

interface Props {
  galleries: AccountGallery[];
  setSortedGalleries(galleries: AccountGallery[]): void;
}

const GalleriesSortSelect = ({ galleries, setSortedGalleries }: Props) => {
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const sortByText = intl.formatMessage({
    id: 'accountLanding.sortBy',
    defaultMessage: 'Sort by:',
  });
  // Select option text
  const alphabeticalText = intl.formatMessage({
    id: 'accountLanding.alphabetical',
    defaultMessage: 'Alphabetical',
  });
  const expirationText = intl.formatMessage({
    id: 'accountLanding.expirationDate',
    defaultMessage: 'Expiration Date',
  });
  const mostRecentText = intl.formatMessage({
    id: 'accountLanding.mostRecent',
    defaultMessage: 'Most Recent',
  });
  const oldestText = intl.formatMessage({
    id: 'accountLanding.oldest',
    defaultMessage: 'Oldest',
  });
  const reverseAlphaText = intl.formatMessage({
    id: 'accountLanding.reverseAlpha',
    defaultMessage: 'Reverse (Z-A)',
  });

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    const sortedGalleries = [...galleries].sort((a, b) => {
      if (value === SELECT_ALPHABETICAL) {
        return sort(a.title, b.title);
      }
      if (value === SELECT_REVERSE_ALPHABETICAL) {
        return sort(b.title, a.title);
      }
      if (value === SELECT_OLDEST) {
        return sort(a.eventDate, b.eventDate);
      }
      if (value === SELECT_EXPIRATION) {
        return sort(a.expirationDate, b.expirationDate);
      }
      return sort(b.eventDate, a.eventDate);
    });

    setSortedGalleries(sortedGalleries);
  };

  return (
    <Flex align="center">
      {!isMobile && <Text fontSize="sm" lineHeight="1.8">{`${sortByText}\u00A0`}</Text>}
      <Select
        defaultValue={SELECT_NEWEST}
        fontFamily="ITC Avant Garde Gothic Demi"
        fontSize="sm"
        icon={isMobile ? <Icon as={BiSlider} fontSize="3xl" /> : undefined}
        onChange={handleSelectChange}
        width={{ base: '32px', md: '140px' }}
        data-test="gallery-sort-select"
      >
        <option value={SELECT_NEWEST}>{mostRecentText}</option>
        <option value={SELECT_OLDEST}>{oldestText}</option>
        <option value={SELECT_ALPHABETICAL}>{alphabeticalText}</option>
        <option value={SELECT_REVERSE_ALPHABETICAL}>{reverseAlphaText}</option>
        <option value={SELECT_EXPIRATION}>{expirationText}</option>
      </Select>
    </Flex>
  );
};

export default GalleriesSortSelect;
