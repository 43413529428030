import { Flex, Menu } from '@chakra-ui/react';
import React from 'react';
import MyGalleries from '../../Navigation/DesktopNavigation/DesktopDropdown/MyGalleries';
import UserWelcome from '../../Navigation/DesktopNavigation/DesktopDropdown/UserWelcome';

const AvailableGalleries = () => {
  return (
    <Flex justifyContent="center" direction="column" padding="10px 20px 0 20px">
      <UserWelcome />

      <Menu autoSelect={false}>
        <MyGalleries />
      </Menu>
    </Flex>
  );
};
export default AvailableGalleries;
