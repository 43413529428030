import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import ActiveImageOverlay, { ActiveImageOverlayProps } from '../../ActiveImageOverlay';

interface Props extends BoxProps {
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  overlayProps?: ActiveImageOverlayProps;
  showOverlay?: boolean;
  size: string;
  url: string;
  'data-test'?: string;
}

const Swatch = ({
  onClick,
  overlayProps,
  showOverlay = false,
  size,
  url,
  'data-test': dataTest,
  ...rest
}: Props) => (
  <Box
    background={url}
    backgroundPosition="center"
    backgroundSize="cover"
    borderRadius="8px"
    cursor="pointer"
    height={size}
    onClick={onClick}
    width={size}
    data-test={dataTest}
    {...rest}
  >
    {showOverlay && <ActiveImageOverlay borderRadius="8px" badgeSize="16px" {...overlayProps} />}
  </Box>
);

export default Swatch;
