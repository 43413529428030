export const DISCOUNT_SEGMENTS = {
  1: 'order',
  2: 'shipping',
  3: 'handling',
};

export const PACKAGE_TYPES = {
  STANDARD: 'standard',
  BUILDYOUROWN: 'buildYourOwn',
};

export const PRODUCT_TYPES = {
  IMAGEDOWNLOAD: 'imageDownload',
  COLLECTION: 'collection',
  NONPRINTPRODUCT: 'nonPrintProduct',
  PRODUCT: 'product',
};
