import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAccount } from '../../redux/selectors/account.selectors';
import { selectInteractions } from '../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../redux/selectors/priceSheet.selectors';
import { selectVisitor } from '../../redux/selectors/visitor.selectors';
import Logo from '../../shared/components/Logo';
import CopyrightText from './CopyrightText';
import FooterLink from './FooterLink';

const Footer = () => {
  const { categories, sortedCategoryIDs } = useSelector(selectPriceSheet);
  const { company, logoImage } = useSelector(selectAccount);
  const { currentVisitKey, isLoggedIn } = useSelector(selectVisitor);
  const { showFooter } = useSelector(selectInteractions);
  const { pathname } = useLocation();
  const intl = useIntl();

  const isCheckout = pathname.includes('checkout');

  const handleLinkClick = () => window.scrollTo(0, 0);

  if (!isLoggedIn || pathname.includes('/galleries/available')) {
    return null;
  }

  const renderCategories = () => {
    if (!sortedCategoryIDs.length) {
      return null;
    }

    return (
      <Flex
        flexFlow="column wrap"
        maxH={{ base: '100%', md: '120px' }}
        maxW={{ base: '100%', md: '30vw' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        <FooterLink
          key="All"
          display={intl.formatMessage({ id: 'footer.all', defaultMessage: 'All' })}
          onClick={handleLinkClick}
          to={`/${currentVisitKey}/shop/all`}
          width="160px"
        />
        {sortedCategoryIDs.map(categoryID => (
          <FooterLink
            key={categoryID}
            display={categories[categoryID].name}
            onClick={handleLinkClick}
            to={`/${currentVisitKey}/shop/${categoryID}`}
            width="160px"
          />
        ))}
      </Flex>
    );
  };

  return (
    <Flex
      backgroundColor="grey.1"
      direction="column"
      display={showFooter ? undefined : 'none'}
      paddingTop={isCheckout ? '5px' : '30px'}
      paddingX="10%"
      width="100%"
    >
      {!isCheckout && (
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Flex
            flexGrow={1}
            justify={{ base: 'center', md: 'left' }}
            marginBottom="20px"
            minWidth="40px"
          >
            <Logo
              fallbackFontSize="sm"
              fallbackMargin={3}
              fallbackText={company}
              imageSrc={logoImage}
              margin="10px"
              maxHeight="40px"
            />
          </Flex>
          <Flex
            direction="column"
            flexGrow={{ base: 1, md: 2 }}
            fontSize="12px"
            marginBottom="20px"
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Heading fontWeight="900" marginY="10px" size="xs">
              {intl.formatMessage({ id: 'footer.shop', defaultMessage: 'SHOP' })}
            </Heading>
            {renderCategories()}
          </Flex>
        </Flex>
      )}
      <Flex>
        <CopyrightText company={company} />
      </Flex>
    </Flex>
  );
};

export default Footer;