import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends FlexProps {
  children: ReactNode;
  imageSize?: number;
  gridWidth?: number;
}

const GridDynamic = ({ children, imageSize = 0, gridWidth = 0, ...rest }: Props) => {
  const maxW = `${gridWidth - (gridWidth % imageSize)}px` || '100%';

  return (
    <Flex flexFlow="row wrap" maxW={maxW} margin="0 auto" {...rest}>
      {children}
    </Flex>
  );
};

export default GridDynamic;
