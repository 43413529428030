import { AspectRatio, Box, LayoutProps } from '@chakra-ui/react';
import React from 'react';
import { ShopPackage, ShopProduct } from '../../../../../../../shop-api-client';
import ProductImage from '../../../../ProductImage';

interface Props {
  minWidth: LayoutProps['minWidth'];
  product: ShopProduct | ShopPackage;
  src?: string;
}

const DisplayImage = ({ minWidth, src, product }: Props) => {
  return (
    <AspectRatio
      alignItems="center"
      backgroundColor="grey.1"
      justifyContent="center"
      marginY={2}
      minWidth={minWidth}
      ratio={1}
    >
      {/* TODO SC-301: onClick will open a details modal that must be built */}
      <Box height="100%" onClick={() => {}} width="100%">
        <ProductImage
          fallbackFontSize="xx-small"
          fallbackIconSize="sm"
          height="100%"
          image={src || ''}
          product={product}
          isSquare
        />
      </Box>
    </AspectRatio>
  );
};

export default DisplayImage;
