import React from 'react';
import { Box, Text, Button, Badge, InputProps, Tooltip } from '@chakra-ui/react';
import { EDIT, ON_FILE, USING } from '../../../constants';

interface Props extends InputProps {
  label: string | null;
  onEdit: () => void;
  updated?: boolean;
  updatedText?: string;
}

const textColor = '#737475';
const textSize = '16px';
const pillBg = '#e7e7e7';

const TextNodeDisplay = ({
  label,
  onEdit,
  marginTop,
  updated = false,
  updatedText = '',
  width,
}: Props) => {
  const accessibleLabel = label || 'Label not provided';

  return (
    <Box
      display="flex"
      alignItems="center"
      border="2px solid"
      borderColor="#bebfc0"
      borderRadius="8px"
      position="relative"
      width={width}
      marginTop={marginTop}
      padding="11.5px"
      paddingRight="21px"
      paddingLeft="14px"
      maxHeight="55px"
    >
      {!updated ? (
        <Box
          display="flex"
          alignItems="center"
          maxWidth="calc(100% - 50px)"
          overflow="hidden"
          flexGrow={1}
        >
          <Text color={textColor} fontSize={textSize} flexShrink={0}>
            {USING}
          </Text>
          <Tooltip label={accessibleLabel} aria-label={accessibleLabel} hasArrow>
            <Badge
              data-test={`${label}-text-node-label`}
              px={4}
              py={1.5}
              bg={pillBg}
              borderRadius="full"
              color={textColor}
              fontStyle="italic"
              fontWeight="bold"
              fontSize={textSize}
              marginLeft="8px"
              marginRight="8px"
              textTransform="none"
              maxWidth="100%"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {label}
            </Badge>
          </Tooltip>
          <Text color={textColor} fontSize={textSize} flexShrink={0} whiteSpace="nowrap">
            {ON_FILE}
          </Text>
        </Box>
      ) : (
        <Text color="#1a202c" fontSize={textSize} flexShrink={0} whiteSpace="nowrap">
          {updatedText}
        </Text>
      )}
      <Button
        data-test={`${label}-text-node-edit-button`}
        variant="link"
        color="#017dc0"
        onClick={onEdit}
        position="absolute"
        right={0}
        width="72px"
        height="35px"
        minWidth="72px"
        minHeight="35px"
      >
        {EDIT}
      </Button>
    </Box>
  );
};

export default TextNodeDisplay;
