import { Divider } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Pill from '../../../../shared/components/Pill';
import SidebarSection from '../../../../shared/components/SidebarSection';
import { FILTER } from '../../../../shared/constants';
import { CLEAR_FILTERS } from '../../../../shared/constants/labels.constants';
import { INTL_AVAILABILITY_FILTER_LABELS, PRODUCT_TYPE_FILTER_LABELS } from '../../constants';

interface Props {
  filters: string[];
  onToggle(filter: string): void;
}

const FilterPillsSection = ({ filters, onToggle }: Props) => {
  const history = useHistory();

  const handleClearFilters = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(FILTER);

    history.replace({ search: `${searchParams}` });
  };

  return (
    <>
      <SidebarSection
        direction="row"
        heading="Filters"
        paddingLeft="35px"
        actionName={filters.length ? CLEAR_FILTERS : ''}
        actionOnClick={handleClearFilters}
        isCollapsible
      >
        {filters.map(filter => (
          <Pill
            key={filter}
            label={PRODUCT_TYPE_FILTER_LABELS[filter] || INTL_AVAILABILITY_FILTER_LABELS[filter]}
            onClick={() => onToggle(filter)}
          />
        ))}
      </SidebarSection>
      <Divider variant="sidebar" />
    </>
  );
};

export default FilterPillsSection;
