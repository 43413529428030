import { addDays, format, parse, subMinutes } from 'date-fns';
import { getLocalDate } from '../../shared/utils';

export const getDueDateEOD = (dueDate: string) => {
  const dueDateAsDate = parse(dueDate, 'yyyy-MM-dd', new Date());
  const endOfDay = subMinutes(addDays(dueDateAsDate, 1), 1);
  return format(endOfDay, 'yyyy-MM-dd HH:mm');
};

export const hasDueDatePassed = (dueDate: string | null | undefined) => {
  if (!dueDate) {
    return false;
  }
  const dueDateEOD = getDueDateEOD(dueDate);
  const localDate = getLocalDate(dueDateEOD);
  const localNow = getLocalDate();

  return localDate.valueOf() < localNow.valueOf();
};
