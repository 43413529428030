import { Heading } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ShopBackground,
  ShopImage,
  ShopProductCollection,
} from '../../../../../../../shop-api-client';
import {
  CartCollection,
  CartCollectionReq,
  CartDownload,
  CartDownloadReq,
  CollectionImage,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectCart } from '../../../../../../redux/selectors/cart.selectors';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import {
  setCompletedPkgItems,
  setInvalidStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import {
  addEditProductCollectionImgs,
  updateEditProductCollectionImgs,
} from '../../../../../../redux/thunks/configuration.thunks';
import ImageStepHeadings from '../../../../../../shared/components/ImageStepHeadings';
import { Step } from '../../../../../../shared/components/ImageStepHeadings/ImageStepHeadings';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import { ImageAndBackground } from '../../../../../../shared/types/image';
import { Params } from '../../../../../../shared/types/router';
import { FAVORITES, SELECT_YOUR_PHOTO, SELECT_YOUR_PHOTOS } from '../../../constants';
import CollectionSelection from '../../../shared/CollectionSelection';
import CollectionSelectionBackgroundWrapper from '../../../shared/CollectionSelection/CollectionSelectionBackgroundWrapper';
import ImageNodeBackgroundDisplay from '../../../shared/ImageNodesEditor/ImageNodeBackgroundDisplay';
import ImageNodeDisplay from '../../../shared/ImageNodesEditor/ImageNodeDisplay';
import ImageNodeDisplayPreset from '../../../shared/ImageNodesEditor/ImageNodeDisplay/ImageNodeDisplayPreset';
import { shapeCollectionImage } from '../../../utils';
import PackagePoseModal from '../PackagePoseModal';
import { PoseToSwap } from '../PackagePoseModal/PackagePoseModal';

interface Props {
  activeStep: string;
  editProduct: CartCollection | CartDownload | CartCollectionReq | CartDownloadReq;
  poseLimit: number | undefined;
  poseMap: Record<string, number[]>;
  shopProduct: ShopProductCollection;
}

const PackageCollection = ({ activeStep, editProduct, poseLimit, poseMap, shopProduct }: Props) => {
  const { key } = useParams<Params>();
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const { completedPkgItems, editPackageStep, invalidSteps } = useAppSelector(selectConfiguration);
  const { images, isGreenScreen } = useAppSelector(state => selectGallery(state, key));
  const { shopFavorites } = useAppSelector(state => selectCart(state, key));

  const [selectedImage, setSelectedImage] = useState('');
  const [newPose, setNewPose] = useState<PoseToSwap | null>(null);
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const poseLimitReached = poseLimit && Object.keys(poseMap).length >= poseLimit;

  // Misc vars
  const singlePoseGS =
    Object.keys(images).length === 1 && isGreenScreen && Object.keys(backgroundsMap).length > 0;
  const steps: Step[] = isGreenScreen && !singlePoseGS ? ['photo', 'background'] : ['photo'];
  const { imageRequirementType, includeAll, maxImages, minImages } = shopProduct;
  const { collectionImages } = editProduct;

  const toggleCollectionImage = (image: ShopImage, background?: ShopBackground) => {
    const compareImage = (ci: CollectionImage) =>
      ci.internalName === image.internalName && (ci.backgroundID || undefined) === background?.id;

    const found = collectionImages.some(compareImage);
    let updatedSelection: CollectionImage[] = collectionImages.filter(ci => !!ci.internalName);

    let shouldUpdateSelection = true;

    // Key to lookup in the existing poses map
    const key = background ? `${image.internalName}-${background.id}` : image.internalName;

    if (poseLimitReached && !found && imageRequirementType !== 'group' && !poseMap[key]) {
      // We need to ask the user what pose they want to replace
      setNewPose({ imageName: image.internalName, backgroundID: background?.id });
      shouldUpdateSelection = false;
    }

    if (found) {
      // `updatedSelection` is updated to de-select a collection image:
      updatedSelection = collectionImages.reduce<CollectionImage[]>((result, ci) => {
        // If the current image is the one being de-selected...
        if (compareImage(ci)) {
          // If more than one collection image, return result to filter out the de-selected image
          if (collectionImages.length > 1) {
            return result;
          }

          // Otherwise, the de-selected collection image gets replaced with the default entry
          result.push(shapeCollectionImage(null));
        } else {
          result.push(ci);
        }

        return result;
      }, []);
    } else if (maxImages === 1) {
      updatedSelection = [shapeCollectionImage(image, background)];
    } else {
      updatedSelection = [...updatedSelection, shapeCollectionImage(image, background)];
    }

    const selectionCount = updatedSelection.filter(s => !!s.internalName).length;

    if (selectionCount <= maxImages && shouldUpdateSelection) {
      dispatch(updateEditProductCollectionImgs(editProduct.id!, updatedSelection));
    }

    // The item is invalid if it was completed and the minimum is no longer met
    const isInvalid = completedPkgItems[editProduct.id!] && selectionCount < minImages;

    // If valid state has changed, update:
    if (isInvalid !== invalidSteps[editPackageStep!]) {
      dispatch(setInvalidStep({ [editPackageStep!]: isInvalid }));
    }

    // If invalid, set completed state to false for the item:
    if (isInvalid) {
      dispatch(setCompletedPkgItems({ ...completedPkgItems, [editProduct.id!]: false }));
    }
  };

  const getHeading = () => {
    if (includeAll) {
      return undefined;
    }

    return maxImages === 1 ? SELECT_YOUR_PHOTO : SELECT_YOUR_PHOTOS;
  };

  const getSelectedImages = () =>
    collectionImages.reduce<Record<string, ImageAndBackground>>((map, ci) => {
      // Explicitly use `undefined` instead of `null` here
      // This will allow ImageNodeDisplay to correctly associate the image
      const key = `${ci.internalName}-${ci.backgroundID || 'undefined'}`;
      map[key] = { imageName: ci.internalName!, backgroundID: ci.backgroundID };
      return map;
    }, {});

  const handleStep = (step: Step) => {
    if (step === 'background' && !selectedImage) {
      // TODO: Show invalid state
    } else {
      // Reset back to photo state
      setSelectedImage('');
    }
  };

  const handleSwapPose = () => {
    if (!newPose) {
      return;
    }

    const { imageName, backgroundID } = newPose;
    dispatch(
      addEditProductCollectionImgs(
        editProduct.id!,
        shapeCollectionImage(
          images[imageName],
          backgroundID ? backgroundsMap[backgroundID] : undefined,
        ),
      ),
    );
  };

  const selectImage = (image: ShopImage, background?: ShopBackground) => {
    if ((image && background) || !image.isGreenScreen || !isGreenScreen) {
      toggleCollectionImage(image, background);
    } else {
      setSelectedImage(image.internalName);
    }
  };

  const renderImageDisplay = () => {
    if (includeAll) {
      return null;
    }

    // If there is only one image, and it's a green screen gallery, show the backgrounds alongside the image:
    if (singlePoseGS) {
      const selectedBackgrounds = collectionImages.map(ci => ci.backgroundID!);
      const selectedImages = collectionImages.reduce<Record<string, ImageAndBackground>>(
        (result, ci) => {
          result[`${ci.internalName}-${ci.backgroundID}`] = {
            imageName: ci.internalName!,
            backgroundID: ci.backgroundID,
          };
          return result;
        },
        {},
      );

      return (
        <>
          <ImageNodeDisplayPreset
            heading={FAVORITES}
            imageMaxHeight="150px"
            imageMaxWidth="100px"
            imageRequirementType={imageRequirementType}
            presetImages={shopFavorites}
            selectedImages={selectedImages}
            selectImage={selectImage}
          />
          <ImageNodeBackgroundDisplay
            columns={3}
            image={Object.values(images)[0]}
            selectBackground={selectImage}
            selectedBackgrounds={selectedBackgrounds}
          />
        </>
      );
    }

    if (selectedImage) {
      return (
        <CollectionSelectionBackgroundWrapper
          addOrDelFromCollection={toggleCollectionImage}
          collectionImages={collectionImages}
          columns={3}
          image={images[selectedImage]}
        />
      );
    }

    return (
      <ImageNodeDisplay
        imageRequirementType={imageRequirementType}
        selectedImages={getSelectedImages()}
        selectImage={selectImage}
        showErrorOutline={invalidSteps[editPackageStep!]}
      />
    );
  };

  const renderPoseModal = () => {
    if (!newPose) {
      return null;
    }

    return (
      <PackagePoseModal
        onClose={() => setNewPose(null)}
        onConfirm={handleSwapPose}
        poseMap={poseMap}
        newPose={newPose}
      />
    );
  };

  if (isMobile) {
    return (
      <CollectionSelection
        containerProps={{ marginTop: -6 }}
        editProduct={editProduct}
        hideSave
        isInvalid={invalidSteps[activeStep!]}
        poseLimit={poseLimit}
        poseMap={poseMap}
        shopProduct={shopProduct}
      />
    );
  }

  return (
    <>
      <Heading size="md" marginX="auto">
        {getHeading()}
      </Heading>
      <ImageStepHeadings
        activeStep={selectedImage ? 'background' : 'photo'}
        disabled={[]}
        handleSelectStep={handleStep}
        position="top"
        steps={steps}
      />
      {renderImageDisplay()}
      {renderPoseModal()}
    </>
  );
};

export default PackageCollection;
