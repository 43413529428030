import {
  api,
  ProductOption,
  ShopBackground,
  ShopImage,
  ShopPackage,
  ShopProduct,
  ShopProductCollection,
} from '../../../shop-api-client';
import {
  CartError,
  CartImage,
  CartImageOption,
  CartPackage,
  CartProduct,
  CartProductOptionReq,
  CreateCartImage,
  CreateCartPackageReq,
  CreateCartProductReq,
  ShopFavorite,
  UpdateCartPackageReq,
  UpdateCartProductReq,
  VisitWithCart,
} from '../../../shop-api-client/models/Cart';
import {
  CartFinancialsWithGallery,
  filterEmptyCarts,
  getAllBackgroundFees,
  getCartItemsFromCategory,
  getCartItemsSubtotalWithExclusions,
  getCartsByPriceSheetID,
  getDigitalDownloadSubtotal,
  getImageOptionFees,
  getMultiCartFinSum,
  getShipping,
  getSubtotal,
  getSubtotalWithExclusions,
  getTaxes,
} from '../../features/Carts/utils';
import {
  isConfigurable,
  shapeCollectionImage,
  shapeEditPackage,
  shapeEditProduct,
  shapeNodesForItem,
} from '../../features/Products/Configuration/utils';
import { toast } from '../../shared/components/Toast/Toast';
import { intl } from '../../shared/constants';
import {
  removedCartItem,
  updatedAssociatedOffers,
  updatedCartItem,
} from '../../shared/constants/carts.constants';
import {
  selectCartImageMap,
  selectCartItem,
  selectUniqueImagesOnCartItem,
} from '../selectors/cart.selectors';
import { selectEditItemImageMap } from '../selectors/configurations.selectors';
import { selectPriceSheetItem } from '../selectors/priceSheet.selectors';
import {
  removeCartOption,
  removeCartPackage,
  removeCartProduct,
  setCartErrors,
  setCartImageOption,
  setCartMap,
  setCartPackage,
  setCartProduct,
  setFavorites,
  setFinancials,
  setIsSubmittingCart,
  setSummaryFinancials,
} from '../slices/cart.slice';
import { OffersStatusMap, setOffersStatusMap } from '../slices/gallery.slice';
import { AppThunk, PromiseThunk, Thunk } from '../store';

export const autoAddItems =
  (visitKey: string, showToast: boolean, skipJustAdded: boolean = false): Thunk =>
  async (dispatch, getState) => {
    const { galleryMap, priceSheetMap } = getState().gallery;
    const { cartMap } = getState().cart;
    const { isGreenScreen, isPreOrder, priceSheetID } = galleryMap[visitKey];
    const {
      backgroundSets,
      categories,
      offersStatusMap,
      preOrderBackgroundSelectionType,
      productCategoryMap,
      productNodeMap,
      products,
    } = priceSheetMap[priceSheetID];
    const { cartPackages, cartProducts } = cartMap[visitKey];
    const filteredCategories = Object.values(categories).filter(
      c =>
        ['atLeastOne', 'singleRequired'].includes(c.selectionType) &&
        productCategoryMap[c.id].length === 1 &&
        !offersStatusMap[c.id]?.isLocked,
    );

    for (const category of filteredCategories) {
      const cartItems = [...cartPackages, ...cartProducts];
      const itemID = productCategoryMap[category.id][0];
      const item = products[itemID];

      const hasBackgrounds = Object.keys(backgroundSets).length > 0;
      const isFree = item.price === 0;
      const isInCart = !!cartItems.find(i => i.priceSheetItemID === item.id);
      const isNonPrint = item.type === 'nonPrintProduct';
      const perOrderBG = preOrderBackgroundSelectionType === 'perOrder';
      const requiresBG = !isNonPrint && isGreenScreen && hasBackgrounds && !perOrderBG;

      // If the item is not free, is already in the cart, or requires manual configuration from the
      // visitor, skip:
      if (!isFree || isInCart || isConfigurable(isPreOrder, item, productNodeMap) || requiresBG) {
        continue;
      }
      const cartItem =
        item.type === 'package'
          ? shapeEditPackage(item, productNodeMap)
          : shapeEditProduct(item as ShopProduct, productNodeMap);
      const toastTitle = intl.formatMessage(
        {
          id: 'visitor.thunks.freeItemAdded',
          defaultMessage: 'Free {item} was added to your cart!',
        },
        { item: item.name },
      );

      await dispatch(addCartItem(cartItem, visitKey, true, skipJustAdded));

      if (showToast) {
        toast({ title: toastTitle });
      }
    }
  };

export const getCartSummary =
  (visitKey?: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    try {
      const cartMap = await api.getCarts(visitKey || currentVisitKey!);

      // Dispatch cart summaries into Redux
      await dispatch(setCartMap(cartMap));
      await dispatch(getCartFinancials(visitKey || currentVisitKey!));

      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

export const managePostCartChange =
  (visitKey: string): Thunk =>
  async dispatch => {
    try {
      await dispatch(getCartSummary());
      dispatch(manageLockedCategories(visitKey));
    } catch (error) {
      toast({
        title: 'There was an issue loading your cart. Please refresh to get the latest changes',
      });
      return { valid: false, payload: null, error };
    }
  };

export const addSelectAndBuyItemToCart =
  (product: ShopPackage | ShopProduct): PromiseThunk<CartProduct | CartPackage | null> =>
  async (dispatch, getState) => {
    const { buyModeBackground, buyModeImage } = getState().interactions;
    const { currentVisitKey } = getState().visitor;
    const gallery = getState().gallery.galleryMap[currentVisitKey!];
    const { productNodeMap } = getState().gallery.priceSheetMap[gallery.priceSheetID];
    const { images } = gallery;

    try {
      if (!buyModeImage) {
        return { valid: false, payload: null, error: 'Item could not be added to cart' };
      }

      const createCartProduct = (product: ShopProduct) => {
        const newProduct = {
          priceSheetItemID: product.id,
          quantity: 1,
          type: product.type,
        } as CreateCartProductReq;

        if (
          newProduct.type === 'product' &&
          product.type === 'product' &&
          productNodeMap[product.catalogProductID]
        ) {
          newProduct.nodes = shapeNodesForItem(
            gallery,
            product,
            productNodeMap,
            buyModeImage,
            buyModeBackground || undefined,
          );
        } else if (newProduct.type === 'collection' || newProduct.type === 'imageDownload') {
          const { includeAll } = product as ShopProductCollection;

          if (includeAll) {
            newProduct.collectionImages = Object.values(images).map(image =>
              shapeCollectionImage(image, buyModeBackground || undefined),
            );
          } else {
            newProduct.collectionImages = [
              shapeCollectionImage(images[buyModeImage], buyModeBackground || undefined),
            ];
          }
        }

        return newProduct;
      };

      const createCartPackage = (product: ShopPackage) => {
        const item = {
          priceSheetItemID: product.id,
          quantity: 1,
          type: product.type === 'package' ? 'standard' : 'buildYourOwn',
          products: [],
        } as CreateCartPackageReq;

        item.products = product.availableProducts.map(p => createCartProduct(p));
        return item;
      };

      let newCartItem: CreateCartPackageReq | CreateCartProductReq;

      if (product.type === 'package' || product.type === 'package-byop') {
        newCartItem = createCartPackage(product);
      } else {
        newCartItem = createCartProduct(product);
      }

      return dispatch(addCartItem(newCartItem, undefined));
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

/**
 * Adds a shop item to the visitor's cart and stores the result in Redux
 */
export const addCartItem =
  (
    item: CreateCartProductReq | CreateCartPackageReq,
    visitKey?: string,
    skipAutoAdd?: boolean,
    skipJustAdded?: boolean,
  ): PromiseThunk<CartProduct | CartPackage | null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    const key = visitKey || currentVisitKey!;

    dispatch(setIsSubmittingCart(true));
    try {
      const { accountID } = getState().account;
      const shopItem = selectPriceSheetItem(getState(), item.priceSheetItemID, key);
      let payload: CartPackage | CartProduct | null = null;

      if (!shopItem) {
        return { payload, valid: false, error: 'Shop Item not found' };
      }

      /**
       * Returns the cart item with the temporary id stripped to prepare it for inserting to DB
       */
      const prepareCartItem = (cartItem: CreateCartProductReq | CreateCartPackageReq) => {
        const copy = { ...cartItem };
        delete copy.id;

        if (copy.type === 'buildYourOwn' || copy.type === 'standard') {
          copy.products = copy.products.map(p => prepareCartItem(p) as CreateCartProductReq);
        }
        return copy;
      };

      const clonedCartItem = prepareCartItem(item);

      const invalidOptions = validateCartProductOptions(item, shopItem);
      if (Object.keys(invalidOptions).length) {
        return { payload: null, valid: false, error: invalidOptions };
      }

      if (clonedCartItem.type === 'buildYourOwn' || clonedCartItem.type === 'standard') {
        payload = await api.createCartPackage(key, clonedCartItem);
        dispatch(
          setCartPackage({ cartPackage: payload, justAdded: !skipJustAdded, visitKey: key }),
        );
      } else {
        payload = await api.createCartProduct(key, clonedCartItem);
        dispatch(
          setCartProduct({ cartProduct: payload, justAdded: !skipJustAdded, visitKey: key }),
        );
      }

      //Google Analytics add_to_cart event
      window.gtag('event', 'add_to_cart', {
        items: [
          {
            item_id: payload.priceSheetItemID,
            item_name: payload.name,
            item_category: payload.type,
            affiliation: accountID,
            price: payload.unitPrice,
            quantity: payload.quantity,
          },
        ],
      });

      return { payload, valid: true, error: null };
    } catch (error) {
      const failedToAddProduct = intl.formatMessage({
        id: 'visitor.thunks.failedToAddProduct',
        defaultMessage: 'Product could not be added to cart',
      });
      const failedToAddPackage = intl.formatMessage({
        id: 'visitor.thunks.failedToAddPackage',
        defaultMessage: 'Package could not be added to cart',
      });
      const isPackage = item.type === 'buildYourOwn' || item.type === 'standard';

      toast({ title: isPackage ? failedToAddPackage : failedToAddProduct }, error);

      return { valid: false, payload: null, error };
    } finally {
      await dispatch(saveCartImageOptions());
      await dispatch(managePostCartChange(key));

      if (!skipAutoAdd) {
        await dispatch(autoAddItems(key, false));
      }

      dispatch(setIsSubmittingCart(false));
    }
  };

/**
 * Validates that the visitor has made selections for required Product Options
 * @returns true if valid, false if invalid
 */
const validateCartProductOptions = (
  cartItem: CartProduct | CreateCartProductReq | CartPackage | CreateCartPackageReq,
  shopItem: ShopProduct | ShopPackage,
) => {
  const cartItemArray =
    cartItem.type === 'buildYourOwn' || cartItem.type === 'standard'
      ? cartItem.products
      : [cartItem];

  const cartItemMap = cartItemArray.reduce<{ [key: string]: boolean }>((map, item) => {
    map[item.priceSheetItemID] = true;
    return map;
  }, {});

  // Hashmap of cart product options keyed by catalog option group ID
  const cartOptionMap = cartItemArray.reduce<Record<string, CartProductOptionReq>>(
    (map, product) => {
      for (const option of product.options || []) {
        map[option.optionGroupID] = option;
      }
      return map;
    },
    {},
  );

  let productArray =
    shopItem.type === 'package' || shopItem.type === 'package-byop'
      ? shopItem.availableProducts
      : [shopItem];

  if (shopItem.type === 'package-byop') {
    // Filter product array to only chosen sub-items, so that options of unused products
    // are not validated:
    productArray = productArray.filter(p => cartItemMap[p.id]);
  }

  // Hashmap of product options keyed by catalog option group ID
  const productOptionMap = productArray.reduce<Record<string, ProductOption>>((res, product) => {
    for (const option of product.options || []) {
      res[option.catalogOptionGroupID] = option;
    }
    return res;
  }, {});

  const invalidOptionsMap = Object.values(productOptionMap).reduce<Record<string, boolean>>(
    (res, option) => {
      if (option.requirementType === 'required' && option.type !== 'boolean') {
        const match = cartOptionMap[option.catalogOptionGroupID];

        if (!match?.optionID) {
          res[option.id] = true;
        }
      }
      return res;
    },
    {},
  );

  // Check existing cart product options to verify that selection ID and value are present:
  for (const option of Object.values(cartOptionMap)) {
    const psOption = productOptionMap[option.optionGroupID];
    if (!option.optionID || (psOption.type === 'text' && !option.value)) {
      invalidOptionsMap[psOption.id] = true;
    }
  }

  return invalidOptionsMap;
};

export const updateCartItem =
  (
    item: UpdateCartProductReq | UpdateCartPackageReq,
    visitKey: string,
    withToast = true,
  ): PromiseThunk<CartProduct | CartPackage | null> =>
  async (dispatch, getState) => {
    const { cartMap } = getState().cart;
    const { cartProducts, cartPackages } = cartMap[visitKey];

    try {
      const shopItem = selectPriceSheetItem(getState(), item.priceSheetItemID, visitKey);
      const cartItem = selectCartItem(getState(), { id: item.id, type: item.type }, visitKey);
      let payload: CartPackage | CartProduct | null = null;

      if (!shopItem || !cartItem) {
        return { payload: null, valid: false, error: 'Item not found' };
      }
      const updatedItem = { ...cartItem, ...item } as CartProduct | CartPackage;
      const invalidOptions = validateCartProductOptions(updatedItem, shopItem);

      if (Object.keys(invalidOptions).length) {
        return { payload, valid: false, error: invalidOptions };
      }

      const handleUndo = (itemBeforeUpdate: CartProduct | CartPackage) => async () =>
        await dispatch(updateCartItem(itemBeforeUpdate, visitKey));

      if (item.type === 'buildYourOwn' || item.type === 'standard') {
        const packageBeforeUpdate = cartPackages.find(p => p.id === item.id);
        payload = await api.updateCartPackage(visitKey, item);
        dispatch(setCartPackage({ cartPackage: payload, visitKey }));

        if (withToast && packageBeforeUpdate) {
          toast({
            id: `updateCartItem-package-${payload.id}`,
            title: `${updatedCartItem} ${payload.name}`,
            onUndo: handleUndo(packageBeforeUpdate),
          });
        }
      } else {
        const productBeforeUpdate = cartProducts.find(p => p.id === item.id);
        payload = await api.updateCartProduct(visitKey, item as UpdateCartProductReq);
        dispatch(setCartProduct({ cartProduct: payload, visitKey }));

        if (withToast && productBeforeUpdate) {
          toast({
            id: `updateCartItem-product-${payload.id}`,
            title: `${updatedCartItem} ${payload.name}`,
            onUndo: handleUndo(productBeforeUpdate),
          });
        }
      }

      await dispatch(autoAddItems(visitKey, true, true));
      return { valid: true, payload, error: null };
    } catch (error) {
      const failedToUpdateCartItem = intl.formatMessage({
        id: 'visitor.thunks.failedToUpdateCartItem',
        defaultMessage: 'Cart item could not be updated',
      });
      toast({ title: failedToUpdateCartItem }, error);

      return { valid: false, payload: null, error };
    } finally {
      await dispatch(saveCartImageOptions());
      await dispatch(managePostCartChange(visitKey));
    }
  };

export const deleteCartItem =
  (item: CartPackage | CartProduct, visitKey: string): PromiseThunk =>
  async (dispatch, getState) => {
    try {
      const { accountID } = getState().account;
      if (['buildYourOwn', 'standard'].includes(item.type)) {
        await api.deleteCartPackage(visitKey, item.id);
        await dispatch(manageImageOptionsPostDelete(visitKey, item.id));
        dispatch(removeCartPackage({ cartPackageID: item.id, visitKey }));
      } else {
        await api.deleteCartProduct(visitKey, item.id);
        await dispatch(manageImageOptionsPostDelete(visitKey, item.id));
        dispatch(removeCartProduct({ cartProductID: item.id, visitKey }));
      }

      const handleUndo = () => {
        dispatch(calculateFinancials(item, visitKey, { add: true }));
        dispatch(addCartItem(item, visitKey));
      };

      toast({
        title: `${removedCartItem} ${item.name}`,
        description: updatedAssociatedOffers,
        onUndo: handleUndo,
      });

      // Google Anlaytics remove_from_cart Event for Products

      window.gtag('event', 'remove_from_cart', {
        items: [
          {
            item_id: item.priceSheetItemID,
            item_name: item.name,
            item_category: item.type,
            affiliation: accountID,
            quantity: item.quantity,
            price: item.unitPrice,
          },
        ],
      });

      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: true, payload: null, error };
    } finally {
      await dispatch(managePostCartChange(visitKey));
    }
  };

/**
 * reconciles the images between locally save values for an edit item and what's saved in cart
 * @param editItemImages string[] of imagesNames used in the editItem beign configured
 * @param cartIOImages array of cartImageOptionImages for an option selection
 * @param editIOImages array of Images saved locally on edit Image Options for an option selection
 * @returns
 */
const reconcileImagesOnImageOption = (
  editItemImages: string[],
  cartIOImages: CartImage[],
  editIOImages: CreateCartImage[],
  allCartImages: (string | null)[],
) => {
  // steps for reconciliation

  // remove unselected images
  // 1. diff = complement of images on edit item \ images on the edit image option
  const diff = editItemImages.filter(eii => !editIOImages.map(i => i.imageName).includes(eii));
  // 2. items to remove = intersection of diff images ∩ and the images on the cart image option
  const removeDelta = diff.filter(d => cartIOImages.map(i => i.imageName).includes(d));
  // 3. final = complement of cart image option images \ items to remove
  const removedFinal = cartIOImages.filter(cio => !removeDelta.includes(cio.imageName));

  // add net new ones
  // 4. get complement of images on edit option \ new cart option images
  const addDelta = editIOImages.filter(
    eio => !removedFinal.map(i => i.imageName).includes(eio.imageName!), // ! for ts, since it should never be null
  );
  const addedFinal = [...removedFinal, ...addDelta];

  // 5. final check to ensure that all images are on at least one cart item/the cart item being edited
  const final = addedFinal.filter(f =>
    [...editItemImages, ...allCartImages].includes(f.imageName!),
  );
  return final;
};

/**
 * Saves and reconciles all the current cart image options,
 * and locally saved OPTIONAL image option modifications on an 'Add To Cart' event
 * disclaimer: optional image options only
 */
export const saveCartImageOptions = (): PromiseThunk<null> => async (dispatch, getState) => {
  try {
    const { currentVisitKey } = getState().visitor;
    const { editImageOptionsMap } = getState().configuration;
    const { cartImageOptions } = getState().cart.cartMap[currentVisitKey!];
    const { isPreOrder } = getState().gallery.galleryMap[currentVisitKey!];
    const editItemImageMap = selectEditItemImageMap(getState());
    const editItemImages = Object.keys(editItemImageMap);
    let allCartImages: (string | null)[] = [];
    if (isPreOrder) {
      allCartImages = [null];
    } else {
      allCartImages = Object.keys(selectCartImageMap(getState(), currentVisitKey!));
    }

    for (const [psOptionID, optionSelectionsMap] of Object.entries(editImageOptionsMap)) {
      //TODO: an alternative to using null on a option group:
      // have an each selection for that option instead be assigned to a cart req with an empty []
      // this would mean everything can instead go through the else logic below

      // if the value is null, they have opted out and all current editItem images need to be removed from all current cart selections
      if (!optionSelectionsMap) {
        const cartMatches = cartImageOptions.filter(
          cio => cio.priceSheetOptionID === parseInt(psOptionID),
        );

        // if there are cart options matching the option group => reconcile images
        for (const cartIO of cartMatches) {
          const updatedImages = reconcileImagesOnImageOption(
            editItemImages,
            cartIO.images,
            [],
            allCartImages,
          );

          if (!updatedImages.length) {
            // if no more images after reconciling, delete
            await api.deleteCartImageOption(currentVisitKey!, cartIO.id);
          } else {
            const updatedCartIO = { ...cartIO, images: updatedImages } as CartImageOption;
            await api.updateCartImageOption(currentVisitKey!, updatedCartIO);
          }
        }
      } else {
        // if local selections that need to be managed for that option group
        // go through every selection on the option group
        for (const editOptionSelection of Object.values(optionSelectionsMap)) {
          const cartMatch = cartImageOptions.find(
            cio =>
              cio.optionID === editOptionSelection.optionID &&
              cio.priceSheetOptionID === editOptionSelection.priceSheetOptionID,
          );

          if (cartMatch) {
            const updatedImages = reconcileImagesOnImageOption(
              editItemImages,
              cartMatch.images,
              editOptionSelection.images,
              allCartImages,
            );

            if (!updatedImages.length) {
              // delete if no more images left after reconciliation
              await api.deleteCartImageOption(currentVisitKey!, cartMatch.id);
            } else {
              //could technically skip the update if the new images === current on the selection
              const updatedCartIO = { ...cartMatch, images: updatedImages } as CartImageOption;
              await api.updateCartImageOption(currentVisitKey!, updatedCartIO);
            }
          } else if (editOptionSelection.images.length) {
            // create a net new cart image option if no match and there are images for the option selection locally
            const images = reconcileImagesOnImageOption(
              editItemImages,
              [],
              editOptionSelection.images,
              allCartImages,
            );
            const newCartIO = { ...editOptionSelection, images } as CartImageOption;
            await api.createCartImageOption(currentVisitKey!, newCartIO);
          }
        }
      }
    }
    // TODO: update all cart options here - prob should just refetch them
    return { valid: true, payload: null, error: 'Error adding image options to cart' };
  } catch (error) {
    return { valid: false, payload: null, error };
  }
};

export const manageImageOptionsPostDelete =
  (visitKey: string, deleteItemID: number): Thunk =>
  async (dispatch, getState) => {
    const { isPreOrder } = getState().gallery.galleryMap[visitKey];
    const { cartImageOptions, cartPackages, cartProducts } = getState().cart.cartMap[visitKey];

    // if preorder and there's only a single cartproduct/package
    // delete all image options since we can't rely on image reconciliation
    if (isPreOrder && cartPackages.length + cartProducts.length <= 1) {
      for (const cartIO of cartImageOptions) {
        await api.deleteCartImageOption(visitKey!, cartIO.id);
        dispatch(removeCartOption({ cartOptionID: cartIO.id, visitKey }));
      }
      return;
    }

    // get the array of images that uniquely exist on the deleted product
    const deleteImages = selectUniqueImagesOnCartItem(getState(), visitKey, deleteItemID);

    // for every cart image option, remove any of the deleted images
    for (const cartIO of cartImageOptions) {
      const updatedImages = cartIO.images.filter(i => !deleteImages.includes(i.imageName));

      // delete if no more images on the image option, else update it
      if (!updatedImages.length) {
        await api.deleteCartImageOption(visitKey!, cartIO.id);
        dispatch(removeCartOption({ cartOptionID: cartIO.id, visitKey }));
      } else if (updatedImages.length !== cartIO.images.length) {
        const updatedIO = { ...cartIO, images: updatedImages };
        const cartOption = await api.updateCartImageOption(visitKey!, updatedIO);
        dispatch(setCartImageOption({ cartOption, visitKey }));
      }
    }
  };

export const deleteImageFromCartOption =
  (cartOptionID: number, deleteImage: string | null, visitKey: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { cartMap } = getState().cart;
    const cart = cartMap[visitKey];
    const match = cart.cartImageOptions.find(o => o.id === cartOptionID);

    try {
      if (match) {
        const images = match.images.filter(ci => ci.imageName !== deleteImage);

        if (!images.length) {
          await api.deleteCartImageOption(visitKey!, match.id);
          dispatch(removeCartOption({ cartOptionID: match.id, visitKey }));
        } else {
          const updatedIO = { ...match, images };
          const cartOption = await api.updateCartImageOption(visitKey!, updatedIO);
          dispatch(setCartImageOption({ cartOption, visitKey }));
        }
      }
      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    } finally {
      await dispatch(managePostCartChange(visitKey));
    }
  };

/**
 * only use for pre-order
 */
export const deleteCartOption =
  (cartOptionID: number, visitKey: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { accountID } = getState().account;
    const { cartMap } = getState().cart;
    const cart = cartMap[visitKey];
    const cartOption = cart.cartImageOptions.find(o => o.id === cartOptionID);

    try {
      if (!cartOption) {
        return { valid: false, payload: null, error: 'Could not find cart option' };
      }
      await api.deleteCartImageOption(visitKey, cartOptionID);
      dispatch(removeCartOption({ cartOptionID, visitKey }));

      // //Google Analytics remove_from_cart Event for Options

      window.gtag('event', 'remove_from_cart', {
        items: [
          {
            item_id: cartOption?.priceSheetOptionID,
            item_name: cartOption?.name,
            item_category: cartOption?.type,
            affiliation: accountID,
            quantity: cartOption?.quantity,
            price: cartOption?.unitPrice,
          },
        ],
      });

      return { valid: true, payload: null, error: null };
    } catch (error) {
      const failedToRemoveOption = intl.formatMessage({
        id: 'visitor.thunks.failedToRemoveOption',
        defaultMessage: 'Image option could not be removed from cart',
      });
      toast({ title: failedToRemoveOption }, error);

      return { valid: false, payload: null, error };
    } finally {
      await dispatch(managePostCartChange(visitKey));
    }
  };

export const manageLockedCategories =
  (visitKey: string): Thunk =>
  (dispatch, getState) => {
    const { cartMap } = getState().cart;
    const { galleryMap, priceSheetMap } = getState().gallery;
    const { images, priceSheetID, type } = galleryMap[visitKey];
    const {
      backgroundSets,
      categories,
      imageOptionMap,
      productCategoryMap,
      productNodeMap,
      products,
    } = priceSheetMap[priceSheetID];

    const cart = cartMap[visitKey];
    if (!cart) {
      return;
    }

    const cartsWithSamePS = getCartsByPriceSheetID(priceSheetID, cartMap, galleryMap);
    const updatedStatusMap: OffersStatusMap = {};

    for (const categoryID of Object.keys(categories)) {
      const category = categories[categoryID];
      const categoryProductIDs = productCategoryMap[categoryID];
      const excludedItems = categoryProductIDs.map(id => products[id]);

      const subtotal = cartsWithSamePS.reduce((sum, cart) => {
        const cartSubtotal = getSubtotalWithExclusions(
          cart,
          products,
          imageOptionMap,
          backgroundSets,
          images,
          type,
          productNodeMap,
          excludedItems,
        );
        return sum + cartSubtotal;
      }, 0);

      if (category.behaviors.length) {
        updatedStatusMap[categoryID] = {
          behaviorStatusMap: {},
          isLocked: true,
        };
      }

      for (const behavior of category.behaviors) {
        const { behaviorStatusMap } = updatedStatusMap[categoryID];
        behaviorStatusMap[behavior.id] = {
          conditionStatusMap: {},
          progressPercentage: 0,
        };

        const { conditionStatusMap } = behaviorStatusMap[behavior.id];

        for (const condition of behavior.conditions) {
          if (condition.type === 'cartAmount') {
            conditionStatusMap[condition.id] = {
              isMet: subtotal >= condition.minCartAmount,
              subtotal,
            };
          } else if (condition.type === 'requiredCategory') {
            const itemsFromCategory = cartsWithSamePS.reduce<(CartPackage | CartProduct)[]>(
              (result, cart) => {
                const cartItems = getCartItemsFromCategory(
                  cart,
                  productCategoryMap[condition.requiredCategoryID],
                  excludedItems,
                );
                result.push(...cartItems);
                return result;
              },
              [],
            );
            conditionStatusMap[condition.id] = {
              isMet: itemsFromCategory.length > 0,
            };
          }
        }

        // Total of all conditions met within the behavior:
        const completedCount = Object.values(conditionStatusMap).filter(c => c.isMet).length;
        const progressPercentage = Math.round(
          (completedCount / Object.values(conditionStatusMap).length) * 100,
        );
        behaviorStatusMap[behavior.id].progressPercentage = progressPercentage;

        // If all conditions are met, the category is unlocked:
        if (progressPercentage === 100) {
          updatedStatusMap[categoryID].isLocked = false;
        }
      }

      // If only a single item can or must be purchased within the category and an item is in the cart,
      // the category is locked
      if (['singleOptional', 'singleRequired'].includes(category.selectionType)) {
        const hasCategoryItem = cartsWithSamePS.some(cart => {
          const cartItems = getCartItemsFromCategory(cart, productCategoryMap[category.id]);
          return !!cartItems.length;
        });
        if (hasCategoryItem) {
          if (!updatedStatusMap[categoryID]) {
            updatedStatusMap[categoryID] = {
              behaviorStatusMap: {},
              isLocked: true,
            };
          }
          updatedStatusMap[categoryID].isLocked = true;
        } else if (
          !hasCategoryItem &&
          updatedStatusMap[categoryID]?.isLocked &&
          !category.behaviors.length
        ) {
          // If there are no cart items from the category, yet the locked status is still present and there are no
          // category `behaviors`, remove the entry from the status map so visitors can shop the category:
          delete updatedStatusMap[categoryID];
        }
      }
    }
    dispatch(setOffersStatusMap({ data: updatedStatusMap, priceSheetID }));
  };

// ----------------------------- Financials -----------------------------

export const getCartFinancials =
  (visitKey?: string): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    try {
      const financials = await api.getFinancials(visitKey || currentVisitKey!);
      dispatch(setFinancials(financials));

      return { valid: true, payload: null, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };

/**
 * Recalculates financials and omits any calculations pertaining to discounts or checkout data.
 * @returns An approximation of the financials we expect to be returned from the BE.
 */
export const calculateFinancials =
  (
    cartItem: CartPackage | CartProduct | CartImageOption,
    visitKey: string,
    { add, remove, quantity }: { add?: boolean; remove?: boolean; quantity?: number },
  ): AppThunk<null> =>
  (dispatch, getState) => {
    const { cartMap, cartFinancials: currentCartFinancials, summaryFinancials } = getState().cart;
    const { galleryMap, priceSheetMap } = getState().gallery;
    const filteredCartMap = filterEmptyCarts(cartMap);

    const calculatedBGs: Record<string, boolean> = {};
    const cartFinancials: CartFinancialsWithGallery[] = [];
    const isMultiCart = Object.values(filteredCartMap).length > 1;

    for (const [cartVisitKey, cart] of Object.entries(filteredCartMap)) {
      const financials = currentCartFinancials[cartVisitKey];
      const gallery = galleryMap[cartVisitKey];
      if (!gallery) {
        continue;
      }

      const priceSheet = priceSheetMap[gallery.priceSheetID];
      const shopProducts = priceSheet.products;
      const updatedCart: VisitWithCart = { ...cart };

      if (cartVisitKey === visitKey) {
        const isMatch = (item: CartProduct | CartPackage | CartImageOption) =>
          item.type === cartItem.type && item.id === cartItem.id;

        // Adds item to cart
        if (add) {
          const cartProductIndex = updatedCart.cartProducts.findIndex(cp => isMatch(cp));
          const cartPackageIndex = updatedCart.cartPackages.findIndex(cp => isMatch(cp));
          const cartImageOptionIndex = updatedCart.cartImageOptions.findIndex(cp => isMatch(cp));
          const isPackage = cartItem.type === 'buildYourOwn' || cartItem.type === 'standard';
          const isImageOption = cartItem.type === 'image';

          if (cartProductIndex === -1 && !isPackage && !isImageOption) {
            updatedCart.cartProducts = [...updatedCart.cartProducts, cartItem as CartProduct];
          }
          if (cartPackageIndex === -1 && isPackage) {
            updatedCart.cartPackages = [...updatedCart.cartPackages, cartItem as CartPackage];
          }
          if (cartImageOptionIndex === -1 && isImageOption) {
            updatedCart.cartImageOptions = [
              ...updatedCart.cartImageOptions,
              cartItem as CartImageOption,
            ];
          }
        }

        // Removes item from cart
        if (remove) {
          updatedCart.cartProducts = cart.cartProducts.filter(cp => !isMatch(cp));
          updatedCart.cartPackages = cart.cartPackages.filter(cp => !isMatch(cp));
          updatedCart.cartImageOptions = cart.cartImageOptions.filter(cio => !isMatch(cio));
        }

        // Updates quantity for cart item
        if (quantity) {
          updatedCart.cartProducts = cart.cartProducts.map(cp => {
            if (isMatch(cp)) {
              return { ...cp, quantity };
            }
            return cp;
          });
          updatedCart.cartPackages = cart.cartPackages.map(cp => {
            if (isMatch(cp)) {
              return { ...cp, quantity };
            }
            return cp;
          });
        }
      }

      const financialsWithGallery: CartFinancialsWithGallery = {
        ...financials,
        cartSettings: gallery.settings,
        digitalDownloadSubtotal: 0,
        subtotal: 0,
        gallery,
      };

      financialsWithGallery.imageOptionFees = getImageOptionFees(
        updatedCart.cartImageOptions,
        priceSheet.imageOptionMap,
      );

      financialsWithGallery.digitalDownloadSubtotal = getDigitalDownloadSubtotal(
        updatedCart.cartProducts,
        shopProducts,
        gallery,
        priceSheet.productNodeMap,
      );

      financialsWithGallery.subtotal = getCartItemsSubtotalWithExclusions(
        updatedCart,
        shopProducts,
        gallery.images,
        gallery.type,
        priceSheet.productNodeMap,
      );

      financialsWithGallery.backgroundFees = getAllBackgroundFees(
        updatedCart,
        shopProducts,
        priceSheet.backgroundSets,
        calculatedBGs,
      );

      financialsWithGallery.total = getSubtotal(financialsWithGallery);

      cartFinancials.push(financialsWithGallery);
    }

    const shippingSettings = getShipping(cartFinancials);
    const { tax, digitalDownloadTax } = getTaxes(cartFinancials, shippingSettings);
    const updatedSummaryFinancials = getMultiCartFinSum(cartFinancials);

    const total =
      getSubtotal(updatedSummaryFinancials) +
      shippingSettings.shipping +
      updatedSummaryFinancials.handling +
      digitalDownloadTax +
      tax;

    dispatch(
      setSummaryFinancials({
        ...summaryFinancials,
        ...updatedSummaryFinancials,
        shipping: shippingSettings.shipping,
        tax: isMultiCart ? 0 : tax,
        total,
      }),
    );

    if (quantity && cartItem.type !== 'image') {
      if (cartItem.type === 'buildYourOwn' || cartItem.type === 'standard') {
        dispatch(setCartPackage({ cartPackage: { ...cartItem, quantity }, visitKey }));
      } else {
        dispatch(setCartProduct({ cartProduct: { ...cartItem, quantity }, visitKey }));
      }
    }

    return { payload: null, valid: true, error: null };
  };

// ----------------------------- Favorites -----------------------------
export const addFavorite =
  (visitKey: string, image: ShopImage, background: ShopBackground | null): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { cartMap } = getState().cart;
    const { shopFavorites } = cartMap[visitKey];
    try {
      if (image.isGreenScreen && !background) {
        return { valid: false, payload: null, error: 'no background selected' };
      }

      const payload = {
        imageName: image.internalName,
        backgroundID: image.isGreenScreen ? background?.id : null,
      };
      const res = await api.addFavorite(visitKey, payload);
      dispatch(setFavorites({ visitKey, favorites: [...shopFavorites, res] }));
      return { valid: true, payload: null, error: null };
    } catch (error) {
      const addFavoriteErr = intl.formatMessage({
        id: 'visitor.thunks.addFavoriteErr',
        defaultMessage: 'Could not add favorite',
      });
      toast({ title: addFavoriteErr }, error);

      return { valid: false, payload: null, error };
    }
  };

export const deleteFavorite =
  (visitKey: string, favorite: ShopFavorite): PromiseThunk<null> =>
  async (dispatch, getState) => {
    const { cartMap } = getState().cart;
    const { shopFavorites } = cartMap[visitKey];
    try {
      await api.deleteFavorite(visitKey, favorite.id);

      const favorites = shopFavorites.filter(fav => fav.id !== favorite.id);
      dispatch(setFavorites({ visitKey, favorites }));

      return { valid: true, payload: null, error: null };
    } catch (error) {
      const deleteFavoriteErr = intl.formatMessage({
        id: 'visitor.thunks.deleteFavoriteErr',
        defaultMessage: 'Could not delete favorite',
      });
      toast({ title: deleteFavoriteErr }, error);

      return { valid: false, payload: null, error };
    }
  };

// ----------------------------- Validate Pre checkout -----------------------------

export const validateCarts =
  (visitKey?: string): PromiseThunk<Record<string, CartError[]> | null> =>
  async (dispatch, getState) => {
    const { currentVisitKey } = getState().visitor;
    try {
      const cartErrorMap = await api.validateCarts(visitKey || currentVisitKey!);
      dispatch(setCartErrors(cartErrorMap));

      return { valid: true, payload: cartErrorMap, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };
