import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ShopBackground } from '../../../../../shop-api-client';
import Swatch from '../../../../shared/components/BackgroundSwatches/Swatch';
import { getNameWithoutExt } from '../../../../shared/utils';

interface Props extends FlexProps {
  shopBG: ShopBackground;
}

// TODO SC-205: https://imagequix.atlassian.net/browse/SC-205
// Add Product Option details. This may entail changing the conditions
// for when this component is rendered (currently does not support package types)

/**
 * Displays details for a cart product
 */
const CartBGSection = ({ shopBG, ...flexProps }: Props) => {
  const intl = useIntl();

  const backgroundText = intl.formatMessage({
    id: 'cartItemDetails.background',
    defaultMessage: 'Background',
  });

  return (
    <Flex direction="column" marginBottom={4} {...flexProps}>
      <Heading fontSize={{ base: 'xs', md: 'sm' }} marginBottom={{ base: 1.5, md: 2 }}>
        {backgroundText}
      </Heading>

      <Flex>
        <Swatch
          position="relative"
          size="20px"
          borderRadius="5px"
          url={`url(${shopBG.sources.thumb}) no-repeat`}
          data-test="background-swatches-images"
        />
        <Text marginLeft={2} fontSize="xs">
          {getNameWithoutExt(shopBG?.name)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CartBGSection;
