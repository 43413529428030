import { BoxProps } from '@chakra-ui/react';
import { ImageNode, ImageTone, productHasNoOrientation } from 'iq-product-render';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShopBackground, ShopImage, ShopProductPrint } from '../../../../../../shop-api-client';
import {
  CartImageNode,
  CartImageNodeCrop,
  CartImageNodeReq,
  CartPrintProduct,
  CartPrintProductReq,
} from '../../../../../../shop-api-client/models/Cart';
import { selectCatalog } from '../../../../../redux/selectors/catalog.selectors';
import { selectConfiguration } from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { selectCanSelectAndBuy } from '../../../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import { setCompletedSteps, setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { updateEditProductNode } from '../../../../../redux/thunks/configuration.thunks';
import {
  getConfigurableImageNodes,
  getImageMultiNodeStep,
  getImageNodesStep,
  shapeCartImageNode,
} from '../../utils';
import ImageNodeMulti from './ImageNodeMulti';
import ImageNodeSingle from './ImageNodeSingle';

interface Props {
  catalogProductID: number;
  containerProps?: BoxProps;
  editProduct: CartPrintProductReq | CartPrintProduct;
  shopProduct: ShopProductPrint;
}

/**
 * Image Node Editor for Single Node products
 *
 * This component is the high level wrapper for managing single image node
 * products.
 */
const ImageNodesEditor = ({ catalogProductID, editProduct, shopProduct }: Props) => {
  const { rendererProductMap } = useAppSelector(selectCatalog);
  const { completedSteps } = useAppSelector(selectConfiguration);
  const { images } = useAppSelector(selectGallery);
  const {
    settings: { disableCropping },
  } = useAppSelector(selectGallery);
  const { productNodeMap } = useAppSelector(selectPriceSheet);
  const canSelectAndBuy = useSelector(selectCanSelectAndBuy);

  const [ready, setReady] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ready) {
      return;
    }
    const { priceSheetItemID } = editProduct;

    if (canSelectAndBuy[priceSheetItemID] && disableCropping) {
      dispatch(setCompletedSteps({ [getImageNodesStep(editProduct.id!)]: true }));
    }

    setReady(true);
  }, [canSelectAndBuy, disableCropping, dispatch, editProduct, images, ready]);

  const imageNodes = getConfigurableImageNodes(
    shopProduct as ShopProductPrint,
    productNodeMap[catalogProductID],
  );

  if (!imageNodes.length) {
    return null;
  }

  // maybe doesnt need to be passed down 100xx layers, but we'll see when multinode starts
  const selectAndSaveImage = (
    image: ShopImage,
    editImageNode: CartImageNodeReq | CartImageNode,
    catalogNode: ImageNode,
    background?: ShopBackground,
    imageTone?: ImageTone,
    userCrop?: CartImageNodeCrop,
  ) => {
    const shapedImgNode = shapeCartImageNode(
      catalogNode,
      image,
      background,
      imageTone || editImageNode.imageTone,
      userCrop,
    );

    dispatch(updateEditProductNode(editProduct.id!, shapedImgNode));
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [getImageNodesStep(editProduct.id!)]: true }));
  };

  if (imageNodes.length > 1) {
    // Only the multi-node completed state is shown here:
    if (completedSteps[getImageMultiNodeStep(editProduct.id!)]) {
      return (
        <ImageNodeMulti
          catalogNodes={imageNodes}
          complete
          editProduct={editProduct}
          shopProduct={shopProduct}
        />
      );
    }

    // Multi-node assignment is handled at the ProductConfiguration component level,
    // so if we somehow end up here, bail out
    return null;
  }

  // Grab the first image node
  const catalogNode = imageNodes[0];
  const editNode = editProduct.nodes.find(
    en => en.catalogNodeID === catalogNode.id,
  ) as CartImageNodeReq;

  const catalogProduct = rendererProductMap[shopProduct.catalogProductID];
  const flipSingleImageNode =
    productHasNoOrientation(catalogProduct) && catalogProduct.nodes[0].type === 'image';

  return (
    <ImageNodeSingle
      catalogNode={catalogNode}
      editNode={editNode}
      editProduct={editProduct}
      flipSingleImageNode={flipSingleImageNode}
      selectAndSaveImage={selectAndSaveImage}
    />
  );
};

export default ImageNodesEditor;
