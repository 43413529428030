import { Box, Flex, Stack } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/skeleton';
import React from 'react';

const CARD_COUNT = 12;

const ProductsSkeleton = () => (
  <Stack>
    <Stack spacing="20px" margin="2">
      <Skeleton height="30px" width="180px" />
      <Skeleton height="15px" width="145px" />
    </Stack>
    <Flex
      maxWidth="1000px"
      justifyContent={{ base: 'center', lg: 'left' }}
      marginBottom="100px"
      wrap="wrap"
    >
      {[...Array(CARD_COUNT).keys()].map(num => (
        <Box key={num} margin={{ base: 1, md: 2 }} marginBottom="10">
          <Skeleton
            height={{ base: '150px', md: '222px' }}
            marginY="1"
            width={{ base: '150px', md: '222px' }}
          />
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="15px" marginY="1" width="100px" />
            <Skeleton height="25px" marginY="1" width="30px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" width="130px" />
          </Stack>
        </Box>
      ))}
    </Flex>
  </Stack>
);

export default ProductsSkeleton;
