import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import RoundedBorderBox from '../../RoundedBorderBox';

interface Props extends BoxProps {
  children: ReactNode;
  isInvalid?: boolean;
  isSelected: boolean;
  isSingleRow?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const ChoiceContainer = ({
  children,
  isInvalid,
  isSelected,
  isSingleRow,
  onClick,
  ...rest
}: Props) => {
  const getBorderColor = () => {
    if (isSelected) {
      return 'brand';
    }
    return isInvalid ? 'error' : 'grey.2';
  };

  return (
    <Box width={isSingleRow ? '100%' : '50%'} {...rest}>
      <RoundedBorderBox
        borderColor={getBorderColor()}
        borderWidth={isSelected || isInvalid ? 2 : 1}
        borderRadius={4}
        display="flex"
        flexDirection="column"
        height="70px"
        justifyContent="center"
        marginBottom={2}
        onClick={onClick}
        paddingBottom={1}
        padding={2}
        cursor="pointer"
      >
        {children}
      </RoundedBorderBox>
    </Box>
  );
};

export default ChoiceContainer;
