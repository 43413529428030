import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  RadioGroup,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCartSlice } from '../../../../redux/selectors/cart.selectors';
import Modal from '../../../../shared/components/Modal';
import {
  CONTINUE_TO_CHECKOUT,
  ITEMS_CANNOT_BE_COMBINED,
  MULTIPLE_CHECKOUTS_AVAILABLE,
} from '../../../../shared/constants/carts.constants';
import CartsModalSection from './CartsModalSection';

interface Props {
  checkingOut: boolean;
  handleCheckout(visitKeys: string[]): void;
  possibleCheckouts: string[][];
  setShowModal(showModal: boolean): void;
  showModal: boolean;
}

const CartsSelectionModal = ({
  checkingOut,
  handleCheckout,
  possibleCheckouts,
  setShowModal,
  showModal,
}: Props) => {
  const { cartFinancials } = useSelector(selectCartSlice);
  const [selectedCheckoutIndex, setSelectedCheckoutIndex] = useState<number>(0);
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  // Construct the list of carts from the visitKeys:
  const multiCarts = possibleCheckouts.map(visitKeys => ({
    visitKeys,
    financials: cartFinancials[visitKeys[0]],
  }));

  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    handleCheckout(possibleCheckouts[selectedCheckoutIndex]);
  };

  const isSelectedCart = (index: number) => selectedCheckoutIndex === index;

  const renderMultiCarts = () => (
    <RadioGroup value={selectedCheckoutIndex}>
      {multiCarts.map((multiCart, index) => (
        <CartsModalSection
          key={index}
          cartVisitKeys={multiCart.visitKeys}
          index={index}
          isSelected={isSelectedCart(index)}
          setSelectedCart={setSelectedCheckoutIndex}
        />
      ))}
    </RadioGroup>
  );

  if (isMobile) {
    return (
      <Drawer closeOnOverlayClick isOpen={showModal} onClose={handleClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent maxHeight="70%" paddingTop={3}>
          <DrawerCloseButton borderRadius="50%" borderWidth={1} marginTop={2} />
          <DrawerHeader fontFamily="heading">{MULTIPLE_CHECKOUTS_AVAILABLE}</DrawerHeader>
          <Text marginBottom={8} marginLeft={6}>
            {ITEMS_CANNOT_BE_COMBINED}
          </Text>
          <DrawerBody> {renderMultiCarts()}</DrawerBody>
          <DrawerFooter paddingTop={5}>
            <Button onClick={handleConfirm} width="100%">
              {CONTINUE_TO_CHECKOUT}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Modal
      actionLabel={CONTINUE_TO_CHECKOUT}
      heading={MULTIPLE_CHECKOUTS_AVAILABLE}
      isLoading={checkingOut}
      isOpen={showModal}
      onClose={handleClose}
      onConfirm={handleConfirm}
      subHeading={ITEMS_CANNOT_BE_COMBINED}
    >
      <Center overflowY="auto" paddingTop={8}>
        {renderMultiCarts()}
      </Center>
    </Modal>
  );
};

export default CartsSelectionModal;
