import { Flex } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Link } from '@chakra-ui/react';
import LRU from 'lru-cache';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { ShopBackground } from '../../../../shop-api-client';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import {
  setSelectedImageName,
  toggleIsBgViewerExapnded,
  toggleShowNav,
} from '../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../redux/store';
import {
  initializeSelectAndBuy,
  loadBackgroundImage,
} from '../../../redux/thunks/interactions.thunks';
import { CAROUSEL_Z_INDEX } from '../../../shared/constants';
import { BUY_PHOTO } from '../../../shared/constants/labels.constants';
import { ImageAndBackground } from '../../../shared/types/image';
import { Params } from '../../../shared/types/router';
import FavoriteButton from '../ImageGrid/FavoriteButton';
import {
  BG_VIEWER_WIDTH,
  Direction,
  NEXT,
  VIEWER_BTN_HEIGHT,
  VIEWER_BTN_MARGIN,
} from '../constants';
import ActiveImage from './ActiveImage';
import ImageSlider from './ImageSlider';
import ImageViewerSidePanel from './ImageViewerSidePanel';
import { SIDE_PANEL_WIDTH } from './ImageViewerSidePanel/ImageViewerSidePanel';
import MobileBackgroundsSlider from './MobileBackgroundsSlider';
import SlideOutBackgroundViewer from './SlideOutBackgroundViewer';

const PRELOAD_PER_SIDE = 2;
const cache = new LRU<string, HTMLImageElement>({
  max: 30,
});

const ImageViewer = () => {
  const { groupImageMap, groups, images, isGreenScreen, subjectImageNames } =
    useSelector(selectGallery);
  const { selectedBackground, isBgViewerExpanded } = useSelector(selectInteractions);
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const orderedBackgrounds = useSelector(selectAllBackgrounds);

  const [direction, setDirection] = useState<Direction>(NEXT);
  const [imageElem, setImageElem] = useState<HTMLImageElement | null>(null);
  const [imageOnlyMode, setImageOnlyMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isResizingCanvas, setIsResizingCanvas] = useState(false);

  const { groupID, key, photoID, backgroundID } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });

  const isSingleGreenScreen =
    Object.keys(images).length === 1 && Object.values(images)[0].isGreenScreen;
  const selected = images[photoID];

  // Only show background viewer if allowed by the redux `isBgViewerExpanded` state, the image is greenscreen,
  // and, if not mobile, there is more than one image in the gallery:
  const showBackgroundViewer =
    images[photoID].isGreenScreen && (isMobile || !isSingleGreenScreen) && isBgViewerExpanded;

  ///////////////////////////////////////////////////////////////////////////////////
  // Variables for group and subject images
  ///////////////////////////////////////////////////////////////////////////////////

  const imagesReducer = useCallback(
    (result: ImageAndBackground[], imageName: string) => {
      if (isSingleGreenScreen) {
        const withBackgrounds = orderedBackgrounds.map(b => ({
          imageName,
          backgroundID: b.id,
        }));

        result.push(...withBackgrounds);
      } else {
        result.push({ imageName, backgroundID: parseInt(backgroundID) });
      }
      return result;
    },
    [backgroundID, isSingleGreenScreen, orderedBackgrounds],
  );

  // Array of images for the groupID from params if present, or all group images:
  const imagesForGroup = groupID ? groupImageMap[groupID] : Object.values(groupImageMap).flat();
  const groupImages = useMemo(
    () => imagesForGroup.reduce<ImageAndBackground[]>(imagesReducer, []),
    [imagesForGroup, imagesReducer],
  );
  const subjectImages = useMemo(
    () => subjectImageNames.reduce<ImageAndBackground[]>(imagesReducer, []),
    [imagesReducer, subjectImageNames],
  );
  const allImages = useMemo(() => [...subjectImages, ...groupImages], [groupImages, subjectImages]);
  const currentIndex = allImages.findIndex(
    i => i.imageName === photoID && (!backgroundID || parseInt(backgroundID) === i.backgroundID),
  );

  const loadImage = useCallback(
    async (imageID: string) => {
      if (!images[imageID]) {
        return null;
      }
      // If imageID already in cache, return that image object
      if (cache.has(imageID)) {
        //TODO: set loading state to false here?
        return cache.get(imageID)!;
      }

      // Otherwise create the new image and return
      const image = new Image();
      image.src = images[imageID].sources.full;
      await new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });

      cache.set(imageID, image);
      return image;
    },
    [images],
  );

  const handleSelectBackground = useCallback(
    (bg: ShopBackground) => {
      if (isResizingCanvas) {
        setIsResizingCanvas(false);
      }

      dispatch(loadBackgroundImage(bg));
      history.push(`/${key}/photos/${groupID || 'all'}/photo/${photoID}/bg/${bg.id}`);
    },
    [dispatch, groupID, history, key, photoID, isResizingCanvas],
  );

  // Initialize the images cache
  useEffect(() => {
    const init = async () => {
      // Set the image
      setImageElem(await loadImage(photoID));
      // Preload the other nearby images
      //TODO: preload different numbers for desktop vs mobile?
      const preload = [
        ...allImages.slice(Math.max(0, currentIndex - PRELOAD_PER_SIDE), currentIndex),
        ...allImages.slice(
          currentIndex + 1,
          Math.min(allImages.length, currentIndex + PRELOAD_PER_SIDE),
        ),
      ];

      await Promise.all(preload.map(({ imageName }) => loadImage(imageName)));
    };

    init();
  }, [currentIndex, allImages, loadImage, photoID]);

  useEffect(() => {
    dispatch(toggleShowNav(false));
    return () => {
      dispatch(toggleShowNav(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading || isMobile === undefined) {
      return;
    }

    // If the photoID param is not a valid image internalName, and if
    // groupID is present, but is not a valid image group ID
    // then route the user to /photos/all
    if (!selected || (groupID && !groups[groupID])) {
      history.push(`/${key}/photos/all`);
      return;
    }

    dispatch(setSelectedImageName(photoID));

    // If backgroundID param is present, set as selected background
    if (
      isGreenScreen &&
      (!selectedBackground || selectedBackground.id !== parseInt(backgroundID)) &&
      orderedBackgrounds.length
    ) {
      handleSelectBackground(backgroundsMap[backgroundID] || orderedBackgrounds[0]);
    }

    setIsLoading(false);
  }, [
    backgroundID,
    backgroundsMap,
    dispatch,
    groupID,
    groups,
    handleSelectBackground,
    history,
    isGreenScreen,
    isLoading,
    isMobile,
    key,
    orderedBackgrounds,
    photoID,
    selected,
    selectedBackground,
  ]);

  useEffect(() => {
    // The isResizingCanvas variable is used to determine whether the container should have
    // a transition effect when the width changes.
    // It is set to false on window resize so that the components are immediately scaled to size,
    // but is set to true when expanding/collapsing the Background Viewer
    const handleSetIsResizingCanvas = () => {
      if (imageOnlyMode) {
        setImageOnlyMode(false);
      }
      if (isResizingCanvas) {
        setIsResizingCanvas(false);
      }
    };
    window.addEventListener('resize', handleSetIsResizingCanvas);

    return () => {
      window.removeEventListener('resize', handleSetIsResizingCanvas);
    };
  }, [imageOnlyMode, isResizingCanvas]);

  // Prevents page from zooming with pinch/zoom
  useEffect(() => {
    const disableWindowZoom = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    window.addEventListener('touchmove', disableWindowZoom, { passive: false });
    return () => {
      window.removeEventListener('touchmove', disableWindowZoom);
    };
  }, []);

  useEffect(() => {
    if (isMobile && selected?.isGreenScreen && orderedBackgrounds.length) {
      dispatch(toggleIsBgViewerExapnded(true));
    }
  }, [dispatch, isMobile, orderedBackgrounds, selected]);

  // Clear cache
  useEffect(() => {
    return () => {
      cache.reset();
    };
  }, []);

  const getWidthSubtrahend = () => {
    if (isMobile) {
      return 0;
    }
    return SIDE_PANEL_WIDTH + (showBackgroundViewer ? BG_VIEWER_WIDTH : 0);
  };

  if (!imageElem) {
    return null;
  }

  const handleBuyMode = () => {
    dispatch(initializeSelectAndBuy(photoID, parseInt(backgroundID)));
  };

  const handleSelectImage = (imageName: string, backgroundID?: number) => {
    setIsResizingCanvas(false);
    dispatch(setSelectedImageName(imageName));
    const appendToPath = backgroundID ? `/bg/${backgroundID}` : '';
    history.push(`/${key}/photos/${groupID || 'all'}/photo/${imageName}${appendToPath}`);
  };

  const toggleShowViewer = () => {
    if (!isResizingCanvas) {
      setIsResizingCanvas(true);
    }
    dispatch(toggleIsBgViewerExapnded(!showBackgroundViewer));
  };

  // TODO: Revisit with Chris for UX improvements
  const toggleImageOnly = () => setImageOnlyMode(false);

  const handleNavigate = (direction: Direction) => {
    const nextIndex = direction === NEXT ? currentIndex + 1 : currentIndex - 1;
    const next = allImages[nextIndex];
    if (!next) {
      return;
    }
    handleSelectImage(next.imageName, next.backgroundID);
    setDirection(direction);
  };

  return (
    <Flex
      backgroundColor="black"
      height="100%"
      position="fixed"
      top="0"
      width="100vw"
      zIndex={CAROUSEL_Z_INDEX}
    >
      <Flex
        direction="column"
        transition={!isResizingCanvas ? 'none' : 'all .2s ease'}
        width={`calc(100vw - ${getWidthSubtrahend()}px)`}
      >
        <ActiveImage
          background={backgroundID ? backgroundsMap[backgroundID] : undefined}
          direction={direction}
          imageElem={imageElem}
          imageIndex={currentIndex}
          imageListCount={allImages.length}
          imageName={photoID}
          imageOnlyMode={imageOnlyMode}
          isViewerExpanded={showBackgroundViewer}
          onNavigate={handleNavigate}
          shouldResizeCanvas={isResizingCanvas && !isLoading}
          toggleImageOnly={toggleImageOnly}
          toggleShowViewer={toggleShowViewer}
        />
        {!imageOnlyMode && isMobile && (
          <Flex background="black" bottom="0" direction="column" marginY={`${VIEWER_BTN_MARGIN}px`}>
            <Flex paddingX="2">
              <FavoriteButton
                background="black"
                backgroundID={parseInt(backgroundID)}
                height={`${VIEWER_BTN_HEIGHT}px`}
                image={selected}
                variant="modalSecondary"
                width="100%"
              />

              <Link
                as={RouterLink}
                data-test="image-viewer-buy-photo"
                onClick={handleBuyMode}
                to={{
                  pathname: `/${key}/shop/all`,
                  search: `?image=${photoID}${
                    selected.isGreenScreen && backgroundID ? `&bg=${backgroundID}` : ''
                  }`,
                }}
                variant="modalPrimary"
                width="100%"
              >
                {BUY_PHOTO}
              </Link>
            </Flex>
            <MobileBackgroundsSlider
              isExpanded={showBackgroundViewer}
              onSelectBackground={handleSelectBackground}
            />
          </Flex>
        )}
        {!isMobile && (
          <ImageSlider
            groupImages={groupImages}
            isViewerExpanded={showBackgroundViewer}
            onSelectImage={handleSelectImage}
            selectedIndex={currentIndex}
            subjectImages={subjectImages}
            setDirection={setDirection}
            isSingleImage={isSingleGreenScreen}
          />
        )}
      </Flex>
      {!isMobile && (
        <>
          <SlideOutBackgroundViewer
            isExpanded={showBackgroundViewer}
            onSelectBackground={handleSelectBackground}
            setIsResizingCanvas={setIsResizingCanvas}
            toggleShowViewer={toggleShowViewer}
          />
          <ImageViewerSidePanel
            onSelectBackground={handleSelectBackground}
            toggleShowViewer={toggleShowViewer}
          />
        </>
      )}
    </Flex>
  );
};

export default ImageViewer;
