import { useEffect, useState } from 'react';

const useWindowSize = () => {
  // 1. Initialize state with current window size
  const [windowSize, setWindowSize] = useState<{ [key: string]: number }>({
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      // 3. Update the state
      setWindowSize({
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      });
    };

    // 2. Add an event listener that calls handleResize whenever the window size changes
    window.addEventListener('resize', handleResize);

    // 4. Call handle resize to make sure state is current
    handleResize();

    return () => {
      // Remove the event listener when unmounted
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 5. Return windowSize
  return windowSize;
};

export default useWindowSize;
