import { Flex, FlexProps, Slide } from '@chakra-ui/react';
import { MotionStyle } from 'framer-motion';
import React, { ReactNode } from 'react';
import { NAVIGATION_Z_INDEX } from '../../../constants';

interface Props extends Omit<FlexProps, 'direction'> {
  children: ReactNode;
  containerHeight?: string;
  direction: 'top' | 'bottom';
  show: boolean;
  slideMotionProps?: MotionStyle;
  top?: string;
}

const StickyMobileSection = ({
  children,
  containerHeight = '65px',
  direction,
  show,
  slideMotionProps,
  top = '',
  ...rest
}: Props) => {
  return (
    <Slide
      direction={direction}
      in={show}
      style={{ height: containerHeight, zIndex: NAVIGATION_Z_INDEX, top, ...slideMotionProps }}
    >
      <Flex
        align="center"
        backgroundColor="white"
        borderBottomWidth={direction === top ? '1px' : ''}
        borderColor="grey.2"
        borderTopWidth={direction === 'bottom' ? '1px' : ''}
        height="100%"
        justify="space-between"
        paddingX="2"
        width="100%"
        zIndex={NAVIGATION_Z_INDEX}
        {...rest}
      >
        {children}
      </Flex>
    </Slide>
  );
};

export default StickyMobileSection;
