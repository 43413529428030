import { Box, Button, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { BACKGROUND, CROP, EFFECTS, PHOTO } from '../../constants';

export type Step = 'photo' | 'background' | 'crop' | 'effects';

interface Props {
  activeStep: Step;
  disabled: Step[];
  handleSelectStep(step: Step): void;
  position: 'bottom' | 'top';
  steps: Step[];
}

const ImageStepHeadings = ({ activeStep, disabled, handleSelectStep, position, steps }: Props) => {
  const showAtBottom = position === 'bottom';

  const renderStepHeading = (step: Step) => {
    let backgroundColor: string | undefined = undefined;
    if (step === activeStep && !showAtBottom) {
      backgroundColor = 'black';
    }

    let color: string | undefined = undefined;
    if (step === activeStep) {
      color = showAtBottom ? 'brand' : 'white';
    }

    let stepLabel = '';
    switch (step) {
      case 'background':
        stepLabel = BACKGROUND;
        break;
      case 'crop':
        stepLabel = CROP;
        break;
      case 'effects':
        stepLabel = EFFECTS;
        break;
      case 'photo':
        stepLabel = PHOTO;
        break;
    }

    return (
      <Box key={step}>
        <Button
          animation={undefined}
          backgroundColor={backgroundColor}
          color={color}
          disabled={disabled.includes(step)}
          onClick={() => handleSelectStep(step)}
          variant="multiNodeStep"
        >
          {stepLabel}
        </Button>
      </Box>
    );
  };

  if (steps.length === 1) {
    return null;
  }

  const styles: FlexProps = {
    borderBottomWidth: showAtBottom ? 0 : 1,
    borderLeftRadius: showAtBottom ? 0 : 25,
    borderLeftWidth: showAtBottom ? 0 : 1,
    borderRightRadius: showAtBottom ? 0 : 25,
    borderRightWidth: showAtBottom ? 0 : 1,
    minHeight: showAtBottom ? undefined : '50px',
    justifyContent: showAtBottom ? 'space-evenly' : 'space-between',
    marginTop: showAtBottom ? 0 : 6,
    marginX: showAtBottom ? undefined : 'auto',
    paddingY: showAtBottom ? '10px' : 0,
    width: showAtBottom ? '100%' : undefined,
  };

  return (
    <Flex
      alignItems="center"
      borderColor="grey.2"
      borderTopWidth={1}
      direction="row"
      paddingX="5px"
      {...styles}
    >
      {steps.map(renderStepHeading)}
    </Flex>
  );
};

export default ImageStepHeadings;
