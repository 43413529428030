import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopPackage, ShopProduct, ShopProductPrint } from '../../../../../shop-api-client';
import {
  CartPackage,
  CartPrintProduct,
  CartProduct,
} from '../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import {
  selectBackgroundSetMap,
  selectBackgroundsMap,
  selectBackgroundToSetMap,
} from '../../../../redux/selectors/background.selectors';
import { selectUniqueImages } from '../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import {
  selectGroupedImageOptions,
  selectPriceSheet,
} from '../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  calculateFinancials,
  deleteCartItem,
  updateCartItem,
} from '../../../../redux/thunks/cart.thunks';
import { prefillEditImageOptions } from '../../../../redux/thunks/configuration.thunks';
import NumberInput from '../../../../shared/components/NumberInput';
import { DEBOUNCE_DURATION } from '../../../../shared/components/NumberInput/NumberInput';
import { PACKAGE_TYPES, PRODUCT_TYPES } from '../../../../shared/constants/zTable.constants';
import { Params } from '../../../../shared/types/router';
import { formatCurrency, getUniqueImages } from '../../../../shared/utils';
import useConfigurationRouter from '../../../Products/Configuration/useConfigurationRouter';
import {
  isConfigurable,
  requiresPerImageBG,
  requiresPerProductBG,
} from '../../../Products/Configuration/utils';
import ProductImage from '../../../Products/ProductImage';
import ProductPreview from '../../../Products/ProductPreview';
import {
  CART_SUMMARY_MAX_PREVIEW_SIZE,
  CART_SUMMARY_MAX_PREVIEW_SIZE_MOBILE,
} from '../../../Products/ProductPreview/ProductPreview';
import { getFirstAssignedImage } from '../../../Products/utils';
import {
  getBackgroundFees,
  getBackgroundForCartProduct,
  getCartProductTextNodes,
  getProductOptionFees,
} from '../../utils';
import CartBGSection from '../CartBGSection';
import CartItemActions from '../CartItemActions';
import CartItemThumbnails from '../CartItemThumbnails';
import CartPackageDetails from '../CartPackageDetails';
import CartProductDetails from '../CartProductDetails';
import { HIDE, SHOW } from '../../../../shared/constants';

interface Props extends FlexProps {
  item: CartProduct | CartPackage;
  priceSheetID: number;
  visitKey: string;
}

/**
 * Displays details and price for cart packages and products, and allows the visitor
 * to edit, remove, and change the quantity of the item
 */
const CartItemSection = ({ item, priceSheetID, visitKey, ...flexProps }: Props) => {
  const { offersStatusMap, preOrderBackgroundSelectionType, productNodeMap, products } =
    useAppSelector(state => selectPriceSheet(state, priceSheetID));
  const gallery = useAppSelector(state => selectGallery(state, visitKey));
  const { optionalImageOptions } = useSelector(selectGroupedImageOptions);
  const backgroundSetMap = useAppSelector(state => selectBackgroundSetMap(state, priceSheetID));
  const backgroundToSetMap = useAppSelector(state => selectBackgroundToSetMap(state, priceSheetID));
  const backgroundsMap = useAppSelector(state => selectBackgroundsMap(state, visitKey));
  const { routeToConfigurationPath } = useConfigurationRouter();
  const { currency } = useAppSelector(selectAccount);

  const isProduct = Object.values(PRODUCT_TYPES).includes(item.type);
  const uniqueImages = useAppSelector(state =>
    selectUniqueImages(state, isProduct ? (item as CartProduct) : undefined, visitKey),
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [numberInputError, setNumberInputError] = useState(false);
  const [quantityAdded, setQuantityAdded] = useState(item.quantity);
  const [showDetails, setShowDetails] = useState(false);

  // Enables cart item quantity to be updated in Redux without causing
  // handleQuantityChange to re-initialize and break debounce functionality
  const [cartItem, setCartItem] = useState(item);

  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const { isGreenScreen, isPreOrder, images, type } = gallery;
  const shopItem = products[item.priceSheetItemID];
  const isLocked = offersStatusMap[shopItem.categoryID]?.isLocked;
  const canEditPerProductBG = requiresPerProductBG(
    shopItem.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    Object.values(backgroundSetMap),
  );
  const canEditPerImageBG = requiresPerImageBG(
    shopItem.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    Object.values(backgroundSetMap),
  );
  const hasOptionalImageOptions =
    optionalImageOptions.length > 0 && shopItem.type !== 'nonPrintProduct';
  const canEditProduct =
    hasOptionalImageOptions || isConfigurable(isPreOrder, shopItem, productNodeMap);
  const canEdit = canEditProduct || canEditPerProductBG || canEditPerImageBG;
  const isPackage = Object.values(PACKAGE_TYPES).includes(item.type);
  const isIncludeAll =
    (shopItem.type === 'imageDownload' || shopItem.type === 'collection') && shopItem.includeAll;
  const packageItemCount = isPackage ? (item as CartPackage).products.length : 0;

  const background = getBackgroundForCartProduct(item, Object.values(backgroundsMap));
  const hasProductOptions =
    (item.type === 'product' || item.type === 'nonPrintProduct') && !!item.options?.length;
  const hasTextNodes =
    item.type === 'product' &&
    !!getCartProductTextNodes(cartItem as CartProduct, productNodeMap, shopItem as ShopProduct)
      .length;
  const showProductDetails =
    hasProductOptions || hasTextNodes || isPackage || (!!uniqueImages.length && !isIncludeAll);

  const maxPreviewSize = isMobile
    ? CART_SUMMARY_MAX_PREVIEW_SIZE_MOBILE
    : CART_SUMMARY_MAX_PREVIEW_SIZE;

  const allPhotosSelected = intl.formatMessage({
    id: 'cartItemSection.allPhotosSelected',
    defaultMessage: 'All Photos Selected',
  });
  const showOrHideDetails = intl.formatMessage(
    {
      id: 'cartItemSection.hideDetails',
      defaultMessage: '{showOrHide} {itemType} details',
    },
    {
      showOrHide: showDetails ? HIDE : SHOW,
      itemType: isPackage ? 'package' : 'item',
    },
  );
  const packageItems = intl.formatMessage(
    {
      id: 'cartItemSection.packageItems',
      defaultMessage: '{packageItemCount} {items}',
    },
    { packageItemCount, items: packageItemCount > 1 ? 'items' : 'item' },
  );

  const calculateProductPrice = (item: CartProduct | CartPackage) => {
    let price = item.unitPrice;
    price += getProductOptionFees(item, shopItem);

    if (isPackage) {
      const uniqueImages = isPreOrder
        ? []
        : getUniqueImages(
            item as CartPackage,
            shopItem as ShopPackage,
            images,
            type,
            productNodeMap,
          );

      const { additionalPoseFeeType, additionalPoseFee, posesIncluded } = shopItem as ShopPackage;

      if (uniqueImages.length > posesIncluded) {
        if (additionalPoseFeeType === 'oneTime') {
          price += additionalPoseFee;
        } else if ((shopItem as ShopPackage).additionalPoseFeeType === 'perImage') {
          const additional = uniqueImages.length - posesIncluded;
          price += additional * additionalPoseFee;
        }
      }
    }

    // Account for item quantity
    price *= quantityAdded;

    // Add BG fees after applying quantity to avoid multiple charges for same BG selection
    price += getBackgroundFees(item, shopItem, backgroundSetMap, backgroundToSetMap);

    return price;
  };

  const handleUpdate = useCallback(
    async (updated: CartPackage | CartProduct, withToast = true) => {
      setIsUpdating(true);
      const result = await dispatch(updateCartItem(updated, visitKey, withToast));
      setIsUpdating(false);
      return result;
    },
    [dispatch, visitKey],
  );

  /**
   * `handleQuantityChange` is used as a depedency for memoization of a debounced
   * version of itself in NumberInput, which requires it to be memoized here
   */
  const handleQuantityChange = useCallback(
    async (quantity: number) => {
      setNumberInputError(false);
      const { valid } = await handleUpdate({ ...cartItem, quantity }, false);
      if (!valid) {
        setNumberInputError(true);
      }
    },
    [handleUpdate, cartItem],
  );

  useEffect(() => {
    if (numberInputError) {
      setQuantityAdded(item.quantity);
    }

    // Prevent cartItem from getting stale without interfering with handleQuantityChange
    const timer = setTimeout(() => setCartItem(item), 1000);
    return () => clearTimeout(timer);
  }, [numberInputError, item]);

  const handleQuantityChangeImmediate = (quantity: number) => {
    setQuantityAdded(quantity);
    dispatch(calculateFinancials(item, visitKey, { quantity }));
  };

  const handleEditClick = () => {
    if (canEdit) {
      dispatch(prefillEditImageOptions());
      const isPkg = ['package-byop', 'package'].includes(shopItem.type);
      const search = `?originatingKey=${key}&${isPkg ? 'cartPackageID' : 'cartProductID'}=${
        item.id
      }`;

      // Pass visitKey for this cart item to route to the correct gallery:
      routeToConfigurationPath(visitKey, shopItem, gallery, true, search);
    }
  };

  const handleRemoveItem = async () => {
    setIsUpdating(true);
    dispatch(calculateFinancials(item, visitKey, { remove: true }));
    await dispatch(deleteCartItem(item, visitKey));
    setIsUpdating(false);
  };

  const handleDetails = () => setShowDetails(!showDetails);

  const renderNumberInput = () => (
    <NumberInput
      debounceDuration={DEBOUNCE_DURATION}
      defaultValue={item.quantity}
      isDisabled={isUpdating}
      justifySelf="center"
      marginX={isMobile ? 2 : 4}
      onValueChange={handleQuantityChange}
      onValueChangeImmediate={handleQuantityChangeImmediate}
      overrideValue={numberInputError ? item.quantity : undefined}
      width={100}
    />
  );

  const renderItemDetails = () => {
    if (isPackage) {
      return <CartPackageDetails item={item as CartPackage} visitKey={visitKey} />;
    }
    if (!isProduct) {
      return null;
    }
    return (
      <CartProductDetails
        item={item as CartProduct}
        priceSheetItem={products[item.priceSheetItemID] as ShopProduct}
        visitKey={visitKey}
      />
    );
  };

  const renderThumbnails = () => {
    if (background && isPreOrder) {
      return (
        <CartBGSection shopBG={background} marginTop={showProductDetails || isMobile ? 0 : 3.5} />
      );
    }
    if (isIncludeAll && !isPreOrder) {
      return (
        <Text fontSize="sm" marginTop={2}>
          {allPhotosSelected}
        </Text>
      );
    }
    if (!uniqueImages.length) {
      return null;
    }
    if (item.type === 'product') {
      return (
        <ProductPreview
          disableMiniPreview
          editProduct={item as CartPrintProduct}
          hidePreviewText
          isFinalRender
          isInteractive={false}
          marginBottom={{ base: 1, md: 4 }}
          marginTop={{ base: 1, md: 0 }}
          maxPreviewHeight={maxPreviewSize}
          maxPreviewWidth={maxPreviewSize}
          shopProduct={shopItem as ShopProductPrint}
          visitKey={visitKey}
        />
      );
    }
    return <CartItemThumbnails imageSet={uniqueImages} visitKey={visitKey} />;
  };

  const renderMain = () => {
    if (isMobile) {
      return (
        <Flex direction="row" alignItems="flex-start" paddingTop={3} marginTop={1}>
          <Box minW="80px" marginTop={1} marginBottom={2} width="80px">
            <ProductImage
              fallbackFontSize="10px"
              fallbackIconSize="20px"
              height="inherit"
              image={getFirstAssignedImage(products[item.priceSheetItemID])}
              product={products[item.priceSheetItemID]}
            />
          </Box>
          <Flex direction="column" justifyContent="center" marginLeft="18px" flex={1}>
            <Heading fontSize="md" marginBottom={isPackage ? 1.5 : 3}>
              {item.name}
            </Heading>
            {isPackage && (
              <Text fontSize="sm" marginBottom={3}>
                {packageItems}
              </Text>
            )}
            <Heading as="span" fontSize="sm" marginBottom={0.5}>
              {formatCurrency(calculateProductPrice(item), currency)}
            </Heading>
            <Flex
              alignItems="center"
              direction="row"
              flex={1}
              justifyContent="space-between"
              marginY={4}
            >
              {!isLocked && (
                <NumberInput
                  debounceDuration={DEBOUNCE_DURATION}
                  defaultValue={item.quantity}
                  isDisabled={isUpdating}
                  justifySelf="center"
                  marginX={0}
                  onValueChange={handleQuantityChange}
                  onValueChangeImmediate={handleQuantityChangeImmediate}
                  overrideValue={numberInputError ? item.quantity : undefined}
                  width={80}
                />
              )}
              <Flex height="20px" marginRight={8}>
                <CartItemActions
                  onEdit={canEdit ? handleEditClick : undefined}
                  onRemove={handleRemoveItem}
                  isDisabled={isUpdating}
                />
              </Flex>
            </Flex>

            {renderThumbnails()}

            {showProductDetails && (
              <Button
                color="grey.9"
                fontSize="sm"
                justifyContent="flex-start"
                marginBottom={isPackage ? 6 : 3}
                marginLeft={0}
                onClick={handleDetails}
                variant="link"
              >
                {showOrHideDetails}
              </Button>
            )}

            {showDetails && (
              <Collapse in={showDetails} animateOpacity>
                <Flex maxWidth="60vw">{renderItemDetails()}</Flex>
              </Collapse>
            )}
          </Flex>
        </Flex>
      );
    }

    return (
      <>
        <Box width="145px" marginTop={1}>
          <ProductImage
            fallbackFontSize="13px"
            fallbackIconSize="34px"
            height="120px"
            image={getFirstAssignedImage(products[item.priceSheetItemID])}
            product={products[item.priceSheetItemID]}
          />
        </Box>
        <Flex
          data-test={`${item.name}-container`}
          direction="column"
          marginLeft={5}
          maxW="100%"
          width="100%"
        >
          <Flex width="100%">
            <Box width="300px">
              <Heading fontSize="md" lineHeight="1.5">
                {item.name}
              </Heading>

              {isPackage && (
                <Text fontSize="sm" marginTop={1} marginBottom={3}>
                  {packageItems}
                </Text>
              )}
            </Box>
            <Flex grow={1} justify={isLocked ? 'right' : 'space-between'} marginTop={1}>
              {!isLocked && renderNumberInput()}
              <Text as="span" fontSize="lg" fontWeight="bold" fontFamily="heading">
                {formatCurrency(calculateProductPrice(item), currency)}
              </Text>
            </Flex>
          </Flex>
          <Box flexGrow={1} minHeight="25px" marginTop="-7px">
            {showProductDetails && (
              <Button
                data-test={`${showOrHideDetails}-button`}
                color="grey.9"
                onClick={handleDetails}
                variant="link"
                marginBottom={6}
              >
                {showOrHideDetails}
              </Button>
            )}

            {renderThumbnails()}

            {showDetails && (
              <Collapse in={showDetails} animateOpacity>
                {renderItemDetails()}
              </Collapse>
            )}
          </Box>
          <Flex justify="right" height="30px" paddingX={1} marginTop={3}>
            <CartItemActions
              onEdit={canEdit ? handleEditClick : undefined}
              onRemove={handleRemoveItem}
              isDisabled={isUpdating}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <Flex direction="column">
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        key={item.priceSheetItemID}
        marginTop={{ base: 1, lg: 6 }}
        minHeight="100px"
        paddingBottom={{ base: 0, md: 2 }}
        position="relative"
        {...flexProps}
      >
        {renderMain()}
      </Flex>
      <Divider borderColor="grey.2" borderBottomWidth="1px" marginTop={4} />
    </Flex>
  );
};

export default CartItemSection;
