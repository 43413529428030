import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartErrors } from '../../../redux/selectors/cart.selectors';
import { MIN_ORDER_AMT_ERROR, REQUIRED_ITEM_ERROR } from '../../../shared/constants';
import CartsSelectionModal from './CartsSelectionModal';
import MinOrderAmountModal from './MinOrderAmountModal';
import RequiredPurchaseModal from './RequiredPurchaseModal';

interface Props {
  checkingOut: boolean;
  handleCheckout(visitKeys?: string[] | undefined): Promise<void>;
  possibleCheckouts: string[][] | undefined;
  setShowModal(showModal: boolean): void;
  showModal: boolean;
}

const CartsModal = ({
  checkingOut,
  handleCheckout,
  possibleCheckouts,
  setShowModal,
  showModal,
}: Props) => {
  const cartErrors = useSelector(selectCartErrors);

  if (!possibleCheckouts || !showModal) {
    return null;
  }

  const minOrderErrKeys = [];
  const requiredItemErrKeys = [];

  for (const [key, errors] of Object.entries(cartErrors)) {
    for (const error of errors) {
      if (error.message === MIN_ORDER_AMT_ERROR) {
        minOrderErrKeys.push(key);
      } else if (error.message === REQUIRED_ITEM_ERROR) {
        requiredItemErrKeys.push(key);
      }
    }
  }

  // If the current cart has the required item error, show RequiredPurchaseModal:
  if (requiredItemErrKeys.length) {
    return (
      <RequiredPurchaseModal
        errorVisitKeys={requiredItemErrKeys}
        isOpen={!checkingOut}
        onClose={setShowModal}
      />
    );
  }

  // If any carts have the minimum order amount error, show MinOrderAmountModal:
  if (minOrderErrKeys.length) {
    return (
      <MinOrderAmountModal
        errorVisitKeys={minOrderErrKeys}
        isOpen={!checkingOut}
        onClose={setShowModal}
      />
    );
  }

  // Show the multi-cart modal:
  return (
    <CartsSelectionModal
      handleCheckout={handleCheckout}
      possibleCheckouts={possibleCheckouts}
      setShowModal={setShowModal}
      showModal={showModal}
      checkingOut={checkingOut}
    />
  );
};

export default CartsModal;
