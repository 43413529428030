import { Flex } from '@chakra-ui/react';
import { getDefaultCrop } from 'iq-product-render';
import React, { useState } from 'react';
import { ImageRequirementType, ShopBackground, ShopImage } from '../../../../../../shop-api-client';
import {
  CartImageNode,
  CartImageNodeCrop,
  CartImageNodeReq,
} from '../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../shop-api-client/models/ShopProductNodes';
import { setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../../../redux/store';
import useSearchParams from '../../../../../shared/hooks/useSearchParams';
import { ImageAndBackground } from '../../../../../shared/types/image';
import { SELECT_YOUR_PHOTO } from '../../constants';
import { getNodeCrop } from '../../utils';
import CompletedSection from '../CompletedSection';
import EditorSectionContainer from '../EditorSectionContainer';
import ImageNodeDisplay from '../ImageNodesEditor/ImageNodeDisplay';
import ImageNodeModal from '../ImageNodesEditor/ImageNodeModal';
import ImageSelectionPresets from '../ImageSelectionPresets';
import EditSelection from './EditSelection';
import SelectedImage from './SelectedImage';

type Step = 'background' | 'crop';

interface Props {
  availableImages?: ShopImage[];
  catalogNode?: ShopImageNodeWithReqType;
  completed: boolean;
  disableCrop: boolean;
  flipSingleImageNode?: boolean;
  imageNode?: CartImageNodeReq | CartImageNode;
  imageRequirementType?: ImageRequirementType | null;
  initialBackground?: ShopBackground;
  initialCrop?: CartImageNodeCrop;
  initialImage?: ShopImage;
  isActive: boolean;
  isDisabled: boolean;
  lockedImage?: boolean;
  onSelect(image: ShopImage, background?: ShopBackground, crop?: CartImageNodeCrop): void;
  selectInitialImage?: boolean;
  showContinue?: boolean;
  stepName: string;
  subText?: string;
}

const ImageSelection = ({
  availableImages = [],
  completed,
  disableCrop,
  catalogNode,
  flipSingleImageNode,
  imageNode,
  imageRequirementType,
  initialBackground,
  initialImage,
  isDisabled,
  lockedImage,
  onSelect,
  selectInitialImage = true,
  showContinue,
  stepName,
  subText,
}: Props) => {
  const { getParamsValue } = useSearchParams();
  const prefillImage = getParamsValue('image');

  const [editImage, setEditImage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedBg, setSelectedBg] = useState<ShopBackground | undefined>(initialBackground);
  const [selectedImage, setSelectedImage] = useState<ShopImage | undefined>(
    selectInitialImage ? initialImage : undefined,
  );
  const [showModal, setShowModal] = useState(
    !completed &&
      !!prefillImage.length &&
      ((initialImage?.isGreenScreen && !initialBackground) || !disableCrop),
  );
  const [showWarning, setShowWarning] = useState(false);
  const [userCrop, setUserCrop] = useState(getNodeCrop(imageNode));

  const isEditable = !lockedImage || !disableCrop || selectedImage?.isGreenScreen;

  const getSteps = (image: ShopImage | undefined, hasBackground: boolean) => {
    const steps: Step[] = [];
    if (
      !hasBackground &&
      image?.isGreenScreen &&
      !catalogNode?.skipBackgroundSelection &&
      !lockedImage
    ) {
      steps.push('background');
    }
    if (!disableCrop) {
      steps.push('crop');
    }
    return steps;
  };

  const [modalSteps, setModalSteps] = useState<Step[]>(getSteps(initialImage, !!selectedBg));
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    // Reset the image back to the initial selection:
    setSelectedImage(initialImage);
    setSelectedBg(initialBackground);
    setShowModal(false);
  };

  const handleContinue = () => {
    if (
      selectedImage &&
      (!selectedImage.isGreenScreen || selectedBg || catalogNode?.skipBackgroundSelection)
    ) {
      handleSave();
    } else {
      setShowWarning(true);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    dispatch(setEditStep(stepName));
  };

  const handleSave = (
    image: ShopImage | undefined = selectedImage,
    background: ShopBackground | undefined = selectedBg,
    crop?: CartImageNodeCrop,
  ) => {
    if (!image || (image.isGreenScreen && !background && !catalogNode?.skipBackgroundSelection)) {
      return;
    }
    setEditImage(false);
    setEditMode(false);
    onSelect(image, background, crop);
  };

  const handleModalSave = (
    background: ShopBackground | undefined = selectedBg,
    crop?: CartImageNodeCrop,
  ) => {
    setSelectedBg(background);
    handleSave(selectedImage, background, crop);
    if (crop) {
      setUserCrop(crop);
    }
    setShowModal(false);
  };

  const handleSelection = (image: ShopImage, background?: ShopBackground) => {
    setSelectedImage(image);
    setSelectedBg(background);
    setShowWarning(false);

    if (
      (!image.isGreenScreen || background || catalogNode?.skipBackgroundSelection) &&
      disableCrop
    ) {
      handleSave(image, background);
    } else {
      if (!disableCrop) {
        if (selectedImage?.internalName !== image.internalName) {
          // Calculate the default crop:
          const { height, width } = image;
          const defaultCrop = getDefaultCrop({ height, width }, catalogNode!, true, true);
          setUserCrop({
            cropH: defaultCrop.height,
            cropW: defaultCrop.width,
            cropX: defaultCrop.x,
            cropY: defaultCrop.y,
            orientation: defaultCrop.rotation,
          });
        } else {
          setUserCrop(getNodeCrop(imageNode));
        }
      }

      setModalSteps(getSteps(image, !!background));
      setShowModal(true);
    }
  };

  const handleStepSelection = (step: Step | 'image') => {
    if (step === 'image') {
      setEditImage(true);
    } else {
      setModalSteps([step]);
      setShowModal(true);
    }
  };

  const renderContent = () => {
    // If locked to the initial image, only background selection is needed:
    if (
      (lockedImage && initialImage) ||
      (availableImages.length === 1 && availableImages[0].isGreenScreen)
    ) {
      return (
        <>
          {/* Removed temporarily until the component can be consistently implemented
              See commit:
              https://bitbucket.org/imagequix/shop-ui/commits/97384ca8b16df6af22d4b66daf50d148b434d369
              */}
          {/* <ImageNodeDisplayPreset
                heading={RECENTLY_USED}
                imageMaxWidth="60px"
                imageRequirementType={imageRequirementType}
                presetImages={cartImages
                  .filter(ci => ci.imageName === initialImage.internalName)
                  .slice(0, isMobile ? 3 : 5)}
                selectedImages={shapeSelectedImage(initialImage.internalName, selectedBg?.id)}
                selectImage={handleSelection}
                showImageName={false}
                showNumImages={false}
                columns={{ base: 3, md: 5 }}
                color="white"
                thumbnailProps={{
                  overlayProps: { backgroundColor: 'none', borderColor: 'none' },
                  error: true,
                  containerStyles: { opacity: 0.3 },
                }}
              /> */}
          <ImageSelectionPresets
            error={showWarning}
            images={[initialImage || availableImages[0]]}
            selectedBackgrounds={selectedBg?.id ? [selectedBg.id] : []}
            selectImage={handleSelection}
            selectedImageName={selectedImage?.internalName}
            skipBackgroundSelection={catalogNode?.skipBackgroundSelection}
          />
        </>
      );
    }

    if (editMode) {
      return (
        <EditSelection
          canEditCrop={!disableCrop}
          disableImage={!!lockedImage}
          background={initialBackground}
          editImage={editImage}
          image={selectedImage}
          imageRequirementType={imageRequirementType}
          onEdit={handleStepSelection}
          onSelectImage={handleSelection}
          skipBackgroundSelection={catalogNode?.skipBackgroundSelection}
        />
      );
    }

    const selectedImages: Record<string, ImageAndBackground> = {};
    if (selectedImage) {
      selectedImages[`${selectedImage.internalName}-${selectedBg?.id}`] = {
        backgroundID: selectedBg?.id,
        imageName: selectedImage.internalName,
      };
    }

    return (
      <Flex direction="column" width="100%" marginY={2}>
        <SelectedImage background={initialBackground} image={initialImage} subText={subText} />
        <ImageNodeDisplay
          imageRequirementType={imageRequirementType}
          selectedImages={selectedImages}
          selectImage={handleSelection}
          showErrorOutline={showWarning}
          skipBackgroundSelection={catalogNode?.skipBackgroundSelection}
        />
      </Flex>
    );
  };

  if (completed) {
    return (
      <CompletedSection heading={SELECT_YOUR_PHOTO} onEdit={isEditable ? handleEdit : undefined}>
        <SelectedImage
          background={initialBackground}
          containerProps={{ marginY: 0 }}
          image={initialImage}
          subText={subText}
        />
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer
      heading={SELECT_YOUR_PHOTO}
      isDisabled={isDisabled}
      onSave={showContinue ? handleContinue : undefined}
    >
      {renderContent()}
      {selectedImage && showModal && (
        <ImageNodeModal
          editing={editMode}
          flipSingleImageNode={!!flipSingleImageNode}
          imageNode={catalogNode}
          initialBackground={selectedBg}
          initialCrop={userCrop}
          onClose={handleCancel}
          onSave={handleModalSave}
          selectedImg={selectedImage}
          showModal={showModal}
          steps={modalSteps}
        />
      )}
    </EditorSectionContainer>
  );
};

export default ImageSelection;
