import { Flex, Text } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopBackground } from '../../../../../shop-api-client';
import {
  selectAllBackgrounds,
  selectBackgroundsMap,
} from '../../../../redux/selectors/background.selectors';
import Swatch from '../../../../shared/components/BackgroundSwatches/Swatch';
import { Params } from '../../../../shared/types/router';
import { MOBILE_BG_SLIDER_HEIGHT } from '../../constants';

interface Props {
  isExpanded: boolean;
  onSelectBackground(bg: ShopBackground): void;
}

const MobileBackgroundsSlider = ({ isExpanded, onSelectBackground }: Props) => {
  const backgroundsMap = useSelector(selectBackgroundsMap);
  const backgrounds = useSelector(selectAllBackgrounds);
  const [isMounted, setIsMounted] = useState(false);
  const { backgroundID } = useParams<Params>();
  const selectedBackground = backgroundsMap[backgroundID];

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    // The isMounted variable is used to delay setting the expanded component width
    // from zero until after the initial render. Doing so allows a sliding transition effect
    // on the width change
    if (isExpanded) {
      setIsMounted(true);
    } else {
      timeout = setTimeout(() => setIsMounted(false), 200);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isExpanded]);

  if (!isMounted && !isExpanded) {
    return null;
  }

  return (
    <Flex
      height={isMounted && isExpanded ? `${MOBILE_BG_SLIDER_HEIGHT}px` : 0}
      marginLeft="2"
      transition="height .2s ease"
    >
      <Flex direction="column" height="92px">
        <Text color="white" fontSize="md">
          Backgrounds
        </Text>
        <Flex overflow="scroll" paddingX="1" sx={{ scrollSnapType: 'x mandatory' }} width="100vw">
          {backgrounds.map(bg => (
            <Flex
              key={bg.id}
              cursor="pointer"
              direction="column"
              margin="1"
              position="relative"
              sx={{ scrollSnapAlign: 'start', scrollSnapStop: 'normal' }}
            >
              <Swatch
                onClick={() => onSelectBackground(bg)}
                showOverlay={selectedBackground?.sources.full === bg.sources.full}
                size="60px"
                url={`url(${bg.sources.thumb}) no-repeat`}
                data-test="mobile-background-slider-background-image"
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MobileBackgroundsSlider;
