import { Z_INDEX_ONE } from '../../shared/constants';

const Input = {
  baseStyle: {},
  variants: {
    base: {
      field: {
        borderColor: 'grey.3',
        borderWidth: '2px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        height: '55px',
        paddingLeft: '13px',
        _invalid: { borderColor: 'error' },
        _focus: {
          borderColor: 'focus',
        },
      },
    },
    floatingLabel: {
      field: {
        background: 'transparent',
        borderColor: 'grey.3',
        borderWidth: '2px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        height: '55px',
        paddingLeft: '13px',
        paddingTop: '14px',
        zIndex: Z_INDEX_ONE,
        _invalid: { borderColor: 'error' },
        _focus: {
          borderColor: 'focus',
        },
      },
    },
    checkbox: {
      field: {
        background: 'white',
        borderWidth: '2px',
        borderRadius: '3px',
        height: '20px',
        width: '20px',
        padding: '0',
        _focus: {
          borderColor: 'focus',
        },
      },
    },
    numberInput: {
      field: {
        borderColor: 'grey.2',
        borderWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderRadius: '0',
        fontFamily: 'ITC Avant Garde Gothic Demi',
        fontSize: 'sm',
        height: '30px',
        margin: '0',
        padding: '2px 0 0 0',
        textAlign: 'center',
      },
    },
    radio: {
      field: {
        background: 'white',
        borderWidth: '2px',
        borderRadius: '50%',
        height: '25px',
        width: '25px',
        padding: '2px',
        _after: {
          content: "''",
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          borderColor: 'white',
          borderRadius: '50%',
          borderStyle: 'solid',
          borderWidth: '3px',
        },
        _focus: {
          borderColor: 'focus',
        },
      },
    },
    search: {
      field: {
        background: 'grey.1',
        borderRadius: '35px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'grey.9',
        height: '45px',
        _invalid: { borderColor: 'error' },
        _focus: {
          borderColor: 'focus',
        },
        _placeholder: {
          color: 'grey.5',
        },
      },
    },
    custom: (props: any) => ({
      bg: props.customPrimary,
    }),
  },
  defaultProps: {
    variant: 'base',
  },
};

export default Input;
