import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectBackgroundsMap } from '../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import Thumbnail from '../../../shared/components/Thumbnail';
import { ImageAndBackground } from '../../../shared/types/image';
import { BG_VIEWER_WIDTH } from '../constants';

interface Props {
  imageList: ImageAndBackground[];
  maxGridWidth: number;
  onSelectImage(imageName: string, backgroundID?: number): void;
  primaryImage?: ImageAndBackground | null;
  showBackgroundViewer?: boolean;
  showNames: boolean;
  singlePose?: boolean;
}

const BASE_ROW_HEIGHT = 200;
const DEFAULT_PRIMARY_W_PERCENT = 0.2;
const NUM_ACCENT_BRICKS = 2;
const IMG_GAP = 5;
const IMG_MAX = 500;
const IMG_MIN = 200;

const MasonryGrid = ({
  imageList,
  maxGridWidth,
  onSelectImage,
  primaryImage,
  showBackgroundViewer,
  showNames,
  singlePose,
}: Props) => {
  // Redux
  const { images } = useSelector(selectGallery);
  const backgroundsMap = useSelector(selectBackgroundsMap);

  // State
  const [accentBricks, setAccentBricks] = useState<ImageAndBackground[][]>([]);
  const [lastImgW, setLastImgW] = useState<number[]>([]);
  const [mainBricks, setMainBricks] = useState<ImageAndBackground[][]>([]);
  const [maxW, setMaxW] = useState(0);
  const [maxRowH, setMaxRowH] = useState(600);
  const [primaryDimensions, setPrimaryDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [rowHeights, setRowHeights] = useState<number[]>([]);

  const primaryWidthPercent = useBreakpointValue({ base: 1, sm: 0.4, md: 0.3, lg: 0.2 });
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  // Functions
  const getScaledHeight = (aspectRatio: number, targetW: number) => targetW / aspectRatio;
  const getScaledWidth = (aspectRatio: number, targetH: number) => targetH * aspectRatio;

  const isSameAsPrimary = useCallback(
    ({ backgroundID, imageName }: ImageAndBackground) => {
      return primaryImage?.imageName === imageName && primaryImage.backgroundID === backgroundID;
    },
    [primaryImage],
  );

  // Returns a truncated width if there are only a few images, else returns the original maxGridWidth
  const getGridWidth = useCallback(() => {
    if (!primaryImage || !primaryWidthPercent) {
      return maxGridWidth;
    }
    // Calculate initial width of the primary image
    const initialPrimaryWidth =
      (maxGridWidth + (showBackgroundViewer ? BG_VIEWER_WIDTH : 0)) *
      (primaryWidthPercent || DEFAULT_PRIMARY_W_PERCENT);
    const initialPrimaryHeight = getScaledHeight(
      images[primaryImage.imageName].aspectRatio,
      initialPrimaryWidth,
    );

    // Estimate width needed based on preliminary estimations
    // If there are enough images so it exceeds maxGridWidth then return the full max width
    let predictedAccentW = 0;
    let imageCounter = 0;

    for (const imageAndBG of imageList) {
      const { imageName } = imageAndBG;
      const image = images[imageName];

      if (isSameAsPrimary(imageAndBG)) {
        continue;
      }
      const scaledImageWidth = getScaledWidth(
        image.aspectRatio,
        initialPrimaryHeight / NUM_ACCENT_BRICKS,
      );

      if (predictedAccentW + scaledImageWidth + IMG_GAP > maxGridWidth - initialPrimaryWidth) {
        return maxGridWidth;
      }
      imageCounter++;
      predictedAccentW += scaledImageWidth + IMG_GAP;
    }

    if (!imageCounter) {
      // When there's only one primary image, return the primary image width
      return initialPrimaryWidth;
    }

    return Math.ceil(initialPrimaryWidth + IMG_GAP + predictedAccentW);
  }, [
    imageList,
    images,
    isSameAsPrimary,
    maxGridWidth,
    primaryImage,
    primaryWidthPercent,
    showBackgroundViewer,
  ]);

  // Calculate the final accent width based on the aspect ratios of the primary image and accent rows
  const getAccentWidth = useCallback(
    (rowsAR: number[], accentWidth: number, gridWidth: number) => {
      if (!primaryImage) {
        return 0;
      }
      const aspectRatio = images[primaryImage.imageName].aspectRatio;

      if (!accentWidth) {
        setPrimaryDimensions({
          width: gridWidth,
          height: Math.ceil(getScaledHeight(aspectRatio, gridWidth)),
        });
        return 0;
      }

      // Calculate the # of bottom margins to take into account
      const bottomMargin = IMG_GAP * (Math.min(rowsAR.length, NUM_ACCENT_BRICKS) - 1);

      // Calculate the sum of the heights of the accent rows given available width
      const accentHeight = rowsAR
        .slice(0, NUM_ACCENT_BRICKS)
        .reduce(
          (accentHeight, rowAR) =>
            accentHeight + Math.max(IMG_MIN, getScaledHeight(rowAR, accentWidth)) + IMG_GAP,
          bottomMargin,
        );

      // Calculate final target height for primary + accent using GridAR = PrimaryAR + AccentAR
      const accentBlockAR = accentWidth / accentHeight;
      const calculatedHeight = Math.ceil(gridWidth / (aspectRatio + accentBlockAR));

      // Rescale primary block and accent block based on calculatedHeight
      const finalPrimaryWidth = Math.ceil(getScaledWidth(aspectRatio, calculatedHeight));

      setPrimaryDimensions({ width: finalPrimaryWidth, height: calculatedHeight });
      return gridWidth - finalPrimaryWidth;
    },
    [images, primaryImage],
  );

  const getRowHeights = useCallback(
    (
      bricks: ImageAndBackground[][],
      rowsAR: number[],
      finalAccentWidth: number,
      gridWidth: number,
    ) => {
      // Calculate the rowHeights for each row using the aspect ratio + available width space
      const rowHeights = rowsAR.map((ar, index) => {
        const targetWidth =
          finalAccentWidth && index < NUM_ACCENT_BRICKS ? finalAccentWidth : gridWidth;
        return Math.ceil(getScaledHeight(ar, targetWidth));
      });

      // Take any fractional pixels into account and add it to the last image
      const lastImageAddWidth = bricks.map((brick, index) => {
        let lastImageWidth = 0;
        const remainder = brick.reduce((result, { imageName }) => {
          const image = images[imageName];
          lastImageWidth = getScaledWidth(image.aspectRatio, rowHeights[index]);
          const remainder = lastImageWidth - Math.floor(lastImageWidth);
          return result + remainder;
        }, 0);

        return Math.ceil(lastImageWidth + remainder);
      });

      setRowHeights(rowHeights);
      setLastImgW(lastImageAddWidth);

      // Get average of all row heights (except last row) to get max (used only for logic in orphaned images)
      const sumHeight = rowHeights
        .slice(0, rowHeights.length - 1)
        .reduce((maxHeight, h) => maxHeight + h, 0);

      const maxHeight = Math.ceil(sumHeight / (rowHeights.length - 1));
      setMaxRowH(maxHeight);
    },
    [images],
  );

  // Create the rows/bricks used to build each row of images
  const createBricks = useCallback(
    (accentWidth: number, gridWidth: number, rowHeight: number) => {
      // Initialize arrays for rows + aspect ratios
      const bricks: ImageAndBackground[][] = [[]];
      const rowsAR: number[] = [];

      // Initialize counters for tracking the current width and aspect ratio of each row
      let counter = 0;
      let rowAR = 0;

      // Loop over images to create the bricks/rows and get AR of each row
      for (const imageAndBG of imageList) {
        const { imageName } = imageAndBG;
        const image = images[imageName];
        if (isSameAsPrimary(imageAndBG)) {
          continue;
        }

        // Calculate if target width is based on available accent space or full grid width
        const targetWidth =
          accentWidth && bricks.length <= NUM_ACCENT_BRICKS ? accentWidth - IMG_GAP : gridWidth;

        // Calculate # scaled images that can fit in target space, including any margins
        const scaledImageWidth = getScaledWidth(image.aspectRatio, rowHeight);
        const imgGap = counter === 0 ? 0 : IMG_GAP;

        if (
          counter + scaledImageWidth + imgGap < targetWidth ||
          (rowAR === 0 && bricks[0].length === 0)
        ) {
          rowAR += (scaledImageWidth + imgGap) / rowHeight;
          counter += scaledImageWidth + imgGap;
          bricks[bricks.length - 1].push(imageAndBG);
        } else {
          // Add the AR to the list
          rowAR !== 0 && rowsAR.push(rowAR);
          // And reset counter + AR
          rowAR = scaledImageWidth / rowHeight;
          counter = scaledImageWidth;
          // Either push an array or to the old one
          bricks[bricks.length - 1].length !== 0
            ? bricks.push([imageAndBG])
            : bricks[bricks.length - 1].push(imageAndBG);
        }
      }
      // Push any remaining values for last row in
      rowsAR.push(rowAR);

      // Get final accent container width
      const finalAccentWidth = getAccentWidth(rowsAR, accentWidth, gridWidth);

      // Calculate the row heights using AR of each row
      getRowHeights(bricks, rowsAR, finalAccentWidth, gridWidth);

      return bricks;
    },
    [getAccentWidth, getRowHeights, imageList, images, isSameAsPrimary],
  );

  // Main useeffect
  useEffect(() => {
    const gridWidth = getGridWidth();
    setMaxW(gridWidth); // Used for flex container

    if (primaryImage) {
      const aspectRatio = images[primaryImage.imageName].aspectRatio;
      // Calculate initial values that are dependent on a primary image
      // Get the available width for accent
      // Which is the max container width minus the width of the primary (scaled to match base width)
      const primaryWidth =
        (maxGridWidth + (showBackgroundViewer ? BG_VIEWER_WIDTH : 0)) *
        (primaryWidthPercent || DEFAULT_PRIMARY_W_PERCENT);
      const primaryHeight = getScaledHeight(aspectRatio, primaryWidth);
      const accentWidth = gridWidth - primaryWidth;

      // Set initial target accent height
      const rowHeight = Math.max(IMG_MIN, Math.min(primaryHeight, IMG_MAX) / NUM_ACCENT_BRICKS);

      // Create bricks
      const bricks = createBricks(accentWidth, gridWidth, isMobile ? BASE_ROW_HEIGHT : rowHeight);

      // Slice returned values based on # of accent bricks
      const accent = accentWidth ? bricks.slice(0, NUM_ACCENT_BRICKS) : [];
      const main = accentWidth ? bricks.slice(NUM_ACCENT_BRICKS) : bricks;

      setAccentBricks(accent);
      setMainBricks(main);
    } else {
      const bricks = createBricks(0, gridWidth, BASE_ROW_HEIGHT);
      setMainBricks(bricks);
    }
  }, [
    createBricks,
    getGridWidth,
    images,
    isMobile,
    maxGridWidth,
    primaryImage,
    primaryWidthPercent,
    showBackgroundViewer,
  ]);

  // The 'just make it work' height/width handlers for pixel-perfect sizing/edge cases
  const getMainImageHeight = (
    brickLength: number,
    isLastRow: boolean,
    prevBrickLength: number,
    rowHeight: number,
  ) => {
    // When there is a singular non-primary image
    if (brickLength === 1 && !prevBrickLength && isLastRow && !primaryImage) {
      return `${Math.min(Math.ceil(rowHeight / 2), IMG_MAX)}px`;
    }
    // When its the last row and filling the space would result in images that are too large
    if (brickLength <= 3 && (prevBrickLength !== 1 || !prevBrickLength) && isLastRow) {
      return `${Math.min(IMG_MAX, maxRowH, rowHeight)}px`;
    }
    return `${rowHeight}px`;
  };

  const getAccentImageHeight = (isLastRow: boolean, prevBrickLength: number, rowHeight: number) => {
    // If single row of accent bricks
    if (isLastRow && !prevBrickLength) {
      return `${Math.ceil(primaryDimensions.height / 2)}px`;
    }
    return `${rowHeight}px`;
  };

  const getPrimaryHeight = (primaryHeight: number) => {
    // Get difference between the two values to force pixel adjustment
    const bottomMargin = IMG_GAP * (accentBricks.length - 1);
    const sumAccentHeights = rowHeights
      .slice(0, NUM_ACCENT_BRICKS)
      .reduce((height, rowH) => height + rowH, bottomMargin);

    return Math.abs(primaryHeight - sumAccentHeights) <= 10 ? sumAccentHeights : primaryHeight;
  };

  const getImageWidth = (imageAR: number, lastImage: boolean, rowIndex: number) => {
    // Get difference between the two values to force pixel adjustment if needed
    if (lastImage && maxW === maxGridWidth) {
      const scaledWidth = getScaledWidth(imageAR, rowHeights[rowIndex]);
      return Math.abs(lastImgW[rowIndex] - scaledWidth) <= 10 ? `${lastImgW[rowIndex]}px` : 'auto';
    }
    return 'auto';
  };

  const getBackground = (backgroundID?: number) => {
    return backgroundID ? backgroundsMap[backgroundID]?.sources.full : undefined;
  };

  const renderPrimary = () => {
    if (!primaryImage) {
      return;
    }
    const { backgroundID, imageName } = primaryImage;
    const image = images[imageName];

    return (
      <Thumbnail
        background={getBackground(backgroundID)}
        cursor="pointer"
        data-test="masonry-grid-thumbnail-images"
        display="flex"
        displayName={image.displayName}
        height={`${getPrimaryHeight(primaryDimensions.height)}px`}
        onClick={() => onSelectImage(imageName, backgroundID)}
        showNames={showNames}
        src={image.sources.thumb}
        width={`${primaryDimensions.width}px`}
      />
    );
  };

  const renderAccentGrid = () =>
    accentBricks.map((brick, rowIndex) => (
      <Flex
        key={`accentBrick-${rowIndex}`}
        flexFlow="row"
        marginBottom={rowIndex !== accentBricks.length - 1 ? `${IMG_GAP}px` : ''}
      >
        {brick.map(({ backgroundID, imageName }, index) => (
          <Flex
            key={singlePose ? `${imageName}-${backgroundID}` : imageName}
            marginLeft={`${IMG_GAP}px`}
          >
            <Thumbnail
              background={getBackground(backgroundID)}
              cursor="pointer"
              data-test="masonry-grid-thumbnail-images"
              display="flex"
              displayName={images[imageName].displayName}
              height={getAccentImageHeight(
                accentBricks.length - 1 === rowIndex,
                accentBricks[rowIndex - 1]?.length,
                rowHeights[rowIndex],
              )}
              onClick={() => onSelectImage(imageName, backgroundID)}
              showNames={showNames}
              src={images[imageName].sources.thumb}
              width={getImageWidth(
                images[imageName].aspectRatio,
                index === brick.length - 1,
                rowIndex,
              )}
              lazyLoad
            />
          </Flex>
        ))}
      </Flex>
    ));

  const renderMainGrid = () =>
    mainBricks.map((brick, rowIndex) => (
      <Flex key={`mainBrick-${rowIndex}`} flexFlow="row" marginBottom={`${IMG_GAP}px`}>
        {brick.map(({ backgroundID, imageName }, index) => (
          <Flex
            key={singlePose ? `${imageName}-${backgroundID}` : imageName}
            marginLeft={`${index !== 0 ? IMG_GAP : 0}px`}
          >
            <Thumbnail
              background={getBackground(backgroundID)}
              cursor="pointer"
              data-test="masonry-grid-thumbnail-images"
              display="flex"
              displayName={images[imageName].displayName}
              height={getMainImageHeight(
                brick.length,
                mainBricks.length - 1 === rowIndex,
                mainBricks[rowIndex - 1]?.length,
                rowHeights[rowIndex + accentBricks?.length],
              )}
              onClick={() => onSelectImage(imageName, backgroundID)}
              showNames={showNames}
              src={images[imageName].sources.thumb}
              width={getImageWidth(
                images[imageName].aspectRatio,
                index === brick.length - 1 && mainBricks.length - 1 !== rowIndex,
                rowIndex + accentBricks?.length,
              )}
              lazyLoad
            />
          </Flex>
        ))}
      </Flex>
    ));

  return (
    <Flex flexFlow="column" marginBottom="40px" maxW={`${maxW}px`}>
      {!!primaryImage && (
        <Flex flexFlow="row" marginBottom={`${IMG_GAP}px`}>
          {renderPrimary()}
          <Flex flexFlow="column">{renderAccentGrid()}</Flex>
        </Flex>
      )}
      <Flex flexFlow="column">{renderMainGrid()}</Flex>
    </Flex>
  );
};

export default MasonryGrid;
