import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { ShopBackground } from '../../../../shop-api-client';

interface Props {
  background?: ShopBackground | null;
  imageName?: string;
  width: string;
}

const ImageDisplayName = ({ background, imageName, width }: Props) => (
  <Box
    background="black"
    bottom="0"
    fontWeight="bold"
    lineHeight="1.5"
    opacity="0.6"
    padding="5px"
    position="absolute"
    textAlign="center"
    width={width}
  >
    <Text
      color="white"
      fontSize="md"
      fontWeight="bold"
      noOfLines={1}
      paddingX="5px"
      textAlign="left"
      width="100%"
      data-test="image-display-name"
    >
      {imageName}
    </Text>
    {!!background && (
      <Text color="grey.2" fontSize="sm" noOfLines={1} paddingX="5px" textAlign="left" width="100%">
        on {background.name}
      </Text>
    )}
  </Box>
);

export default ImageDisplayName;
