import { Button, ButtonProps, Flex, Icon, IconProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props extends ButtonProps {
  'data-test'?: string;
  leftIcon?: any;
  leftIconProps?: IconProps;
  onClick?(): void;
  text?: string;
  to: string;
}

const NavigationAction = ({
  'data-test': dataTest,
  leftIcon,
  leftIconProps,
  onClick,
  text,
  to,
  ...buttonProps
}: Props) => {
  const history = useHistory();
  const handleClick = (to: string, callback?: () => void) => () => {
    if (callback) {
      callback();
    }
    history.push(to);
  };
  return (
    <Button
      data-test={dataTest}
      onClick={handleClick(to, onClick)}
      paddingX="2px"
      variant="nav"
      minWidth="35px"
      {...buttonProps}
    >
      <Flex justifyContent="center">
        {!!leftIcon && <Icon as={leftIcon} fontSize="lg" {...leftIconProps} />}
        {!!text && <Text marginLeft={leftIcon && '8px'}>{text}</Text>}
      </Flex>
    </Button>
  );
};

export default NavigationAction;
