import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCurrentVisitKey } from '../../../../redux/selectors/visitor.selectors';
import { toggleShowFindGalleryModal } from '../../../../redux/slices/interactions.slice';
import ArrowLeftbutton from '../../../../shared/components/ArrowLeftButton';
import GallerySearch from '../../../AccountLanding/GallerySearch';
import MultiGalleryLogin from '../../MultiGalleryLogin';

const FindGalleryModal = (props: Omit<ModalProps, 'children'>) => {
  const currentVisitKey = useSelector(selectCurrentVisitKey);

  const [nextVisitKey, setNextVisitKey] = useState('');

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  if (isMobile) {
    return null;
  }

  const backToFindGalleryText = intl.formatMessage({
    id: 'findGalleryModal.backToFindGallery',
    defaultMessage: 'Back to Find Gallery',
  });

  const overlayBackgroundColor = CSS.supports('backdrop-filter', 'blur()')
    ? 'blackAlpha.300'
    : 'blackAlpha.600';

  const handleBackButtonClick = () => {
    setNextVisitKey('');
  };

  const handleResults = (nextVisitKey: string | null, query: string) => {
    if (nextVisitKey) {
      setNextVisitKey(nextVisitKey);
      return;
    }
    dispatch(toggleShowFindGalleryModal(false));
    history.push(`/${currentVisitKey}/galleries/find?${query}`, { prevPath: pathname });
  };

  const renderModalContent = () => {
    if (nextVisitKey) {
      return (
        <Flex paddingY={4}>
          <ArrowLeftbutton
            aria-label={backToFindGalleryText}
            color="grey.5"
            fontSize="xl"
            lightMode
            onClick={handleBackButtonClick}
          />
          <MultiGalleryLogin nextVisitKey={nextVisitKey} handleBack={handleBackButtonClick} />
        </Flex>
      );
    }

    return (
      <GallerySearch accountKey={null} visitKey={currentVisitKey} handleResults={handleResults} />
    );
  };

  return (
    <Modal motionPreset="none" size="" {...props}>
      <ModalOverlay backgroundColor={overlayBackgroundColor} backdropFilter="blur(5px)" />
      <ModalContent
        alignItems="center"
        backgroundColor="white"
        marginTop="145px"
        minHeight="260px"
        rounded="none"
      >
        {renderModalContent()}
      </ModalContent>
    </Modal>
  );
};

export default FindGalleryModal;
