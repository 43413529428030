import { Button, Center, Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '../../../shared/types/router';
import MultiGalleryLogin from '../MultiGalleryLogin';

const GalleryLogin = () => {
  const { key, nextKey } = useParams<Params>();
  const history = useHistory();
  const intl = useIntl();

  const backToResults = intl.formatMessage({
    id: 'multiGallery.backToResults',
    defaultMessage: 'back to results',
  });

  const handleBackButtonClick = () => history.push(`/${key}/galleries/find`);

  return (
    <Center>
      <Flex direction="column" alignItems="flex-start" maxWidth="484px" paddingY={4}>
        <Button
          aria-label={backToResults}
          onClick={handleBackButtonClick}
          leftIcon={<Icon as={HiOutlineArrowLeft} color="grey.8" marginRight="3px" />}
          variant="nav"
        >
          {backToResults}
        </Button>
        <MultiGalleryLogin nextVisitKey={nextKey} handleBack={handleBackButtonClick} />
      </Flex>
    </Center>
  );
};
export default GalleryLogin;
