import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ImageTone } from '../../../../../../../shop-api-client/models/Cart';

interface Props {
  selectedTone: ImageTone;
  selectTone(tone: ImageTone): void;
  useOffWhite?: boolean;
}

const degrees = '135deg';
const black = '#5c5d5f';
const offWhite = '#f0f5f6';
const white = '#ffffff';
const yellow = '#ddb55d';
const paleYellow = '#e4d578';
const red = '#FF0000';

/** Renders a section that for a SINGLE */
const ImageNodeToneDisplay = ({ selectedTone, selectTone, useOffWhite = true }: Props) => {
  const intl = useIntl();

  const none = intl.formatMessage({
    id: 'imageNode.toneDisplay.none',
    defaultMessage: 'None',
  });
  const blackAndWhite = intl.formatMessage({
    id: 'imageNode.toneDisplay.blackAndWhite',
    defaultMessage: 'Black & White',
  });
  const sepia = intl.formatMessage({
    id: 'imageNode.toneDisplay.sepia',
    defaultMessage: 'Sepia',
  });

  const getTwoToneBg = (colorOne: string, colorTwo: string) =>
    `linear-gradient(${degrees}, ${colorOne} 0%, ${colorOne} 50%, ${colorTwo} 50%, ${colorTwo} 100%)`;

  const getOneToneBg = (colorOne: string, colorTwo: string) =>
    `linear-gradient(${degrees}, ${colorOne} 49.5%, ${colorTwo} 50%, ${colorOne} 50.5%)`;

  const renderToneBox = (value: ImageTone, background: string, name: string) => (
    <Flex direction="column" alignItems="center">
      <Box
        borderRadius="md"
        borderWidth="2px"
        borderColor={selectedTone === value ? 'brand' : 'transparent'}
      >
        <Box
          data-test="image-tone-selection"
          background={background}
          height="50px"
          width="50px"
          borderRadius="md"
          margin={1}
          onClick={() => selectTone(value)}
        />
      </Box>

      <Text textAlign="center" fontSize="xs" width="50px">
        {name}
      </Text>
    </Flex>
  );

  return (
    <HStack spacing={4} alignItems="flex-start">
      {renderToneBox('original', getOneToneBg(useOffWhite ? offWhite : white, red), none)}
      {renderToneBox('bw', getTwoToneBg(black, useOffWhite ? offWhite : white), blackAndWhite)}
      {renderToneBox('sepia', getTwoToneBg(yellow, paleYellow), sepia)}
    </HStack>
  );
};

export default ImageNodeToneDisplay;
