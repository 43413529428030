import { Box, BoxProps, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import ArrowLeftbutton from '../../../shared/components/ArrowLeftButton';
import ArrowRightButton from '../../../shared/components/ArrowRightButton';
import { NAVIGATE_LEFT, NAVIGATE_RIGHT } from '../../../shared/constants';
import useOnEvent from '../../../shared/hooks/useOnEvent';
import { Direction } from '../../MyPhotos/constants';
import ThumbnailCarouselItem from './ThumbnailCarouseltem';

interface Props extends BoxProps {
  images: string[];
  selectedImage: string;
  setSelectedImage(selectedImage: string): void;
}

const ThumbnailCarousel = ({ images, selectedImage, setSelectedImage, ...boxProps }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  useOnEvent('keydown', (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      handleNavigate('prev');
    }
    if (e.key === 'ArrowRight') {
      handleNavigate('next');
    }
  });

  const handleNavigate = (direction: Direction) => {
    const index = images.indexOf(selectedImage);
    const newIndex = direction === 'next' ? index + 1 : index - 1;

    if (newIndex > images.length - 1) {
      setSelectedImage(images[0]);
      return;
    }
    if (newIndex < 0) {
      setSelectedImage(images[images.length - 1]);
      return;
    }
    setSelectedImage(images[newIndex]);
  };

  return (
    <Box paddingBottom="24px" position="relative" width="100%" paddingTop="18px" {...boxProps}>
      <Flex justifyContent="center">
        <Flex
          justify="space-between"
          left={`-${isMobile ? 0 : 15}px`}
          position="absolute"
          top="32px"
          width={`calc(100% + ${isMobile ? 0 : 30}px)`}
        >
          <ArrowLeftbutton
            aria-label={NAVIGATE_LEFT}
            fontSize="18px"
            onClick={() => handleNavigate('prev')}
            size="sm"
            lightMode
          />

          <ArrowRightButton
            aria-label={NAVIGATE_RIGHT}
            fontSize="18px"
            onClick={() => handleNavigate('next')}
            size="sm"
            lightMode
          />
        </Flex>
        <Flex
          direction="row"
          gap="8px"
          overflowX="auto"
          sx={{
            scrollSnapType: 'x mandatory',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          width="fit-content"
        >
          {images.map((image, index) => (
            <ThumbnailCarouselItem
              key={`${image}-${index}`}
              image={image}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ThumbnailCarousel;
