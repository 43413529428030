import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
}

const RoundedBorderBox = ({ children, ...rest }: Props) => (
  <Box borderColor="grey.2" borderWidth="1px" borderRadius={10} padding={4} {...rest}>
    {children}
  </Box>
);

export default RoundedBorderBox;
