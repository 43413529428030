import { Divider, Flex } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectBackgroundsMap } from '../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import ArrowLeftbutton from '../../../../shared/components/ArrowLeftButton';
import ArrowRightButton from '../../../../shared/components/ArrowRightButton';
import Thumbnail from '../../../../shared/components/Thumbnail';
import { NAVIGATE_LEFT, NAVIGATE_RIGHT } from '../../../../shared/constants';
import { HIDE_SCROLLBAR_STYLES } from '../../../../shared/constants/style.constants';
import { ImageAndBackground } from '../../../../shared/types/image';
import { Params } from '../../../../shared/types/router';
import { getAspectRatio, getImageWidthByRatio, getUniqueImageKey } from '../../../../shared/utils';
import { VERTICAL_MARGIN, VERTICAL_WIDTH } from '../../../../theme/components/divider';
import { BG_VIEWER_WIDTH, Direction, NEXT, PREV } from '../../constants';
import { IMAGE_SLIDER_HEIGHT, THUMB_HEIGHT, THUMB_MARGIN_X, THUMB_MARGIN_Y } from '../constants';
import { SIDE_PANEL_WIDTH } from '../ImageViewerSidePanel/ImageViewerSidePanel';

interface ThumbPositionMap {
  [key: string]: {
    thumbWidth: number;
    position: number;
  };
}

const NAV_CONTAINER_WIDTH = 60;
const BASE_SUBTRAHEND = NAV_CONTAINER_WIDTH * 2 + SIDE_PANEL_WIDTH;
const DIVIDER_WIDTH = VERTICAL_WIDTH + VERTICAL_MARGIN * 2;

const THUMBNAIL_STYLES = {
  cursor: 'pointer',
  'data-test': 'image-slider-thumbnail-image',
  height: `${THUMB_HEIGHT}px`,
  hideSpinner: true,
  margin: `${THUMB_MARGIN_Y}px ${THUMB_MARGIN_X}px`,
  maxWidth: 'unset',
  sx: { scrollSnapAlign: 'start', scrollSnapStop: 'normal' },
};

interface Props {
  groupImages: ImageAndBackground[];
  isViewerExpanded: boolean;
  onSelectImage(imageName: string, backgroundID?: number): void;
  selectedIndex: number;
  setDirection(direction: Direction): void;
  subjectImages: ImageAndBackground[];
  isSingleImage?: boolean;
}

const ImageSlider = ({
  groupImages,
  isViewerExpanded,
  onSelectImage,
  selectedIndex,
  setDirection,
  subjectImages,
  isSingleImage,
}: Props) => {
  const { images } = useSelector(selectGallery);
  const backgroundsMap = useSelector(selectBackgroundsMap);

  const [thumbPositionMap, setThumbPositionMap] = useState<ThumbPositionMap>({});
  const [canInitScroll, setCanInitScroll] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [isContainerEnd, setIsContainerEnd] = useState(false);

  const { backgroundID: paramsBackgroundID, photoID } = useParams<Params>();
  const container = useRef<HTMLDivElement>(null);
  const viewContainer = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const selectedImageKey = getUniqueImageKey(photoID, parseInt(paramsBackgroundID));
  // The amount to subtract from the thumbnails container width,
  // dependent upon whether the Background Viewer is expanded:
  const widthSubtrahend = isMobile
    ? NAV_CONTAINER_WIDTH * 2
    : BASE_SUBTRAHEND + (isViewerExpanded ? BG_VIEWER_WIDTH : 0);

  useEffect(() => {
    // If thumbPositionMap has been set, ensure selected image thumbnail is in view,
    if (
      container.current &&
      Object.keys(thumbPositionMap).length &&
      thumbPositionMap[selectedImageKey]
    ) {
      const { position, thumbWidth } = thumbPositionMap[selectedImageKey];

      // If canInitScroll is true, set container's scrollLeft position,
      // so that the selected thumbnail is centered in the container
      if (canInitScroll) {
        const widthWithoutThumb = container.current.clientWidth - thumbWidth;
        container.current!.scrollLeft = position - widthWithoutThumb / 2;
        setCanInitScroll(false);

        // If the selected thumbnail position is right of view,
        // set scrollLeft to position that displays selected thumbnail last in view
      } else if (
        container.current.scrollLeft + container.current.clientWidth <
        position + thumbWidth
      ) {
        const widthWithoutThumb = container.current.clientWidth - thumbWidth;
        container.current!.scrollLeft = position - widthWithoutThumb;

        // Else if selected thumbnail is left of images in view, set scrollLeft
        // to display the selected thumbnail first in the list of visible images
      } else if (container.current.scrollLeft > position) {
        container.current!.scrollLeft = position;
      }
      // short-circuit, since thumbPositionMap has been set
      return;
    }

    // Set thumbPositionMap
    // thumbPositionMap is keyed by image name, and tracks each thumbnail's width
    // and its horizontal position in the container

    let imageWidthTotal = DIVIDER_WIDTH;

    const newThumbPositionMap = [...subjectImages, ...groupImages].reduce<ThumbPositionMap>(
      (res, { backgroundID, imageName }) => {
        const { height, width } = images[imageName];
        const aspectRatio = getAspectRatio(height, width);

        const thumbWidth = getImageWidthByRatio(height > width, THUMB_HEIGHT, aspectRatio);
        res[getUniqueImageKey(imageName, backgroundID)] = { thumbWidth: 0, position: 0 };
        res[getUniqueImageKey(imageName, backgroundID)].thumbWidth = thumbWidth;
        res[getUniqueImageKey(imageName, backgroundID)].position = imageWidthTotal;
        imageWidthTotal += thumbWidth + THUMB_MARGIN_X * 2;
        return res;
      },
      {},
    );

    setThumbPositionMap(newThumbPositionMap);
  }, [canInitScroll, groupImages, images, selectedImageKey, subjectImages, thumbPositionMap]);

  useEffect(() => {
    if (!container.current) {
      return;
    }
    const containerRef = container.current; // local variable is required for cleanup

    // Scan scroll coordinates to toggle displaying navigation arrows
    const scanCoordinates = () => {
      // If left arrow is not visible and scroll is greater than thumbnail margin
      // (so part of the first thumbnail is now concealed), then show arrow:
      if (!showLeftArrow && containerRef.scrollLeft > THUMB_MARGIN_X) {
        setShowLeftArrow(true);
        // If scroll is less than or equal to thumbnail margin, hide left arrow:
      } else if (showLeftArrow && containerRef.scrollLeft <= THUMB_MARGIN_X) {
        setShowLeftArrow(false);
      }

      // If isContainerEnd is true, but the sum of the current scrollLeft coordinates
      // and the visible container width are no longer equal to the full container's width,
      // then set isContainerEnd to false;
      if (
        isContainerEnd &&
        containerRef.scrollLeft + containerRef.clientWidth !== containerRef.scrollWidth
      ) {
        setIsContainerEnd(false);
        // If isContainerEnd is false and the sum of scrollLeft coordinates and the visible container
        // width equal the full container's width, then set isContainerEnd to true:
      } else if (
        !isContainerEnd &&
        containerRef.scrollLeft + containerRef.clientWidth === containerRef.scrollWidth
      ) {
        setIsContainerEnd(true);
      }
    };

    // If all thumbnails fit in the visible width of the container, set isContainerEnd to true:
    if (!isContainerEnd && containerRef.scrollWidth <= containerRef.clientWidth) {
      setIsContainerEnd(true);
    }

    containerRef.addEventListener('scroll', scanCoordinates);
    return () => {
      containerRef.removeEventListener('scroll', scanCoordinates);
    };
  }, [showLeftArrow, isContainerEnd]);

  const navigate = (direction: Direction) => {
    if (!container.current) {
      return;
    }
    const { clientWidth } = container.current;

    container.current.scrollBy({
      top: 0,
      left: direction === NEXT ? clientWidth : -clientWidth,
      behavior: 'smooth',
    });
  };

  const handleSelect = (imageName: string, backgroundID?: number) => () => {
    const nextIndex = [...subjectImages, ...groupImages].findIndex(
      i => `${i.imageName}-${i.backgroundID}` === `${imageName}-${backgroundID}`,
    );

    const direction = nextIndex > selectedIndex ? NEXT : PREV;

    setDirection(direction);
    onSelectImage(imageName, backgroundID);
  };

  /**
   * The selected thumbnail must be centered in view on page load. However, the scrollLeft
   * value cannot be modified on the container's reference until the last image has been loaded and
   * the DOM node has been painted.
   * Toggling canInitScroll to true allows container.current.scrollLeft to be set to the
   * expected position when the element is ready.
   */
  const handleInitScroll = (isLast: boolean) => {
    if (!isLast) {
      return;
    }
    setCanInitScroll(true);
  };

  return (
    <Flex
      align="center"
      backgroundColor="grey.10"
      height={`${IMAGE_SLIDER_HEIGHT}px`}
      ref={viewContainer}
    >
      <Flex justify="center" minWidth={`${NAV_CONTAINER_WIDTH}px`}>
        <ArrowLeftbutton
          aria-label={NAVIGATE_LEFT}
          backgroundColor="grey.9"
          disabled={!showLeftArrow}
          marginRight="5px"
          onClick={() => navigate(PREV)}
          size="sm"
          data-test="image-slider-navigate-left"
        />
      </Flex>
      <Flex
        css={HIDE_SCROLLBAR_STYLES}
        overflowX="scroll"
        ref={container}
        sx={{ scrollSnapType: 'x mandatory' }}
        width={`calc(100vw - ${widthSubtrahend}px)`}
        zIndex={0}
      >
        {subjectImages.map(({ backgroundID, imageName }, i) => (
          <Thumbnail
            key={isSingleImage ? getUniqueImageKey(imageName, backgroundID) : imageName}
            background={backgroundID ? backgroundsMap[backgroundID]?.sources.thumb : undefined}
            isActive={
              photoID === imageName &&
              (!backgroundID || backgroundID === parseInt(paramsBackgroundID))
            }
            onClick={handleSelect(imageName, backgroundID)}
            onImageLoad={() => handleInitScroll(!groupImages.length && !subjectImages[i + 1])}
            src={images[imageName].sources.thumb}
            lazyLoadOptions={{ root: viewContainer.current }}
            {...THUMBNAIL_STYLES}
          />
        ))}
        {!!subjectImages.length && !!groupImages.length && (
          <Divider height="auto" orientation="vertical" variant="vertical" />
        )}
        {groupImages.map(({ backgroundID, imageName }, i) => (
          <Thumbnail
            key={isSingleImage ? getUniqueImageKey(imageName, backgroundID) : imageName}
            background={
              images[imageName].isGreenScreen && backgroundID
                ? backgroundsMap[backgroundID]?.sources.thumb
                : undefined
            }
            isActive={getUniqueImageKey(imageName, backgroundID) === selectedImageKey}
            onClick={handleSelect(imageName, backgroundID)}
            onImageLoad={() => handleInitScroll(!groupImages[i + 1])}
            src={images[imageName].sources.thumb}
            lazyLoadOptions={{ root: viewContainer.current }}
            {...THUMBNAIL_STYLES}
          />
        ))}
      </Flex>
      <Flex justify="center" minWidth={`${NAV_CONTAINER_WIDTH}px`}>
        <ArrowRightButton
          aria-label={NAVIGATE_RIGHT}
          backgroundColor="grey.9"
          disabled={isContainerEnd}
          marginLeft="5px"
          onClick={() => navigate(NEXT)}
          size="sm"
          data-test="image-slider-navigate-right"
        />
      </Flex>
    </Flex>
  );
};

export default ImageSlider;
