import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShopBackground } from '../../../shop-api-client';
import {
  CartPackage,
  CartProduct,
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../shop-api-client/models/Cart';
import {
  ImageOptionConfigMap,
  ImageOptionSelectionConfigMap,
} from '../../../shop-api-client/models/ShopConfig';
import { reset } from './checkout.slice';
import { signout } from './visitor.slice';

const setBackgroundOnItem = (
  editItem: CartPackage | CartProduct,
  background: ShopBackground,
): CartPackage | CartProduct => {
  const copy = { ...editItem };
  if (copy.type === 'product') {
    // Set the background on each image node:
    copy.nodes = copy.nodes.map(node => ({
      ...node,
      backgroundID: node.type === 'image' ? background.id : null,
    }));
  } else if (copy.type === 'collection' || copy.type === 'imageDownload') {
    // Set the background on each collectionImage:
    copy.collectionImages = copy.collectionImages.map(image => ({
      ...image,
      backgroundID: background.id,
    }));
  } else if (copy.type === 'buildYourOwn' || copy.type === 'standard') {
    return {
      ...copy,
      products: copy.products.map(p => setBackgroundOnItem(p, background)),
    } as CartPackage;
  }

  return copy;
};

const initialState: {
  completedPkgItems: Record<string, boolean>;
  completedSteps: Record<string, boolean>;
  editImageOptionsMap: ImageOptionConfigMap;
  editNodeID: number | null;
  editPackage: CartPackage | CreateCartPackageReq | null;
  editPackageProduct: CartProduct | CreateCartProductReq | null;
  editPackageStep: string | null;
  editProduct: CartProduct | CreateCartProductReq | null;
  editStep: string | null;
  invalidSteps: Record<string, boolean>;
  optedOutProductOptions: Record<string, boolean>;
  showMultiNodeModal: boolean;
} = {
  completedPkgItems: {},
  completedSteps: {},
  editImageOptionsMap: {},
  editNodeID: null,
  editPackage: null,
  editPackageProduct: null,
  editPackageStep: null,
  editProduct: null,
  editStep: null,
  invalidSteps: {},
  optedOutProductOptions: {},
  showMultiNodeModal: false,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    resetConfiguration() {
      return initialState;
    },
    resetCompletedSteps(state) {
      state.completedSteps = {};
    },
    setCompletedPkgItems(state, action: PayloadAction<Record<string, boolean>>) {
      state.completedPkgItems = {
        ...state.completedPkgItems,
        ...action.payload,
      };
    },
    setCompletedSteps(state, action: PayloadAction<Record<string, boolean>>) {
      for (const step of Object.keys(action.payload)) {
        const completed = action.payload[step];

        state.completedSteps[step] = completed;

        if (completed) {
          // Once completed, it can no longer be invalid:
          state.invalidSteps[step] = false;
        }
      }
    },
    setEditNodeID(state, action: PayloadAction<number | null>) {
      state.editNodeID = action.payload;
    },
    setEditPackage(state, action: PayloadAction<CartPackage | CreateCartPackageReq | null>) {
      state.editPackage = action.payload;

      // If we're swapping between items from the drawer, make sure to clear out the
      // product field:
      if (state.editProduct) {
        state.editProduct = null;
      }
    },
    setEditPackageProduct(state, action: PayloadAction<CartProduct | CreateCartProductReq | null>) {
      state.editPackageProduct = action.payload;
    },
    setEditPackageStep(state, action: PayloadAction<string | null>) {
      state.editPackageStep = action.payload;
    },
    setEditProduct(state, action: PayloadAction<CartProduct | CreateCartProductReq | null>) {
      state.editProduct = action.payload;

      // If we're swapping between items from the drawer, make sure to clear out the
      // package field:
      if (state.editPackage) {
        state.editPackage = null;
      }
    },
    setEditImageOption(
      state,
      action: PayloadAction<{
        priceSheetOptionID: number;
        imageOptionSelections: ImageOptionSelectionConfigMap;
      }>,
    ) {
      // these store image options that are in flux - not yet officially saved,
      // currently saved image options in cart are merged inside the component
      const { priceSheetOptionID, imageOptionSelections } = action.payload;
      state.editImageOptionsMap[priceSheetOptionID] = imageOptionSelections;
    },
    setOptedOutProductOptions(state, action: PayloadAction<string>) {
      if (state.optedOutProductOptions[action.payload]) {
        delete state.optedOutProductOptions[action.payload];
      } else {
        state.optedOutProductOptions[action.payload] = true;
      }
    },
    removeImageOption(state, action: PayloadAction<number>) {
      const priceSheetOptionID = action.payload;
      // set to null so we can reconcile with cart image options
      state.editImageOptionsMap[priceSheetOptionID] = null;
    },
    prefillImageOptions(state, action: PayloadAction<ImageOptionConfigMap>) {
      state.editImageOptionsMap = action.payload;
    },
    setEditItemBackground(
      state,
      action: PayloadAction<{
        background: ShopBackground;
        editSubItem?: CreateCartProductReq;
      }>,
    ) {
      const { background, editSubItem } = action.payload;

      if (state.editPackage) {
        if (editSubItem) {
          state.editPackage = {
            ...state.editPackage,
            products: state.editPackage.products.map(p => {
              if (p.id !== editSubItem.id) {
                return p;
              }
              return setBackgroundOnItem(p as CartProduct, background) as CartProduct;
            }),
          };
        } else {
          state.editPackage = setBackgroundOnItem(
            state.editPackage as CartPackage,
            background,
          ) as CartPackage;
        }
      }
      if (state.editProduct) {
        state.editProduct = setBackgroundOnItem(
          state.editProduct as CartProduct,
          background,
        ) as CartProduct;
      }
      return state;
    },
    setEditStep(state, action: PayloadAction<string | null>) {
      if (state.editPackage) {
        state.editPackageStep = action.payload;
      } else {
        state.editStep = action.payload;
      }
    },
    setInvalidStep(state, action: PayloadAction<Record<string, boolean>>) {
      state.invalidSteps = {
        ...state.invalidSteps,
        ...action.payload,
      };
    },
    setShowMultiNodeModal(state, action: PayloadAction<boolean>) {
      state.showMultiNodeModal = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signout, () => {
        return initialState;
      })
      .addCase(reset, () => {
        return initialState;
      });
  },
});

const { actions, reducer } = configurationSlice;

export const {
  prefillImageOptions,
  removeImageOption,
  resetCompletedSteps,
  resetConfiguration,
  setCompletedPkgItems,
  setCompletedSteps,
  setEditImageOption,
  setEditItemBackground,
  setEditNodeID,
  setEditPackage,
  setEditPackageProduct,
  setEditPackageStep,
  setEditProduct,
  setEditStep,
  setInvalidStep,
  setOptedOutProductOptions,
  setShowMultiNodeModal,
} = actions;

export default reducer;
