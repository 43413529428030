import { Heading } from '@chakra-ui/layout';
import { BoxProps } from '@chakra-ui/react';
import React from 'react';
import {
  ImageRequirementType,
  PriceSheetOption,
  ProductOption,
} from '../../../../../../shop-api-client';
import {
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../../../../shop-api-client/models/Cart';
import { selectEditItemImageMap } from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../redux/store';
import { AVAILABLE_ADD_ONS, OTHER } from '../../../../../shared/constants';
import EditorSectionContainer from '../EditorSectionContainer';
import ImageOptionEditor from '../ImageOptionEditor/ImageOptionEditor';
import ProductOptionEditor from '../ProductOptionEditor';

interface Props {
  containerProps?: BoxProps;
  editItem: CreateCartProductReq | CreateCartPackageReq;
  hideBorder?: boolean;
  isDisabled: boolean;
  onComplete?(): void;
  onSave(item: CreateCartProductReq): void;
  optionalProductOptions?: ProductOption[];
  optionalImageOptions?: PriceSheetOption[];
  useOther?: boolean;
}

/**
 * rudimentary implementatation of what could be an optional section
 * subject to change as other package use cases come in
 */
const OptionalSection = ({
  containerProps,
  editItem,
  hideBorder,
  isDisabled,
  onComplete,
  onSave,
  optionalProductOptions,
  optionalImageOptions,
  useOther,
}: Props) => {
  const { images, isPreOrder, type } = useAppSelector(selectGallery);
  const imagesOnProduct = useAppSelector(selectEditItemImageMap);

  let availableImageOptions = optionalImageOptions;

  // Full order subject galleries filter down image options
  if (!isPreOrder && type !== 'standard' && availableImageOptions) {
    // Track if there are group/individual images
    const supportedTypes: Set<ImageRequirementType> = Object.keys(imagesOnProduct).reduce(
      (result, imageName) => {
        if (!imageName || !images[imageName]) {
          return result;
        }

        if (images[imageName]?.group) {
          result.add('group');
        } else {
          result.add('nonGroup');
        }

        return result;
      },
      new Set<ImageRequirementType>(),
    );
    supportedTypes.add('any');

    // Check what type of images are available on this product, and use that
    // to extrapolate what kind of image options we can show (any, group, individual):
    availableImageOptions = availableImageOptions.filter(option =>
      supportedTypes.has(option.imageRequirementType!),
    );
  }

  if (!availableImageOptions?.length && !optionalProductOptions?.length) {
    return null;
  }

  return (
    <EditorSectionContainer
      hideBorder={hideBorder}
      hideToolbar
      isDisabled={isDisabled}
      onSave={onComplete}
      paddingY={4}
      {...containerProps}
    >
      <Heading fontSize="lg" marginBottom={6}>
        {useOther && OTHER + ' '}
        {AVAILABLE_ADD_ONS}
      </Heading>
      {!!optionalProductOptions?.length &&
        optionalProductOptions.map(option => (
          <ProductOptionEditor
            key={option.id}
            option={option}
            editProduct={editItem as CreateCartProductReq}
            onSave={onSave}
          />
        ))}
      {editItem.type !== 'nonPrintProduct' &&
        !!availableImageOptions?.length &&
        availableImageOptions.map(option => <ImageOptionEditor key={option.id} option={option} />)}
    </EditorSectionContainer>
  );
};

export default OptionalSection;
