import { Box, BoxProps, IconProps, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { ALL, ALL_CATEGORIES } from '../../../shared/constants';
import CategoryLink from '../CategoryLink';

interface Props {
  onLinkClick?(): void;
  boxProps?: BoxProps;
  iconProps?: IconProps;
}

const CategoryLinksSection = ({ onLinkClick, boxProps, iconProps }: Props) => {
  const { categories, offersStatusMap, sortedCategoryIDs } = useSelector(selectPriceSheet);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Box height="100%">
      <CategoryLink
        key={ALL}
        onClick={onLinkClick}
        path={`/${currentVisitKey}/shop/all`}
        text={isMobile ? ALL_CATEGORIES : ALL}
        boxProps={boxProps}
        iconProps={iconProps}
      />
      {sortedCategoryIDs.map(categoryID => (
        <CategoryLink
          key={categoryID}
          isLocked={offersStatusMap[categoryID]?.isLocked}
          onClick={onLinkClick}
          path={`/${currentVisitKey}/shop/${categoryID}`}
          text={categories[categoryID].name}
          boxProps={boxProps}
          iconProps={iconProps}
        />
      ))}
    </Box>
  );
};

export default CategoryLinksSection;
