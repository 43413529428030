import { Box, Link } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  display: string;
  isExternal?: boolean;
  onClick?(): void;
  to: string;
  width?: string;
}

const FooterLink = ({ display, isExternal, onClick, to, width }: Props) => (
  <Box paddingBottom="1">
    {isExternal ? (
      <Link href={to}>{display}</Link>
    ) : (
      <Link as={RouterLink} onClick={onClick} to={to}>
        <Text noOfLines={1} width={width || '100%'}>
          {display}
        </Text>
      </Link>
    )}
  </Box>
);
export default FooterLink;
