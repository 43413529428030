import React from 'react';
import { useIntl } from 'react-intl';

const NoKeyInUrl = () => {
  const intl = useIntl();
  return (
    <h1>
      {intl.formatMessage({
        id: 'noKeyInUrl.noKey',
        defaultMessage: 'URL does not include a gallery to view, please check your address bar.',
      })}
    </h1>
  );
};

export default NoKeyInUrl;
