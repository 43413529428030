import { keyframes } from '@chakra-ui/react';
import colors from '../colors';

const animation = keyframes`
  from {background-color: ${colors.grey[1]};} 
  to {background-color: ${colors.grey[2]};}
`;

const Skeleton = {
  baseStyle: {
    animation: `${animation} 0.8s linear infinite alternate`,
  },
};

export default Skeleton;
