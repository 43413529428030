import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ImageRequirementType,
  ShopBackground,
  ShopImage,
} from '../../../../../../../shop-api-client';
import { selectCart } from '../../../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import FloatingLabelSelect from '../../../../../../shared/components/FloatingLabelSelect';
import Modal from '../../../../../../shared/components/Modal';
import { ImageAndBackground } from '../../../../../../shared/types/image';
import { Params } from '../../../../../../shared/types/router';
import {
  ALL_PHOTOS,
  CHOOSE_PHOTO_HEADING,
  FAVORITES,
  GROUP_PHOTOS,
  IMAGE_NODE_ALL_GROUPS,
  IMAGE_NODE_ALL_PHOTOS,
  IMAGE_NODE_EXPAND,
  OTHER_PHOTOS,
} from '../../../constants';
import ImageNodeDisplayBasic from './ImageNodeDisplayBasic';
import ImageNodeDisplayPreset from './ImageNodeDisplayPreset';

interface Props {
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  imageRequirementType?: ImageRequirementType | null;
  selectedImagesCounter?: Record<string, number>;
  selectedImages: Record<string, ImageAndBackground>;
  selectImage(img: ShopImage, background?: ShopBackground): void;
  showErrorOutline?: boolean;
  showAsHorizontalList?: boolean;
  skipBackgroundSelection?: boolean;
}

/**
 * Renders the available images that can be chosen for an image node
 * Sorted by favorites, recently used, and all other images
 * selectImage is a function from parent that can pass back image + optional bg
 * selectedImages are passed in images that are selected (usually 1, but multiple for DD)
 */
const ImageNodeDisplay = ({
  imageMaxHeight,
  imageMaxWidth,
  imageRequirementType,
  selectedImagesCounter,
  selectedImages,
  selectImage,
  showAsHorizontalList,
  showErrorOutline,
  skipBackgroundSelection,
}: Props) => {
  const { key } = useParams<Params>();

  // Selectors
  const { shopFavorites } = useAppSelector(state => selectCart(state, key));
  const { groupImageMap, subjectImageNames, groups, type } = useAppSelector(state =>
    selectGallery(state, key),
  );

  // State
  const [groupID, setGroupID] = useState<string>('');
  const [expanded, setExpanded] = useState(false);

  // Favorites with backgrounds are not valid for no-background image nodes, so filter:
  const filteredFavorites = skipBackgroundSelection
    ? shopFavorites.filter(f => !f.backgroundID)
    : shopFavorites;

  const selectGroup = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setGroupID(e.target.value);

  const handleSelectImage = (image: ShopImage, background?: ShopBackground) => {
    // Wrap the selectImage props function to facilitate closing the expanded view
    if (expanded) {
      setExpanded(false);
    }

    selectImage(image, background);
  };

  const renderHeader = () => {
    if (!showAsHorizontalList) {
      return null;
    }

    let imageCount = filteredFavorites.length + Object.values(groupImageMap).flat().length;
    if (type === 'subject') {
      // Take the difference of subject images and favorites:
      const favoritesMap = filteredFavorites.reduce<Record<string, boolean>>((result, image) => {
        result[image.imageName] = true;
        return result;
      }, {});

      // Then add those images to the count
      imageCount += subjectImageNames.filter(imageName => !favoritesMap[imageName]).length;
    }

    return (
      <Flex direction="row" width="100%" paddingX={2} paddingTop={2}>
        <Text fontFamily="heading" fontSize="14px">
          {IMAGE_NODE_ALL_PHOTOS} ({imageCount})
        </Text>
        <Button
          color="brand"
          margin={0}
          marginLeft="auto"
          onClick={() => setExpanded(!expanded)}
          variant="link"
        >
          {IMAGE_NODE_EXPAND}
        </Button>
      </Flex>
    );
  };

  const renderImages = () => {
    return (
      <>
        {renderPresets()}
        {renderSubject()}
        {renderStandard()}
      </>
    );
  };

  const renderPresets = () => (
    <>
      {/*
        Removed until  Recently Used is consistently implemented. The styling on this one
        is the only one that appears to be incorrect per the current design. So usages in
        SingleNodeSelectBackgroundForImage and ImageSelection would serve as better references
        See commit:
        https://bitbucket.org/imagequix/shop-ui/commits/97384ca8b16df6af22d4b66daf50d148b434d369
    */}
      {/* {!(showAsHorizontalList && !expanded) && (
        <ImageNodeDisplayPreset
          heading={RECENTLY_USED}
          imageMaxHeight={expanded ? '150px' : imageMaxHeight}
          imageMaxWidth={expanded ? '100px' : imageMaxWidth}
          imageRequirementType={imageRequirementType}
          presetImages={cartImages.slice(0, isMobile ? 3 : 5)}
          selectedImagesCounter={selectedImagesCounter}
          selectedImages={selectedImages}
          selectImage={handleSelectImage}
          showErrorOutline={showErrorOutline}
          showImageName={false}
          showNumImages={false}
        />
      )} */}
      <ImageNodeDisplayPreset
        boxProps={{ marginTop: showAsHorizontalList ? 1 : 4 }}
        heading={FAVORITES}
        imageMaxHeight={expanded ? '150px' : imageMaxHeight}
        imageMaxWidth={expanded ? '100px' : imageMaxWidth}
        imageRequirementType={imageRequirementType}
        presetImages={filteredFavorites}
        selectedImagesCounter={selectedImagesCounter}
        selectedImages={selectedImages}
        selectImage={handleSelectImage}
        showAsHorizontalList={showAsHorizontalList && !expanded}
        showImageName={!showAsHorizontalList && !expanded}
        showErrorOutline={showErrorOutline}
      />
    </>
  );

  const renderStandard = () => {
    if (type !== 'standard') {
      return null;
    }

    return (
      <>
        {!showAsHorizontalList && (
          <Flex justifyContent="flex-end">
            <FloatingLabelSelect defaultValue="all" width="150px" onChange={selectGroup}>
              <option key="all" value="">
                {IMAGE_NODE_ALL_GROUPS}
              </option>
              {Object.entries(groups).map(([groupID, groupName]) => (
                <option key={groupID} value={groupID}>
                  {groupName}
                </option>
              ))}
            </FloatingLabelSelect>
          </Flex>
        )}
        {groupID ? (
          <ImageNodeDisplayBasic
            basicImages={groupImageMap[groupID]}
            boxProps={{ marginTop: showAsHorizontalList ? 1 : 2 }}
            heading={groups[groupID]}
            imageRequirementType="any"
            imageMaxHeight={expanded ? '150px' : imageMaxHeight}
            imageMaxWidth={expanded ? '100px' : imageMaxWidth}
            selectedImagesCounter={selectedImagesCounter}
            selectedImages={selectedImages}
            selectImage={handleSelectImage}
            showAsHorizontalList={showAsHorizontalList && !expanded}
            showErrorOutline={showErrorOutline}
          />
        ) : (
          Object.entries(groupImageMap).map(([groupID, group]) => (
            <ImageNodeDisplayBasic
              key={groupID}
              basicImages={group}
              boxProps={{ marginTop: showAsHorizontalList ? 1 : 4 }}
              heading={groups[groupID]}
              imageMaxHeight={expanded ? '150px' : imageMaxHeight}
              imageMaxWidth={expanded ? '100px' : imageMaxWidth}
              imageRequirementType="any"
              selectedImagesCounter={selectedImagesCounter}
              selectedImages={selectedImages}
              selectImage={handleSelectImage}
              showAsHorizontalList={showAsHorizontalList && !expanded}
              showErrorOutline={showErrorOutline}
            />
          ))
        )}
      </>
    );
  };

  const renderSubject = () => {
    if (type !== 'subject') {
      return null;
    }

    const allGroupImages = Object.values(groupImageMap).flat();

    return (
      <>
        <ImageNodeDisplayBasic
          basicImages={subjectImageNames}
          boxProps={{ marginTop: showAsHorizontalList ? 1 : 4 }}
          heading={filteredFavorites.length ? OTHER_PHOTOS : ALL_PHOTOS}
          imageMaxHeight={expanded ? '150px' : imageMaxHeight}
          imageMaxWidth={expanded ? '100px' : imageMaxWidth}
          imageRequirementType={imageRequirementType}
          selectedImagesCounter={selectedImagesCounter}
          selectedImages={selectedImages}
          selectImage={handleSelectImage}
          showAsHorizontalList={showAsHorizontalList && !expanded}
          showErrorOutline={showErrorOutline}
        />
        <ImageNodeDisplayBasic
          basicImages={allGroupImages}
          boxProps={{ marginTop: showAsHorizontalList ? 1 : 4 }}
          heading={GROUP_PHOTOS}
          imageMaxHeight={expanded ? '150px' : imageMaxHeight}
          imageMaxWidth={expanded ? '100px' : imageMaxWidth}
          imageRequirementType={imageRequirementType}
          selectedImagesCounter={selectedImagesCounter}
          selectedImages={selectedImages}
          selectImage={handleSelectImage}
          showAsHorizontalList={showAsHorizontalList && !expanded}
          showErrorOutline={showErrorOutline}
        />
      </>
    );
  };

  if (expanded) {
    // The expanded view is shown as a modal
    return (
      <Modal
        heading={CHOOSE_PHOTO_HEADING}
        isOpen
        size="full"
        onClose={() => setExpanded(false)}
        scrollBehavior="outside"
      >
        {renderImages()}
      </Modal>
    );
  }

  return (
    <>
      {renderHeader()}
      <Flex
        direction={showAsHorizontalList ? 'row' : 'column'}
        overflowX={showAsHorizontalList ? 'auto' : undefined}
      >
        {renderImages()}
      </Flex>
    </>
  );
};

export default ImageNodeDisplay;
