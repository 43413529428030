import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  selectConfiguration,
  selectGroupedPkgItems,
  selectPackageSteps,
} from '../../../../../../redux/selectors/configurations.selectors';
import { setEditPackageStep } from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { Params } from '../../../../../../shared/types/router';
import CustomizeItem from './CustomizeItem';
import CustomizeOverview from './CustomizeOverview';

const Customize = () => {
  // Selectors
  const { completedSteps, editPackageStep } = useAppSelector(selectConfiguration);
  const { configurableItems } = useAppSelector(selectGroupedPkgItems);
  const packageSteps = useAppSelector(selectPackageSteps);

  // Router hooks
  const { editSubItemID } = useParams<Params>();

  const dispatch = useAppDispatch();

  const packageProduct = useMemo(() => {
    const index = parseInt(editSubItemID);
    return configurableItems[index - 1];
  }, [configurableItems, editSubItemID]);

  useEffect(() => {
    if (!packageProduct) {
      return;
    }

    if (editPackageStep?.startsWith(`cartItemID/${packageProduct.id}`)) {
      // Don't automatically switch to the first step if we are already on the product
      return;
    }

    const steps = packageSteps[packageProduct.id!];
    let firstIncompleteStep = steps.find(({ step }) => !completedSteps[step]);
    if (!firstIncompleteStep) {
      // Fallback to the first step, if all steps had been completed:
      firstIncompleteStep = steps.length ? steps[0] : undefined;
    }

    if (firstIncompleteStep && firstIncompleteStep.step !== editPackageStep) {
      dispatch(setEditPackageStep(firstIncompleteStep.step));
    }
  }, [completedSteps, dispatch, editPackageStep, packageProduct, packageSteps]);

  if (packageProduct) {
    return <CustomizeItem editPackageProduct={packageProduct} />;
  }

  return <CustomizeOverview />;
};

export default Customize;
