import { Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  CartImageNode,
  CartPrintProduct,
  CartPrintProductReq,
  ImageTone,
} from '../../../../../../shop-api-client/models/Cart';
import {
  selectActiveStep,
  selectIsStepCompleted,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { setCompletedSteps, setEditStep } from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { updateEditProductNode } from '../../../../../redux/thunks/configuration.thunks';
import { ADD_PHOTO_EFFECT, BLACK_AND_WHITE, NONE_SELECTED, SEPIA } from '../../constants';
import { getImageToneStep } from '../../utils';
import CompletedSection from '../CompletedSection';
import EditorSectionContainer from '../EditorSectionContainer';
import ImageNodeToneDisplay from '../ImageNodesEditor/ImageNodeToneDisplay';

interface Props {
  editProduct: CartPrintProductReq | CartPrintProduct;
}

/**
 * Renders a wrapper for image tone selection when there is only a single node
 */
const ImageToneSelection = ({ editProduct }: Props) => {
  const activeStep = useAppSelector(selectActiveStep);
  const stepName = useMemo(() => getImageToneStep(editProduct.id!), [editProduct]);
  const isDisabled = activeStep !== stepName;

  const {
    settings: { showBwAndSepia },
  } = useAppSelector(selectGallery);
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  const editNode = editProduct.nodes.find(node => node.type === 'image') as
    | CartImageNode
    | undefined;

  const dispatch = useAppDispatch();

  if (!editProduct || !editNode || !showBwAndSepia) {
    return null;
  }

  const handleEdit = () => {
    dispatch(setEditStep(stepName));
  };

  const fullNameLookup = {
    original: NONE_SELECTED,
    bw: BLACK_AND_WHITE,
    sepia: SEPIA,
  };

  const selectToneForNode = (imageTone: ImageTone) => {
    const newEditNode = { ...editNode, imageTone };
    dispatch(updateEditProductNode(editProduct.id!, newEditNode));
  };

  const saveToneForNode = () => {
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
  };

  if (completed) {
    return (
      <CompletedSection heading={ADD_PHOTO_EFFECT} onEdit={handleEdit}>
        <Text fontSize="sm">{fullNameLookup[editNode.imageTone || 'original']}</Text>
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer
      heading={ADD_PHOTO_EFFECT}
      isDisabled={isDisabled}
      onSave={!isDisabled ? saveToneForNode : undefined}
    >
      <Flex direction="column" marginTop={4}>
        <ImageNodeToneDisplay
          selectedTone={editNode.imageTone || 'original'}
          selectTone={selectToneForNode}
        />
      </Flex>
    </EditorSectionContainer>
  );
};

export default ImageToneSelection;
