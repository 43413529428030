import { CustomDataSpecField } from '../../../shop-api-client';
import { intl } from '../../shared/constants';

export const ARMED_FORCES_PROVINCES = [
  { name: 'Armed Forces Europe', short: 'AE', country: 'US' },
  { name: 'Armed Forces Pacific', short: 'AP', country: 'US' },
  { name: 'Armed Forces the Americas', short: 'AA', country: 'US' },
];

export const AUTOCOMPLETE: Record<string, string> = {
  name: 'name',
  firstName: 'fname',
  lastName: 'lname',
  email: 'email',
  address1: 'address-line1',
  address2: 'address-line2',
  address3: 'address-line3',
  municipality: 'address-level4',
  region: 'address-level3',
  city: 'address-level2',
  state: 'address-level1',
  country: 'country',
  countryName: 'country-name',
  zip: 'postal-code',
  ccName: 'cc-name',
  ccNumber: 'cc-number',
  ccExp: 'cc-exp',
  ccExpMonth: 'cc-exp-month',
  ccExpYear: 'cc-exp-year',
  ccCSC: 'cc-csc',
  phone1: 'tel',
  phone2: 'tel',
  organization: 'organization',
};

export const INPUT_LABELS: Record<string, string> = {
  firstName: intl.formatMessage({
    id: 'input.firstName',
    defaultMessage: 'First Name',
  }),
  lastName: intl.formatMessage({
    id: 'input.lastName',
    defaultMessage: 'Last Name',
  }),
  address: intl.formatMessage({
    id: 'input.address',
    defaultMessage: 'Address',
  }),
  address1: intl.formatMessage({
    id: 'input.address1',
    defaultMessage: 'Address',
  }),
  address2: intl.formatMessage({
    id: 'input.address2',
    defaultMessage: 'Apt, suite, c/o, etc.',
  }),
  city: intl.formatMessage({
    id: 'input.city',
    defaultMessage: 'City',
  }),
  state: intl.formatMessage({
    id: 'input.state',
    defaultMessage: 'State',
  }),
  province: intl.formatMessage({
    id: 'input.province',
    defaultMessage: 'Province',
  }),
  zip: intl.formatMessage({
    id: 'input.zip',
    defaultMessage: 'Zip',
  }),
  postalCode: intl.formatMessage({
    id: 'input.postalCode',
    defaultMessage: 'Postal Code',
  }),
  country: intl.formatMessage({
    id: 'input.country',
    defaultMessage: 'Country',
  }),
  phone: intl.formatMessage({
    id: 'input.phone',
    defaultMessage: 'Phone',
  }),
  phone1: intl.formatMessage({
    id: 'input.phone1',
    defaultMessage: 'Phone',
  }),
  phone2: intl.formatMessage({
    id: 'input.phone2',
    defaultMessage: 'Phone',
  }),
  email: intl.formatMessage({
    id: 'input.email',
    defaultMessage: 'Email',
  }),
  mother: intl.formatMessage({
    id: 'input.mother',
    defaultMessage: 'Mother',
  }),
  father: intl.formatMessage({
    id: 'input.father',
    defaultMessage: 'Father',
  }),
  organization: intl.formatMessage({
    id: 'input.organization',
    defaultMessage: 'Organization',
  }),
  grade: intl.formatMessage({
    id: 'input.grade',
    defaultMessage: 'Grade',
  }),
  homeroom: intl.formatMessage({
    id: 'input.homeroom',
    defaultMessage: 'Homeroom',
  }),
  jerseyNumber: intl.formatMessage({
    id: 'input.jerseyNumber',
    defaultMessage: 'Jersey No.',
  }),
  teacher: intl.formatMessage({
    id: 'input.teacher',
    defaultMessage: 'Teacher',
  }),
  year: intl.formatMessage({
    id: 'input.year',
    defaultMessage: 'Year',
  }),
};

export const ADDRESS_FIELDS: CustomDataSpecField[] = [
  {
    description: 'First Name',
    field: 'custom1',
    name: INPUT_LABELS['firstName'],
    requiredness: 'required',
    subjectMap: 'firstName',
    type: 'text',
    inputType: 'text',
  },
  {
    description: 'Last Name',
    field: 'custom2',
    name: INPUT_LABELS['lastName'],
    requiredness: 'required',
    subjectMap: 'lastName',
    type: 'text',
    inputType: 'text',
  },
  {
    description: 'Street Address',
    field: 'custom3',
    name: INPUT_LABELS['address1'],
    requiredness: 'required',
    subjectMap: 'address1',
    type: 'text',
    inputType: 'text',
  },
  {
    description: 'Apt, suite, c/o, etc.',
    field: 'custom4',
    name: INPUT_LABELS['address2'],
    requiredness: 'optional',
    subjectMap: 'address2',
    type: 'text',
    inputType: 'text',
  },
  {
    description: 'Town/City',
    field: 'custom5',
    name: INPUT_LABELS['city'],
    requiredness: 'required',
    subjectMap: 'city',
    type: 'text',
    inputType: 'text',
  },
  {
    description: 'State',
    field: 'custom6',
    name: INPUT_LABELS['state'],
    requiredness: 'required',
    subjectMap: 'state',
    type: 'selection',
  },

  {
    description: 'Zipcode',
    field: 'custom7',
    name: INPUT_LABELS['zip'],
    requiredness: 'required',
    subjectMap: 'zip',
    type: 'text',
    inputType: 'number',
  },
  {
    description: 'Personal or Mobile number',
    field: 'custom8',
    name: INPUT_LABELS['phone'],
    requiredness: 'required',
    subjectMap: 'phone',
    type: 'text',
    inputType: 'tel',
  },
  {
    description: 'Email you prefer to be contacted at.',
    field: 'custom9',
    name: INPUT_LABELS['email'],
    requiredness: 'required',
    subjectMap: 'email',
    type: 'text',
    inputType: 'email',
  },
  {
    description: 'Country',
    field: 'custom10',
    name: INPUT_LABELS['country'],
    requiredness: 'required',
    subjectMap: 'country',
    type: 'text',
    inputType: 'text',
  },
];

export const CHECKOUT_ERROR = intl.formatMessage({
  id: 'checkout.selectError',
  defaultMessage: 'Please choose an option',
});

/**
 * checkout steps headings
 */
export const ADDITIONAL_INFORMATION = intl.formatMessage({
  id: 'checkout.additionalInformation',
  defaultMessage: 'Additional Information',
});
export const BILLING_ADDRESS = intl.formatMessage({
  id: 'checkout.billingAddress',
  defaultMessage: 'Enter Billing Address',
});
export const CHOOSE_BACKGROUND = intl.formatMessage({
  id: 'checkout.backgroundOptions',
  defaultMessage: 'Choose a Background',
});
export const IMAGE_ADD_ONS = intl.formatMessage({
  id: 'checkout.imageAddOns',
  defaultMessage: 'Image Add-Ons',
});
export const SHIPPING_AND_PICKUP_OPTIONS = intl.formatMessage({
  id: 'checkout.shippingAndPickupOptions',
  defaultMessage: 'Shipping and Pickup Options',
});
export const SHIPPING_ADDRESS = intl.formatMessage({
  id: 'checkout.shippingAddress',
  defaultMessage: 'Enter Shipping Address',
});
export const ORDER_OPTIONS = intl.formatMessage({
  id: 'checkout.orderOptions',
  defaultMessage: 'Order Options',
});
export const PAYMENT = intl.formatMessage({
  id: 'checkout.payment',
  defaultMessage: 'Payment',
});

// Max character limits for billing fields
export const ADDRESS_MAX_CHARS = 50;
export const CITY_MAX_CHARS = 30;
export const EMAIL_MAX_CHARS = 60;
export const NAME_MAX_CHARS = 25;
export const PHONE_MAX_CHARS = 16;
export const ZIP_MAX_CHARS = 10;
