import { Flex, Heading } from '@chakra-ui/layout';
import {
  Box,
  Button,
  chakra,
  Divider,
  Icon,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { FiUnlock } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopCategory } from '../../../../shop-api-client';
import { CartPackage, CartProduct } from '../../../../shop-api-client/models/Cart';
import { selectCartMap } from '../../../redux/selectors/cart.selectors';
import { selectGallery, selectGalleryMap } from '../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../redux/store';
import { deleteCartItem } from '../../../redux/thunks/cart.thunks';
import CheckMarkIcon from '../../../shared/components/CheckMarkIcon';
import { REMOVE } from '../../../shared/constants/labels.constants';
import { getCartItemsFromCategory, getCartsByPriceSheetID } from '../../Carts/utils';
import OfferOption from './OfferOption';

interface Props {
  category: ShopCategory;
  /**
   * `productID` is passed as a prop when viewing Offers from Product Details
   */
  productID?: number;
}

const OffersInstructions = ({ category, productID }: Props) => {
  const { priceSheetID } = useSelector(selectGallery);
  const { productCategoryMap, offersStatusMap } = useSelector(selectPriceSheet);
  const cartMap = useSelector(selectCartMap);
  const galleryMap = useSelector(selectGalleryMap);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const catCartItems = useMemo(() => {
    const cartsWithSamePS = getCartsByPriceSheetID(priceSheetID, cartMap, galleryMap);

    return cartsWithSamePS.reduce<(CartPackage | CartProduct)[]>((result, cart) => {
      const cartItems = getCartItemsFromCategory(cart, productCategoryMap[category.id]);
      result.push(...cartItems);
      return result;
    }, []);
  }, [cartMap, galleryMap, priceSheetID, productCategoryMap, category.id]);

  const behaviors = category.behaviors;
  /**
   * `isCompact` is used to determine the style requirements of this component
   */
  const isCompact = isMobile || !!productID;
  const isLocked = offersStatusMap[category.id]?.isLocked;
  /**
   * `isSingleOnly` is true if the the category only permits a single item to be added to the cart
   */
  const isSingleOnly = ['singleOptional', 'singleRequired'].includes(category.selectionType);
  const itemCount = productID ? 1 : productCategoryMap[category.id].length;
  const showOptionHeading = behaviors.length > 1;
  const singleItemRequirement = isSingleOnly && catCartItems.length === 1 && isLocked;

  if (!behaviors.length && !singleItemRequirement) {
    return null;
  }
  const { behaviorStatusMap } = offersStatusMap[category.id];

  /**
   * Total number of conditions met across all `behaviors` in the category
   */
  const totalCompleted = Object.values(behaviorStatusMap).reduce((res, conditions) => {
    return (res += Object.values(conditions.conditionStatusMap).filter(c => c.isMet).length);
  }, 0);

  /**
   * Reduces to set progress as a percentage for the set of conditions that is closest to completion
   * of all behaviors in the category
   */
  const progress = Object.values(behaviorStatusMap).reduce((highestPercentage, conditions) => {
    if (conditions.progressPercentage > highestPercentage) {
      return conditions.progressPercentage;
    }
    return highestPercentage;
  }, 0);

  const handleRemoveItem = () => {
    const catCartItem = catCartItems[0];

    // Ensure correct visitKey is passed to deleteCartItem
    const visitKey = Object.keys(cartMap).find(visitKey => {
      const cart = cartMap[visitKey];
      return [...cart.cartProducts, ...cart.cartPackages].some(
        item => item.id === catCartItem.id && item.type === catCartItem.type,
      );
    });
    dispatch(deleteCartItem(catCartItem, visitKey!));
  };

  const renderContents = () => {
    if (singleItemRequirement) {
      return (
        <>
          <Text data-test="offers-instructions-to-unlock-only-one-item" marginY={2}>
            {intl.formatMessage({
              id: 'offersInstructions.onlyOneItem',
              defaultMessage:
                'Only one item can be purchased from this category. Remove the cart item below to unlock the category.',
            })}
          </Text>
          <Flex align="center" marginY={2} marginLeft={1}>
            <CheckMarkIcon backgroundColor="successGreen" />
            <Box marginLeft={3}>
              <chakra.span color="successGreen" fontFamily="heading">
                {intl.formatMessage({
                  id: 'offerInstructions.added',
                  defaultMessage: 'Added:',
                })}
              </chakra.span>
              <chakra.span marginLeft={2} marginRight={3}>
                {catCartItems[0].name}
              </chakra.span>
              <Button onClick={handleRemoveItem} color="brand" margin="6px 0" variant="link">
                {REMOVE}
              </Button>
            </Box>
          </Flex>
        </>
      );
    }

    const getSubheading = () => {
      if (progress === 100) {
        return intl.formatMessage({
          id: 'offersInstructions.completedAllReqs',
          defaultMessage: "You've already completed all the requirements.",
        });
      }
      return `${intl.formatMessage({
        id: 'offersInstructions.followSteps',
        defaultMessage: 'To unlock this category, follow the steps below.',
      })}
          ${
            totalCompleted > 0
              ? intl.formatMessage(
                  {
                    id: 'offersInstructions.alreadyCompleted',
                    defaultMessage:
                      "You've already completed {totalCompleted} requirement{plural}.",
                  },
                  { totalCompleted, plural: totalCompleted > 1 ? 's' : '' },
                )
              : ''
          }
      `;
    };

    return (
      <>
        {!productID && (
          <Text data-test="offers-instructions-follow-steps-text" marginY={2}>
            {getSubheading()}
          </Text>
        )}
        {behaviors.map((behavior, i) => (
          <div key={behavior.id}>
            <OfferOption
              key={behavior.id}
              behavior={behavior}
              behaviorStatus={behaviorStatusMap[behavior.id]}
              isCompact={isCompact}
              optionNumber={i + 1}
              showHeading={showOptionHeading}
            />
            {!!behaviors[i + 1] && (
              <Flex justifyContent="space-between" align="center">
                <Divider borderColor="grey.3" maxWidth="unset" />
                <Text marginX={isCompact ? 5 : 10}>
                  {intl.formatMessage({
                    id: 'offersInstructions.or',
                    defaultMessage: 'OR',
                  })}
                </Text>
                <Divider borderColor="grey.3" maxWidth="unset" />
              </Flex>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <Flex backgroundColor="grey.1" direction="column" marginY={3} padding={isCompact ? 6 : 10}>
      <Flex wrap="wrap">
        <Flex>
          <Icon as={FiUnlock} fontSize="30px" />
          <Heading
            marginX="4"
            size={isCompact ? 'md' : 'lg'}
            data-test="offers-instructions-how-to-unlock-items-text"
          >
            {intl.formatMessage(
              { id: 'offersInstructions.howTo', defaultMessage: 'How to Unlock {item}' },
              {
                item: itemCount > 1 ? 'Items' : 'Item',
              },
            )}
          </Heading>
        </Flex>
        <Spacer />
        {!singleItemRequirement && (
          <Heading
            fontSize={isCompact ? 'md' : 'lg'}
            marginLeft="45px"
            data-test="offers-instructions-progress-text"
          >
            {intl.formatMessage(
              {
                id: 'offersInstructions.percentComplete',
                defaultMessage: '{progress}% Complete',
              },
              { progress },
            )}
          </Heading>
        )}
      </Flex>
      <Flex direction="column" marginX={12}>
        {renderContents()}
      </Flex>
    </Flex>
  );
};

export default OffersInstructions;
