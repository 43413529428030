import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Spacer,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { ReactNode, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { StepKey } from '../../../../shop-api-client';
import {
  selectCheckoutActiveStep,
  selectCheckoutSlice,
  selectCheckoutSteps,
} from '../../../redux/selectors/checkout.selectors';
import StickyMobileSection from '../../../shared/components/Mobile/StickyMobileSection';
import { FADE_IN_KEYFRAMES } from '../../../shared/constants/style.constants';
import useIsElemInView from '../../../shared/hooks/useIsElemInView';

interface Props extends FlexProps {
  children?: ReactNode;
  contentWidth?: FlexProps['width'];
  error: boolean;
  handleEdit(stepKey: StepKey): void;
  handleNext(stepNumber: number, stepKey: StepKey): void;
  isActive: boolean;
  isComplete: boolean;
  loading: boolean;
  stepIndex: number;
  stepKey: StepKey;
  stepTitle: string;
}

const CheckoutStep = ({
  children,
  contentWidth = '500px',
  error,
  handleEdit,
  handleNext,
  isActive,
  isComplete,
  loading,
  stepIndex,
  stepKey,
  stepTitle,
  ...rest
}: Props) => {
  const { isSubmitting } = useSelector(selectCheckoutSlice);
  const activeStep = useSelector(selectCheckoutActiveStep);
  const steps = useSelector(selectCheckoutSteps);

  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });
  const nextBtnRef = useRef<HTMLDivElement>(null);
  const elemInView = useIsElemInView(nextBtnRef.current);

  const editMsg = intl.formatMessage({
    id: 'checkout.edit',
    defaultMessage: 'Edit',
  });
  const saveAndContinue = intl.formatMessage({
    id: 'checkout.saveAndCont',
    defaultMessage: 'Save & Continue',
  });
  const continueToPayment = intl.formatMessage({
    id: 'checkout.contToPayment',
    defaultMessage: 'Continue to Payment',
  });

  const activeStepIndex = steps?.findIndex(step => step.stepKey === activeStep?.stepKey) || 0;
  const shouldShowChange = (isComplete || stepIndex < activeStepIndex) && !isActive;
  const showSpinner = stepKey === 'customDataSpec' && isActive && loading;

  const getMaxWidth = () => {
    if (isMobile) {
      return '100%';
    }
    if (isActive) {
      return contentWidth;
    }
    return isComplete ? '730px' : '695px';
  };

  const renderStepHeader = () => (
    <Flex
      alignItems="center"
      color={isActive ? 'black' : isComplete ? 'successGreen' : 'grey.5'}
      marginBottom={isActive ? 6 : 0}
      marginTop={4}
      maxHeight="40px"
    >
      <AspectRatio
        border="1px solid"
        borderColor={isActive ? 'black' : isComplete ? 'transparent' : 'grey.5'}
        borderRadius="50%"
        borderWidth={isActive ? 2 : isComplete ? 0 : 1}
        marginRight={isActive ? 6 : isComplete ? 0 : 6}
        maxWidth="40px"
        ratio={1}
        width="40px"
      >
        {isActive || !isComplete ? (
          <Heading fontWeight="bold" fontSize="xl">
            {stepIndex + 1}
          </Heading>
        ) : (
          <Flex>
            <Icon as={CheckIcon} boxSize={5} />
          </Flex>
        )}
      </AspectRatio>

      <Heading fontSize={{ base: 'lg', lg: `${isActive ? '2xl' : 'xl'}` }} noOfLines={1}>
        {stepTitle}
      </Heading>

      <Spacer />
      {shouldShowChange && (
        <Flex marginRight={{ base: 2, lg: 10 }}>
          {isMobile ? (
            <Icon as={EditIcon} onClick={() => handleEdit(stepKey)} color="brand" />
          ) : (
            <Button
              color="brand"
              disabled={isSubmitting}
              onClick={() => handleEdit(stepKey)}
              variant="link"
            >
              {editMsg}
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );

  const renderNextBtn = () => (
    <Flex justifyContent={{ base: 'center', lg: 'flex-end' }} marginY={4} width="100%">
      <Button
        data-test={
          steps.length - 2 === stepIndex
            ? 'checkout-continue-to-payment'
            : 'checkout-save-and-continue'
        }
        isDisabled={error}
        onClick={() => handleNext(stepIndex, stepKey)}
        width={{ base: '100%', lg: '40%' }}
      >
        {steps.length - 2 === stepIndex ? continueToPayment : saveAndContinue}
      </Button>
    </Flex>
  );

  return (
    <Flex
      borderColor="grey.2"
      borderRadius={isComplete ? 'md' : ''}
      borderStyle={isActive ? '' : isComplete ? 'solid' : 'solid none none none'}
      borderWidth={isActive ? '0px' : '1px'}
      flexFlow="column"
      marginBottom={4}
      maxWidth={getMaxWidth()}
      paddingLeft={{ base: 0, lg: `${isActive ? 0 : isComplete ? 6 : 0}` }}
      width="100%"
      {...rest}
    >
      {renderStepHeader()}

      <Box
        animation={isActive ? `${FADE_IN_KEYFRAMES} .5s` : 'initial'}
        display={showSpinner ? 'none' : 'initial'}
      >
        {children}
        {isActive && stepKey !== 'paymentOptions' && (
          <Flex ref={nextBtnRef}>{renderNextBtn()}</Flex>
        )}
        {isActive && stepKey !== 'paymentOptions' && isMobile && !elemInView && (
          <StickyMobileSection show={!elemInView} direction="bottom">
            {renderNextBtn()}
          </StickyMobileSection>
        )}
      </Box>

      {showSpinner && (
        <Center height="150px" width="100%">
          <Spinner />
        </Center>
      )}
    </Flex>
  );
};

export default CheckoutStep;
