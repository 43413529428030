import { Box, Flex, Heading, Link, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  selectPriceSheet,
  selectPSproductsByType,
} from '../../../redux/selectors/priceSheet.selectors';
import ButtonLink from '../../../shared/components/ButtonLink';
import { FILTER, SEARCH } from '../../../shared/constants';
import { SHOP_ALL_CATEGORIES } from '../../../shared/constants/labels.constants';
import { Params } from '../../../shared/types/router';
import ProductCarousel from '../ProductCarousel';
import ProductsSkeleton from '../ProductsSkeleton';

const MAX_WIDTH = 1200;

const NoResultsFound = () => {
  const { categories, sortedCategoryIDs } = useSelector(selectPriceSheet);
  const { packages, productsAndPrints } = useSelector(selectPSproductsByType);

  const { key } = useParams<Params>();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const searchParams = new URLSearchParams(history.location.search);
  const queryString = searchParams.get(SEARCH)?.split('+').join(' ') || '';

  const handleCategoryClick = () => {
    // Clear search and filter query keys:
    searchParams.delete(SEARCH);
    searchParams.delete(FILTER);
    history.replace({ search: `${searchParams}` });
  };

  // Since this view is only visible if there is one or more products that
  // meet the criteria of queryString, and if we are in a state where the
  // queryString is an empty string, we will be re-routed to the 'all' category
  // from Products.tsx, so the ProductsSkeleton is displayed until we've left the component
  if (!queryString.length) {
    return (
      <Flex align="center" justify="center">
        <ProductsSkeleton />
      </Flex>
    );
  }

  return (
    <Flex align="center" direction="column" width="100%" marginBottom="20">
      <VStack minHeight="250px">
        <Heading size="md" textAlign="center" data-test="no-results-found-text">
          {intl.formatMessage(
            {
              id: 'noResultsFound.noResultsFound',
              defaultMessage: 'No Results Found for "{queryString}"',
            },
            { queryString },
          )}
        </Heading>
        <Text
          fontSize="sm"
          textAlign="center"
          data-test="no-results-found-try-different-search-text"
        >
          {intl.formatMessage({
            id: 'noResultsFound.tryDifferentSearch',
            defaultMessage: 'Try a different search or shop one of the categories below',
          })}
        </Text>
        <Box padding="20px">
          <ButtonLink
            data-test="no-results-found-shop-category-link"
            label={SHOP_ALL_CATEGORIES}
            marginY="20px"
            onClick={handleCategoryClick}
            to={`/${key}/shop/all`}
          />
        </Box>
        <Box maxWidth={`900px`} paddingBottom="20" width="100%">
          {sortedCategoryIDs.map(categoryID => (
            <Box
              key={categoryID}
              display="inline-block"
              paddingLeft={isMobile ? 0 : '80px'}
              paddingRight={isMobile ? 0 : '40px'}
              textAlign={isMobile ? 'center' : 'left'}
              width={isMobile ? '100%' : '50%'}
            >
              <Link
                as={RouterLink}
                margin="auto"
                onClick={handleCategoryClick}
                to={`${categoryID}`}
                variant="brand"
              >
                {categories[categoryID].name}
              </Link>
            </Box>
          ))}
        </Box>
      </VStack>
      <Box
        marginBottom="35px"
        maxWidth={`${MAX_WIDTH}px`}
        marginX="10px"
        position="relative"
        width={'calc(100vw - 10px)'}
      >
        {!!packages.length && (
          <ProductCarousel
            heading={intl.formatMessage({
              id: 'noResultsFound.shopPackages',
              defaultMessage: 'Shop Packages',
            })}
            items={packages}
          />
        )}
        {!!productsAndPrints.length && (
          <ProductCarousel
            heading={intl.formatMessage({
              id: 'noResultsFound.shopProductsPrints',
              defaultMessage: 'Shop Products & Prints',
            })}
            items={productsAndPrints}
          />
        )}
      </Box>
    </Flex>
  );
};

export default NoResultsFound;
