import { Box, Flex, Heading, Icon } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { HIDE_SCROLLBAR_STYLES } from '../../../../shared/constants/style.constants';
import CategoryLinksSection from '../../../Products/CategoryLinksSection';

interface Props {
  onBackClick(): void;
  onSelectCategory(): void;
}

const ShopCategoriesMenu = ({ onBackClick, onSelectCategory }: Props) => {
  const intl = useIntl();

  const boxProps = { paddingY: 0, marginTop: '4px', paddingLeft: '25px' };
  const iconProps = { left: '0px' };

  return (
    <Box minHeight="100vh" minWidth="100vw" paddingX="10" paddingY="6" position="relative">
      <Flex align="center" onClick={onBackClick} marginBottom="10px">
        <Icon as={HiOutlineArrowLeft} fontSize="xl" />
        <Heading size="sm" marginX="4">
          {intl.formatMessage({
            id: 'navigation.back',
            defaultMessage: 'Back',
          })}
        </Heading>
      </Flex>
      <Box css={HIDE_SCROLLBAR_STYLES} height="90%" overflow="scroll" width="100%">
        <Heading size="md" marginY="7">
          {intl.formatMessage({
            id: 'navigation.shopProducts',
            defaultMessage: 'Shop Products',
          })}
        </Heading>
        <CategoryLinksSection
          boxProps={boxProps}
          iconProps={iconProps}
          onLinkClick={onSelectCategory}
        />
      </Box>
    </Box>
  );
};

export default ShopCategoriesMenu;
