import { Flex, Heading, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaCheck } from 'react-icons/fa';

interface Props {
  iconSize?: string;
  text: string;
  textSize?: string;
}

const CheckMarkSuccess = ({ iconSize = 'sm', text, textSize = 'xs' }: Props) => (
  <Flex color="successGreen" marginY="1">
    <Icon as={FaCheck} fontSize={iconSize} marginRight={2} data-test="check-mark-success-icon" />
    <Heading data-test="check-mark-success-text" fontSize={textSize}>
      {text}
    </Heading>
  </Flex>
);

export default CheckMarkSuccess;
