import { Box, Modal, ModalOverlay, Text, Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectInteractions } from '../../../../redux/selectors/interactions.selectors';
import { toggleShowMultiGalleryTooltip } from '../../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../../redux/store';
import { ABOVE_MODAL_Z_INDEX } from '../../../../shared/constants';
import useOnEvent from '../../../../shared/hooks/useOnEvent';

interface Props {
  children: ReactNode;
}

const MultiGalleryTooltip = ({ children }: Props) => {
  const { showMultiGalleryTooltip, showWelcomeModal } = useSelector(selectInteractions);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const switchBackAndForthText = intl.formatMessage({
    id: 'multiGallery.switchBackAndForth',
    defaultMessage: 'Switch back and forth between your open galleries here',
  });
  const gotItText = intl.formatMessage({
    id: 'multiGallery.gotIt',
    defaultMessage: 'Got It!',
  });

  const isOpen = showMultiGalleryTooltip && !showWelcomeModal;

  useOnEvent('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleTooltipClick();
    }
  });

  const handleTooltipClick = () => {
    dispatch(toggleShowMultiGalleryTooltip(false));
  };

  return (
    <Box onClick={handleTooltipClick} display="inline">
      <Modal blockScrollOnMount isCentered isOpen={isOpen} onClose={handleTooltipClick}>
        <ModalOverlay zIndex={ABOVE_MODAL_Z_INDEX + 1} />
      </Modal>

      <Tooltip
        background="brand"
        color="grey.1"
        hasArrow
        isOpen={isOpen}
        label={
          <Box padding={'20px 10px 10px 10px'} maxWidth={200}>
            <Text fontFamily="heading" marginBottom={4} data-test="gallery-switch-tip-text">
              {switchBackAndForthText}
            </Text>
            <Text
              data-test="gallery-switch-tip-gotit-text"
              fontFamily="heading"
              marginRight={2}
              textAlign="right"
            >
              {gotItText}
            </Text>
          </Box>
        }
        offset={[-40, 8]}
      >
        {children}
      </Tooltip>
    </Box>
  );
};

export default MultiGalleryTooltip;
