import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  currentVisitKey: string | null;
  email: string;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isLoggedOut: boolean;
} = {
  currentVisitKey: null,
  email: '',
  isLoggedIn: false,
  isLoggingIn: false, // Used for auth useEffect in Login to prevent multiple calls
  isLoggedOut: false, // Used to prevent the auth useEffect that tries to authorize a new visitor
};

const visitorSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setIsLoggedIn(state, { payload }: PayloadAction<boolean>) {
      state.isLoggedIn = payload;
      state.isLoggedOut = false;
      state.isLoggingIn = false;
    },
    setIsLoggedOut(state, { payload }: PayloadAction<boolean>) {
      state.isLoggedOut = payload;
    },
    setIsLoggingIn(state, action: PayloadAction<boolean>) {
      state.isLoggingIn = action.payload;
      state.isLoggedOut = false;
    },
    setVisitKey(state, action: PayloadAction<string>) {
      state.currentVisitKey = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    signout() {
      return {
        ...initialState,
        isLoggedOut: true,
      };
    },
  },
});

const { actions, reducer } = visitorSlice;

export const { setEmail, setIsLoggingIn, setIsLoggedIn, setIsLoggedOut, setVisitKey, signout } =
  actions;

export default reducer;
