import { Button, Flex, Progress, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ShopProductPrint } from '../../../../../../../../shop-api-client';
import {
  CartPrintProduct,
  CartPrintProductReq,
  CreateCartProductReq,
} from '../../../../../../../../shop-api-client/models/Cart';
import {
  selectConfiguration,
  selectConfiguredProgress,
  selectGroupedPkgItems,
  selectPackageItemMap,
} from '../../../../../../../redux/selectors/configurations.selectors';
import { useAppSelector } from '../../../../../../../redux/store';
import ArrowLeftbutton from '../../../../../../../shared/components/ArrowLeftButton';
import Modal from '../../../../../../../shared/components/Modal';
import { NAVIGATION_Z_INDEX } from '../../../../../../../shared/constants';
import useIsMobile from '../../../../../../../shared/hooks/useIsMobile';
import { Params } from '../../../../../../../shared/types/router';
import ProductPreview from '../../../../../ProductPreview';
import { FONTS, PREVIEW } from '../../../../constants';
import useConfigurationRouter from '../../../../useConfigurationRouter';
import { MOBILE_TOOLBAR_HEIGHT } from '../../../WizardNavigation';

interface Props {
  editPackageProduct: CreateCartProductReq;
}

const CustomizeNavigation = ({ editPackageProduct }: Props) => {
  const { editPackage } = useAppSelector(selectConfiguration);
  const { configurableItems } = useAppSelector(selectGroupedPkgItems);
  const packageItemMap = useAppSelector(selectPackageItemMap);
  const progress = useAppSelector(selectConfiguredProgress);

  const [showModal, setShowModal] = useState(false);

  const { routeToWizardStep } = useConfigurationRouter();
  const { editSubItemID } = useParams<Params>();
  const intl = useIntl();
  const isMobile = useIsMobile();

  const handleBack = () => {
    // Route to the customize overview:
    routeToWizardStep('customize');
  };

  const shopProduct = packageItemMap[editPackageProduct.priceSheetItemID];

  if (!isMobile) {
    return null;
  }

  const closePreview = () => {
    setShowModal(false);
  };

  const openPreview = () => {
    setShowModal(true);
  };

  const renderMiniPreview = () => {
    if (editPackageProduct.type === 'product') {
      return (
        <>
          <Button variant="link" color="brand" marginRight={6} onClick={openPreview}>
            {PREVIEW}
          </Button>
          <ProductPreview
            disableMiniPreview
            editProduct={editPackageProduct as CartPrintProduct}
            hidePreviewText
            isInteractive={false}
            maxPreviewHeight={80}
            maxPreviewWidth={120}
            shopProduct={shopProduct as ShopProductPrint}
          />
        </>
      );
    }

    // Product image maybe?
    return null;
  };

  const renderPreviewModal = () => {
    if (!showModal) {
      return null;
    }

    return (
      <Modal isOpen onClose={closePreview} size="full" unwrapChildren>
        <Flex direction="column">
          <Text size="sm" marginY={3} textAlign="center">
            {FONTS}
          </Text>
          <ProductPreview
            disableMiniPreview
            editProduct={editPackageProduct as CartPrintProductReq}
            hidePreviewText
            isFinalRender
            isInteractive={false}
            shopProduct={shopProduct as ShopProductPrint}
          />
        </Flex>
      </Modal>
    );
  };

  return (
    <Flex
      backgroundColor="white"
      direction="column"
      position="sticky"
      top={0}
      zIndex={NAVIGATION_Z_INDEX}
    >
      <Flex
        align="center"
        height={`${MOBILE_TOOLBAR_HEIGHT}px`}
        justify="space-between"
        paddingX={{ base: 2, md: 5 }}
      >
        <Flex flex={3}>
          <ArrowLeftbutton
            aria-label="Back"
            border="none"
            fontSize="md"
            lightMode
            onClick={handleBack}
            size="sm"
          />
          <Flex direction="column" marginLeft={1}>
            <Text fontSize="11">
              {intl.formatMessage(
                {
                  id: 'progress.countOfTotal',
                  defaultMessage: '{current} of {configurable} ({total} items)',
                },
                {
                  current: editSubItemID,
                  configurable: configurableItems.length,
                  total: editPackage?.products.length,
                },
              )}
            </Text>
            <Text
              fontFamily="ITC Avant Garde Gothic Demi"
              fontSize="lg"
              lineHeight={1.2}
              noOfLines={2}
            >
              {shopProduct.name}
            </Text>
          </Flex>
        </Flex>
        <Flex flex={2} justifyContent="flex-end" paddingRight={2}>
          {renderMiniPreview()}
        </Flex>
      </Flex>
      <Progress
        borderRadius={6}
        height="4px"
        marginBottom={1}
        marginTop="auto"
        marginX={5}
        value={progress}
      />
      {renderPreviewModal()}
    </Flex>
  );
};

export default CustomizeNavigation;
