import { Flex } from '@chakra-ui/layout';
import { IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { ShopBackground } from '../../../../../../../shop-api-client';
import Swatch from '../../../../../../shared/components/BackgroundSwatches/Swatch';
import { ENLARGE } from '../../../../../../shared/constants';
import BackgroundFullScreen from './BackgroundFullScreen';

interface Props {
  selected: ShopBackground;
}

const BackgroundPreview = ({ selected }: Props) => {
  const [showFull, setShowFull] = useState(false);

  const toggleFullScreen = () => setShowFull(!showFull);

  return (
    <Flex justify="center" padding={5} position="relative">
      <BackgroundFullScreen isOpen={showFull} onClose={toggleFullScreen} selected={selected} />
      <Swatch position="relative" size="250px" url={`url(${selected.sources.full}) no-repeat`} />
      <IconButton
        aria-label={ENLARGE}
        icon={<BsArrowsFullscreen size="20px" />}
        onClick={toggleFullScreen}
        position="absolute"
        right={0}
        top={0}
        variant="modalLight"
      />
    </Flex>
  );
};

export default BackgroundPreview;
