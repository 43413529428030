import {
  Box,
  Button,
  Flex,
  Heading,
  Slide,
  Text,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElement, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../../../../shop-api-client';
import {
  selectCheckoutSlice,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import {
  setCheckoutOrderNotes,
  setCheckoutUseShippingForBillingAddress,
  setIsSubmitting,
} from '../../../redux/slices/checkout.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import Checkbox from '../../../shared/components/Checkbox';
import ExternalLink from '../../../shared/components/ExternalLink';
import StickyMobileSection from '../../../shared/components/Mobile/StickyMobileSection';
import { NAVIGATION_Z_INDEX } from '../../../shared/constants';
import useIsElemInView from '../../../shared/hooks/useIsElemInView';
import { Params } from '../../../shared/types/router';
import CheckoutAddressForm from '../CheckoutAddressForm';
import CheckoutAddressComplete from '../CheckoutAddressForm/CheckoutAddressComplete';
import { CheckoutStep } from '../CheckoutData';
import Discounts from '../Discounts';

type SubmitErrorKey = 'stripe' | 'requiredFields' | 'errorProcessingCart';

interface Props extends CheckoutStep {
  hideBilling: boolean;
  scrollToPaymentOptions(): void;
  showUseShipping: boolean;
  showStripe: boolean;
  validateCallback:
    | {
        validate: () => Promise<boolean>;
      }
    | null
    | undefined;
}

const CheckoutPayment = ({
  hideBilling,
  isActive,
  scrollToPaymentOptions,
  setValidateCallback,
  showUseShipping,
  showStripe,
  validateCallback,
}: Props) => {
  const { isSubmitting, formData: checkoutData } = useSelector(selectCheckoutSlice);
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const {
    settings: { hideOrderNotes, shipmentType, showDonationLink },
  } = useAppSelector(state => selectGallery(state, checkoutVisitKeys?.[0] || currentVisitKey!));

  const [paymentType, setPaymentType] = useState<string>();
  const [orderNotes, setOrderNotes] = useState(checkoutData.orderNotes);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<Record<string, string>>({});
  const [useShippingAddress, setUseShippingAddress] = useState(showUseShipping);
  const [validatedBilling, setValidatedBilling] = useState(false);

  // Stripe
  const elements = useElements();
  const stripe = useStripe();

  const { checkoutID } = useParams<Params>();
  const submitBtnRef = useRef<HTMLDivElement>(null);
  const elemInView = useIsElemInView(submitBtnRef.current);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const useBillingMsg = intl.formatMessage({
    id: 'checkoutPayment.paymentUseBillingAddress',
    defaultMessage: 'Billing Address',
  });
  const paymentLabel = intl.formatMessage({
    id: 'checkoutPayment.paymentLabel',
    defaultMessage: 'Payment',
  });
  const useShippingMsg = intl.formatMessage({
    id: 'checkoutPayment.paymentUseShippingAddress',
    defaultMessage: 'Use my shipping address',
  });
  const submit = intl.formatMessage({
    id: 'checkoutPayment.placeOrder',
    defaultMessage: 'Place Order',
  });
  const submitMsg = intl.formatMessage({
    id: 'checkoutPayment.placingOrder',
    defaultMessage: 'Placing Order',
  });
  const donationTextLine1 = intl.formatMessage({
    id: 'checkoutPayment.donationTextLine1',
    defaultMessage:
      'The Tim Tebow Foundation (TTF) mission is to help exploited and forgotten children in the US and all over the world fighting for the Most Vulnerable People with focus on Anti-Human Trafficking/Child Exploration, Orphan Care and Prevention, Profound Medical Needs and Special Need Children.',
  });
  const donationTextLine2 = intl.formatMessage({
    id: 'checkoutPayment.donationTextLine2',
    defaultMessage:
      'The Tim Tebow Foundation has zero administrative fees/costs taken from any donations.',
  });
  const donationLinkHeading = intl.formatMessage({
    id: 'checkoutPayment.donationLinkHeading',
    defaultMessage: 'Would you like to donate?',
  });
  const orderNotesHeading = intl.formatMessage({
    id: 'checkoutPayment.orderNotesHeading',
    defaultMessage: 'Order Notes',
  });
  const processing = intl.formatMessage({
    id: 'checkoutPayment.processing',
    defaultMessage: 'Preparing your order. Please do not refresh or navigate away…',
  });
  const errorProcessingCart = intl.formatMessage({
    id: 'checkoutPayment.errorProcessingCart',
    defaultMessage: 'Error processing cart',
  });
  const stripeFormError = intl.formatMessage({
    id: 'checkoutPayment.stripeFormError',
    defaultMessage: 'Could not initialize payment',
  });
  const requiredFields = intl.formatMessage({
    id: 'checkoutPayment.requiredFields',
    defaultMessage: 'Please fill out all required fields before continuing',
  });

  const handleSubmit = async () => {
    if (!checkoutID) {
      return;
    }
    // reset Submit Message
    setSubmitErrorMessage({});

    // either we use the validated shipping address, and don't need to validate
    // or we need to check if billing address was validated
    let isValid = useShippingAddress || validatedBilling;

    if (!useShippingAddress && !validatedBilling) {
      // try validating again
      // since validation is onBlur, autofill does not trigger a revalidate
      // so attempt final validation here
      const valid = await validateCallback?.validate();
      isValid = !!valid;
    }
    if (!isValid) {
      setSubmitErrorMessage({ requiredFields });
      scrollToPaymentOptions();

      // final validtion attempt was bad, return
      return;
    }

    dispatch(setIsSubmitting(true));

    let stripeForm: StripePaymentElement | null = null;

    if (showStripe) {
      stripeForm = elements?.getElement('payment') || null;

      // If Stripe form fails to initialize, bail with error message
      if (!stripeForm) {
        dispatch(setIsSubmitting(false));
        setSubmitErrorMessage({ stripe: stripeFormError });
        return;
      }

      // Disable Stripe form when staging order
      stripeForm.update({ readOnly: true });
    }

    const updatedCheckoutData = {
      ...checkoutData,
      orderNotes: orderNotes,
      shippingOptions: { useShippingForBillingAddress: useShippingAddress },
      billingAddress: checkoutData.billingAddress,
      paymentType,
    };

    if (useShippingAddress) {
      updatedCheckoutData.billingAddress = checkoutData.shippingAddress;
    }

    const checkout = await api
      .stageCheckout(currentVisitKey!, checkoutID, updatedCheckoutData)
      .catch(() => null);

    if (!checkout) {
      dispatch(setIsSubmitting(false));
      setSubmitErrorMessage({ errorProcessingCart });

      if (stripeForm) {
        // Re-enable Stripe form if staging fails
        stripeForm.update({ readOnly: false });
      }
      return;
    }

    // if zero dollar order, skip confirmpayment
    if (showStripe) {
      if (!stripe || !elements) {
        dispatch(setIsSubmitting(false));
        setSubmitErrorMessage({ stripe: stripeFormError });
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.href}/confirmation`,
          payment_method_data: {
            billing_details: {
              address: {
                city: updatedCheckoutData.billingAddress.city,
                country: updatedCheckoutData.billingAddress.country,
                postal_code: updatedCheckoutData.billingAddress.zip,
                line1: updatedCheckoutData.billingAddress.address1,
                line2: updatedCheckoutData.billingAddress.address2,
                state: updatedCheckoutData.billingAddress.state,
              },
              email: updatedCheckoutData.billingAddress.email,
              name: `${updatedCheckoutData.billingAddress.firstName} ${updatedCheckoutData.billingAddress.lastName}`,
              phone: updatedCheckoutData.billingAddress.phone,
            },
          },
        },
      });

      if (error?.message) {
        dispatch(setIsSubmitting(false));
        setSubmitErrorMessage({ stripe: error.message });

        // Re-enable Stripe form if confirmPayment fails
        if (stripeForm) {
          stripeForm.update({ readOnly: false });
        }
      }
    } else {
      history.push(`/${currentVisitKey}/checkout/${checkoutID}/confirmation`);
    }
  };

  const handleBillingAddress = async () => {
    const valid = await validateCallback?.validate();
    setValidatedBilling(!!valid);
  };

  const handleCheckbox = () => {
    setUseShippingAddress(!useShippingAddress);
    dispatch(setCheckoutUseShippingForBillingAddress(!useShippingAddress));
  };

  const handlePaymentTypeChange = (event: StripePaymentElementChangeEvent) => {
    setPaymentType(event.value.type);
  };

  const handleSaveOrderNotes = () => dispatch(setCheckoutOrderNotes(orderNotes));

  const handleUpdateOrderNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOrderNotes(e.target.value);
  };

  const renderBillingAddress = () => {
    const showHeading = shipmentType !== 'direct' && checkoutData.shippingType !== 'direct';
    return (
      <Flex flexFlow="column" onBlur={handleBillingAddress}>
        {showHeading && (
          <Heading fontSize="md" marginTop={2}>
            {useBillingMsg}
          </Heading>
        )}
        <CheckoutAddressForm
          hasTaxState={checkoutData.shippingType === 'pickup'}
          hideErrorState={!submitErrorMessage['requiredFields']}
          isComplete={false}
          setValidateCallback={setValidateCallback}
          showEmail={!showUseShipping}
          showPhone={!showUseShipping}
          stepKey="billingAddress"
          isActive
        />
      </Flex>
    );
  };

  const renderShippingAddress = () => (
    <CheckoutAddressComplete
      address={checkoutData.shippingAddress}
      marginBottom={4}
      showEmail={false}
      showPhone={false}
    />
  );

  const renderBillingAddressSection = () => {
    if (checkoutData.shippingType === 'direct' || shipmentType === 'direct') {
      return (
        <Flex flexFlow="column" marginBottom={2}>
          <Heading fontSize="md" marginBottom={3}>
            {useBillingMsg}
          </Heading>
          {showUseShipping && (
            <Checkbox
              data-test="use-shipping-address-checkbox"
              isChecked={useShippingAddress}
              isDisabled={isSubmitting}
              marginBottom={4}
              onChange={handleCheckbox}
            >
              {useShippingMsg}
            </Checkbox>
          )}
          {useShippingAddress ? renderShippingAddress() : renderBillingAddress()}
        </Flex>
      );
    }

    return (
      <Flex flexFlow="column" marginBottom={6}>
        {renderBillingAddress()}
      </Flex>
    );
  };

  const renderDonationLink = () => (
    <Flex flexFlow="column" marginBottom={8} onBlur={handleSaveOrderNotes}>
      <Heading fontSize="md" marginBottom={3}>
        {donationLinkHeading}
      </Heading>
      <Text fontSize="13px">{donationTextLine1}</Text>
      <Text fontSize="13px" marginY={4}>
        {donationTextLine2}
      </Text>
      <ExternalLink
        url="community.timtebowfoundation.org/give/438262/#!/donation/checkout"
        text="Donate Here"
      />
    </Flex>
  );

  const renderOrderNotes = () => (
    <Flex flexFlow="column" marginBottom={8}>
      <Heading fontSize="md" marginBottom={3}>
        {orderNotesHeading}
      </Heading>
      <Textarea
        borderColor="grey.3"
        borderWidth={2}
        isDisabled={isSubmitting}
        onChange={e => handleUpdateOrderNotes(e)}
        value={orderNotes}
      />
    </Flex>
  );

  const renderSubmitError = (key: SubmitErrorKey) => {
    if (!submitErrorMessage[key]) {
      return null;
    }
    return (
      <Text marginTop={2} fontSize="xs" color="error" data-test="payment-error-message">
        {submitErrorMessage[key]}
      </Text>
    );
  };

  const renderSubmitBtn = () => (
    <Box width="100%">
      <Flex bgColor="white" justifyContent={{ base: 'center', lg: 'flex-end' }} marginY={4}>
        <Button
          data-test="checkout-place-order-button"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          loadingText={submitMsg}
          maxW={{ base: '100%', lg: '160px' }}
          onClick={handleSubmit}
          width={{ base: '100%', lg: '40%' }}
        >
          {submit}
        </Button>
      </Flex>

      <Flex justifyContent={{ base: 'center', lg: 'flex-end' }}>
        {renderSubmitError('requiredFields')}
      </Flex>
    </Box>
  );

  const renderStripe = () => {
    return (
      <Box data-test="checkout-payment-details-container">
        <Heading fontSize="md" marginBottom={3}>
          {paymentLabel}
        </Heading>
        <PaymentElement
          key={isActive ? 'active' : 'inactive'}
          id="stripe-payment-element"
          onChange={handlePaymentTypeChange}
        />
      </Box>
    );
  };

  return (
    <div style={{ display: isActive ? 'inherit' : 'none' }}>
      {!hideBilling && renderBillingAddressSection()}
      <Discounts />
      {!!showDonationLink && renderDonationLink()}
      {!hideOrderNotes && renderOrderNotes()}
      {showStripe && renderStripe()}
      {renderSubmitError('stripe') || renderSubmitError('errorProcessingCart')}
      <Flex ref={submitBtnRef}>{renderSubmitBtn()}</Flex>
      {isMobile && !elemInView && (
        <Slide
          direction="bottom"
          in={!elemInView}
          style={{ height: '75px', zIndex: NAVIGATION_Z_INDEX }}
        >
          <StickyMobileSection show={!elemInView} direction="bottom">
            {renderSubmitBtn()}
          </StickyMobileSection>
        </Slide>
      )}
      {isSubmitting && (
        <Text size="sm" align="right" as="cite">
          {processing}
        </Text>
      )}
    </div>
  );
};

export default CheckoutPayment;
