import { useEffect, useRef, useState } from 'react';

const useIsElemInView = (
  targetElem: HTMLElement | null,
  addedOffset = 0,
  useBoundingRect = false,
) => {
  const [inView, setInView] = useState(false);
  const scrollPosition = useRef<number>(window.scrollY);

  useEffect(() => {
    if (!targetElem) {
      return;
    }

    const handleOnScroll = () => {
      const offsetTop = useBoundingRect
        ? targetElem.getBoundingClientRect().top + scrollPosition.current
        : targetElem.offsetTop;

      const fromTop = scrollPosition.current + window.innerHeight >= offsetTop + addedOffset;
      const fromBottom = scrollPosition.current <= offsetTop + addedOffset;

      setInView(fromTop && fromBottom);
      scrollPosition.current = window.scrollY;
    };

    window.addEventListener('scroll', handleOnScroll);

    handleOnScroll();

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, [addedOffset, targetElem, useBoundingRect]);

  return inView;
};

export default useIsElemInView;
