import { Image } from '@chakra-ui/react';
import React from 'react';
import { ShopBackground } from '../../../../../../../../shop-api-client';
import Modal from '../../../../../../../shared/components/Modal';

interface Props {
  isOpen: boolean;
  onClose(): void;
  selected: ShopBackground;
}

const BackgroundFullScreen = ({ isOpen, onClose, selected }: Props) => (
  <Modal isOpen={isOpen} modalBodyPaddingX={2} onClose={onClose}>
    <Image src={selected.sources.full} />
  </Modal>
);

export default BackgroundFullScreen;
