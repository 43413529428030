import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return { getParamsValue: (val: string) => searchParams.get(val) || '' };
  }, [search]);
};

export default useSearchParams;
