import { ShopBackground } from '../../../shop-api-client';
import { getBackgroundFromSets } from '../../features/Products/utils';
import { getImageBuffer } from '../../shared/utils';
import { setBuyModeImage, setSelectedBackground } from '../slices/interactions.slice';
import { Thunk } from '../store';

export const loadBackgroundImage =
  (background: ShopBackground | null): Thunk =>
  async dispatch => {
    const backgroundBuffer = background ? await getImageBuffer(background.sources.full) : null;
    dispatch(setSelectedBackground({ background, selectedBackgroundData: backgroundBuffer }));
  };

export const initializeSelectAndBuy =
  (imageName?: string, backgroundID?: number): Thunk =>
  (dispatch, getState) => {
    const { gallery, visitor } = getState();
    const { galleryMap, priceSheetMap } = gallery;
    const { currentVisitKey } = visitor;
    const { images, priceSheetID } = galleryMap[currentVisitKey!];
    const { backgroundSets } = priceSheetMap[priceSheetID];

    const image = imageName ? images[imageName] : undefined;
    const background = image?.isGreenScreen
      ? getBackgroundFromSets(backgroundSets, backgroundID)
      : undefined;

    dispatch(setBuyModeImage({ image: imageName || null, background }));
  };
