import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ShopBuildYourOwnPackage, ShopPackage } from '../../../../../../shop-api-client';
import { selectAccount } from '../../../../../redux/selectors/account.selectors';
import {
  selectConfiguration,
  selectConfigurationSummary,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../redux/selectors/priceSheet.selectors';
import ArrowLeftbutton from '../../../../../shared/components/ArrowLeftButton';
import { NAVIGATION_Z_INDEX } from '../../../../../shared/constants';
import { HIDE_SCROLLBAR_STYLES } from '../../../../../shared/constants/style.constants';
import { formatCurrency } from '../../../../../shared/utils';
import { ADD_ONS, IMAGE_ADD_ONS, SUMMARY } from '../../constants';
import useConfigurationRouter, { PathKey } from '../../useConfigurationRouter';
import {
  requiresPerProductBG,
  validateBackgroundSelection,
  validateConfiguredItem,
} from '../../utils';
import WizardStepHeading from './WizardStepHeading';

interface Props {
  onBackClick(): void;
}

export const TOOLBAR_HEIGHT = 65;
const MOBILE_HEADING_HEIGHT = 35;
export const MOBILE_TOOLBAR_HEIGHT = TOOLBAR_HEIGHT + MOBILE_HEADING_HEIGHT;

const WizardNavigation = ({ onBackClick }: Props) => {
  const { editPackage } = useSelector(selectConfiguration);
  const { currency } = useSelector(selectAccount);
  const { subtotal } = useSelector(selectConfigurationSummary);
  const { isGreenScreen, isPreOrder } = useSelector(selectGallery);
  const {
    backgroundSets,
    imageOptionMap,
    preOrderBackgroundSelectionType,
    products,
    productNodeMap,
  } = useSelector(selectPriceSheet);

  const { routeToWizardStep } = useConfigurationRouter();
  const { pathname } = useLocation();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const ref = useRef<HTMLDivElement>(null);

  const backgroundsHeading = intl.formatMessage({
    id: 'wizardNavigation.background',
    defaultMessage: 'Background',
  });
  const customizeHeading = intl.formatMessage({
    id: 'wizardNavigation.customize',
    defaultMessage: 'Customize',
  });
  const buildPackageHeading = intl.formatMessage({
    id: 'wizardNavigation.buildPackage',
    defaultMessage: 'Build Package',
  });

  const requiresBG = requiresPerProductBG(
    'package',
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (
      pathname.includes('/backgrounds') ||
      (!requiresBG && (pathname.includes('/customize') || pathname.includes('/byop-selection')))
    ) {
      ref.current.scrollLeft = 0;
    }
    if (pathname.includes('/summary')) {
      ref.current.scrollLeft = ref.current.clientWidth;
    }
  }, [pathname, requiresBG]);

  // If we're customizing an item, a different navigation renders, so opt-out.
  // Also make sure to check for `/customize/` with a trailing slash, to prevent
  // an early exit on the customize overview page
  if (!editPackage || (pathname.includes('/customize/') && isMobile)) {
    return null;
  }

  const shopPkg = products[editPackage.priceSheetItemID] as ShopPackage;
  const getBYOPReadyState = () => {
    const { minUnits } = shopPkg as ShopBuildYourOwnPackage;
    const includedCount = shopPkg.availableProducts.filter(p => p.price === 0).length;
    return minUnits + includedCount <= editPackage.products.length;
  };

  const hasImageOptions = Object.keys(imageOptionMap).length > 0;
  const isBYOP = editPackage.type === 'buildYourOwn';
  const isBYOPDone = !isBYOP || getBYOPReadyState();
  const isConfigured = validateConfiguredItem(
    editPackage,
    shopPkg,
    productNodeMap,
    isPreOrder,
    requiresBG,
  );
  const isBGDone = !!editPackage && validateBackgroundSelection(editPackage, requiresBG);

  const handleRouteToPath = (pathKey: PathKey) => () => routeToWizardStep(pathKey);

  const renderStepHeadings = () => (
    <Flex ref={ref} css={HIDE_SCROLLBAR_STYLES} overflowX="auto" paddingX={{ base: 2, md: 7 }}>
      {requiresBG && (
        <WizardStepHeading
          heading={backgroundsHeading}
          isActive={pathname.includes('/backgrounds')}
          isReady={true}
          onClick={handleRouteToPath('background')}
        />
      )}
      {isBYOP && (
        <WizardStepHeading
          heading={buildPackageHeading}
          isActive={pathname.includes('/byop-selection')}
          isReady={isBGDone}
          onClick={handleRouteToPath('byop')}
        />
      )}
      <WizardStepHeading
        heading={customizeHeading}
        isActive={pathname.includes('/customize')}
        isReady={isBGDone && isBYOPDone}
        onClick={handleRouteToPath('customize')}
      />
      {hasImageOptions && (
        <WizardStepHeading
          heading={isMobile ? ADD_ONS : IMAGE_ADD_ONS}
          isActive={pathname.includes('/image-options')}
          isReady={isBGDone && isBYOPDone && isConfigured}
          onClick={handleRouteToPath('imageOption')}
        />
      )}
      <WizardStepHeading
        heading={SUMMARY}
        isActive={pathname.includes('/summary')}
        isReady={isBGDone && isBYOPDone && isConfigured}
        onClick={handleRouteToPath('summary')}
      />
    </Flex>
  );

  return (
    <Flex
      backgroundColor="white"
      borderBottomColor="grey.1"
      borderBottomWidth="1px"
      direction="column"
      position="sticky"
      top={0}
      zIndex={NAVIGATION_Z_INDEX}
    >
      <Flex align="center" height={`${TOOLBAR_HEIGHT}px`} paddingX={5} justify="space-between">
        <Flex>
          <ArrowLeftbutton
            aria-label="Back"
            border="none"
            fontSize="md"
            lightMode
            onClick={onBackClick}
            size="sm"
          />
          <Flex direction="column" marginLeft={1}>
            <Text fontSize="11px">
              {intl.formatMessage({
                id: 'wizardNavigation.backTo',
                defaultMessage: 'Back to',
              })}
            </Text>
            <Text fontSize="xs" lineHeight={1.2} noOfLines={1} whiteSpace="nowrap" maxWidth="220px">
              {shopPkg.name}
            </Text>
          </Flex>
        </Flex>
        <Flex height="100%" align="flex-end">
          {!isMobile && renderStepHeadings()}
        </Flex>
        <Text fontSize="xl">{formatCurrency(subtotal, currency)}</Text>
      </Flex>
      {isMobile && renderStepHeadings()}
    </Flex>
  );
};

export default WizardNavigation;
