import { Box, BoxProps, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import Thumbnail from '../../../../../../shared/components/Thumbnail';
import { UniqueImage } from '../../../../../../shared/types/image';

const THUMBNAIL_STACK_LEFT_OFFSET = 4;

interface Props extends BoxProps {
  imageSet: UniqueImage[];
  visitKey: string;
}

/**
 * Renders a stack of thumbnails if there's more than 1 unique image, and shows only the first 3.
 * If only 1 image is present, it shows as a regular thumbnail.
 *
 * To account for variance in aspect ratio, and to maintain stack appearance,
 * each image uses the same dimensions as the top most image.
 */
const CartItemThumbnailStack = ({ imageSet, visitKey, ...boxProps }: Props) => {
  const { images } = useAppSelector(state => selectGallery(state, visitKey));
  const backgroundsMap = useSelector(selectBackgroundsMap);

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const imageStack = imageSet.filter(image => !!images[image.image]).slice(0, 3);
  const containerWidth = isMobile ? 45 : 57;
  const thumbnailSize = containerWidth - (imageStack.length - 1) * THUMBNAIL_STACK_LEFT_OFFSET;

  const { aspectRatio } = images[imageStack[0].image];
  const scaledHeight = thumbnailSize / aspectRatio;
  const scaledWidth = scaledHeight * aspectRatio;

  const renderThumbnail = (name: string, index: number, backgroundID?: number) => {
    const image = images[name];
    const { internalName, sources } = image;
    const background = backgroundID ? backgroundsMap[backgroundID]?.sources.thumb : undefined;
    const thumbnailShadow =
      imageStack.length > 1 ? '1px 1px 1.5px 0.1px rgba(0, 0, 0, 0.5)' : undefined;

    return (
      <Flex
        key={`${internalName}-${backgroundID}`}
        position="absolute"
        top={`${index * 3}px`}
        left={`${index * THUMBNAIL_STACK_LEFT_OFFSET}px`}
        zIndex={imageStack.length - index}
      >
        <Thumbnail
          background={background}
          boxShadow={thumbnailShadow}
          hideSpinner
          src={sources.thumb}
          height={scaledHeight}
          width={scaledWidth}
        />
      </Flex>
    );
  };

  const renderThumbnails = () =>
    imageStack.map((image, index) => renderThumbnail(image.image, index, image.backgroundID));

  return (
    <Box
      position="relative"
      width={`${containerWidth}px`}
      height={`${scaledHeight}px`}
      marginBottom={{ base: 2.5, md: 0 }}
      {...boxProps}
    >
      {renderThumbnails()}
    </Box>
  );
};

export default CartItemThumbnailStack;
