import { Box, Icon, Select, SelectProps, Text } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { Z_INDEX_ONE } from '../../constants';

interface Props extends SelectProps {
  children: ReactNode;
  defaultValue?: string | number;
  invalidMessage?: string;
}

const FloatingLabelSelect = ({
  children,
  defaultValue,
  invalidMessage,
  isInvalid,
  isRequired,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  placeholder,
  value,
  width,
  ...rest
}: Props) => {
  const [isActive, setIsActive] = useState(!!value || !!defaultValue);
  const intl = useIntl();

  useEffect(() => setIsActive(!!value || !!defaultValue), [value, defaultValue]);

  const selectPlaceholder = intl.formatMessage({
    id: 'floatingLabelSelect.selectPlaceholder',
    defaultMessage: 'Select…',
  });

  const handleBlur = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setIsActive(e.target.value !== '');

  const handleFocus = () => setIsActive(true);

  return (
    <Box
      alignContent="center"
      display="flex"
      flexDirection="column"
      margin={margin}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      position="relative"
      width={width}
    >
      <Select
        defaultValue={defaultValue}
        fontWeight="bold"
        icon={<Icon as={MdArrowDropDown} marginBottom="10px" />}
        isInvalid={isInvalid}
        isRequired={isRequired}
        minWidth="60px"
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={isActive ? selectPlaceholder : ' '}
        rootProps={{ backgroundColor: 'white', borderRadius: '8px' }}
        value={value}
        variant="floating"
        {...rest}
      >
        {children}
      </Select>
      <Text
        as="label"
        background={isActive ? 'transparent' : 'white'}
        color={isInvalid ? 'error' : isActive ? 'grey.6' : 'black'}
        fontSize="16px"
        marginLeft="5px"
        maxWidth={width}
        noOfLines={1}
        padding="0 10px"
        position="absolute"
        transform={isActive ? 'translate(0, 5px) scale(0.70) ' : 'translate(0, 15px) scale(1) '}
        transformOrigin="top left"
        transition="all 0.2s ease-out"
        zIndex={isActive ? Z_INDEX_ONE : 0}
      >
        {placeholder}
        {isRequired && (
          <Text as="span" color="error" paddingLeft="2">
            *
          </Text>
        )}
      </Text>
      {isInvalid && (
        <Text color="error" fontSize="12px" marginLeft="5px" marginTop="8px">
          {invalidMessage || `Please enter a valid ${placeholder}`}
        </Text>
      )}
    </Box>
  );
};

export default FloatingLabelSelect;
