import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FiGrid, FiSquare } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectCart } from '../../../redux/selectors/cart.selectors';
import {
  selectActiveCategory,
  selectInteractions,
} from '../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { setActiveCategoryID } from '../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../redux/store';
import { FILTER, SEARCH } from '../../../shared/constants';
import { CLEAR_FILTERS } from '../../../shared/constants/labels.constants';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import { Params } from '../../../shared/types/router';
import { getCartItemsFromCategory } from '../../Carts/utils';
import CartItemDrawer from '../CartItemDrawer';
import { PRODUCTS_GUTTER } from '../constants';
import NoResultsFound from '../NoResultsFound';
import OffersInstructions from '../OffersInstructions';
import ProductCard from '../Productcard';
import ProductsSkeleton from '../ProductsSkeleton';
import PurchaseCriteriaBanner from '../PurchaseCriteriaBanner';
import { getFilteredProducts } from '../utils';

const PRODUCT_CARD_WIDTH = 222;
const PRODUCT_CARD_MARGIN = 10;
const MARGIN = 60;
const MAX_COLUMNS = 5;
const MAX_COLUMN_WIDTH = (PRODUCT_CARD_WIDTH + PRODUCT_CARD_MARGIN * 2) * MAX_COLUMNS;
const SIDEBAR = 370 + 60;
const SIDEBAR_PADDING_RIGHT = 60;
const TABLET_MARGIN = 20;
const WIDTH_WITHOUT_GRID = SIDEBAR + SIDEBAR_PADDING_RIGHT + MARGIN * 2;

const GRID_ICON_STYLES = {
  fontSize: '2xl',
  left: '0',
  marginLeft: '5px',
  top: '10px',
};

const SINGLE_COL_GRID_STYLES = {
  mobileCardWidth: '100%',
};

const DOUBLE_COL_GRID_STYLES = {
  mobileCardWidth: `calc(50% - ${PRODUCTS_GUTTER / 2}px)`,
  _even: {
    marginLeft: `${PRODUCTS_GUTTER / 2}px`,
  },
  _odd: {
    marginRight: `${PRODUCTS_GUTTER / 2}px`,
  },
};

const DESKTOP_GRID_STYLES = {
  margin: '10px',
};

const Category = () => {
  const priceSheet = useSelector(selectPriceSheet);
  const { activeCategoryID } = useSelector(selectInteractions);
  const activeCategory = useSelector(selectActiveCategory);
  const cart = useSelector(selectCart);

  const [isLoading, setIsLoading] = useState(true);
  const [isSingleColumn, setIsSingleColumn] = useState(false);

  const { categoryID } = useParams<Params>();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const isTablet = useBreakpointValue({ md: true, lg: false });

  const { search } = history.location;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const searchString = searchParams.get(SEARCH)?.split('+').join(' ') || '';
  const filteredProducts = useMemo(
    () => getFilteredProducts(activeCategoryID, priceSheet, searchParams),
    [activeCategoryID, priceSheet, searchParams],
  );

  const { categories, offersStatusMap, productCategoryMap, products } = priceSheet;
  const catCartItems = categories[categoryID]
    ? getCartItemsFromCategory(cart, productCategoryMap[categoryID])
    : [];
  const category = categories[categoryID];
  const filters = searchParams.getAll(FILTER);
  const mobileCardStyles = isSingleColumn ? SINGLE_COL_GRID_STYLES : DOUBLE_COL_GRID_STYLES;
  const productCardStyles = isMobile ? mobileCardStyles : DESKTOP_GRID_STYLES;
  // Show purchase criteria banner if category is not in the `offersStatusMap`, or if it is,
  // then no cart items from this category:
  const showBanner =
    !!category &&
    category.selectionType !== 'none' &&
    (!offersStatusMap[categoryID] || catCartItems.length === 0);
  const isFlexDirRow = isMobile && !offersStatusMap[categoryID] && !showBanner;

  useEffect(() => {
    if (!isMobile && isSingleColumn) {
      setIsSingleColumn(false);
    }
  }, [isMobile, isSingleColumn]);
  useLayoutEffect(() => {
    // If the categoryID param is present but no category is found, then the categoryID
    // is not valid, so route user to '/shop/all'
    if (categoryID && !categories[categoryID]) {
      history.push('all');
    } else {
      // Set the active category from the categoryID URL parameter or fallback to All Categories:
      dispatch(setActiveCategoryID(categories[categoryID] ? categoryID : 'all'));
      setIsLoading(false);
    }
  }, [categories, categoryID, dispatch, history]);

  const handleClearFilters = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(FILTER);
    history.replace({ search: `${searchParams}` });
  };

  if (isLoading || !activeCategory) {
    return <ProductsSkeleton />;
  }

  const displayedProductsCount = filteredProducts.length;
  const totalProductsCount = productCategoryMap[categoryID || 'all'].length;

  const getGridWidth = () => {
    // If tablet window dimensions, set grid width to full width, minus margins
    if (isTablet) {
      return width - TABLET_MARGIN * 2;
    }
    // If isMobile, set width to 100%
    if (isMobile) {
      return '100%';
    }

    // If the max grid width is greater than the window width, set grid width to window width
    // Otherwise, set grid width to allow the maximum number of columns
    return Math.min(width - WIDTH_WITHOUT_GRID, MAX_COLUMN_WIDTH);
  };

  const handleViewDoubleColumn = () => setIsSingleColumn(false);

  const handleViewSingleColumn = () => setIsSingleColumn(true);

  const handleViewProduct = (productID: number) => {
    if (!products[productID]) {
      return;
    }
    history.push(`${products[productID].categoryID}/product/${productID}`);
  };

  // Displays the number of filtered items of total products on desktop
  const renderfilteredItemsCount = () => (
    <Flex>
      <Text fontSize="14px" marginY="15px" data-test="displayedProductsCount">
        {intl.formatMessage(
          {
            id: 'category.desktopCount',
            defaultMessage: 'Showing {displayedProductsCount} of {totalProductsCount} items',
          },
          { displayedProductsCount, totalProductsCount },
        )}
      </Text>
      {!!filters.length && (
        <Button marginX="40px" onClick={handleClearFilters} variant="link">
          {CLEAR_FILTERS}
        </Button>
      )}
      <Spacer />
    </Flex>
  );

  // Renders the single and double column controls for mobile users
  const renderGridDisplayControls = () => (
    <Flex
      align="center"
      alignSelf="flex-end"
      direction="row"
      paddingBottom="2px"
      paddingRight="5px"
    >
      <Text fontSize="12px" data-test="displayedProductsCount">
        {intl.formatMessage(
          {
            id: 'category.mobileCount',
            defaultMessage: '{displayedProductsCount} items',
          },
          { displayedProductsCount },
        )}
      </Text>
      <Icon
        as={FiSquare}
        color={isSingleColumn ? 'brand' : 'black'}
        onClick={handleViewSingleColumn}
        {...GRID_ICON_STYLES}
      />
      <Icon
        as={FiGrid}
        color={!isSingleColumn ? 'brand' : 'black'}
        onClick={handleViewDoubleColumn}
        {...GRID_ICON_STYLES}
      />
    </Flex>
  );

  const renderHeading = () => {
    if (searchString.length) {
      if (!filteredProducts.length) {
        return '';
      }
      return intl.formatMessage(
        {
          id: 'category.searchResults',
          defaultMessage: 'Search Results for "{searchString}"',
        },
        { searchString },
      );
    }
    return intl.formatMessage(
      { id: 'category.shopActiveCategory', defaultMessage: 'Shop {activeCategory}' },
      { activeCategory },
    );
  };

  if (!!searchString.length && !filteredProducts.length) {
    return <NoResultsFound />;
  }

  return (
    <Box
      marginBottom="125px"
      width={isMobile ? `calc(100vw - ${PRODUCTS_GUTTER * 2}px)` : 'inherit'}
    >
      <CartItemDrawer />
      <Flex direction={isFlexDirRow ? 'row' : 'column'} marginBottom="2">
        <Heading fontSize="22px" width={isFlexDirRow ? '65%' : '100%'} data-test={activeCategory}>
          {renderHeading()}
        </Heading>
        {!!category && <OffersInstructions category={category} />}
        {!!category && showBanner && (
          <PurchaseCriteriaBanner selectionType={category.selectionType} />
        )}
        {!isMobile &&
          (!searchString.length || !!filteredProducts.length) &&
          renderfilteredItemsCount()}
        <Spacer />
        {isMobile && renderGridDisplayControls()}
      </Flex>
      <Flex
        direction={isSingleColumn ? 'column' : 'row'}
        width={getGridWidth()}
        wrap={isSingleColumn ? 'nowrap' : 'wrap'}
      >
        {filteredProducts.map(product => (
          <ProductCard
            key={product.id}
            maxCardWidth="222px"
            onCardClick={handleViewProduct}
            product={product}
            showDetails
            showQuickView={!isMobile}
            {...productCardStyles}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Category;
