import {
  AspectRatio,
  Box,
  BoxProps,
  Image as ChakraImage,
  Flex,
  Heading,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FiCamera, FiLock } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { ShopPackage, ShopProduct } from '../../../../shop-api-client';
import { getAspectRatio } from '../../../shared/utils';
import { getProductImageSrc } from '../utils';

interface Props extends BoxProps {
  fallbackFontSize?: string;
  fallbackIconSize?: string;
  image: string;
  isLocked?: boolean;
  isSquare?: boolean;
  product?: { type: (ShopProduct | ShopPackage)['type']; maxImages?: number };
  width?: number;
}

const ProductImage = ({
  fallbackFontSize = 'md',
  fallbackIconSize = '60px',
  image,
  isLocked,
  isSquare,
  product,
  width,
  ...rest
}: Props) => {
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const isLandscape = imageHeight < imageWidth;

  // Ensures that the Locked product overlay width is that of the product image,
  // whose width is not known at the time of render
  useEffect(() => {
    if (!isLoading || !containerRef.current) {
      return;
    }

    const img = new Image();
    img.src = image;
    img.onload = () => {
      if (!containerRef.current) {
        return;
      }
      const { clientHeight, clientWidth } = containerRef.current;
      const isLandscape = img.height < img.width;
      const ratio = getAspectRatio(img.height, img.width);
      const containerWidth = width || clientWidth;
      const maxWidth = containerWidth ? Math.min(img.width, containerWidth) : img.width;
      const maxHeight = (isSquare ? width : maxWidth) || clientHeight;

      const getHeight = () =>
        Math.min(img.height > img.width ? maxWidth * ratio : maxWidth / ratio, maxHeight);

      const imageHeight = getHeight();
      const imageWidth = isLandscape
        ? Math.min(imageHeight * ratio, maxWidth)
        : imageHeight / ratio;

      setImageHeight(getHeight());
      setImageWidth(imageWidth);
    };

    setIsLoading(false);
  }, [image, isLoading, isSquare, width]);

  const getImageSrc = () => {
    if (!image && product) {
      return getProductImageSrc(product);
    }
    return image;
  };

  const renderLoadingState = () => (
    <AspectRatio ratio={1} width={imageWidth ? `${imageWidth}px` : '100%'} {...rest}>
      <Box>
        <Spinner color="grey.3" />
      </Box>
    </AspectRatio>
  );

  const renderLockOverlay = () => (
    <Box
      backgroundColor="semiTransparentBlack"
      height={0}
      paddingBottom="100%"
      position="absolute"
      width="100%"
      zIndex={1}
    >
      <Flex
        align="center"
        direction="column"
        height="100%"
        justify="center"
        position="absolute"
        width="100%"
      >
        <Icon as={FiLock} color="white" fontSize="100px" />
      </Flex>
    </Box>
  );

  const renderImage = () => {
    const imageSrc = getImageSrc();
    if (!imageSrc) {
      return (
        <Box
          backgroundColor="grey.1"
          height={0}
          paddingBottom="100%"
          position="relative"
          width="100%"
        >
          <Flex
            align="center"
            direction="column"
            height="100%"
            justify="center"
            position="absolute"
            width="100%"
          >
            <Icon as={FiCamera} color="grey.3" fontSize={fallbackIconSize} />
            <Heading
              color="grey.5"
              fontSize={fallbackFontSize}
              marginX="20%"
              marginY="6%"
              textAlign="center"
              data-test="product-image-heading"
            >
              {intl.formatMessage({
                id: 'productImage.photoNotAvailable',
                defaultMessage: 'Sorry, Photo Not Available',
              })}
            </Heading>
          </Flex>
        </Box>
      );
    }
    return (
      <ChakraImage
        alignSelf={isSquare ? 'center' : 'flex-start'}
        fallback={renderLoadingState()}
        height={!isLandscape ? '100%' : 'auto'}
        maxHeight="100%"
        maxWidth={isLandscape ? '100%' : 'auto'}
        src={imageSrc}
      />
    );
  };

  return (
    <Flex
      ref={containerRef}
      height="100%"
      justify="center"
      position="relative"
      width="100%"
      {...rest}
    >
      {isLocked && renderLockOverlay()}
      {renderImage()}
    </Flex>
  );
};

export default ProductImage;
