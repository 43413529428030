import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CartError,
  CartFinancials,
  CartImageOption,
  CartPackage,
  CartProduct,
  OrderFinancials,
  ShopFavorite,
  VisitWithCart,
} from '../../../shop-api-client/models/Cart';
import { Discount } from '../../../shop-api-client/models/Discounts';
import { reset } from './checkout.slice';
import { signout } from './visitor.slice';

export type InvalidOptionsMap = Record<string, boolean>;
export type ShopCartItem = CartPackage | CartProduct;

interface JustAdded {
  initial: ShopCartItem | null;
  other: ShopCartItem[];
}

export const CART_FINANCIALS_DEFAULTS: CartFinancials = {
  backgroundFees: 0,
  digitalDownloadSubtotal: 0,
  discountAmount: 0,
  galleryID: null,
  handling: 0,
  imageOptionFees: 0,
  orderOptionFees: 0,
  subjectID: null,
  subtotal: 0,
  subtotalDiscount: 0,
  total: 0,
  visitKey: null,
};

export const JUST_ADDED_DEFAULT: JustAdded = {
  initial: null,
  other: [],
};

export const SUMMARY_FINANCIALS_DEFAULTS: OrderFinancials = {
  backgroundFees: 0,
  digitalDownloadTax: 0,
  discount: 0,
  handling: 0,
  imageOptionFees: 0,
  orderOptionFees: 0,
  shipping: 0,
  subtotal: 0,
  tax: 0,
  total: 0,
};

export const DEFAULT_VISIT_WITH_CART: VisitWithCart = {
  cartImageOptions: [],
  cartPackages: [],
  cartProducts: [],
  shopFavorites: [],
};

const initialState: {
  cartDiscounts: Record<string, Discount[]>;
  cartErrors: Record<string, CartError[]>;
  cartFinancials: Record<string, CartFinancials>;
  cartMap: Record<string, VisitWithCart>;
  isSubmittingCart: boolean;
  /**
   * The `justAdded` state is used to trigger the `CartItemDrawer` when the
   * `initial` property is populated with a cart item.
   * Items are pushed to the `other` array while the drawer is opened. This
   * occurs when an item is unlocked by adding the initial item to the cart,
   * and is then automatically added, or when an item that requires no
   * configuration is added from the list of other items in the drawer
   */
  justAdded: JustAdded;
  summaryFinancials: OrderFinancials;
} = {
  cartDiscounts: {},
  cartErrors: {},
  cartFinancials: {},
  cartMap: {},
  justAdded: JUST_ADDED_DEFAULT,
  isSubmittingCart: false,
  summaryFinancials: SUMMARY_FINANCIALS_DEFAULTS,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    removeJustAdded(state) {
      state.justAdded = JUST_ADDED_DEFAULT;
    },
    removeCartOption(
      state,
      action: PayloadAction<{
        cartOptionID: number;
        visitKey: string;
      }>,
    ) {
      const { cartOptionID, visitKey } = action.payload;
      state.cartMap[visitKey].cartImageOptions = state.cartMap[visitKey].cartImageOptions.filter(
        option => option.id != cartOptionID,
      );
    },
    removeCartProduct(
      state,
      action: PayloadAction<{
        cartProductID: number;
        visitKey: string;
      }>,
    ) {
      const { cartProductID, visitKey } = action.payload;
      state.cartMap[visitKey].cartProducts = state.cartMap[visitKey].cartProducts.filter(
        p => p.id !== cartProductID,
      );
    },
    removeCartPackage(
      state,
      action: PayloadAction<{
        cartPackageID: number;
        visitKey: string;
      }>,
    ) {
      const { cartPackageID, visitKey } = action.payload;
      state.cartMap[visitKey].cartPackages = state.cartMap[visitKey].cartPackages.filter(
        p => p.id !== cartPackageID,
      );
    },
    setCartDiscounts(state, action: PayloadAction<Record<string, Discount[]>>) {
      state.cartDiscounts = action.payload;
    },
    setCartErrors(state, action: PayloadAction<Record<string, CartError[]>>) {
      state.cartErrors = action.payload;
    },
    setCartImageOption(
      state,
      action: PayloadAction<{
        visitKey: string;
        cartOption: CartImageOption;
      }>,
    ) {
      const { cartOption, visitKey } = action.payload;
      const index = state.cartMap[visitKey].cartImageOptions.findIndex(p => p.id === cartOption.id);
      // If the cart option already exists, then update it to the incoming `cartOption`
      if (index > -1) {
        state.cartMap[visitKey].cartImageOptions[index] = cartOption;
      } else {
        // Else, push the option to the cartImageOptions array
        state.cartMap[visitKey].cartImageOptions.push(cartOption);
      }
    },
    setCartImageOptions(
      state,
      action: PayloadAction<{
        visitKey: string;
        cartOptions: CartImageOption[];
      }>,
    ) {
      const { cartOptions, visitKey } = action.payload;
      state.cartMap[visitKey].cartImageOptions = cartOptions;
    },
    setCartMap(state, action: PayloadAction<Record<string, VisitWithCart>>) {
      state.cartMap = action.payload;
    },
    setCartPackage(
      state,
      action: PayloadAction<{
        cartPackage: CartPackage;
        justAdded?: boolean;
        visitKey: string;
      }>,
    ) {
      const { cartPackage, justAdded, visitKey } = action.payload;
      const index = state.cartMap[visitKey].cartPackages.findIndex(p => p.id === cartPackage.id);
      // If the cart package already exists, then update it to the incoming `cartPackage`
      if (index > -1) {
        state.cartMap[visitKey].cartPackages[index] = cartPackage;
      } else {
        // Else, push the package to the cartPackages array
        state.cartMap[visitKey].cartPackages.push(cartPackage);
      }

      // If justAdded is true, add the product to the justAdded state:
      if (justAdded) {
        // If the initial item that triggers the CartItemDrawer is not yet stored, set it:
        if (!state.justAdded.initial) {
          state.justAdded.initial = cartPackage;
        } else {
          // Push other items, auto-added or added from already opened drawer, to `other` array:
          state.justAdded.other.push(cartPackage);
        }
      }
    },
    setCartProduct(
      state,
      action: PayloadAction<{
        cartProduct: CartProduct;
        justAdded?: boolean;
        visitKey: string;
      }>,
    ) {
      const { cartProduct, justAdded, visitKey } = action.payload;
      const index = state.cartMap[visitKey].cartProducts.findIndex(p => p.id === cartProduct.id);

      // If the cart product already exists, then update it to the incoming `cartProduct`
      if (index > -1) {
        state.cartMap[visitKey].cartProducts[index] = cartProduct;
      } else {
        // Else, push the product to the cartProducts array
        state.cartMap[visitKey].cartProducts.push(cartProduct);
      }

      // If justAdded is true, add the product to the justAdded state:
      if (justAdded) {
        // If the initial item that triggers the CartItemDrawer is not yet stored, set it:
        if (!state.justAdded.initial) {
          state.justAdded.initial = cartProduct;
        } else {
          // Push other items, auto-added or added from already opened drawer, to `other` array:
          state.justAdded.other.push(cartProduct);
        }
      }
    },
    setFavorites(state, action: PayloadAction<{ visitKey: string; favorites: ShopFavorite[] }>) {
      const { visitKey, favorites } = action.payload;
      state.cartMap[visitKey].shopFavorites = favorites;
    },
    // Only used for any financials outside of checkout
    setFinancials(
      state,
      action: PayloadAction<{
        summaryFinancials: OrderFinancials;
        cartFinancials: Record<string, CartFinancials>;
      }>,
    ) {
      const { summaryFinancials, cartFinancials } = action.payload;
      state.cartFinancials = cartFinancials; // for now we completely overwrite every time
      state.summaryFinancials = summaryFinancials;
    },
    setIsSubmittingCart(state, action: PayloadAction<boolean>) {
      state.isSubmittingCart = action.payload;
    },
    setSummaryFinancials(state, action: PayloadAction<OrderFinancials>) {
      state.summaryFinancials = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signout, () => {
        return initialState;
      })
      .addCase(reset, () => {
        return initialState;
      });
  },
});

const { actions, reducer } = cartSlice;

export const {
  removeJustAdded,
  removeCartOption,
  removeCartPackage,
  removeCartProduct,
  setFinancials,
  setSummaryFinancials,
  setCartDiscounts,
  setCartErrors,
  setCartImageOption,
  setCartImageOptions,
  setCartMap,
  setCartPackage,
  setCartProduct,
  setFavorites,
  setIsSubmittingCart,
} = actions;

export default reducer;
