import React from 'react';
import { useIntl } from 'react-intl';
import { CategorySelectionType } from '../../../../shop-api-client';
import RoundedBanner from '../../../shared/components/RoundedBanner';

interface Props {
  selectionType: CategorySelectionType;
}

const PurchaseCriteriaBanner = ({ selectionType }: Props) => {
  const intl = useIntl();

  if (selectionType === 'none') {
    return null;
  }

  const getText = () => {
    if (selectionType === 'atLeastOne') {
      return intl.formatMessage({
        id: 'purchaseCriteriaBanner.atLeastOne',
        defaultMessage: 'At least one item from this category must be purchased.',
      });
    }

    if (selectionType === 'singleOptional') {
      return intl.formatMessage({
        id: 'purchaseCriteriaBanner.oneItemCanBePurchased',
        defaultMessage:
          'Only one item from this category can be purchased. After you add an item to your cart, this category will lock.',
      });
    }

    return intl.formatMessage({
      id: 'purchaseCriteriaBanner.oneItemMustBePurchased',
      defaultMessage:
        'One item from this category must be purchased. After you add an item to your cart, this category will lock.',
    });
  };

  return (
    <RoundedBanner
      data-test="banner-message"
      marginBottom={4}
      marginTop={{ base: 2, md: 7 }}
      borderRadius={{ base: 12, md: 50 }}
    >
      {getText()}
    </RoundedBanner>
  );
};

export default PurchaseCriteriaBanner;
