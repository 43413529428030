import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { Address } from '../../../../../shop-api-client';

interface Props extends FlexProps {
  address: Address;
  showEmail?: boolean;
  showPhone?: boolean;
  lineHeight?: number;
}

// renders a completed address form
const CheckoutAddressComplete = ({
  address,
  showEmail = true,
  showPhone = true,
  lineHeight = 6,
  ...rest
}: Props) => {
  const AddressFlex = ({ children }: { children: ReactNode }) => (
    <Flex lineHeight={lineHeight}>{children}</Flex>
  );

  return (
    <Flex flexFlow="column" {...rest}>
      <AddressFlex>{`${address.firstName} ${address.lastName}`}</AddressFlex>
      <AddressFlex>{address.address1}</AddressFlex>
      <AddressFlex>{address.address2}</AddressFlex>
      <AddressFlex>{`${address.city}, ${address.state} ${address.zip}`}</AddressFlex>
      {showPhone && <AddressFlex>{address.phone}</AddressFlex>}
      {showEmail && <AddressFlex>{address.email}</AddressFlex>}
    </Flex>
  );
};

export default CheckoutAddressComplete;
