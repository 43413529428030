import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../../../redux/selectors/account.selectors';
import { selectConfigurationSummary } from '../../../../../redux/selectors/configurations.selectors';
import RoundedBorderBox from '../../../../../shared/components/RoundedBorderBox';
import { ITEM_PRICE } from '../../../../../shared/constants/labels.constants';
import { formatCurrency } from '../../../../../shared/utils';
import { ADD_TO_CART } from '../../../constants';
import {
  ADDITIONAL_CART_ITEMS,
  ADDITIONAL_POSES,
  BACKGROUNDS,
  PHOTO_ADD_ONS,
  PRODUCT_ADD_ONS,
  SUMMARY_WITH_COLON,
  TOTAL_WITH_COLON,
  UPDATE,
} from '../../constants';
import AddOnsSummaryList from '../AddOnsSummaryList';
import SummaryRow from './SummaryRow';

interface Props {
  hasAdditionalPoses: boolean;
  hasBackgrounds: boolean;
  hasProductOptions: boolean;
  showUpdateText: boolean;
  onAddToCart(): void;
  isAddingOrUpdating: boolean;
}

const ConfigurationSummary = ({
  hasAdditionalPoses,
  hasBackgrounds,
  hasProductOptions,
  onAddToCart,
  isAddingOrUpdating,
  showUpdateText,
}: Props) => {
  const {
    additionalPoseFees,
    backgroundFees,
    editProductPrice,
    editPackagePrice,
    imageOptionFees,
    imageOptionSummary,
    productAddOnsTotal,
    subtotal,
  } = useSelector(selectConfigurationSummary);

  const { currency } = useSelector(selectAccount);

  const buttonDataCy = showUpdateText
    ? 'cart-item-editor-update-button'
    : 'configuration-add-to-cart-button';

  const buttonText = showUpdateText ? UPDATE : ADD_TO_CART;

  const renderSummary = () => {
    return (
      <>
        <Heading marginBottom={3} fontSize="md">
          {SUMMARY_WITH_COLON}
        </Heading>
        <SummaryRow
          data-test="item-price"
          amount={editProductPrice || editPackagePrice}
          fontSize="md"
          label={ITEM_PRICE}
        />
        {hasAdditionalPoses && (
          <SummaryRow amount={additionalPoseFees} label={ADDITIONAL_POSES} fontSize="md" />
        )}
        {hasBackgrounds && (
          <SummaryRow
            data-test="background-fees"
            amount={backgroundFees}
            label={BACKGROUNDS}
            fontSize="md"
          />
        )}
        {hasProductOptions && (
          <SummaryRow
            data-test="item-add-ons"
            amount={productAddOnsTotal}
            label={PRODUCT_ADD_ONS}
            fontSize="md"
          />
        )}
        <Divider borderColor="grey.3" marginY={6} />
      </>
    );
  };

  const renderAdditionalCartItems = () => {
    if (!imageOptionSummary.count) {
      return null;
    }

    return (
      <>
        <Heading marginBottom={3} fontSize="md">
          {ADDITIONAL_CART_ITEMS}:
        </Heading>
        <SummaryRow
          data-test="photo-add-ons"
          amount={imageOptionFees}
          fontSize="md"
          label={PHOTO_ADD_ONS}
        />
        <AddOnsSummaryList />
        <Divider borderColor="grey.3" marginY={6} />
      </>
    );
  };

  return (
    <RoundedBorderBox backgroundColor="grey.1" borderColor="grey.1" padding={8}>
      {renderSummary()}
      {renderAdditionalCartItems()}
      {!showUpdateText && (
        <Flex align="end" justify="space-between" marginY={'2px'}>
          <Heading lineHeight="1.6" size="md">
            {TOTAL_WITH_COLON}
          </Heading>
          <Text fontFamily="heading" fontSize="xl">
            {formatCurrency(subtotal, currency)}
          </Text>
        </Flex>
      )}
      <Button
        data-test={buttonDataCy}
        disabled={isAddingOrUpdating}
        isLoading={isAddingOrUpdating}
        loadingText={buttonText}
        marginTop={6}
        onClick={onAddToCart}
        width="100%"
      >
        {buttonText}
      </Button>
    </RoundedBorderBox>
  );
};

export default ConfigurationSummary;
