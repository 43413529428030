import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PriceSheetOption, SelectionOption } from '../../../../../../shop-api-client';
import { CartImageOptionReq } from '../../../../../../shop-api-client/models/Cart';
import {
  selectAvailableImagesForOptions,
  selectConfiguration,
} from '../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../redux/selectors/gallery.selectors';
import {
  removeImageOption,
  setEditImageOption,
} from '../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import Option from '../../../../../shared/components/Option';
import { getInitialImageOptionMap, shapeCartImageOption } from '../../utils';
import ImageOptionEditorModal from './ImageOptionEditorModal';

interface Props {
  option: PriceSheetOption;
}

/**
 * ImageOptionEditor
 *
 * This component renders a single image option during product configuration.
 * Each image option handles its own logic on adding/updating/removing image options
 * from the configuration slice in Redux, as well as error handling.
 */
const ImageOptionEditor = ({ option }: Props) => {
  const { isPreOrder } = useSelector(selectGallery);
  const { editImageOptionsMap } = useSelector(selectConfiguration);
  const imagesByRequirement = useAppSelector(selectAvailableImagesForOptions);

  const [selectionIDToImageMap, setSelectionIDToImageMap] = useState<Record<number, string[]>>({});
  const [optionSelectionID, setOptionSelectionID] = useState<number | false>();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const availableImages = imagesByRequirement[option.imageRequirementType!];

  useEffect(() => {
    setSelectionIDToImageMap(
      getInitialImageOptionMap(
        option,
        editImageOptionsMap[option.id] || {},
        imagesByRequirement[option.imageRequirementType!],
      ),
    );
  }, [option, editImageOptionsMap, imagesByRequirement]);

  useEffect(() => {
    // if option is in the edit image options map, it has selection values associated with it
    const editOption = editImageOptionsMap[option.id];
    // we only want to highlight if there is one value (single selection or toggle)
    // otherwise it's a selection image option with multiple potential values
    if (!editOption || Object.keys(editOption).length > 1) {
      return;
    }
    const selectionID = parseInt(Object.keys(editOption)[0]);
    setOptionSelectionID(selectionID);
  }, [option, editImageOptionsMap]);

  // If any of the items in the selection map have images, it should show the expanded option:
  const showExpanded = Object.values(selectionIDToImageMap).some(images => images.length);

  /**
   * Creates a map for the number of images assigned to an image option on the product
   */
  const getSelectionBadgeValues = () => {
    // only show badges if we need to select multiple images for an image option
    if (isPreOrder || Object.keys(availableImages).length === 1) {
      return {};
    }

    return Object.entries(selectionIDToImageMap).reduce<Record<number, number>>(
      (map, [selectionID, images]) => {
        map[parseInt(selectionID)] = images.length;
        return map;
      },
      {},
    );
  };

  /**
   * Callback for the selection modal to save the selections
   */
  const updateImageOptionSelections = (newSelectionIDToImageMap: Record<number, string[]>) => {
    setSelectionIDToImageMap(newSelectionIDToImageMap);
    handleApplyImageOption(newSelectionIDToImageMap);

    // If no images were set, this should unset the selected state:
    const hasImages = Object.values(newSelectionIDToImageMap).some(images => images.length > 0);
    if (!hasImages) {
      setOptionSelectionID(undefined);
    }
  };

  /**
   * handles actually saving the current state values to redux
   * takes map of key:selectionID to string|null[] of images selected for that option selection
   * recreates cart image option elements for each
   * saves to redux
   */
  const handleApplyImageOption = (selectionIDToImageMap: Record<number, (string | null)[]>) => {
    const imageOptionSelections = Object.entries(selectionIDToImageMap).reduce<
      Record<number, CartImageOptionReq>
    >((map, [selectionID, images]) => {
      const optionSelection = option.selections.find(
        s => s.catalogOptionID === parseInt(selectionID),
      );
      if (optionSelection) {
        // 1. shape
        const newCartImageOption = shapeCartImageOption(
          option,
          optionSelection,
          images.map(img => ({ imageName: img })),
        );

        map[parseInt(selectionID)] = newCartImageOption;
      }

      return map;
    }, {});

    dispatch(
      setEditImageOption({
        priceSheetOptionID: option.id,
        imageOptionSelections,
      }),
    );
  };

  /**
   * Saves selection and shows a modal if further image selection is required.
   */
  const handleSelectOptionSelection = (selectionID: number | false) => {
    // if selectionID is null, we opted out of an optional option
    if (!selectionID) {
      // and then if that option exits on either cart or in editImageOptions...
      // set to null
      setOptionSelectionID(false);
      if (editImageOptionsMap[option.id]) {
        setSelectionIDToImageMap({});
        dispatch(removeImageOption(option.id));
      }
      return;
    }

    // otherwise we are adding an image option
    // save the selectionID to state
    setOptionSelectionID(selectionID);
    // single node case:
    // if it's preorder, we add a null placeholder for the image
    // if there was only ONE image used in the edit item - use that image
    const updatedSelectionMap = Object.keys(selectionIDToImageMap).reduce<
      Record<number, (string | null)[]>
    >((map, sID) => {
      const arr = [isPreOrder ? null : Object.keys(availableImages)[0]];
      map[parseInt(sID)] = parseInt(sID) === selectionID ? arr : [];

      return map;
    }, {});

    if (isPreOrder) {
      handleApplyImageOption(updatedSelectionMap);
      return;
    }

    if (Object.keys(availableImages).length === 1) {
      handleApplyImageOption(updatedSelectionMap);
      return;
    }

    // otherwise we have multiple images on the product that an image option can be applied to
    // so bust out the modal
    setShowModal(true);
  };

  return (
    <>
      <Option
        expanded={showExpanded}
        heading={option.name}
        isInvalid={false}
        option={option}
        selectionBadges={getSelectionBadgeValues()}
        setSelectionID={handleSelectOptionSelection}
        selectionID={optionSelectionID}
      />
      {optionSelectionID && showModal && (
        <ImageOptionEditorModal
          images={availableImages}
          key={optionSelectionID}
          option={option as SelectionOption}
          optionSelectionID={optionSelectionID}
          selectionIDToImageMap={selectionIDToImageMap}
          setOptionSelectionID={setOptionSelectionID}
          setShowModal={setShowModal}
          showModal={showModal}
          updateImageOptionSelections={updateImageOptionSelections}
        />
      )}
    </>
  );
};

export default ImageOptionEditor;
