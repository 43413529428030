import { createSelector } from '@reduxjs/toolkit';
import { CartFinancials } from '../../../shop-api-client/models/Cart';
import { getMultiCartFinSum } from '../../features/Carts/utils';
import { CART_FINANCIALS_DEFAULTS } from '../slices/cart.slice';
import { RootState } from '../store';

// Select financials for a single cart (current visitKey cart if none is provided)
export const selectCartFinancials = createSelector(
  [
    (state: RootState) => state.cart.cartFinancials,
    (state: RootState) => state.visitor.currentVisitKey,
    (_, visitKey?: string) => visitKey,
  ],
  (cartFinancials, currentVisitKey, visitKey) => {
    if (!cartFinancials[visitKey || currentVisitKey!]) {
      return CART_FINANCIALS_DEFAULTS;
    }
    return cartFinancials[visitKey || currentVisitKey!];
  },
);

// defaults to CheckoutVisitKeys financials
export const selectCartCheckoutFinancials = createSelector(
  [
    (state: RootState) => state.cart.cartFinancials,
    (state: RootState) => state.checkout.checkoutVisitKeys,
    (_, cartVisitKeys?: string[]) => cartVisitKeys,
  ],
  (cartFinancials, checkoutVisitKeys, cartVisitKeys) => {
    if (!cartVisitKeys?.length && !checkoutVisitKeys?.length) {
      return CART_FINANCIALS_DEFAULTS;
    }
    let visitKeys: string[] = cartVisitKeys || [];
    if (!cartVisitKeys) {
      visitKeys = checkoutVisitKeys || [];
    }

    const multiCartFinMap = visitKeys.reduce<Record<string, CartFinancials>>((res, visitKey) => {
      res[visitKey] = cartFinancials[visitKey] || CART_FINANCIALS_DEFAULTS;

      return res;
    }, {});

    return getMultiCartFinSum(Object.values(multiCartFinMap));
  },
);

// Select total financials for all carts
export const selectSummaryFinancials = createSelector(
  (state: RootState) => state.cart.summaryFinancials,
  summaryFinancials => summaryFinancials,
);
