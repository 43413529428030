import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateCartPackageReq,
  CreateCartProductReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import {
  selectBackgroundSetMap,
  selectBackgroundToSetMap,
} from '../../../../../../redux/selectors/background.selectors';
import { selectIsStepCompleted } from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import {
  setCompletedSteps,
  setEditStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import Swatch from '../../../../../../shared/components/BackgroundSwatches/Swatch';
import { FREE, SELECT_YOUR_BACKGROUND } from '../../../../../../shared/constants';
import useSearchParams from '../../../../../../shared/hooks/useSearchParams';
import { formatCurrency, getNameWithoutExt } from '../../../../../../shared/utils';
import { BACKGROUND_WITH_COLON, NONE_SELECTED } from '../../../constants';
import { getBackground, getPreOrderBackgroundsStep } from '../../../utils';
import Backgrounds from '../../Backgrounds';
import CompletedSection from '../../CompletedSection';
import EditorSectionContainer from '../../EditorSectionContainer';

interface Props {
  editItem: CreateCartPackageReq | CreateCartProductReq;
  isInvalid?: boolean;
  isSubItem?: boolean;
}

/** Bg selection component used primarily for pre-order GS galleries
 * when ordering in product/single-flow package configuration
 * */
const BackgroundSelection = ({ editItem, isSubItem, isInvalid = false }: Props) => {
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const backgroundToSetMap = useSelector(selectBackgroundToSetMap);
  const { preOrderBackgroundSelectionType } = useSelector(selectPriceSheet);
  const { currency } = useAppSelector(selectAccount);

  const backgroundsStep = getPreOrderBackgroundsStep(editItem.id!, preOrderBackgroundSelectionType);
  const { current: stepName } = useRef(backgroundsStep);

  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  const { getParamsValue } = useSearchParams();
  const dispatch = useAppDispatch();

  const cartPackageID = getParamsValue('cartPackageID');
  const cartProductID = getParamsValue('cartProductID');

  const selectedBackground = getBackground(editItem, Object.values(backgroundSetMap));

  const handleEdit = () => {
    dispatch(setEditStep(backgroundsStep));
  };

  const handleSelection = () => {
    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
  };

  if (completed && selectedBackground && !isSubItem) {
    const backgroundSetID = selectedBackground ? backgroundToSetMap[selectedBackground.id] : null;
    const backgroundSet = backgroundSetID ? backgroundSetMap[backgroundSetID] : null;
    const price = backgroundSet?.price;

    return (
      <CompletedSection
        heading={SELECT_YOUR_BACKGROUND}
        isLoading={!!(cartPackageID || cartProductID) && !selectedBackground}
        onEdit={handleEdit}
        paddingBottom={4}
      >
        <Flex>
          <Swatch
            position="relative"
            size="55px"
            url={`url(${selectedBackground.sources.thumb}) no-repeat`}
            cursor="auto"
          />
          <Flex direction="column" marginX={5}>
            <Text marginBottom={1}>
              <Box as="span" fontFamily="heading">
                {BACKGROUND_WITH_COLON}
              </Box>{' '}
              {getNameWithoutExt(selectedBackground?.name) || NONE_SELECTED}
            </Text>
            <Text fontSize="xs">{price ? formatCurrency(price, currency) : FREE}</Text>
          </Flex>
        </Flex>
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer>
      <Backgrounds
        editItem={editItem}
        isSubItem={isSubItem}
        onSelect={handleSelection}
        selectedBackground={selectedBackground}
        isInvalid={isInvalid}
      />
    </EditorSectionContainer>
  );
};

export default BackgroundSelection;
