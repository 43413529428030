import React, { useEffect } from 'react';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { resetConfiguration } from '../../../redux/slices/configurations.slice';
import { useAppDispatch } from '../../../redux/store';
import { loadBackgroundImage } from '../../../redux/thunks/interactions.thunks';
import ProtectedRoute from '../../../shared/components/ProtectedRoute';
import { Params } from '../../../shared/types/router';
import PackageConfiguration from './PackageConfiguration';
import PackageWizard from './PackageWizard';
import ProductConfiguration from './ProductConfiguration';

// WIP: This component serves as the base for Product Configuration.
// We are still awaiting design criteria, so at the moment its purpose
// is to get coverage on Preorder configuration in the absence of design
const Configuration = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { packageID, productID } = useParams<Params>();

  useEffect(() => {
    history.replace({ search });
  }, [history, search]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('/configure')) {
        dispatch(resetConfiguration());
        dispatch(loadBackgroundImage(null));
      }
    };
  }, [dispatch, history]);

  return (
    <Switch>
      <ProtectedRoute
        path="/:key/configure/package/:packageID"
        key={packageID}
        component={PackageConfiguration}
      />
      <ProtectedRoute
        path="/:key/configure/product/:productID"
        key={productID}
        component={ProductConfiguration}
      />
      <ProtectedRoute
        key={packageID}
        path="/:key/configure/wizard/:packageID"
        component={PackageWizard}
      />
    </Switch>
  );
};

export default Configuration;
