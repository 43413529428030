import { Box, BoxProps } from '@chakra-ui/layout';
import React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
}

const DrawerSection = ({ children, ...rest }: Props) => (
  <Box
    borderBottomWidth="1px"
    padding="4"
    width="100%"
    _last={{
      borderBottomWidth: '0',
    }}
    {...rest}
  >
    {children}
  </Box>
);

export default DrawerSection;
