import { Flex } from '@chakra-ui/react';
import { TextNode } from 'iq-api-client';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  ShopProduct,
  ShopProductCollection,
  ShopProductPrint,
} from '../../../../../../../../shop-api-client';
import {
  CartCollection,
  CartPrintProduct,
  CartProduct,
  CreateCartProductReq,
} from '../../../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../../../shop-api-client/models/ShopProductNodes';
import { selectBackgroundsMap } from '../../../../../../../redux/selectors/background.selectors';
import { selectCart } from '../../../../../../../redux/selectors/cart.selectors';
import {
  selectConfiguration,
  selectGroupedPkgItems,
  selectPackageItemMap,
  selectPackageSteps,
} from '../../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../../../redux/selectors/priceSheet.selectors';
import {
  setCompletedPkgItems,
  setCompletedSteps,
  setEditPackage,
  setEditPackageStep,
  setInvalidStep,
  setShowMultiNodeModal,
} from '../../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/store';
import { addFavorite } from '../../../../../../../redux/thunks/cart.thunks';
import useIsMobile from '../../../../../../../shared/hooks/useIsMobile';
import { Params } from '../../../../../../../shared/types/router';
import { rotateArray } from '../../../../../../../shared/utils';
import { getCartItemToOptionKey, getImagesOnProduct } from '../../../../../../Carts/utils';
import ProductImage from '../../../../../ProductImage';
import { isConfigurableImageNode } from '../../../../../utils';
import { NEXT_ADD_ONS, NEXT_ITEM, NEXT_PREFIX, NEXT_SUMMARY } from '../../../../constants';
import useConfigurationRouter from '../../../../useConfigurationRouter';
import {
  getCollectionImagesStep,
  getGroupedProductOptions,
  getImageMultiNodeStep,
  getImageNodesStep,
  getProductOptionsStep,
  getRequiredProductOptionsStep,
  getTextNodesStep,
  matchTextNodesInPackage,
} from '../../../../utils';
import PackageCollectionPreview from '../../../EditorPreview/PackageCollectionPreview';
import PreviewCarousel from '../../../EditorPreview/PreviewCarousel';
import SidebarCustomize from '../../../EditorSidebar/SidebarCustomize';
import PackageFooter from '../../../PackageFooter';
import CustomizeNavigation from '../CustomizeNavigation';
import ItemProgressionOverview from '../ItemProgressionOverview';

interface Props {
  editPackageProduct: CartProduct | CreateCartProductReq;
}

const CustomizeItem = ({ editPackageProduct }: Props) => {
  // Selectors
  const { shopFavorites } = useAppSelector(selectCart);
  const { editPackage } = useAppSelector(selectConfiguration);
  const { completedPkgItems, completedSteps, editPackageStep, optedOutProductOptions } =
    useAppSelector(selectConfiguration);
  const { images, isPreOrder } = useAppSelector(selectGallery);
  const { configurableItems } = useAppSelector(selectGroupedPkgItems);
  const { imageOptionMap, productNodeMap } = useAppSelector(selectPriceSheet);
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const packageItemMap = useAppSelector(selectPackageItemMap);
  const packageSteps = useAppSelector(selectPackageSteps);

  // Routing
  const { routeToWizardStep } = useConfigurationRouter();

  // Misc hooks
  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  // Regular variables
  const shopPkgItem = packageItemMap[editPackageProduct.priceSheetItemID];
  const { requiredProductOptions } = getGroupedProductOptions(shopPkgItem.options);
  const hasOptions = Object.keys(imageOptionMap).length;

  const getNextLabel = useCallback(() => {
    const productSteps = packageSteps[editPackageProduct.id!];
    const currentIndex = productSteps.findIndex(step => step.step === editPackageStep);

    // Are there any remaining incomplete steps after the current index?
    const hasIncompleteSteps = productSteps
      .slice(currentIndex + 1)
      .some(step => !completedSteps[step.step]);

    if (!hasIncompleteSteps) {
      // If all products have been completed, move onto the next big step:
      const isAllComplete = configurableItems.every(item => completedPkgItems[item.id!]);
      if (isAllComplete) {
        return Object.keys(imageOptionMap).length ? NEXT_ADD_ONS : NEXT_SUMMARY;
      }

      return NEXT_ITEM;
    }

    return `${NEXT_PREFIX} ${productSteps[currentIndex + 1].nextLabel}`;
  }, [
    completedPkgItems,
    completedSteps,
    configurableItems,
    editPackageProduct,
    editPackageStep,
    imageOptionMap,
    packageSteps,
  ]);

  const getNextStep = useCallback(() => {
    const productSteps = packageSteps[editPackageProduct.id!];
    const currentIndex = productSteps.findIndex(step => step.step === editPackageStep);

    // We only need to go to the next step within the same product, if we have finished
    // the product, the routing will happen on the package layer
    if (currentIndex !== productSteps.length - 1) {
      return productSteps[currentIndex + 1].step;
    }
  }, [editPackageProduct, editPackageStep, packageSteps]);

  const handleBack = () => {
    const productIndex = configurableItems.findIndex(item => item.id === editPackageProduct.id);
    const productSteps = packageSteps[editPackageProduct.id!];
    const currentIndex = productSteps.findIndex(step => step.step === editPackageStep);

    if (currentIndex > 0) {
      // Set the previous step
      dispatch(setEditPackageStep(productSteps[currentIndex - 1].step));
    } else if (productIndex > 0) {
      // Route to the previous product (no minus 1 because URL parameter is 1-indexed)
      routeToWizardStep('customize', productIndex);
    } else {
      // Route to the customize overview:
      routeToWizardStep('customize');
    }
  };

  const handleBackToOverview = () => {
    routeToWizardStep('customize');
  };

  /**
   * Loops through unique poses in the package sub-item and adds to Favorites, if the poses
   * is not already favorited
   */
  const handleAddToFavorites = async () => {
    const imageMap = getImagesOnProduct(editPackageProduct as CartProduct);
    const favoritesMap = shopFavorites.reduce<Record<string, boolean>>((result, image) => {
      result[`${image.imageName}-${image.backgroundID || undefined}`] = true;
      return result;
    }, {});

    // Loop through sub-item images to favorite
    for (const [imageName, backgroundSet] of Object.entries(imageMap)) {
      const backgrounds = Array.from(backgroundSet);
      const image = images[imageName];

      if (image.isGreenScreen) {
        // PNG images can't be favorited if no background is present, however this state
        // is possible if the image is used in a node that requires no background, so skip:
        if (backgrounds.length === 0) {
          continue;
        }
        for (const backgroundID of backgrounds) {
          if (!favoritesMap[`${imageName}-${backgroundID}`]) {
            const background = backgroundsMap[backgroundID];
            await dispatch(addFavorite(key, image, background));
          }
        }
      } else if (!favoritesMap[`${imageName}-${undefined}`]) {
        await dispatch(addFavorite(key, image, null));
      }
    }
  };

  const handleNext = async () => {
    if (!editPackageStep) {
      return;
    }

    // Depending on the step, validate the state:
    const imageNodeStep = getImageNodesStep(editPackageProduct.id!);
    const imageMultiNodeStep = getImageMultiNodeStep(editPackageProduct.id!);
    const collectionStep = getCollectionImagesStep(editPackageProduct.id!);
    const textNodeStep = getTextNodesStep(editPackageProduct.id!);
    const productOptionStep = getProductOptionsStep(editPackageProduct.id!);

    const imageNodeMap: Record<string, ShopImageNodeWithReqType> = {};
    const textNodeMap: Record<string, TextNode> = {};

    for (const node of productNodeMap[(shopPkgItem as ShopProduct).catalogProductID] || []) {
      if (node.type === 'image' && isConfigurableImageNode(node)) {
        const imgReq = (shopPkgItem as ShopProductPrint).imageRequirementProperties.find(
          p => p.nodeID === node.id,
        );

        // Store image nodes with consistent imageRequirementType:
        imageNodeMap[node.id] = {
          ...node,
          imageRequirementType: imgReq?.type || 'any',
        };
      }

      if (node.type === 'text') {
        textNodeMap[node.id] = node;
      }
    }

    if (editPackageStep === imageNodeStep || editPackageStep === imageMultiNodeStep) {
      // Verify all image nodes have the required properties:
      for (const node of (editPackageProduct as CartPrintProduct).nodes) {
        const catalogNode = imageNodeMap[node.catalogNodeID];
        if (node.type !== 'image' || !catalogNode) {
          continue;
        }
        const image = node.imageInternalName ? images[node.imageInternalName] : undefined;

        if (
          !image ||
          (image.isGreenScreen && !node.backgroundID && !catalogNode?.skipBackgroundSelection)
        ) {
          dispatch(setInvalidStep({ [editPackageStep]: true }));

          if (editPackageStep === imageMultiNodeStep) {
            dispatch(setShowMultiNodeModal(true));
          }
          return;
        }
      }
    } else if (editPackageStep === collectionStep) {
      const selectedImages = (editPackageProduct as CartCollection).collectionImages.filter(
        ci => !!ci.internalName,
      ).length;

      // Verify the minimum amount of images has been met:
      if (selectedImages < (shopPkgItem as ShopProductCollection).minImages) {
        dispatch(setInvalidStep({ [editPackageStep]: true }));
        return;
      }
    } else if (editPackageStep === textNodeStep) {
      // Verify all unlocked nodes have text:
      if (
        (editPackageProduct as CartPrintProduct).nodes.some(
          node => node.type === 'text' && !node.text && !textNodeMap[node.catalogNodeID]?.locked,
        )
      ) {
        dispatch(setInvalidStep({ [editPackageStep]: true }));
        return;
      } else {
        const { completedSteps, updatedPackage } = matchTextNodesInPackage(
          editPackage!,
          editPackageProduct,
          packageItemMap,
          productNodeMap,
        );
        dispatch(setEditPackage(updatedPackage));
        if (Object.keys(completedSteps).length) {
          dispatch(setCompletedSteps(completedSteps));
        }
      }
    } else if (editPackageStep === productOptionStep) {
      const invalidSteps: Record<string, boolean> = {};

      // Make sure all required product options have selections:
      for (const { catalogOptionGroupID } of requiredProductOptions) {
        const optionKey = getCartItemToOptionKey(editPackageProduct.id!, catalogOptionGroupID);
        const stepName = getRequiredProductOptionsStep(
          editPackageProduct.id!,
          catalogOptionGroupID,
        );

        // Find the option in the product:
        const hasSelection = !!editPackageProduct.options?.find(
          option => option.optionGroupID === catalogOptionGroupID,
        )?.optionID;
        const isOptedOut = optedOutProductOptions[optionKey];

        invalidSteps[stepName] = !hasSelection && !isOptedOut;
      }

      dispatch(setInvalidStep(invalidSteps));
      if (Object.values(invalidSteps).some(Boolean)) {
        return;
      }
    }

    dispatch(setCompletedSteps({ [editPackageStep]: true }));

    // Determine if the product is now completed:
    const completedProduct = packageSteps[editPackageProduct.id!].every(
      ({ step }) => completedSteps[step] || step === editPackageStep,
    );

    if (completedProduct) {
      dispatch(setCompletedPkgItems({ [editPackageProduct.id!]: true }));
      await handleAddToFavorites();

      const index = configurableItems.findIndex(item => item.id === editPackageProduct.id);

      // Find the next incomplete product, after this one, looping back around if needed:
      const completedProducts = configurableItems.map((item, index) => ({
        completed: completedPkgItems[item.id!],
        index,
      }));
      const rotatedProducts = rotateArray(completedProducts, configurableItems.length - index);

      // Now find the first incomplete product:
      const firstIncomplete = rotatedProducts.find(
        product => product.index !== index && !product.completed,
      );

      if (firstIncomplete) {
        routeToWizardStep('customize', firstIncomplete.index + 1);
      } else {
        routeToWizardStep(hasOptions ? 'imageOption' : 'summary');
      }
    } else {
      dispatch(setEditPackageStep(getNextStep()!));
    }
  };

  const renderContent = () => {
    if (isPreOrder) {
      return (
        <Flex align="center" justify="center" width="100%" paddingTop={5} paddingX={5}>
          <ProductImage
            fallbackFontSize="xl"
            fallbackIconSize="100px"
            maxWidth={500}
            height={undefined}
            image={shopPkgItem.image || ''}
            product={shopPkgItem}
            isSquare
          />
        </Flex>
      );
    }

    if (editPackageProduct.type === 'collection' || editPackageProduct.type === 'imageDownload') {
      if (editPackageStep === getProductOptionsStep(editPackageProduct.id!)) {
        return (
          <Flex align="flex-start" justify="center" width="100%" paddingTop={5} paddingX={5}>
            <ProductImage
              fallbackFontSize="xl"
              fallbackIconSize="100px"
              height={undefined}
              image={shopPkgItem.image || ''}
              product={shopPkgItem}
              isSquare
              maxWidth={500}
            />
          </Flex>
        );
      }

      return (
        <PackageCollectionPreview
          editProduct={editPackageProduct}
          shopProduct={packageItemMap[editPackageProduct.priceSheetItemID] as ShopProductCollection}
        />
      );
    }

    return <PreviewCarousel product={editPackageProduct} />;
  };

  const renderLeftPanel = () => {
    if (isMobile) {
      return null;
    }

    return (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        flex={1}
        justifyContent="center"
        position={{ base: undefined, md: 'sticky' }}
        height={{ base: 'calc(100vh - 265px)', md: 'calc(100vh - 130px)' }}
        top={{ base: undefined, md: 0 }}
      >
        <Flex width={{ base: '100%', md: '360px' }}>
          <ItemProgressionOverview
            activeItem={editPackageProduct}
            activeStep={editPackageStep!}
            packageItems={configurableItems}
            steps={packageSteps[editPackageProduct.id!]}
          />
        </Flex>
        <Flex
          alignItems={{ base: undefined, md: 'flex-start' }}
          flexBasis={{ base: undefined, md: '50%' }}
          flexShrink={{ base: undefined, md: 1 }}
          flex={{ base: 1, md: undefined }}
          justifyContent={{ base: 'center', md: undefined }}
          maxWidth={{ base: undefined, md: '50%' }}
        >
          {renderContent()}
        </Flex>
      </Flex>
    );
  };

  const showingMultiNode = editPackageStep === getImageMultiNodeStep(editPackageProduct.id!);

  return (
    <>
      {!showingMultiNode && <CustomizeNavigation editPackageProduct={editPackageProduct} />}
      <Flex direction="column" flex={1}>
        <Flex direction={{ base: 'column', md: 'row' }} flex={1}>
          {renderLeftPanel()}
          <SidebarCustomize
            activeItem={editPackageProduct}
            activeStep={editPackageStep}
            onBackToOverview={handleBackToOverview}
            onNext={handleNext}
          />
        </Flex>
      </Flex>
      {(!showingMultiNode || !isMobile) && (
        <PackageFooter nextLabel={getNextLabel()} onBack={handleBack} onNext={handleNext} />
      )}
    </>
  );
};

export default CustomizeItem;
