import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ShopProductPrint } from '../../../../../../../shop-api-client';
import {
  selectConfiguration,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import useIsMobile from '../../../../../../shared/hooks/useIsMobile';
import ProductImage from '../../../../ProductImage';
import ProductPreview from '../../../../ProductPreview';

const PackageSummaryPreview = () => {
  const { editPackage } = useAppSelector(selectConfiguration);
  const packageItemMap = useAppSelector(selectPackageItemMap);
  const isMobile = useIsMobile();

  if (isMobile || !editPackage) {
    return null;
  }

  return (
    <Flex direction="row" flexWrap="wrap" height="100%" width="100%">
      {editPackage.products.map(product => (
        <Flex key={product.id} direction="column" width="250px" margin={6}>
          {product.type === 'product' && (
            <ProductPreview
              editProduct={product}
              hidePreviewText
              isInteractive={false}
              maxPreviewHeight={250}
              maxPreviewWidth={250}
              shopProduct={packageItemMap[product.priceSheetItemID] as ShopProductPrint}
            />
          )}
          {product.type !== 'product' && (
            <ProductImage
              fallbackFontSize="xl"
              fallbackIconSize="100px"
              height={undefined}
              image={packageItemMap[product.priceSheetItemID].image || ''}
              maxHeight={250}
              maxWidth={250}
              product={packageItemMap[product.priceSheetItemID]}
              isSquare
            />
          )}
          <Text color="grey.6" fontFamily="ITC Avant Garde Gothic Demi" fontSize="sm" marginY={2}>
            {packageItemMap[product.priceSheetItemID].name}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default PackageSummaryPreview;
