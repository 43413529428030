import { Box, Stack, Text } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ShopBackground } from '../../../../../shop-api-client';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../../redux/store';
import { initializeSelectAndBuy } from '../../../../redux/thunks/interactions.thunks';
import { CAROUSEL_PANEL_Z_INDEX } from '../../../../shared/constants';
import { BUY_PHOTO } from '../../../../shared/constants/labels.constants';
import { Params } from '../../../../shared/types/router';
import FavoriteButton from '../../ImageGrid/FavoriteButton';
import SidePanelBackgrounds from '../SidePanelBackgrounds';

export const SIDE_PANEL_WIDTH = 360;

interface Props {
  onSelectBackground(bg: ShopBackground): void;
  toggleShowViewer(): void;
}

const ImageViewerSidePanel = ({ onSelectBackground, toggleShowViewer }: Props) => {
  const { images, title } = useSelector(selectGallery);
  const { backgroundSets } = useSelector(selectPriceSheet);

  const { backgroundID, key, photoID } = useParams<Params>();
  const dispatch = useAppDispatch();

  const selectedImage = images[photoID];
  const { isGreenScreen } = selectedImage;
  const isSingleImage = Object.keys(images).length === 1;

  const handleBuyMode = () => {
    dispatch(initializeSelectAndBuy(photoID, parseInt(backgroundID)));
  };

  return (
    <Box
      backgroundColor="grey.10"
      borderColor="black"
      borderLeftStyle="solid"
      borderLeftWidth="1px"
      flex={1}
      height="100%"
      overflow="auto"
      padding="15px"
      position="absolute"
      right="0"
      width={`${SIDE_PANEL_WIDTH}px`}
      zIndex={CAROUSEL_PANEL_Z_INDEX}
    >
      <Text color="white" fontSize="sm">
        {title}
      </Text>
      <Text
        color="white"
        data-test="image-viewer-side-panel-text"
        fontFamily="ITC Avant Garde Gothic Demi"
        fontSize="xs"
      >
        {selectedImage.displayName}
      </Text>
      <Stack paddingX="2" paddingY="6">
        <Link
          as={RouterLink}
          data-test="image-viewer-side-panel-select-buy"
          marginY="20px"
          onClick={handleBuyMode}
          to={{
            pathname: `/${key}/shop/all`,
            search: `?image=${photoID}${
              isGreenScreen && backgroundID ? `&bg=${backgroundID}` : ''
            }`,
          }}
          variant="modalPrimary"
        >
          {BUY_PHOTO}
        </Link>
        <FavoriteButton
          backgroundID={parseInt(backgroundID)}
          image={selectedImage}
          variant="modalSecondary"
          width="100%"
        />
      </Stack>
      {!!backgroundSets.length && isGreenScreen && !isSingleImage && (
        <SidePanelBackgrounds
          onSelectBackground={onSelectBackground}
          toggleShowViewer={toggleShowViewer}
        />
      )}
    </Box>
  );
};

export default ImageViewerSidePanel;
