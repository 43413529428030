import { Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  backgroundColor?: string;
  color?: string;
  text: string;
  'data-test'?: string;
}

const Tag = ({
  backgroundColor = 'grey.2',
  color = 'black',
  text,
  'data-test': dataTest,
}: Props) => (
  <Flex
    align="center"
    backgroundColor={backgroundColor}
    borderRadius={6}
    color={color}
    fontFamily="ITC Avant Garde Gothic Demi"
    fontSize="12px"
    height="25px"
    justify="center"
    marginRight={1}
    minWidth="40px"
    paddingX={2}
    data-test={dataTest}
  >
    {text}
  </Flex>
);

export default Tag;
