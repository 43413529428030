import React, { useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { api, RegenerationTokenState } from '../../../shop-api-client';
import { useIntl } from 'react-intl';

interface RegenerateButtonProps {
  text: string;
  token: string;
}

const RegenerateButton = ({ text, token }: RegenerateButtonProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const intl = useIntl();

  const handleRegeneration = async () => {
    setIsProcessing(true);
    const results = await api.regenerateDownloadToken(token);
    setResultMessage(results.state);
    setIsProcessing(false);
  };

  const render = () => {
    if (isProcessing) {
      // TODO: do something here to show the user work is being done
      return;
    }

    if (resultMessage === '') {
      return (
        <Button colorScheme="blue" onClick={handleRegeneration}>
          {text}
        </Button>
      );
    }

    // TODO: get text for both of the Text tags
    if (resultMessage === RegenerationTokenState.MessageSent) {
      return (
        <Text>
          {intl.formatMessage({
            id: 'regenerationButton.messageSent.text',
            defaultMessage: 'Email Sent!',
          })}
        </Text>
      );
    }

    return (
      <Text>
        {intl.formatMessage({
          id: 'regenerationButton.notFound.text',
          defaultMessage: 'generic message sent text',
        })}
      </Text>
    );
  };

  return <Box>{render()}</Box>;
};

export default RegenerateButton;
