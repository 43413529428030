import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { ShopBackground, ShopImage } from '../../../../../../shop-api-client';
import { selectCart } from '../../../../../redux/selectors/cart.selectors';
import { useAppSelector } from '../../../../../redux/store';
import ThumbnailWithBackground from '../../../../../shared/components/ThumbnailWithBackground';
import { ImageAndBackground } from '../../../../../shared/types/image';
import { FAVORITES, THUMB_GRID_MAX_HEIGHT, THUMB_GRID_MAX_WIDTH } from '../../constants';
import ImageNodeBackgroundDisplay from '../ImageNodesEditor/ImageNodeBackgroundDisplay';
import ImageNodeDisplayPreset from '../ImageNodesEditor/ImageNodeDisplay/ImageNodeDisplayPreset';
import { keyBy } from 'lodash';

interface Props {
  error?: boolean;
  images: ShopImage[];
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  selectedBackgrounds?: number[];
  selectedImageName?: string | null;
  selectImage(image: ShopImage, background?: ShopBackground): void;
  selectedImages?: Record<string, ImageAndBackground>;
  showAsHorizontalList?: boolean;
  skipBackgroundSelection?: boolean;
}

const ImageSelectionPresets = ({
  error,
  images,
  imageMaxHeight = THUMB_GRID_MAX_HEIGHT,
  imageMaxWidth = THUMB_GRID_MAX_WIDTH,
  selectedBackgrounds,
  selectedImageName,
  selectImage,
  selectedImages = {},
  showAsHorizontalList,
  skipBackgroundSelection,
}: Props) => {
  const { shopFavorites } = useAppSelector(selectCart);
  const intl = useIntl();

  const imageMap = keyBy(images, 'internalName');
  const filteredFavorites = shopFavorites.filter(f => imageMap[f.imageName]);

  const heading = intl.formatMessage(
    {
      id: 'imageSelectionPresets.heading',
      defaultMessage: 'Available Photos ({count})',
    },
    {
      count: images.length,
    },
  );

  const message = intl.formatMessage({
    id: 'imageSelectionPresets.message',
    defaultMessage: 'Only specific photos are allowed on this item',
  });

  const isSingleGreenScreen =
    images.length === 1 && images[0].isGreenScreen && !skipBackgroundSelection;

  const createHandleSelectImage = (image: ShopImage) => () => {
    selectImage(image);
  };

  const getPresetSelectedImages = () => {
    if (Object.keys(selectedImages).length) {
      return selectedImages;
    }
    return {
      [`${selectedImageName}-${selectedBackgrounds?.[0]}`]: {
        imageName: selectedImageName!,
        backgroundID: selectedBackgrounds?.[0],
      },
    };
  };

  const renderImages = () => {
    if (isSingleGreenScreen) {
      // Render background version
      return (
        <ImageNodeBackgroundDisplay
          columns={3}
          error={error}
          image={images[0]}
          selectBackground={selectImage}
          selectedBackgrounds={selectedBackgrounds}
        />
      );
    }

    // Render regular versions, no further interaction possible
    return images.map(image => (
      <Flex
        key={image.internalName}
        direction="column"
        alignItems="center"
        marginBottom="15px"
        paddingX={2}
      >
        <Box
          data-test={`shop-photo-selection-${image.displayName}`}
          borderColor={image.internalName === selectedImageName ? 'brand' : 'white'}
          borderRadius={{ base: '3px', md: '5px' }}
          borderWidth={{ base: '3px', md: '4px' }}
          marginY={2}
          padding={{ base: '2px', md: '4px' }}
        >
          <ThumbnailWithBackground
            containerOnClick={createHandleSelectImage(image)}
            isActive={image.internalName === selectedImageName}
            maxHeight={imageMaxHeight}
            maxWidth={imageMaxWidth}
            src={image.sources.thumb}
            _hover={{
              cursor: 'pointer',
            }}
          />
        </Box>
        {!showAsHorizontalList && (
          <Text fontSize="xs" noOfLines={1} maxWidth={imageMaxWidth}>
            {image.displayName}
          </Text>
        )}
      </Flex>
    ));
  };

  return (
    <Flex direction="column" marginTop={4}>
      {!isSingleGreenScreen && (
        <Text fontFamily="heading" fontSize="sm" margin={showAsHorizontalList ? 2 : 0}>
          {heading}
        </Text>
      )}
      {!isSingleGreenScreen && (
        <Text
          fontSize={showAsHorizontalList ? 'sm' : 'md'}
          paddingBottom={2}
          paddingX={showAsHorizontalList ? 2 : 0}
        >
          {message}
        </Text>
      )}
      {isSingleGreenScreen && (
        <Box paddingTop={2} width="100%">
          <ImageNodeDisplayPreset
            heading={FAVORITES}
            imageMaxHeight={'150px'}
            imageMaxWidth={'100px'}
            presetImages={filteredFavorites}
            selectedImages={getPresetSelectedImages()}
            selectImage={selectImage}
          />
        </Box>
      )}
      <Flex
        flexFlow={showAsHorizontalList ? undefined : 'wrap'}
        overflowX={showAsHorizontalList ? 'scroll' : 'auto'}
      >
        {renderImages()}
      </Flex>
    </Flex>
  );
};

export default ImageSelectionPresets;
