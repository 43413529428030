import { Heading, Image, ImageProps, SpaceProps } from '@chakra-ui/react';
import React, { memo, useState } from 'react';

interface Props extends ImageProps {
  fallbackFontSize?: string;
  fallbackMargin?: SpaceProps['margin'];
  fallbackText?: string;
  imageSrc: string | null;
}

const Logo = ({
  fallback,
  fallbackFontSize,
  fallbackMargin,
  fallbackText,
  imageSrc,
  ...rest
}: Props) => {
  const [showFallbackText, setShowFallbackText] = useState(
    !imageSrc && !!fallbackText && !fallback,
  );

  /**
   * Toggles state to show fallback text, when the image fails to load
   */
  const handleFallback = () => {
    // If a fallback element or string was provided, or if no fallbackText, bail:
    if (fallback || !fallbackText) {
      return;
    }
    setShowFallbackText(true);
  };

  // Render fallback text heading if `showFallbackText` is true
  if (showFallbackText) {
    return (
      <Heading
        alignSelf="center"
        margin={fallbackMargin}
        noOfLines={2}
        size={fallbackFontSize || 'md'}
        textAlign="center"
        whiteSpace="normal"
      >
        {fallbackText}
      </Heading>
    );
  }
  return (
    <Image alt="Logo" fallback={fallback} src={imageSrc || ''} {...rest} onError={handleFallback} />
  );
};

export default memo(Logo, (prevProps, nextProps) => prevProps.imageSrc === nextProps.imageSrc);
