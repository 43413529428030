import { RadioGroup, RadioGroupProps, Text, TextProps, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends RadioGroupProps {
  children: ReactNode;
  invalidMessage: string;
  isInvalid: boolean;
  isRequired?: boolean;
  name: string;
  text?: string;
  headingProps?: TextProps;
}

const VerticalRadioGroup = ({
  children,
  invalidMessage,
  isInvalid,
  isRequired,
  name,
  text,
  headingProps = {},
  ...rest
}: Props) => (
  <>
    {text && (
      <Text fontSize="md" {...headingProps}>
        {text}
        {isRequired && (
          <Text as="span" color="error" paddingLeft="2">
            *
          </Text>
        )}
      </Text>
    )}
    <RadioGroup name={name} {...rest}>
      <VStack alignItems="left">{children}</VStack>
    </RadioGroup>
    {isInvalid && (
      <Text color="error" fontSize="12px" marginLeft="5px" marginTop="2px">
        {invalidMessage || 'Please select an option.'}
      </Text>
    )}
  </>
);

export default VerticalRadioGroup;
